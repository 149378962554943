import { Address } from '@traba/types'
import { Money } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { User } from '@traba/types'
import { ShiftTime } from '@traba/types'
import parsePhoneNumberFromString from 'libphonenumber-js'
import _ from 'lodash'
import removeAccents from 'remove-accents'
import * as yup from 'yup'
import { getShiftTimeString } from './dateUtils'
import { formatDuration } from './dateUtils'

export function getAddressString(address: Address) {
  try {
    const { street1, street2, city, state, postalCode } = address

    const street2Str = street2 ? street2 + ', ' : ''

    return `${street1}, ${street2Str}${city}, ${state} ${postalCode}`
  } catch (err) {
    console.error(
      'stringUtils -> geAddressString() ERROR. Returning empty address string instead.',
      { address },
      err,
    )
    return ''
  }
}

export function getMoneyString(
  money: Money | number | undefined,
  hideCents?: boolean | undefined,
) {
  const currencySymbol = '$' // eventually have this map based on the 'currency' property in the Money object
  return money !== undefined
    ? typeof money === 'number'
      ? `${money < 0 ? '-' : ''}${currencySymbol}${String(
          Math.abs(money).toFixed(hideCents ? 0 : 2),
        )}`
      : `${money.amount < 0 ? '-' : ''}${currencySymbol}${(
          Math.abs(money.amount) / 100
        ).toFixed(hideCents ? 0 : 2)}`
    : ''
}

export function getPayRateString(
  payType: ShiftPayType = ShiftPayType.HOURLY,
  payRate: number,
): string {
  switch (payType) {
    case ShiftPayType.HOURLY:
      return `${getMoneyString(payRate)} / hr`
    case ShiftPayType.UNIT:
      return `${getMoneyString(payRate)} / unit`
  }
}

export function getChargedUnitsString({
  payType,
  timeWorkedInMinutes,
  unitsWorked,
}: {
  payType: ShiftPayType
  timeWorkedInMinutes: number
  unitsWorked?: number
}) {
  switch (payType) {
    case ShiftPayType.HOURLY:
      return formatDuration(timeWorkedInMinutes)
    case ShiftPayType.UNIT:
      return `${unitsWorked} units`
  }
}

export function toPercentString(percent: number) {
  return (percent * 100).toFixed(0)
}

export function truncateString(str: string, length = 6) {
  if (str.length <= length) {
    return str
  }
  return `${str.substring(0, length)}...`
}

export const reNonDigits = /\D/g

export const formatPhoneNumber = (
  phoneNumber: string,
  userFacing?: boolean,
) => {
  const cleaned = ('' + phoneNumber).replace(reNonDigits, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    if (userFacing) {
      return `+1 (${match[2]}) ${match[3]}-${match[4]}`
    }
    return ['+1', match[2], match[3], match[4]].join('')
  }
  return phoneNumber
}

export const sanitizePhoneNumber = (phoneNumber: string) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US')
  if (parsedNumber && parsedNumber.isValid()) {
    return parsedNumber.format('E.164')
  }
}

/*
This function will remove all diacritics from the string and return it lowercased
*/
export const normalizeString = (input: string) => {
  const removedDiacritics = removeAccents(input)
  return removedDiacritics.toLowerCase()
}

/*
 * This function will capitalize the first letter of each
 * word in the string separated by the passed string
 * Example: bad_overbook -> Bad Overbook
 */
export function capitalizeFirstLetterOfEachWord(
  sentence: string,
  separator: string,
) {
  return sentence
    .split(separator)
    .map((word) => _.capitalize(word))
    .join(' ')
}

export function getInitials(firstName: string, lastName: string) {
  return `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`
}

export function breaksToString(breaks: ShiftTime[], timezone: string) {
  return breaks.map((b) => {
    if (!b.startTime || !b.endTime) {
      return null
    }
    return `${getShiftTimeString(b.startTime, b.endTime, timezone)}`
  })
}

export function getCompanyUsersNames(
  companyUsers?: User[],
  userIds?: string[],
) {
  if (!companyUsers || !userIds) {
    return 'None'
  }
  const users = companyUsers.filter((user) => userIds.includes(user.uid))
  return users.map((user) => `${user.firstName} ${user.lastName}`).join(', ')
}

const email = () => yup.string().email('Invalid email')
export const validateEmail = (str: string) => email().validateSync(str)

export const getUniqueValidatedEmails = (emails: string[]) => {
  const validEmails = emails.filter((e) => {
    try {
      validateEmail(e)
      return true
    } catch (err) {
      return false
    }
  })
  return _.uniq(validEmails)
}

export function getShortUnitDescription(payType: ShiftPayType) {
  switch (payType) {
    case ShiftPayType.HOURLY:
      return 'hr'
    case ShiftPayType.UNIT:
      return 'unit'
  }
}
