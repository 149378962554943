import { CircularProgress, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColTypeDef,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ShiftInvoicedStatus,
  ShiftStatus,
  ShiftWithSlimCharges,
} from '@traba/types'
import { CompanyResponse } from '@traba/types'
import { Region } from '@traba/types'
import { subWeeks } from 'date-fns'
import startOfDay from 'date-fns/startOfDay'
import { useEffect, useMemo, useState } from 'react'
import { Col, CopyTextIcon, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import Pagination from 'src/components/base/Pagination/Pagination'
import { useCompanies } from 'src/hooks/useCompanies'
import { useShiftsForInvoicing } from 'src/hooks/useInvoices'
import { useBasicPagination } from 'src/hooks/usePagination'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { getEstimatedTotalChargeInCentsFromCharges } from 'src/utils/billingUtils'
import { convertUSDCentsToMoney } from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import InvoiceStatusBadge from './InvoiceStatusBadge'

type UninvoicedShiftsProps = {
  regions: Region[]
  companies: CompanyResponse[]
}

const PAGE_SIZE = 20

const BaseRenderCell = ({
  value,
  title,
  style,
}: {
  value: React.ReactElement | string
  title?: string
  style?: React.CSSProperties
  hoverText?: string
}) => (
  <Row fullWidth>
    <Tooltip title={title ?? value}>
      <div>
        <Text style={style}>{value}</Text>
      </div>
    </Tooltip>
  </Row>
)

export default function UninvoicedShifts(props: UninvoicedShiftsProps) {
  const { companies, regions } = props
  const { companies: allCompanies, isLoading: isLoadingAllCompanies } =
    useCompanies({})
  const { getDateTime } = useTimezonedDates()

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    currentLocalOffset,
    setCurrentPage,
  } = useBasicPagination(PAGE_SIZE)

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfDay(subWeeks(new Date(), 2)),
    startOfDay(subWeeks(new Date(), 1)),
  ])

  // Reset page when any of the props / query filters change
  useEffect(() => {
    setCurrentPage(0)
  }, [companies, regions, dateRange, setCurrentPage])

  const companyIdToNames = useMemo(
    () =>
      allCompanies
        ? Object.fromEntries(
            allCompanies.map((company) => [company.id, company.employerName]),
          )
        : {},
    [allCompanies],
  )

  const { shifts, totalCount, isFetching } = useShiftsForInvoicing({
    companyIds: companies.map((company) => company.id),
    regionIds: regions.map((region) => region.regionId),
    limit: PAGE_SIZE,
    startAt: currentLocalOffset,
    sortBy: 'endTime',
    sortDir: 'desc',
    startAfter: dateRange[0]?.toISOString(),
    // Fetch all shifts starting anytime before the end of the day of the end range
    startBefore: dateRange[1]?.toISOString(),
    statuses: [ShiftStatus.COMPLETE, ShiftStatus.CANCELED],
    invoicedStatus: ShiftInvoicedStatus.NOT_INVOICED,
  })

  const COL_DEF_OPTIONS: GridColTypeDef = {
    sortable: false,
    flex: 1,
  }

  const shiftsColumns: GridColDef[] = [
    {
      ...COL_DEF_OPTIONS,
      field: 'id',
      headerName: 'Shift ID',
      minWidth: 200,
      renderCell: (params) => (
        <BaseRenderCell
          value={
            <Row gap={5}>
              <CopyTextIcon textToCopy={params.value} />
              {params.value}
            </Row>
          }
          title={params.value}
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'companyId',
      headerName: 'Company',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            <Anchor href={`/companies/${params.value}`} variant="link">
              {companyIdToNames[params.value]}
            </Anchor>
          }
          title={params.value}
        />
      ),
      minWidth: 200,
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'startTime',
      headerName: 'Shift Start Time',
      renderCell: (params) => (
        <BaseRenderCell value={getDateTime(params.value)} />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'region',
      headerName: 'Region',
      renderCell: (params) => <BaseRenderCell value={params.value} />,
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'invoiceId',
      headerName: 'Invoice ID',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            params.value ? (
              <Row gap={5}>
                <CopyTextIcon textToCopy={params.value} />
                <Anchor
                  href={`/billing/invoices/${params.value}`}
                  variant="link"
                >
                  {params.value}
                </Anchor>
              </Row>
            ) : (
              '-'
            )
          }
          title={params.value}
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'invoiceGroup',
      headerName: 'Invoice Group',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            params.value ? (
              <Row gap={5}>
                <CopyTextIcon textToCopy={params.value.id} />
                {params.value.name ?? '-'}
              </Row>
            ) : (
              '-'
            )
          }
          title={params.value.id}
          hoverText={params.value.id}
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'invoiceStatus',
      headerName: 'Invoice Status',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            params.value ? <InvoiceStatusBadge status={params.value} /> : '-'
          }
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'chargeAmount',
      headerName: 'Total Charge Amount',
      renderCell: (params) => (
        <BaseRenderCell
          value={params.value ? getMoneyString(params.value) : '-'}
        />
      ),
    },
  ]

  const makeShiftRow = (shift: ShiftWithSlimCharges) => {
    return {
      id: shift.id,
      invoiceId: shift.invoiceId,
      invoiceStatus: shift.invoiceStatus,
      region: shift.regionId,
      companyId: shift.companyId,
      startTime: shift.startTime,
      chargeAmount: convertUSDCentsToMoney(
        getEstimatedTotalChargeInCentsFromCharges(shift),
      ),
      invoiceGroup: {
        id: shift.parentInvoiceGroupId,
        name: shift.invoiceGroupName,
      },
    }
  }

  const rows = shifts ? shifts?.map(makeShiftRow) : []

  const handleRowClick = (row: GridRowParams<any>, event: MuiEvent<any>) => {
    if ((event.target as HTMLElement).tagName !== 'A') {
      row.row.id && window.open(`/field-monitor/${row.row.id}`, '_blank')
    }
  }

  const shiftsTable = (
    <DataGrid
      rows={rows}
      columns={shiftsColumns}
      loading={isFetching}
      onRowClick={handleRowClick}
      autoHeight
      hideFooterPagination
      hideFooter
      disableColumnFilter
      disableRowSelectionOnClick
      sx={{
        borderRadius: 1,
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderRight: '1px solid #f0f0f0',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      }}
    />
  )

  return (
    <Col>
      <Row mt={theme.space.xs} style={{ maxWidth: '20em' }}>
        <DateRangePicker
          label="Shift Start Date Range"
          inlineLabel={true}
          dateRange={dateRange}
          setDateRange={setDateRange}
          granularity="day"
          style={{
            marginBottom: theme.space.med,
            width: '100%',
          }}
        />
      </Row>
      <Row justifyEnd>
        <Pagination
          page={currentPage}
          pageSize={PAGE_SIZE}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          dataSize={shifts ? shifts.length : 0}
          disabled={isFetching}
          totalFound={totalCount}
        />
      </Row>
      <Row mt={theme.space.xs} alignCenter justifyCenter>
        {isFetching || isLoadingAllCompanies ? (
          <CircularProgress size={20} />
        ) : (
          shiftsTable
        )}
      </Row>
    </Col>
  )
}
