import { useHotSettings } from '@traba/hooks'
import { PendingClockOutsResponse } from '@traba/types'
import { getBreaksForWorkerShift } from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import useTimezonedDates from 'src/hooks/useTimezonedDates'

import { formatDuration } from 'src/utils/dateUtils'
import { getTotalBreakTime } from 'src/utils/moneyUtils'
import { DataTable, DataTableHeader, TableCell } from '../base/Table/DataTable'

type PendingClockOutsItemTableProps = {
  workerShiftId: string
  pendingClockOutsWorkerShift: PendingClockOutsResponse
}

export function PendingClockOutsItemTable({
  workerShiftId,
  pendingClockOutsWorkerShift,
}: PendingClockOutsItemTableProps) {
  const { hotSettings } = useHotSettings()
  const {
    clockInTime: clockInDateString,
    clockOutTimeBeforeWorkerEdit,
    shiftInfo,
    timeWorked,
  } = pendingClockOutsWorkerShift.workerShift

  const displayedBreaks = getBreaksForWorkerShift(
    pendingClockOutsWorkerShift.workerShift,
    hotSettings?.enableWorkerEditTime,
  )

  const shiftStartTime = new Date(shiftInfo.startTime)
  const shiftEndTime = new Date(shiftInfo.endTime)

  const clockInTime = clockInDateString
    ? new Date(clockInDateString)
    : undefined
  const clockOutTime = clockOutTimeBeforeWorkerEdit
    ? new Date(clockOutTimeBeforeWorkerEdit)
    : undefined
  const { getShiftTime, getTime } = useTimezonedDates(shiftInfo.timezone)
  const headers: DataTableHeader[] = [
    {
      key: 'shiftTime',
      label: 'Shift Time',
    },
    {
      key: 'clockInTime',
      label: 'Clock In Time',
    },
    {
      key: 'breakTime',
      label: 'Break Time',
    },
    {
      key: 'clockOutTime',
      label: 'Clock Out Time',
    },
    {
      key: 'hoursWorked',
      label: 'Hrs Worked',
    },
    {
      key: 'clockInDiff',
      label: 'Clock In Diff',
    },
    {
      key: 'clockOutDiff',
      label: 'Clock Out Diff',
    },
  ]

  const cells: TableCell[] = [
    {
      renderFn: () => getShiftTime(shiftStartTime, shiftEndTime),
    },
    {
      renderFn: () => (clockInTime ? getTime(clockInTime) : '--'),
    },
    {
      renderFn: () => {
        const totalBreakTime = getTotalBreakTime(
          displayedBreaks,
          shiftInfo.breakType,
          shiftInfo.scheduledBreaks,
        )
        return formatDuration(totalBreakTime)
      },
    },
    {
      renderFn: () => (clockOutTime ? getTime(clockOutTime) : '--'),
    },
    {
      renderFn: () => {
        return timeWorked ? formatDuration(timeWorked) : '--'
      },
    },
    {
      renderFn: () =>
        clockInTime
          ? formatDuration(
              Math.abs(differenceInMinutes(clockInTime, shiftStartTime)),
            )
          : '--',
    },
    {
      renderFn: () =>
        clockOutTime
          ? formatDuration(
              Math.abs(differenceInMinutes(clockOutTime, shiftEndTime)),
            )
          : '--',
    },
  ]

  return <DataTable headers={headers} rows={[{ cells, key: workerShiftId }]} />
}
