import { InfoTooltip } from '@traba/react-components'
import React from 'react'
import Icon from '../Icon'
import * as S from './Table.styles'
import { TableRow, TableCell, TableFooter } from './Table.styles'

export interface TableHeader {
  key?: string
  label?: string | React.ReactNode
  centerLabel?: boolean
  info?: string
  onPressHeaderItem?: () => void
  /**
   * Shows that the column is sorted (presentation only)
   * Use Data Table to make columns sortable.
   */
  sorted?: 'asc' | 'desc'
}
export interface TableProps {
  children?: React.ReactNode
  headers?: Array<string | TableHeader>
  activeHeaderItemKey?: string
  onPressHeaderItem?: (headerItem: string | TableHeader) => void
  tableContainerStyles?: React.CSSProperties
}

export { TableRow as Tr, TableCell as Td, TableFooter }

export function Table({
  children,
  headers,
  activeHeaderItemKey,
  tableContainerStyles,
}: TableProps) {
  return (
    <S.TableContainer style={tableContainerStyles}>
      <thead>
        <S.TableHeader>
          {Array.isArray(headers) &&
            headers.map((header, i) => {
              const headerItemKey =
                typeof header === 'string' ? header : header?.key
              const shouldCenterLabel =
                typeof header === 'string' ? false : header?.centerLabel
              const info = typeof header === 'string' ? false : header?.info
              const onHeaderClick =
                typeof header === 'string' ? null : header?.onPressHeaderItem
              const sorted = typeof header === 'string' ? null : header?.sorted

              return (
                <th key={`table-header-${i}-${headerItemKey}`}>
                  <S.TableHeaderItem
                    active={activeHeaderItemKey === headerItemKey}
                    clickable={!!onHeaderClick}
                    onClick={() => onHeaderClick && onHeaderClick()}
                    centerLabel={shouldCenterLabel}
                  >
                    {sorted && (
                      <div>
                        <Icon
                          name={sorted === 'desc' ? 'chevronUp' : 'chevronDown'}
                        />
                      </div>
                    )}
                    <div>
                      {typeof header === 'string' ? header : header?.label}
                    </div>
                    {info && (
                      <div>
                        <InfoTooltip title={info} />
                      </div>
                    )}
                  </S.TableHeaderItem>
                </th>
              )
            })}
        </S.TableHeader>
      </thead>
      <S.TableBody>{children}</S.TableBody>
    </S.TableContainer>
  )
}
