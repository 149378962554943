import { trabaApi } from '@traba/api-utils'
import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CustomizedBadgeVariant,
  ShiftBadge,
  ShiftBadgeStatus,
} from '@traba/types'
import { Dispatch, SetStateAction } from 'react'
import { Badge, Row } from 'src/components/base'
import { RadioButton } from 'src/components/RadioButton/RadioButton'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { getBadgeDetails } from './BadgePreview'

interface Props {
  selectedPreviewBadgeId: string | null
  isCreatingNew: boolean
  badges: ShiftBadge[] | undefined
  setSelectedPreviewBadgeId: Dispatch<SetStateAction<string | null>>
  setShortDescription: Dispatch<SetStateAction<string>>
  setLongDescription: Dispatch<SetStateAction<string>>
  setSelectedVariant: Dispatch<SetStateAction<CustomizedBadgeVariant | null>>
  setBadgeName: Dispatch<SetStateAction<string>>
  isActive: boolean
}

export const BadgesListCard = ({
  isCreatingNew,
  selectedPreviewBadgeId,
  setSelectedPreviewBadgeId,
  badges,
  setSelectedVariant,
  setShortDescription,
  setLongDescription,
  setBadgeName,
  isActive,
}: Props) => {
  const { refetch } = useCustomBadges()
  return (
    <Card
      style={{
        backgroundColor: theme.colors.Grey10,
        marginTop: theme.space.xs,
      }}
    >
      <Row alignCenter justifyBetween>
        <Text variant="h5">
          {isActive ? 'Presets and existing badges' : 'Archived badges'}
        </Text>

        {selectedPreviewBadgeId && !isCreatingNew && (
          <Text
            variant="link"
            onClick={async () => {
              await trabaApi.patch(`shift-badge/${selectedPreviewBadgeId}`, {
                status: isActive
                  ? ShiftBadgeStatus.ARCHIVED
                  : ShiftBadgeStatus.ACTIVE,
              })
              refetch()
            }}
          >
            {isActive ? 'Archive selected badge' : 'Activate selected badge'}
          </Text>
        )}
      </Row>
      {isActive && (
        <Text variant="body2">
          Ops users will be able to add these to shifts, roles and companies
        </Text>
      )}
      <Row wrap mt={theme.space.xs}>
        {badges?.map(
          ({ variant, badgeName, id, shortDescription, longDescription }) => {
            const { badgeStyleVariant } = getBadgeDetails(variant)
            return (
              <Row
                key={id}
                alignCenter
                onClick={() => {
                  setSelectedPreviewBadgeId(id)
                  setSelectedVariant(variant)
                  setBadgeName(badgeName)
                  setShortDescription(shortDescription)
                  longDescription && setLongDescription(longDescription)
                }}
                mr={theme.space.xs}
                my={theme.space.xxs}
                gap={theme.space.xxs}
              >
                <RadioButton selected={selectedPreviewBadgeId === id} />
                <Badge variant={badgeStyleVariant} title={badgeName} />
              </Row>
            )
          },
        )}
      </Row>
    </Card>
  )
}
