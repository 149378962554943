import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import { useCertifications } from 'src/hooks/useCertifications'

export const ExistingCertifications = () => {
  const { certifications } = useCertifications()

  return (
    <Row
      flexCol
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
        flex: 1,
      }}
    >
      <Text variant="h4">Existing certifications</Text>
      {certifications?.map((cert) => (
        <div key={cert.name}>
          <Text variant="h6">{cert.name}</Text>
          <Text variant="body2">{`Restriction level: ${cert.restrictionLevel}`}</Text>
          <Text variant="body2">{cert.en.description}</Text>
        </div>
      ))}
    </Row>
  )
}
