import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { otherColors } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { WorkerProfile, WorkerProfileStatus } from '@traba/types'
import { JobStatus } from '@traba/types'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { Icon, Row, Td, Tr } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import JobStatusBadge from 'src/components/JobStatusBadge'
import { ShiftTile } from 'src/components/ShiftTile'
import { useAttributes } from 'src/hooks/useAttributes'
import { useWorkerScores } from 'src/hooks/useWorkerMetrics'
import { useWorkerProfile } from 'src/hooks/useWorkerProfile'
import { WorkerAttributeActions } from './WorkerAttributeActions'
import { WorkerAttributeAdder } from './WorkerAttributeAdder'

/**
 * TODO: Incorporate when worker attribute rating is released
 */
enum FeedbackRating {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
}

export interface WorkerProfileAttributeUIModel {
  attributeMetadata: WorkerProfile
  displayName: string
  ratings: Record<FeedbackRating, number>
  shiftsCompleted: number
  shiftsConsidered: number
  favorited: number
  blocked: number
  lastUniqueBusinessShifts: WorkerShift[]
}

interface WorkerProfileAttributesProps {
  workerShifts?: WorkerShift[]
}

export default function WorkerAttributes(props: WorkerProfileAttributesProps) {
  const { workerId } = useParams()
  if (!workerId) {
    throw new Error('no worker id present in params')
  }
  const { isLoading: isLoadingProfileData, workerProfileAttributes } =
    useWorkerProfile(workerId, true)
  const { isLoading: isLoadingMetrics, workerScores } =
    useWorkerScores(workerId)

  const { attributes: roleAttributes } = useAttributes()
  const { hotSettings } = useHotSettings()

  if (isLoadingProfileData || isLoadingMetrics) {
    return <CircularProgress size="medium" />
  }

  const getUniqueBusinessShifts = (attributeType: string): WorkerShift[] => {
    if (props.workerShifts) {
      const shiftsResult = []
      const businessesSeen: string[] = []

      const sortedShiftsByTime = _.orderBy(
        props.workerShifts,
        (shift) => shift.shiftInfo.startTime,
        'desc',
      ).filter((shift) =>
        [JobStatus.Complete, JobStatus.Abandoned, JobStatus.Rejected].includes(
          shift.jobStatus,
        ),
      )
      for (const shift of sortedShiftsByTime) {
        if (shift.canceledAt) {
          continue
        }
        if (
          shift.shiftInfo.requiredAttributes
            ?.map((requiredAttr) =>
              typeof requiredAttr === 'string'
                ? requiredAttr
                : requiredAttr.type,
            )
            .includes(attributeType)
        ) {
          if (!businessesSeen.includes(shift.shiftInfo.companyId)) {
            shiftsResult.push(shift)
            businessesSeen.push(shift.shiftInfo.companyId)
          }
        }
      }
      return shiftsResult
    }
    return []
  }

  const workerAttributes: WorkerProfileAttributeUIModel[] =
    workerProfileAttributes
      ?.filter((workerProfileAttribute) => {
        // remove those attributes that the worker
        // explicitly omitted
        if (workerProfileAttribute.status === WorkerProfileStatus.Reported) {
          return workerProfileAttribute.value === true
        }
        return true
      })
      .map((workerProfileAttribute) => {
        const roleAttributesMetrics = workerScores?.roleAttributes?.find(
          (roleAttributeMetric) => {
            return (
              roleAttributeMetric.type === workerProfileAttribute.profileField
            )
          },
        )
        return {
          attributeMetadata: workerProfileAttribute,
          displayName:
            roleAttributes?.find(
              (roleAttribute) =>
                roleAttribute.type === workerProfileAttribute.profileField,
            )?.displayName || workerProfileAttribute.profileField,
          status: workerProfileAttribute.status,
          key: workerProfileAttribute.profileField,
          shiftsCompleted: roleAttributesMetrics?.completed || 0,
          shiftsConsidered: roleAttributesMetrics?.considered || 0,
          ratings: {
            [FeedbackRating.Positive]:
              workerProfileAttribute.positiveAttributeFeedback || 0,
            [FeedbackRating.Neutral]: 0,
            [FeedbackRating.Negative]:
              workerProfileAttribute.negativeAttributeFeedback || 0,
          },
          favorited: roleAttributesMetrics?.favorited || 0,
          blocked: roleAttributesMetrics?.blocked || 0,
          lastUniqueBusinessShifts: getUniqueBusinessShifts(
            workerProfileAttribute.profileField,
          ),
        }
      }) || []

  const getStatusDisplay = (status: WorkerProfileStatus) => {
    switch (status) {
      case WorkerProfileStatus.Verified:
        return (
          <Row>
            <Icon name="greenCheck" />
            <Text style={{ marginLeft: 5 }}>Verified</Text>
          </Row>
        )
      case WorkerProfileStatus.Revoked:
        return (
          <Row>
            <Icon name="blocked_active" />
            <Text style={{ marginLeft: 5 }}>Revoked</Text>
          </Row>
        )
      case WorkerProfileStatus.Reported:
        return (
          <Row>
            <Icon name="userProfile" />
            <Text style={{ marginLeft: 5 }}>Reported</Text>
          </Row>
        )
      default:
        return <></>
    }
  }

  const tableRows: TableRow[] = workerAttributes?.map((workerAttribute) => {
    const displayName = workerAttribute.displayName
    const category = workerAttribute.attributeMetadata.category
    const status = workerAttribute.attributeMetadata.status
    const highRating = workerAttribute.ratings.POSITIVE
    const lowRating = workerAttribute.ratings.NEGATIVE
    const shiftsConsidered = workerAttribute.shiftsConsidered
    const shiftsCompeleted = workerAttribute.shiftsCompleted
    const favorited = workerAttribute.favorited
    const blocked = workerAttribute.blocked

    return {
      key: workerAttribute.attributeMetadata.profileField,
      cells: [
        {
          renderFn: () => displayName,
          sortKey: workerAttribute.attributeMetadata.profileField,
        },
        {
          renderFn: () => category,
          sortKey: category,
        },
        ...(!hotSettings?.allowStoringRequiredAttributeLevel
          ? [
              {
                renderFn: () => <Text center>{getStatusDisplay(status)}</Text>,
                sortKey: status,
              },
            ]
          : []),
        {
          renderFn: () => (
            <Row alignCenter center gap={9}>
              <Icon name="thumbsUp" type="svg" />
              <div>{highRating}</div>
              <div
                style={{
                  color: `${otherColors.Grey20}`,
                }}
              >
                /
              </div>
              <Icon name="thumbsDown" type="svg" />
              <div>{lowRating}</div>
            </Row>
          ),
          sortKey: highRating,
        },
        {
          sortKey: shiftsConsidered,
          renderFn: () => <Text center>{shiftsConsidered}</Text>,
        },
        {
          sortKey: shiftsCompeleted,
          renderFn: () => <Text center>{shiftsCompeleted}</Text>,
        },
        {
          sortKey: favorited,
          renderFn: () => <Text center>{favorited}</Text>,
        },
        {
          sortKey: blocked,
          renderFn: () => <Text center>{blocked}</Text>,
        },
        {
          renderFn: () => (
            <Row center alignCenter gap={9}>
              {workerAttribute.lastUniqueBusinessShifts
                .slice(0, 2)
                .map((shift) => {
                  return (
                    <JobStatusBadge
                      key={shift.shiftId}
                      jobStatus={shift.jobStatus}
                      additionalBadgeProps={{
                        short: true,
                        title: shift.shiftInfo.employerName,
                        badgeDetails: (
                          <ShiftTile
                            shiftId={shift.shiftId}
                            showCompanyName
                            showShortAddress
                            navigateToShiftDetailsOnClick
                            showJobStatus
                            jobStatus={shift.jobStatus}
                          />
                        ),
                      }}
                    />
                  )
                })}
            </Row>
          ),
        },
        ...(!hotSettings?.allowStoringRequiredAttributeLevel
          ? [
              {
                renderFn: () => (
                  <WorkerAttributeActions
                    workerId={workerId}
                    workerAttribute={workerAttribute}
                  />
                ),
              },
            ]
          : []),
      ],
    }
  })

  return (
    <DataTable
      rows={tableRows}
      headers={[
        {
          key: 'attribute',
          label: 'Attribute',
          sortable: true,
        },
        {
          key: 'category',
          label: 'Category',
          sortable: true,
        },
        ...(!hotSettings?.allowStoringRequiredAttributeLevel
          ? [
              {
                key: 'status',
                label: 'Status',
                sortable: true,
              },
            ]
          : []),
        {
          key: 'feedback',
          label: 'Feedback',
          centerLabel: true,
          info: '(Coming Soon) Attribute specific feedback by businesses',
        },

        {
          key: 'shiftsConsidered',
          label: 'Shifts Considered',
          centerLabel: true,
          info: 'Number of shifts where the worker demonstrated this attribute and which were completed successfully OR abandoned OR rejected',
          sortable: true,
        },
        {
          key: 'shiftsCompleted',
          label: 'Shifts Completed',
          centerLabel: true,
          info: 'Number of shifts where the worker demonstrated this attribute and which were completed successfully',
          sortable: true,
        },
        {
          key: 'favorited',
          label: 'Favorited',
          centerLabel: true,
          info: 'Number of businesses that have favorited the worker where the worker has demonstrated this attribute',
          sortable: true,
        },

        {
          key: 'blocked',
          label: 'Blocked',
          centerLabel: true,
          info: 'Number of businesses that have blocked the worker where the worker has demonstrated this attribute',
          sortable: true,
        },
        {
          key: 'lastTwoBusinesses',
          label: 'Last Two Businesses',
          centerLabel: true,
          info: 'Recent businesses the worker has been considered for a shift (completed, abandoned, rejected)',
        },
        ...(!hotSettings?.allowStoringRequiredAttributeLevel
          ? [
              {
                key: 'actions',
                label: '',
              },
            ]
          : []),
      ]}
    >
      <Tr>
        <Td>
          <WorkerAttributeAdder
            workerId={workerId}
            workerAttributes={workerProfileAttributes || []}
            roleAttributes={roleAttributes || []}
          />
        </Td>
      </Tr>
    </DataTable>
  )
}
