import { WORKER_DISTANCE_WARNING_LEVEL } from '@traba/consts'
import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ReactNode } from 'react'
import { AlertIcon } from 'src/assets/svg/icons/AlertIcon'

// TODO(sumeet-bansal): deduplicate this with biz app component
export const WorkerDistanceText = (workerDistance?: number): ReactNode => {
  if (!workerDistance) {
    return undefined
  }
  const text = `${workerDistance.toFixed(1)} mi`
  if (workerDistance > WORKER_DISTANCE_WARNING_LEVEL) {
    return (
      <Row
        gap={theme.space.xxxs}
        style={{ color: theme.colors.Red80 }}
        alignCenter
      >
        <AlertIcon />
        <span>{text}</span>
      </Row>
    )
  }
  return text
}
