export type Note = {
  noteId: string
  createdAt: Date
  author: string
  note: string
  updatedAt?: Date
  source: NoteSource
}

export enum NoteSource {
  Ops = 'OPS',
  Business = 'BUSINESS',
}

export type ShiftNote = Note & { shiftId: string }
export type CompanyNote = Note & { companyId: string }
export type WorkerNote = Note & { workerId: string }

export type WorkerNotesCount = {
  workerId: string
  notesCount: number
}
export type WorkersNotesCounts = {
  workersNotesCounts: WorkerNotesCount[]
}

export enum NoteType {
  Shift = 'shift',
  Company = 'company',
  Worker = 'worker',
}
