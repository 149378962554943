import { InfoTooltip } from '@traba/react-components'
import { PaidBackupSource, PaidBackupStatus } from '@traba/types'
import { Badge, Row } from 'src/components/base'
import { BadgeProps } from 'src/components/base/Badge'

export interface PaidBackupStatusBadgeProps {
  status: PaidBackupStatus
  source: PaidBackupSource
  cancellationReason?: string
  additionalBadgeProps?: Partial<BadgeProps>
}

const statusToBadgeTitle = {
  [PaidBackupStatus.ADDED_TO_SHIFT]: 'ADDED TO SHIFT',
  [PaidBackupStatus.DISMISSED]: 'DISMISSED WITH PAY',
  [PaidBackupStatus.CANCELED]: 'CANCELED',
  [PaidBackupStatus.TO_DO]: 'TODO',
  [PaidBackupStatus.WAITING_AT_SHIFT]: 'WAITING AT SHIFT',
  [PaidBackupStatus.NO_SHOW]: 'NO SHOW',
}

const sourceToTitle = {
  [PaidBackupSource.WORKER]: 'WKR',
  [PaidBackupSource.SYSTEM]: 'SYS',
  [PaidBackupSource.OPS]: 'OPS',
}

export function getBadgeTitle({ status, source }: PaidBackupStatusBadgeProps) {
  let badgeTitle = statusToBadgeTitle[status]

  if (
    source &&
    status in [PaidBackupStatus.CANCELED, PaidBackupStatus.WAITING_AT_SHIFT]
  ) {
    badgeTitle = `${sourceToTitle[source]} ${badgeTitle}`
  }
  return badgeTitle
}

export default function PaidBackupStatusBadge(
  props: PaidBackupStatusBadgeProps,
) {
  const { status, source, cancellationReason, additionalBadgeProps } = props

  const statusToBadgeColor = {
    [PaidBackupStatus.ADDED_TO_SHIFT]: 'success',
    [PaidBackupStatus.WAITING_AT_SHIFT]: 'warning',
    [PaidBackupStatus.TO_DO]: 'info',
    [PaidBackupStatus.CANCELED]: 'danger',
    [PaidBackupStatus.NO_SHOW]: 'danger',
    [PaidBackupStatus.DISMISSED]: 'business',
  }

  const badgeTitle = getBadgeTitle({ status, source })
  const badgeColor = statusToBadgeColor[status]

  return (
    <Row>
      <Badge
        title={badgeTitle}
        variant={badgeColor}
        {...additionalBadgeProps}
      />
      {status === PaidBackupStatus.CANCELED && cancellationReason && (
        <InfoTooltip title={`Reason: ${cancellationReason}`} />
      )}
    </Row>
  )
}
