import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import {
  NotificationSettingsLevel,
  NotificationStatus,
  SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY,
  SentinelNotification,
  SentinelNotificationForShiftsResponse,
  ShiftNotificationSettingsForShift,
  UpdateNotificationSettings,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import { Button, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircleDiv } from 'src/components/base/Icon'
import { FIVE_MINUTES_IN_MS, ONE_MINUTE_IN_MS } from 'src/libs/constants'
import { truncateString } from 'src/utils/stringUtils'
import { SentinelEventTypeToToastSettingsMap } from '../utils/sentinel'
import { getQueryParams } from './useApi'
import { SEARCH_SHIFTS_QUERY_KEY } from './useShifts'

export const SENTINEL_NOTIFICATION_QUERY_KEY = 'sentinelNotifications'
export const SENTINEL_QUERY_BY_SHIFTS_KEY = 'shiftsSentinelNotifications'
export const SENTINEL_QUERY_BY_USER_KEY = 'userSentinelNotifications'
export const SENTINEL_NOTIFICATION_QUERY_BY_ID_KEY = 'sentinelNotification'
const SENTINEL_NOTIFICATION_SETTINGS_QUERY_KEY = 'sentinelNotificationSettings'

export type SentinelQueryKeyParams = {
  internalUserId?: string
  limit?: number
  startAt?: number
  shiftIds?: string[]
}

type SentinelNotificationPaginationProps = {
  limit?: number
  startAt?: number
}

export function matchesSentinelQueryByShifts(queryKey: any, shiftId: string) {
  return (
    queryKey[0] === SENTINEL_NOTIFICATION_QUERY_KEY &&
    queryKey[1] === SENTINEL_QUERY_BY_SHIFTS_KEY &&
    !!(queryKey[2] as SentinelQueryKeyParams).shiftIds?.includes(shiftId)
  )
}

export function matchesSentinelQueryByUser(queryKey: any) {
  return (
    queryKey[0] === SENTINEL_NOTIFICATION_QUERY_KEY &&
    queryKey[1] === SENTINEL_QUERY_BY_USER_KEY
  )
}

export function invalidateSentinelQueries(
  queryClient: QueryClient,
  shiftId: string,
) {
  queryClient.invalidateQueries({
    predicate: (query) =>
      matchesSentinelQueryByShifts(query.queryKey, shiftId) ||
      matchesSentinelQueryByUser(query.queryKey),
  })
}

export function invalidateSentinelQueryByShifts(
  queryClient: QueryClient,
  shiftId: string,
) {
  queryClient.invalidateQueries({
    predicate: (query) => matchesSentinelQueryByShifts(query.queryKey, shiftId),
  })
}

async function getBulkSentinelNotificationSettings({
  shiftIds,
  internalUserId,
}: {
  shiftIds: string[]
  internalUserId?: string
}) {
  if (!shiftIds) {
    return
  }

  try {
    const response = await trabaApi.post(
      `/sentinel/bulk-notification-settings`,
      {
        shiftIds,
        internalUserId,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return
  }
}

async function getSentinelNotifications({
  limit,
  startAt,
  shiftIds,
  internalUserId,
}: SentinelNotificationPaginationProps & {
  shiftIds?: string[]
  internalUserId?: string
}): Promise<SentinelNotificationForShiftsResponse | undefined> {
  if (!internalUserId) {
    return
  }

  try {
    const queryString = getQueryParams([
      ['startAt', startAt ? startAt.toString() : undefined],
      ['limit', limit ? limit.toString() : undefined],
    ])

    const response = await trabaApi.post(
      `/sentinel/query-notifications${queryString}`,
      {
        shiftIds,
        includeSilencedShifts: true,
        internalUserId,
        includedStatuses: [
          NotificationStatus.PENDING,
          NotificationStatus.SENT,
          NotificationStatus.READ,
          NotificationStatus.ACTIONED,
          NotificationStatus.SEND_FAILED,
        ],
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return
  }
}

async function getSentinelNotificationForId(
  notificationId: string,
  internalUserId: string,
): Promise<SentinelNotification | undefined> {
  try {
    const response = await trabaApi.get(
      `/sentinel/notifications/${notificationId}?internalUserId=${internalUserId}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return
  }
}

async function updateSentinelNotificationSettings(
  notificationSettingsUpdate: UpdateNotificationSettings,
) {
  const { settingsLevel, internalUserId, shiftId, notificationSettings } =
    notificationSettingsUpdate
  const url =
    settingsLevel === NotificationSettingsLevel.SHIFT
      ? `/sentinel/${shiftId}/shift-notification-settings`
      : `/sentinel/${shiftId}/internal-user-settings/${internalUserId}`

  try {
    await trabaApi.post(url, notificationSettings)
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    throw error
  }
}

async function dismissSentinelNotification({
  notificationId,
}: {
  notificationId: string
}) {
  try {
    await trabaApi.post(`/sentinel/notifications`, {
      id: notificationId,
      status: NotificationStatus.DISMISSED,
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function useSentinelNotificationsForShifts({
  shiftIds,
  internalUserId,
  limit,
  startAt,
  enabled = true,
}: SentinelNotificationPaginationProps & {
  internalUserId?: string
  shiftIds?: string[]
  enabled?: boolean
}) {
  // TODO(joey): add pagination and modify as useInfiniteQuery
  const queryKeyParams: SentinelQueryKeyParams = {
    limit,
    startAt,
    internalUserId,
    shiftIds,
  }

  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<
    SentinelNotificationForShiftsResponse | undefined,
    Error
  >(
    [
      SENTINEL_NOTIFICATION_QUERY_KEY,
      shiftIds ? SENTINEL_QUERY_BY_SHIFTS_KEY : SENTINEL_QUERY_BY_USER_KEY,
      queryKeyParams,
    ],
    () =>
      getSentinelNotifications({
        limit,
        startAt,
        shiftIds,
        internalUserId,
      }),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: !!internalUserId && enabled,
      refetchOnWindowFocus: 'always',
    },
  )
  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
    refetch,
  }
}

export function useSentinelNotificationForId({
  notificationId,
  internalUserId,
}: {
  notificationId: string
  internalUserId: string
}) {
  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<
    SentinelNotification | undefined,
    Error
  >(
    [SENTINEL_NOTIFICATION_QUERY_BY_ID_KEY, { notificationId, internalUserId }],
    () => getSentinelNotificationForId(notificationId, internalUserId),
    {
      enabled: !!notificationId,
    },
  )

  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
    refetch,
  }
}

export function useBulkSentinelNotificationSettings({
  shiftIds,
  internalUserId,
  enabled = true,
}: {
  shiftIds: string[]
  internalUserId?: string
  enabled?: boolean
}) {
  const { isLoading, isError, data, error, isFetched, refetch } = useQuery<
    Record<string, ShiftNotificationSettingsForShift>,
    Error
  >(
    [SENTINEL_NOTIFICATION_SETTINGS_QUERY_KEY, { shiftIds, internalUserId }],
    () => getBulkSentinelNotificationSettings({ shiftIds, internalUserId }),
    {
      enabled: !!shiftIds.length && !!internalUserId && enabled,
      keepPreviousData: true,
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
    refetch,
  }
}

export function useSentinelMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const updateNotificationSettingsMutation = useMutation<
    any,
    AxiosError,
    UpdateNotificationSettings
  >(updateSentinelNotificationSettings, {
    onSuccess: (_response: any, variables: UpdateNotificationSettings) => {
      showSuccess(`Successfully updated notification settings`)

      invalidateSentinelQueryByShifts(queryClient, variables.shiftId)

      // TODO(JOEY): optimistic update cache for all notifs & cache for shift notif

      // Invalidate query cache for notification settings
      queryClient.invalidateQueries([SENTINEL_NOTIFICATION_SETTINGS_QUERY_KEY])
    },
    onError: (error) => {
      showError(JSON.stringify(error), 'Error muting notification')
    },
  })

  const dismissNotificationMutation = useMutation<
    any,
    AxiosError,
    { notificationId: string; shiftId: string }
  >(dismissSentinelNotification, {
    onSuccess: (_response: any, variables) => {
      showSuccess(`Successfully dismissed notification`)

      invalidateSentinelQueryByShifts(queryClient, variables.shiftId)
      queryClient.invalidateQueries([
        SENTINEL_NOTIFICATION_QUERY_BY_ID_KEY,
        variables.notificationId,
      ])

      // Invalidate field monitor cache to trigger refetch.
      // TODO(Joey) possibly optimistic update here with setQueryData to avoid a refetch
      queryClient.invalidateQueries([SEARCH_SHIFTS_QUERY_KEY])
    },
    onError: (error) => {
      showError(error.message, 'Error dismissing notification')
    },
  })

  return {
    updateNotificationSettings: updateNotificationSettingsMutation.mutateAsync,
    dismissSentinelNotification: dismissNotificationMutation.mutateAsync,
  }
}

export function useSentinelNotificationToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const showNewSentinelNotificationToast = (
    notification?: SentinelNotification,
  ) => {
    if (!notification) {
      return
    }
    window.analytics.track('Sentinel Notification Toast Enqueued', {
      notification,
    })
    const storedSettings = parseInt(
      localStorage.getItem(SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY) ??
        '0',
    )
    if (
      (storedSettings &
        SentinelEventTypeToToastSettingsMap[notification.eventType]) ===
      0
    ) {
      return
    }

    enqueueSnackbar(truncateString(notification?.message, 36), {
      style: {
        backgroundColor: theme.colors.Violet20,
        color: theme.colors.MidnightBlue,
        maxWidth: '500px',
      },
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      onClose: () => {
        return
      },
      action: (key) => {
        window.analytics.track('Sentinel Notification Toast Displayed', {
          notification,
        })
        return (
          <Row alignCenter mr={theme.space.xxs}>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                window.analytics.track('Sentinel Notification Toast Clicked', {
                  notification,
                })
                window.open(`/field-monitor/${notification.relatedShiftId}`)
              }}
              slim
            >
              View Shift
            </Button>
            <CircleDiv
              onClick={() => {
                window.analytics.track(
                  'Sentinel Notification Toast Dismissed',
                  {
                    notification,
                  },
                )
                closeSnackbar(key)
              }}
            >
              <Icon name="cancel" />
            </CircleDiv>
          </Row>
        )
      },
    })
  }
  return showNewSentinelNotificationToast
}
