import { trabaApi } from '@traba/api-utils'
import { useQuery } from 'react-query'
import { WardenResult } from 'src/types/warden'

async function getWardenResult(
  workerId: string,
  shiftId: string,
): Promise<WardenResult | undefined> {
  try {
    const res = await trabaApi.get(
      `warden/${workerId}/worker-shifts/${shiftId}/warden-result`,
    )
    return res.data
  } catch (error) {
    console.error('useWardenResults -> getWardenResult() ERROR', error)
  }
}

export function useWardenResult(workerId: string, shiftId: string) {
  const {
    isLoading,
    isError,
    data: wardenResult,
    error,
    isFetched,
    refetch,
  } = useQuery<WardenResult | undefined, Error>(
    ['warden_result', { workerId, shiftId }],
    () => getWardenResult(workerId, shiftId),
    {},
  )

  return {
    isLoading,
    isError,
    wardenResult,
    error,
    isFetched,
    refetch,
  }
}
