import './App.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { CircularProgress } from '@mui/material'
import { SegmentAnalytics } from '@segment/analytics.js-core'
import { useDisableNumberInputScroll } from '@traba/hooks'
import { theme } from '@traba/theme'
import { useEffect } from 'react'
import Col from './components/base/Col'
import Row from './components/base/Row'
import { Toast } from './components/Toast/Toast'
import { useUserContext } from './context/user/UserContext'
import { useGoogleAuth } from './hooks/useAuth'
import LoggedIn from './routes/LoggedIn'
import LoggedOut from './routes/LoggedOut'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    Meticulous?: {
      isRunningAsTest: boolean
    }
  }
}

function App() {
  const userContext = useUserContext()
  const { isLoadingUser, isUserLoggedIn, userProfile } = userContext.state
  const { handleAuthStateChanged } = useGoogleAuth()
  useDisableNumberInputScroll()

  /**
   * Adds onAuthStateChanged listener that checks if user is
   * logged in and sets user context accordingly
   */
  useEffect(() => {
    handleAuthStateChanged()
    window.addEventListener('focus', handleAuthStateChanged)
    return () => window.removeEventListener('focus', handleAuthStateChanged)
    // eslint-disable-next-line
  }, [])

  if (isLoadingUser || (isUserLoggedIn && !userProfile)) {
    return (
      <Col style={{ height: '100%' }}>
        <Row justifyCenter style={{ height: '100%', alignItems: 'center' }}>
          <CircularProgress size={theme.space.xxxl} />
        </Row>
      </Col>
    )
  }

  return (
    <div className="App">
      {isUserLoggedIn ? <LoggedIn /> : <LoggedOut />}
      <Toast />
    </div>
  )
}

export default App
