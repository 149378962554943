import Drawer from '@mui/material/Drawer'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import WorkerCard from '../WorkerCard/WorkerCard'
import * as S from './styles'

interface SearchConfigProps {
  isOpen: boolean
  onClose: () => void
  worker: PopulatedWorker
}

const WorkerDrawer = ({ isOpen, onClose, worker }: SearchConfigProps) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <S.DrawerContainer>
        {worker && <WorkerCard worker={worker} />}
        <S.ExpandedWrapper>
          This space can be used for more actions or to display more data, share
          your ideas!
        </S.ExpandedWrapper>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default WorkerDrawer
