import { Checkbox, LinearProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AcceptShiftBypassSetting } from '@traba/types'
import { InitialAcceptShiftBypasses } from '@traba/types'
import { GenericAcceptShiftBypasses, Shift } from '@traba/types'
import { WorkerShiftEligibility } from '@traba/types'
import { Worker } from '@traba/types'
import { Col, Row } from 'src/components/base'
import { useHotSettings } from '../../../../hooks/useHotSettings'
import useTimezonedDates from '../../../../hooks/useTimezonedDates'
import AcceptShiftBypass from '../../../../modals/AcceptShiftModal/components/AcceptShiftBypass'
import * as S from '../../styles'
import { WorkerOnShift } from './types'

const getWorkerId = (worker: Worker) => worker.id || worker.workerId

export type WorkersWithFailedEligibilities = Record<
  string,
  { failedCriteria: string[] }
>

function getWorkerFailedEligibilitiesSet(
  workerId: string,
  eligibilities: WorkerShiftEligibility[],
) {
  const workerEligibilities = eligibilities.filter(
    (eligibility) => eligibility.workerId === workerId,
  )

  const failedCriteria = Array.from(
    new Set<string>(
      workerEligibilities.flatMap((el) =>
        Object.entries(el.eligibilityCriteria)
          .filter(([_, value]) => !value)
          .map(([key]) => key),
      ),
    ),
  )

  return {
    failedCriteria,
  }
}

interface LineItemProps {
  isSelected: boolean
  handleRowClick: () => void
  conflictShiftId: string
  shift: Shift
  workerAlreadyOnShift: boolean
  workerAlreadySignedUpForPaidBackup: boolean
}

const LineItem: React.FC<LineItemProps> = ({
  isSelected,
  handleRowClick,
  conflictShiftId,
  shift,
  workerAlreadyOnShift,
  workerAlreadySignedUpForPaidBackup,
}) => {
  const tz = useTimezonedDates(shift.timezone)
  const rowStyle = {
    cursor: 'pointer',
    backgroundColor: isSelected ? theme.colors.Grey10 : 'transparent',
  }

  return (
    <Row
      flexCol
      py={theme.space.xxs}
      style={{ borderBottom: `1px solid ${theme.colors.Grey20}` }}
    >
      <Text
        variant="brand"
        mr={theme.space.xs}
        style={workerAlreadyOnShift ? { textDecoration: 'line-through' } : {}}
      >
        {new Date(tz.getDate(shift.startTime)).toDateString()} - {shift.shiftId}
      </Text>
      {!workerAlreadyOnShift || workerAlreadySignedUpForPaidBackup ? (
        <Row
          key={`${conflictShiftId}_conflict`}
          px={theme.space.xs}
          justifyBetween
          alignCenter
          onClick={handleRowClick}
          style={rowStyle}
        >
          <Text variant="caption">
            {workerAlreadySignedUpForPaidBackup &&
            shift.shiftId === conflictShiftId
              ? 'Worker already signed up as a paid backup'
              : `Conflicts with worker's shift ${conflictShiftId}`}
          </Text>
          <Text variant="caption">
            Cancel? <Checkbox checked={isSelected || false} />
          </Text>
        </Row>
      ) : (
        <Text variant="caption">Skipping, worker already on this shift</Text>
      )}
    </Row>
  )
}
const ConflictingShifts = ({
  shiftsWithConflict = [],
  workerShiftsToCancel,
  handleAddToCancel,
  workerId,
  shifts,
}: {
  shiftsWithConflict?: WorkerShiftEligibility[]
  workerShiftsToCancel: { workerId: string; shiftId: string }[]
  handleAddToCancel: (
    workerId: string,
    shiftId: string,
    paidBackup: boolean,
  ) => void
  workerId: string
  shifts: Shift[]
}) => {
  return (
    <ul>
      <Text variant="label">Conflicting shifts:</Text>
      {shiftsWithConflict.map(
        ({
          shiftId,
          conflictingShiftIds,
          workerAlreadySignedUpForShift,
          workerAlreadySignedUpForPaidBackup,
        }) => {
          const shift = shifts.find((s) => s.shiftId === shiftId)
          if (!shift) {
            return null
          }
          return (
            <Row flexCol key={`${shiftId}_${workerId}_list_item`}>
              {conflictingShiftIds?.map((conflictShiftId) => {
                const isSelected = workerShiftsToCancel.some(
                  (cancelShift) =>
                    cancelShift.workerId === workerId &&
                    cancelShift.shiftId === conflictShiftId,
                )

                const handleRowClick = () =>
                  handleAddToCancel(
                    workerId,
                    conflictShiftId,
                    workerAlreadySignedUpForPaidBackup &&
                      conflictShiftId === shiftId,
                  )

                return (
                  <LineItem
                    key={conflictShiftId}
                    isSelected={isSelected}
                    handleRowClick={handleRowClick}
                    conflictShiftId={conflictShiftId}
                    shift={shift}
                    workerAlreadyOnShift={workerAlreadySignedUpForShift}
                    workerAlreadySignedUpForPaidBackup={
                      workerAlreadySignedUpForPaidBackup
                    }
                  />
                )
              })}
            </Row>
          )
        },
      )}
    </ul>
  )
}

const WorkerEligibilityCard = ({
  worker,
  workerShiftsToCancel,
  handleAddToCancel,
  shifts,
  eligibilityData,
  acceptShiftBypasses,
}: {
  worker: Worker
  workerShiftsToCancel: { workerId: string; shiftId: string }[]
  handleAddToCancel: (
    workerId: string,
    shiftId: string,
    paidBackup: boolean,
  ) => void
  shifts: Shift[]
  eligibilityData: WorkerShiftEligibility[]
  acceptShiftBypasses: AcceptShiftBypassSetting[]
}) => {
  const workerId = getWorkerId(worker)
  const parsedWorkerEligibility = getWorkerFailedEligibilitiesSet(
    workerId,
    eligibilityData,
  )

  const uniqueFailedCriteria =
    parsedWorkerEligibility && parsedWorkerEligibility.failedCriteria?.length
      ? Array.from(new Set(parsedWorkerEligibility.failedCriteria))
      : []

  const shiftsWithConflict = eligibilityData.filter(
    (el) => el.workerId === workerId && el.conflictingShiftIds?.length,
  )

  return (
    <S.WorkerEligibilityCard key={workerId}>
      <Row fullWidth>
        <Text variant="h7">
          {worker.firstName} {worker.lastName} id: {workerId}
        </Text>
      </Row>
      {parsedWorkerEligibility.failedCriteria?.length ? (
        <Row flexCol ml={theme.space.xxs} pt={theme.space.xxs}>
          {uniqueFailedCriteria.map((el) => (
            <Text key={el} variant="error" style={{ fontWeight: 500 }}>
              {
                acceptShiftBypasses.find(
                  (bypass) => bypass.eligibilityCriteria === el,
                )?.label
              }
            </Text>
          ))}
          {!!shiftsWithConflict.length && (
            <ConflictingShifts
              workerId={workerId}
              shifts={shifts}
              shiftsWithConflict={shiftsWithConflict}
              workerShiftsToCancel={workerShiftsToCancel}
              handleAddToCancel={handleAddToCancel}
            />
          )}
        </Row>
      ) : (
        <Text variant="body1">
          Eligibility Status: <Text variant="brand">OK</Text>
        </Text>
      )}
    </S.WorkerEligibilityCard>
  )
}

function getShiftsFull(shifts: Shift[]) {
  return shifts.filter((s) => s.slotsFilled >= s.slotsRequested)
}

type WorkerRestrictionsReviewProps = {
  workers: Worker[]
  shifts: Shift[]
  workerShiftsToCancel: { workerId: string; shiftId: string }[]
  setWorkerShiftsToCancel: React.Dispatch<React.SetStateAction<WorkerOnShift[]>>
  eligibilityData: WorkerShiftEligibility[]
  bypasses: GenericAcceptShiftBypasses
  setBypasses: React.Dispatch<React.SetStateAction<GenericAcceptShiftBypasses>>
  hasUncheckedBypasses: boolean
  hasRequiredMultiShift: boolean
}

const WorkersRestrictionsReview = ({
  workers,
  shifts,
  workerShiftsToCancel,
  setWorkerShiftsToCancel,
  eligibilityData,
  bypasses,
  setBypasses,
  hasUncheckedBypasses,
  hasRequiredMultiShift,
}: WorkerRestrictionsReviewProps) => {
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()

  const bypassSettings =
    hotSettings?.acceptShiftBypasses &&
    hotSettings?.acceptShiftBypasses.length > 0
      ? hotSettings?.acceptShiftBypasses
      : InitialAcceptShiftBypasses

  const handleAddToCancelList = (
    workerId: string,
    shiftId: string,
    paidBackup: boolean,
  ) => {
    setWorkerShiftsToCancel((prevShifts) => {
      const shiftIndex = prevShifts.findIndex(
        (shift) => shift.workerId === workerId && shift.shiftId === shiftId,
      )
      if (shiftIndex > -1) {
        return prevShifts.filter((_, index) => index !== shiftIndex)
      } else {
        return [...prevShifts, { workerId, shiftId, paidBackup }]
      }
    })
  }

  if (isLoadingHotSettings) {
    return <LinearProgress />
  }
  if (!hotSettings) {
    return <Text variant="error">No hot settings found</Text>
  }

  const shiftsFull = getShiftsFull(shifts)

  return (
    <Col>
      <Text variant="h5">Workers Eligibility</Text>
      <Text variant="label">
        This list shows all the eligibility restrictions for each worker
        selected for all selected shifts, to check each worker individual
        eligibility on a specific shift please search the worker directly on the
        Shift Details screen.
      </Text>
      <Row ml={theme.space.xxs} flexCol>
        <S.WorkersListWrapper>
          {workers.map((worker) => (
            <WorkerEligibilityCard
              key={`${worker.firstName}-${worker.lastName}`}
              worker={worker}
              shifts={shifts}
              eligibilityData={eligibilityData}
              workerShiftsToCancel={workerShiftsToCancel}
              handleAddToCancel={handleAddToCancelList}
              acceptShiftBypasses={hotSettings.acceptShiftBypasses || []}
            />
          ))}
        </S.WorkersListWrapper>
      </Row>

      {shiftsFull.length > 0 && (
        <Row flexCol fullWidth px={theme.space.xs} py={theme.space.xxs}>
          <Text variant="h5" mt={theme.space.xs}>
            The following shifts are full
          </Text>
          {shiftsFull.map((s) => (
            <Text>{s.shiftId}</Text>
          ))}
        </Row>
      )}

      <Row flexCol fullWidth px={theme.space.xs} py={theme.space.xxs}>
        <Text variant="h5" mt={theme.space.xs}>
          Select the bypasses and options that will be applied to all selected
          workers
        </Text>
        {hasUncheckedBypasses && (
          <Text variant="error">
            You must accept all bypasses before continuing
          </Text>
        )}

        <AcceptShiftBypass
          shifts={shifts}
          bypasses={bypasses}
          setBypasses={setBypasses}
          eligibilityData={eligibilityData}
          acceptShiftBypassSettings={bypassSettings}
          hasRequiredMultiShift={hasRequiredMultiShift}
        />
      </Row>
    </Col>
  )
}

export { WorkersRestrictionsReview }
