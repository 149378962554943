export function More({
  color = '#7A8A99',
  size = 16,
}: {
  size: number
  color: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 8C0.25 6.76079 1.26079 5.75 2.5 5.75C3.73921 5.75 4.75 6.76079 4.75 8C4.75 9.23921 3.73921 10.25 2.5 10.25C1.26079 10.25 0.25 9.23921 0.25 8ZM2.5 7.25C2.08921 7.25 1.75 7.58921 1.75 8C1.75 8.41079 2.08921 8.75 2.5 8.75C2.91079 8.75 3.25 8.41079 3.25 8C3.25 7.58921 2.91079 7.25 2.5 7.25ZM11.25 8C11.25 6.76079 12.2608 5.75 13.5 5.75C14.7392 5.75 15.75 6.76079 15.75 8C15.75 9.23921 14.7392 10.25 13.5 10.25C12.2608 10.25 11.25 9.23921 11.25 8ZM13.5 7.25C13.0892 7.25 12.75 7.58921 12.75 8C12.75 8.41079 13.0892 8.75 13.5 8.75C13.9108 8.75 14.25 8.41079 14.25 8C14.25 7.58921 13.9108 7.25 13.5 7.25ZM8 5.75C6.76079 5.75 5.75 6.76079 5.75 8C5.75 9.23921 6.76079 10.25 8 10.25C9.23921 10.25 10.25 9.23921 10.25 8C10.25 6.76079 9.23921 5.75 8 5.75ZM7.25 8C7.25 7.58921 7.58921 7.25 8 7.25C8.41079 7.25 8.75 7.58921 8.75 8C8.75 8.41079 8.41079 8.75 8 8.75C7.58921 8.75 7.25 8.41079 7.25 8Z"
        fill={color}
      />
    </svg>
  )
}
