export enum CustomizedBadgeVariant {
  VIOLET = 'VIOLET',
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export enum ShiftBadgeStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type ShiftBadge = {
  id: string
  createdAt: Date
  updatedAt?: Date
  badgeName: string
  shortDescription: string
  longDescription?: string
  variant: CustomizedBadgeVariant
  status: ShiftBadgeStatus
}
