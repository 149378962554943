import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import {
  ForwardFillRenotificationMethod,
  ShiftNotificationStatus,
} from '@traba/types'
import { WorkerShiftSignupNotification } from '@traba/types'
import { Shift } from '@traba/types'
import { TierLevel } from '@traba/types'
import { Worker } from '@traba/types'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { Badge, Button, Col, Row, Table } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Pagination from 'src/components/base/Pagination/Pagination'
import FilterFields from 'src/components/FilterFields/FilterFields'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useFilteredData from 'src/hooks/useFilteredData'
import { DEFAULT_PAGE_SIZE, useServerPagination } from 'src/hooks/usePagination'
import { useShiftNotificationStatus } from 'src/hooks/useShifts'
import { notificationsTableFilters } from './NotificationsTable.filters'
import NotificationsTableRow from './NotificationsTableRow'
import NotificationsTableSkeleton from './NotificationsTableSkeleton'

export type NotificationsTableProps = {
  shift: Shift
}

type WorkerShiftSignupNotificationRes = {
  notification: WorkerShiftSignupNotification
  worker: Worker
  workerTierLevel: TierLevel
}

export default function NotificationsTable({ shift }: NotificationsTableProps) {
  const { forwardFillRenotificationMethod, shiftId } = shift
  const { notificationStatus } = useShiftNotificationStatus(shiftId)
  const shiftStartTimeIsInThePast = isAfter(
    new Date(),
    new Date(shift.startTime),
  )

  const tableHeaders = [
    { label: 'Index', key: 'immutableIndex' },
    { label: 'Attribute List Index', key: 'attributeListIndex' },
    { label: 'Worker Name', key: 'worker.firstName' },
    { label: 'Worker Id', key: 'worker.workerId' },
    { label: 'Worker Tier', key: 'workerTierLevel' },
    { label: 'Worker Phone', key: 'worker.phoneNumber' },
    { label: 'Status', key: 'status' },
    { label: 'Sent at', key: 'sentAt' },
    { label: 'Last Send Attempted At', key: 'lastSendAttemptedAt' },
    ...(forwardFillRenotificationMethod === ForwardFillRenotificationMethod.SMS
      ? [{ label: 'SMS Follow Up Sent At', key: 'smsFollowUpSentAt' }]
      : []),
    { label: '' },
  ]

  const DEFAULT_SORTING_KEY = 'immutableIndex'
  const { showSuccess, showError } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()

  const [isLoading, setIsLoading] = useState({
    playOrPauseNotifications: false,
    restartNotifications: false,
  })

  const {
    data: notificationsData,
    isLoading: isLoadingNotifications,
    currentPage,
    goToNextPage,
    goToPreviousPage,
  } = useServerPagination<WorkerShiftSignupNotificationRes>(
    `/worker-shift-signup-notifications/${shiftId}`,
    DEFAULT_SORTING_KEY,
  )

  const notificationsFilter = useFilteredData<WorkerShiftSignupNotificationRes>(
    notificationsData,
    notificationsTableFilters,
  )

  async function sendNotification(notificationId: string) {
    try {
      const isConfirmed = window.confirm('Confirm send notification?')
      if (!isConfirmed) {
        return
      }
      await trabaApi.post(
        `/worker-shift-signup-notifications/${notificationId}/send`,
      )
      showSuccess('Notification was successfully sent', 'Success!')
    } catch (err: any) {
      showError(err.message, 'There was an error sending the notification')
    }
  }

  async function cancelNotification(notificationId: string) {
    try {
      const isConfirmed = window.confirm('Confirm cancel notification?')
      if (!isConfirmed) {
        return
      }
      await trabaApi.post(
        `/worker-shift-signup-notifications/${notificationId}/cancel`,
      )
      showSuccess('Notification was successfully canceled', 'Success!')
    } catch (err: any) {
      showError(err.message, 'There was an error canceling the notification')
    }
  }

  async function playOrPauseNotifications() {
    try {
      setIsLoading({ ...isLoading, playOrPauseNotifications: true })
      const updatedNotificationStatus =
        shift.notificationStatus === ShiftNotificationStatus.DISALLOWED
          ? ShiftNotificationStatus.ALLOWED
          : ShiftNotificationStatus.DISALLOWED

      await trabaApi.patch(`/shifts?shiftIds=${shiftId}`, {
        shiftUpdates: {
          notificationStatus: updatedNotificationStatus,
        },
      })
      showSuccess('Notification status successfully updated', 'Success!')
    } catch (err: any) {
      showError(err, 'Error updating shift notifications status')
    } finally {
      await refetchActiveQueries()
      setIsLoading({ ...isLoading, playOrPauseNotifications: false })
    }
  }

  async function restartNotifications() {
    try {
      setIsLoading({ ...isLoading, restartNotifications: true })
      await trabaApi.post(`/shifts/${shiftId}/reschedule-forward-fill-jobs`, {
        overrideListWriteTime: new Date().toISOString(),
      })
      showSuccess('Notifications sucessfully restarted.', 'Success!')
    } catch (err: any) {
      showError(err, 'Error restarting notifications')
    } finally {
      await refetchActiveQueries()
      setIsLoading({ ...isLoading, restartNotifications: false })
    }
  }

  if (!isLoading && (!notificationsData || notificationsData.length === 0)) {
    return null
  }

  return (
    <>
      <Row mt={60} mb={24}>
        <Col lg={8}>
          <Row alignCenter>
            <Text mr={10} variant="h4">
              Notifications
            </Text>
            <Badge
              style={{ margin: 0 }}
              title={shift.notificationStatus ?? 'Allowed'}
              variant={
                shift.notificationStatus === ShiftNotificationStatus.DISALLOWED
                  ? 'danger'
                  : 'info'
              }
            />
          </Row>
          <Row>
            <Text variant="h7">
              {notificationStatus
                ? `Current: ${notificationStatus.startIndexInclusive}/${notificationStatus.numWorkersInList}`
                : 'Not enabled'}
            </Text>
          </Row>
        </Col>
        <Col>
          <Row>
            <Button
              disabled={shiftStartTimeIsInThePast}
              loading={isLoading.playOrPauseNotifications}
              variant={ButtonVariant.OUTLINED}
              slim
              onClick={playOrPauseNotifications}
            >
              {shift.notificationStatus === ShiftNotificationStatus.DISALLOWED
                ? 'Play'
                : 'Pause'}
            </Button>
            <Button
              disabled={shiftStartTimeIsInThePast}
              loading={isLoading.restartNotifications}
              variant={ButtonVariant.OUTLINED}
              slim
              ml={5}
              onClick={restartNotifications}
            >
              Restart
            </Button>
            <FilterFields {...notificationsFilter} />
          </Row>
        </Col>
      </Row>
      <Row mb={30}>
        <Pagination
          page={currentPage}
          pageSize={DEFAULT_PAGE_SIZE}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          dataSize={
            notificationsFilter.filteredData?.length || DEFAULT_PAGE_SIZE
          }
        />
      </Row>

      {isLoadingNotifications ? (
        <NotificationsTableSkeleton
          headers={tableHeaders.map((header) => header.label)}
        />
      ) : (
        <Table headers={tableHeaders}>
          {notificationsFilter.filteredData?.map((notificationEntry) => (
            <NotificationsTableRow
              {...notificationEntry}
              timezone={shift.timezone}
              forwardFillRenotificationMethod={forwardFillRenotificationMethod}
              handlePressCancelNotification={cancelNotification}
              handlePressSendNotification={sendNotification}
              key={notificationEntry.worker.id}
            />
          ))}
        </Table>
      )}
    </>
  )
}
