import { Button, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerCertification } from '@traba/types'
import { useState } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import ImagesModal from 'src/components/base/ImagesModal/ImagesModal'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import { useApi } from 'src/hooks/useApi'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { WorkerCertificationsAddModal } from './WorkerCertificationsAddModal'

interface WorkerCertificationsProps {
  workerId: string
}

export default function WorkerCertifications({
  workerId,
}: WorkerCertificationsProps) {
  const imagesModal = useModal()
  const {
    isLoading: isLoadingCertifications,
    data: workerCertifications,
    refetch,
  } = useApi<WorkerCertification[]>(`workers/${workerId}/worker-certifications`)
  const [selectedImageUrls, setSelectedImageUrls] = useState<
    string[] | undefined
  >()
  const [selectedImageTitle, setSelectedImageTitle] = useState<
    string | undefined
  >()
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  function handlePressCertificationImage(
    imageTitle: string,
    imageUrls: string[],
  ) {
    setSelectedImageUrls(imageUrls)
    setSelectedImageTitle(imageTitle)
    imagesModal.open()
  }

  if (isLoadingCertifications) {
    return <CircularProgress size="medium" />
  }

  const tableRows: TableRow[] =
    workerCertifications?.map((workerCertification) => {
      const {
        certificationId,
        certificationType,
        createdAt,
        expirationDate,
        imageUrl,
        imageUrls,
        issueDate,
        status,
      } = workerCertification
      const existingImages = imageUrls?.length
        ? imageUrls
        : imageUrl
          ? [imageUrl]
          : []
      const createdAtTime = new Date(createdAt ?? '').getTime()
      const issueDateTime = new Date(issueDate).getTime()
      const expirationDateTime = new Date(expirationDate ?? '').getTime()

      return {
        key: workerCertification.certificationId,
        cells: [
          {
            renderFn: () => (
              <>
                {truncateString(certificationId, 6)}
                <CopyTextIcon textToCopy={certificationId} />
              </>
            ),
            sortKey: certificationId,
          },
          {
            renderFn: () => (
              <img
                onClick={() =>
                  handlePressCertificationImage(
                    workerCertification.certificationType,
                    existingImages,
                  )
                }
                style={{
                  borderRadius: theme.shape.borderRadius,
                  cursor: 'pointer',
                }}
                src={existingImages[0]}
                alt=""
                height={30}
              />
            ),
          },
          {
            renderFn: () => certificationType,
            sortKey: certificationType,
          },
          {
            renderFn: () => status,
            sortKey: status,
          },
          {
            renderFn: () => formatDateTimeWithTimezone(createdAt),
            sortKey: createdAtTime,
          },

          {
            renderFn: () => formatDateTimeWithTimezone(issueDate),
            sortKey: issueDateTime,
          },
          {
            renderFn: () => formatDateTimeWithTimezone(expirationDate),
            sortKey: expirationDateTime,
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Button
        style={{ alignSelf: 'flex-end', marginBottom: theme.space.xs }}
        variant={ButtonVariant.OUTLINED}
        onClick={() => setShowAddModal(true)}
      >
        Add certification
      </Button>
      <DataTable
        rows={tableRows}
        headers={[
          {
            key: 'certificationId',
            label: 'ID',
          },
          {
            key: 'imageUrl',
            label: 'Images',
          },
          {
            key: 'certificationType',
            label: 'Type',
            sortable: true,
          },
          {
            key: 'status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'createdAt',
            label: 'Created At',
            sortable: true,
          },

          {
            key: 'issueDate',
            label: 'Issue Date',
            sortable: true,
          },
          {
            key: 'expirationDate',
            label: 'Expiration Date',
            sortable: true,
          },
        ]}
      />
      <ImagesModal
        {...imagesModal}
        title={selectedImageTitle}
        imageUrls={selectedImageUrls}
      />
      <WorkerCertificationsAddModal
        refetch={refetch}
        workerId={workerId}
        showAddModal={showAddModal}
        onCloseModal={() => setShowAddModal(false)}
      />
    </>
  )
}
