import { useStatsigClient } from '@statsig/react-bindings'
import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon, Row } from 'src/components/base'
import { Button } from 'src/components/base/Button'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompany } from 'src/hooks/useCompany'
import { CompanyContactDetails } from './components/CompanyContactDetails'
import { TableConfigurationList } from './components/TableConfigurationList'

export default function InvoiceSettingsScreen() {
  const { companyId = '' } = useParams()
  const navigate = useNavigate()

  const { client: statsig } = useStatsigClient()
  if (!statsig.checkGate('invoice_statements')) {
    navigate('/')
  }

  const { company, isLoading } = useCompany(companyId)

  if (isLoading) {
    return (
      <Row justifyCenter alignCenter>
        <CircularProgress size="large" />
      </Row>
    )
  }

  if (!company) {
    return <div>Company Not Found</div>
  }

  return (
    <MainLayout title={`Invoice Settings`}>
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Back to {company.employerName}
      </Button>
      <Divider wrapperStyle={{ marginTop: theme.space.xxs }} />
      <Col gap={theme.space.sm}>
        <Text variant="h4" mt={theme.space.sm}>
          Edit Default Statement
        </Text>
        <CompanyContactDetails
          companyId={companyId}
          employerName={company.employerName}
        />
        <TableConfigurationList companyId={company.companyId} />
      </Col>
    </MainLayout>
  )
}
