import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { Shift, ShiftPayType } from '@traba/types'
import { Row } from 'src/components/base'
import { DotText } from 'src/components/base/DotText'
import { useTimesheetForShift } from 'src/hooks/useTimesheet'

import { formatDuration } from 'src/shared/utils/dateUtils'
import { getMoneyString } from 'src/utils/stringUtils'

interface Props {
  shift: Shift
}

export const ShiftDetailsTimeSheet = ({ shift }: Props) => {
  const { timesheet, isLoading } = useTimesheetForShift(shift.shiftId)

  if (isLoading) {
    return <CircularProgress />
  }

  if (!timesheet) {
    return (
      <Row alignCenter style={{ justifyContent: 'space-around', width: 1000 }}>
        <Text variant="h6">No Timesheet data</Text>
      </Row>
    )
  }

  return (
    <Row alignCenter style={{ justifyContent: 'space-around', width: 1000 }}>
      <Text variant="h5">Timesheet Totals:</Text>
      {shift.payType === ShiftPayType.HOURLY ? (
        <>
          <Text variant="h6">
            Time Worked: {formatDuration(timesheet.totalTimeWorked || 0)}
          </Text>
          <DotText />
          <Text variant="h6">
            Time Billed: {formatDuration(timesheet.totalBilledTime)}
          </Text>
        </>
      ) : (
        <>
          <Text variant="h6">
            Total Units Worked: {timesheet.totalUnitsWorked}
          </Text>
          <DotText />
          <Text variant="h6">Total Paid Units: {timesheet.totalPaidUnits}</Text>
        </>
      )}
      <DotText />
      {timesheet.totalPaidTime && (
        <>
          <Text variant="h6">
            Time Paid: {formatDuration(timesheet.totalPaidTime)}
          </Text>
          <DotText />
        </>
      )}
      <Text variant="h6">
        Worker Pay: {getMoneyString(timesheet.grossPayToWorkers)}
      </Text>
      <DotText />
      <Text variant="h6">
        Total Charge: {getMoneyString(timesheet.totalCharge)}
      </Text>
    </Row>
  )
}
