import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Badge, Row } from 'src/components/base'

export function RegionCellContent({
  locationName,
  regionId,
}: {
  locationName?: string
  regionId: string
}) {
  return (
    <Row alignCenter>
      <Text>{locationName}</Text>
      <Badge
        style={{ marginLeft: theme.space.xxs }}
        variant="info"
        title={regionId}
      />
    </Row>
  )
}
