import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Announcement, AnnouncementStatus } from '@traba/types'
import { formatDistanceToNow, isAfter } from 'date-fns'
import { useState } from 'react'
import { Row, Col, Badge, Modal } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { formatTimeDateString } from 'src/shared/utils/dateUtils'

interface Props {
  announcement: Announcement
  timezone?: string
}

export const AnnouncementItem = ({ announcement, timezone }: Props) => {
  const isSent = announcement.status === AnnouncementStatus.SENT
  const [showImageModal, setShowImageModal] = useState(false)

  // If the announcement is pending and scheduled for the future, show the
  // scheduled time, else show the distance to the time from when the
  // announcement was sent
  function getRelevantAnnouncementTime() {
    const isPending = announcement.status === AnnouncementStatus.PENDING
    const isScheduledForFuture =
      announcement?.scheduledFor &&
      isAfter(new Date(announcement.scheduledFor!), new Date())
    if (isPending && isScheduledForFuture) {
      const tz = timezone || announcement.shift?.timezone
      if (tz) {
        return `Scheduled for ${formatTimeDateString(
          new Date(announcement.scheduledFor!),
          tz,
        )}`
      } else {
        return 'Scheduled for later'
      }
    }
    return formatDistanceToNow(
      new Date(announcement.scheduledFor ?? announcement.createdAt),
      {
        addSuffix: true,
      },
    )
  }

  return (
    <Col>
      <Row justifyBetween mt={theme.space.sm}>
        <Text mr={theme.space.xl} variant="h5" style={{ maxWidth: 800 }}>
          {announcement.content}
          {announcement.imageUrls?.[0] && (
            <Text
              ml={theme.space.xxs}
              variant="link"
              onClick={() => setShowImageModal(true)}
            >
              See attached image
            </Text>
          )}
        </Text>
        <Badge
          style={{ alignSelf: 'flex-start' }}
          variant={isSent ? 'success' : 'warning'}
          title={announcement.status}
        />
      </Row>

      <Text mb={theme.space.xs} variant="body2">{`Sent by ${
        announcement.senderEmail
      } \u2022 ${getRelevantAnnouncementTime()}`}</Text>
      <Divider />
      <Modal
        isOpen={!!showImageModal}
        handleClose={() => setShowImageModal(false)}
      >
        <img
          src={announcement.imageUrls?.[0]}
          style={{
            maxWidth: 500,
            padding: theme.space.xl,
            alignSelf: 'center',
          }}
        />
      </Modal>
    </Col>
  )
}
