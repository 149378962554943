import { theme } from '@traba/theme'

export interface HorizontalRuleProps {
  color?: string
  backgroundColor?: string
  height?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
}

export const HorizontalRule: React.FC<HorizontalRuleProps> = (props) => {
  const {
    color = theme.colors.Grey20,
    backgroundColor = theme.colors.Grey20,
    height = 1,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
  } = props
  return (
    <hr
      style={{
        color,
        backgroundColor,
        height,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
      }}
    />
  )
}
