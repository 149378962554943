import { styled } from '@mui/material/styles'
import styledComp, { css } from 'styled-components'

export const CostSummaryContainer = styled('div')``

export const SummaryRow = styled('div')`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
`

export const SummaryRowItem = styled('div')(({ theme }) => ({
  color: theme.colors.Grey50,
  fontVariant: 'small-caps',
  fontWeight: 500,
}))

export const LargeAmount = styled('span')(({ theme }) => ({
  color: theme.colors.Blue100,
  fontSize: '24px',
  fontWeight: 'bolder',
}))

export const SummaryTitle = styled('span')(({ theme }) => ({
  color: theme.colors.Blue100,
  fontWeight: 600,
  paddingBottom: 24,
}))

export const SummaryRowValue = styled('span')(({ theme }) => ({
  color: theme.colors.Blue100,
  fontWeight: 600,
}))

export const LargeAmountSuper = styled('span')(({ theme }) => ({
  color: theme.colors.Grey50,
  fontSize: '12px',
  verticalAlign: 'super',
  fontWeight: 500,
}))

export const LargeAmountSub = styled('span')(({ theme }) => ({
  color: theme.colors.Grey50,
  fontSize: '12px',
  fontWeight: 500,
}))

export const FunnyMoneyWrapper = styledComp.div<{
  strikeThrough?: boolean
}>`
  ${({ strikeThrough, theme }) => {
    if (!strikeThrough) {
      return
    }
    return css`
      &:after {
        content: '';
        border-bottom: 2px solid ${theme.colors.Blue100};
        width: 100%;
        position: absolute;
        right: 0;
        top: 40%;
      }
    `
  }}
`
