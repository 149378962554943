import { trabaApi } from '@traba/api-utils'
import { CertificationEntry } from '@traba/types'
import { useQuery } from 'react-query'

const getCertifications = async () => {
  try {
    const response = await trabaApi.get('/certifications?locale=en')
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useCertifications = () => {
  const {
    isLoading,
    isError,
    data: certifications,
    error,
    isFetched,
  } = useQuery<CertificationEntry[], Error>('certifications', getCertifications)

  return {
    isLoading,
    isError,
    error,
    isFetched,
    certifications,
  }
}
