import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus } from '@traba/types'
import { ChatSummary } from '@traba/types'
import { Company } from '@traba/types'
import { Worker } from '@traba/types'
import { useEffect, useState } from 'react'
import ImagesModal from 'src/components/base/ImagesModal/ImagesModal'
import { useChats } from 'src/hooks/useChats'
import { Row } from '../../../components/base'
import { useModal } from '../../../components/base/Modal/Modal'
import MessageListItem from './MessageListItem'
import { MessagesPanel } from './MessagesPanel'

interface Props {
  company?: Company
  worker?: Worker & AccountStatus
  lastChatRef: React.MutableRefObject<HTMLDivElement | null>
}

export const MessagesTab = ({ company, lastChatRef, worker }: Props) => {
  const imagesModal = useModal()
  const { chats, isLoading, refetch } = useChats({
    companyId: company?.companyId,
    workerId: worker?.workerId ?? worker?.uid,
  })
  const [selectedImageUrls, setSelectedImageUrls] = useState<
    string[] | undefined
  >()
  const [selectedChat, setSelectedChat] = useState<ChatSummary | undefined>()
  const isPanelOpen = selectedChat !== undefined

  const handleClickChatImage = (imageUrls: string[]) => {
    setSelectedImageUrls(imageUrls)
    imagesModal.open()
  }

  useEffect(() => {
    lastChatRef.current?.scrollIntoView()
  }, [lastChatRef])

  if (isLoading) {
    return (
      <Row mb={theme.space.sm} justifyBetween alignCenter>
        <CircularProgress
          size={36}
          sx={{
            left: '50%',
          }}
        />
      </Row>
    )
  }

  return (
    <div style={{ height: 850, overflow: 'hidden' }}>
      {!chats || chats.length === 0 ? (
        <Text>No messages yet.</Text>
      ) : (
        <Row>
          <div
            style={{
              overflow: 'auto',
              height: 800,
              width: !isPanelOpen ? '100% ' : '40%',
            }}
          >
            {chats.map((chat: ChatSummary, index: number) => (
              <MessageListItem
                lastChatRef={
                  index === chats.length - 1 ? lastChatRef : undefined
                }
                chat={chat}
                key={index}
                selected={selectedChat?.summaryId === chat.summaryId}
                onClick={() => {
                  if (selectedChat?.summaryId !== chat.summaryId) {
                    setSelectedChat(chat)
                  } else {
                    setSelectedChat(undefined)
                  }
                }}
              />
            ))}
          </div>
          {isPanelOpen && (
            <MessagesPanel
              refetchChats={refetch}
              handleClickChatImage={handleClickChatImage}
              setSelectedChat={setSelectedChat}
              selectedChat={selectedChat}
              company={company}
              worker={worker}
            />
          )}
        </Row>
      )}
      <ImagesModal {...imagesModal} imageUrls={selectedImageUrls} />
    </div>
  )
}
