import { useAlert } from '@traba/context'
import { Button, ButtonVariant, Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AccountApprovalStatus,
  Shift,
  ShiftRequest,
  WorkerShiftEligibility,
} from '@traba/types'
import { isRecurringShiftRequest, isShiftRequestInactive } from '@traba/utils'
import { Dispatch, SetStateAction, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { fetchEligibilities } from 'src/hooks/useWorkerShiftsEligibilities'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getPayRateString } from 'src/utils/stringUtils'
import { EditScheduleOption } from './../EditSchedule/EditScheduleOption'

const isWorkerNotApproved = (worker: PopulatedWorker) => {
  return worker.accountStatus.accountStatus !== AccountApprovalStatus.Approved
}

interface Props {
  shiftRequests: ShiftRequest[]
  shiftRequestIdWithOneShift: Map<string, Shift>
  checkedWorkers: PopulatedWorker[]
  setCheckedWorkers: Dispatch<SetStateAction<PopulatedWorker[]>>
  setEligibilities: Dispatch<
    SetStateAction<WorkerShiftEligibility[] | undefined>
  >
  selectedShiftRequest: ShiftRequest | undefined
  setSelectedShiftRequest: Dispatch<SetStateAction<ShiftRequest | undefined>>
  onContinue: () => void
}

export function AddWorkerToScheduleSelect({
  shiftRequests,
  shiftRequestIdWithOneShift,
  checkedWorkers,
  selectedShiftRequest,
  setEligibilities,
  setCheckedWorkers,
  setSelectedShiftRequest,
  onContinue,
}: Props) {
  const [isLoadingEligibility, setIsLoadingEligibility] = useState(false)
  const [error, setError] = useState('')
  const { showError } = useAlert()
  const recurringShiftRequests = shiftRequests.filter(isRecurringShiftRequest)
  const validateSetWorkers = () => {
    const unapproved = checkedWorkers
      .filter(isWorkerNotApproved)
      .map((worker) => worker.id)
    if (unapproved.length) {
      return setError(
        `These workers are not approved: ${unapproved.join(', ')}`,
      )
    }
    if (checkedWorkers.length > 5) {
      return setError(
        'We currently only allow adding up to 5 workers at a time',
      )
    }
    return setError('')
  }

  const onContinueToNext = async () => {
    setIsLoadingEligibility(true)
    try {
      if (selectedShiftRequest?.shiftRequestId) {
        const shiftId = shiftRequestIdWithOneShift.get(
          selectedShiftRequest.shiftRequestId,
        )?.shiftId
        if (shiftId) {
          const eligibilityResponse = await fetchEligibilities(
            checkedWorkers.map((cw) => cw.id || cw.workerId),
            shiftId,
          )
          setEligibilities(eligibilityResponse)
        }
      }
      setIsLoadingEligibility(false)
      onContinue()
    } catch (error) {
      showError('Error fetching eligibilities, please try again')
      setIsLoadingEligibility(false)
    }
  }

  return (
    <>
      <SearchWorkers
        checkedWorkers={checkedWorkers}
        validateSetWorkers={validateSetWorkers}
        setCheckedWorkers={setCheckedWorkers}
        disableSideBar
      />
      {error && <Text variant="error">{error}</Text>}

      {checkedWorkers.length > 0 && (
        <>
          <Divider />
          <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
            Which role in this schedule you want to add above workers to?
          </Text>
          <Col gap={theme.space.xs}>
            {recurringShiftRequests
              .filter((sr) => !isShiftRequestInactive(sr))
              .map((sr) => (
                <EditScheduleOption
                  key={`edit_schedule_option_workers_${sr.shiftRequestId}`}
                  selected={
                    selectedShiftRequest?.shiftRequestId === sr.shiftRequestId
                  }
                  title={`${sr.shiftRole} (${sr.slotsRequested} slots - ${getPayRateString(sr.payType, sr.payRate)})`}
                  onClick={() => {
                    setSelectedShiftRequest(sr)
                  }}
                />
              ))}
          </Col>
        </>
      )}

      <Row justifyEnd mt={theme.space.sm}>
        <Button
          disabled={!selectedShiftRequest || !checkedWorkers.length}
          variant={ButtonVariant.FILLED}
          loading={isLoadingEligibility}
          onClick={onContinueToNext}
        >
          Continue
        </Button>
      </Row>
    </>
  )
}
