import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Row,
  Button,
  Icon,
  Collapsible,
  CollapsibleIcon,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useAttributes } from 'src/hooks/useAttributes'
import {
  Field,
  FieldType,
  useSearchInputItems,
} from 'src/hooks/useSearchInputItems'
import InputMapper from '../../../components/InputMapper/InputMapper'
import { useFieldsConfig } from '../../../hooks/useFieldsConfig'
import { buildInputsList } from './ConfigDrawer/helpers'
import SearchConfigDrawer from './ConfigDrawer/SearchConfigDrawer'
import ExportDataDrawer from './ExportDrawer/ExportDataDrawer'
import { ParamsContainer } from './WorkerSearch.styles'

type WorkerSearchFormProps = {
  form: any
  setForm: any
  onPressSearch: () => void
  onClearParams: (field?: string) => void
  isLoading: boolean
}

// sort list by: text => select => checkbox, etc

export default function WorkerSearchForm({
  form,
  setForm,
  onPressSearch,
  isLoading,
  onClearParams,
}: WorkerSearchFormProps) {
  const { basicInfoFields, advancedInfoFields, metricsInfoFields } =
    useSearchInputItems()
  const [expanded, setExpanded] = useState(true)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [selectedFields, setSelectedFields] =
    useFieldsConfig('workerSearchFields')

  const { attributes: roleAttributes, isFetched } = useAttributes()

  function onSubmitForm(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    onPressSearch()
  }

  const allInputFields: Field[] = useMemo(
    () => [...basicInfoFields, ...advancedInfoFields, ...metricsInfoFields],
    [basicInfoFields, advancedInfoFields, metricsInfoFields],
  )

  const fieldsToShow = useMemo(() => {
    if (isFetched) {
      return buildInputsList(selectedFields, allInputFields, roleAttributes)
    }
    return buildInputsList(selectedFields, allInputFields)
  }, [isFetched, roleAttributes, selectedFields, allInputFields])

  useEffect(() => {
    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onPressSearch()
      }
    }

    window.addEventListener('keydown', handleEnterKey)
    return () => {
      window.removeEventListener('keydown', handleEnterKey)
    }
  }, [onPressSearch])

  const handleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const handleDrawerClose = useCallback(
    (newSelectedFields: string[]) => {
      setSelectedFields(newSelectedFields)

      setForm((prevForm: any) => {
        const updatedForm = { ...prevForm }
        const newFields = buildInputsList(
          newSelectedFields,
          allInputFields,
          roleAttributes,
        )

        // Remove fields that are no longer selected
        Object.keys(updatedForm).forEach((field) => {
          if (!newFields.some((newField) => newField.field === field)) {
            delete updatedForm[field]
            if (onClearParams) {
              onClearParams(field)
            }
          }
        })

        // Initialize new fields
        newFields.forEach((field) => {
          if (!(field.field in updatedForm)) {
            updatedForm[field.field] = undefined
          }
        })

        return updatedForm
      })
    },
    [setSelectedFields, allInputFields, roleAttributes, setForm, onClearParams],
  )

  const isFormFilled = (
    form: any,
    inputFields: { field: string; type: FieldType }[],
  ) => inputFields.some(({ field }) => !!form[field])

  const isFormValid = isFormFilled(form, allInputFields)

  if (!fieldsToShow) {
    return null
  }

  return (
    <>
      <Row
        alignCenter
        justifyBetween
        mb={8}
        px={12}
        onClick={() => setExpanded(!expanded)}
      >
        <Text variant="h4">Find workers</Text>
        <CollapsibleIcon
          collapsed={!expanded}
          setCollapsed={setExpanded}
          size={20}
        />
      </Row>
      <form onSubmit={onSubmitForm} id="queryForm">
        <ParamsContainer>
          <Collapsible collapsed={!expanded}>
            <InputMapper
              inputFields={fieldsToShow}
              values={form}
              onChange={(field, value) => setForm({ ...form, [field]: value })}
            />
          </Collapsible>
        </ParamsContainer>
        <Row justifyEnd>
          <Button
            leftIcon={<Icon name="download" />}
            variant={ButtonVariant.OUTLINED}
            loading={isLoading}
            style={{ width: 180 }}
            onClick={() => setShowExport(true)}
            mr={theme.space.xs}
            disabled={!isFormValid}
          >
            Export
          </Button>
          <Button
            leftIcon={<Icon name="cancel" />}
            variant={ButtonVariant.OUTLINED}
            loading={isLoading}
            style={{ width: 120 }}
            onClick={() => onClearParams()}
            mr={theme.space.xs}
            disabled={!isFormValid}
          >
            Clear
          </Button>
          <Button
            leftIcon={<Icon name="edit" />}
            variant={ButtonVariant.OUTLINED}
            loading={isLoading}
            style={{ width: 220 }}
            onClick={handleDrawer}
            mr={theme.space.xs}
          >
            Edit search fields
          </Button>
          <Button
            leftIcon={<Icon name="search" />}
            variant={ButtonVariant.FILLED}
            loading={isLoading}
            disabled={!isFormValid}
            style={{ width: 220 }}
          >
            Search
          </Button>
        </Row>
      </form>

      <SearchConfigDrawer
        onClose={handleDrawer}
        isOpen={showDrawer}
        onDrawerClose={handleDrawerClose}
        showBasicInfo
        listName="workerSearchFields"
      />

      <ExportDataDrawer
        params={form}
        isOpen={showExport}
        onClose={() => setShowExport(false)}
      />
    </>
  )
}
