import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ActionSource,
  CompanyEvents,
  ShiftEvents,
  WorkerEvents,
} from '@traba/types'
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Input, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { IMenuItem } from 'src/components/base/Select/Select'
import { AuditSearchParams } from 'src/hooks/useAuditLogs'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface AuditSearchBoxProps {
  logType: 'SHIFT' | 'WORKER' | 'COMPANY'
  logSearch: AuditSearchParams
  setLogSearch: React.Dispatch<React.SetStateAction<AuditSearchParams>>
  eventFilter: ShiftEvents | WorkerEvents | CompanyEvents | undefined
  setEventFilter: React.Dispatch<
    React.SetStateAction<ShiftEvents | WorkerEvents | CompanyEvents | undefined>
  >
}

export default function AuditSearchBox(props: AuditSearchBoxProps) {
  const { logType, logSearch, setLogSearch, eventFilter, setEventFilter } =
    props

  const [workerToSearch, setWorkerToSearch] = useState<PopulatedWorker[]>([])

  const searchEventOptions: IMenuItem[] = Object.values(
    logType === 'SHIFT'
      ? ShiftEvents
      : logType === 'COMPANY'
        ? CompanyEvents
        : WorkerEvents,
  )
    .map((event) => {
      return { label: event, value: event }
    })
    .sort((a, b) => a.value.localeCompare(b.value))

  const actorTypeOptions: IMenuItem[] = Object.values(ActionSource).map(
    (event) => {
      return { label: event, value: event }
    },
  )

  const handleActorTypeSelect = (s: ActionSource | undefined) => {
    if (s) {
      setLogSearch({ ...logSearch, actionSource: s })
    } else {
      setLogSearch({ ...logSearch, actionSource: undefined })
    }
  }

  useEffect(() => {
    if (logType === 'WORKER') {
      return
    }
    if (workerToSearch.length > 0) {
      setLogSearch({ ...logSearch, workerId: workerToSearch[0].id })
    } else {
      setLogSearch({ ...logSearch, workerId: undefined })
    }
  }, [workerToSearch, setLogSearch])

  function handleClearFilters() {
    setLogSearch((prev) => {
      return {
        afterCreationDate: prev.afterCreationDate,
        beforeCreationDate: prev.beforeCreationDate,
        shiftId: logType === 'SHIFT' ? prev.shiftId : '',
        workerId: logType === 'WORKER' ? prev.workerId : '',
        companyId: logType === 'COMPANY' ? prev.companyId : '',
        actorId: '',
        actionSource: undefined,
        event: undefined,
      }
    })
    setWorkerToSearch([])
    setEventFilter(undefined)
  }

  return (
    <Row
      flexCol
      style={{
        backgroundColor: theme.colors.Grey20,
        padding: theme.space.xs,
        borderRadius: theme.space.xs,
      }}
    >
      <Row flexCol>
        <Row justifyBetween alignCenter>
          <Text variant="h6">Event Type</Text>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={handleClearFilters}
            style={{ padding: 0 }}
          >
            Clear
          </Button>
        </Row>
        <Row
          mt={theme.space.xs}
          style={{ backgroundColor: 'white', borderRadius: theme.space.xs }}
          fullWidth
        >
          <Select
            menuItems={searchEventOptions}
            value={(eventFilter || '') as string}
            fullWidth
            handleSelect={(s) =>
              setEventFilter(
                s as ShiftEvents | WorkerEvents | CompanyEvents | undefined,
              )
            }
            placeholder="All Events"
            showEmptyOption
          />
        </Row>
      </Row>

      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6">User type who took action</Text>
        <Row
          mt={theme.space.xs}
          style={{ backgroundColor: 'white', borderRadius: theme.space.xs }}
          fullWidth
        >
          <Select
            menuItems={actorTypeOptions}
            value={(logSearch.actionSource || '') as string}
            fullWidth
            handleSelect={(s) =>
              handleActorTypeSelect(s as ActionSource | undefined)
            }
            placeholder="All user types"
            showEmptyOption
          />
        </Row>
      </Row>

      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6">Actor Id</Text>
        <Row
          mt={theme.space.xs}
          style={{ backgroundColor: 'white', borderRadius: theme.space.xs }}
          fullWidth
        >
          <Input
            value={logSearch.actorId}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setLogSearch((prevLogSearch) => ({
                ...prevLogSearch,
                actorId: ev.target.value,
              }))
            }}
            full
          />
        </Row>
      </Row>

      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6">Worker</Text>
        <Row fullWidth>
          <SearchWorkers
            checkedWorkers={workerToSearch}
            setCheckedWorkers={setWorkerToSearch}
            hideApprovedOnlyToggle
            hideInstructionText
            selectOneOnly
            disabled={logType === 'WORKER'}
          />
        </Row>
      </Row>

      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6">Company Id</Text>
        <Row
          mt={theme.space.xs}
          style={{ backgroundColor: 'white', borderRadius: theme.space.xs }}
          fullWidth
        >
          <Input
            value={logSearch.companyId}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setLogSearch({ ...logSearch, companyId: ev.target.value })
            }}
            full
            disabled={logType === 'COMPANY' || logType === 'SHIFT'}
          />
        </Row>
      </Row>

      <Row flexCol mt={theme.space.xs}>
        <Text variant="h6">Shift Id</Text>
        <Row
          mt={theme.space.xs}
          style={{ backgroundColor: 'white', borderRadius: theme.space.xs }}
          fullWidth
        >
          <Input
            value={logSearch.shiftId}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setLogSearch({ ...logSearch, shiftId: ev.target.value })
            }}
            full
            disabled={logType === 'SHIFT'}
          />
        </Row>
      </Row>
    </Row>
  )
}
