import { Text } from '@traba/react-components'
import {
  SentinelEventAction,
  SentinelEventActionPreset,
  SentinelEventActionType,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { useCompany } from 'src/hooks/useCompany'
import { useHotSettings } from 'src/hooks/useHotSettings'
import {
  SEARCH_INPUT_AVAILABLE_FOR_SHIFT_FIELD_KEY,
  SEARCH_INPUT_ON_SHIFT_WAITLIST_FIELD_KEY,
  SEARCH_INPUT_SAME_DAY_WAITLIST_FIELD_KEY,
} from 'src/hooks/useSearchInputItems'
import { useShift } from 'src/hooks/useShifts'
import { AbandonModal, ClockInModal, ClockOutModal } from 'src/modals'
import AcceptShiftModal from 'src/modals/AcceptShiftModal/AcceptShiftModal'
import BulkActionModal from 'src/modals/BulkActionModal/BulkActionModal'
import DirectPushModal from 'src/modals/DirectPushModal/DirectPushModal'
import DirectSMSModal from 'src/modals/DirectSMSModal/DirectSMSModal'
import { EditShiftModal } from 'src/modals/EditShiftModal/EditShiftModal'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { NoShowContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'
import { Modal, ModalState } from '../../base/Modal/Modal'
import { MODAL_SIZE } from '../../base/Modal/types'
import BugleDrawer, { CommunicationMethod } from '../../BugleDrawer/BugleDrawer'

const getBuglePreSelectedFieldsForPreset = (
  preset?: SentinelEventActionPreset,
) => {
  if (!preset) {
    return []
  }

  switch (preset) {
    case SentinelEventActionPreset.TO_BACKFILL_LIST:
      return []
    case SentinelEventActionPreset.TO_WAITLISTS_ON_SHIFT:
      return [SEARCH_INPUT_ON_SHIFT_WAITLIST_FIELD_KEY]
    case SentinelEventActionPreset.TO_WAITLISTS_AND_AVAILABLE_NOW:
      return [
        SEARCH_INPUT_SAME_DAY_WAITLIST_FIELD_KEY,
        SEARCH_INPUT_AVAILABLE_FOR_SHIFT_FIELD_KEY,
      ]
  }
  return []
}

const getBugleDefaultCommunicationMethodForAction = (
  action?: SentinelEventAction,
) => {
  if (!action) {
    return
  }

  switch (action.actionType) {
    case SentinelEventActionType.BUGLE_PUSH:
      return CommunicationMethod.Push
    case SentinelEventActionType.BUGLE_SMS:
      return CommunicationMethod.SMS
    case SentinelEventActionType.BACKFILL_CALLS:
      return CommunicationMethod.BackfillCall
  }
}

export const SentinelActionModals = ({
  shiftId,
  editShiftModal,
  handleClose,
  bulkCommsDrawer,
  bulkCancelModal,
  bulkRobocallModal,
  clockInModal,
  clockOutModal,
  noShowModal,
  abandonModal,
  acceptShiftModal,
  directSmsModal,
  directPushModal,
  sentinelNotificationToUserId,
  selectedPrimaryAction,
  selectedPreset,
  selectedWorkerShifts,
}: {
  shiftId: string
  handleClose: () => void
  editShiftModal: ModalState
  bulkCommsDrawer: ModalState
  bulkCancelModal: ModalState
  bulkRobocallModal: ModalState
  clockInModal: ModalState
  clockOutModal: ModalState
  noShowModal: ModalState
  abandonModal: ModalState
  acceptShiftModal: ModalState
  directSmsModal: ModalState
  directPushModal: ModalState
  sentinelNotificationToUserId: string
  selectedPrimaryAction?: SentinelEventAction
  selectedPreset?: SentinelEventActionPreset
  selectedWorkerShifts?: WorkerShift[]
}) => {
  const { shift, isLoading: isLoadingShift } = useShift(shiftId)
  const { company, isLoading: isLoadingCompany } = useCompany(shift?.companyId)
  const { hotSettings } = useHotSettings()
  const firstSelectedWorkerShift = selectedWorkerShifts?.[0]

  if (isLoadingShift || isLoadingCompany || !hotSettings) {
    return
  }

  if (!shift) {
    return <Text>{`There was an error loading shiftId: ${shiftId}`}</Text>
  }

  return (
    <>
      {editShiftModal.isOpen && (
        <EditShiftModal
          handleClose={() => {
            editShiftModal.handleClose()
            handleClose()
          }}
          isOpen={editShiftModal.isOpen}
          shift={shift}
          minHourlyPayRate={getPayRate({
            companyMin: company?.minHourlyPayRate,
            hotSettingMin: hotSettings.platformMinHourlyPayRate,
          })}
          allowSelectionFromShiftRequest={false}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {bulkCommsDrawer.isOpen && (
        <BugleDrawer
          isOpen={bulkCommsDrawer.isOpen}
          onClose={() => {
            bulkCommsDrawer.handleClose()
            handleClose()
          }}
          shift={shift}
          preSelectedFields={getBuglePreSelectedFieldsForPreset(selectedPreset)}
          defaultCommunicationMethod={getBugleDefaultCommunicationMethodForAction(
            selectedPrimaryAction,
          )}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      <BulkActionModal
        {...bulkCancelModal}
        action="cancel"
        workerShifts={selectedWorkerShifts ?? []}
        sentinelNotificationToUserId={sentinelNotificationToUserId}
      />
      <BulkActionModal
        {...bulkRobocallModal}
        action="robocall"
        workerShifts={selectedWorkerShifts ?? []}
        sentinelNotificationToUserId={sentinelNotificationToUserId}
      />
      {firstSelectedWorkerShift && (
        <ClockInModal
          {...clockInModal}
          workerShift={firstSelectedWorkerShift}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {firstSelectedWorkerShift && (
        <ClockOutModal
          {...clockOutModal}
          workerShift={firstSelectedWorkerShift}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {firstSelectedWorkerShift && (
        <Modal
          isOpen={noShowModal.isOpen}
          handleClose={() => {
            noShowModal.handleClose()
            handleClose()
          }}
          title="No Show Worker"
          size={MODAL_SIZE.EXTRA_SMALL}
        >
          <NoShowContent
            workerShift={firstSelectedWorkerShift}
            handleClose={() => {
              noShowModal.handleClose()
              handleClose()
            }}
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        </Modal>
      )}
      {firstSelectedWorkerShift && (
        <AbandonModal
          isOpen={abandonModal.isOpen}
          workerShift={firstSelectedWorkerShift}
          handleClose={() => {
            abandonModal.handleClose()
            handleClose()
          }}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {acceptShiftModal.isOpen && (
        <AcceptShiftModal
          shift={shift}
          isOpen={acceptShiftModal.isOpen}
          handleClose={() => {
            acceptShiftModal.handleClose()
            handleClose()
          }}
          workerIds={selectedWorkerShifts?.map((ws) => ws.workerId)}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {firstSelectedWorkerShift && (
        <Modal
          isOpen={noShowModal.isOpen}
          handleClose={() => {
            noShowModal.handleClose()
            handleClose()
          }}
          title="No Show Worker"
          size={MODAL_SIZE.EXTRA_SMALL}
        >
          <NoShowContent
            workerShift={firstSelectedWorkerShift}
            handleClose={() => {
              noShowModal.handleClose()
              handleClose()
            }}
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        </Modal>
      )}
      {firstSelectedWorkerShift && (
        <AbandonModal
          isOpen={abandonModal.isOpen}
          workerShift={firstSelectedWorkerShift}
          handleClose={() => {
            abandonModal.handleClose()
            handleClose()
          }}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {acceptShiftModal.isOpen && (
        <AcceptShiftModal
          shift={shift}
          isOpen={acceptShiftModal.isOpen}
          handleClose={() => {
            acceptShiftModal.handleClose()
            handleClose()
          }}
          workerIds={selectedWorkerShifts?.map((ws) => ws.workerId)}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
      {firstSelectedWorkerShift && (
        <DirectSMSModal
          {...directSmsModal}
          workerShifts={selectedWorkerShifts ?? []}
          deepLink={shift.shiftDetailsDeeplink}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
          shiftId={shiftId}
        />
      )}
      {firstSelectedWorkerShift && (
        <DirectPushModal
          {...directPushModal}
          workerShifts={selectedWorkerShifts ?? []}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
    </>
  )
}
