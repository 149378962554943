import { WorkerShiftSignupNotificationStatus } from '@traba/types'
import { FilterField, FilterFieldType } from 'src/hooks/useFilteredData'

export const notificationsTableFilters: FilterField[] = [
  {
    key: 'notification.status',
    label: 'Hide Worker Ineligible?',
    type: FilterFieldType.Select,
    shouldPass: (docValue, filterValue) => {
      const hideWorkerIneligible = JSON.parse(filterValue)
      return hideWorkerIneligible
        ? docValue !== WorkerShiftSignupNotificationStatus.WorkerIneligible
        : true
    },
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
]
