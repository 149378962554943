import { theme } from '@traba/theme'
import { AccountApprovalStatus } from '@traba/types'
import { RequiredMultiShiftType } from '@traba/types'
import { GenericAcceptShiftBypasses } from '@traba/types'
import { useMemo, useState } from 'react'
import { Button, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { ConfirmationStep } from './ConfirmationStep'
import { useAddWorkersModalActions } from './hooks/useAddWorkersModalActions'
import { useShiftRequestsWithShifts } from './hooks/useShiftRequestWithShifts'
import { ListStep } from './ListStep'
import { ReviewStep } from './ReviewStep'
import { AddWorkerToShiftsModalProps, WorkerOnShift } from './types'

const isWorkerNotApproved = (worker: PopulatedWorker) => {
  return worker.accountStatus.accountStatus !== AccountApprovalStatus.Approved
}

export function AddWorkerToShiftsModal({
  isOpen,
  handleClose: parentHandleClose,
  shiftsList,
  workers,
}: AddWorkerToShiftsModalProps) {
  if (!isOpen) {
    return null
  }
  return (
    <AddWorkerToShiftsModalComponent
      isOpen={isOpen}
      handleClose={parentHandleClose}
      shiftsList={shiftsList}
      workers={workers}
    />
  )
}

function AddWorkerToShiftsModalComponent({
  isOpen,
  handleClose: parentHandleClose,
  shiftsList,
  workers,
}: AddWorkerToShiftsModalProps) {
  const [checkedWorkers, setCheckedWorkers] = useState<PopulatedWorker[]>(
    workers ?? [],
  )
  const [selectedShiftsForRequest, setSelectedShiftsForRequest] = useState<
    Record<string, string[]>
  >(() => {
    return shiftsList?.[0]
      ? { [shiftsList[0].shiftRequestId]: [shiftsList[0].shiftId] }
      : {}
  })
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [bypasses, setBypasses] = useState<GenericAcceptShiftBypasses>({})
  const [workerShiftsToCancel, setWorkerShiftsToCancel] = useState<
    WorkerOnShift[]
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const shiftRequestIds = useMemo(
    () => shiftsList.map((shift) => shift.shiftRequestId),
    [shiftsList],
  )

  const {
    shiftRequests,
    shifts,
    isLoading: isLoadingShiftRequests,
  } = useShiftRequestsWithShifts(shiftRequestIds)

  const hasRequiredMultiShift = useMemo(
    () =>
      shiftRequests.some(
        (sr) =>
          sr.requiredMultiShiftType === RequiredMultiShiftType.ALL_IN_REQUEST,
      ),
    [shiftRequests],
  )

  const {
    prepareShiftsForConfirmation,
    toCancelWithStatus,
    handleAddWorkers,
    handleCancellations,
    setToCancelWithStatus,
    setWorkerShiftsWithStatus,
    workerShiftsWithStatus,
  } = useAddWorkersModalActions({
    selectedShifts: selectedShiftsForRequest,
    checkedWorkers,
    workerShiftsToCancel,
    setIsLoading,
    bypasses,
  })

  const validateSetWorkers = () => {
    const unapproved = checkedWorkers
      .filter(isWorkerNotApproved)
      .map((worker) => worker.id)
    if (unapproved.length) {
      return setError(
        `These workers are not approved: ${unapproved.join(', ')}`,
      )
    }
    if (checkedWorkers.length > 5) {
      return setError(
        'We currently only allow adding up to 5 workers at a time',
      )
    }
    return setError('')
  }

  /** Navigation logic */
  const clearState = () => {
    setCheckedWorkers([])
    setSelectedShiftsForRequest({})
    setWorkerShiftsToCancel([])
    setToCancelWithStatus([])
    setWorkerShiftsWithStatus([])
    setIsComplete(false)
  }

  const handleClose = () => {
    parentHandleClose()
    clearState()
    setStep(1)
  }

  function handlePrevious() {
    setStep(step - 1)
    if (step === 3) {
      setToCancelWithStatus([])
      setWorkerShiftsWithStatus([])
    }
  }

  async function handleNext() {
    // Close
    if (isComplete) {
      clearState()
      return handleClose()
    }
    // Confirm step 3
    if (step === 3) {
      setIsLoading(true)
      await handleCancellations()
      await handleAddWorkers()
      setIsLoading(false)
      setIsComplete(true)
      return
    }
    // Before step 3
    if (step === 2) {
      prepareShiftsForConfirmation()
    }
    // Default
    return setStep(step + 1)
  }

  const hasShiftsSelected = Object.values(selectedShiftsForRequest).some(
    (value) => value.length > 0,
  )
  const stepOneNextDisabled =
    step === 1 && (!hasShiftsSelected || checkedWorkers.length === 0 || !!error)

  const hasUncheckedBypasses = Object.values(bypasses).some((bp) => !bp)
  const stepTwoNextDisabled = step === 2 && (!!error || hasUncheckedBypasses)

  const nextButtonTitle =
    checkedWorkers.length === 0 || error
      ? 'Select workers'
      : !hasShiftsSelected
        ? 'Select shifts'
        : !isComplete && step === 3
          ? 'Confirm'
          : isComplete && step === 3
            ? 'Close'
            : 'Next'

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={
        step === 1
          ? 'Add workers for shifts'
          : step === 2
            ? 'Review'
            : 'Confirmation'
      }
    >
      <Row flexCol style={{ flex: 1 }} px={theme.space.med}>
        {step === 1 && (
          <ListStep
            checkedWorkers={checkedWorkers}
            setCheckedWorkers={setCheckedWorkers}
            validateSetWorkers={validateSetWorkers}
            shiftRequests={shiftRequests}
            shifts={shifts}
            selectedShifts={selectedShiftsForRequest}
            setSelectedShifts={setSelectedShiftsForRequest}
            error={error}
            isLoadingShiftRequests={isLoadingShiftRequests}
          />
        )}
        {step === 2 && (
          <ReviewStep
            selectedShifts={selectedShiftsForRequest}
            shifts={shifts}
            workers={checkedWorkers}
            setBypasses={setBypasses}
            bypasses={bypasses}
            workerShiftsToCancel={workerShiftsToCancel}
            setWorkerShiftsToCancel={setWorkerShiftsToCancel}
            hasUncheckedBypasses={hasUncheckedBypasses}
            hasRequiredMultiShift={hasRequiredMultiShift}
          />
        )}

        {step === 3 && (
          <ConfirmationStep
            bypasses={bypasses}
            toCancelWithStatus={toCancelWithStatus}
            workerShiftsWithStatus={workerShiftsWithStatus}
            checkedWorkers={checkedWorkers}
            selectedShifts={selectedShiftsForRequest}
          />
        )}
      </Row>
      <Row
        mt={theme.space.xs}
        fullWidth
        style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={handleClose}
          disabled={isLoading || isComplete}
        >
          Cancel
        </Button>
        <Row>
          {step !== 1 && !isComplete && (
            <Button
              disabled={step === 1 || isLoading}
              onClick={handlePrevious}
              mr={theme.space.xxs}
            >
              Previous
            </Button>
          )}
          <Button
            disabled={stepOneNextDisabled || stepTwoNextDisabled || isLoading}
            onClick={handleNext}
          >
            {nextButtonTitle}
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}
