import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { SlackUser } from '@traba/types'
import { useQuery } from 'react-query'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'

async function getSlackUsers(): Promise<SlackUser[] | undefined> {
  try {
    const res = await trabaApi.get('communication/slack/users')

    return res.data
  } catch (error: any) {
    const errorMessage = `useSlackUsers -> getSlackUsers() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    Sentry.captureException(errorMessage)
  }
}

export function useSlackUsers() {
  const {
    isLoading,
    isError,
    data: users,
    error,
    isFetched,
    refetch,
  } = useQuery<SlackUser[] | undefined, Error>(
    ['slack_users'],
    () => getSlackUsers(),
    {
      staleTime: ONE_HOUR_IN_MS,
      cacheTime: ONE_HOUR_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    users,
    error,
    isFetched,
    refetch,
  }
}
