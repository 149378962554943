import { trabaApi } from '@traba/api-utils'
import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { useQuery } from 'react-query'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

async function getWorkerShiftsForTimesheetComparison(
  shiftIds: string[],
): Promise<WorkerShiftAsTimesheetRow[] | undefined> {
  try {
    const { data } = await trabaApi.post('worker-shifts/compare-timesheets', {
      shiftIds,
    })
    return data
  } catch (error: unknown) {
    console.error(
      'useWorkerShifts -> getWorkerShifts() ERROR',
      error instanceof Error ? error.message : error,
    )
  }
}

export function useWorkerShiftsForTimesheet(shiftIds: string[] = []) {
  const {
    isLoading,
    isError,
    data: workerShifts,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerShiftAsTimesheetRow[] | undefined, Error>(
    `workerShifts_${shiftIds.sort()}`,
    () => getWorkerShiftsForTimesheetComparison(shiftIds),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: shiftIds.length > 0,
    },
  )

  return {
    isLoading,
    isError,
    workerShifts,
    error,
    isFetched,
    refetch,
  }
}
