import { trabaApi } from '@traba/api-utils'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import {
  CreateWardenExemption,
  ExtendedWardenExemption,
} from 'src/types/warden'

async function getWardenExemptionsForCompany(
  companyId: string,
): Promise<ExtendedWardenExemption[] | undefined> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/warden-exemptions`)
    return res.data
  } catch (error) {
    console.error(
      'useWardenExemptions -> getWardenExemptionsForCompany() ERROR',
      error,
    )
  }
}

export function useCompanyWardenExemptions(companyId: string) {
  const {
    isLoading,
    isError,
    data: companyWardenExemptions,
    error,
    isFetched,
    refetch,
  } = useQuery<ExtendedWardenExemption[] | undefined, Error>(
    ['warden_exemptions', { companyId }],
    () => getWardenExemptionsForCompany(companyId),
    {},
  )

  return {
    isLoading,
    isError,
    companyWardenExemptions,
    error,
    isFetched,
    refetch,
  }
}

async function getWardenExemptionsForWorker(
  workerId: string,
): Promise<ExtendedWardenExemption[] | undefined> {
  try {
    const res = await trabaApi.get(`workers/${workerId}/warden-exemptions`)
    return res.data
  } catch (error) {
    console.error(
      'useWardenExemptions -> getWardenExemptionsForWorker() ERROR',
      error,
    )
  }
}

export function useWorkerWardenExemptions(workerId: string) {
  const {
    isLoading,
    isError,
    data: workerWardenExemptions,
    error,
    isFetched,
    refetch,
  } = useQuery<ExtendedWardenExemption[] | undefined, Error>(
    ['warden_exemptions', { workerId }],
    () => getWardenExemptionsForWorker(workerId),
    {},
  )

  return {
    isLoading,
    isError,
    workerWardenExemptions,
    error,
    isFetched,
    refetch,
  }
}

async function getWardenExemptionsForShift(
  shiftId: string,
): Promise<ExtendedWardenExemption[] | undefined> {
  try {
    const res = await trabaApi.get(`shifts/${shiftId}/warden-exemptions`)
    return res.data
  } catch (error) {
    console.error(
      'useWardenExemptions -> getWardenExemptionsForShift() ERROR',
      error,
    )
  }
}

export function useShiftWardenExemptions(shiftId: string) {
  const {
    isLoading,
    isError,
    data: shiftWardenExemptions,
    error,
    isFetched,
    refetch,
  } = useQuery<ExtendedWardenExemption[] | undefined, Error>(
    ['warden_exemptions', { shiftId }],
    () => getWardenExemptionsForShift(shiftId),
    {},
  )

  return {
    isLoading,
    isError,
    shiftWardenExemptions,
    error,
    isFetched,
    refetch,
  }
}

export const useWardenExemptions = () => {
  const createWardenExemptionMutation = useMutation<
    ExtendedWardenExemption,
    AxiosError,
    CreateWardenExemption
  >(async (newExemption) => {
    const res = await trabaApi.post(`/warden/exemption`, newExemption)
    return res.data
  }, {})

  const archiveWardenExemptionMutation = useMutation<void, AxiosError, string>(
    async (exemptionId) => {
      const res = await trabaApi.patch(
        `/warden/exemption/${exemptionId}/archive`,
      )
      return res.data
    },
    {},
  )

  return {
    createWardenExemption: createWardenExemptionMutation.mutate,
    archiveWardenExemption: archiveWardenExemptionMutation.mutate,
  }
}
