import { IncentiveTypeIds } from '@traba/types'
import {
  PAID_BACK_UP_CATEGORY_ID,
  PaymentLogEventType,
  PaymentLogReasons,
  StripePaymentLogEventType,
  TrabaPaymentLogEventType,
  WorkerIncentiveForPaymentLog,
  WorkerShiftForPaymentLog,
} from '@traba/types'

export const getTitleForEarning = ({
  workerIncentive,
  workerShift,
}: {
  workerShift?: WorkerShiftForPaymentLog
  workerIncentive?: WorkerIncentiveForPaymentLog
}): string => {
  if (workerShift) {
    return `${workerShift.shiftRole} at ${workerShift.employerName}`
  } else if (workerIncentive) {
    const { typeId, categoryId } = workerIncentive
    switch (typeId) {
      case IncentiveTypeIds.dXgY:
        return categoryId.includes('-REF') ? 'Referral Bonus' : 'Bonus'
      case IncentiveTypeIds.appeasement:
        return categoryId === PAID_BACK_UP_CATEGORY_ID
          ? 'Paid Backup'
          : 'Appeasement'
      default:
        return 'Bonus'
    }
  }
  return 'Earning'
}

export const getTitleForPaymentEarned = (
  isWorkerShift: boolean,
  amount: number,
  reason?: PaymentLogReasons,
) => {
  switch (reason) {
    case PaymentLogReasons.IP_FEE:
      return 'Instant Pay Fee'
    case PaymentLogReasons.IP_TOP_UP:
      return 'Instant Pay Refund'
    case PaymentLogReasons.SHIFT_ADJUSTMENT:
      return 'Shift Adjustment'
    default:
      return isWorkerShift
        ? amount >= 0
          ? 'Shift Completed'
          : 'Shift Adjustment'
        : amount >= 0
          ? 'Incentive Completed'
          : 'Incentive Reversed'
  }
}

export const getTitleForPaymentLogType = (
  type: PaymentLogEventType,
  isWorkerShift: boolean,
  amount: number,
  reason?: PaymentLogReasons,
): string => {
  switch (type) {
    case StripePaymentLogEventType.PAYOUT_CREATED:
      return 'Payout Created'
    case StripePaymentLogEventType.PAYOUT_UPDATED:
      return 'Payout Updated'
    case StripePaymentLogEventType.PAYOUT_PAID:
      return 'Payout Paid'
    case StripePaymentLogEventType.PAYOUT_FAILED:
      return 'Payout Failed'
    case StripePaymentLogEventType.PAYOUT_CANCELED:
      return 'Payout Canceled'
    case StripePaymentLogEventType.TRANSFER_REVERSED:
      return 'Transfer Reversed'
    case TrabaPaymentLogEventType.PAYMENT_EARNED:
      return getTitleForPaymentEarned(isWorkerShift, amount, reason)
    case TrabaPaymentLogEventType.TRANSFER_CREATED:
      return 'Transfer Created'
    default:
      return 'Unknown payout log type'
  }
}
