import { theme } from '@traba/theme'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  width: 400px;
  padding-top: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
`

export const SubHeaderWrapper = styled.div`
  margin-bottom: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
`

export const AvatarWrapper = styled.div`
  display: inline-block;
  position: relative;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
`
export const ExpandedWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.Green10};
  padding: ${theme.space.med}px;
  width: 100%;
  overflow-wrap: break-word;
  flex: 1;
  opacity: 0.5;
  display: flex;
  align-items: center;
`
