import { useStatsigClient } from '@statsig/react-bindings'
import { Anchor, Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import { roundToDecimalPlaces } from '@traba/utils'
import copyToClipboard from 'copy-to-clipboard'
import { Link, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  Col,
  CompanyStatusBadge,
  Icon,
  Row,
} from 'src/components/base'
import { EmploymentTypeBadge } from 'src/components/base/Badge/EmploymentTypeBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import { UserRolePermission } from 'src/context/user/types'
import { useBilling } from 'src/hooks/useBilling'
import { usePermissions } from 'src/hooks/usePermissions'
import { getStripeUrl } from 'src/utils/companyUtils'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getTagEmojiString } from 'src/utils/shiftUtils'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { useHotSettings } from '../../../hooks/useHotSettings'
import * as S from '../CompanyDetailsScreen.styles'

type CompanyDetailsHeaderProps = {
  company: Company
  onPressCompanySettings: () => void
}

export default function CompanyDetailsHeader(props: CompanyDetailsHeaderProps) {
  const navigate = useNavigate()
  const { company, onPressCompanySettings } = props
  const { billing } = useBilling(company.companyId)
  const { street1, city, state, postalCode } = company.address || {}

  const editCompanySettingsAccess = usePermissions([
    UserRolePermission.ManageCompanySettings,
  ])
  const stripeAccess = usePermissions([UserRolePermission.StripeAccess])

  const { client: statsig } = useStatsigClient()

  const stripeCustomerId = billing?.paymentSettings?.stripeCustomerId
  const stripeCustomerLink = stripeCustomerId ? (
    <Anchor
      href={getStripeUrl(stripeCustomerId)}
      style={{ fontWeight: 'bold' }}
    >
      {stripeCustomerId}
    </Anchor>
  ) : (
    '-'
  )

  const stripePhoneNumber = billing?.paymentSettings?.phoneNumber
  const stripePhoneNumberLink = stripePhoneNumber ? (
    <Anchor
      openInNewTab={false}
      href={`tel:${stripePhoneNumber}`}
      style={{ fontWeight: 'bold' }}
    >
      {formatPhoneNumber(stripePhoneNumber, true)}
    </Anchor>
  ) : (
    '-'
  )

  // Truncate markup strings at 4th decimal
  const trimmedBaseMarkup =
    company?.baseMarkup === undefined
      ? 'no base markup set'
      : `${truncateString(`${roundToDecimalPlaces(company?.baseMarkup * 100, 2)}`, 7)}%`
  const trimmedCalculatedMarkup =
    company?.calculatedMarkup === undefined
      ? 'no calculated markup set'
      : `${truncateString(`${roundToDecimalPlaces(company?.calculatedMarkup * 100, 2)}`, 7)}%`

  const { hotSettings } = useHotSettings()
  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting
  const defaultBreaksLength = company.defaultBreaks?.breakLength
  const defaultBreaksCount = company.defaultBreaks?.count

  return (
    <>
      <S.Nav>
        <Text variant="body2" onClick={() => navigate(-1)}>
          <Icon name="leftArrow" />
          Go back
        </Text>
      </S.Nav>
      <S.Cover />
      <Row mt={-24} alignCenter>
        <Avatar src={company.companyLogo} name={company.employerName} />
        <Col ml={24} mt={24}>
          <Text variant="h4">{company.employerName}</Text>
          <Row>
            <Text mr={8} variant="body2">
              Joined on: {formatDateTimeWithTimezone(company.createdAt)}
            </Text>
            <CompanyStatusBadge isApproved={company.isApproved} />
            {!!company.activeEmploymentTypes && (
              <Row ml={theme.space.xxxs}>
                <EmploymentTypeBadge
                  employmentTypes={company.activeEmploymentTypes}
                />
              </Row>
            )}

            <Row alignCenter ml={theme.space.sm}>
              {getTagEmojiString(company.shiftTags || [])}
            </Row>
          </Row>
        </Col>
        <Col mt={24}>
          <Row justifyEnd>
            {statsig.checkGate('invoice_statements') && (
              <Link to={`/companies/${company.companyId}/invoice-settings`}>
                <Button
                  variant={ButtonVariant.OUTLINED}
                  leftIcon={<Icon name="dollarRound" />}
                  style={{ marginRight: theme.space.xxs }}
                >
                  Invoice Settings
                </Button>
              </Link>
            )}
            <Button
              slim
              variant={ButtonVariant.OUTLINED}
              style={{ marginRight: 8 }}
              iconPadding={'0 8px 0 0'}
              leftIcon={<Icon name="accountSettings" />}
              onClick={onPressCompanySettings}
              disabled={!editCompanySettingsAccess}
            >
              Company Settings
            </Button>
            <Button
              slim
              variant={ButtonVariant.FILLED}
              onClick={() => copyToClipboard(company.companyId ?? '')}
              leftIcon={
                <Icon name="copy" type="svg" size={14} color={'#fff'} />
              }
            >
              Copy UID
            </Button>
            <Row alignCenter ml={theme.space.xxs}>
              <Link to={`/post/${company.companyId}`}>
                <Button
                  variant={ButtonVariant.FILLED}
                  disabled={shouldFreezeShiftPosting}
                >
                  Post Shift
                </Button>
              </Link>
            </Row>
            <Link to={`/companies/${company.companyId}/logs`}>
              <Button
                slim
                variant={ButtonVariant.OUTLINED}
                style={{ marginLeft: theme.space.xxs }}
              >
                Audit Log
              </Button>
            </Link>
          </Row>
        </Col>
      </Row>

      <Row mt={24}>
        <Col>
          <Text variant="caption" mb={8}>
            COMPANY ADDRESS
          </Text>
          <Text variant="body1" mb={16}>
            {`${street1}, ${city}, ${state}, ${postalCode}`}
          </Text>
          <Text variant="caption" mb={8}>
            BASE MARKUP
          </Text>
          <Text variant="body1" mb={16}>
            {trimmedBaseMarkup}
          </Text>
          <Text variant="caption" mb={8}>
            CALCULATED MARKUP
          </Text>
          <Text variant="body1" mb={16}>
            {trimmedCalculatedMarkup}
          </Text>
          <Text variant="caption" mb={8}>
            CATEGORY
          </Text>
          <Text variant="h7" mb={16}>
            {company.category || '-'}
          </Text>
        </Col>

        <Col>
          <Text variant="caption" mb={8}>
            BGC REQUIREMENT
          </Text>
          <Text variant="h7" mb={16}>
            {company.extraBGCRequirement || '-'}
          </Text>
          <Text variant="caption" mb={8}>
            MINIMUM ACCEPTED TIER
          </Text>
          <Text variant="h7" mb={16}>
            {company.minimumAcceptedTier || '-'}
          </Text>
          <Text variant="caption" mb={8}>
            UNPROVEN WORKER THRESHOLD
          </Text>
          <Text variant="h7" mb={16}>
            {`${(company?.unprovenWorkerThreshold || 1) * 100}%`}
          </Text>
          <Text variant="caption" mb={8}>
            ALLOWS BACKFILL / BACKFILL LATE TOLERANCE
          </Text>
          <Text variant="h7" mb={16}>
            {company.backfillSettings?.allowsBackfill ? `Yes` : 'No'} /{' '}
            {`${company.backfillSettings?.lateTolerance} mins` || '-'}
          </Text>
        </Col>

        <Col>
          <Row alignCenter>
            <Text variant="caption" mr={theme.space.xxxs}>
              INVOICE APPROVAL
            </Text>
            <InfoTooltip title="Determines when invoices are sent to the client - automatically upon creation, or manually after draft approval by Ops" />
          </Row>
          <Text variant="h7" mb={theme.space.xs}>
            {billing?.invoiceApproval || '-'}
          </Text>

          {stripeAccess && (
            <>
              <Text variant="caption" mb={8}>
                STRIPE/PRIMARY BILLING EMAIL
              </Text>
              <Text variant="h7" mb={16}>
                {billing?.paymentSettings?.email || '-'}
              </Text>
              <Text variant="caption" mb={8}>
                STRIPE PHONE
              </Text>
              <Text variant="h7" mb={16}>
                {stripePhoneNumberLink}
              </Text>
              <Text variant="caption" mb={8}>
                STRIPE CUSTOMER ID
              </Text>
              <Text variant="h7" mb={16}>
                {stripeCustomerLink}
              </Text>
            </>
          )}
        </Col>
        <Col>
          <Row alignCenter>
            <Text variant="caption" mr={theme.space.xxxs}>
              DEFAULT SCHEDULED BREAKS
            </Text>
          </Row>
          <Text>
            {defaultBreaksCount && defaultBreaksLength
              ? `${defaultBreaksCount} x ${defaultBreaksLength} minutes`
              : 'None'}
          </Text>
        </Col>
      </Row>
    </>
  )
}
