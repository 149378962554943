import { Col, ModalButtons, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { RequiredAttributeLevel, RoleAttribute } from '@traba/types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { RequiredAttributeLevelsSections } from 'src/components/RequiredAttributeLevelsSections/RequiredAttributeLevelsSections'
import { valueEdited } from 'src/utils/helperUtils'

interface Props {
  attributes: RoleAttribute[]
  handleConfirm: () => void
  handleClose: () => void
  isOpen: boolean
  requiredAttributeLevels?: RequiredAttributeLevel[]
  setRequiredAttributeLevels?: (levels: RequiredAttributeLevel[]) => void
  selectedAttributes?: string[]
  setSelectedAttributes?: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedQuestions?: React.Dispatch<React.SetStateAction<string[]>>
  title: string
  subTitle?: string
  allowBooleanSelectionOnly?: boolean
}

export function RequiredAttributeLevelsModal(props: Props) {
  const {
    isOpen,
    attributes,
    handleClose,
    title,
    subTitle,
    allowBooleanSelectionOnly,
    selectedAttributes,
    setSelectedAttributes,
    setSelectedQuestions,
    requiredAttributeLevels,
    setRequiredAttributeLevels,
  } = props

  const [attributeLevels, setAttributeLevels] = useState<
    RequiredAttributeLevel[]
  >(requiredAttributeLevels ?? [])

  useEffect(() => {
    if (isOpen) {
      setAttributeLevels(requiredAttributeLevels ?? [])
    }
  }, [isOpen, setAttributeLevels, requiredAttributeLevels])
  function handleConfirm() {
    setRequiredAttributeLevels?.(attributeLevels)
    props.handleConfirm()
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={title}>
      <Col
        style={{
          justifyContent: 'space-between',
          height: '100%',
          flexGrow: '1',
        }}
      >
        <>
          {subTitle && (
            <Text variant="h6" mb={theme.space.sm}>
              {subTitle}
            </Text>
          )}
          <RequiredAttributeLevelsSections
            attributes={attributes}
            {...(allowBooleanSelectionOnly
              ? {
                  selectedAttributes: selectedAttributes,
                  setSelectedAttributes: setSelectedAttributes,
                  setSelectedQuestions: setSelectedQuestions,
                }
              : {
                  requiredAttributeLevels: attributeLevels,
                  setRequiredAttributeLevels: setAttributeLevels,
                })}
            allowBooleanSelectionOnly={allowBooleanSelectionOnly}
          />
        </>

        <Row>
          <ModalButtons
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            isDisabled={!valueEdited(attributeLevels, requiredAttributeLevels)}
          />
        </Row>
      </Col>
    </Modal>
  )
}
