import { Row, SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React, { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Input } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompanies } from 'src/hooks/useCompanies'
import { useRegions } from 'src/hooks/useRegions'
import { useVettingCampaigns } from 'src/hooks/useVettingCampaigns'
import { VettingCampaignSearchResults } from './VettingCampaignSearchResults'

export const VettingCampaignSearchScreen = () => {
  const [campaignName, setCampaignName] = useState<string>('')
  const [companiesValue, setCompaniesValue] = useState<IMenuItem[]>([])
  const [regionsValue, setRegionsValue] = useState<IMenuItem[]>([])
  const { regions = [] } = useRegions()
  const { companies = [] } = useCompanies({
    identifiersOnly: true,
  })
  const navigate = useNavigate()

  const companiesOptions: IMenuItem[] = companies
    .filter((company) => company.employerName)
    .map((company) => ({
      label: company.employerName,
      value: company.id,
    }))

  const regionsOptions: IMenuItem[] = React.useMemo(
    () =>
      regions.map((region) => ({
        label: region.displayName,
        value: region.regionId,
      })),
    [regions],
  )

  const { vettingCampaigns, isError: isErrorLoadingCampaigns } =
    useVettingCampaigns({
      title: campaignName,
      companyIds: companiesValue.map((company) => company.value),
      regionIds: regionsValue.map((region) => region.value),
    })

  return (
    <MainLayout>
      <Text variant="h3">Vetting Campaigns</Text>
      <Row mt={theme.space.xs} alignCenter justifyBetween wrap fullWidth>
        <Row alignCenter wrap gap={theme.space.xs}>
          <Row mr={theme.space.xs}>
            <Input
              label="Search all campaigns"
              value={campaignName}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setCampaignName(ev.target.value)
              }
              width="480px"
              style={{ height: 40, width: 480 }}
            />
          </Row>
          <Row mr={theme.space.xs}>
            <SearchSelect
              multiple
              onlyShowLabel
              options={companiesOptions}
              selectedItems={companiesValue}
              handleSelectMultiple={setCompaniesValue}
              label={`Companies`}
              width="244px"
            />
          </Row>
          <Row mr={theme.space.xs}>
            <SearchSelect
              multiple
              onlyShowLabel
              options={regionsOptions}
              selectedItems={regionsValue}
              handleSelectMultiple={setRegionsValue}
              label={`Regions`}
              width="244px"
            />
          </Row>
        </Row>
        <Button
          leftIcon={<Icon name="plus" />}
          onClick={() => navigate('/vetting/new')}
        >
          Create New Campaign
        </Button>
      </Row>
      {isErrorLoadingCampaigns ? (
        <Text variant="error" mt={theme.space.xs}>
          There was an error loading the vetting campaigns.
        </Text>
      ) : (
        <VettingCampaignSearchResults campaigns={vettingCampaigns} />
      )}
    </MainLayout>
  )
}
