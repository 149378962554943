import { IncentiveRules, IncentiveTypeIds, ValueType } from '@traba/types'

export interface IncentiveCategory {
  key: string
  typeId: IncentiveTypeIds
  valueType: ValueType
  label: string
  allowsRuleSelection: boolean
  isOpsGranted: boolean
  requiredRules?: IncentiveRules[]
}

export enum FieldType {
  input = 'input',
  int = 'int',
  float = 'float',
  date = 'date',
  checkbox = 'checkbox',
  select = 'select',
  multiSelect = 'multiSelect',
}

export interface Field {
  fieldKey: string
  fieldLabel: string
  fieldDescription?: string
  fieldType: FieldType
  fieldOptions?: Array<{ value: any; label: string }>
}

export interface RuleOption {
  key: string
  type?: IncentiveRules
  label: string
  keyFields: Field[]
  description: string
}
