import { theme } from '@traba/theme'
import { useEffect, useState } from 'react'
import { Row } from '../../../components/base'
import Toggle from '../../../components/base/Toggle'
import { WorkerCommunicationsWorkerMessages } from '../../../components/WorkerCommunications/WorkerCommunicationsWorkerMessages'
import { MessageLogsFilters } from '../../../types/worker-communications'

interface WorkerIncentivesProps {
  workerId: string
}

const filterList = [
  {
    name: MessageLogsFilters.OPEN_PHONE_MESSAGES,
    label: 'Op Messages',
  },
  {
    name: MessageLogsFilters.OPEN_PHONE_CALLS,
    label: 'Op Calls',
  },
  {
    name: MessageLogsFilters.BUGLE_SMS,
    label: 'Bugle SMS',
  },
]

export const WorkerMessagesTab = ({ workerId }: WorkerIncentivesProps) => {
  const [messageLogsFilters, setMessageLogFilters] = useState<{
    [key: string]: boolean
  }>({})

  const handleToggleFilter = (filter: string) => {
    setMessageLogFilters((prevFilters) => {
      const newFilters = Object.keys(prevFilters).reduce(
        (acc, key) => {
          acc[key] = false
          return acc
        },
        {} as { [key: string]: boolean },
      )
      return {
        ...newFilters,
        [filter]: !prevFilters[filter],
      }
    })
  }

  useEffect(() => {
    window.analytics.track('Worker Details Messages Tab Viewed', { workerId })
  }, [workerId])

  return (
    <Row fullHeight fullWidth flexCol>
      <Row mb={theme.space.xs}>
        {filterList.map((filter) => (
          <Row key={filter.name} mr={theme.space.xs}>
            <Toggle
              label={filter.label}
              buttonState={!!messageLogsFilters[filter.name]}
              runOnChange={() => handleToggleFilter(filter.name)}
              containerStyle={{ width: 200, justifyContent: 'center' }}
            />
          </Row>
        ))}
      </Row>
      <WorkerCommunicationsWorkerMessages
        workerId={workerId}
        filters={messageLogsFilters}
      />
    </Row>
  )
}
