import { useContext } from 'react'
import SettingsContext from 'src/context/settingsContext/SettingsContext'
import {
  formatDateTimeWithTimezone,
  getDateInTimezone,
  getLocalTimezone,
  buildShiftDateStringWithTimezoneOverride,
  getShiftTimeString,
  getTimeInTimezone,
} from 'src/utils/dateUtils'

export type TimezonedDateFns = {
  getTime: (date: Date | null | undefined, showTimezone?: boolean) => string
  getDate: (date: Date | null | undefined, showTimezone?: boolean) => string
  getDateTime: (date: Date | null | undefined) => string
  getDayOfWeek: (date: Date | string) => string
  getShiftDate: (
    startTime: Date | string,
    endTime: Date | string,
    additionalOptions?: Intl.DateTimeFormatOptions,
  ) => string
  getShiftTime: (
    startTime: Date | string,
    endTime: Date | string,
    withoutAbv?: boolean,
  ) => string
}

export default function useTimezonedDates(
  _timezone?: string,
): TimezonedDateFns {
  const settingsContext = useContext(SettingsContext)
  const localTimezone = getLocalTimezone()
  const timezone =
    settingsContext.timezoneOverride || _timezone || localTimezone

  const getTime = (date: Date | null | undefined, showTimezone?: boolean) =>
    getTimeInTimezone(date, timezone, showTimezone)

  const getDate = (date: Date | null | undefined, showTimezone?: boolean) =>
    getDateInTimezone(date, timezone, showTimezone)

  const getDateTime = (date: Date | null | undefined) =>
    formatDateTimeWithTimezone(date, timezone)

  const getShiftDate = (
    startTime: Date | string,
    endTime: Date | string,
    additionalOptions?: Intl.DateTimeFormatOptions,
  ) =>
    buildShiftDateStringWithTimezoneOverride(
      startTime,
      endTime,
      timezone,
      additionalOptions,
    )

  const getShiftTime = (
    startTime: Date | string,
    endTime: Date | string,
    withoutAbv?: boolean,
  ) => getShiftTimeString(startTime, endTime, timezone, withoutAbv)

  const getDayOfWeek = (date: Date | string) => {
    const dateObj = typeof date === 'string' ? new Date(date) : date
    return dateObj.toLocaleString('en-US', {
      timeZone: timezone,
      weekday: 'long',
    })
  }

  return {
    getTime,
    getDate,
    getDateTime,
    getDayOfWeek,
    getShiftDate,
    getShiftTime,
  }
}
