import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { GenderPreference } from '@traba/types'
import { Role } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export interface CreateRoleData extends Omit<Role, 'roleId' | 'companyId'> {
  locationId?: string
}
export interface EditRoleData extends Partial<Omit<Role, 'genderPreference'>> {
  genderPreference?: GenderPreference | null
  locationId?: undefined
}
export interface ArchiveRoleData {
  roleId: string
  companyId: string
}

export interface UpdateRoleResponseDto {
  role?: Role
  previousRoleId?: string
  updatedShiftIds?: string[]
  failedToUpdateShiftIds?: string[]
}

function formatRoleData(roleData: EditRoleData | CreateRoleData) {
  return { ...roleData, roleName: roleData.roleName?.trim() }
}

async function getRoles(companyId: string | undefined): Promise<Role[]> {
  if (!companyId) {
    return []
  }
  try {
    const res = await trabaApi.get(`companies/${companyId}/roles`)
    return res.data?.roles || []
  } catch (error: any) {
    console.error('useRoles -> getRoles() ERROR', error.message ?? error)
    return []
  }
}

interface UseRolesProps {
  companyId: string | undefined
}

export function useRoles(props: UseRolesProps) {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()
  const { companyId } = props
  const {
    isLoading,
    isError,
    data: roles,
    error,
    isFetched,
    refetch,
  } = useQuery<Role[], Error>(`roles_${companyId}`, () => getRoles(companyId), {
    enabled: !!companyId,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const createRoleMutation = useMutation<Role, AxiosError, CreateRoleData>(
    async (roleToCreate: CreateRoleData) => {
      if (!companyId) {
        return undefined
      }
      const formattedRoleData = formatRoleData(roleToCreate)
      const res = await trabaApi.post(
        `companies/${companyId}/roles`,
        formattedRoleData,
      )
      return res.data
    },
    {
      onSuccess: (data: Role) => {
        queryClient.invalidateQueries(`roles_${companyId}`)
        showSuccess(
          `Role "${data.roleName}" created for company ${data.companyId}`,
          'Successfully created role',
        )
      },
      onError: (error: Error) => {
        showError(error.message, 'Error creating role')
      },
    },
  )

  const editRoleMutation = useMutation<
    UpdateRoleResponseDto,
    AxiosError,
    {
      roleId: string
      companyId: string
      editRoleData: EditRoleData
    }
  >(
    async ({
      roleId,
      companyId,
      editRoleData,
    }: {
      roleId: string
      companyId: string
      editRoleData: EditRoleData
    }) => {
      const formattedRoleData = formatRoleData(editRoleData)
      const response = await trabaApi.patch(
        `companies/${companyId}/roles/${roleId}`,
        formattedRoleData,
      )
      return response.data
    },
    {
      onSuccess: (response) => {
        if (!response.role) {
          showError(
            'If you meant to update the role, double check the fields you wish to update.',
            'No changes detected',
          )
          return
        }

        refetch()
        showSuccess('Successfully edited role')
      },
      onError: (error: Error) => {
        showError(error.message, 'Error editing role')
      },
    },
  )

  const archiveRoleMutation = useMutation<Role, AxiosError, ArchiveRoleData>(
    async (archiveRoleData: ArchiveRoleData) => {
      const { companyId, roleId } = archiveRoleData
      const response = await trabaApi.patch(
        `companies/${companyId}/roles/${roleId}/archive`,
      )
      return response.data
    },
    {
      onSuccess: (archivedRole: Role) => {
        refetch()
        showSuccess(
          `Role "${archivedRole.roleId}" archived`,
          'Successfully archived role',
        )
      },
      onError: (error: Error) => {
        if (error.message === 'archive/active-shifts') {
          showError(
            'You cannot archive a role that is tied to shifts in the future.',
            'Unable to archive role',
          )
        } else {
          showError(error.message, 'Error archiving role')
        }
      },
    },
  )

  return {
    isLoading,
    isError,
    roles,
    error,
    isFetched,
    refetch,
    createRole: createRoleMutation.mutate,
    editRole: editRoleMutation.mutate,
    archiveRole: archiveRoleMutation.mutate,
  }
}
