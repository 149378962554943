import { CircularProgress } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { RobocallCommunication } from '@traba/types'
import { CallAnsweredBy } from '@traba/types'
import { useEffect, useState } from 'react'
import {
  Modal,
  Row,
  Button,
  Table,
  Tr,
  Td,
  Col,
  CopyTextIcon,
  Icon,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useApi } from 'src/hooks/useApi'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'

type MakeRobocallModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
}

export default function MakeRobocallModal(props: MakeRobocallModalProps) {
  const { handleClose, isOpen, workerShift } = props

  const [isLoadingPreShiftRobocall, setIsLoadingPreShiftRobocall] =
    useState(false)
  const { showError, showSuccess } = useAlert()

  const {
    data: robocalls,
    isLoading: isLoadingRobocalls,
    refetch: fetchRobocalls,
  } = useApi<RobocallCommunication[]>(
    `communication/robocalls?workerId=${workerShift.workerId}&shiftId=${workerShift.shiftId}`,
    {},
    { enabled: false },
  )

  useEffect(() => {
    if (isOpen) {
      fetchRobocalls()
    }
  }, [isOpen, fetchRobocalls])

  async function makePreShiftRobocall() {
    try {
      setIsLoadingPreShiftRobocall(true)
      await trabaApi.post(
        `/communication/robocall/pre-shift-confirmation?workerId=${workerShift.workerId}&shiftId=${workerShift.shiftId}`,
      )
      showSuccess('Pre-shift robocall is on its way', 'Success', 2000)
      fetchRobocalls()
    } catch (err: any) {
      showError(err, 'Error sending pre-shift robocall', 2000)
    } finally {
      setIsLoadingPreShiftRobocall(false)
      handleClose()
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={`Robocalls to ${workerShift.worker?.firstName} ${workerShift.worker?.lastName}`}
    >
      <Col>
        <Row fullWidth alignStart justifyBetween>
          <Text variant="h5">Make pre-shift confirmation robocall</Text>
          <Button
            slim
            loading={isLoadingPreShiftRobocall}
            onClick={makePreShiftRobocall}
          >
            Make Call
          </Button>
        </Row>
        <Row flexCol mt={32}>
          <Row justifyStart alignCenter>
            <Text variant="h4" mr={16}>
              Robocalls Log
            </Text>
            <Button
              slim
              leftIcon={<Icon name="refresh" />}
              onClick={() => fetchRobocalls()}
              variant={ButtonVariant.OUTLINED}
              loading={isLoadingRobocalls}
            >
              Refresh
            </Button>
          </Row>
          <Text mt={8} mb={24} variant="body3">
            Log of robocalls related to this worker AND shift.
          </Text>
          {isLoadingRobocalls ? (
            <CircularProgress size={24} />
          ) : robocalls?.length === 0 ? (
            <Text>No robocalls</Text>
          ) : (
            <Table
              headers={[
                'Created at',
                'Communication Id',
                'Subject',
                'Status',
                'picked up',
              ]}
            >
              {robocalls?.map((robocall) => (
                <Tr>
                  <Td>
                    {robocall.createdAt
                      ? formatDateTimeWithTimezone(robocall.createdAt)
                      : '-'}
                  </Td>
                  <Td>
                    {truncateString(robocall.communicationId ?? '')}
                    <CopyTextIcon textToCopy={robocall?.communicationId} />
                  </Td>
                  <Td>{robocall.subject}</Td>
                  <Td>{robocall.data.status}</Td>
                  <Td>
                    {robocall.data.answeredBy === CallAnsweredBy.Human ? (
                      <Icon name="greenCheck" />
                    ) : (
                      '-'
                    )}
                  </Td>
                </Tr>
              ))}
            </Table>
          )}
        </Row>
      </Col>
    </Modal>
  )
}
