import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ShiftTag, WorkerShiftForOps as WorkerShift } from '@traba/types'
import {
  EarningsSummaryPrisma,
  Flags,
  LocationPoint,
  PaymentStatus,
  RulesSummary,
  Shift,
  Worker,
} from '@traba/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { QueryParamArray } from 'src/types'
import { WardenResult } from 'src/types/warden'
import { getErrorMessage } from 'src/utils/errorUtils'
import { chunkRequests } from 'src/utils/helperUtils'
import { useActiveQueries } from './useActiveQueries'
import { getQueryParams } from './useApi'

export type EarningSummaryWorkerShift = Pick<
  WorkerShift,
  | 'clockInTime'
  | 'clockOutTime'
  | 'clockOutTimeBeforeWorkerEdit'
  | 'breaks'
  | 'breaksBeforeWorkerEdit'
  | 'timeWorked'
  | 'unitsWorked'
  | 'jobStatus'
  | 'isBackfill'
  | 'workerEditStatus'
> & {
  overridePayRate?: number
  netPayAmount?: number
  shift: Pick<
    Shift,
    | 'startTime'
    | 'endTime'
    | 'role'
    | 'payRate'
    | 'payType'
    | 'company'
    | 'timezone'
    | 'breakType'
  >
  worker: Pick<Worker, 'firstName' | 'lastName' | 'phoneNumber'>
  endShiftFlags?: Flags
  rulesSummary: Omit<RulesSummary, 'rulesOutput' | 'updatedAt'> | null
  locationPoints: LocationPoint[]
  wardenResult: WardenResult | null
  workerShiftAdjustments: Array<
    Pick<
      WorkerShift,
      'clockInTime' | 'clockOutTime' | 'timeWorked' | 'unitsWorked'
    > & { netPayAmount?: number; overridePayRate?: number }
  >
}

export type EarningsSummaryResponse = EarningsSummaryPrisma & {
  workerShift: EarningSummaryWorkerShift
}

interface EarningsSummaryParams {
  shiftId?: string
  workerId?: string
  after?: string
  before?: string
  regionIds?: string[]
  companyIds?: string[]
  excludeCompanies?: boolean
  paymentStatuses?: PaymentStatus[]
  tags?: ShiftTag[]
  wardenHalted?: boolean
  workerEditedOnly?: boolean
  includeZeroPay?: boolean
  order?: 'asc' | 'desc'
  isEnabled?: boolean
}

const concatParsedResponses = (
  input: {
    fulfilled: any[]
    rejected: any[]
  }[],
): { fulfilled: any[]; rejected: any[] } => {
  const response = { fulfilled: [] as any[], rejected: [] as any[] }

  for (const { fulfilled, rejected } of input) {
    response.fulfilled = response.fulfilled.concat(fulfilled)
    response.rejected = response.rejected.concat(rejected)
  }
  return response
}

async function getEarningsSummaries({
  shiftId,
  workerId,
  after,
  before,
  regionIds,
  companyIds,
  excludeCompanies,
  paymentStatuses,
  tags,
  wardenHalted,
  workerEditedOnly,
  includeZeroPay,
  order,
}: EarningsSummaryParams): Promise<EarningsSummaryResponse[] | undefined> {
  try {
    const regionIdsArray = [
      ...((regionIds
        ? regionIds?.map((regionId) => ['regionIds[]', regionId])
        : []) as QueryParamArray),
    ]

    const companyIdsArray = [
      ...((companyIds
        ? companyIds?.map((companyId) => ['companyIds[]', companyId])
        : []) as QueryParamArray),
    ]

    const paymentStatusesArray = [
      ...((paymentStatuses
        ? paymentStatuses?.map((status) => ['paymentStatuses[]', status])
        : []) as QueryParamArray),
    ]

    const tagsArray = [
      ...((tags ? tags?.map((tag) => ['tags[]', tag]) : []) as QueryParamArray),
    ]

    const queryString = getQueryParams([
      ['shiftId', shiftId],
      ['workerId', workerId],
      ['after', after],
      ['before', before],
      ['excludeCompanies', excludeCompanies],
      ['wardenHalted', wardenHalted],
      ['workerEditedOnly', workerEditedOnly],
      ['includeZeroPay', includeZeroPay],
      ['orderByStartTime', order || 'asc'],
      ...companyIdsArray,
      ...regionIdsArray,
      ...paymentStatusesArray,
      ...tagsArray,
    ])

    const res = await trabaApi.get(`payments/earnings${queryString}`)
    return res.data
  } catch (error) {
    console.error('useEarningsSummary -> getEarningsSummaries() ERROR', error)
  }
}

export function useEarningsSummaries({
  shiftId,
  workerId,
  after,
  before,
  regionIds,
  companyIds,
  excludeCompanies,
  paymentStatuses,
  tags,
  wardenHalted,
  workerEditedOnly,
  includeZeroPay,
  order,
  isEnabled = true,
}: EarningsSummaryParams) {
  const {
    isLoading,
    isError,
    data: earningsSummaries,
    error,
    isFetched,
    refetch,
  } = useQuery<EarningsSummaryResponse[] | undefined, Error>(
    [
      'earnings_summaries',
      {
        shiftId,
        workerId,
        after,
        before,
        regionIds,
        companyIds,
        excludeCompanies,
        paymentStatuses,
        tags,
        wardenHalted,
        workerEditedOnly,
        includeZeroPay,
        order,
      },
      {
        enabled: isEnabled,
      },
    ],
    () =>
      getEarningsSummaries({
        shiftId,
        workerId,
        after,
        before,
        regionIds,
        companyIds,
        excludeCompanies,
        paymentStatuses,
        tags,
        wardenHalted,
        workerEditedOnly,
        includeZeroPay,
        order,
      }),
    {},
  )

  return {
    isLoading,
    isError,
    earningsSummaries,
    error,
    isFetched,
    refetch,
  }
}

export function useEarnings() {
  const { showError, showSuccess } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()

  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const handlePayout = async (
    earningSummaries: EarningsSummaryResponse[],
    shouldInstantPay: boolean,
  ) => {
    setPaymentLoading(true)
    try {
      const workerShiftsToPayout = earningSummaries.map((earningSummary) => {
        return {
          shiftId: earningSummary.shiftId,
          workerId: earningSummary.workerId,
        }
      })
      const payoutWorkerShifts = async (
        workerShiftsToPayout: {
          shiftId: string
          workerId: string
        }[],
      ) => {
        const res = await trabaApi.post(`payments/payout`, {
          workerShiftsToPayout,
          shouldInstantPay,
        })
        return res.data as { fulfilled: any[]; rejected: any[] }
      }

      const chunkResponse = await chunkRequests(
        payoutWorkerShifts,
        workerShiftsToPayout,
        10,
      )

      const response = concatParsedResponses(chunkResponse)

      if (response.rejected.length > 0) {
        showError(
          workerShiftsToPayout.length === 1
            ? response.rejected[0]
            : 'Check response viewer for details of failed payments',
          'Not All Payments Processed Successfully',
        )
      } else {
        showSuccess(
          'Check response viewer for details of the payments',
          'Successfully Processed Payment',
        )
      }
      await refetchActiveQueries()
      return response
    } catch (e) {
      showError(getErrorMessage(e), 'Error Processing Payment')
    } finally {
      setPaymentLoading(false)
    }
  }

  interface WorkerShiftToPayout {
    shiftId: string
    workerId: string
  }

  const handlePayoutWithId = async ({
    workerShiftsToPayout,
    shouldInstantPay,
  }: {
    workerShiftsToPayout: WorkerShiftToPayout[]
    shouldInstantPay: boolean
  }) => {
    setPaymentLoading(true)
    try {
      await trabaApi.post(`payments/payout`, {
        workerShiftsToPayout,
        shouldInstantPay,
      })
      await refetchActiveQueries()
    } catch (e) {
      showError(getErrorMessage(e), 'Error Processing Payment')
    } finally {
      setPaymentLoading(false)
    }
  }

  const [patchLoading, setPatchLoading] = useState<boolean>(false)
  const handleEarningsSummaryBulkPatch = async (
    earningSummaries: EarningsSummaryResponse[],
    updateType:
      | PaymentStatus.ManualComplete
      | PaymentStatus.NeedsReview
      | 'RESET_STATUS',
  ) => {
    setPatchLoading(true)
    try {
      const workerShiftsToUpdate = earningSummaries.map((earningSummary) => {
        return {
          shiftId: earningSummary.shiftId,
          workerId: earningSummary.workerId,
        }
      })

      const updateWorkerShiftSummaries = async (
        workerShiftsToUpdate: { shiftId: string; workerId: string }[],
      ) => {
        const body: any = {}
        switch (updateType) {
          case PaymentStatus.ManualComplete:
          case PaymentStatus.NeedsReview:
            body['paymentStatus'] = updateType
            break
          case 'RESET_STATUS':
            body['resetStatus'] = true
            break
        }
        const res = await trabaApi.patch(`payments/earnings`, {
          workerShiftsToUpdate,
          ...body,
        })
        return res.data as { fulfilled: any[]; rejected: any[] }
      }

      const chunkResponse = await chunkRequests(
        updateWorkerShiftSummaries,
        workerShiftsToUpdate,
        10,
      )

      const response = concatParsedResponses(chunkResponse)

      if (response.rejected.length > 0) {
        showError(
          'Check response viewer for details of failed updates',
          'Not All Updates Processed Successfully',
        )
      } else {
        showSuccess('Successfully Marked Worker Shifts')
      }

      await refetchActiveQueries()
      return response
    } catch (e) {
      showError(getErrorMessage(e), 'Error Marking Worker Shifts')
    } finally {
      setPatchLoading(false)
    }
  }

  return {
    paymentLoading,
    patchLoading,
    handlePayout,
    handlePayoutWithId,
    handleEarningsSummaryBulkPatch,
  }
}
