import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Card,
  LoadingSpinner,
  MultiDatePicker,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  Shift,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  dateMinFromArray,
  getMinDateForScheduleChangeDatePicker,
  getNextStartAndEndTime,
  getShiftDatesForSchedules,
  isNewShiftDateAlignWithScheduleA,
} from '@traba/utils'
import { addMinutes, differenceInMinutes, isSameDay } from 'date-fns'
import { useMemo, useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import {
  CreateShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { ConfirmShiftRequestCreationDialog } from 'src/screens/PostShiftScreen/components/PostShiftForm/ConfirmShiftRequestCreationDialog'
import { CreateShiftRequestButton } from 'src/screens/PostShiftScreen/components/PostShiftForm/CreateShiftRequestButton'
import { RolesAndWorkersSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/RolesAndWorkersSection'
import {
  getShiftRequestForEditOrAdd,
  validateShiftCreationForm,
} from 'src/screens/PostShiftScreen/components/PostShiftForm/utils'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { validateDynamicOverbookAllowed } from 'src/utils/shiftFormUtils'

interface Props {
  company: Company
  firstRecurringShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onBack: () => void
  shifts?: Shift[]
  onSuccess: () => void
}

export const EditScheduleAddShiftRequest = ({
  company,
  firstRecurringShiftRequest,
  shiftRequestParent,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const { companyId } = company
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const { roles, isLoading: isLoadingRoles } = useRoles({
    companyId,
  })
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const { showError } = useAlert()
  const { locations } = useLocations(companyId)
  const { activeCompanyUsers, isLoading: isLoadingUsers } =
    useCompanyUsers(companyId)
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { hotSettings, isLoading: isLoadingHotsettings } = useHotSettings()
  const { createShiftReqLoading, createShiftRequest: submitShiftRequest } =
    useShiftRequests()

  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([
    getShiftRequestForEditOrAdd(shiftRequestParent, firstRecurringShiftRequest),
  ])
  const shiftsList = shifts?.filter(
    (s) =>
      s.shiftRequestId === firstRecurringShiftRequest.shiftRequestId && !!s.id,
  )

  // Uncomment this if we want to allow invoice group change for a new role in same schedule
  // const handleCreateInvoiceGroupModalClose = async (
  //   newGroup?: ParentInvoiceGroup,
  // ) => {
  //   await refetchInvoiceGroups()
  //   if (newGroup) {
  //     setCreateShiftRequests((prev) => {
  //       return prev.map((sr) => {
  //         return {
  //           ...sr,
  //           parentInvoiceGroupId: newGroup.id,
  //         }
  //       })
  //     })
  //   }
  //   createOrEditInvoiceGroupModal.handleClose()
  // }

  const minHourlyPayRate = useMemo(() => {
    return getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    })
  }, [])

  if (
    isLoadingUsers ||
    isLoadingHotsettings ||
    isLoadingRoles ||
    isLoadingRosters
  ) {
    return <LoadingSpinner />
  }

  const handleSubmitShiftRequest = async () => {
    try {
      await Promise.all(createShiftRequests.map(submitShiftRequest))
      onSuccess()
    } catch (e) {
      showError('Error creating shift request')
    }
  }
  const selectedLocation = locations?.find(
    (loc) => loc.locationId === createShiftRequests[0].locationId,
  )
  const timezone = selectedLocation?.timezone || 'America/New_York'
  const isFormInvalid =
    createShiftRequests.some(
      (sr) =>
        !validateShiftCreationForm(
          sr,
          minHourlyPayRate,
          hotSettings?.paidBackupPayAmountMax,
        ) ||
        !validateDynamicOverbookAllowed(
          sr.slotsRequested,
          !!sr.enableDynamicOverbooking,
        ),
    ) || !selectedDate

  const onSelectDate = (dates: Date[]) => {
    setSelectedDate(dates[0])
    const selectedShiftStartTime = combineTwoDatesForDateAndTime(
      dates[0],
      dateMinFromArray(
        firstRecurringShiftRequest.schedules.map((s) => s.startTime),
      ),
    )
    const shiftDurationInMinutes = differenceInMinutes(
      firstRecurringShiftRequest.schedules[0].endTime,
      firstRecurringShiftRequest.schedules[0].startTime,
    )
    const selectedShiftEndTime = addMinutes(
      selectedShiftStartTime,
      shiftDurationInMinutes,
    )
    // Biweekly case
    if (createShiftRequests[0].schedules.length === 2) {
      const scheduleA = firstRecurringShiftRequest.schedules[0]
      const scheduleB = firstRecurringShiftRequest.schedules[1]
      const shouldUseSameSchedules = isNewShiftDateAlignWithScheduleA(
        selectedShiftStartTime,
        scheduleA.startTime,
        scheduleB.startTime,
      )
      // This is when the new shift date is aligning with schedule A so the first week of the shift request should be scheduleA
      if (shouldUseSameSchedules) {
        const newScheduleA = {
          ...scheduleA,
          startTime: selectedShiftStartTime,
          endTime: selectedShiftEndTime,
        }
        const { startTime, endTime } = getNextStartAndEndTime(
          newScheduleA,
          scheduleB,
        )
        return setCreateShiftRequests((prev) => {
          return prev.map((sr) => ({
            ...sr,
            schedules: [newScheduleA, { ...scheduleB, startTime, endTime }],
          }))
        })
      }
      //This is the case where the new shift date is aligning with schedule B so the first week of the shift request should be scheduleB
      const newScheduleB = {
        ...scheduleB,
        startTime: selectedShiftStartTime,
        endTime: selectedShiftEndTime,
      }
      const { startTime, endTime } = getNextStartAndEndTime(
        newScheduleB,
        scheduleA,
      )
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => ({
          ...sr,
          schedules: [newScheduleB, { ...scheduleA, startTime, endTime }],
        }))
      })
    } else {
      // Weekly case - schedules will be a 1-item array
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => ({
          ...sr,
          schedules: [
            {
              ...sr.schedules[0],
              startTime: selectedShiftStartTime,
              endTime: selectedShiftEndTime,
            },
          ],
        }))
      })
    }
  }
  const shiftTimes = getShiftDatesForSchedules(
    firstRecurringShiftRequest.schedules,
  )
  const mapDaysForDatePicker = ({ date }: { date: DateObject }) => {
    const isUnavailable = !shiftTimes.some((shiftTime) =>
      isSameDay(date.toDate(), new Date(shiftTime)),
    )
    return {
      disabled: isUnavailable,
    }
  }

  return (
    <>
      <Card style={{ marginBottom: theme.space.sm }}>
        <Text variant="h5">When should the date of the first shift be?</Text>
        <Text variant="body2" mb={theme.space.xs}>
          Selected date will be the first shift for this role on this schedule{' '}
        </Text>

        <MultiDatePicker
          minDate={getMinDateForScheduleChangeDatePicker(
            combineTwoDatesForDateAndTime(
              new Date(),
              dateMinFromArray(
                firstRecurringShiftRequest.schedules.map((s) => s.startTime),
              ),
            ),
          )}
          selectedDates={selectedDate ? [selectedDate] : null}
          onSelectDates={onSelectDate}
          multiple={false}
          mapDays={mapDaysForDatePicker}
        />
      </Card>
      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={roles}
        companyId={companyId}
        companyUsers={activeCompanyUsers}
        minHourlyPayRate={minHourlyPayRate}
        businessStartTime={shiftsList?.[0]?.businessStartTime ?? null}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
      />
      {/* Uncomment this if we want to allow invoice group change for a new role in same schedule
        <InvoiceSection
        createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        activeInvoiceGroups={activeInvoiceGroups}
      /> */}
      <Row alignCenter justifyBetween>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <CreateShiftRequestButton
          onClick={() => setShowConfirmationDialog(true)}
          buttonTitle={'Add role to schedule'}
          isLoadingButton={createShiftReqLoading}
          disabledButton={isFormInvalid}
        />
      </Row>
      {/* Uncomment this if we want to allow invoice group change for a new role in same schedule
        <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
      /> */}
      <ConfirmShiftRequestCreationDialog
        showConfirmationDialog={showConfirmationDialog}
        setShowConfirmationDialog={setShowConfirmationDialog}
        handleSubmitShiftRequest={handleSubmitShiftRequest}
        createShiftRequests={createShiftRequests}
        timezone={timezone}
        businessStartTime={shiftsList?.[0]?.businessStartTime ?? null}
        selectedLocation={selectedLocation}
        selectedSingleShiftDates={null}
        companyId={companyId}
      />
    </>
  )
}
