import { SvgIconProps } from '../SvgIconProps'

export default function OpenphoneCall({
  size,
  color = '#8000FF',
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5411 13C11.1377 13 10 14.0313 10 15.3034C10 15.5919 10.044 15.879 10.1306 16.1565L10.214 16.4236C10.6071 17.6826 11.7484 18.6338 13.1653 18.8835C14.0469 19.0388 14.9531 19.0388 15.8347 18.8835C17.2516 18.6338 18.3929 17.6826 18.786 16.4236L18.8694 16.1565C18.956 15.879 19 15.5919 19 15.3034C19 14.0313 17.8623 13 16.4589 13H12.5411Z"
        fill="white"
      />
      <path
        d="M7 3.54106C7.00001 2.13768 5.96874 1 4.69661 1C4.40813 1 4.12103 1.04396 3.84345 1.13063L3.57641 1.21402C2.31744 1.60713 1.36618 2.74842 1.1165 4.16532C0.961166 5.04686 0.961165 5.95315 1.1165 6.83468C1.36618 8.25159 2.31744 9.39287 3.57641 9.78598L3.84345 9.86937C4.12103 9.95604 4.40813 10 4.69661 10C5.96874 10 7.00001 8.86233 7 7.45894L7 3.54106Z"
        fill="white"
      />
      <path
        d="M13.1653 18.8835C14.0469 19.0388 14.9531 19.0388 15.8347 18.8835C17.2516 18.6338 18.3929 17.6826 18.786 16.4236L18.8694 16.1565C18.956 15.879 19 15.5919 19 15.3034C19 14.0313 17.8623 13 16.4589 13H12.5411C11.1377 13 10 14.0313 10 15.3034C10 15.5919 10.044 15.879 10.1306 16.1565L10.214 16.4236C10.6071 17.6826 11.7484 18.6338 13.1653 18.8835ZM13.1653 18.8835C7.04195 17.7489 2.25108 12.958 1.1165 6.83468M1.1165 6.83468C0.961165 5.95315 0.961166 5.04686 1.1165 4.16532C1.36618 2.74842 2.31744 1.60713 3.57641 1.21402L3.84345 1.13063C4.12103 1.04396 4.40813 1 4.69661 1C5.96874 1 7.00001 2.13768 7 3.54106L7 7.45894C7.00001 8.86233 5.96874 10 4.69661 10C4.40813 10 4.12103 9.95604 3.84345 9.86937L3.57641 9.78598C2.31744 9.39287 1.36618 8.25159 1.1165 6.83468Z"
        stroke={color}
      />
    </svg>
  )
}
