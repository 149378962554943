import { ListItem } from '@mui/material'
import { theme } from '@traba/theme'
import { Button } from 'src/components/base'
import styled from 'styled-components'

export const FieldMonitorInputsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, minmax(250px, 280px));
  grid-auto-rows: auto;
  grid-column-gap: ${theme.space.xxs}px;
  grid-row-gap: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
  margin: ${theme.space.xs}px 0 0 0;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(
      4,
      minmax(250px, 250px)
    ); // 4 columns for medium screens
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(
      2,
      minmax(250px, 280px)
    ); // 3 columns for small screens
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(
      1,
      minmax(250px, 280px)
    ); // 1 column for tiny screens
  }
`
export const SearchBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: ${theme.space.xxs}px;
`
export const CardWrapper = styled.div`
  border: 1px solid ${theme.colors.Grey20};
  border-radius: ${theme.border.radius};
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
`

export const StyledListItem = styled(ListItem)`
  flex-wrap: wrap;
  &:hover {
    background-color: ${theme.colors.Grey10};
  }
`

export const shiftCardWrapper = styled.div`
  display: grid;
  grid-gap: ${theme.space.xxs}px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  width: 100%;
`

export const ShiftCard = styled.div`
  padding: ${theme.space.xxs}px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const WorkerEligibilityCard = styled.li`
  padding: ${theme.space.xs}px;
  border-bottom: 1px solid ${theme.colors.Grey20};
  display: flex:
  flex-direction: column;
  width: 100%;
  list-style: none;
`

export const ListItemWithStatus = styled.li`
  margin: ${theme.space.xxs}px 0;
  border-bottom: 1px solid ${theme.colors.Grey20};
  display: flex:
  flex-direction: column;
  width: 100%;
  list-style: none;
`

export const WorkersListWrapper = styled.ul`
  padding: 0;
  overflow-y: auto;
`

export const ShiftActionButton = styled(Button)`
  width: 180px;
  height: 50px;
`
