import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { EmploymentType, Shift } from '@traba/types'
import { useEffect, useState } from 'react'
import { useSessionStorage } from 'react-use'
import { Col, Select } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import WorkerDrawer from 'src/components/WorkerDrawer/WorkerDrawer'
import useWorkerShiftsEligibilities from 'src/hooks/useWorkerShiftsEligibilities'
import SearchConfigDrawer from 'src/screens/WorkerSearchScreen/components/ConfigDrawer/SearchConfigDrawer'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import ExportBugleDataDrawer from '../../../screens/WorkerSearchScreen/components/ExportDrawer/ExportBugleDataDrawer'
import { CommunicationMethod, CustomParams } from '../BugleDrawer'
import useBugleForm from '../hooks/useBugleForm'
import { DEFAULT_PAGE_SIZE } from '../hooks/useBugleForm'
import { useBugleHelpers } from '../hooks/useBugleHelpers'
import { useConfigDrawer } from '../hooks/useConfigDrawer'
import WorkerActionsSection from '../sections/ActionsSection'
import { BackfillCallSection } from '../sections/BackfillCallSection'
import { NewBugleScreenSection } from '../sections/NewBugleScreenSection'
import ParamsSection from '../sections/ParametersSection'
import PreviewSection from '../sections/PreviewSection'

type NewBugleScreenProps = {
  shift: Shift
  preSelectedFields?: string[]
  defaultCommunicationMethod?: CommunicationMethod
  sentinelNotificationToUserId?: string
}

export enum NewBugleSection {
  CommunicationMethod = 'communication_method',
  SearchWorkers = 'search_workers',
  ViewAndEdit = 'view_and_edit',
  Action = 'send',
}

export type SectionsOpenState = {
  [key in NewBugleSection]: boolean
}

export const DEFAULT_SECTIONS_OPEN_STATE: SectionsOpenState = {
  [NewBugleSection.CommunicationMethod]: true,
  [NewBugleSection.SearchWorkers]: true,
  [NewBugleSection.ViewAndEdit]: true,
  [NewBugleSection.Action]: true,
}

const PARAM_SESSION_STORAGE_KEY = 'bugle_params'
const COMM_METHOD_SESSION_STORAGE_KEY = 'bugle_comm_method'

export const NewBugleScreen = (props: NewBugleScreenProps) => {
  const {
    shift,
    preSelectedFields,
    defaultCommunicationMethod,
    sentinelNotificationToUserId,
  } = props
  const [selectedWorkerList, setSelectedWorkerList] = useState<
    PopulatedWorker[]
  >([])

  const isW2Shift = shift.shiftEmploymentType === EmploymentType.W2
  const initialParamsSet = isW2Shift ? 'custom' : 'backfill'
  const [paramsSet, setParamsSet] = useSessionStorage<CustomParams>(
    PARAM_SESSION_STORAGE_KEY,
    initialParamsSet,
  )
  const [showExport, setShowExport] = useState(false)

  const [communicationMethod, setCommunicationMethod] =
    useSessionStorage<CommunicationMethod>(
      COMM_METHOD_SESSION_STORAGE_KEY,
      CommunicationMethod.SMS,
    )

  useEffect(() => {
    if (defaultCommunicationMethod) {
      setCommunicationMethod(defaultCommunicationMethod)
    }
  }, [defaultCommunicationMethod, setCommunicationMethod])

  const handleCommChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommunicationMethod(
      (event.target as HTMLInputElement).value as CommunicationMethod,
    )
  }

  const [sectionsOpenState, setSectionsOpenState] = useState<SectionsOpenState>(
    DEFAULT_SECTIONS_OPEN_STATE,
  )

  useEffect(() => {
    setParamsSet(preSelectedFields?.length || isW2Shift ? 'custom' : 'backfill')
  }, [preSelectedFields, setParamsSet, isW2Shift])

  const [focusedWorker, setFocusedWorker] = useState<
    PopulatedWorker | undefined
  >()

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  const workerLimitOptions: IMenuItem[] = [
    { label: '10', value: '10' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: '250', value: '250' },
    { label: '500', value: '500' },
  ]

  const {
    form,
    setForm,
    isFormValid,
    isFetchingWorkers,
    error,
    workersSearchResult,
    roleAttributes,
    isLoadingAttributes,
    regions,
    isLoadingRegions,
    hotSettings,
    isLoadingHotSettings,
    handleClearParam,
    handleClear,
    handleRegionSelect,
    handleSearch,
    currentPage,
    hasProcessedEligibility,
    setHasProcessedEligibility,
    totalFound,
  } = useBugleForm({
    shiftId: shift.shiftId,
    regionId: shift.regionId,
    companyId: shift.companyId,
    paramsSet,
    communicationMethod,
    pageSize,
    sectionsOpenState,
    setSectionsOpenState,
    initialValueOverrides: {
      activeEmploymentType: shift.shiftEmploymentType,
    },
  })

  const { toggleParamsSet, isFirstSectionComplete, isSecondSectionComplete } =
    useBugleHelpers({
      sectionsOpenState,
      setSectionsOpenState,
      paramsSet,
      setParamsSet,
      isFormValid,
      form,
      workersSearchResult,
    })

  const { fieldsToShow, handleDrawerClose, showDrawer, setShowDrawer } =
    useConfigDrawer({
      form,
      setForm,
      handleClearParam,
      handleClear,
      roleAttributes,
      preSelectedFields,
    })

  const { workersWithEligibility, isFetchingEligibility } =
    useWorkerShiftsEligibilities({
      workersSearchResult,
      shiftId: shift.shiftId,
      hasProcessedEligibility,
      setHasProcessedEligibility,
    })

  if (isLoadingRegions || isLoadingAttributes || isLoadingHotSettings) {
    return <>Loading...</>
  }

  return (
    <>
      <NewBugleScreenSection
        section={NewBugleSection.CommunicationMethod}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Select your method of communication'}
        description={'This affects worker selection parameters'}
        icon={'bell'}
      >
        <RadioGroup
          name="comms-radio-buttons-group"
          value={communicationMethod}
          onChange={handleCommChange}
          row
        >
          <FormControlLabel
            value={CommunicationMethod.SMS}
            control={<Radio />}
            label="SMS"
          />
          <FormControlLabel
            value={CommunicationMethod.Push}
            control={<Radio />}
            label="Push"
          />
          <FormControlLabel
            value={CommunicationMethod.BackfillCall}
            control={<Radio />}
            label="Backfill Call"
          />
        </RadioGroup>
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.SearchWorkers}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Select parameters'}
        description={'Select a preset or create a custom search'}
        icon={'timesheet'}
      >
        <ParamsSection
          toggleParamsSet={toggleParamsSet}
          setShowDrawer={setShowDrawer}
          handleClear={handleClear}
          paramsSet={paramsSet}
          setForm={setForm}
          form={form}
          fieldsToShow={fieldsToShow}
          handleRegionSelect={handleRegionSelect}
          regions={regions}
          isFormValid={isFormValid}
          workersSearchResult={workersSearchResult}
          shift={shift}
        />
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.ViewAndEdit}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Preview'}
        description={`Generate a list of workers. ${
          paramsSet === 'backfill'
            ? 'The backfill list uses a dedicated scoring system to sort the workers in the result based on their probability of backfilling the shift'
            : 'When scheduling a message to be sent in the future this list will be used as a preview of the workers that may get notified'
        }`}
        icon={'workers'}
      >
        <Select
          label="Page Limit"
          value={pageSize.toString()}
          handleSelect={(v) => setPageSize(parseInt(v))}
          menuItems={workerLimitOptions}
        />
        <PreviewSection
          isFetchingWorkers={isFetchingWorkers}
          isFetchingEligibility={isFetchingEligibility}
          workersWithEligibility={workersWithEligibility}
          workersSearchResult={workersSearchResult}
          isFirstSectionComplete={isFirstSectionComplete}
          isSecondSectionComplete={isSecondSectionComplete}
          currentPage={currentPage}
          handleSearch={handleSearch}
          paramsSet={paramsSet}
          error={error}
          onAvatarClick={setFocusedWorker}
          totalFound={totalFound}
          selectableList={
            communicationMethod === CommunicationMethod.BackfillCall
          }
          selectedWorkerList={selectedWorkerList}
          setSelectedWorkerList={setSelectedWorkerList}
          pageSize={pageSize}
        />
      </NewBugleScreenSection>

      <NewBugleScreenSection
        section={NewBugleSection.Action}
        sectionsOpenState={sectionsOpenState}
        setSectionsOpenState={setSectionsOpenState}
        title={'Actions'}
        description={
          'Here you can define what actions to perform on the workers list, send a message, export the list, etc.'
        }
        icon={'send'}
      >
        {communicationMethod === CommunicationMethod.BackfillCall ? (
          <Col>
            <BackfillCallSection
              shiftId={shift.shiftId}
              selectedWorkerList={selectedWorkerList}
              setSelectedWorkerList={setSelectedWorkerList}
              sentinelNotificationToUserId={sentinelNotificationToUserId}
            />
          </Col>
        ) : (
          <WorkerActionsSection
            isFirstSectionComplete={isFirstSectionComplete}
            isSecondSectionComplete={isSecondSectionComplete}
            isLoading={isFetchingWorkers}
            onExport={() => setShowExport(true)}
            shift={shift}
            parameters={form}
            paramsSet={paramsSet}
            totalFound={totalFound}
            deepLink={shift.shiftDetailsDeeplink}
            presetSavedSearchId={
              paramsSet === 'backfill'
                ? hotSettings?.presetSavedSearchIds?.backfill
                : undefined
            }
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        )}
      </NewBugleScreenSection>

      <SearchConfigDrawer
        onClose={() => setShowDrawer(false)}
        isOpen={showDrawer}
        onDrawerClose={handleDrawerClose}
        listName="bugle-custom-search-fields"
        showShiftInfo
      />
      <ExportBugleDataDrawer
        bugleParams={{
          parameters: form,
          savedSearchId:
            paramsSet === 'backfill'
              ? hotSettings?.presetSavedSearchIds?.backfill
              : undefined,
          targetShiftId: shift.shiftId,
          targetCompanyId: shift.companyId,
        }}
        isOpen={showExport}
        onClose={() => setShowExport(false)}
      />

      {focusedWorker && (
        <WorkerDrawer
          worker={focusedWorker}
          isOpen={!!focusedWorker}
          onClose={() => setFocusedWorker(undefined)}
        />
      )}
    </>
  )
}
