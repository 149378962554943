import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import { ShiftAssignmentResponse, ShiftAssignmentStatus } from '@traba/types'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { Col, Row, Select } from 'src/components/base'
import ShiftAssignmentBadge from 'src/components/base/Badge/ShiftAssignmentBadge'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useShiftAssignments } from 'src/hooks/useAssignments'

type ShiftAssignmentListItemProps = {
  shift: Shift
  shiftAssignment: ShiftAssignmentResponse
  refetchAssignments: () => Promise<void>
  shouldRefetchAssignmentOnEachUpdate: boolean
}

export const ShiftAssignmentListItem = (
  props: ShiftAssignmentListItemProps,
) => {
  const {
    shift,
    shiftAssignment,
    refetchAssignments,
    shouldRefetchAssignmentOnEachUpdate,
  } = props

  const shiftAssignmentsStatuses = Object.values(ShiftAssignmentStatus)

  const shiftAssignmentsStatusesMenuItems: IMenuItem[] =
    shiftAssignmentsStatuses.map((tag) => {
      return { label: tag, value: tag }
    })
  const [assignmentStatusValue, setAssignmentStatusValue] = useState<string>(
    shiftAssignment.status,
  )
  const { updateShiftAssignment, updateShiftAssignmentLoading } =
    useShiftAssignments()

  const handleChangeStatus = async (status: string) => {
    await updateShiftAssignment({
      shiftId: shift.id,
      internalUserId: shiftAssignment.assignees[0].internalUser.id,
      status: status as ShiftAssignmentStatus,
    })
    setAssignmentStatusValue(status)
    if (refetchAssignments && shouldRefetchAssignmentOnEachUpdate) {
      await refetchAssignments()
    }
  }

  return (
    <Col>
      <Row alignCenter>
        {shiftAssignment.assignees.map((assignee) => (
          <ShiftAssignmentBadge
            key={assignee.id}
            shiftAssignment={shiftAssignment}
            internalUser={assignee.internalUser}
            refetchAssignments={refetchAssignments}
            shouldRefetchAssignmentOnEachUpdate={
              shouldRefetchAssignmentOnEachUpdate
            }
          />
        ))}
        {!updateShiftAssignmentLoading ? (
          <Select
            menuItems={shiftAssignmentsStatusesMenuItems}
            value={assignmentStatusValue}
            handleSelect={handleChangeStatus}
          />
        ) : (
          <CircularProgress
            size={24}
            sx={{
              marginLeft: theme.space.xxxs,
            }}
          />
        )}
      </Row>
    </Col>
  )
}
