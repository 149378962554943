import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentLog } from '@traba/types'
import { PaymentLogEarnings, TrabaEntityType } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import { formatInTimeZone } from 'date-fns-tz'
import { Button, Col, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import HeaderWithDetailItem from 'src/components/ShiftDetails/ShiftDetailsItem'
import { usePaymentLogs } from 'src/hooks/usePaymentLogs'
import { anyToDate } from 'src/shared/utils/dateUtils'
import { getTitleForPaymentLogType } from 'src/utils/paymentHistoryUtils'
import { getMoneyString } from 'src/utils/stringUtils'

const ShiftPaymentEvents = ({
  paymentLogs,
  timezone,
}: {
  paymentLogs: PaymentLog[]
  timezone: string
}) => {
  return paymentLogs.map((paymentLog: PaymentLog) => (
    <Row key={paymentLog.id} mt={theme.space.xxs} alignCenter>
      <Col width={600}>
        <Row alignCenter>
          <Text mr={theme.space.sm}>
            {getTitleForPaymentLogType(
              paymentLog.eventType,
              paymentLog.trabaEntityType === TrabaEntityType.WORKER_SHIFT,
              paymentLog.amount,
              paymentLog.reason,
            )}
          </Text>
          {paymentLog.payoutFailedReason && (
            <Text mr={theme.space.sm}>{paymentLog.payoutFailedReason}</Text>
          )}
        </Row>
      </Col>
      <Col mr={theme.space.sm}>
        <Text>
          {formatInTimeZone(
            paymentLog.createdAt,
            timezone,
            'MM/dd/yyyy hh:mm:ss aa zzz',
          )}
        </Text>
      </Col>
      <Col>
        <Text
          variant={paymentLog.amount > 0 ? 'h7' : 'error'}
          mr={theme.space.sm}
        >
          {getMoneyString(paymentLog.amount / 100)}
        </Text>
      </Col>
    </Row>
  ))
}

export const PaymentLogEarningsDetails = ({
  workerId,
  paymentLogEarnings,
  timezone,
}: {
  workerId: string
  paymentLogEarnings: PaymentLogEarnings
  timezone: string
}) => {
  const { workerShift, workerIncentive, trabaEntityType, trabaEntityId } =
    paymentLogEarnings
  const { paymentLogs, isLoading } = usePaymentLogs(workerId, {
    ...(trabaEntityType === TrabaEntityType.WORKER_INCENTIVE
      ? {
          workerIncentiveId: trabaEntityId,
        }
      : {}),
    ...(trabaEntityType === TrabaEntityType.WORKER_SHIFT
      ? {
          workerShiftId: trabaEntityId,
        }
      : {}),
  })

  if (isLoading) {
    return <CircularProgress size={theme.space.sm} />
  }

  const ShiftDetails = () => {
    return (
      <>
        <Row>
          <Text>{workerShift?.shiftRole}</Text>
        </Row>
        <Row>
          <Text>{workerShift?.employerName}</Text>
        </Row>
        <Row>
          <Text variant="body1">
            {workerShift?.startTime &&
              workerShift?.endTime &&
              getShiftDateString(
                anyToDate(workerShift?.startTime),
                anyToDate(workerShift?.endTime),
                timezone,
              )}
          </Text>
        </Row>
        <Row>
          <Text variant="body1">
            {workerShift?.startTime &&
              workerShift?.endTime &&
              getShiftTimeString(
                anyToDate(workerShift?.startTime),
                anyToDate(workerShift?.endTime),
                timezone,
              )}
          </Text>
        </Row>
      </>
    )
  }

  const IncentiveDetails = () => {
    return (
      <>
        <Row>
          <Text>{workerShift?.shiftRole}</Text>
        </Row>
        <Row>
          <Text>{workerShift?.employerName}</Text>
        </Row>
        <Col>
          <Row alignCenter>
            <Text variant="h6" mr={theme.space.xxs}>
              Category:
            </Text>
            <Text variant="body1">{`${workerIncentive?.category}`}</Text>
          </Row>
          <Row alignCenter>
            <Text variant="h6" mr={theme.space.xxs}>
              Description:
            </Text>
            <Text variant="body1">{`${workerIncentive?.description}`}</Text>
          </Row>
        </Col>
        <Row>
          <Text>
            {workerShift?.startTime &&
              workerShift?.endTime &&
              getShiftDateString(
                workerShift?.startTime,
                workerShift?.endTime,
                timezone,
              )}
          </Text>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row my={theme.space.xs} mx={theme.space.xs}>
        <Col>
          {paymentLogs && paymentLogs.length > 0 && (
            <HeaderWithDetailItem title="Shift payment events">
              <ShiftPaymentEvents
                paymentLogs={paymentLogs}
                timezone={timezone}
              />
            </HeaderWithDetailItem>
          )}
        </Col>
        <Col>
          <HeaderWithDetailItem
            title={
              trabaEntityType === TrabaEntityType.WORKER_SHIFT
                ? 'Shift details'
                : 'Incentive details'
            }
          >
            {trabaEntityType === TrabaEntityType.WORKER_SHIFT && (
              <ShiftDetails />
            )}
            {trabaEntityType === TrabaEntityType.WORKER_INCENTIVE && (
              <IncentiveDetails />
            )}
          </HeaderWithDetailItem>
        </Col>
      </Row>
      {trabaEntityType === TrabaEntityType.WORKER_SHIFT &&
        workerShift?.shiftId && (
          <Row justifyEnd mb={theme.space.sm} mr={theme.space.sm}>
            <Link
              to={`/action-center/clearing-house?shiftId=${workerShift?.shiftId}`}
              target="_blank"
            >
              <Button
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                variant={ButtonVariant.OUTLINED}
                loading={isLoading}
                mr={theme.space.sm}
              >
                Go to clearing house
              </Button>
            </Link>
            <Link to={`/field-monitor/${workerShift?.shiftId}`} target="_blank">
              <Button
                /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                onClick={() => {}}
                variant={ButtonVariant.OUTLINED}
                loading={isLoading}
              >
                Go to shift
              </Button>
            </Link>
          </Row>
        )}
    </>
  )
}
