import { Text } from '@traba/react-components'
import { HeaderWrapper, SubWrapper } from './ShiftDetails/ShiftDetails.styles'

type WorkerShiftHistoryItemProps = {
  header: string
  value: string | React.ReactNode
}

export function WorkerShiftHistoryItem({
  header,
  value,
}: WorkerShiftHistoryItemProps) {
  return (
    <>
      <HeaderWrapper>
        <Text variant="caption">{header}</Text>
      </HeaderWrapper>
      <SubWrapper>
        {typeof value === 'string' ? (
          <Text variant="body1">{value}</Text>
        ) : (
          value
        )}
      </SubWrapper>
    </>
  )
}
