import { Card, RadioButton, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'

interface Props {
  selected: boolean
  title: string
  onClick: () => void
}

export const EditScheduleOption = (props: Props) => {
  const { selected, title, onClick } = props
  return (
    <Card isClickable onClick={onClick}>
      <Row alignCenter fullWidth>
        <RadioButton selected={selected} />
        <Text
          color={theme.colors.MidnightBlue}
          variant="h5"
          ml={theme.space.xs}
        >
          {title}
        </Text>
      </Row>
    </Card>
  )
}
