import { IncentiveRules, ProgressLine } from '@traba/types'

export function extractProgressFromArray(progress: ProgressLine[]) {
  const currentProgress = progress.find(
    (p) => p.type === IncentiveRules.ShiftsCompletionRequired,
  )
  if (!currentProgress) {
    return
  }
  return `${currentProgress.count}/${currentProgress.target}`
}
