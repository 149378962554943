import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentLogEarnings, TrabaEntityType } from '@traba/types'
import { formatInTimeZone } from 'date-fns-tz'
import { useState } from 'react'
import { Col, CopyTextIcon, Icon, Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { getTitleForEarning } from 'src/utils/paymentHistoryUtils'
import { getMoneyString, truncateString } from 'src/utils/stringUtils'
import styled from 'styled-components'
import { PaymentLogEarningsDetails } from './PaymentLogEarningsDetails'

const StyledRow = styled(Row)`
  &:hover {
    background-color: ${theme.colors.Grey10};
  }
`

export const PaymentLogEarningsRow = ({
  workerId,
  paymentLogEarnings,
  timezone,
}: {
  workerId: string
  paymentLogEarnings: PaymentLogEarnings
  timezone: string
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    workerShift,
    workerIncentive,
    trabaEntityId,
    trabaEntityType,
    latestPaymentLogCreatedAt,
  } = paymentLogEarnings
  const isShiftEntityType = trabaEntityType === TrabaEntityType.WORKER_SHIFT

  const title = getTitleForEarning({
    workerShift,
    workerIncentive,
  })

  return (
    <>
      <StyledRow py={theme.space.xs} justifyBetween px={theme.space.xs}>
        <Row gap={theme.space.med}>
          <Col width={200}>
            <Text variant="h7">{title}</Text>
          </Col>
          <Col width={100}>
            <Text variant="h7" mr={theme.space.sm}>
              {getMoneyString(paymentLogEarnings.totalEarned / 100)}
            </Text>
          </Col>
          <Row alignCenter>
            <Text>
              {isShiftEntityType
                ? `Shift id: ${truncateString(workerShift?.shiftId ?? '')}`
                : `Worker incentive id: ${truncateString(trabaEntityId)}`}
            </Text>
            <CopyTextIcon
              textToCopy={
                isShiftEntityType ? workerShift?.shiftId : trabaEntityId
              }
            />
          </Row>
        </Row>
        <Row>
          <Text mr={theme.space.sm}>
            {formatInTimeZone(
              latestPaymentLogCreatedAt,
              timezone,
              'MM/dd/yyyy hh:mm:ss aa zzz',
            )}
          </Text>
          <Col>
            <Icon
              name={isExpanded ? 'chevronUp' : 'chevronDown'}
              style={{
                height: 16,
                width: 16,
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsExpanded((isExpanded) => !isExpanded)
              }}
            />
          </Col>
        </Row>
      </StyledRow>
      {isExpanded && (
        <PaymentLogEarningsDetails
          workerId={workerId}
          paymentLogEarnings={paymentLogEarnings}
          timezone={timezone}
        />
      )}
      <Divider />
    </>
  )
}
