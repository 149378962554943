import { useAlert } from '@traba/context'
import { InvoiceStatus, ReportConfiguration } from '@traba/types'
import {
  CreateCustomReportDto,
  useCustomReportMutations,
  useFinalizedCustomReportFromInvoiceId,
} from 'src/hooks/useCustomReports'
import { useReportConfiguration } from 'src/hooks/useReportConfiguration'
import {
  TableConfigurationDetails,
  TableConfigurationDetailsProps,
} from 'src/screens/InvoiceSettingsScreen/components/TableConfigurationDetails'
import { openInNewTab } from 'src/utils/helperUtils'

interface CustomReportProps {
  successMessage: string
  errorMessage: string
  companyId?: string
  invoiceId: string
  invoiceStatus?: InvoiceStatus
  invoiceCreatedAt?: Date
}

type CustomReportContainerProps = CustomReportProps & {
  showModal: boolean
  handleShowButtonLoading: (bool: boolean) => void
  handleShowModal: (bool: boolean) => void
}

const CustomReportContainer: React.FC<CustomReportContainerProps> = ({
  successMessage,
  errorMessage,
  companyId,
  invoiceId,
  invoiceStatus,
  showModal,
  invoiceCreatedAt,
  handleShowButtonLoading,
  handleShowModal,
}) => {
  const { showError, showSuccess } = useAlert()

  const handleSave: TableConfigurationDetailsProps['onSave'] = async (
    updatedConfiguration,
    options,
  ) => {
    handleShowButtonLoading(true)
    if (options?.saveReportConfig) {
      upsertReportConfiguration(updatedConfiguration)
    }

    try {
      await handleDownload(updatedConfiguration, options?.dirty)
      showSuccess(successMessage)
      handleShowButtonLoading(false)
    } catch (error) {
      showError(`${errorMessage}: ${error}`)
      handleShowButtonLoading(false)
    } finally {
      handleShowModal(false)
    }
  }

  const isDraftInvoice = invoiceStatus && invoiceStatus === InvoiceStatus.DRAFT
  const { finalizedCustomReport } = useFinalizedCustomReportFromInvoiceId(
    invoiceId,
    invoiceStatus,
    invoiceCreatedAt,
  )
  const { createCustomReport } = useCustomReportMutations(invoiceId)

  const handleDownload = async (
    configuration: ReportConfiguration,
    dirty?: boolean,
  ) => {
    // No update nor draft status so we should show user the finalized report
    if (!dirty && !isDraftInvoice && finalizedCustomReport) {
      return openInNewTab(finalizedCustomReport)
    }

    const customReportDto: CreateCustomReportDto = {
      selectedCols: configuration.columns,
      formatType: configuration.groupBy,
      sumColumns: configuration.showTotals ?? true,
    }

    return openInNewTab(await createCustomReport(customReportDto))
  }

  const { reportConfiguration, upsertReportConfiguration } =
    useReportConfiguration(companyId)

  return (
    reportConfiguration &&
    showModal && (
      <TableConfigurationDetails
        isEditing={true}
        singleTable={true}
        onSave={handleSave}
        configuration={reportConfiguration}
        isDraftInvoice={isDraftInvoice}
        handleClose={() => handleShowModal(false)}
      />
    )
  )
}

export default CustomReportContainer
