import { REQUIRED_FIELD_MESSAGE } from '@traba/consts'
import { theme } from '@traba/theme'
import {
  CostCenterType,
  CostCenterFormData,
  CostCenterStatus,
} from '@traba/types'
import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import * as yup from 'yup'
import { SelectDropdown, Input, Text } from '../base-components'
import { RegionAndLocationSelectWithSearch } from '../RegionAndLocationSelectWithSearch'

export type CostCenterFormProps = {
  companyId?: string
  onChange: (submitData: CostCenterFormData, error?: boolean) => void
  onCancel: () => void
  costCenter?: CostCenterType
}

export const CostCenterForm: React.FC<CostCenterFormProps> = (
  props: CostCenterFormProps,
) => {
  const { onChange, costCenter, companyId } = props

  const initialFormValues: CostCenterFormData = {
    name: costCenter?.name ?? '',
    locationIds: costCenter?.locations.map((loc) => loc.id) ?? [],
    ...(costCenter && { status: costCenter.status ?? CostCenterStatus.ACTIVE }),
  }

  const validationSchema = yup.object({
    name: yup.string().required(REQUIRED_FIELD_MESSAGE),
    locationIds: yup.array().of(yup.string()),
    ...(costCenter && {
      status: yup
        .string()
        .oneOf(Object.values(CostCenterStatus))
        .required(REQUIRED_FIELD_MESSAGE),
    }),
  })

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      // do nothing
    },
  })

  const { errors, touched, values } = formik

  const onUpdateSelectedLocations = useCallback(
    (locations: Set<string>) => {
      formik.setFieldValue('locationIds', Array.from(locations))
    },
    [formik],
  )

  useEffect(() => {
    onChange(
      {
        name: values.name,
        locationIds: values.locationIds,
        ...(costCenter && { status: values.status as CostCenterStatus }),
      },
      !!(errors.name || errors.locationIds || (costCenter && errors.status)),
    )
  }, [values, errors, onChange, costCenter])

  return (
    <div>
      <form onChange={formik.handleChange}>
        <Input
          full
          label="Cost Center Name"
          {...formik.getFieldProps('name')}
          inputStatus={touched.name && errors.name ? 3 : 1}
          errorMessage={errors.name}
          containerStyle={{
            marginTop: theme.space.xs,
            marginBottom: theme.space.sm,
          }}
        />

        {costCenter && (
          <>
            <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
              Status
            </Text>
            <SelectDropdown
              label="Status"
              value={formik.values.status}
              handleSelect={(value: CostCenterStatus) =>
                formik.setFieldValue('status', value)
              }
              menuItems={Object.values(CostCenterStatus).map((status) => ({
                value: status,
                label: status,
              }))}
              inputStatus={touched.status && errors.status ? 3 : 1}
              errorMessage={errors.status}
            />
          </>
        )}

        <Text
          style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
        >
          Pick a location (optional)
        </Text>
        <RegionAndLocationSelectWithSearch
          companyId={companyId}
          selectedLocations={new Set(values.locationIds)}
          onUpdateSelectedLocations={onUpdateSelectedLocations}
        />
      </form>
    </div>
  )
}
