import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { CustomParams } from '../BugleDrawer'
import { NewBugleSection, SectionsOpenState } from '../tabs/NewBugleScreen'
import { SearchFormData } from './useBugleForm'

const useBugleHelpers = ({
  sectionsOpenState,
  setSectionsOpenState,
  paramsSet,
  setParamsSet,
  isFormValid,
  form,
  workersSearchResult,
}: {
  sectionsOpenState: SectionsOpenState
  setSectionsOpenState: React.Dispatch<React.SetStateAction<SectionsOpenState>>
  paramsSet: CustomParams
  setParamsSet: (data: CustomParams) => void
  isFormValid: boolean
  form: SearchFormData
  workersSearchResult: PopulatedWorker[]
}) => {
  const toggleParamsSet = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newParamSet = event.currentTarget.dataset.type as CustomParams
    if (newParamSet === 'custom') {
      setSectionsOpenState({
        ...sectionsOpenState,
        [NewBugleSection.ViewAndEdit]: false,
      })
    }
    if (newParamSet === 'backfill') {
      setSectionsOpenState({
        ...sectionsOpenState,
        [NewBugleSection.ViewAndEdit]: true,
      })
    }
    setParamsSet(newParamSet || 'backfill')
  }

  const isFirstSectionComplete =
    (paramsSet === 'custom' && isFormValid && form.regionIds?.length > 0) ||
    (paramsSet === 'backfill' && form.regionIds?.length > 0)
  const isSecondSectionComplete =
    !!workersSearchResult.length && isFirstSectionComplete

  return {
    toggleParamsSet,
    isFirstSectionComplete,
    isSecondSectionComplete,
  }
}

export { useBugleHelpers }
