import { Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { JobStatus } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { useState } from 'react'
import { Col, DatePicker, Row } from 'src/components/base'
import { BreakAdjuster } from 'src/components/BreakAdjuster/BreakAdjuster'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useApiWithFeedback } from 'src/hooks/useApi'
import ManageWorkerModalButtons from '../ManageWorkerModalButtons'

export type CompleteShiftContentProps = {
  workerShift: WorkerShift
  handleClose: () => void
}

export default function CompleteShiftContent({
  workerShift,
  handleClose,
}: CompleteShiftContentProps) {
  const [clockInTime, setClockInTime] = useState(
    new Date(workerShift.shiftInfo.startTime),
  )
  const [clockOutTime, setClockOutTime] = useState(
    new Date(workerShift.shiftInfo.endTime),
  )
  const [breaks, setBreaks] = useState<ShiftTime[]>(workerShift.breaks || [])

  const { isLoading: isLoadingEndShift, apiCall: handleEndShift } =
    useApiWithFeedback<void>(
      'patch',
      `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}/complete-shift`,
      'Shift was ended successfully',
      {
        data: {
          clockInTime,
          clockOutTime,
          breaks,
        },
      },
    )

  const { refetchActiveQueries } = useActiveQueries()

  async function handleConfirm() {
    try {
      await handleEndShift()
      refetchActiveQueries()
    } finally {
      handleClose()
    }
  }

  return (
    <>
      <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
        <Col>
          <Text variant="h5" mb={8}>
            Set worker to complete
          </Text>
          <Text variant="body2" mb={24}>
            The worker completed the shift, but it was not reflected in his
            worker shift document. This option can only be used for workers that
            are in <i>TO_DO</i>.
          </Text>
          <Text variant="h6">Clock in and out times</Text>
          <Row fullWidth center alignCenter mt={16}>
            <Col>
              <DatePicker
                date={clockInTime}
                setDate={(clockInTime) => {
                  if (clockInTime) {
                    setClockInTime(clockInTime)
                  }
                }}
                label="Clock In Time"
                showTimeFieldInPopover
                granularity="minute"
                isClearable={false}
                timezone={workerShift.shiftInfo.timezone}
              />
            </Col>
          </Row>
          <Row fullWidth center alignCenter mt={16}>
            <Col>
              <DatePicker
                date={clockOutTime}
                setDate={(clockOutTime) => {
                  if (clockOutTime) {
                    setClockOutTime(clockOutTime)
                  }
                }}
                label="Clock Out Time"
                showTimeFieldInPopover
                granularity="minute"
                isClearable={false}
                timezone={workerShift.shiftInfo.timezone}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Text variant="h6" mt={32}>
        Breaks
      </Text>
      <Row flexCol mt={16} mb={32}>
        <BreakAdjuster
          breaks={breaks}
          setBreaks={setBreaks}
          timezone={workerShift.shiftInfo.timezone}
          defaultBreakTime={workerShift.shiftInfo.startTime}
        />
      </Row>
      <ManageWorkerModalButtons
        handleConfirm={handleConfirm}
        loading={isLoadingEndShift}
        handleClose={handleClose}
        disableSubmit={workerShift.jobStatus !== JobStatus.ToDo}
      />
    </>
  )
}
