import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { BreakType } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { getBreaksForWorkerShift } from '@traba/utils'
import { compact } from 'lodash'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatDuration, getTotalPaidTimeInMinutes } from 'src/utils/dateUtils'
import {
  calculateWorkerPayandFees,
  getChargedUnits,
} from 'src/utils/moneyUtils'
import { breaksToString, getMoneyString } from 'src/utils/stringUtils'
import { Row, Table, Td, Tr } from '../../base'
import { SummaryRow } from '../../SummaryRow/SummaryRow'

type AdjustmentSummaryProps = {
  workerShift: WorkerShift
  adjustedStartTime: Date
  adjustedEndTime: Date
  adjustedBreakType: BreakType
  adjustedBreaks?: ShiftTime[]
  adjustedPayRate?: number
  adjustedMinPaidTime?: number
  adjustmentReason?: string
  adjustedUnitsWorked: number | undefined
}

export function AdjustmentSummary(props: AdjustmentSummaryProps) {
  const {
    workerShift,
    adjustedStartTime,
    adjustedEndTime,
    adjustedBreakType,
    adjustedBreaks,
    adjustedPayRate,
    adjustedMinPaidTime,
    adjustmentReason,
    adjustedUnitsWorked,
  } = props

  const { hotSettings } = useHotSettings()

  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const originalClockIn = new Date(workerShift.clockInTime!)

  const originalClockOut = new Date(workerShift.clockOutTime!)

  const breaksFromWorkerShift = getBreaksForWorkerShift(
    workerShift,
    hotSettings?.enableWorkerEditTime,
  )

  const adjustedBreaksStrings = compact(
    breaksToString(
      adjustedBreaks || breaksFromWorkerShift,
      workerShift.shiftInfo.timezone,
    ),
  )

  const adjustedBreaksTableCell = adjustedBreaksStrings.map(
    (breakString, i) => (
      <Text variant="body1" style={{ fontWeight: '600' }} key={i}>
        {breakString}
      </Text>
    ),
  )

  const originalBreaksTableCell = compact(
    breaksToString(breaksFromWorkerShift, workerShift.shiftInfo.timezone),
  ).map((origBreakString, idx) => {
    const isInAdjusted = adjustedBreaksStrings.includes(origBreakString)
    return (
      <Text key={idx} variant={isInAdjusted ? 'body1' : 'strikethrough'}>
        {origBreakString}
      </Text>
    )
  })

  const adjustedTimeWorked = getTotalPaidTimeInMinutes(
    adjustedStartTime,
    adjustedEndTime,
    adjustedBreaks || workerShift.breaks || [],
    adjustedBreakType,
    workerShift.shiftInfo.scheduledBreaks,
  )

  const { grossPay: adjustedGrossPay, netPay: adjustedNetPay } =
    calculateWorkerPayandFees({
      chargedUnits: getChargedUnits({
        payType: workerShift.shiftInfo.payType,
        unitsCompletedByWorker: adjustedUnitsWorked,
        minimumPaidTime:
          adjustedMinPaidTime ?? workerShift.shiftInfo.minimumPaidTime,
        slotsRequested: workerShift.shiftInfo.slotsRequested,
        numberOfUnitsInShift: workerShift.shiftInfo.numberOfUnits,
        totalTimeWorked: adjustedTimeWorked,
        payByUnitMinimumPay: workerShift.shiftInfo.minimumPayByUnitPay,
        payRate: workerShift.shiftInfo.payRate,
      }),
      payRate: adjustedPayRate || workerShift.shiftInfo.payRate,
      payType: workerShift.shiftInfo.payType,
      timeWorkedInMinutes: adjustedTimeWorked,
      trustAndSafetyFeeHourly: workerShift.shiftInfo.trustAndSafetyFeeHourly,
    })

  // The worker shift might have had a previous instant pay fee deducted
  // from the gross pay. That fee will still remain post-adjustment, so subtract here.
  const netPayMinusPrevInstantPayFee = {
    ...adjustedNetPay,
    amount: adjustedNetPay.amount - (workerShift.instantPayFee?.amount || 0),
  }

  return (
    <Row
      flexCol
      style={{
        backgroundColor: theme.colors.Grey10,
        borderRadius: theme.space.xxs,
        padding: theme.space.xs,
      }}
    >
      <Text
        variant="h5"
        style={{
          marginBottom: '12px',
        }}
      >
        Summary
      </Text>
      <Text variant="body2">
        A summary of the changes made by this adjustment. You can also choose to
        change the payment and whether it should be sent as instant pay.
      </Text>
      <Row fullWidth center flexCol>
        <Table headers={['', 'Old', 'New']}>
          {/* Start Time */}
          <Tr>
            <Td>
              <Text variant="caption">START TIME</Text>
            </Td>
            <SummaryRow
              original={originalClockIn}
              adjusted={adjustedStartTime}
              originalString={`${tz.getDate(originalClockIn, false)}, 
              ${tz.getTime(originalClockIn)}`}
              adjustedString={`${tz.getDate(adjustedStartTime, false)}, 
              ${tz.getTime(adjustedStartTime)}`}
            />
          </Tr>
          {/* End Time */}
          <Tr>
            <Td>
              <Text variant="caption">END TIME</Text>
            </Td>
            <SummaryRow
              original={originalClockOut}
              adjusted={adjustedEndTime}
              originalString={`${tz.getDate(originalClockOut, false)}, 
              ${tz.getTime(originalClockOut)}`}
              adjustedString={`${tz.getDate(adjustedEndTime, false)}, 
              ${tz.getTime(adjustedEndTime)}`}
            />
          </Tr>
          {/* Time Worked */}
          <Tr>
            <Td>
              <Text variant="caption">TIME WORKED</Text>
            </Td>
            <SummaryRow
              original={workerShift.timeWorked}
              adjusted={adjustedTimeWorked}
              originalString={
                workerShift.timeWorked
                  ? formatDuration(workerShift.timeWorked)
                  : '0h 00m'
              }
              adjustedString={formatDuration(adjustedTimeWorked)}
            />
          </Tr>
          {/* Units Worked */}
          {workerShift.shiftInfo.payType === ShiftPayType.UNIT && (
            <Tr>
              <Td>
                <Text variant="caption">UNITS WORKED</Text>
              </Td>
              <SummaryRow
                original={workerShift.unitsWorked}
                adjusted={adjustedUnitsWorked}
                originalString={`${workerShift.unitsWorked ?? '-'}`}
                adjustedString={`${adjustedUnitsWorked ?? '-'}`}
              />
            </Tr>
          )}
          {/* Break Type */}
          {adjustedBreaks && (
            <Tr>
              <Td>
                <Text variant="caption">BREAK TYPE</Text>
              </Td>
              <SummaryRow
                original={workerShift.shiftInfo.breakType}
                adjusted={adjustedBreakType}
                originalString={`${workerShift.shiftInfo.breakType}`}
                adjustedString={`${adjustedBreakType}`}
              />
            </Tr>
          )}

          {/* Breaks */}
          {adjustedBreaks && (
            <Tr>
              <Td>
                <Text variant="caption">BREAKS</Text>
              </Td>
              <Td>
                {originalBreaksTableCell.length > 0
                  ? originalBreaksTableCell
                  : '-'}
              </Td>
              <Td>
                {adjustedBreaksTableCell.length > 0
                  ? adjustedBreaksTableCell
                  : '-'}
              </Td>
            </Tr>
          )}

          {/* Pay Rate */}
          {adjustedPayRate && (
            <Tr>
              <Td>
                <Text variant="caption">PAY RATE</Text>
              </Td>
              <SummaryRow
                original={workerShift.shiftInfo.payRate}
                adjusted={adjustedPayRate}
                originalString={`$${workerShift.shiftInfo.payRate}`}
                adjustedString={`$${adjustedPayRate}`}
              />
            </Tr>
          )}

          {/* Min Paid Time */}
          {adjustedMinPaidTime && (
            <Tr>
              <Td>
                <Text variant="caption">MINIMUM PAID TIME</Text>
              </Td>
              <SummaryRow
                original={workerShift.shiftInfo.minimumPaidTime}
                adjusted={adjustedMinPaidTime}
                originalString={
                  workerShift.shiftInfo.minimumPaidTime
                    ? `${workerShift.shiftInfo.minimumPaidTime}`
                    : '-'
                }
                adjustedString={`${adjustedMinPaidTime}`}
              />
            </Tr>
          )}

          {/* Gross Pay */}
          <Tr>
            <Td>
              <Text variant="caption">GROSS PAY</Text>
            </Td>
            {workerShift.grossPay ? (
              <SummaryRow
                original={workerShift.grossPay}
                adjusted={adjustedGrossPay}
                originalString={getMoneyString(workerShift.grossPay)}
                adjustedString={getMoneyString(adjustedGrossPay)}
              />
            ) : (
              <Td>-</Td>
            )}
          </Tr>

          {/* Net Pay */}
          <Tr>
            <Td>
              <Text variant="caption">NET PAY</Text>
            </Td>
            {workerShift.netPay ? (
              <SummaryRow
                original={workerShift.netPay}
                adjusted={netPayMinusPrevInstantPayFee}
                originalString={getMoneyString(workerShift.netPay)}
                adjustedString={getMoneyString(netPayMinusPrevInstantPayFee)}
              />
            ) : (
              <Td>-</Td>
            )}
          </Tr>
        </Table>
        {adjustmentReason && (
          <Row mt={16} alignCenter>
            <Text variant="caption">ADJUSTMENT REASON:</Text>
            <Text variant="body1" style={{ marginLeft: theme.space.xxs }}>
              {adjustmentReason}
            </Text>
          </Row>
        )}
      </Row>
    </Row>
  )
}
