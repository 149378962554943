import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { CancelManyContent } from '../ManageWorkerModal/components/CancelManyContent'
import BulkActionShiftTable from './components/BulkActionShiftTable'
import BulkActionWorkerTable from './components/BulkActionWorkerTable'
import BulkCancelForm from './components/BulkCancelForm'
import BulkRobocall from './components/BulkRobocall'

export enum CancelInfo {
  Worker = 'Worker',
  Shift = 'Shift',
}

type BulkActionModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShifts: WorkerShift[]
  action?: 'cancel' | 'robocall' | 'cancel multiple shifts'
  cancelInfoToShow?: CancelInfo
  sentinelNotificationToUserId?: string
}

export default function BulkActionModal({
  workerShifts,
  isOpen,
  action,
  handleClose,
  cancelInfoToShow = CancelInfo.Worker,
  sentinelNotificationToUserId,
}: BulkActionModalProps) {
  const workers = workerShifts.map((ws) => ws.worker!)
  const { refetchActiveQueries } = useActiveQueries()

  function onSubmit() {
    refetchActiveQueries()
    handleClose()
  }

  const getTable = () => {
    switch (cancelInfoToShow) {
      case CancelInfo.Worker: {
        return <BulkActionWorkerTable workers={workers} />
      }
      case CancelInfo.Shift: {
        return <BulkActionShiftTable workerShifts={workerShifts} />
      }
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={`${action} ${workerShifts.length} ${cancelInfoToShow}${
        workerShifts.length > 1 ? 's' : ''
      }`}
      size={MODAL_SIZE.LARGE}
    >
      <Row flexCol fullWidth mt={32}>
        {/* ************ */}
        {/* Workers list */}
        {/* ************ */}
        {getTable()}
        {action === 'cancel multiple shifts' && (
          <CancelManyContent
            workerShift={workerShifts[0]}
            multipleWorkerShifts={workerShifts}
            handleClose={handleClose}
            sentinelNotificationToUserId={sentinelNotificationToUserId}
          />
        )}
        <Row mt={32}>
          {action === 'cancel' && (
            <BulkCancelForm
              workerShifts={workerShifts}
              onCancel={handleClose}
              onSubmit={onSubmit}
              sentinelNotificationToUserId={sentinelNotificationToUserId}
            />
          )}

          {action === 'robocall' && (
            <BulkRobocall
              workerShifts={workerShifts}
              handleClose={handleClose}
              sentinelNotificationToUserId={sentinelNotificationToUserId}
            />
          )}
        </Row>
      </Row>
    </Modal>
  )
}
