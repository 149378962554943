import {
  PaidBackup,
  ExtendedShift,
  WorkerShiftTimeToDestination,
} from '@traba/types'
import { Table } from 'src/components/base'
import {
  indexByWorkerId,
  useBulkWorkerScores,
} from 'src/hooks/useBulkWorkerScores'
import {
  buildWorkerShiftTimeToDestinationMap,
  getWorkerShiftTimeToDestinationKey,
} from 'src/utils/workerShiftUtils'
import PaidBackupTableRow from './PaidBackupTableRow'

export type PaidBackupsTableProps = {
  paidBackups: PaidBackup[]
  shift: ExtendedShift
  workerShiftTimeToDestinations?: WorkerShiftTimeToDestination[]
  favoriteWorkerIds: Set<string>
  refetchDependencies: () => void
}

export default function PaidBackupsTable(props: PaidBackupsTableProps) {
  const {
    paidBackups,
    shift,
    refetchDependencies,
    workerShiftTimeToDestinations,
    favoriteWorkerIds,
  } = props
  const sortedPaidBackups = paidBackups.sort(
    (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
  )

  const workerShiftTimeToDestinationsMap = buildWorkerShiftTimeToDestinationMap(
    workerShiftTimeToDestinations ?? [],
  )

  const paidBackupWorkerIds = paidBackups.map((pb) => pb.workerId)
  const { bulkWorkerScores } = useBulkWorkerScores(paidBackupWorkerIds)

  return (
    <Table
      headers={[
        'Worker Name',
        'Acct Status',
        'Worker Id',
        'Phone Number',
        'Tier',
        'Shift Status',
        'Distance (mi)',
        'Conf',
        'On Time Status',
        'Arrived',
        'ETA',
        '# Shifts Worked',
        'Action',
      ]}
    >
      {sortedPaidBackups?.map((pb) => {
        const timeToDestination = workerShiftTimeToDestinationsMap.get(
          getWorkerShiftTimeToDestinationKey({
            workerId: pb.workerId,
            shiftId: pb.shiftId,
          }),
        )
        return (
          <PaidBackupTableRow
            paidBackup={pb}
            shift={shift}
            timeToDestination={timeToDestination}
            favoriteWorkerIds={favoriteWorkerIds}
            workerScoresByWorkerId={indexByWorkerId(bulkWorkerScores)}
            refetchDependencies={refetchDependencies}
          />
        )
      })}
    </Table>
  )
}
