import { useAlert } from '@traba/context'
import { PayoutMethod, TransactionType, TransferStatus } from '@traba/types'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Button, Icon, Td, Tr } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import CopyTextIcon from 'src/components/base/CopyTextIcon/CopyTextIcon'
import TransferStatusBadge from 'src/components/TransferStatusBadge'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getMoneyString, truncateString } from 'src/utils/stringUtils'
import { PopulatedTransaction } from 'src/utils/workerShiftUtils'
import * as S from './TransactionsTable.styles'

type TransactionsTableRowProps = {
  transaction: PopulatedTransaction
  reverseTransfer: (
    transaction: PopulatedTransaction,
  ) => Promise<AxiosResponse<any, any>>
}
export default function TransactionsTableRow({
  transaction,
  reverseTransfer,
}: TransactionsTableRowProps) {
  const { worker, timezone } = transaction
  const { showError, showSuccess } = useAlert()
  const [isLoadingReversal, setIsLoadingReversal] = useState(false)

  const revertPaymentAccess = usePermissions([
    UserRolePermission.RevertPayments,
  ])

  async function handlePressReverse() {
    try {
      const isConfirmed = window.confirm('Confirm reversal?')
      if (!isConfirmed) {
        return
      }

      setIsLoadingReversal(true)
      await reverseTransfer(transaction)
      showSuccess(
        `Transaction ${transaction.id} was successfully reversed`,
        'Successfully reversed',
        3000,
      )
    } catch (err) {
      showError(
        `There was an error while reverting transaction ${transaction.id} `,
        'Transaction Error',
        3000,
      )
    } finally {
      setIsLoadingReversal(false)
    }
  }

  return (
    <Tr key={worker?.workerId}>
      <Td>
        {worker?.firstName} {worker?.lastName}
      </Td>
      <Td style={{ fontFamily: 'monospace' }}>
        {truncateString(worker?.uid ?? '')}{' '}
        <CopyTextIcon textToCopy={worker?.uid} />
      </Td>
      <S.TransactionTypeTd>
        <S.TransactionIconWrapper>
          {transaction.type === TransactionType.TRANSFER ? '💸' : '🏦'}
        </S.TransactionIconWrapper>
        {transaction.type.toLowerCase()}
      </S.TransactionTypeTd>
      <Td>{formatDateTimeWithTimezone(transaction.created, timezone)}</Td>
      <Td>
        <TransferStatusBadge
          transferStatus={
            transaction.reversed ? TransferStatus.REVERSED : transaction.status
          }
        />
      </Td>
      <Td>
        {transaction.method === PayoutMethod.INSTANT_PAYOUT ? (
          <Icon name="greenCheck" />
        ) : (
          '-'
        )}
      </Td>
      <Td>{getMoneyString(transaction.amount / 100)}</Td>
      <Td>
        {transaction.type === TransactionType.TRANSFER &&
          !transaction.reversed && (
            <Button
              variant={ButtonVariant.TEXT}
              onClick={handlePressReverse}
              loading={isLoadingReversal}
              disabled={!revertPaymentAccess}
            >
              Reverse
            </Button>
          )}
      </Td>
    </Tr>
  )
}
