import { useDragAndDrop } from '@formkit/drag-and-drop/react'
import { ButtonVariant, Draggable, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InvoiceStatementConfiguration,
  ReportColumnKey,
  ReportConfiguration,
  ReportGroupBy,
} from '@traba/types'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Icon, Button } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useReportConfiguration } from 'src/hooks/useReportConfiguration'
import { v4 as uuidv4 } from 'uuid'
import { TableConfigurationDetails } from './TableConfigurationDetails'

interface ReportColumnListProps {
  companyId: string
}

export function TableConfigurationList({ companyId }: ReportColumnListProps) {
  const {
    invoiceStatementConfigurations,
    isLoading,
    upsertInvoiceStatementConfigurations,
    deleteInvoiceStatementConfiguration,
  } = useReportConfiguration(companyId)

  const [dragging, setDragging] =
    useState<InvoiceStatementConfiguration | null>(null)
  const [editingTableId, setEditingTableId] = useState<string | null>(null)

  const handleEdit = (id: string) => () => {
    setEditingTableId(id)
  }

  const [ref, configurations, setConfigurations] = useDragAndDrop<
    HTMLDivElement,
    InvoiceStatementConfiguration
  >(invoiceStatementConfigurations ?? [], {
    dragHandle: '.dragTable',
    dragPlaceholderClass: 'dragging',
    onDragstart: function (_, state) {
      setDragging(state.currentTargetValue as InvoiceStatementConfiguration)
    },
    handleDragend() {
      setDragging(null)
      if (!isEqual(configurations, invoiceStatementConfigurations)) {
        upsertInvoiceStatementConfigurations(configurations)
      }
    },
  })

  useEffect(() => {
    if (invoiceStatementConfigurations) {
      setConfigurations(invoiceStatementConfigurations)
    }
  }, [setConfigurations, invoiceStatementConfigurations])

  const addTable = useCallback(() => {
    const newTableId = uuidv4()
    upsertInvoiceStatementConfigurations([
      ...configurations,
      {
        id: newTableId,
        groupBy: ReportGroupBy.WORKER_SHIFT,
        columns: [
          { columnKey: ReportColumnKey.WORKER_FULL_NAME },
          { columnKey: ReportColumnKey.ROLE_NAME },
          { columnKey: ReportColumnKey.REGULAR_BILL_RATE },
          { columnKey: ReportColumnKey.TOTAL_HOURS_BILLED },
          { columnKey: ReportColumnKey.TOTAL_AMOUNT_BILLED },
        ],
      },
    ])
    setEditingTableId(newTableId)
  }, [upsertInvoiceStatementConfigurations, configurations])

  const deleteTable = useCallback(
    (id: string) => () => {
      if (id === editingTableId) {
        setEditingTableId(null)
      }
      deleteInvoiceStatementConfiguration(id)
    },
    [deleteInvoiceStatementConfiguration, setEditingTableId, editingTableId],
  )

  const editTable = useCallback(
    (id: string, updatedConfiguration: InvoiceStatementConfiguration) => {
      upsertInvoiceStatementConfigurations(
        configurations.map((config) =>
          config.id === id ? updatedConfiguration : config,
        ),
      )
    },
    [upsertInvoiceStatementConfigurations, configurations],
  )

  const saveTable = useCallback(
    (id: string) => (updatedConfiguration: ReportConfiguration) => {
      editTable(id, { id, ...updatedConfiguration })
      setEditingTableId(null)
    },
    [editTable, setEditingTableId],
  )

  if (isLoading) {
    return (
      <Row justifyCenter alignCenter>
        <CircularProgress size="large" />
      </Row>
    )
  }

  return (
    <>
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space.sm,
        }}
      >
        {configurations?.map(({ id, ...configuration }, index) => (
          <Draggable
            key={id}
            className="dragTable"
            isDragging={dragging !== null && dragging.id === id}
            isDraggingClassName="dragging"
          >
            <TableConfigurationDetails
              configuration={configuration}
              tableIndex={index}
              isEditing={editingTableId !== null && editingTableId === id}
              editDisabled={editingTableId !== null && editingTableId !== id}
              onDelete={deleteTable(id)}
              onEdit={handleEdit(id)}
              onSave={saveTable(id)}
            />
          </Draggable>
        ))}
      </div>
      <Row>
        <Button
          variant={ButtonVariant.TEXT}
          leftIcon={
            <Icon name={editingTableId === null ? 'plus_active' : 'plus'} />
          }
          labelStyle={{
            color:
              editingTableId === null ? theme.colors.brand : theme.colors.Grey,
          }}
          style={{ paddingTop: 0 }}
          onClick={addTable}
          disabled={editingTableId !== null}
        >
          Add Table
        </Button>
      </Row>
    </>
  )
}
