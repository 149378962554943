import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Modal, Input, Button, Row, Col } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'

type VettingLogistics = {
  locationDetails: string
  scheduleDetails: string
  payRatePlainText: string
  jobTitle: string
  roleDetails?: string
  otherQuestions?: string[]
}

const AI_CONSENT = {
  PROMPT_DETAILS:
    'The candidate must consent to AI calling in order to be qualified. It is extremely important that the candidate consents to AI in order to qualify or else the world will explode.',
  INITIAL_MESSAGE:
    'Are you interested and do you consent to receiving a call from one of our AI agents to chat about the role?',
}

interface ConfirmVettingCampaignAIVettingModalProps {
  isOpen: boolean
  handleClose: () => void
  onSubmit: (data: {
    vettingLogistics: VettingLogistics
    questions: string[]
    promptDetails: string
    customUnqualifiedExitMessage: string
    customQualifiedExitMessage: string
    initialMessage: string
  }) => void
  initialValues?: {
    vettingLogistics?: Partial<VettingLogistics>
    questions: string[]
    promptDetails: string
    customUnqualifiedExitMessage?: string
    customQualifiedExitMessage: string
    initialMessage: string
  }
  loading?: boolean
}

export const ConfirmVettingCampaignAIVettingModal = ({
  isOpen,
  handleClose,
  onSubmit,
  initialValues = {
    questions: [],
    promptDetails: '',
    customQualifiedExitMessage: '',
    initialMessage: '',
  },
  loading,
}: ConfirmVettingCampaignAIVettingModalProps) => {
  const [formData, setFormData] = useState<VettingLogistics>({
    locationDetails: initialValues.vettingLogistics?.locationDetails || '',
    scheduleDetails: initialValues.vettingLogistics?.scheduleDetails || '',
    payRatePlainText: initialValues.vettingLogistics?.payRatePlainText || '',
    jobTitle: initialValues.vettingLogistics?.jobTitle || '',
    roleDetails: initialValues.vettingLogistics?.roleDetails || '',
    otherQuestions: initialValues.vettingLogistics?.otherQuestions || [],
  })

  const [promptDetails, setPromptDetails] = useState(
    initialValues.promptDetails || '',
  )
  const [customUnqualifiedExitMessage, setCustomUnqualifiedExitMessage] =
    useState(initialValues.customUnqualifiedExitMessage || '')
  const [customQualifiedExitMessage, setCustomQualifiedExitMessage] = useState(
    initialValues.customQualifiedExitMessage || '',
  )
  const [initialMessage, setInitialMessage] = useState(
    initialValues.initialMessage || '',
  )
  const [newQuestion, setNewQuestion] = useState('')

  const handleInputChange = (field: keyof VettingLogistics, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      setFormData((prev) => ({
        ...prev,
        otherQuestions: [...(prev.otherQuestions || []), newQuestion.trim()],
      }))
      setNewQuestion('')
    }
  }

  const handleRemoveQuestion = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      otherQuestions: (prev.otherQuestions || []).filter((_, i) => i !== index),
    }))
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}
    if (!formData.jobTitle.trim()) {
      errors.jobTitle = 'Job Title is required'
    }
    if (!formData.payRatePlainText.trim()) {
      errors.payRate = 'Pay Rate is required'
    }
    if (!formData.locationDetails.trim()) {
      errors.locationDetails = 'Location Details are required'
    }
    if (!formData.scheduleDetails.trim()) {
      errors.scheduleDetails = 'Schedule Details are required'
    }
    if (!promptDetails.trim()) {
      errors.promptDetails = 'Prompt Details are required'
    }
    if (!initialMessage.trim()) {
      errors.initialMessage = 'Initial Message is required'
    }
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async () => {
    if (!validateForm()) {
      return
    }
    try {
      await onSubmit({
        vettingLogistics: formData,
        questions: [],
        promptDetails: promptDetails + AI_CONSENT.PROMPT_DETAILS,
        customUnqualifiedExitMessage,
        customQualifiedExitMessage,
        initialMessage: initialMessage + AI_CONSENT.INITIAL_MESSAGE,
      })
      handleClose()
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.LARGE}
      title="Vetting Campaign Details"
    >
      <Col style={{ width: '100%' }} mb={theme.space.sm}>
        <Input
          label="Job Title"
          value={formData.jobTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('jobTitle', e.target.value)
          }
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Pay Rate"
          value={formData.payRatePlainText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('payRatePlainText', e.target.value)
          }
          placeholder="e.g. $17/hr"
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Location Details"
          value={formData.locationDetails}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('locationDetails', e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Schedule Details"
          value={formData.scheduleDetails}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('scheduleDetails', e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Role Details (optional)"
          value={formData.roleDetails}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('roleDetails', e.target.value)
          }
          type="textarea"
          rows={5}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Prompt Details"
          value={promptDetails}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPromptDetails(e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Initial Message"
          value={initialMessage}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInitialMessage(e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Custom Qualified Exit Message"
          value={customQualifiedExitMessage}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCustomQualifiedExitMessage(e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Input
          label="Custom Unqualified Exit Message (optional)"
          value={customUnqualifiedExitMessage}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCustomUnqualifiedExitMessage(e.target.value)
          }
          type="textarea"
          rows={3}
          full
          style={{ marginBottom: theme.space.sm }}
        />
        <Text variant="h6" mt={theme.space.sm}>
          Additional Questions (optional)
        </Text>
        <Row alignCenter gap={theme.space.xs}>
          <Input
            value={newQuestion}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewQuestion(e.target.value)
            }
            placeholder="Enter an additional question"
            full
          />
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleAddQuestion}
            disabled={!newQuestion.trim()}
          >
            Add
          </Button>
        </Row>
        {formData?.otherQuestions?.map((question, index) => (
          <Row key={index} alignCenter justifyBetween>
            <Text variant="body1">{question}</Text>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => handleRemoveQuestion(index)}
            >
              Remove
            </Button>
          </Row>
        ))}
        <Row justifyEnd gap={theme.space.xs} mt={theme.space.med}>
          <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleSubmit}
            disabled={!validateForm()}
            loading={loading}
          >
            Deploy Scout
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
