export enum TierLevel {
  TIER_ONE = 'TIER_ONE',
  TIER_TWO = 'TIER_TWO',
  TIER_THREE = 'TIER_THREE',
  UNPROVEN = 'UNPROVEN',
  TIER_FOUR = 'TIER_FOUR',
  TIER_FIVE = 'TIER_FIVE',
}

export type WorkerTier = {
  tierLevel: TierLevel
  minimumReliabilityScore?: number
  minimumBusinessApprovalScore?: number
  minimumNumberCompletions?: number
  minimumNumberUniqueBusinesses?: number
  minimumNumberFavorites?: number
}

export const minimumTierMap = {
  TIER_ONE: TierLevel.TIER_ONE,
  TIER_TWO: TierLevel.TIER_TWO,
  TIER_THREE: TierLevel.TIER_THREE,
  TIER_FOUR: TierLevel.TIER_FOUR,
  TIER_FIVE: TierLevel.TIER_FIVE,
  UNPROVEN: TierLevel.UNPROVEN,
}
