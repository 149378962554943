import { CircularProgress } from '@mui/material'
import { VIRTUOSO_DYNAMIC_HEIGHT_CHANGE_HEIGHT } from '@traba/consts'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerIncentive, WorkerIncentiveReviewAction } from '@traba/types'
import { useCallback, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { PendingIncentiveItem } from 'src/components/PendingIncentiveItem/PendingIncentiveItem'
import {
  useBulkDismissWorkerIncentivesMutation,
  usePendingWorkerIncentives,
} from 'src/hooks/useIncentives'
import {
  FiltersSection,
  FilterProps,
  HiddenFilterTypes,
} from '../FiltersSection'
interface PendingIncentivesProps {
  filterProps: FilterProps
}

export const PendingIncentives = (props: PendingIncentivesProps) => {
  const bulkDismissIncentives = useBulkDismissWorkerIncentivesMutation()
  const { filterProps } = props
  const [selectedIncentives, setSelectedIncentives] = useState<string[]>([])
  const [expandedIncentives, setExpandedIncentives] = useState<string[]>([])
  const [submitting, setSubmitting] = useState<boolean>(false)
  const filter = {
    regionIds: filterProps.regionIds.length ? filterProps.regionIds : undefined,
    workerIncentiveId: filterProps.workerIncentiveId || undefined,
    incentiveId: filterProps.incentiveId || undefined,
    after: filterProps.dateRange[0]?.toISOString(),
    before: filterProps.dateRange[1]?.toISOString(),
    ...(filterProps.workerId ? { workerIds: [filterProps.workerId] } : {}),
    ...(filterProps.shiftId ? { shiftIds: [filterProps.shiftId] } : {}),
  }

  const {
    isLoading,
    isFetching,
    pendingIncentives,
    refetch: refetchPendingIncentives,
    reviewWorkerIncentive,
  } = usePendingWorkerIncentives(filter)

  const allSelected =
    pendingIncentives && selectedIncentives.length === pendingIncentives.length

  const handleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelectedIncentives([])
    } else {
      setSelectedIncentives(
        pendingIncentives?.map((pi) => pi.id ?? pi.workerIncentiveId) ?? [],
      )
    }
  }, [allSelected, setSelectedIncentives, pendingIncentives])

  const handleExpandClick = useCallback(
    (id: string) => {
      setExpandedIncentives((curr) =>
        curr.includes(id) ? curr.filter((i) => i !== id) : [...curr, id],
      )
    },
    [setExpandedIncentives],
  )

  const handleActionSubmission = useCallback(
    async (
      pi: WorkerIncentive,
      action: WorkerIncentiveReviewAction,
      bulk = false,
    ) => {
      let reversalReason
      if (action === WorkerIncentiveReviewAction.REVERSE) {
        reversalReason = window.prompt('Please enter the reversal reason:')
        if (!reversalReason) {
          alert('A reason is required to proceed with the reversal.')
          return
        }
      } else {
        const isConfirmed =
          bulk ||
          window.confirm(`Do you want to ${action} this worker incentive?`)
        if (!isConfirmed) {
          return
        }
      }
      setSubmitting(true)
      reviewWorkerIncentive(
        {
          workerIncentiveId: pi.id ?? pi.workerIncentiveId,
          workerId: pi.workerId,
          action,
          reversalReason,
        },
        {
          onSuccess: () => {
            refetchPendingIncentives()
            setSubmitting(false)
          },
        },
      )
    },
    [reviewWorkerIncentive],
  )

  const handleBulkDismiss = useCallback(async () => {
    const isConfirmed = window.confirm(
      `Do you want to ${WorkerIncentiveReviewAction.DISMISS} ${selectedIncentives.length} worker incentives`,
    )
    if (isConfirmed) {
      bulkDismissIncentives(selectedIncentives, {
        onSuccess: () => refetchPendingIncentives(),
      })
      setSelectedIncentives([])
    }
  }, [handleActionSubmission, refetchPendingIncentives, selectedIncentives])

  const handleSelectIncenitve = useCallback(
    (wi: WorkerIncentive) => () => {
      setSelectedIncentives((curr) => {
        return curr.includes(wi.id ?? wi.workerIncentiveId)
          ? curr.filter((i) => i !== wi.id)
          : [...curr, wi.id ?? wi.workerIncentiveId]
      })
    },
    [setSelectedIncentives],
  )

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Text variant="h4">Pending Incentives</Text>
      <FiltersSection
        isLoading={!pendingIncentives || isLoading || submitting}
        onClickSearch={refetchPendingIncentives}
        filterProps={filterProps}
        hiddenFilters={[
          HiddenFilterTypes.PAYMENT_STATUS_OPTIONS,
          HiddenFilterTypes.WARDEN_FLAG_ONLY,
          HiddenFilterTypes.WORKER_EDITED_ONLY,
          HiddenFilterTypes.COMPANY_OPTIONS,
        ]}
      />

      <Row alignCenter gap={theme.space.xs}>
        <Text variant="caption" style={{ fontSize: 12 }}>
          {selectedIncentives.length} Selected
        </Text>

        <Button variant={ButtonVariant.OUTLINED} onClick={handleSelectAll}>
          {allSelected ? 'Unselect All' : `Select All`}
        </Button>
        <Button
          variant={ButtonVariant.PURPLEGRADIENT}
          onClick={handleBulkDismiss}
          loading={isLoading || isFetching}
          disabled={selectedIncentives.length === 0}
        >
          Bulk Dismiss
        </Button>
      </Row>
      {isLoading || isFetching ? (
        <CircularProgress size={'medium'} />
      ) : pendingIncentives && pendingIncentives.length > 0 ? (
        <Virtuoso
          data={pendingIncentives}
          // the following sets a dynamic height to take up the viewport
          // aside from the height of components above. 435px will need to change if the height of other components changes
          style={{
            height: `calc(100vh - ${VIRTUOSO_DYNAMIC_HEIGHT_CHANGE_HEIGHT}px)`,
            marginTop: theme.space.xs,
          }}
          totalCount={pendingIncentives.length}
          itemContent={(_, pendingIncentive) => (
            <Row mt={theme.space.xs}>
              <PendingIncentiveItem
                key={pendingIncentive.id}
                pendingIncentive={pendingIncentive}
                isLoading={isLoading || isFetching || submitting}
                isSelected={selectedIncentives.includes(
                  pendingIncentive.id ?? pendingIncentive.workerIncentiveId,
                )}
                onSelect={handleSelectIncenitve(pendingIncentive)}
                handleActionSubmission={handleActionSubmission}
                handleExpandClick={handleExpandClick}
                collapsed={
                  !expandedIncentives.includes(
                    pendingIncentive.id ?? pendingIncentive.workerIncentiveId,
                  )
                }
              />
            </Row>
          )}
        />
      ) : (
        <Text>Worker incentives that are pending review will show up here</Text>
      )}
    </Col>
  )
}
