import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  MultiDatePicker,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CancellationSource,
  Shift,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  dateMinFromArray,
  getMinDateForScheduleChangeDatePicker,
  getShiftDatesForSchedules,
  isRecurringShiftRequest,
} from '@traba/utils'
import { isSameDay } from 'date-fns'
import { useMemo, useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { useShiftRequestEditsMutation } from 'src/hooks/useShiftRequestEdits'
import { cancelShiftsById } from 'src/hooks/useShifts'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSuccess: () => void
  onBack: () => void
  shifts?: Shift[]
}

export const EditScheduleCancel = (props: Props) => {
  const { shiftRequestParent, onBack, shifts, onSuccess } = props
  const recurringShiftRequests = new Set(
    shiftRequestParent.shiftRequests
      .filter(isRecurringShiftRequest)
      .map((sr) => sr.shiftRequestId),
  )
  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    isRecurringShiftRequest,
  )
  const { showError } = useAlert()
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const shiftRequestIdsToShift = useMemo(() => {
    return Array.from(
      shifts?.reduce((acc, shift) => {
        if (shift.status === ShiftStatus.ACTIVE) {
          if (!acc.has(shift.shiftRequestId)) {
            acc.set(shift.shiftRequestId, [])
          }
          acc.get(shift.shiftRequestId)?.push(shift)
        }
        return acc
      }, new Map<string, Shift[]>()) || [],
    )
  }, [shifts])

  const { editShiftRequest, isPending } = useShiftRequestEditsMutation(
    shiftRequestParent.shiftRequestParentId,
    shiftRequestParent.companyId,
  )
  if (firstRecurringShiftRequest === undefined) {
    return null
  }
  const onConfirm = async () => {
    try {
      if (!selectedDate) {
        return showError('Please select a date to remove from')
      }
      const chosenOriginalStartTime = combineTwoDatesForDateAndTime(
        selectedDate,
        dateMinFromArray(
          firstRecurringShiftRequest.schedules.map((s) => s.startTime),
        ),
      )
      const [filteredRecurringShiftRequests, oneOffShiftRequests] =
        shiftRequestIdsToShift.reduce<
          [Array<[string, Shift[]]>, Array<[string, Shift[]]>]
        >(
          (acc, curr) => {
            const [shiftRequestId, shifts] = curr
            if (recurringShiftRequests.has(shiftRequestId)) {
              acc[0].push([shiftRequestId, shifts])
            } else {
              acc[1].push([shiftRequestId, shifts])
            }
            return acc
          },
          [[], []],
        )

      await Promise.all([
        ...filteredRecurringShiftRequests.map(([shiftRequestId, _]) =>
          editShiftRequest({
            edit: {
              shiftRequestId,
              editType: ShiftRequestEditType.ALL_FUTURE,
              cancellationSource: CancellationSource.Ops,
              cancellationReason: 'Cancelled by ops user',
              status: ShiftStatus.CANCELED,
              canceledAt: new Date(),
              originalStartTime: chosenOriginalStartTime,
            },
          }),
        ),
        cancelShiftsById(
          oneOffShiftRequests.flatMap(([_, shifts]) => {
            return shifts
              .filter(
                (shift) => shift.originalStartTime <= chosenOriginalStartTime,
              )
              .flatMap((shift) => [shift.id])
          }),
          'Cancelled by ops user',
          CancellationSource.Ops,
          new Date(),
        ),
      ])
      onSuccess()
    } catch (error) {
      showError('Failed to cancel, please try again')
    }
  }

  const shiftTimes = getShiftDatesForSchedules(
    firstRecurringShiftRequest.schedules,
  )

  const mapDaysForDatePicker = ({ date }: { date: DateObject }) => {
    const isUnavailable = !shiftTimes.some((shiftTime) =>
      isSameDay(date.toDate(), new Date(shiftTime)),
    )
    return {
      disabled: isUnavailable,
    }
  }

  return (
    <>
      <>
        <Text variant="h5" mt={theme.space.lg}>
          Select the date after which this schedule should be canceled
        </Text>
        <InlineBanner
          style={{
            marginTop: theme.space.xs,
            marginBottom: theme.space.xs,
          }}
          severity="info"
          text="For example, if you want cancel ALL SHIFTS for this schedule on and after Jun.30th, select Jun.30th"
        />
        <MultiDatePicker
          minDate={getMinDateForScheduleChangeDatePicker(
            combineTwoDatesForDateAndTime(
              new Date(),
              dateMinFromArray(
                firstRecurringShiftRequest.schedules.map((s) => s.startTime),
              ),
            ),
          )}
          selectedDates={selectedDate ? [selectedDate] : null}
          onSelectDates={(dates: Date[]) => {
            setSelectedDate(dates[0])
          }}
          multiple={false}
          mapDays={mapDaysForDatePicker}
        />
      </>
      <Row justifyBetween mt={theme.space.lg}>
        <Button onClick={onBack} variant={ButtonVariant.OUTLINED}>
          Back
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isPending || selectedDate === null}
          loading={isPending}
        >
          Confirm cancel schedule
        </Button>
      </Row>
    </>
  )
}
