import styled from 'styled-components'

export const Nav = styled.nav`
  border-bottom: 1px solid #e2e6e9;
  padding: 16px 60px;
  margin: 0 -40px;

  p {
    cursor: pointer;
    font-weight: 500;

    img {
      margin-right: 6px;
    }
  }
`

export const Cover = styled.div`
  background-color: #f2f3f7;
  height: 160px;
  margin: 0 -40px;
`

export const DropdownWrapper = styled.div`
  margin-right: 8px;
  button {
    padding: 6px 16px;
    margin-bottom: -10px;
    .MuiBox-root > div {
      margin-top: 5px;
    }
  }
`

export const FeedbackRating = styled.div``
