import styled from 'styled-components'

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-end;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`
export const SelectedCheckbox = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  width: 26px;
  height: 26px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.Grey30 : theme.colors.brand};
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
`
export const NotSelectedCheckbox = styled.div`
  border-radius: 8px;
  width: 26px;
  height: 26px;
  border: 2px solid #e5e5e5;
  background: #fff;
  display: flex;
  justify-self: flex-end;
`
export const Checkmark = styled.div`
  display: inline-block;
  width: 20px;
  height: 16px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`
export const CheckmarkStem = styled.div`
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: #fff;
  left: 11px;
  top: 0px;
`

export const CheckmarkKick = styled.div`
  position: absolute;
  width: 7px;
  height: 2px;
  background-color: #fff;
  left: 6px;
  top: 12px;
`
