import { Row } from 'src/components/base'
import useNotes from 'src/hooks/useNotes'
import { CompanyNoteDrawer } from './CompanyNoteDrawer'
import { ShiftNoteDrawer } from './ShiftNoteDrawer'

interface ShiftAndCompanyNoteDrawerProps {
  shiftId: string
  companyId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideFAB?: boolean
}

export const ShiftAndCompanyNoteDrawer = (
  props: ShiftAndCompanyNoteDrawerProps,
) => {
  const { shiftId, companyId, isOpen, setIsOpen, hideFAB } = props
  const { useShiftNotes } = useNotes()
  const { shiftNotes = [] } = useShiftNotes(shiftId)
  return (
    <Row>
      <ShiftNoteDrawer
        shiftId={shiftId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hideFAB
        noCloseButton
        dualNote
      />
      <CompanyNoteDrawer
        companyId={companyId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noteCount={shiftNotes.length}
        hideFAB={hideFAB}
      />
    </Row>
  )
}
