import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompany } from 'src/hooks/useCompany'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { PostShiftForm } from './components/PostShiftForm/PostShiftForm'
import { PostShiftFormWithSchedules } from './components/PostShiftForm/PostShiftFormWithSchedules'

export const PostShiftScreen = () => {
  const [search] = useSearchParams()
  const { companyId = '' } = useParams<{ companyId: string }>()
  const { company, isLoading: companyLoading } = useCompany(companyId)
  const { locations, isLoading: locationsLoading } = useLocations(companyId)
  const { roles, isLoading: rolesLoading } = useRoles({ companyId })
  const { activeCompanyUsers, isLoading: isLoadingUsers } =
    useCompanyUsers(companyId)
  const { rosters, isLoading: rostersLoading } = useRosters(companyId)
  const { hotSettings, isLoading: hotSettingsLoading } = useHotSettings()
  const shiftRequestParentId = search.get('shiftRequestParentId')

  const navigate = useNavigate()

  if (
    companyLoading ||
    locationsLoading ||
    rolesLoading ||
    isLoadingUsers ||
    rostersLoading ||
    hotSettingsLoading
  ) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <CircularProgress
            size={75}
            sx={{
              position: 'absolute',
              top: '200px',
              left: '50%',
              marginTop: '-18px',
              marginLeft: '-18px',
            }}
          />
        </Row>
      </MainLayout>
    )
  }

  if (!company) {
    return (
      <MainLayout>
        <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
          <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
          Go back
        </Button>
        <Divider />
        <Row justifyBetween alignCenter mt={theme.space.xs}>
          <Text variant="h3">
            We are having trouble finding your company's information
          </Text>
        </Row>
      </MainLayout>
    )
  }

  const paramsForPostShiftForm = {
    company,
    locations,
    companyUsers: activeCompanyUsers,
    roles,
    rosters,
    minHourlyPayRate: getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    }),
  }

  return (
    <MainLayout>
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Go back
      </Button>
      <Divider />
      {shiftRequestParentId ? (
        <Row my={theme.space.sm}>
          <Text variant="h3" style={{ marginRight: theme.space.xxxs }}>
            Add one off shifts to schedule
          </Text>
        </Row>
      ) : (
        <Row alignCenter mt={theme.space.xs} mb={theme.space.sm}>
          <Text variant="h3" style={{ marginRight: theme.space.xxxs }}>
            Posting Shift for
          </Text>
          <Text variant="h3" style={{ color: theme.colors.brand }}>
            {company.employerName}
          </Text>
        </Row>
      )}

      {hotSettings?.recurringSchedulesEnabled ||
      (hotSettings?.recurringSchedulesEnabledCompanies &&
        hotSettings?.recurringSchedulesEnabledCompanies?.includes(
          company.companyId,
        )) ? (
        <PostShiftFormWithSchedules {...paramsForPostShiftForm} />
      ) : (
        <PostShiftForm {...paramsForPostShiftForm} />
      )}
    </MainLayout>
  )
}
