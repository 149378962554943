import { FormControl, MenuItem, Select } from '@mui/material'
import { Text } from '@traba/react-components'
import {
  BusinessCancellationReasons,
  CancellationSource,
  Shift,
} from '@traba/types'
import { Col, DatePicker, Input, Row } from 'src/components/base'

export type InputProps = {
  shift: Shift
  sourceDropdownValue: CancellationSource | string
  setSourceDropdownValue: (value: string) => void
  reasonDropdownValue: BusinessCancellationReasons | string
  setReasonDropdownValue: (value: string) => void
  customReasonInput: string
  setCustomReasonInput: (value: string) => void
  canceledAt: Date | null
  setCanceledAt: (value: Date | null) => void
}

export const InputFields = ({
  shift,
  sourceDropdownValue,
  setSourceDropdownValue,
  reasonDropdownValue,
  setReasonDropdownValue,
  customReasonInput,
  setCustomReasonInput,
  canceledAt,
  setCanceledAt,
}: InputProps) => {
  const { shiftId } = shift

  const shiftInput = (
    <Input
      width="100%"
      value={shiftId}
      disabled={true}
      containerStyle={{ marginTop: '10px', height: 50 }}
    />
  )

  const sourceInput = (
    <FormControl fullWidth>
      <Select
        id="cancellation-dropdown"
        value={sourceDropdownValue}
        onChange={(evt) => {
          setSourceDropdownValue(evt.target.value)
        }}
        placeholder="Reason"
        style={{ height: 50 }}
      >
        <MenuItem value={CancellationSource.Business}>
          {CancellationSource.Business}
        </MenuItem>
        <MenuItem value={CancellationSource.Ops}>
          {CancellationSource.Ops}
        </MenuItem>
      </Select>
    </FormControl>
  )

  const reasonInput = (
    <FormControl fullWidth>
      <Select
        id="cancellation-dropdown"
        value={reasonDropdownValue}
        onChange={(evt) => {
          setReasonDropdownValue(evt.target.value)
        }}
        placeholder="Reason"
        style={{ height: 50 }}
      >
        {Object.values(BusinessCancellationReasons).map((rsn) => (
          <MenuItem value={rsn}>
            {rsn === BusinessCancellationReasons.WEATHER ? (
              <div style={{ display: 'flex', fontWeight: 'inherit' }}>
                <Text
                  style={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                  }}
                >
                  {rsn}
                </Text>
                <Text style={{ marginLeft: 6 }}>
                  (exempt from worker pay and business charge)
                </Text>
              </div>
            ) : (
              rsn
            )}
          </MenuItem>
        ))}
      </Select>
      {reasonDropdownValue === BusinessCancellationReasons.OTHER && (
        <Input
          width="100%"
          value={customReasonInput}
          autoFocus
          onChange={(e: any) => {
            setCustomReasonInput(e.target.value)
          }}
          containerStyle={{ marginTop: '10px', height: 50 }}
        />
      )}
    </FormControl>
  )
  return (
    <Col style={{ flex: 1, justifyContent: 'center' }}>
      <Row style={{ alignItems: 'center' }}>
        <Text variant="h5" pt={'12px'} mr={'64px'}>
          Shift ID
        </Text>
        {shiftInput}
      </Row>
      <Row mt={'32px'} style={{ alignItems: 'center' }}>
        <Text variant="h5" pt={'12px'} mr={'64px'}>
          Source
        </Text>
        {sourceInput}
      </Row>
      <Row mt={'32px'} style={{ alignItems: 'center' }}>
        <Text variant="h5" pt={'12px'} mr={'64px'}>
          Cancellation Reason
        </Text>
        {reasonInput}
      </Row>
      <Row mt={'32px'} style={{ alignItems: 'center' }}>
        <Text variant="h5" pt={'12px'} mr={'64px'}>
          Canceled At
        </Text>
        <FormControl fullWidth>
          <DatePicker
            date={canceledAt}
            setDate={(canceledAt) => {
              setCanceledAt(canceledAt)
            }}
            label="Canceled At"
            showTimeFieldInPopover
            granularity="minute"
            isClearable={true}
            maxDate={new Date(shift.endTime)}
          />
        </FormControl>
      </Row>
    </Col>
  )
}
