import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import {
  AddRecurringWorkerShiftResponse,
  GenericAcceptShiftBypasses,
} from '@traba/types'

export const addWorkerToRecurringWorkerShift = async (
  workerId: string,
  shiftRequestId: string,
  originalStartTime: Date,
  bypasses: GenericAcceptShiftBypasses,
): Promise<AddRecurringWorkerShiftResponse> => {
  try {
    const response = await trabaApi.post(
      `recurring-worker-shifts/worker/${workerId}`,
      {
        shiftRequestId,
        originalStartTime,
        bypasses,
      },
    )
    return { ...response.data, workerId }
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}
