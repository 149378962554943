export const sortQuestionKeys = (
  selectedQuestions: string[],
  customQuestions: string[],
  generalQuestions: { key: string; displayName: string }[] | undefined,
) => {
  const nonCustomQuestions = selectedQuestions.filter(
    (q) => !customQuestions.includes(q),
  )
  const generalQuestionKeys = nonCustomQuestions.filter((q) =>
    generalQuestions?.some((gq) => gq.key === q),
  )
  const otherQuestions = nonCustomQuestions.filter(
    (q) => !generalQuestions?.some((gq) => gq.key === q),
  )

  const sortedGeneralQuestions = generalQuestionKeys.sort((a, b) => {
    const aIndex = generalQuestions?.findIndex((q) => q.key === a) ?? 0
    const bIndex = generalQuestions?.findIndex((q) => q.key === b) ?? 0
    return aIndex - bIndex
  })

  const sortedOtherQuestions = otherQuestions.sort()
  return [...sortedGeneralQuestions, ...sortedOtherQuestions]
}
