import { useCallback, useEffect } from 'react'
import AudioPlayer from 'react-modern-audio-player'
import { useAnalytics } from '../../hooks/useAnalytics'

export function Player({
  mediaUrl,
  workerId,
}: {
  mediaUrl: string
  workerId?: string
}) {
  const { trackAnalytics } = useAnalytics()

  const handlePlayButtonClick = useCallback(
    () => trackAnalytics('Openphone Recording Played', { mediaUrl, workerId }),
    [mediaUrl, trackAnalytics, workerId],
  )

  useEffect(() => {
    const playButton = document.querySelector('.play-button')

    if (playButton) {
      playButton.addEventListener('click', handlePlayButtonClick)
    }

    return () => {
      if (playButton) {
        playButton.removeEventListener('click', handlePlayButtonClick)
      }
    }
  }, [handlePlayButtonClick])

  return (
    <AudioPlayer
      playList={[{ src: mediaUrl, id: 1 }]}
      audioInitialState={{
        volume: 0.2,
        curPlayId: 1,
      }}
      activeUI={{
        progress: 'bar',
        playButton: true,
        trackTime: true,
        trackInfo: true,
        volume: true,
      }}
      rootContainerProps={{ colorScheme: 'light' }}
    />
  )
}
