import { Tooltip } from '@mui/material'
import { Col, LoadingSpinner, SvgIcon } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { allCompanyWideMembers, getUserNameWithRole } from '@traba/utils'
import { Row } from 'src/components/base'
import {
  useCompanyUsers,
  useLocationAssignedActiveUsers,
} from 'src/hooks/useCompanyUsers'
import { TooltipHeader, TooltipText } from './styles'

interface LocationAssignedUsersWithTooltipProps {
  locationId: string
  companyId: string
}

export default function LocationAssignedUsersWithTooltip({
  companyId,
  locationId,
}: LocationAssignedUsersWithTooltipProps) {
  const { activeCompanyUsers = [], isLoading: isLoadingMembers } =
    useCompanyUsers(companyId)
  const { locationAssignedUsers, isLoading: isLoadingLocationAssignedUsers } =
    useLocationAssignedActiveUsers(companyId, locationId)
  const companyWideMembers = allCompanyWideMembers(activeCompanyUsers)

  const numberOfUsersAtLocation =
    locationAssignedUsers.length + companyWideMembers.length

  const tooltipContent = (
    <Col gap={theme.space.xxs}>
      <div>
        <TooltipHeader>Location-assigned members</TooltipHeader>
        {locationAssignedUsers.length === 0 && (
          <TooltipText>0 members</TooltipText>
        )}
        {locationAssignedUsers.map((user) => (
          <TooltipText key={user.uid}>{getUserNameWithRole(user)}</TooltipText>
        ))}
      </div>
      <div>
        <TooltipHeader>Company wide members with access</TooltipHeader>
        {companyWideMembers.map((user) => (
          <TooltipText key={user.uid}>{getUserNameWithRole(user)}</TooltipText>
        ))}
      </div>
    </Col>
  )

  const isLoading = isLoadingMembers || isLoadingLocationAssignedUsers

  return (
    <Tooltip title={tooltipContent}>
      <div>
        <Row gap={theme.space.xxxs} alignCenter>
          <Row alignCenter>
            <SvgIcon
              name="location"
              size={16}
              color={theme.colors.MidnightBlue}
            />
          </Row>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            `${numberOfUsersAtLocation} user${makePlural(numberOfUsersAtLocation)}`
          )}
        </Row>
      </div>
    </Tooltip>
  )
}
