import { WorkerJobPlacementAction } from '@traba/types'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerJobPlacementContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'

type WorkerJobPlacementTerminateModalProps = {
  workerId: string
  companyId: string
  companyName: string
  handleClose: () => void
  isOpen: boolean
}

export const WorkerJobPlacementTerminateModal = (
  props: WorkerJobPlacementTerminateModalProps,
) => {
  const { isOpen, handleClose, workerId, companyId, companyName } = props

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={'Terminate ' + companyName}
    >
      <Row flexCol fullHeight fullWidth>
        {
          <WorkerJobPlacementContent
            companyId={companyId}
            handleClose={handleClose}
            workerId={workerId}
            action={WorkerJobPlacementAction.TERMINATE_WORKER}
          />
        }
      </Row>
    </Modal>
  )
}
