import { useQueryClient as useTanstackQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

export const useActiveQueries = () => {
  const setCurrentDate = () => {
    const currentDate = new Date()
    return format(currentDate, 'hh:mm aa')
  }

  const [refetchLoading, setRefetchLoading] = useState<boolean>(false)
  const [lastUpdated, setLastUpdated] = useState<string>(() => setCurrentDate())

  const queryClient = useQueryClient()
  const tanstackQueryClient = useTanstackQueryClient()
  const refetchActiveQueries = async () => {
    setRefetchLoading(true)
    // tanstack is the version we are moving to, before fully migrated, we need to refetch active queries for both versions
    await Promise.all([
      queryClient.refetchQueries({ active: true }),
      tanstackQueryClient.refetchQueries({ type: 'active' }),
    ])
    setRefetchLoading(false)
    setLastUpdated(() => setCurrentDate())
  }

  return {
    refetchLoading,
    lastUpdated,
    refetchActiveQueries,
  }
}
