import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { ScheduleShiftRequestInvitation } from '@traba/types'

const sendScheduleInvitation = async ({
  companyId,
  shiftRequestId,
  workerIds,
}: {
  companyId: string
  shiftRequestId: string
  workerIds: string[]
}) => {
  await trabaApi.post(
    `companies/${companyId}/shift-request/${shiftRequestId}/invitations`,
    { workerIds },
  )
}

const getScheduleInvitations = async (shiftRequestParentId: string) => {
  try {
    const response = await trabaApi.get(
      `shift-request-parents/${shiftRequestParentId}/invitations`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

interface RescindScheduleInvitationParams {
  shiftRequestId: string
  workerId: string
}
const rescindScheduleInvitation = async ({
  shiftRequestId,
  workerId,
}: RescindScheduleInvitationParams) => {
  const response = await trabaApi.patch(
    `/shift-requests/${shiftRequestId}/invitations/${workerId}/rescind`,
  )
  return response.data
}

export const useScheduleInvitations = (shiftRequestParentId: string) => {
  const { showError, showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: scheduleInvitations,
    error,
    isFetched: isRolesFetched,
    refetch,
  } = useQuery<ScheduleShiftRequestInvitation[], Error>({
    queryKey: ['schedule-invitations', shiftRequestParentId],
    queryFn: () => getScheduleInvitations(shiftRequestParentId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const rescindScheduleInvitationMutation = useMutation<
    ScheduleShiftRequestInvitation,
    Error,
    RescindScheduleInvitationParams
  >({
    mutationFn: rescindScheduleInvitation,
    onSuccess: () => {
      refetch()
      showSuccess('Invitation rescinded')
    },
    onError: (error) => {
      console.error(error)
      showError('Failed to rescind invitation')
    },
  })

  return {
    isLoading,
    isError,
    error,
    isRolesFetched,
    scheduleInvitations,
    sendScheduleInvitation,
    refetch,
    rescindScheduleInvitation: rescindScheduleInvitationMutation.mutate,
  }
}
