import React from 'react'
import { Icon } from 'src/components/base'

export type CollapsibleIconProps = {
  collapsed?: boolean
  setCollapsed?: (collapsed: boolean) => void
  size?: number
}

export type CollapsibleProps = {
  children?: React.ReactNode
  collapsed?: boolean
}

export function CollapsibleIcon({
  collapsed,
  setCollapsed,
  size = 24,
}: CollapsibleIconProps) {
  return (
    <div style={{ marginLeft: '16px', marginTop: '4px' }}>
      <Icon
        onClick={() => setCollapsed && setCollapsed(!collapsed)}
        name={collapsed ? 'plus' : 'minus'}
        style={{
          height: size,
          width: size,
          cursor: 'pointer',
        }}
      />
    </div>
  )
}

export default function Collapsible({ children, collapsed }: CollapsibleProps) {
  if (collapsed) {
    return null
  }
  return <>{children}</>
}
