import { Dialog } from '@mui/material'
import { Col, Row, Text, Button } from '@traba/react-components'
import { theme } from '@traba/theme'

export interface ResponseDisplayResponseType {
  fulfilled: any[]
  rejected: any[]
}

interface ResponseDisplayDialogProps {
  isOpen: boolean
  onClose: () => void
  response?: ResponseDisplayResponseType
}

export function ResponseDisplayDialog({
  response,
  isOpen,
  onClose,
}: ResponseDisplayDialogProps) {
  const { fulfilled = [], rejected = [] } = response || {}

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Col
        p={theme.space.xs}
        style={{
          minWidth: '500px',
          minHeight: '500px',
        }}
      >
        <Text variant="h5">Responses</Text>
        <Text variant="h6" mt={theme.space.xxs} mb={theme.space.xxxs}>
          Rejected
        </Text>
        <ul style={{ padding: theme.space.xs }}>
          {rejected.map((res, i) => (
            <li key={i}>
              <Text variant="caption">{JSON.stringify(res)}</Text>
            </li>
          ))}
          {rejected.length === 0 && (
            <Text variant="caption">No data to display</Text>
          )}
        </ul>
        <Text variant="h6" mt={theme.space.xxs} mb={theme.space.xxxs}>
          Fulfilled
        </Text>
        <ul style={{ padding: theme.space.xs }}>
          {fulfilled.map((res, i) => (
            <li key={i}>
              <Text variant="caption">{JSON.stringify(res)}</Text>
            </li>
          ))}
          {fulfilled.length === 0 && (
            <Text variant="caption">No data to display</Text>
          )}
        </ul>
      </Col>
      <Row center p={theme.space.xs}>
        <Button onClick={onClose} style={{ width: 250 }}>
          Close
        </Button>
      </Row>
    </Dialog>
  )
}
