import { Tooltip } from '@mui/material'
import { Icon, Row } from 'src/components/base'
import {
  Buttons,
  SliderButtonContainer,
  SliderButtonStyle,
} from './SliderButton.styles'

export type SliderButtonOption = {
  label: string
  disabled?: boolean
  info?: string
}
interface SliderButtonProps {
  buttonOptions: SliderButtonOption[]
  currentButton: number
  setCurrentButton: (arg: number) => void
  width?: number
}
export const SliderButton = (props: SliderButtonProps) => {
  const { buttonOptions, currentButton, setCurrentButton } = props

  return (
    <SliderButtonContainer
      style={{
        width: props.width ? props.width : 'auto',
        alignItems: 'center',
      }}
    >
      <Buttons
        variant="scrollable"
        value={currentButton}
        onChange={(_, v) => setCurrentButton(v)}
        TabIndicatorProps={{ style: { height: 0 } }}
      >
        {buttonOptions.map(({ info, label, disabled }) => (
          <SliderButtonStyle
            style={{
              pointerEvents: 'auto',
            }}
            disabled={disabled}
            key={label}
            label={
              <Row>
                {label}
                {info && (
                  <Row ml={4} alignCenter>
                    <Tooltip title={info}>
                      <div>
                        <Icon name="info" />
                      </div>
                    </Tooltip>
                  </Row>
                )}
              </Row>
            }
          />
        ))}
      </Buttons>
    </SliderButtonContainer>
  )
}
