import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import { Roster } from '@traba/types'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Input } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { UpdateRosterDto, useRosters } from 'src/hooks/useRosters'
import { useWorkersWithDetails } from 'src/hooks/useWorkers'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import * as yup from 'yup'

interface formValues {
  rosterName: string
}

export function RosterModal({
  companyId,
  isOpen,
  handleClose,
  rosterToEdit,
}: {
  companyId: string
  isOpen: boolean
  handleClose: () => void
  rosterToEdit: Roster | undefined
}) {
  const [checkedWorkers, setCheckedWorkers] = useState<PopulatedWorker[]>([])

  const {
    workersWithDetails: populatedWorkers,
    isLoading: isLoadingPopulatedWorkers,
  } = useWorkersWithDetails(rosterToEdit?.workers.map((w) => w.workerId) || [])

  const hasSelectedWorkers = checkedWorkers.length > 0
  const { createRoster, updateRoster } = useRosters(companyId)
  const { handleError } = useAlert()

  const onClose = useCallback(() => {
    setCheckedWorkers([])
    handleClose()
  }, [handleClose])

  function onCreateRoster(values: formValues) {
    const reqBody = {
      ...values,
      workerIds: checkedWorkers.map((worker) => worker.id || worker.workerId),
    }
    window.analytics?.track('Ops User Clicked Create Roster', {
      ...reqBody,
    })
    formik.setStatus({})
    createRoster(reqBody, {
      onError: (error) => {
        handleError(
          error,
          'RosterModal -> createRoster()',
          'There was an error creating the roster.',
          'Error creating roster',
        )
      },
    })
    onClose()
    formik.resetForm()
  }

  function onUpdateRoster(values: formValues) {
    if (!rosterToEdit) {
      return
    }
    const existingWorkerIds = rosterToEdit.workers.map((w) => w.workerId)
    const checkedWorkerIds = checkedWorkers.map((w) => w.id || w.workerId)
    const workersToAdd = new Set<string>()
    const workersToRemove = new Set<string>()
    for (const checkedWorkerId of checkedWorkerIds) {
      if (!existingWorkerIds.includes(checkedWorkerId)) {
        workersToAdd.add(checkedWorkerId)
      }
    }
    for (const existingWorkerId of existingWorkerIds) {
      if (!checkedWorkerIds.includes(existingWorkerId)) {
        workersToRemove.add(existingWorkerId)
      }
    }
    const reqBody: UpdateRosterDto = {
      ...values,
      addWorkers: workersToAdd.size > 0 ? Array.from(workersToAdd) : undefined,
      removeWorkers:
        workersToRemove.size > 0 ? Array.from(workersToRemove) : undefined,
      rosterId: rosterToEdit.id,
    }
    updateRoster(reqBody, {
      onError: (error) => {
        handleError(
          error,
          'RosterModal -> udpateRoster()',
          'There was an error updating the roster.',
          'Error updating roster',
        )
      },
    })
    onClose()
    formik.resetForm()
  }

  const initialValues: formValues = {
    rosterName: rosterToEdit?.rosterName || '',
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object({
      rosterName: yup.string().required('Please enter a roster name'),
    }),
    onSubmit: async (values: formValues) => {
      try {
        if (rosterToEdit) {
          onUpdateRoster(values)
          return
        }
        onCreateRoster(values)
      } catch (err) {
        handleError(
          err,
          'RosterModal -> onSubmit()',
          'Error creating roster',
          'Error creating roster',
        )
      }
    },
  })

  useEffect(() => {
    setCheckedWorkers((prevCheckedWorkers) => {
      const existingWorkerIds = new Set(prevCheckedWorkers.map((w) => w.id))
      return [
        ...prevCheckedWorkers,
        ...(populatedWorkers?.filter(
          (worker) => !existingWorkerIds.has(worker.id),
        ) || []),
      ]
    })
  }, [populatedWorkers, isLoadingPopulatedWorkers, isOpen])
  const { errors, touched } = formik
  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={isOpen}
      onClose={onClose}
      onConfirmCTA={rosterToEdit ? 'Update Roster' : 'Create Roster'}
      dialogTitle={rosterToEdit ? 'Edit Roster' : 'New Roster'}
      confirmDisabled={!hasSelectedWorkers}
      formId="create-roster"
      maxWidth="xl"
      onConfirm={formik.handleSubmit}
    >
      {isLoadingPopulatedWorkers ? (
        <CircularProgress size={'medium'} />
      ) : (
        <form
          id="create-roster"
          onSubmit={formik.handleSubmit}
          style={{ paddingBottom: '700px' }}
        >
          <Input
            key={'roster_name'}
            placeholder="Roster Name"
            containerStyle={{
              marginTop: theme.space.xxs,
              marginBottom: theme.space.xs,
            }}
            width="100%"
            {...formik.getFieldProps('rosterName')}
            errorMessage={errors.rosterName}
            inputStatus={
              touched.rosterName && errors.rosterName && formik.touched ? 3 : 1
            }
          />
          <SearchWorkers
            checkedWorkers={checkedWorkers}
            setCheckedWorkers={setCheckedWorkers}
          />
        </form>
      )}
    </Dialog>
  )
}
