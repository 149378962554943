import { Dialog as MuiDialog, DialogContent } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import { Button } from '../Button'
import { ButtonVariant } from '../Button/types'
import Row from '../Row'
import * as S from './Dialog.styles'

export const Dialog: React.FC<S.DialogProps> = (props) => {
  const {
    children,
    dialogTitle,
    onClose,
    onDismiss,
    onConfirm,
    onConfirmCTA,
    confirmDisabled,
    confirmLoading,
    formId,
    ...rest
  } = props

  return (
    <>
      <S.DialogStyling />
      <MuiDialog
        {...rest}
        onClose={onDismiss || onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <Row justifyBetween alignCenter style={{ padding: theme.space.med }}>
          <Text variant="h4" mr="xxs">
            {dialogTitle}
          </Text>
          <S.ButtonIcon name="cancel" onClick={onClose} />
        </Row>
        {children && (
          <DialogContent style={{ padding: theme.space.med }} dividers={true}>
            {children}
          </DialogContent>
        )}
        <S.StyledDialogActions>
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={confirmDisabled}
            variant={ButtonVariant.FILLED}
            type="submit"
            onClick={onConfirm}
            form={formId}
            loading={confirmLoading}
          >
            {onConfirmCTA}
          </Button>
        </S.StyledDialogActions>
      </MuiDialog>
    </>
  )
}
