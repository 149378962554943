import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { getBreaksForWorkerShift } from '@traba/utils'
import { Col } from 'src/components/base'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatDuration } from 'src/utils/dateUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import { WorkerShiftHistoryItem } from './WorkerShiftHistoryItem'

type WorkerShiftHistoryProps = {
  workerShift: WorkerShift
}

export const WorkerShiftHistory = ({
  workerShift,
}: WorkerShiftHistoryProps) => {
  const { hotSettings } = useHotSettings()
  const {
    clockInTime,
    shiftInfo,
    worker,
    clockOutTime,
    clockOutTimeBeforeWorkerEdit,
    timeWorked,
    grossPay,
    unitsWorked,
  } = workerShift
  const tz = useTimezonedDates(shiftInfo.timezone)

  const displayedBreaks = getBreaksForWorkerShift(
    workerShift,
    hotSettings?.enableWorkerEditTime,
  )
  const breaksToStringArray = displayedBreaks
    .map((b) => {
      return b.startTime && b.endTime
        ? tz.getShiftTime(b.startTime, b.endTime, true)
        : '-'
    })
    .join(', ')

  const displayedClockOutTime = clockOutTime || clockOutTimeBeforeWorkerEdit

  return (
    <>
      <Col>
        <WorkerShiftHistoryItem
          header="WORKER"
          value={`${worker?.firstName} ${worker?.lastName}`}
        />
        <WorkerShiftHistoryItem
          header="CLOCK IN"
          value={clockInTime ? tz.getTime(clockInTime) : '-'}
        />
        <WorkerShiftHistoryItem
          header="PAY RATE"
          value={getMoneyString(shiftInfo.payRate)}
        />
      </Col>

      <Col>
        <WorkerShiftHistoryItem header="SHIFT" value={shiftInfo.shiftRole} />
        <WorkerShiftHistoryItem
          header="CLOCK OUT"
          value={
            displayedClockOutTime ? tz.getTime(displayedClockOutTime) : '-'
          }
        />
        <WorkerShiftHistoryItem
          header="TIME WORKED"
          value={timeWorked ? formatDuration(timeWorked) : '-'}
        />
      </Col>

      <Col>
        <WorkerShiftHistoryItem
          header="DATE"
          value={tz.getDate(shiftInfo.startTime)}
        />
        <WorkerShiftHistoryItem
          header={`BREAKS (${displayedBreaks.length})`}
          value={breaksToStringArray || '-'}
        />
        <WorkerShiftHistoryItem
          header="GROSS PAY"
          value={
            <Text
              style={{ color: theme.colors.Violet, fontWeight: '600' }}
              variant="body1"
            >
              {grossPay ? getMoneyString(grossPay) : '-'}
            </Text>
          }
        />
      </Col>

      {shiftInfo.payType === ShiftPayType.UNIT && (
        <Col>
          <WorkerShiftHistoryItem
            header="UNITS WORKED"
            value={unitsWorked ?? '-'}
          />
        </Col>
      )}
    </>
  )
}
