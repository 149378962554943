import { TabPanel, Tabs, TabsContainer, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Badge, Col, IconButton, Input, Row } from 'src/components/base'
import {
  WorkerVettingCampaignResponse,
  WorkerVettingPhoneCallResponse,
  WorkerVettingSessionResponse,
  WorkerVettingSessionWithPhoneCalls,
  useWorkerVettingCampaign,
} from 'src/hooks/useWorkerVetting'
import { Tab } from 'src/screens/IncentivesMonitorScreen/styles'
import {
  WorkerVettingMethod,
  WorkerVettingSessionStatus,
} from '../../../../types/worker-vetting'
import { downloadArrayAsCSV } from '../../../WorkerSearchScreen/components/ExportDrawer/utils'
import WorkerVettingSessionMessagePreview from '../WorkerVettingSessionMessagePreview'
import WorkerVettingSessionMessageViewer from '../WorkerVettingSessionMessageViewer'
import { PreviewWrapper } from './WorkerVettingSessions.styles'

interface WorkerVettingSessionsProps {
  campaign: WorkerVettingCampaignResponse
}

const parseResultsToCSV = (data?: WorkerVettingSessionResponse[]) => {
  return data?.map((w) => ({
    phoneNumber: w.phoneNumber,
    parsedStatus: parseStatus(w.opsScore ?? w.score ?? 0),
    opsScore: w.opsScore,
    score: w.score,
    status: w.status,
    firstName: w.worker?.firstName,
    lastName: w.worker?.lastName,
    workerId: w.workerId,
    workerVettingCampaignId: w.workerVettingCampaignId,
  }))
}

const parseStatus = (status: number) => {
  switch (status) {
    case 200:
      return 'Qualified'
    case 300:
      return 'Ongoing'
    case 500:
      return 'Disqualified'
    case 0:
      return 'Exited'
    default:
      return 'Other'
  }
}

const getCurrentTabDataKey = (tab: number) => {
  switch (tab) {
    case 0:
      return 'ongoing'
    case 1:
      return 'qualified'
    case 2:
      return 'disqualified'
    case 3:
      return 'exited'
    default:
      return 'other'
  }
}

type WorkerVettingSessionForCSV = {
  phoneNumber: string
  workerVettingCampaignId: string
  score?: number
  opsScore?: number
  status: WorkerVettingSessionStatus
  workerId?: string
}

export default function WorkerVettingSessions({
  campaign,
}: WorkerVettingSessionsProps) {
  const { refetch, isFetching } = useWorkerVettingCampaign(campaign.id)

  const [currentTab, setCurrentTab] = useState(0)
  const [selectedSession, setSelectedSession] = useState<
    WorkerVettingSessionWithPhoneCalls | undefined
  >(undefined)
  const [phoneNumberSearch, setPhoneNumberSearch] = useState<string>('')

  const handleTabSelect = (index: number) => {
    if (index === currentTab) {
      return
    }
    setSelectedSession(undefined)
    setCurrentTab(index)
  }

  const filteredSessions = campaign.workerVettingSessions.filter((session) => {
    if (phoneNumberSearch === '') {
      return true
    }
    return session.phoneNumber.includes(phoneNumberSearch)
  })

  const sessionsByStatus = useMemo(() => {
    const callsMap: Record<string, WorkerVettingPhoneCallResponse[]> = {}
    campaign.workerVettingPhoneCalls?.forEach((phoneCall) => {
      if (!callsMap[phoneCall.phoneNumber]) {
        callsMap[phoneCall.phoneNumber] = []
      }
      callsMap[phoneCall.phoneNumber].push(phoneCall)
    })

    const sessionsByStatus = filteredSessions.reduce<
      Record<string, WorkerVettingSessionWithPhoneCalls[]>
    >(
      (acc, session) => {
        const sessionWithPhoneCalls: WorkerVettingSessionWithPhoneCalls = {
          ...session,
          phoneCalls: callsMap[session.phoneNumber] || [],
        }
        if (session.opsScore !== undefined || session.score !== undefined) {
          switch (session.opsScore ?? session.score) {
            case 0:
              acc['exited'].push(sessionWithPhoneCalls)
              break
            case 200:
              acc['qualified'].push(sessionWithPhoneCalls)
              break
            case 300:
              acc['ongoing'].push(sessionWithPhoneCalls)
              break
            case 500:
              acc['disqualified'].push(sessionWithPhoneCalls)
              break
            default:
              acc['other'].push(sessionWithPhoneCalls)
              break
          }
          return acc
        }
        acc['other'].push(sessionWithPhoneCalls)
        return acc
      },
      {
        ongoing: [],
        qualified: [],
        disqualified: [],
        exited: [],
        other: [],
      },
    )

    return sessionsByStatus
  }, [campaign.workerVettingPhoneCalls, filteredSessions])

  const handleResultsAsCsv = useCallback(() => {
    const dataKey = getCurrentTabDataKey(currentTab)
    const data = parseResultsToCSV(sessionsByStatus[dataKey])
    downloadArrayAsCSV<WorkerVettingSessionForCSV>(
      data,
      `worker-vetting-sessions-${dataKey}`,
    )
    window.analytics.track(
      'Exported Worker Vetting Sessions',
      { dataKey },
      { context: { campaignId: campaign.id } },
    )
  }, [currentTab, sessionsByStatus, campaign.id])

  return (
    <Col>
      <Row mt={theme.space.sm} justifyBetween>
        <Text variant="h5">Worker Vetting Sessions</Text>
        <IconButton
          iconName="refresh"
          onClick={() => refetch()}
          loading={isFetching}
        />
      </Row>
      <Row mt={theme.space.xs} justifyBetween>
        <Input
          label="Number Search"
          value={phoneNumberSearch}
          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
            setPhoneNumberSearch(ev.target.value)
          }
          style={{ height: 40 }}
          width="260px"
        />
        {!!filteredSessions?.length && (
          <IconButton
            iconName="download"
            onClick={handleResultsAsCsv}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        )}
      </Row>
      <TabsContainer>
        <Tabs value={currentTab}>
          <Row alignCenter>
            <Tab label="Ongoing" onClick={() => handleTabSelect(0)} />
            <NumberBadge
              count={sessionsByStatus['ongoing'].length}
              isActive={currentTab === 0}
            />
          </Row>
          <Row alignCenter>
            <Tab label="Qualified" onClick={() => handleTabSelect(1)} />
            <NumberBadge
              count={sessionsByStatus['qualified'].length}
              isActive={currentTab === 1}
            />
          </Row>
          <Row alignCenter>
            <Tab label="Disqualified" onClick={() => handleTabSelect(2)} />
            <NumberBadge
              count={sessionsByStatus['disqualified'].length}
              isActive={currentTab === 2}
            />
          </Row>
          <Row alignCenter>
            <Tab label="Exited" onClick={() => handleTabSelect(3)} />
            <NumberBadge
              count={sessionsByStatus['exited'].length}
              isActive={currentTab === 3}
            />
          </Row>
          <Row alignCenter>
            <Tab label="Not Started" onClick={() => handleTabSelect(4)} />
            <NumberBadge
              count={sessionsByStatus['other'].length}
              isActive={currentTab === 4}
            />
          </Row>
        </Tabs>
      </TabsContainer>
      <Col style={{ height: 300 }}>
        <TabPanel value={currentTab} index={0}>
          <MessagePreviewList
            sessions={sessionsByStatus['ongoing']}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            campaignType={campaign.type}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <MessagePreviewList
            sessions={sessionsByStatus['qualified']}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            campaignType={campaign.type}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <MessagePreviewList
            sessions={sessionsByStatus['disqualified']}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            campaignType={campaign.type}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <MessagePreviewList
            sessions={sessionsByStatus['exited']}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            campaignType={campaign.type}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={4}>
          <MessagePreviewList
            sessions={sessionsByStatus['other']}
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            campaignType={campaign.type}
          />
        </TabPanel>
      </Col>
    </Col>
  )
}

const MessagePreviewList = ({
  sessions,
  selectedSession,
  setSelectedSession,
  campaignType,
}: {
  sessions: WorkerVettingSessionWithPhoneCalls[]
  selectedSession: WorkerVettingSessionWithPhoneCalls | undefined
  setSelectedSession: Dispatch<
    SetStateAction<WorkerVettingSessionWithPhoneCalls | undefined>
  >
  campaignType: WorkerVettingMethod
}) => {
  const messageSelected = selectedSession !== undefined
  return (
    <Row fullWidth style={{ maxHeight: 'calc(80vh)' }}>
      <PreviewWrapper messageSelected={messageSelected}>
        {sessions.length > 0 ? (
          sessions.map((session) => {
            const isSelected = session.id === selectedSession?.id
            return (
              <WorkerVettingSessionMessagePreview
                vettingSession={session}
                isSelected={isSelected}
                setSelectedSession={setSelectedSession}
                key={session.id}
                campaignType={campaignType}
                messageSelected={messageSelected}
              />
            )
          })
        ) : (
          <NoSessionsPlaceholder />
        )}
      </PreviewWrapper>
      {messageSelected && (
        <WorkerVettingSessionMessageViewer
          vettingSession={selectedSession}
          setSelectedSession={setSelectedSession}
        />
      )}
    </Row>
  )
}

const NumberBadge = ({
  count,
  isActive,
}: {
  count: number
  isActive: boolean
}) => {
  return (
    <Badge
      variant={isActive ? 'brand' : 'disabled'}
      title={`${count}`}
      style={{ minWidth: 40, height: 25, borderRadius: theme.space.xxs }}
    />
  )
}

const NoSessionsPlaceholder = () => (
  <Row center>
    <Text variant="body1">No sessions in this category</Text>
  </Row>
)
