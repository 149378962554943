import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { BreakType } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { compact, divide, multiply, sum } from 'lodash'
import { Row, Table, Td, Tr } from 'src/components/base'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { getTotalPaidTimeInMinutes } from 'src/utils/dateUtils'
import {
  calculateWorkerPayandFees,
  getChargedUnits,
} from 'src/utils/moneyUtils'
import { breaksToString, getMoneyString } from 'src/utils/stringUtils'
import { SummaryRow } from '../../SummaryRow/SummaryRow'

type BulkAdjustSummaryProps = {
  workerShifts: WorkerShift[]
  adjustedStartTime: Date
  adjustedEndTime: Date
  adjustedBreakType: BreakType
  adjustedBreaks: ShiftTime[]
  adjustedPayRate: number
  adjustedMinPaidTime: number
  adjustmentReason: string
  unitsPerWorker: number | undefined
  adjustedUnitsWorked: number | undefined
}

export const BulkAdjustSummary = (props: BulkAdjustSummaryProps) => {
  const {
    workerShifts,
    adjustedStartTime,
    adjustedEndTime,
    adjustedBreakType,
    adjustedBreaks,
    adjustedPayRate,
    adjustedMinPaidTime,
    adjustmentReason,
    unitsPerWorker,
    adjustedUnitsWorked,
  } = props

  const workerShift = workerShifts[0]
  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const originalClockIn = new Date(workerShift.shiftInfo.startTime)
  const originalClockOut = new Date(workerShift.shiftInfo.endTime)

  const adjustedBreaksStrings = compact(
    breaksToString(adjustedBreaks, workerShift.shiftInfo.timezone),
  )

  const adjustedBreaksTableCell = adjustedBreaksStrings.map((breakString) => (
    <Text variant="body1" style={{ fontWeight: '600' }}>
      {breakString}
    </Text>
  ))

  const originalBreaksTableCell = compact(
    breaksToString(workerShift.breaks || [], workerShift.shiftInfo.timezone),
  ).map((origBreakString) => {
    const isInAdjusted = adjustedBreaksStrings.includes(origBreakString)
    return (
      <Text variant={isInAdjusted ? 'body1' : 'strikethrough'}>
        {origBreakString}
      </Text>
    )
  })

  const adjustedTimeWorked = getTotalPaidTimeInMinutes(
    adjustedStartTime,
    adjustedEndTime,
    adjustedBreaks,
    adjustedBreakType,
    workerShift.shiftInfo.scheduledBreaks,
  )
  const { netPay: adjustedNetPay } = calculateWorkerPayandFees({
    chargedUnits: getChargedUnits({
      payType: workerShift.shiftInfo.payType,
      unitsCompletedByWorker: adjustedUnitsWorked,
      minimumPaidTime:
        adjustedMinPaidTime || workerShift.shiftInfo.minimumPaidTime,
      slotsRequested: workerShift.shiftInfo.slotsRequested,
      numberOfUnitsInShift: workerShift.shiftInfo.numberOfUnits,
      totalTimeWorked: adjustedTimeWorked,
      payByUnitMinimumPay: workerShift.shiftInfo.minimumPayByUnitPay,
      payRate: workerShift.shiftInfo.payRate,
    }),
    payRate: adjustedPayRate,
    payType: workerShift.shiftInfo.payType,
    timeWorkedInMinutes: adjustedTimeWorked,
    trustAndSafetyFeeHourly: workerShift.shiftInfo.trustAndSafetyFeeHourly,
  })

  const originalTotal = divide(
    sum(workerShifts.map((ws) => ws.netPay?.amount ?? 0)),
    100,
  )
  const newTotal = divide(
    multiply(adjustedNetPay.amount, workerShifts.length),
    100,
  )

  return (
    <Row
      flexCol
      style={{
        backgroundColor: theme.colors.Grey10,
        borderRadius: theme.space.xxs,
        padding: theme.space.sm,
      }}
    >
      <Text
        variant="h5"
        style={{
          marginBottom: '12px',
        }}
      >
        Summary
      </Text>
      <Text variant="body2">
        {`A summary of the changes made by this adjustment to ${workerShifts.length} workers who completed the shift. Please verify that the adjustment details are correct.`}
      </Text>
      <Row fullWidth center flexCol>
        <Table headers={['', 'Old', 'New']}>
          {/* Start Time */}
          <Tr>
            <Td>
              <Text variant="caption">START TIME</Text>
            </Td>
            <SummaryRow
              original={originalClockIn}
              adjusted={adjustedStartTime}
              originalString={`${tz.getDate(originalClockIn, false)}, 
              ${tz.getTime(originalClockIn)}`}
              adjustedString={`${tz.getDate(adjustedStartTime, false)}, 
              ${tz.getTime(adjustedStartTime)}`}
            />
          </Tr>
          {/* End Time */}
          <Tr>
            <Td>
              <Text variant="caption">END TIME</Text>
            </Td>
            <SummaryRow
              original={originalClockOut}
              adjusted={adjustedEndTime}
              originalString={`${tz.getDate(originalClockOut, false)}, 
              ${tz.getTime(originalClockOut)}`}
              adjustedString={`${tz.getDate(adjustedEndTime, false)}, 
              ${tz.getTime(adjustedEndTime)}`}
            />
          </Tr>
          {/* Break Type */}
          <Tr>
            <Td>
              <Text variant="caption">BREAK TYPE</Text>
            </Td>
            <SummaryRow
              original={workerShift.shiftInfo.breakType}
              adjusted={adjustedBreakType}
              originalString={`${workerShift.shiftInfo.breakType}`}
              adjustedString={`${adjustedBreakType}`}
            />
          </Tr>

          {/* Breaks */}
          <Tr>
            <Td>
              <Text variant="caption">BREAKS</Text>
            </Td>
            <Td>
              {originalBreaksTableCell.length > 0
                ? originalBreaksTableCell
                : '-'}
            </Td>
            <Td>
              {adjustedBreaksTableCell.length > 0
                ? adjustedBreaksTableCell
                : '-'}
            </Td>
          </Tr>

          {/* Units worked */}
          {workerShift.shiftInfo.payType === ShiftPayType.UNIT && (
            <Tr>
              <Td>
                <Text variant="caption">UNITS WORKED</Text>
              </Td>
              <SummaryRow
                original={workerShift.unitsWorked ?? unitsPerWorker}
                adjusted={adjustedUnitsWorked ?? unitsPerWorker}
                originalString={`${workerShift.unitsWorked ?? unitsPerWorker}`}
                adjustedString={`${adjustedUnitsWorked ?? unitsPerWorker}`}
              />
            </Tr>
          )}
          {/* Pay Rate */}
          <Tr>
            <Td>
              <Text variant="caption">PAY RATE</Text>
            </Td>
            <SummaryRow
              original={workerShift.shiftInfo.payRate}
              adjusted={adjustedPayRate}
              originalString={`$${workerShift.shiftInfo.payRate}`}
              adjustedString={`$${adjustedPayRate}`}
            />
          </Tr>

          {/* Min Paid Time */}
          <Tr>
            <Td>
              <Text variant="caption">MINIMUM PAID TIME</Text>
            </Td>
            <SummaryRow
              original={workerShift.shiftInfo.minimumPaidTime}
              adjusted={adjustedMinPaidTime}
              originalString={
                workerShift.shiftInfo.minimumPaidTime
                  ? `${workerShift.shiftInfo.minimumPaidTime}`
                  : '-'
              }
              adjustedString={`${adjustedMinPaidTime}`}
            />
          </Tr>
          {/* Total Cost */}
          <Tr>
            <Td>
              <Text variant="caption">TOTAL COST</Text>
            </Td>
            <SummaryRow
              original={originalTotal}
              adjusted={newTotal}
              originalString={getMoneyString(originalTotal)}
              adjustedString={getMoneyString(newTotal)}
            />
          </Tr>
        </Table>
        <Row mt={16} alignCenter>
          <Text variant="caption">ADJUSTMENT REASON:</Text>
          <Text variant="body1" style={{ marginLeft: theme.space.xxs }}>
            {adjustmentReason}
          </Text>
        </Row>
      </Row>
    </Row>
  )
}
