import { theme, Z_INDEXES } from '@traba/theme'
import styled, { css } from 'styled-components'

const cellHorizontalPadding = css`
  padding-left: 8px;
  padding-right: 8px;
  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 12px;
  }
`

const trBorderRadius = css`
  td:first-child,
  th:first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius};
  }
  td:last-child,
  th:last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius};
  }
`

export const TableContainer = styled.table`
  border-collapse: separate;
  border-spacing: 0 0px;
  width: 100%;
  position: relative;
`

export const TableHeader = styled.tr`
  background: ${({ theme }) => theme.colors.Grey10};
  position: sticky;
  ${trBorderRadius}
  top: 0;
  z-index: ${Z_INDEXES.TABLE_HEADER};
`

type TableHeaderItemProps = {
  clickable?: boolean
  active?: boolean
  centerLabel?: boolean
}

export const TableHeaderItem = styled.div<TableHeaderItemProps>`
  div {
    font-weight: 600;
    font-size: 10px;
    line-height: 160%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.Grey50};
    ${(props) =>
      props.active &&
      css`
        color: ${props.theme.colors.MidnightBlue};
      `}
    ${(props) => css`
      text-align: ${props.centerLabel ? 'center' : 'left'};
    `}
  }
  display: flex;
  align-items: center;
  gap: 5px;
  max-height: 100px;
  min-height: 40px;
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${cellHorizontalPadding}
`

export const TableBody = styled.tbody``

type TableRowProps = {
  colorWhenHover?: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  &:first-child {
    td {
      box-shadow: none;
    }
  }
  ${(props) =>
    props.colorWhenHover
      ? css`
          &:hover {
            background-color: ${theme.colors.Grey10};
          }
        `
      : ''}
  box-shadow: inset 0px 1px 0px ${({ theme }) => theme.colors.Grey};
`

type TableCellProps = {
  centerContent?: boolean
}

export const TableCell = styled.td<TableCellProps>`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.darker};
  height: 50px;
  ${cellHorizontalPadding}
  ${(props) =>
    props.centerContent &&
    css`
      text-align: center;
    `}
`

export const TableFooter = styled.tr`
  background: ${({ theme }) => theme.colors.Grey10};
  border-radius: ${({ theme }) => theme.border.radius};

  ${trBorderRadius}
  td {
    box-shadow: none;
  }
`
