import { CircularProgress, IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button, Icon, Input, Row } from 'src/components/base'
import { GreyContainer } from './InvoiceDetailsBody'

type InvoiceMemoUneditableProps = {
  stripeMemo: string
  editable: false
}

type InvoiceMemoEditableProps = {
  stripeMemo: string
  editable: true
  updateMemo: (newMemo: string) => Promise<void>
}

export default function InvoiceMemo(
  props: InvoiceMemoUneditableProps | InvoiceMemoEditableProps,
) {
  const { stripeMemo, editable } = props
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newMemoState, setNewMemoState] = useState(stripeMemo)

  const handleClickEdit = () => {
    setIsEditing(true)
  }

  const handleSaveEdits = async () => {
    setIsLoading(true)
    editable && (await props.updateMemo(newMemoState))
    setIsLoading(false)
    setIsEditing(false)
  }

  const canSave = newMemoState !== stripeMemo

  if (isEditing) {
    return (
      <GreyContainer>
        <Row justifyStart alignCenter>
          <Input
            type="textarea"
            style={{ minWidth: '40em', minHeight: '7em' }}
            value={newMemoState}
            disabled={isLoading}
            onChange={(e: any) => setNewMemoState(e.target.value)}
          />
          <div style={{ marginLeft: theme.space.xs }}>
            <Button
              disabled={!canSave || isLoading || newMemoState.length === 0}
              onClick={handleSaveEdits}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </div>
        </Row>
      </GreyContainer>
    )
  }
  return (
    <GreyContainer>
      <Row justifyStart alignCenter>
        <Text variant="body1">{stripeMemo}</Text>
        <div style={{ marginLeft: theme.space.xs }}>
          <Tooltip title="Can only edit the memo for a draft invoice; please regenerate if the invoice is already open">
            <IconButton>
              <Icon
                name="edit"
                onClick={editable ? handleClickEdit : undefined}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Row>
    </GreyContainer>
  )
}
