import { Box, CircularProgress, Tooltip } from '@mui/material'
import Icon from '../Icon'
import Row from '../Row'
import * as S from './Button.components'
import { ButtonProps, ButtonVariant, IconButtonProps } from './types'

export const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    variant,
    rightIcon,
    leftIcon,
    slim,
    reverse,
    iconPadding,
    full,
    disabled,
    loading,
    tooltipText = '',
    labelStyle,
    ...otherProps
  } = props
  return (
    <S.ButtonContainer
      onClick={!disabled ? onClick : undefined}
      variant={variant}
      style={props.style}
      slim={slim}
      reverse={reverse}
      full={full}
      disabled={disabled || loading}
      type={props.type}
      {...otherProps}
    >
      <Box sx={{ position: 'relative' }}>
        <Row alignCenter>
          {leftIcon && (
            <S.ButtonIcon padding={iconPadding} reverse={!!reverse}>
              {leftIcon}
            </S.ButtonIcon>
          )}
          {tooltipText ? (
            <Tooltip
              title={tooltipText}
              leaveDelay={500}
              placement="bottom-end"
              arrow
            >
              <span>
                <S.ButtonLabelContainer style={labelStyle}>
                  {children}
                </S.ButtonLabelContainer>
              </span>
            </Tooltip>
          ) : (
            <S.ButtonLabelContainer style={labelStyle}>
              {children}
            </S.ButtonLabelContainer>
          )}
          {rightIcon && (
            <S.ButtonIcon reverse={!!reverse}>{rightIcon}</S.ButtonIcon>
          )}
        </Row>
        {loading && (
          <CircularProgress
            size={36}
            sx={{
              color: 'ButtonHighlight',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-18px',
              marginLeft: '-18px',
            }}
          />
        )}
      </Box>
    </S.ButtonContainer>
  )
}

export const IconButton = ({
  iconName,
  transparent,
  ...props
}: IconButtonProps) => {
  return (
    <Button
      slim
      variant={transparent ? ButtonVariant.TRANSPARENT : ButtonVariant.OUTLINED}
      style={{ padding: '8px 10px 8px 10px' }}
      iconPadding={'0'}
      leftIcon={<Icon name={iconName} />}
      {...props}
    >
      {' '}
    </Button>
  )
}
