import {
  BackgroundCheckStatus,
  DetailedBackgroundCheckStatus,
  AdjudicationStatus,
  BackgroundCheckAssessment,
} from '@traba/types'

export function convertToDetailedStatus(
  status: BackgroundCheckStatus,
): DetailedBackgroundCheckStatus {
  const mapping: Record<BackgroundCheckStatus, DetailedBackgroundCheckStatus> =
    {
      [BackgroundCheckStatus.PENDING]: DetailedBackgroundCheckStatus.PENDING,
      [BackgroundCheckStatus.INVITATION_CREATED]:
        DetailedBackgroundCheckStatus.INVITATION_CREATED,
      [BackgroundCheckStatus.REPORT_CREATED]:
        DetailedBackgroundCheckStatus.REPORT_CREATED,
      [BackgroundCheckStatus.CONSIDER]: DetailedBackgroundCheckStatus.CONSIDER,
      [BackgroundCheckStatus.CLEAR]: DetailedBackgroundCheckStatus.CLEAR,
      [BackgroundCheckStatus.SUSPENDED]:
        DetailedBackgroundCheckStatus.SUSPENDED,
      [BackgroundCheckStatus.DISPUTE]: DetailedBackgroundCheckStatus.DISPUTE,
      [BackgroundCheckStatus.CANCELED]: DetailedBackgroundCheckStatus.CANCELED,
      [BackgroundCheckStatus.COMPLETE]: DetailedBackgroundCheckStatus.COMPLETE,
    }

  return mapping[status]
}

export function calculateDetailedBackgroundCheckStatus(
  status: BackgroundCheckStatus,
  adjudication?: AdjudicationStatus,
  assessment?: BackgroundCheckAssessment,
): DetailedBackgroundCheckStatus {
  // Check for definitive BGC states.
  if (
    status !== BackgroundCheckStatus.CONSIDER &&
    status !== BackgroundCheckStatus.COMPLETE
  ) {
    return convertToDetailedStatus(status)
  }

  // Check for definitive adjudication states.
  if (adjudication) {
    switch (adjudication) {
      case 'PRE_ADVERSE_ACTION':
        return DetailedBackgroundCheckStatus.PRE_ADVERSE_ACTION
      case 'POST_ADVERSE_ACTION':
        return DetailedBackgroundCheckStatus.POST_ADVERSE_ACTION
      case 'ENGAGED':
        if (assessment) {
          switch (assessment) {
            case 'ELIGIBLE':
              return DetailedBackgroundCheckStatus.ELIGIBLE
            case 'REVIEW':
              return DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW
            case 'ESCALATED':
              return DetailedBackgroundCheckStatus.ESCALATED
          }
        }
    }
  }

  // Check for definitive assessment states if adjudication is null.
  if (assessment) {
    switch (assessment) {
      case 'ELIGIBLE':
        return DetailedBackgroundCheckStatus.ELIGIBLE
      case 'ESCALATED':
        return DetailedBackgroundCheckStatus.ESCALATED
      case 'REVIEW':
        return DetailedBackgroundCheckStatus.REVIEW
    }
  }

  // Default to status if both adjudication and assessment are null.
  // COMPLETE and CONSIDER can fall through to this point.
  return convertToDetailedStatus(status)
}
