import { Text } from '@traba/react-components'
import { Card, CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Locations } from '@traba/types'
import { useState } from 'react'
import { Button, Col, Icon, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { getAddressString } from 'src/utils/stringUtils'

export type ArchiveLocationModalProps = {
  companyId: string
  location: Locations
  handleClose: () => void
  isOpen: boolean
}

export const ArchiveLocationModal = (props: ArchiveLocationModalProps) => {
  const { isOpen, handleClose, companyId, location } = props
  const { archiveLocation } = useLocations(companyId)
  const [loading, setLoading] = useState(false)

  const handleArchiveClick = async () => {
    try {
      setLoading(true)
      archiveLocation(
        {
          locationId: location.locationId,
          companyId: companyId,
        },
        {
          onSettled: () => {
            setLoading(false)
            handleClose()
          },
        },
      )
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Archive Location"
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.LARGE}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col>
          <Text variant="body2">
            Archiving a location will remove it from the dropdown list when
            creating a shift. However, the location information will continue to
            show up for any previous shift it was tied to.
          </Text>

          <Text
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
            variant="h5"
          >
            Location to Archive
          </Text>
          <Card
            style={{
              marginTop: '8px',
              width: '100%',
              display: 'flex',
            }}
          >
            <Row alignCenter>
              <CardTile size="56px">
                <Icon name="location" />
              </CardTile>
              <Col pl={24} width="100%">
                <Row>
                  <Text variant="h5">
                    Name: {location.name ? location.name : '--'}
                  </Text>
                </Row>
                <Row>
                  <Text variant="h5">
                    Address: {getAddressString(location.address)}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Row justifyBetween pt={theme.space.med}>
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => props.handleClose()}
          >
            Back
          </Button>
          <Button
            variant={ButtonVariant.CANCEL}
            onClick={handleArchiveClick}
            loading={loading}
          >
            Archive
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
