import { useParams } from 'react-router-dom'
import { Row } from 'src/components/base'
import BackButton from 'src/components/base/BackButton'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import InvoiceDetails from './components/InvoiceDetails'

export default function InvoicesDetailsScreen() {
  const { invoiceId = '' } = useParams<{ invoiceId: string }>()

  return (
    <MainLayout>
      <BackButton noHistoryUrl="/billing" />
      <Row justifyCenter>
        <InvoiceDetails invoiceId={invoiceId} />
      </Row>
    </MainLayout>
  )
}
