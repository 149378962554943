import styled from 'styled-components'

export const ModalPaddingContainer = styled.div`
  padding: ${({ theme }) => theme.space.xxs}px;
  width: 100%;
`

export const WorkerImgContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WorkerImg = styled.img`
  max-width: 100%;
  max-height: 300px;
`
