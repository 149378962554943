import MuiModal from '@mui/material/Modal'
import { Text } from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import React, { useState } from 'react'
import { Icon } from 'src/components/base'
import * as S from './Modal.styles'
import { MODAL_SIZE } from './types'

export interface ModalProps {
  children: any
  handleClose: () => void
  isOpen: boolean
  size?: MODAL_SIZE
  style?: { [key: string]: string | number }
  title?: string
}

export interface ModalState {
  isOpen: boolean
  handleClose: () => void
  open: () => void
}

export function useModal(): ModalState {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return {
    isOpen: isModalOpen,
    handleClose: () => setIsModalOpen(false),
    open: () => setIsModalOpen(true),
  }
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, size, isOpen, title, handleClose } = props

  return (
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: {
          style: { backgroundColor: theme.colors.MidnightBlue, opacity: 0.5 },
        },
      }}
      sx={{ zIndex: Z_INDEXES.MODAL }}
    >
      <S.ModalContainer size={size} style={props.style}>
        {title ? (
          <S.HeaderRow>
            <Text style={{ textTransform: 'capitalize' }} variant={'h4'}>
              {title}
            </Text>
            <S.IconWrapper onClick={handleClose}>
              <Icon name="cancel" />
            </S.IconWrapper>
          </S.HeaderRow>
        ) : null}
        <S.ModalInnerContainer>{children}</S.ModalInnerContainer>
      </S.ModalContainer>
    </MuiModal>
  )
}
