import { FormControlLabel, Switch } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountApprovalStatus } from '@traba/types'
import { debounce } from 'lodash'
import { ChangeEvent, memo, useCallback, useRef, useState } from 'react'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { Badge } from '../../../../components/base'
import WorkerDrawer from '../../../../components/WorkerDrawer/WorkerDrawer'
import { OrFields } from '../../../../hooks/searchWorkersApi'
import { useSearchWorkers } from '../../../../hooks/useWorkers'
import { SearchInput } from './Inputs'
import { SearchResults } from './SearchResults'
import * as S from './styles'

const SEARCH_LIMIT = 20
const NUM_WORKER_TO_SHOW_MORE = 7
const DEFAULT_STATUSES = [
  AccountApprovalStatus.Approved,
  AccountApprovalStatus.Suspended,
  AccountApprovalStatus.Unapproved,
]

const WorkerBadge = memo(
  ({
    worker,
    onRemove,
  }: {
    worker: PopulatedWorker
    onRemove: (worker: PopulatedWorker) => void
  }) => (
    <Badge
      key={worker.id}
      style={{
        textTransform: 'unset',
        marginRight: '8px',
        marginBottom: '8px',
      }}
      variant="info"
      title={`${worker.firstName} ${worker.lastName} (${worker.id})`}
      onDismiss={() => onRemove(worker)}
      dismissible
    />
  ),
)

export const SearchWorkers = ({
  checkedWorkers,
  setCheckedWorkers,
  validateSetWorkers,
  disableSideBar,
  actionButton,
  hideInstructionText,
  hideApprovedOnlyToggle,
  // Results typically open as a detached modal, but sometimes this is
  // undesired.
  openInline,
  selectOneOnly,
  disabled,
}: {
  checkedWorkers: PopulatedWorker[]
  setCheckedWorkers: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
  validateSetWorkers?: () => void
  disableSideBar?: boolean
  actionButton?: React.ReactNode
  hideInstructionText?: boolean
  hideApprovedOnlyToggle?: boolean
  openInline?: boolean
  selectOneOnly?: boolean
  disabled?: boolean
}) => {
  const [searchText, setSearchText] = useState('')
  const [hoverWorker, setHoverWorker] = useState<PopulatedWorker | undefined>()
  const [focusedWorker, setFocusedWorker] = useState<
    PopulatedWorker | undefined
  >()
  const [showResults, setShowResults] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [isApprovedOnly, setApprovedOnly] = useState(false)

  const { isLoading, totalFound, searchWorkers, workersSearchResult } =
    useSearchWorkers()

  const getStatus = (isApprovedOnly: boolean) => {
    return isApprovedOnly ? [AccountApprovalStatus.Approved] : DEFAULT_STATUSES
  }

  const runSearch = useCallback(
    (text: string, accountStatuses: AccountApprovalStatus[]) =>
      searchWorkers({
        searchData: {
          accountStatuses,
        },
        direction: 'initial',
        activeOrFields: [
          OrFields.firstName,
          OrFields.lastName,
          OrFields.id,
          OrFields.phoneNumber,
        ],
        limit: SEARCH_LIMIT,
        includes: { workerMetric: true },
        cacheKey: 'add-workers-to-shift',
        fullTextSearchParam: text,
      }),
    [searchWorkers],
  )

  const debouncedSearchRef = useRef(
    debounce((text: string, accountStatuses: AccountApprovalStatus[]) => {
      runSearch(text, accountStatuses)
    }, 200),
  )

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setSearchText(text)
    debouncedSearchRef.current(text, getStatus(isApprovedOnly))
    setShowResults(true)
  }

  const handleClear = () => {
    setSearchText('')
    setHoverWorker(undefined)
    setFocusedWorker(undefined)
    setCheckedWorkers([])
  }

  const handleApprovedOnlyToggle = () => {
    runSearch(searchText, getStatus(!isApprovedOnly))
    setApprovedOnly(!isApprovedOnly)
  }

  const handleWorker = (worker: PopulatedWorker) => {
    setCheckedWorkers((prevWorkers) => {
      const workerExists = prevWorkers.find((w) => w.id === worker.id)

      if (selectOneOnly) {
        if (workerExists) {
          // If the worker already exists, remove it
          return []
        } else {
          // If the worker doesn't exist, clear the array and add the new worker
          return [worker]
        }
      }

      if (workerExists) {
        return prevWorkers.filter((w) => w.id !== worker.id)
      } else {
        return [...prevWorkers, worker]
      }
    })
    if (validateSetWorkers) {
      validateSetWorkers()
    }
  }
  const handleBadgeRemove = (worker: PopulatedWorker) => {
    handleWorker(worker)
  }

  return (
    <div>
      {!hideInstructionText && (
        <Text variant="label" mb={theme.space.xs} mt={theme.space.xxs}>
          You can search by first name, last name, phone number or worker ID
        </Text>
      )}

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: theme.space.xxs,
          padding: theme.space.xxs,
          width: '100%',
        }}
      >
        {(showMore
          ? checkedWorkers
          : checkedWorkers.slice(0, NUM_WORKER_TO_SHOW_MORE)
        ).map((worker) => (
          <WorkerBadge
            key={worker.id}
            worker={worker}
            onRemove={handleBadgeRemove}
          />
        ))}
        {checkedWorkers.length > NUM_WORKER_TO_SHOW_MORE && (
          <Text variant="link" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}...
          </Text>
        )}
      </div>

      <SearchInput
        searchText={searchText}
        handleSearch={handleSearch}
        handleClear={handleClear}
        checkedWorkersCount={checkedWorkers.length}
        actionButton={actionButton}
        disabled={disabled}
      />
      {!hideApprovedOnlyToggle && (
        <FormControlLabel
          control={
            <Switch
              checked={isApprovedOnly}
              onChange={handleApprovedOnlyToggle}
              color="primary"
            />
          }
          label="Approved Only"
        />
      )}

      {!isLoading &&
        workersSearchResult.length === 0 &&
        searchText &&
        showResults && (
          <S.SearchEmptyWrapper openInline={openInline}>
            <Text>No workers found</Text>
          </S.SearchEmptyWrapper>
        )}

      {!!workersSearchResult.length && searchText && showResults && (
        <SearchResults
          workersSearchResult={workersSearchResult}
          checkedWorkers={checkedWorkers}
          handleWorker={handleWorker}
          setFocusedWorker={setFocusedWorker}
          setHoverWorker={setHoverWorker}
          hoverWorker={hoverWorker}
          totalFound={totalFound}
          searchLimit={SEARCH_LIMIT}
          onClose={() => setShowResults(false)}
          disableAvatarClick={disableSideBar}
          isLoading={isLoading}
          openInline={openInline}
        />
      )}

      {focusedWorker && (
        <WorkerDrawer
          worker={focusedWorker}
          isOpen={!!focusedWorker}
          onClose={() => setFocusedWorker(undefined)}
        />
      )}
    </div>
  )
}
