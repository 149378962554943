import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { trabaApi } from '@traba/api-utils'
import { FullAddress } from '@traba/types'
import _ from 'lodash'
import * as React from 'react'
import { Row } from 'src/components/base'
import * as S from './PlacesAutocomplete.styles'

const EMPTY_ADDRESS_OBJECT = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  postalCode: '',
  location: undefined,
}

type GoogleMapsPrediction = {
  placeId: string
  description: string
  formattedDescription: {
    main: string
    secondary: string
  }
}

type PlacesAutocompleteProps = {
  onSelect: (val: FullAddress) => void
  onChange?: (val: string) => void
  label?: string
  value?: string
  errorMessage?: string | null
  placesApiType?: string
}

const debouncedFetchGooglePlaces = _.debounce(
  async (
    input: string,
    placesApiType: string | undefined,
    setIsLoading: (isLoading: boolean) => void,
    setOptions: (options: GoogleMapsPrediction[]) => void,
    onSelect: (address: FullAddress) => void,
  ) => {
    if (input.length > 0) {
      setIsLoading(true)
      let url = `/google/places/autocomplete/${input}`
      if (placesApiType) {
        url = url + `?type=${placesApiType}`
      }
      const res = await trabaApi.get(url)
      setOptions(res.data)
      setIsLoading(false)
      return
    }

    setOptions([])
    onSelect(EMPTY_ADDRESS_OBJECT)
  },
  300,
)

export default function PlacesAutocomplete({
  onSelect,
  errorMessage,
  value,
  ...props
}: PlacesAutocompleteProps) {
  const [options, setOptions] = React.useState<GoogleMapsPrediction[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  async function handleInputChange(input: string) {
    if (props.onChange) {
      props.onChange(input)
    }
    debouncedFetchGooglePlaces(
      input,
      props.placesApiType,
      setIsLoading,
      setOptions,
      onSelect,
    )
  }

  /**
   * Gets details from a place based on its placeId
   * and calls props.onSelect function with the place object as a parameter
   * @param ev change/select event
   * @param val selected value
   */
  async function handleSelectPlace(
    ev: React.SyntheticEvent<Element, Event>,
    val: GoogleMapsPrediction | null,
  ) {
    if (val && val.placeId) {
      const res = await trabaApi.get(`/google/places/${val?.placeId}`)
      onSelect(res.data)
    }
  }

  return (
    <Row flexCol fullWidth>
      <S.Autocomplete />
      <Autocomplete
        {...props}
        clearOnBlur={false}
        disablePortal
        loading={isLoading}
        autoComplete={false}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.formattedDescription.main}
        options={options}
        onInputChange={(ev, val) => handleInputChange(val)}
        onChange={handleSelectPlace}
        renderOption={(props, option) => (
          <S.Option {...props} key={option.placeId}>
            <span>{option.formattedDescription.main}</span>
            <b>{option.formattedDescription.secondary}</b>
          </S.Option>
        )}
        renderInput={(params) => (
          <TextField
            error={!!errorMessage}
            {...params}
            label={props.label}
            inputProps={{
              ...params.inputProps,
              value,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Row>
  )
}
