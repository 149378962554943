import { CircularProgress } from '@mui/material'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { theme } from '@traba/theme'
import { PaidBackup, SentinelNotification } from '@traba/types'
import { usePaidBackups } from 'src/hooks/usePaidBackups'
import { Row } from '../../base'
import { SentinelPaidBackupDetailsTable } from './SentinelPaidBackupDetailsTable'

export const SentinelPaidBackupDetailsSection = ({
  shiftId,
  workerIds,
  setSelectedPaidBackups,
  notification,
  slim,
}: {
  shiftId: string
  workerIds: string[]
  setSelectedPaidBackups: (pb: PaidBackup[]) => void
  notification: SentinelNotification
  slim?: boolean
}) => {
  const { paidBackups, isLoading } = usePaidBackups(shiftId)
  const workerIdsSet = new Set(workerIds)
  const finalPaidBackups =
    paidBackups?.filter((pb) => workerIdsSet.has(pb.workerId)) ?? []
  const handleSelectPaidBackups = (
    paidBackupsSelected: GridRowSelectionModel,
  ) => {
    const selectedPaidBackups = finalPaidBackups.filter((pb: PaidBackup) =>
      paidBackupsSelected.includes(pb.id),
    )
    setSelectedPaidBackups(selectedPaidBackups)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Row mx={theme.space.xs} my={theme.space.xxs}>
      <SentinelPaidBackupDetailsTable
        paidBackups={finalPaidBackups}
        handleSelectPaidBackups={handleSelectPaidBackups}
        notification={notification}
        slim={slim}
      />
    </Row>
  )
}
