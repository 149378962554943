import { WorkerShiftSignupNotificationStatus } from '@traba/types'
import Badge from '.'

export type NotificationStatusBadgeProps = {
  notificationStatus: WorkerShiftSignupNotificationStatus
}

const notificationStatusToBadgeVariantDict: Record<
  WorkerShiftSignupNotificationStatus,
  string
> = {
  [WorkerShiftSignupNotificationStatus.Queued]: 'info',
  [WorkerShiftSignupNotificationStatus.SendError]: 'danger',
  [WorkerShiftSignupNotificationStatus.Sent]: 'success',
  [WorkerShiftSignupNotificationStatus.TooManyNotificationsAlreadySent]:
    'opaqueRed',
  [WorkerShiftSignupNotificationStatus.WorkerIneligible]: 'opaqueRed',
}

export default function NotificationStatusBadge({
  notificationStatus,
}: NotificationStatusBadgeProps) {
  return (
    <Badge
      title={notificationStatus?.replaceAll('_', ' ')}
      variant={notificationStatusToBadgeVariantDict[notificationStatus]}
    />
  )
}
