import { IconButton, Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import useTimezonedDates from '../../../hooks/useTimezonedDates'
import { Icon, Row } from '../../base'
import JobStatusBadge from '../../JobStatusBadge'

export default function WorkerTableJobStatusCell({
  workerShift,
  timezone,
}: {
  workerShift: WorkerShift
  timezone: string
}) {
  const tz = useTimezonedDates(timezone)

  const tooltipText = `Originally accepted at ${tz.getTime(
    workerShift.createdAt,
  )}`

  return (
    <Row style={{ width: 120 }} justifyStart alignCenter>
      <JobStatusBadge
        jobStatus={workerShift.jobStatus}
        cancellationSource={workerShift.cancellationSource}
        lateCancellation={workerShift.canceledLate}
        rejectionReason={workerShift.rejectionReason}
        cancellationReason={workerShift.cancellationReason}
        canceledAt={workerShift.canceledAt}
        badgeTooltipText={tooltipText}
      />
      {workerShift.forgiveIncidents && (
        <Tooltip title="Incidents on this worker shift have been forgiven">
          <IconButton style={{ padding: 0 }}>
            <Row center alignCenter ml={theme.space.xxxs}>
              <Icon name="eyeSlash" />
            </Row>
          </IconButton>
        </Tooltip>
      )}
    </Row>
  )
}
