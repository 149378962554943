import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { KioskModeType, LocationResponse } from '@traba/types'

const updateKioskModeType = async (
  companyId: string,
  kioskModeType: KioskModeType,
) => {
  const res = await trabaApi.patch(
    `/companies/${companyId}/locations/kiosk-mode-type`,
    {
      kioskModeType,
    },
  )
  return res.data
}

export function useKioskLocationsMutation(companyId: string) {
  const { showSuccess, showError } = useAlert()

  const mutation = useMutation<LocationResponse[], Error, KioskModeType>({
    mutationFn: (kioskModeType: KioskModeType) =>
      updateKioskModeType(companyId, kioskModeType),
    onSuccess: (response: unknown) => {
      console.log('useKioskLocationsMutation -> mutation SUCCESS', response) // For DD
      showSuccess(
        'All locations have been successfully updated to the specified kiosk mode type.',
      )
    },
    onError: (error: unknown) => {
      console.error('useKioskLocationsMutation -> mutation ERROR', error) // For DD
      showError(
        'There was an error while resetting all locations to the specified kiosk mode type.',
        'Please try again or check each location to make sure operation was successful.',
      )
    },
  })

  return {
    mutateAsync: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
