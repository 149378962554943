import * as Sentry from '@sentry/react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import {
  AT_LEAST_ONE_ADMIN_REQUIRED_ERROR_MESSAGE,
  AT_LEAST_ONE_ADMIN_REQUIRED_VERBOSE_RESPONSE,
} from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  RecordStatus,
  ReplacementSupervisorForLocation,
  User,
  UserAccessLevel,
  UserRole,
} from '@traba/types'
import { useMemo } from 'react'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'
import { COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY } from './useCompanyShiftsLocationsForSupervisor'

export const COMPANY_USERS_QUERY_KEY = 'companyUsersCacheKey'

async function getCompanyUsers(
  companyId?: string,
): Promise<User[] | undefined> {
  if (!companyId) {
    return []
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}/users`)
    return res.data
  } catch (error: any) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyUsers ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

interface ChangeMemberRoleParams {
  uid: User['uid']
  role: UserRole
}

interface DeleteUserParams {
  uid: User['uid']
  firstName?: string
  lastName?: string
}

interface ArchiveMemberRequest {
  uid: string
  replacementUserId?: string
  replacementSupervisorsForLocations?: ReplacementSupervisorForLocation[]
}

export interface UpdateMemberRequest {
  uid: User['uid']
  role?: UserRole | null
  userAccessLevel: UserAccessLevel
  locationIds: string[]
  replacementSupervisorsForLocations?: ReplacementSupervisorForLocation[]
}

const changeMemberRole =
  (companyId: string) =>
  async ({ uid, role }: ChangeMemberRoleParams) => {
    if (!companyId) {
      throw new Error('companyId is required')
    }

    const response = await trabaApi.patch(
      `companies/${companyId}/users/${uid}/role`,
      { role },
    )
    return response.data
  }

const updateMember = async (member: UpdateMemberRequest) => {
  const {
    role,
    userAccessLevel,
    locationIds,
    replacementSupervisorsForLocations,
  } = member
  const response = await trabaApi.patch(`users/${member.uid}`, {
    role,
    userAccessLevel,
    locationIds,
    replacementSupervisorsForLocations,
  })
  return response.data
}

const deleteUser = async ({ uid }: DeleteUserParams) => {
  const response = await trabaApi.delete(`users/${uid}`)
  return response.data
}

const archiveMember = async ({
  uid,
  replacementUserId,
  replacementSupervisorsForLocations,
}: ArchiveMemberRequest) => {
  const response = await trabaApi.patch(`/users/${uid}/archive`, {
    replacementUserId,
    replacementSupervisorsForLocations,
  })
  return response.data
}

export function useCompanyUserMutations(companyId: string) {
  const queryClient = useQueryClient()
  const { showSuccess, handleError, showError } = useAlert()

  const changeMemberRoleMutation = useMutation<
    User,
    Error,
    ChangeMemberRoleParams,
    User
  >({
    mutationFn: changeMemberRole(companyId),
    onSuccess: ({
      role,
      uid,
      companyId,
    }: Pick<User, 'uid' | 'companyId' | 'role'>) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.map((m) =>
            m.uid === uid ? { ...m, role, uid, companyId } : m,
          )
        },
      )
      showSuccess(`User role was successfully updated to ${role}`)
    },
    onError: (error: Error) => {
      const message =
        error?.message === AT_LEAST_ONE_ADMIN_REQUIRED_ERROR_MESSAGE
          ? AT_LEAST_ONE_ADMIN_REQUIRED_VERBOSE_RESPONSE
          : 'There was an error changing your team member‘s role. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useCompanyUserMutations -> changeMemberRoleMutation',
        message,
        'Error changing role',
      )
    },
  })

  const updateMemberMutation = useMutation<
    User,
    Error,
    UpdateMemberRequest,
    User
  >({
    mutationFn: updateMember,
    onSuccess: (
      {
        role,
        uid,
        companyId,
        userAccessLevel,
        locations,
      }: Pick<
        User,
        'uid' | 'companyId' | 'role' | 'locations' | 'userAccessLevel'
      >,
      request,
    ) => {
      const { replacementSupervisorsForLocations } = request
      const userIdsToInvalidate =
        replacementSupervisorsForLocations?.map(
          ({ newSupervisorId }) => newSupervisorId,
        ) || []

      for (const userId of userIdsToInvalidate) {
        queryClient.invalidateQueries({
          queryKey: [
            COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY,
            companyId,
            userId,
          ],
        })
      }
      queryClient.invalidateQueries({
        queryKey: [COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY, companyId, uid],
      })
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.map((m) =>
            m.uid === uid
              ? { ...m, role, userAccessLevel, locations, uid, companyId }
              : m,
          )
        },
      )
      showSuccess(`User was successfully updated`)
    },
    onError: (error: Error) => {
      const message =
        error?.message === AT_LEAST_ONE_ADMIN_REQUIRED_ERROR_MESSAGE
          ? AT_LEAST_ONE_ADMIN_REQUIRED_VERBOSE_RESPONSE
          : 'There was an error updating this team member. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useCompanyUserMutations -> updateMemberMutation',
        message,
        'Error updating member',
      )
    },
  })

  const deleteUserMutation = useMutation<User, Error, DeleteUserParams, User>({
    mutationFn: deleteUser,
    onSuccess: (_response, { uid, firstName, lastName }: DeleteUserParams) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.filter((m) => m.uid !== uid)
        },
      )
      const userName = `${firstName} ${lastName}`.trim() || undefined
      showSuccess(
        userName
          ? `${userName}'s user was successfully deleted`
          : `User was successfully deleted`,
      )
    },
    onError: (error: Error) => {
      handleError(
        error,
        'useCompanyUserMutations -> deleteUserMutation',
        error.message,
        'Error deleting user',
      )
      showError(error.message, 'Error')
    },
  })

  const archiveMemberMutation = useMutation<
    User,
    Error,
    ArchiveMemberRequest,
    User
  >({
    mutationFn: archiveMember,
    onSuccess: ({ uid }: Pick<User, 'uid'>) => {
      queryClient.setQueryData(
        [COMPANY_USERS_QUERY_KEY, { companyId }],
        (companyMembers: User[] | undefined = []) => {
          return companyMembers.filter((m) => m.uid !== uid)
        },
      )
      showSuccess(`User was successfully archived`)
    },
    onError: (error: Error) => {
      const message =
        error?.message === 'archive/active-shifts'
          ? 'This user is a supervisor on current or upcoming shifts, please select a replacement.'
          : 'An error has occurred. Unable to archive user.'
      handleError(
        error,
        'useCompanyUserMutations -> archiveMemberMutation',
        message,
        'Error archiving user',
      )
    },
  })

  return {
    updateMember: updateMemberMutation.mutate,
    isUpdateMemberLoading: updateMemberMutation.isPending,
    changeCompanyMemberRole: changeMemberRoleMutation.mutate,
    isChangeCompanyMemberRoleLoading: changeMemberRoleMutation.isPending,
    deleteUser: deleteUserMutation.mutate,
    isDeleteUserLoading: deleteUserMutation.isPending,
    archiveCompanyMember: archiveMemberMutation.mutate,
    isArchiveCompanyMemberRoleLoading: archiveMemberMutation.isPending,
  }
}

export function useCompanyUsers(companyId: string | undefined) {
  const {
    isLoading,
    isError,
    data: companyUsers,
    error,
    isFetched,
    refetch,
  } = useQuery<User[] | undefined, Error>({
    queryKey: [COMPANY_USERS_QUERY_KEY, { companyId }],
    queryFn: () => getCompanyUsers(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  const userIdToCompanyUserMap = useMemo(() => {
    return (
      companyUsers?.reduce(
        (acc, user) => {
          if (!user.uid) {
            return acc
          }
          return { ...acc, [user.uid]: user }
        },
        {} as Record<string, User>,
      ) ?? {}
    )
  }, [companyUsers])

  const activeCompanyUsers = companyUsers?.filter(
    (user) => user.recordStatus === RecordStatus.Active,
  )

  return {
    isLoading,
    isError,
    activeCompanyUsers,
    companyUsers,
    userIdToCompanyUserMap,
    error,
    isFetched,
    refetch,
  }
}

export function useLocationAssignedActiveUsers(
  companyId: string,
  locationId: string,
) {
  const { activeCompanyUsers, isLoading } = useCompanyUsers(companyId)
  const locationAssignedUsers = useMemo(
    () =>
      activeCompanyUsers?.filter(
        (u) =>
          u.userAccessLevel === UserAccessLevel.LOCATIONS_ASSIGNED &&
          u.locations?.some((loc) => loc.locationId === locationId),
      ) || [],
    [activeCompanyUsers, locationId],
  )
  return {
    locationAssignedUsers,
    isLoading,
  }
}

export function useCompanyMemberIdToMemberMap(companyId: string | undefined) {
  const { companyUsers, isLoading } = useCompanyUsers(companyId)

  const memberEntries: [string, User][] =
    companyUsers?.map((member) => [member.uid, member]) || []

  const memberIdToMemberMap = new Map(memberEntries)
  return { memberIdToMemberMap, isLoading }
}
