import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Modal,
  Text,
} from '@traba/react-components'
import { useState } from 'react'
import WorkerHoursAtCompany from './WorkerHoursAtCompany'

export default function WorkerHours({
  totalMinutesWorkedPerCompany,
}: {
  totalMinutesWorkedPerCompany?: { [key: string]: number }
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!totalMinutesWorkedPerCompany) {
    return <LoadingSpinner />
  }
  if (Object.keys(totalMinutesWorkedPerCompany).length === 0) {
    return <Text>No hours worked data available</Text>
  }
  const sortedCompanies = Object.entries(totalMinutesWorkedPerCompany).sort(
    ([, minsA], [, minsB]) => minsB - minsA,
  )

  return (
    <>
      <Button
        slim
        variant={ButtonVariant.TEXT}
        onClick={() => setIsModalOpen(true)}
      >
        View Hours At Companies
      </Button>

      <Modal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Worker Hours at Companies"
      >
        <div role="list" id="worker-hours-description">
          {sortedCompanies.map(([companyId, minutes]) => (
            <WorkerHoursAtCompany
              key={companyId}
              companyId={companyId}
              minutes={minutes}
            />
          ))}
        </div>
      </Modal>
    </>
  )
}
