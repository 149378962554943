import { unparse } from 'papaparse'
import { downloadBlob } from '../../../../utils/helperUtils'

export function getLinearProgressValue(
  currentPage: number,
  totalRecords: number,
  limit: number,
): number {
  const lastPage = Math.ceil(totalRecords / limit)
  if (currentPage === 1) {
    return 0
  } else if (currentPage === lastPage) {
    return 100
  } else {
    return Math.floor((currentPage / lastPage) * 100)
  }
}

export function getExportMessage({
  currentPage,
  totalRecords,
  isExporting,
  limit,
  entity,
  savedSearchId,
}: {
  currentPage: number
  totalRecords: number
  isExporting: boolean
  limit: number
  entity: string
  savedSearchId?: string
}): string {
  if (!isExporting) {
    return ''
  }

  if (savedSearchId) {
    return `Processing list ${entity}, this may take a while`
  }

  if (currentPage === 1 || totalRecords <= limit) {
    return `Processing ${totalRecords} ${entity}`
  }

  const totalPages = Math.ceil(totalRecords / limit)
  return `Processing ${currentPage} of ${totalPages} pages`
}

export function convertNestedObjectsToString(data: any) {
  if (typeof data === 'object') {
    return JSON.stringify(data).replace(/,/g, ' ').replace(/"/g, "'")
  }
  return data
}

// convert the nested objects to strings in the data array
export function convertToCSV(data: any) {
  const processedData = data.map((item: any) => {
    const newItem = { ...item }
    Object.keys(newItem).forEach((key) => {
      newItem[key] = convertNestedObjectsToString(newItem[key])
    })
    return newItem
  })
  return unparse(processedData, { header: true })
}

export function downloadArrayAsCSV<T>(data?: T[], fileName = 'export') {
  if (!data?.length) {
    return
  }
  const csv = convertToCSV(data)
  downloadBlob(csv, fileName)
}
