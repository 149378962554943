import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerJobPlacementAction } from '@traba/types'
import { useMemo, useState } from 'react'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useCompanies } from 'src/hooks/useCompanies'
import { WorkerJobPlacementContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'

type WorkerJobPlacementCreateModalProps = {
  workerId: string
  handleClose: () => void
  isOpen: boolean
}

export const WorkerJobPlacementCreateModal = (
  props: WorkerJobPlacementCreateModalProps,
) => {
  const { isOpen, handleClose, workerId } = props
  const { companies = [] } = useCompanies({ isApproved: true })

  const companyOptions: IMenuItem[] = useMemo(() => {
    return (
      companies?.reduce(
        (acc, company) => {
          if (company.id) {
            acc.push({ label: company.employerName, value: company.id })
          }
          return acc
        },
        [{ label: '-', value: '' }],
      ) || [{ label: '-', value: '' }]
    )
  }, [companies])

  const [company, setCompany] = useState<IMenuItem | undefined>(
    companyOptions[0],
  )

  function handleCloseModal() {
    handleClose()
    setCompany(undefined)
  }

  return (
    <Modal
      handleClose={handleCloseModal}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={'Mark worker as hired for company'}
    >
      <Row flexCol fullHeight fullWidth>
        <Text variant="h5" style={{ marginRight: theme.space.xs }}>
          Select a company
        </Text>
        <Row alignCenter mb={theme.space.sm} mt={theme.space.xs}>
          <SearchSelect
            options={companyOptions}
            selectItem={company}
            handleSelect={setCompany}
            label="Business"
            style={{ minWidth: '40%' }}
          />
        </Row>
        {!!company?.value && (
          <WorkerJobPlacementContent
            companyId={company.value}
            handleClose={handleCloseModal}
            workerId={workerId}
            action={WorkerJobPlacementAction.CREATE_WORKER}
          />
        )}
      </Row>
    </Modal>
  )
}
