import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { CancelRecurringWorkerShiftRequest } from '@traba/types'

export const cancelRecurringWorkerShift = async (
  request: CancelRecurringWorkerShiftRequest,
): Promise<any> => {
  try {
    return trabaApi.patch(`recurring-worker-shift-edits/cancel`, request)
  } catch (error: any) {
    Sentry.captureException(error)
    throw error
  }
}
