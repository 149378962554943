import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { WardenExemptionsTable } from 'src/components/WardenExemptionsTable'
import { ArchiveWardenExemptionModal } from 'src/modals/ArchiveWardenExemptionModal/ArchiveWardenExemptionModal'
import { CreateWardenExemptionModal } from 'src/modals/CreateWardenExemptionModal/CreateWardenExemptionModal'
import { ExtendedWardenExemption } from 'src/types/warden'

type WardenExemptionsSectionProps = {
  shiftId: string
  wardenExemptions: ExtendedWardenExemption[]
  refetch: () => void
  workersOnShift: Worker[]
}

export const WardenExemptionsSection = (
  props: WardenExemptionsSectionProps,
) => {
  const { wardenExemptions, refetch, shiftId, workersOnShift } = props
  const inheritedWardenExemptions = wardenExemptions.filter(
    (exemption) => !exemption.shiftId,
  )
  const shiftWardenExemptions = wardenExemptions.filter(
    (exemption) => !!exemption.shiftId,
  )

  const [
    isCreateWardenExemptionModalOpen,
    setIsCreateWardenExemptionModalOpen,
  ] = useState(false)

  const [exemptionToArchive, setExemptionToArchive] = useState<
    ExtendedWardenExemption | undefined
  >()

  return (
    <Col>
      <Row>
        <Col mr={theme.space.xxs}>
          <Text variant="h6">Inherited Warden Exemptions</Text>
          <Text mb={theme.space.sm}>
            These warden exemptions are inherited from either the company, role
            or worker. They cannot be created or archived.
          </Text>
          <WardenExemptionsTable
            wardenExemptions={inheritedWardenExemptions}
            hideActions
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onArchiveClick={() => {}} // noop
          />
        </Col>
        <Col ml={theme.space.xxs}>
          <Row>
            <Col>
              <Text variant="h6">Shift Warden Exemptions</Text>
              <Text mb={theme.space.sm}>
                These warden exemptions are specific to this shift. They can be
                created or archived from this screen.
              </Text>
            </Col>
            <Button
              style={{ padding: 0 }}
              leftIcon={<Plus />}
              reverse
              variant={ButtonVariant.TEXT}
              onClick={() => setIsCreateWardenExemptionModalOpen(true)}
            >
              New Exemption
            </Button>
          </Row>

          <WardenExemptionsTable
            wardenExemptions={shiftWardenExemptions}
            hideCompany
            hideRole
            onArchiveClick={setExemptionToArchive}
          />
        </Col>
      </Row>
      <CreateWardenExemptionModal
        title="Create new exemption for shift"
        isOpen={isCreateWardenExemptionModalOpen}
        setIsOpen={setIsCreateWardenExemptionModalOpen}
        refetch={refetch}
        shiftId={shiftId}
        workersOnShift={workersOnShift}
      />
      <ArchiveWardenExemptionModal
        exemptionToArchive={exemptionToArchive}
        setExemptionToArchive={setExemptionToArchive}
        refetch={refetch}
      />
    </Col>
  )
}
