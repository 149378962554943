import { useAlert } from '@traba/context'
import { useState } from 'react'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useWardenExemptions } from 'src/hooks/useWardenExemptions'
import { ExtendedWardenExemption } from 'src/types/warden'

export function ArchiveWardenExemptionModal({
  exemptionToArchive,
  setExemptionToArchive,
  refetch,
}: {
  exemptionToArchive: ExtendedWardenExemption | undefined
  setExemptionToArchive: (value: ExtendedWardenExemption | undefined) => void
  refetch: () => void
}) {
  const { archiveWardenExemption } = useWardenExemptions()
  const { handleError } = useAlert()

  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleConfirm = () => {
    if (!exemptionToArchive) {
      return
    }

    setConfirmLoading(true)

    archiveWardenExemption(exemptionToArchive.id, {
      onSuccess: () => {
        refetch()
        setExemptionToArchive(undefined)
      },
      onError: (error) => {
        handleError(
          error,
          'ArchiveWardenExemptionModal -> handleConfirm()',
          error.message,
          'Error archiving warden exemption',
        )
      },
      onSettled: () => {
        setConfirmLoading(false)
      },
    })
  }

  return (
    <ConfirmationDialog
      open={!!exemptionToArchive}
      onConfirm={handleConfirm}
      onClose={() => setExemptionToArchive(undefined)}
      title="Archive Exemption?"
      confirmButtonText="Archive"
      content={`Are you sure you want to archive this exemption? You cannot undo this action and will need to create a new exemption if you want to re-enable this exemption.`}
      confirmLoading={confirmLoading}
    />
  )
}
