import { PaidBackup } from '@traba/types'
import useTimezonedDates from '../../../hooks/useTimezonedDates'
import { Row } from '../../base'
import JobStatusBadge from '../../JobStatusBadge'

export default function PaidBackupTableJobStatusCell({
  paidBackup,
  timezone,
}: {
  paidBackup: PaidBackup
  timezone: string
}) {
  const tz = useTimezonedDates(timezone)

  const tooltipText = `Originally accepted at ${tz.getTime(
    paidBackup.createdAt,
  )} ${paidBackup.confirmedAt ? `and confirmed at ${tz.getTime(paidBackup.confirmedAt)}` : 'not confirmed'}`

  return (
    <Row style={{ width: 120 }} justifyStart alignCenter>
      <JobStatusBadge
        jobStatus={paidBackup.status}
        cancellationSource={paidBackup.cancellationSource}
        cancellationReason={paidBackup.cancellationReason}
        badgeTooltipText={tooltipText}
      />
    </Row>
  )
}
