import { InputAdornment, TextField } from '@mui/material'
import { SyntheticEvent } from 'react'

type NumberInputProps = {
  value?: number
  setter: (x: number) => void
  label?: string
  placeholder?: string
  step?: number
  disabled?: boolean
  money?: boolean
  decimals?: number
  error?: boolean
  helperText?: string
  width?: number
  alignCenter?: boolean
}

export const DeprecatedNumberInput = (props: NumberInputProps) => {
  const {
    value,
    setter,
    step,
    disabled,
    money,
    decimals,
    helperText,
    error,
    width,
    alignCenter,
  } = props

  const blurTarget = (event: SyntheticEvent) => {
    event.target instanceof HTMLElement && event.target.blur()
  }

  const onIntegerChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: (x: number) => void,
  ) => {
    const inputNum = +ev.currentTarget.value
    const parsedNum = +Math.abs(inputNum).toFixed(
      money ? 2 : decimals !== undefined ? decimals : 0,
    )
    setter(parsedNum)
  }

  const MoneyInputProps = money
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }
    : {}

  return (
    <TextField
      margin="dense"
      style={{ width: width || '100%' }}
      sx={alignCenter ? { input: { textAlign: 'center' } } : {}}
      placeholder={props.placeholder}
      value={value?.toFixed(money ? 2 : decimals !== undefined ? decimals : 0)}
      label={props.label}
      onChange={(ev) => onIntegerChange(ev, setter)}
      disabled={disabled}
      inputProps={{
        step: step ?? 1,
        type: 'number',
        inputMode: 'numeric',
      }}
      onWheel={blurTarget}
      variant="outlined"
      InputProps={MoneyInputProps}
      error={error}
      helperText={error ? helperText : undefined}
    />
  )
}
