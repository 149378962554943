import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftTime } from '@traba/types'
import { addMinutes } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'
import { Button, DatePicker, Icon } from 'src/components/base'
import { ButtonVariant } from '../base/Button/types'

type BreakAdjusterProps = {
  breaks: ShiftTime[]
  setBreaks: Dispatch<SetStateAction<ShiftTime[]>>
  timezone?: string
  defaultBreakTime?: Date
}

export const BreakAdjuster = ({
  breaks,
  setBreaks,
  timezone,
  defaultBreakTime,
}: BreakAdjusterProps) => {
  return (
    <>
      {breaks.map((b, i) => {
        return (
          <Row
            key={i}
            mb={theme.space.sm}
            alignCenter
            justifyEvenly
            style={{ flex: 1 }}
          >
            <Text variant="caption">Break {i + 1}</Text>
            <DatePicker
              date={b.startTime}
              setDate={(startTime) => {
                const clonedBreaks = breaks.map((ele) => {
                  return { ...ele }
                })
                clonedBreaks[i].startTime = startTime
                setBreaks(clonedBreaks)
              }}
              label="Start Time"
              isClearable={false}
              granularity="minute"
              timezone={timezone}
            />
            <DatePicker
              date={b.endTime}
              setDate={(endTime) => {
                const clonedBreaks = breaks.map((ele) => {
                  return { ...ele }
                })
                clonedBreaks[i].endTime = endTime
                setBreaks(clonedBreaks)
              }}
              label="End Time"
              isClearable={false}
              granularity="minute"
              timezone={timezone}
            />
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                setBreaks(breaks.filter((_, idx) => idx !== i))
              }}
            >
              <Icon name="trash" />
            </Button>
          </Row>
        )
      })}
      <Row>
        <Button
          full
          variant={ButtonVariant.PURPLEGRADIENT}
          onClick={() => {
            const clonedBreaks = breaks.map((ele) => {
              return { ...ele }
            })
            const newBreak = {
              startTime: defaultBreakTime
                ? new Date(defaultBreakTime)
                : new Date(),
              endTime: defaultBreakTime
                ? addMinutes(new Date(defaultBreakTime), 30)
                : new Date(),
            }
            clonedBreaks.push(newBreak)
            setBreaks(clonedBreaks)
          }}
        >
          Add Break
        </Button>
      </Row>
    </>
  )
}
