import { IncentiveInfo, ProgressLine } from './incentive'
import { Money } from './money'
import { Transaction } from './payments'
import { Worker } from './workers'

/**
 * IN_PROGRESS: **some** incentives will have a progress stage
 * COMPLETE: incentive that is fully completed
 * REVERSED: Adjustments were made to a worker shift
 * FAILED: Was not completed until the related incentive expired
 */
export enum WorkerIncentiveStatus {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Reversed = 'REVERSED',
  Failed = 'FAILED',
}

export enum ReferralTypes {
  Referee = 'REFEREE', // target
  Referrer = 'REFERRER', // source
}

export interface WorkerIncentive {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  earnedAt?: Date
  workerId: string
  incentiveId: string
  workerIncentiveId: string
  companyId: string
  transactions?: Transaction[]
  instantPayFee?: Money
  progress: ProgressLine[]
  incentiveInfo: IncentiveInfo
  status: WorkerIncentiveStatus
  pendingReview?: boolean
  reviewReason?: string
  shiftId?: string
  shiftsCompleted?: string[]
  // Fields related to referral, dependents of the referralType
  referralType?: ReferralTypes
  refereeId?: string
  referrerId?: string
  referrerCode?: string
  refereeCode?: string
  total?: Money
  worker?: Partial<Worker>
}

export interface WorkerIncentiveData {
  createdAt: Date
  earnedAt?: Date
  workerId: string
  incentiveId: string
  workerIncentiveId: string
  status: WorkerIncentiveStatus
  companyId: string
  progress: ProgressLine[]
  incentiveInfo: IncentiveInfo
  total?: Money
  pendingReview: boolean
  referralType?: ReferralTypes
  refereeId?: string
  referrerId?: string
  referrerCode?: string
  refereeCode?: string
}

export enum WorkerIncentiveReviewAction {
  PAYOUT = 'PAYOUT',
  REVERSE = 'REVERSE',
  DISMISS = 'DISMISS',
}
