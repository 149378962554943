import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button, Input, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useApiWithFeedback } from 'src/hooks/useApi'

export default function VerifyUserEmail() {
  const [email, setEmail] = useState('')

  const { isLoading: isLoadingVerifyEmail, apiCall: verifyEmail } =
    useApiWithFeedback(
      'post',
      `users/email/${email}/verify-email`,
      'Successfully verified email',
    )

  return (
    <Row
      flexCol
      gap={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
        width: '30%',
      }}
    >
      <Text variant="h4">Verify Business User Email</Text>
      <Input
        label="Email"
        width="100%"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setEmail(e.target.value)
        }
      />
      <Button
        variant={ButtonVariant.FILLED}
        loading={isLoadingVerifyEmail}
        onClick={() => verifyEmail()}
        disabled={email === ''}
      >
        Verify
      </Button>
    </Row>
  )
}
