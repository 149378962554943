import { PaymentLogEarnings } from '@traba/types'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useApi } from './useApi'
import { useHotSettings } from './useHotSettings'

export const useEarnings = (workerId: string) => {
  const { hotSettings } = useHotSettings()
  const {
    isLoading: isLoadingEarnings,
    data: earnings,
    refetch: refetchEarnings,
    isFetched,
  } = useApi<PaymentLogEarnings[] | undefined>(
    hotSettings?.newEarningsScreenCutoffDate
      ? `/workers/${workerId}/earnings-v2?cutOffDate=${hotSettings.newEarningsScreenCutoffDate}`
      : `/workers/${workerId}/earnings-v2`,
    undefined,
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoadingEarnings,
    isFetched,
    refetchEarnings,
    earnings,
  }
}
