import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { Company } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

async function getCompany(companyId?: string): Promise<Company | undefined> {
  if (!companyId) {
    return undefined
  }

  try {
    const res = await trabaApi.get(`companies/${companyId}`)
    return res.data
  } catch (error: any) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompany -> getCompany() ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useCompany(companyId?: string) {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: company,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<Company | undefined, Error>(
    `company_${companyId}`,
    () => getCompany(companyId),
    {
      enabled: !!companyId,
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const patchCompanyMutation = useMutation<
    Company,
    AxiosError,
    Partial<Company>,
    Company
  >(
    (updatedCompany) =>
      trabaApi.patch(`/companies/${companyId}`, updatedCompany),
    {
      onSuccess: (response: any) => {
        queryClient.setQueryData('company', response.data)
        refetch()
      },
    },
  )

  return {
    isLoading,
    isFetching,
    isError,
    company,
    error,
    isFetched,
    refetch,
    patchCompany: patchCompanyMutation.mutateAsync,
  }
}
