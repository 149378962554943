import { CheckCircle, WarningOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { WorkerShiftEligibility } from '@traba/types'
import { LightTooltip } from '../../BugleDrawer/styles'
import { TooltipContent } from '../../BugleDrawer/WorkerActions'

function EligibilityIcon({
  eligibility,
  placement = 'left',
  size = 'small',
}: {
  eligibility?: WorkerShiftEligibility
  placement?: 'top' | 'bottom' | 'left' | 'right'
  size?: 'small' | 'medium' | 'large'
}) {
  const makeEligibilityIcon = () => {
    const hasEligibilityIssues = Object.values(
      eligibility?.eligibilityCriteria ?? {},
    ).some((value) => value === false)

    const IconComponent = hasEligibilityIssues ? WarningOutlined : CheckCircle
    const color = hasEligibilityIssues ? 'warning' : 'success'

    return <IconComponent color={color} fontSize={size} />
  }

  return (
    <LightTooltip
      title={<TooltipContent eligibility={eligibility} />}
      placement={placement}
      arrow
    >
      <IconButton size={size} sx={{ maxWidth: 56 }}>
        {makeEligibilityIcon()}
      </IconButton>
    </LightTooltip>
  )
}

export default EligibilityIcon
