import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { Worker } from '@traba/types'
import { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { WorkerVettingSessionStatus } from 'src/types/worker-vetting'
import {
  WorkerVettingCampaign,
  WorkerVettingMethod,
  WorkerVettingSession,
  WorkerVettingSessionMessage,
  SupportedLanguages,
} from '../types/worker-vetting'
import {
  PaginationParams,
  useBasicPagination,
  basePaginationParams,
  DEFAULT_PAGE_SIZE,
} from './usePagination'
import { makeSearchParameters } from './utils/searchUtils'

const WORKER_VETTING_CAMPAIGN_QUERY_KEY = 'WORKER_VETTING_CAMPAIGN_QUERY_KEY'
const WORKER_VETTING_SESSION_QUERY_KEY = 'WORKER_VETTING_SESSION_QUERY_KEY'
const WORKER_VETTING_PHONE_CALLS_QUERY_KEY =
  'WORKER_VETTING_PHONE_CALLS_QUERY_KEY'
const SEARCH_VETTING_QUERY_KEY = 'vettingSearchCacheKey'
const ELEVENLABS_CONVERSATIONAL_AI_QUERY_KEY =
  'ELEVENLABS_CONVERSATIONAL_AI_QUERY_KEY'

export type CreateWorkerVettingCampaignData = {
  title: string
  initialMessage: string
  promptDetails: string
  phoneNumbers: string
  shiftId: string
  questions: string[]
  regionIds: string[]
  deadline: Date
  type: WorkerVettingMethod
  language: SupportedLanguages
  customQualifiedExitMessage?: string
  customUnqualifiedExitMessage?: string
}

export type WorkerVettingSessionResponse = WorkerVettingSession & {
  messages: WorkerVettingSessionMessage[]
  worker?: Worker
}

export type WorkerVettingSessionWithPhoneCalls =
  WorkerVettingSessionResponse & {
    phoneCalls: SlimWorkerVettingPhoneCall[]
  }

export enum WorkerVettingPhoneCallStatus {
  INITIATED = 'INITIATED',
  INITIATED_OUTBOUND = 'INITIATED_OUTBOUND',
  MISSED_OUTBOUND = 'MISSED_OUTBOUND',
  CALL_COMPLETE = 'CALL_COMPLETE',
  VETTING_COMPLETE = 'VETTING_COMPLETE',
}

export interface TranscriptMessage {
  role: string
  message: string
  time_in_call_secs: number
  feedback: null | string
  tool_calls: null | any
  tool_results: null | any
}

export type WorkerVettingPhoneCallResponse = {
  id: string
  createdAt: Date
  phoneNumber: string
  workerId?: string
  ghostProfileId?: string
  status: WorkerVettingPhoneCallStatus
  transcript?: TranscriptMessage[]
  evalOutput?: Record<string, string>
  conversationId?: string
  summary?: string
}

export type SlimWorkerVettingPhoneCall = Pick<
  WorkerVettingPhoneCallResponse,
  'createdAt' | 'ghostProfileId' | 'id' | 'phoneNumber' | 'status' | 'workerId'
>

export type WorkerVettingCampaignResponse = WorkerVettingCampaign & {
  workerVettingSessions: WorkerVettingSessionResponse[]
  workerVettingPhoneCalls: SlimWorkerVettingPhoneCall[]
}

/** Remove letters and trim spaces */
function parsePhoneNumbers(phoneNumbersString: string): string[] {
  return phoneNumbersString
    .split(',')
    .map((number) => {
      return number.trim().replace(/(?!^\+)\D/g, '')
    })
    .filter((number) => number !== '')
}

export type WorkerVettingCampaignParams = {
  id?: string
  shiftId?: string
  roleId?: string
  title?: string
  phoneNumbers?: string[]
  regionIds?: string[]
  initiatedBy?: string
}

type SearchWorkerVettingPhoneCallsParams = {
  phoneNumber: string
  vettingCampaignId?: string
  workerVettingCampaignId?: string
}

interface WorkerVettingPhoneCallsProps {
  params: SearchWorkerVettingPhoneCallsParams
  paginationParams?: PaginationParams
  enabled: boolean
}

interface ElevenLabsConversationalAIProps {
  conversationId?: string
}

export type WorkerVettingCampaignQuery = {
  withCount?: boolean
  parameters: WorkerVettingCampaignParams
}

async function searchWorkerVettingPhoneCalls(
  params: SearchWorkerVettingPhoneCallsParams,
  paginationParams: PaginationParams,
): Promise<WorkerVettingPhoneCallResponse[] | undefined> {
  try {
    const { limit, offset } = paginationParams
    const response = await trabaApi.post(
      `workers/vetting/phone-calls?startAt=${offset}&limit=${limit}`,
      params,
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    throw error
  }
}

async function fetchElevenLabsConversationAudio(conversationId?: string) {
  if (!conversationId) {
    return
  }
  try {
    const response = await trabaApi.get(
      `voice/conversation-audio/${conversationId}`,
      {
        responseType: 'arraybuffer',
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    throw error
  }
}

export async function runSearchWorkerVettingCampaigns(
  parameters: WorkerVettingCampaignParams,
  paginationParams: PaginationParams,
  config?: AxiosRequestConfig,
): Promise<
  | { workerVettingCampaigns: WorkerVettingCampaignResponse[]; count: number }
  | undefined
> {
  try {
    const payload: WorkerVettingCampaignQuery = {
      parameters: makeSearchParameters(parameters),
      withCount: true,
    }
    const { limit, offset, sortBy, sortOrder } = paginationParams
    const URL = `workers/vetting/search?startAt=${offset}&limit=${limit}${
      sortBy ? `&orderBy=${String(sortBy)}` : ''
    }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
    const res = await trabaApi.post(URL, payload, config)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export function useSearchWorkerVettingCampaigns({
  params,
  paginationParams,
  config,
}: {
  params: WorkerVettingCampaignParams
  paginationParams: PaginationParams
  config?: AxiosRequestConfig
}) {
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  const {
    data: searchResults,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [SEARCH_VETTING_QUERY_KEY, params, currentPage],
    queryFn: () => runSearchWorkerVettingCampaigns(params, pagination, config),
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    campaigns: searchResults?.workerVettingCampaigns || [],
    totalFound: searchResults?.count || 0,
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}

export function useWorkerVetting() {
  const [isLoadingVettingData, setIsLoadingVettingData] = useState(false)
  const { showError, showSuccess } = useAlert()

  async function createNewWorkerVettingCampaign(
    campaignData: CreateWorkerVettingCampaignData,
  ) {
    try {
      setIsLoadingVettingData(true)
      const response = await trabaApi.post('workers/vetting/start-vetting', {
        ...campaignData,
        phoneNumbers: parsePhoneNumbers(campaignData.phoneNumbers),
      })
      showSuccess('Vetting campaign created successfully', '')
      return response.data
    } catch (error: any) {
      showError(
        `There was an error creating the vetting campaign: ${error.message}`,
        'Error',
      )
      Sentry.captureException(error)
    } finally {
      setIsLoadingVettingData(false)
    }
  }

  return { isLoadingVettingData, createNewWorkerVettingCampaign }
}

const getWorkerVettingCampaign = async (
  campaignId: string,
): Promise<WorkerVettingCampaignResponse | undefined> => {
  try {
    const response = await trabaApi.get(
      `/workers/vetting/campaign/${campaignId}?includeMessages=true&includePhoneCalls=true`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

const getWorkerVettingSession = async (
  sessionId: string,
): Promise<WorkerVettingSessionResponse | undefined> => {
  try {
    const response = await trabaApi.get(
      `/workers/vetting/session/${sessionId}?includeMessages=true&includeWorker=true`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerVettingCampaign = (campaignId: string) => {
  const { showError, showSuccess } = useAlert()

  const {
    isLoading,
    isError,
    data: campaign,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [WORKER_VETTING_CAMPAIGN_QUERY_KEY, campaignId],
    queryFn: () => getWorkerVettingCampaign(campaignId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const cancelWorkerVettingCampaign = async (campaignId: string) => {
    if (!window.confirm(`Are you sure you want to cancel this campaign?`)) {
      return
    }
    try {
      await trabaApi.patch(`/workers/vetting/cancel-campaign/${campaignId}`)
      showSuccess('Vetting campaign cancelled successfully', '')
    } catch (error: any) {
      showError(
        error.message,
        'There was an error cancelling the vetting campaign',
      )
      Sentry.captureException(error)
    }
  }

  return {
    isLoading,
    isError,
    campaign,
    error,
    isFetching,
    refetch,
    cancelWorkerVettingCampaign,
  }
}

export const useWorkerVettingSession = (sessionId: string) => {
  const { showError, showSuccess } = useAlert()

  const {
    isLoading,
    isError,
    data: session,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [WORKER_VETTING_SESSION_QUERY_KEY, sessionId],
    queryFn: () => getWorkerVettingSession(sessionId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const patchSession = async (
    sessionId: string,
    action: 'qualify' | 'disqualify' | 'cancel',
  ) => {
    if (!window.confirm(`Are you sure you want to ${action} this session?`)) {
      return
    }
    try {
      let reqBody = {}
      if (action === 'qualify') {
        reqBody = { opsScore: 200 }
      } else if (action === 'disqualify') {
        reqBody = { opsScore: 500 }
      } else if (action === 'cancel') {
        reqBody = { status: WorkerVettingSessionStatus.canceled }
      }
      await trabaApi.patch(`/workers/vetting/session/${sessionId}`, reqBody)
      showSuccess('Vetting session updated successfully')
    } catch (error: any) {
      showError(
        error.message,
        'There was an error updating the vetting session',
      )
      Sentry.captureException(error)
    }
  }

  return {
    isLoading,
    isError,
    session,
    error,
    isFetching,
    refetch,
    patchSession,
  }
}

export function useWorkerVettingPhoneCalls(
  props: WorkerVettingPhoneCallsProps,
) {
  const { params, paginationParams = basePaginationParams, enabled } = props
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  const {
    data: phoneCalls,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [WORKER_VETTING_PHONE_CALLS_QUERY_KEY, params, currentPage],
    queryFn: () => searchWorkerVettingPhoneCalls(params, pagination),
    enabled: !!params.phoneNumber && enabled,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    data: phoneCalls || [],
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}

export function useElevenLabsConversationalAI(
  props: ElevenLabsConversationalAIProps,
) {
  const { conversationId } = props
  const { data, error, isError, isLoading, refetch } = useQuery({
    queryKey: [ELEVENLABS_CONVERSATIONAL_AI_QUERY_KEY, conversationId],
    queryFn: () => fetchElevenLabsConversationAudio(conversationId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!conversationId,
  })

  return {
    data,
    isError,
    error,
    isLoading,
    refetch,
  }
}
