import { Switch } from '@mui/material'
import { theme } from '@traba/theme'
import { ScheduledBreak } from '@traba/types'
import { BreakType } from '@traba/types'
import { useState } from 'react'
import { Row, Col, Select } from 'src/components/base'
import { Label } from 'src/components/base/Input/Input.styles'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { ShiftPostingInputContainerSection } from './ShiftPostingInputContainer'

type SelectBreakFormProps = {
  createShiftRequest: CreateShiftRequest
  defaultBreaks?: ScheduledBreak
  setCreateShiftRequest: React.Dispatch<
    React.SetStateAction<CreateShiftRequest>
  >
}

export const SelectBreakForm = (props: SelectBreakFormProps) => {
  const { hotSettings } = useHotSettings()
  const { createShiftRequest, setCreateShiftRequest, defaultBreaks } = props
  const defaultBreaksCount = defaultBreaks?.count
  const defaultBreaksLength = defaultBreaks?.breakLength

  /**
   * Num Breaks State
   */
  const [hasBreaks, setHasBreaks] = useState<boolean>(
    (!!createShiftRequest.scheduledBreaks &&
      createShiftRequest.scheduledBreaks.length > 0) ||
      !!defaultBreaks,
  )

  const [numBreaks, setNumBreaks] = useState<number | undefined>(
    createShiftRequest.scheduledBreaks?.length === 0
      ? defaultBreaksCount || 1
      : createShiftRequest.scheduledBreaks?.reduce((acc, sb) => {
          return acc + sb.count
        }, 0),
  )
  const [breakLength, setBreakLength] = useState<number | undefined>(
    !!createShiftRequest.scheduledBreaks &&
      createShiftRequest.scheduledBreaks.length > 0
      ? createShiftRequest.scheduledBreaks[0].breakLength
      : defaultBreaksLength || 30,
  )

  const breakTypeOptions = [
    { value: BreakType.AUTO_UNPAID, label: 'Auto Unpaid' },
    ...(hotSettings?.enableReturnOfManualBreak
      ? [{ value: BreakType.MANUAL_UNPAID, label: 'Manual Unpaid' }]
      : []),
    { value: BreakType.PAID, label: 'Paid' },
  ]

  const handleScheduledBreaks = (
    hasBreaks: boolean,
    breakLength: number | undefined,
    numBreaks: number | undefined,
  ) => {
    if (!hasBreaks) {
      setCreateShiftRequest({ ...createShiftRequest, scheduledBreaks: [] })
    } else {
      setCreateShiftRequest({
        ...createShiftRequest,
        scheduledBreaks: [
          { breakLength: breakLength || 0, count: numBreaks || 0 },
        ],
      })
    }
  }

  return (
    <>
      <Row alignCenter mt={theme.space.med} center>
        <ShiftPostingInputContainerSection
          label="Has Breaks?"
          input={
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={hasBreaks}
              onClick={() => {
                const breaksToSet = !hasBreaks
                setHasBreaks(breaksToSet)
                handleScheduledBreaks(breaksToSet, breakLength, numBreaks)
              }}
            />
          }
          right
        />
      </Row>
      {hasBreaks && (
        <Col
          mt={theme.space.sm}
          style={{
            border: `2px dashed ${theme.colors.Grey30} `,
            padding: theme.space.sm,
            borderRadius: theme.space.xs,
          }}
        >
          {breakLength === defaultBreaksLength &&
            numBreaks === defaultBreaksCount && (
              <Label style={{ color: theme.colors.red, fontWeight: 'bold' }}>
                Business default break included
              </Label>
            )}
          <Row
            alignCenter
            style={{ justifyContent: 'space-around' }}
            mt={theme.space.xs}
            wrap
          >
            <ShiftPostingInputContainerSection
              label="Break Type"
              input={
                <Select
                  menuItems={breakTypeOptions}
                  handleSelect={(bt) =>
                    setCreateShiftRequest({
                      ...createShiftRequest,
                      breakType: bt as BreakType,
                    })
                  }
                  value={createShiftRequest.breakType}
                  disabled={!hasBreaks}
                />
              }
            />
            <ShiftPostingInputContainerSection
              label="How Many Breaks?"
              input={
                <NumberInput
                  value={numBreaks}
                  setValue={(num) => {
                    setNumBreaks(num)
                    handleScheduledBreaks(hasBreaks, breakLength, num)
                  }}
                  step={1}
                  min={1}
                  required
                />
              }
            />
            <ShiftPostingInputContainerSection
              label="How Long?"
              input={
                <NumberInput
                  value={breakLength}
                  setValue={(len) => {
                    setBreakLength(len)
                    handleScheduledBreaks(hasBreaks, len, numBreaks)
                  }}
                  placeholder="Break Length"
                  required
                  step={5}
                  min={5}
                />
              }
            />
          </Row>
        </Col>
      )}
    </>
  )
}
