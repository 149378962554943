import styled from 'styled-components'

export const SelectContainer = styled.div`
  width: ${(props) => props.style?.width};
  color: ${(props) => props.style?.color};
  margin: ${(props) => props.style?.margin};
`

export const LabelWrapper = styled.div`
  .MuiInputLabel-root {
    top: -6px;
  }
  .MuiInputLabel-root.MuiFormLabel-filled,
  .MuiInputLabel-root.Mui-focused {
    top: 0px;
  }
`
