import { CircularProgress, IconButton } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { Trash } from 'src/assets/svg/icons/Trash'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useShift } from 'src/hooks/useShifts'
import { ShiftPostingInputContainerSection } from 'src/screens/PostShiftScreen/components/ShiftPostingInputContainer'
import { Col, Row, Select } from '../../components/base'

type InvoicingSectionProps = {
  shiftIds: string[]
  shift: Shift
}

export default function InvoicingSection(props: InvoicingSectionProps) {
  const { shift, shiftIds } = props

  const {
    removeShiftsFromInvoiceGroup,
    addShiftsToInvoiceGroup,
    activeInvoiceGroups,
  } = useInvoiceGroups(shift.companyId)
  const { refetch: refetchShift } = useShift(shift.id)

  const [groupId, setGroupId] = useState<string | undefined>(
    shift.parentInvoiceGroupId,
  )
  const [updating, setUpdating] = useState<boolean>(false)

  const handleRemoveFromGroup = async () => {
    if (!groupId) {
      return
    }
    setUpdating(true)
    await removeShiftsFromInvoiceGroup({
      groupId,
      shiftIds,
    })

    setGroupId(undefined)
    await refetchShift()
    setUpdating(false)
  }

  const handleAddToGroup = async (groupId: string) => {
    if (!groupId) {
      return
    }
    setGroupId(groupId)
    setUpdating(true)
    await addShiftsToInvoiceGroup({
      groupId,
      shiftIds,
    })

    await refetchShift()
    setUpdating(false)
  }

  function groupName(groupId?: string): string | undefined {
    if (!groupId) {
      return undefined
    }
    const group = activeInvoiceGroups.find((g) => g.id === groupId)
    return group?.name
  }

  const ExistingInvoiceGroupDisplay = () => (
    <Col>
      <Row>
        <Text variant="h6" mr={theme.space.xxs}>
          Invoice group:
        </Text>
        <Text variant="body1">{groupName(groupId)}</Text>
        <IconButton onClick={handleRemoveFromGroup} style={{ paddingTop: 0 }}>
          <Trash color={theme.colors.Red60} size={20} />
        </IconButton>
      </Row>
    </Col>
  )

  const SelectInvoiceGroupDisplay = () => (
    <Row mt={theme.space.xs}>
      <ShiftPostingInputContainerSection
        label={`Invoice Group`}
        input={
          <Row style={{ width: 300 }}>
            <Select
              menuItems={activeInvoiceGroups.map((group) => ({
                value: group.id,
                label: group.name,
              }))}
              fullWidth
              handleSelect={handleAddToGroup}
              value={groupId || ''}
            />
          </Row>
        }
      />
    </Row>
  )

  return (
    <Row flexCol justifyStart style={{ flex: 1 }} py={theme.space.xxs}>
      <Text variant="h5" mb={theme.space.xs}>
        Invoicing
      </Text>
      {updating ? (
        <CircularProgress size={20} />
      ) : (
        <>
          {groupId && <ExistingInvoiceGroupDisplay />}
          {!groupId && <SelectInvoiceGroupDisplay />}
        </>
      )}
    </Row>
  )
}
