import Tooltip from '@mui/material/Tooltip'
import { Text, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { TransitHistoryEntry, TransitStatus } from '@traba/types'
import { TimezonedDateFns } from 'src/hooks/useTimezonedDates'
import { kmToMiles } from 'src/utils/helperUtils'
import * as S from './styles'
import { sortTransitHistoryByTimestampAndTransitStatus } from './utils'

const NUM_SEGMENTS = 12
const SEGMENT_WIDTH_PERCENTAGE = 100 / NUM_SEGMENTS
const TRANSIT_STATUS_TO_COLOR = {
  [TransitStatus.NOT_LEFT]: theme.colors.Grey90,
  [TransitStatus.IN_TRANSIT]: theme.colors.Green70,
  [TransitStatus.SLOWED]: theme.colors.Green40,
  [TransitStatus.STOPPED]: theme.colors.Yellow70,
  [TransitStatus.MOVING_AWAY]: theme.colors.Red70,
  [TransitStatus.LOST_CONNECTION]: theme.colors.Grey60,
}

export function WorkerShiftTransitProgressBar({
  workerShiftTransitHistory,
  minNumSegments,
  initialDistance,
  tz,
}: {
  workerShiftTransitHistory: TransitHistoryEntry[]
  minNumSegments: number
  initialDistance: number
  tz: TimezonedDateFns
}) {
  if (workerShiftTransitHistory.length === 0) {
    return <Text>Not Left</Text>
  }

  const diffLength =
    workerShiftTransitHistory.length < minNumSegments
      ? minNumSegments - workerShiftTransitHistory.length
      : 0

  const segments = []

  for (let i = 0; i < diffLength; i++) {
    const updateTimestamp = 'None'
    const distanceTraveled = 0
    const transitStatus = TransitStatus.NOT_LEFT
    segments.push({ transitStatus, updateTimestamp, distanceTraveled })
  }

  let prevDistLeft = initialDistance

  // Sort workerShiftTransitHistory based on eventTimestamp
  workerShiftTransitHistory.sort(sortTransitHistoryByTimestampAndTransitStatus)

  for (const transitHistoryEntry of workerShiftTransitHistory) {
    const updateTimestamp = tz.getTime(transitHistoryEntry.eventTimestamp)
    const distanceTraveled = kmToMiles(
      (prevDistLeft - transitHistoryEntry.remainingDistanceMeters) / 1000,
    ).toFixed(2)
    prevDistLeft = transitHistoryEntry.remainingDistanceMeters
    const transitStatus = transitHistoryEntry.transitStatus
    segments.push({ transitStatus, updateTimestamp, distanceTraveled })
  }

  return (
    <Row fullWidth flexCol>
      <S.BarContainer>
        <S.DistributionBar>
          {segments.map((segment, index) => {
            return (
              <Tooltip
                title={
                  <>
                    {[
                      `Last dist. update: ${segment.updateTimestamp}`,
                      `Distance traveled: ${segment.distanceTraveled} Mi`,
                      `Status: ${segment.transitStatus.replace('_', ' ')}`,
                    ].map((text) => (
                      <Text variant={'body1'} color={theme.colors.White}>
                        {text}
                      </Text>
                    ))}
                  </>
                }
                placement="top"
                arrow
              >
                <S.BarSegment
                  width={SEGMENT_WIDTH_PERCENTAGE}
                  backgroundColor={
                    TRANSIT_STATUS_TO_COLOR[segment.transitStatus]
                  }
                  style={{ border: `1px solid ${theme.colors.White}` }}
                  data-tooltip-id={`transit_segment_${index}`}
                />
              </Tooltip>
            )
          })}
        </S.DistributionBar>
      </S.BarContainer>
    </Row>
  )
}
