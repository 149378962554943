import { AcceptShiftBypassSetting } from './base'

export const InitialAcceptShiftBypasses: AcceptShiftBypassSetting[] = [
  {
    eligibilityCriteria: 'shiftAllowsSignups',
    bypassKey: 'bypassSignupStatus',
    label: 'Bypass Signup Status',
  },
  {
    eligibilityCriteria: 'shiftStatusOk',
    bypassKey: 'bypassShiftStatus',
    label: 'Bypass Shift Status',
  },
  {
    eligibilityCriteria: 'workerHasAttributes',
    bypassKey: 'bypassAttributeRequirements',
    label: 'Bypass Attribute Requirement',
  },
  {
    eligibilityCriteria: 'shiftIsNotFull',
    bypassKey: 'allowOverbook',
    label: 'Bypass Overbook',
  },
  {
    eligibilityCriteria: 'workerPassesFavoritesOnly',
    bypassKey: 'bypassFavoritesOnly',
    label: 'Bypass Favorite Only',
  },
  {
    eligibilityCriteria: 'workerPassesFavoritesFirst',
    bypassKey: 'bypassFavoritesFirst',
    label: 'Bypass Favorite First',
  },
  {
    eligibilityCriteria: 'workerIsAvailable',
    bypassKey: 'bypassWorkerAvailability',
    label: 'Bypass Worker Availability',
  },
  {
    eligibilityCriteria: 'workerPassesUnprovenThreshold',
    bypassKey: 'bypassUnprovenThreshold',
    label: 'Bypass Unproven Threshold',
  },
  {
    eligibilityCriteria: 'workerPassesUnprovenTodoLimit',
    bypassKey: 'bypassUnprovenTodoLimit',
    label: 'Bypass Todo Limit',
  },
  {
    eligibilityCriteria: 'workerPassesExtraBGCRequirement',
    bypassKey: 'bypassExtraBGCRequirement',
    label: 'Bypass Extra BGC Requirement',
  },
  {
    eligibilityCriteria: 'workerPassesAcceptedTier',
    bypassKey: 'bypassMinimumAcceptedTier',
    label: 'Bypass Accepted Tier',
  },
  {
    eligibilityCriteria: 'workerPassesForwardFill',
    bypassKey: 'bypassForwardFill',
    label: 'Bypass Forward Fill Eligibility',
  },
  {
    eligibilityCriteria: 'workerPassesInvitedOnly',
    bypassKey: 'bypassInvitations',
    label: 'Bypass Invited Only',
  },
  {
    eligibilityCriteria: 'workerPassesInvitedFirst',
    bypassKey: 'bypassInvitations',
    label: 'Bypass Invited First',
  },
  {
    eligibilityCriteria: 'workerIsCertified',
    errorText:
      'Worker does not have a Certification that is Required for this Shift',
  },
  {
    eligibilityCriteria: 'workerHasNoRevokedAttributes',
    errorText: 'Worker Has a Revoked Attribute that is Required for this Shift',
  },
  {
    eligibilityCriteria: 'workerIsNotBlocked',
    errorText: 'Worker Can Not Accept Shift as Worker is Blocked',
  },
  {
    eligibilityCriteria: 'workerIsNotIneligible',
    errorText: 'Worker Can Not Accept Shift as Worker is Ineligible',
  },
  {
    eligibilityCriteria: 'companyIsApproved',
    errorText: 'Worker Can Not Accept Shift as Company is Not Approved',
  },
  {
    eligibilityCriteria: 'accountStatusOk',
    errorText: 'Worker Can Not Accept Shift as Account is not Approved',
  },
  {
    eligibilityCriteria: 'workerShiftIsNotBizCancelled',
    bypassKey: 'bypassWorkerShiftIsNotBizCancelled',
    label: 'Bypass Biz Cancellation',
  },
  {
    eligibilityCriteria: 'workerShiftIsNotOpsCancelled',
    bypassKey: 'bypassWorkerShiftIsNotOpsCancelled',
    label: 'Bypass Ops Cancellation',
  },
]
