import { Menu, MenuItem } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'src/components/base'
import { SidebarIconName } from '../../../assets/svg/icons'
import * as S from './Sidebar.styles'

type DropdownOption = {
  onClick?: (event: any) => Promise<void> | void
  label: string
  route: string
  icon?: SidebarIconName
  hidden?: boolean
}

export const NavigationDropdown = ({
  label,
  items,
  isCollapsed,
}: {
  label: string
  items: DropdownOption[]
  isCollapsed: boolean
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <S.LinkWrapper key={label} onClick={handleClick}>
        <S.Link
          className="sidebar-link"
          to=""
          onClick={(ev) => ev.preventDefault()}
        >
          <Icon name="settings" style={{ marginRight: theme.space.xxs }} />
          {isCollapsed && (
            <Text style={{ color: theme.TextButton, fontWeight: 600 }}>
              Tools
            </Text>
          )}
        </S.Link>
      </S.LinkWrapper>

      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items?.map((item) => (
          <MenuItem
            component={Link}
            to={item.route}
            onClick={handleClose}
            key={item.route}
          >
            <Text
              variant="caption"
              style={{
                fontSize: 14,
                color: location.pathname.startsWith(item.route)
                  ? theme.colors.MidnightBlue
                  : theme.TextButton,
              }}
              my={theme.space.xxs}
            >
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
