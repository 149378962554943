import { SvgIconProps } from '../SvgIconProps'

export function Edit({ size, color, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M11 6.09056C9.78797 6.49457 8.17194 4.87853 8.57595 3.6665M9.07391 3.16854L6.46269 5.77976C5.32749 6.91496 4.52215 8.33734 4.13278 9.89482L4.00661 10.3995C3.96729 10.5568 4.10974 10.6992 4.26699 10.6599L4.77168 10.5337C6.32917 10.1444 7.75155 9.33902 8.88675 8.20382L11.498 5.5926C11.8194 5.27115 12 4.83517 12 4.38057C12 3.43392 11.2326 2.6665 10.2859 2.6665C9.83134 2.6665 9.39536 2.84709 9.07391 3.16854Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.6673 13.3335H3.33398"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
