import { theme } from '@traba/theme'
import { InvoiceChargeCycle, ParentInvoiceGroup } from '@traba/types'
import { SetStateAction } from 'react'
import { Button, Col, Icon, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { ModalState } from 'src/components/base/Modal/Modal'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'

import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'

interface Props {
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  createShiftRequests: CreateShiftRequest[]
  createOrEditInvoiceGroupModal: ModalState
  activeInvoiceGroups: ParentInvoiceGroup[]
}

const invoiceOptions = [
  { value: InvoiceChargeCycle.WEEKLY, label: 'Weekly' },
  { value: InvoiceChargeCycle.GROUPED, label: 'Grouped' },
]

export const InvoiceSection = ({
  setCreateShiftRequests,
  createShiftRequests,
  createOrEditInvoiceGroupModal,
  activeInvoiceGroups,
}: Props) => {
  return (
    <ShiftPostingInputContainer title="Invoicing">
      <Col>
        <ShiftPostingInputContainerSection
          label={`Invoice Type`}
          input={
            <Row style={{ width: 300 }}>
              <Select
                menuItems={invoiceOptions}
                fullWidth
                handleSelect={(invoiceOption) => {
                  setCreateShiftRequests((prev) => {
                    return prev.map((sr) => {
                      return {
                        ...sr,
                        invoiceChargeCycle: invoiceOption as InvoiceChargeCycle,
                      }
                    })
                  })
                }}
                value={createShiftRequests[0].invoiceChargeCycle}
              />
            </Row>
          }
        />

        {createShiftRequests[0].invoiceChargeCycle ===
          InvoiceChargeCycle.GROUPED && (
          <>
            <Row mt={theme.space.xs}>
              <ShiftPostingInputContainerSection
                label={`Invoice Group`}
                input={
                  <Row style={{ width: 300 }}>
                    <Select
                      menuItems={activeInvoiceGroups.map((group) => ({
                        value: group.id,
                        label: group.name,
                      }))}
                      fullWidth
                      handleSelect={(groupId) => {
                        setCreateShiftRequests((prev) => {
                          return prev.map((sr) => {
                            return {
                              ...sr,
                              parentInvoiceGroupId: groupId,
                            }
                          })
                        })
                      }}
                      value={createShiftRequests[0].parentInvoiceGroupId || ''}
                    />
                  </Row>
                }
              />
            </Row>

            <Row>
              <Button
                leftIcon={
                  <Icon name="plus" style={{ marginRight: theme.space.xxs }} />
                }
                onClick={() => {
                  createOrEditInvoiceGroupModal.open()
                }}
                reverse
                variant={ButtonVariant.TEXT}
                iconPadding="0px"
                style={{ paddingLeft: '0px', color: theme.colors.Violet }}
              >
                Create new invoice group
              </Button>
            </Row>
          </>
        )}
      </Col>
    </ShiftPostingInputContainer>
  )
}
