import { InputStatus } from '@traba/types'
import { useState } from 'react'

import { Icon } from 'src/components/base'
import { Required } from '../Required'
import * as S from './Input.styles'
import { InputProps } from './types'

export default function Input(props: InputProps) {
  const {
    full,
    onChange,
    onKeyPress,
    placeholder,
    value,
    name,
    maxLength,
    type,
    inputStatus,
    className,
    required,
    pattern,
    disabled,
    errorMessage,
    rows,
    width,
    style,
    onBlur,
    minHeight,
  } = props
  const [isVisible, setIsVisible] = useState(props.type !== 'password')
  const isValid = typeof props.value === 'string' && props.value.length

  return (
    <>
      <S.InputContainer style={props.containerStyle}>
        {type === 'textarea' ? (
          <S.MultiLineInput
            className={`${isValid ? 'valid' : ''} ${className ?? ''}`}
            full={full}
            name={name}
            placeholder={placeholder}
            style={{
              width,
              minHeight,
              resize: 'vertical',
              ...style,
            }}
            rows={rows}
            value={value}
            maxLength={maxLength}
            onChange={onChange}
          />
        ) : (
          <S.Input
            {...props}
            name={name}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            maxLength={maxLength}
            type={type !== 'password' ? type : isVisible ? 'text' : 'password'}
            className={`${isValid ? 'valid' : ''} ${className ?? ''}`}
            pattern={pattern}
            style={{
              ...style,
              width: width,
            }}
            inputStatus={inputStatus}
            disabled={disabled}
            onBlur={onBlur}
            {...(props.type === 'password' && {
              'data-dd-privacy': 'mask-user-input',
            })}
          />
        )}
        {type === 'password' && (
          <S.IconWrapper onClick={() => setIsVisible(!isVisible)}>
            <Icon name="eyeSlash" />
          </S.IconWrapper>
        )}
        <S.Label inputStatus={inputStatus} sm={props.sm}>
          {props.label}
          <Required required={required} inputStatus={inputStatus} />
        </S.Label>
      </S.InputContainer>
      {inputStatus === InputStatus.error ? (
        <S.InputErrorMessage>
          <S.InputErrorIcon />
          {errorMessage}
        </S.InputErrorMessage>
      ) : null}
    </>
  )
}
