import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BugleStatistics } from '@traba/types'
import { Icon, Row } from '../../base'
import * as S from './styles'

function BugleStatisticsDistributionBar({
  bugleStatistics,
  showLabels,
  inProgressBugles,
  totalSent,
}: {
  bugleStatistics: BugleStatistics
  totalSent: number
  showLabels?: boolean
  inProgressBugles?: boolean
}) {
  const {
    signedForShift,
    unresponsive,
    unsubscribed,
    undelivered,
    messagesSent,
  } = bugleStatistics

  const signedForShiftPercentage = (signedForShift / messagesSent) * 100
  const unresponsivePercentage = (unresponsive / messagesSent) * 100
  const unsubscribedPercentage = (unsubscribed / messagesSent) * 100
  const undeliveredPercentage = (undelivered / messagesSent) * 100
  const deliveredThreshold =
    ((signedForShift + unresponsive + unsubscribed) / totalSent) * 100

  if (inProgressBugles) {
    return (
      <Row fullWidth fullHeight justifyCenter alignCenter flexCol>
        <Icon name="clock_active" />
        <Text variant="label" mt={theme.space.xs}>
          There is a Bugle in progress, check back in a few minutes for updated
          statistics
        </Text>
      </Row>
    )
  }

  return (
    <Row fullWidth flexCol px={theme.space.xs} justifyCenter>
      {showLabels && (
        <Row fullWidth mb={theme.space.xs}>
          <Row style={{ width: 120 }} flexCol>
            <Text color={theme.colors.Green80} variant="h6">
              Slots filled
            </Text>
            <Text color={theme.colors.Green80} variant="h4">
              {signedForShift}
            </Text>
          </Row>
          <Row style={{ width: 120 }} flexCol>
            <Text color={theme.colors.Grey60} variant="h6">
              Unresponsive
            </Text>
            <Text color={theme.colors.Grey60} variant="h4">
              {unresponsive}
            </Text>
          </Row>
          <Row style={{ width: 120 }} flexCol>
            <Text color={theme.colors.Red80} variant="h6">
              Unsubscribed
            </Text>
            <Text color={theme.colors.Red80} variant="h4">
              {unsubscribed}
            </Text>
          </Row>
          <Row style={{ width: 120 }} flexCol>
            <Text color={theme.colors.Orange70} variant="h6">
              Undelivered
            </Text>
            <Text color={theme.colors.Orange70} variant="h4">
              {undelivered}
            </Text>
          </Row>
        </Row>
      )}
      <S.BarContainer>
        <S.DistributionBar>
          <S.BarSegment
            width={signedForShiftPercentage}
            backgroundColor={theme.colors.Green80}
          />
          <S.BarSegment
            width={unresponsivePercentage}
            backgroundColor={theme.colors.Grey40}
          />
          <S.BarSegment
            width={unsubscribedPercentage}
            backgroundColor={theme.colors.Red80}
            style={{ position: 'relative' }}
          />

          <S.BarSegment
            width={undeliveredPercentage}
            backgroundColor={theme.colors.Orange70}
          />
        </S.DistributionBar>
      </S.BarContainer>
      {showLabels && (
        <>
          <S.DistributionBarMarkers>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                borderLeft: `2px dotted ${theme.colors.Grey30}`,
                borderRight: `2px dotted ${theme.colors.Grey30}`,
                height: 30,
                width: `${deliveredThreshold}%`,
              }}
            />
          </S.DistributionBarMarkers>
          <Row
            alignCenter
            style={{
              justifyContent: 'space-around',
              width: `${deliveredThreshold}%`,
              position: 'relative',
              bottom: 14,
            }}
          >
            <S.ArrowLeft />
            <S.DottedLine />
            <Text style={{ textAlign: 'center' }}>
              Delivered {signedForShift + unresponsive + unsubscribed}
            </Text>
            <S.DottedLine />
            <S.ArrowRight />
          </Row>
        </>
      )}
    </Row>
  )
}

export default BugleStatisticsDistributionBar
