import { Tooltip } from '@mui/material'
import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { IconName, WatchlistRequirementStatus } from '@traba/types'
import { Row } from 'src/components/base'

type WorkerRequirementStatusIndicatorProps = {
  requirementStatus: WatchlistRequirementStatus
  tooltipText?: string
}

export function WorkerRequirementStatusIndicator(
  props: WorkerRequirementStatusIndicatorProps,
) {
  const { requirementStatus, tooltipText } = props

  const statusConfig = {
    DONE: {
      iconName: 'filledCheck' as IconName,
      color: theme.colors.Green80,
      text: 'Done',
    },
    NOT_DONE: {
      iconName: 'cancelFilled' as IconName,
      color: theme.colors.Red60,
      text: 'Not Done',
    },
    ACTION: {
      iconName: 'notificationFilled' as IconName,
      color: theme.colors.Yellow60,
      text: 'Pushed',
    },
  }

  const { iconName, color, text } =
    statusConfig[requirementStatus] || statusConfig['NOT_DONE']

  return (
    <Row style={{ gap: theme.space.xxs }}>
      <SvgIcon name={iconName} size={24} color={color} />
      {tooltipText ? (
        <Tooltip arrow title={tooltipText || 'N/A'}>
          <Text variant="h6">{text}</Text>
        </Tooltip>
      ) : (
        <Text variant="h6">{text}</Text>
      )}
    </Row>
  )
}
