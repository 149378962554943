export default function Cancel({
  color = '#7A8A99',
  size = 10,
  strokeWidth = 1.5,
  ...props
}: {
  size: number
  color: string
  strokeWidth: number
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 10 10`}
      fill="none"
      {...props}
    >
      <path
        d="M0.757289 9.24264L9.24257 0.757359M0.757289 0.757359L9.24257 9.24264"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
