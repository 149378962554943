import { TextField } from '@mui/material'
import { theme } from '@traba/theme'
import { Button } from 'src/components/base'
import styled from 'styled-components'

export const MediumSizedButton = styled(Button)`
  width: 200px;
`
export const ShowMoreToggle = styled(Button)`
  color: ${theme.colors.Violet};
`

export const FullWidthTextField = styled(TextField)`
  width: 100%;
`
