import { PayoutStatus, TransferStatus } from '@traba/types'
import { Badge } from './base'

export interface TransferStatusBadgeProps {
  transferStatus: TransferStatus | PayoutStatus
}

export default function TransferStatusBadge(props: TransferStatusBadgeProps) {
  const { transferStatus } = props

  const transferStatusToBadgeTitle = {
    succeeded: 'succeeded',
    paid: 'paid',
    pending: 'pending',
    failed: 'failed',
    reversed: 'reversed',
    canceled: 'canceled',
    in_transit: 'in transit',
  }

  const transferStatusToBadgeColor = {
    succeeded: 'info',
    paid: 'success',
    pending: 'warning',
    failed: 'danger',
    reversed: 'opaqueRed',
    canceled: 'opaqueRed',
    in_transit: 'warning',
  }

  const badgeTitle = transferStatusToBadgeTitle[transferStatus]
  const badgeColor = transferStatusToBadgeColor[transferStatus]

  return <Badge title={badgeTitle} variant={badgeColor} />
}
