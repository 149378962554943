import { SvgIconProps } from '../SvgIconProps'

export function EllipsesCircle({
  size,
  color = '#7A8A99',
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.23525 5.96695C2.66958 4.11534 4.11534 2.66958 5.96696 2.23525C7.30417 1.92158 8.69583 1.92158 10.033 2.23525C11.8847 2.66958 13.3304 4.11534 13.7647 5.96696C14.0784 7.30417 14.0784 8.69583 13.7647 10.033C13.3304 11.8847 11.8847 13.3304 10.033 13.7647C8.69583 14.0784 7.30417 14.0784 5.96696 13.7647C4.11534 13.3304 2.66958 11.8847 2.23525 10.033C1.92158 8.69583 1.92158 7.30417 2.23525 5.96695Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.03665 8.00016C6.03665 8.36835 5.72999 8.66683 5.3517 8.66683C4.97341 8.66683 4.66675 8.36835 4.66675 8.00016C4.66675 7.63197 4.97341 7.3335 5.3517 7.3335C5.72999 7.3335 6.03665 7.63197 6.03665 8.00016Z"
        stroke={color}
      />
      <path
        d="M8.68503 8.00016C8.68503 8.36835 8.37837 8.66683 8.00008 8.66683C7.62179 8.66683 7.31513 8.36835 7.31513 8.00016C7.31513 7.63197 7.62179 7.3335 8.00008 7.3335C8.37837 7.3335 8.68503 7.63197 8.68503 8.00016Z"
        stroke={color}
      />
      <path
        d="M11.3334 8.00016C11.3334 8.36835 11.0268 8.66683 10.6485 8.66683C10.2702 8.66683 9.96352 8.36835 9.96352 8.00016C9.96352 7.63197 10.2702 7.3335 10.6485 7.3335C11.0268 7.3335 11.3334 7.63197 11.3334 8.00016Z"
        stroke={color}
      />
    </svg>
  )
}
