import { GridColDef } from '@mui/x-data-grid'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Anchor, Row, Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { PaidBackup, SentinelNotification } from '@traba/types'
import { useMemo } from 'react'
import { WorkerNameWithIcons } from 'src/components/WorkerNameWithIcons/WorkerNameWithIcons'
import PaidBackupTableJobStatusCell from 'src/components/WorkerTable/components/PaidBackupTableJobStatusCell'
import PaidBackupConfirmationIcon from 'src/screens/ShiftDetailsScreen/components/PaidBackupsTable/PaidBackupConfirmationIcon'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { CopyTextIcon, Link } from '../../base'
import WorkerReliabilityScore from '../../WorkerTable/components/WorkerReliabilityScore'
import { SentinelTableDataGridStyle } from './styles'

const makePaidBackupDetailsColumns = (notification: SentinelNotification) => {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      width: 150,
      minWidth: 150,
      renderCell: (params) => (
        <Row alignCenter>
          <Link
            to={`/workers/${params.row.id}`}
            target="_blank"
            style={{ alignSelf: 'center', width: 100 }}
          >
            <Text
              variant="body1"
              style={{
                textAlign: 'left',
                width: 100,
              }}
            >
              <WorkerNameWithIcons
                name={params.row.workerName}
                maxLength={10}
                isFirstShiftWithCompany={
                  params.row.isFirstTimeWorkerShiftWithCompany
                }
              />
            </Text>
          </Link>
          {params.row.workerName && (
            <CopyTextIcon textToCopy={params.row.workerName} />
          )}
        </Row>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        return (
          <PaidBackupTableJobStatusCell
            paidBackup={params.value}
            timezone={notification.data.shift.timezone}
          />
        )
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      width: 130,
      minWidth: 130,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(truncateString(params.value, 8))}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'reliability',
      headerName: 'Rel',
      width: 80,
      renderCell: (params) => (
        <WorkerReliabilityScore workerId={params.row.workerId} />
      ),
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      width: 50,
      renderCell: (params) => (
        <>
          {truncateString(params.value, 4)}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'confirmation',
      headerName: 'Conf',
      width: 80,
      renderCell: (params) => (
        <PaidBackupConfirmationIcon
          paidBackup={params.value}
          timezone={notification.data.shift.timezone}
        />
      ),
    },
  ]

  return columns
}

const makePaidBackupDetailsRow = (paidBackup: PaidBackup) => {
  return {
    id: paidBackup.id,
    workerId: paidBackup.workerId,
    workerName: `${paidBackup.worker?.firstName} ${paidBackup.worker?.lastName}`,
    phoneNumber: paidBackup.worker?.phoneNumber,
    confirmation: paidBackup,
    status: paidBackup,
  }
}

export const SentinelPaidBackupDetailsTable = ({
  paidBackups,
  handleSelectPaidBackups,
  notification,
  slim,
}: {
  paidBackups: PaidBackup[]
  handleSelectPaidBackups: (paidBackupsSelected: GridRowSelectionModel) => void
  notification: SentinelNotification
  slim?: boolean
}) => {
  const columns = useMemo(() => {
    return makePaidBackupDetailsColumns(notification)
  }, [notification])
  const rows = useMemo(() => {
    return paidBackups.map((paidBackup) => {
      return makePaidBackupDetailsRow({ ...paidBackup })
    })
  }, [paidBackups])

  return (
    <Row
      style={{
        height: slim ? 'auto' : 300,
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DataGrid
        {...SentinelTableDataGridStyle}
        rows={rows}
        columns={columns}
        localeText={{
          footerRowSelected: (count) =>
            `${count} paid backup${makePlural(count)} selected`,
        }}
        onRowSelectionModelChange={handleSelectPaidBackups}
      />
    </Row>
  )
}
