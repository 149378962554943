import { css } from 'styled-components'
import { TrabaTheme } from './theme'

/**
 * Some Base CSS Mixins that can be re-used across the codebase.
 * Returns a piece of css - "Tagged Template Literal".
 *
 * Apply these two functions for styling based on device width breakpoints.
 */
export type BreakpointProps = {
  /** Show if device is: */
  xs?: number /* <= sm */
  sm?: number /* >= sm */
  md?: number /* >= md */
  lg?: number /* >= lg */
  xl?: number /* >= xl */
}

/*
 * Pass [size]={ratio} where ratio is the width out of 12 for that sized screen and up.
 * For example, md={3}: For medium screens and up, column has width 3/12 (25%). For screens below that default to equal width.
 */
export function styleColOnBreakpoints({
  xs,
  sm,
  md,
  lg,
  xl,
  theme,
}: BreakpointProps & { theme: TrabaTheme }) {
  return css`
    flex: 1 1 0%;
    @media only screen and (max-width: ${theme.media.minSmall}) {
      ${xs
        ? `width: ${(100 * xs) / 12}%; flex: 0 0 auto; display: flex !important`
        : ``};
    }
    @media only screen and (min-width: ${theme.media.minSmall}) {
      ${sm
        ? `width: ${(100 * sm) / 12}%; flex: 0 0 auto; display: flex !important`
        : ``};
    }
    @media only screen and (min-width: ${theme.media.minMedium}) {
      ${md
        ? `width: ${(100 * md) / 12}%; flex: 0 0 auto; display: flex !important`
        : ``};
    }
    @media only screen and (min-width: ${theme.media.minLarge}) {
      ${lg
        ? `width: ${(100 * lg) / 12}%; flex: 0 0 auto; display: flex !important`
        : ``};
    }
    @media only screen and (min-width: ${theme.media.minExtraLarge}) {
      ${xl
        ? `width: ${(100 * xl) / 12}%; flex: 0 0 auto; display: flex !important`
        : ``};
    }
  `
}

/**
 * [size]={0} Will hide a component for that breakpoint.
 * For example:
 * xs={0}: Hide up until the small breakpoint.
 * sm={0}: Hide starting from the small breakpoint.
 * md={0}: Hide starting from the medium breakpoint.
 */
export function hideOnBreakpoints({
  xs,
  sm,
  md,
  lg,
  xl,
  theme,
}: BreakpointProps & { theme: TrabaTheme }) {
  return css`
    ${xs === 0 ? `display: none !important` : ``};
    @media only screen and (min-width: ${theme.media.minSmall}) {
      ${sm === 0 ? `display: none !important` : ``};
    }
    @media only screen and (min-width: ${theme.media.minMedium}) {
      ${md === 0 ? `display: none !important` : ``};
    }
    @media only screen and (min-width: ${theme.media.minLarge}) {
      ${lg === 0 ? `display: none !important` : ``};
    }
    @media only screen and (min-width: ${theme.media.minExtraLarge}) {
      ${xl === 0 ? `display: none !important` : ``};
    }
  `
}
