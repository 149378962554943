import { Row } from 'src/components/base'
import styled from 'styled-components'

export const SummaryLine = styled(Row)`
  border-bottom: 1px solid #66788a;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
`
