import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import {
  ArchiveMemberParams,
  ArchiveUserModalContent,
  LoadingSpinner,
} from '@traba/react-components'
import { User } from '@traba/types'
import { getStartOfRecentFiveMinBlock } from '@traba/utils'
import { useState } from 'react'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyShiftsLocationsForSupervisor } from 'src/hooks/useCompanyShiftsLocationsForSupervisor'
import {
  useCompanyMemberIdToMemberMap,
  useCompanyUserMutations,
  useCompanyUsers,
} from 'src/hooks/useCompanyUsers'

export type UserEditModalProps = {
  user: User
  handleClose: () => void
  isOpen: boolean
}

export function ArchiveUserModal({
  user,
  handleClose,
  isOpen,
}: UserEditModalProps) {
  const { hotSettings } = useHotSettings()
  // using the start of the 5min block to take advantage of cached query with 5min stale-time
  const startOfLastFiveMinBlock = getStartOfRecentFiveMinBlock(
    new Date(),
  ).toISOString()

  const { isLoading: isShiftLocationsLoading, locations } =
    useCompanyShiftsLocationsForSupervisor({
      companyId: user.companyId,
      supervisorId: user.uid,
      minShiftStartTime: startOfLastFiveMinBlock,
    })
  const { activeCompanyUsers, isLoading: isCompanyUsersLoading } =
    useCompanyUsers(user.companyId)
  const { memberIdToMemberMap, isLoading: isLoadingMemberIdToMemberMap } =
    useCompanyMemberIdToMemberMap(user.companyId)
  const [activeShiftsError, setActiveShiftsError] = useState(false)
  const { archiveCompanyMember, isArchiveCompanyMemberRoleLoading } =
    useCompanyUserMutations(user.companyId || '')
  const { showError } = useAlert()

  const handleArchiveMember = useCallback(
    ({
      replacementUserId,
      replacementSupervisorsForLocations,
    }: ArchiveMemberParams) => {
      archiveCompanyMember(
        {
          uid: user.uid,
          replacementUserId,
          replacementSupervisorsForLocations,
        },
        {
          onSuccess: () => {
            window.analytics.track(`User Archived Member`, {
              member: user,
            })
            handleClose()
          },
          onError: (err) => {
            if (err.message === 'archive/active-shifts') {
              setActiveShiftsError(true)
              return
            } else {
              showError(
                'An error has occurred. Please try again or contact support if the issue persists.',
                `Unable to archive user`,
              )
            }
          },
        },
      )
    },
    [user, archiveCompanyMember, handleClose, showError],
  )

  const isMembersOrShiftsLoading =
    isShiftLocationsLoading ||
    isCompanyUsersLoading ||
    isLoadingMemberIdToMemberMap

  return (
    <Modal size={MODAL_SIZE.MEDIUM} isOpen={isOpen} handleClose={handleClose}>
      {!isMembersOrShiftsLoading && activeCompanyUsers ? (
        <ArchiveUserModalContent
          member={user}
          handleClose={handleClose}
          onArchiveMember={handleArchiveMember}
          loading={isArchiveCompanyMemberRoleLoading}
          replacementMembers={activeCompanyUsers}
          activeShiftsError={activeShiftsError}
          locationsForFutureShifts={locations}
          showLocationSpecifiedSupervisorDropdown={
            hotSettings?.enableRegionalAccessPhase2
          }
          memberIdToMemberMap={memberIdToMemberMap}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Modal>
  )
}
