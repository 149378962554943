import { WorkerJobPlacementAction } from '@traba/types'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerJobPlacementContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'

interface CompanyJobPlacementTerminateModalProps {
  companyId: string
  workerId: string
  handleClose: () => void
  isOpen: boolean
  workerName: string
}

export const CompanyJobPlacementTerminateModal = (
  props: CompanyJobPlacementTerminateModalProps,
) => {
  const { isOpen, handleClose, companyId, workerId, workerName } = props

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={`Terminate ${workerName}`}
    >
      <Row flexCol fullHeight fullWidth className="modalWrapper">
        {
          <WorkerJobPlacementContent
            companyId={companyId}
            handleClose={handleClose}
            workerId={workerId}
            action={WorkerJobPlacementAction.TERMINATE_WORKER}
          />
        }
      </Row>
    </Modal>
  )
}
