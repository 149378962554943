import { InvoiceStatus } from '@traba/types'
import { Badge } from 'src/components/base'

type InvoiceStatusBadgeProps = {
  status: InvoiceStatus
}

function getVariant(status: InvoiceStatus): string {
  switch (status) {
    case InvoiceStatus.DRAFT:
      return 'orange'
    case InvoiceStatus.OPEN:
      return 'warning'
    case InvoiceStatus.PAID:
      return 'success'
    default:
      return 'info'
  }
}

export default function InvoiceStatusBadge(props: InvoiceStatusBadgeProps) {
  return (
    <Badge
      title={props.status}
      variant={getVariant(props.status)}
      style={{ height: 'fit-content' }}
    />
  )
}
