import * as Sentry from '@sentry/react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { CompanyInvitation, UserAccessLevel, UserRole } from '@traba/types'
import { AxiosError } from 'axios'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

const COMPANY_INVITATIONS_QUERY_KEY = 'companyInvitations'

async function getInvitations(
  companyId: string,
): Promise<CompanyInvitation[] | undefined> {
  if (!companyId) {
    return []
  }
  try {
    // Retrieve unaccepted invitations
    const res = await trabaApi.get(`companies/${companyId}/invitations`)
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useInvitations ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
  }
}

export async function generateInvitationLink(
  companyId: string,
  invitationId: string,
): Promise<string | undefined> {
  try {
    if (!companyId || !invitationId) {
      return
    }
    const res = await trabaApi.get(
      `companies/${companyId}/invitations/${invitationId}/generate-link`,
    )
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `generateInvitationLink ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
  }
}

export interface CreateInvitationParams {
  companyId: string
  email: string
  role: UserRole
  userAccessLevel?: UserAccessLevel
  locationIds?: string[]
}

export async function createInvitation({
  companyId,
  ...queryParams
}: CreateInvitationParams): Promise<CompanyInvitation> {
  const res = await trabaApi.post(`companies/${companyId}/invitations`, {
    ...queryParams,
  })
  return res.data
}

export function useCompanyInvitations(companyId: string) {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()
  const {
    isLoading,
    isError,
    data: invitations,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyInvitation[] | undefined, Error>({
    queryKey: [COMPANY_INVITATIONS_QUERY_KEY, companyId],
    queryFn: () => getInvitations(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const createInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    CreateInvitationParams
  >({
    mutationFn: createInvitation,
    onSuccess: (response) => {
      queryClient.setQueryData(
        [COMPANY_INVITATIONS_QUERY_KEY, companyId],
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return [response, ...invitations]
          }
          return [response]
        },
      )
    },
    onError: (error, req) => {
      const message =
        error?.message === 'invitation/outstanding-or-accepted'
          ? `An invitation has already been sent to or accepted by ${req.email}.`
          : 'There was an error sending your invitation. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'useCompanyInvitations -> createInvitation',
        message,
        'Error sending invitation',
      )
    },
  })

  const resendInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    { companyId: string; invitationId: string }
  >({
    mutationFn: async ({ companyId, invitationId }) => {
      const response = await trabaApi.patch(
        `companies/${companyId}/invitations/${invitationId}/resend`,
      )
      return response.data
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [COMPANY_INVITATIONS_QUERY_KEY, companyId],
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return invitations.map((i) =>
              i.invitationId === response.invitationId ? response : i,
            )
          }
          return [response]
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useCompanyInvitations -> resendInvitation',
        'There was an error resending the invitation. Please try again or contact support if the issue persists.',
        'Error resending invitation',
      )
    },
  })

  const rescindInvitationMutation = useMutation<
    CompanyInvitation,
    AxiosError,
    { companyId: string; invitationId: string }
  >({
    mutationFn: async ({ companyId, invitationId }) => {
      const response = await trabaApi.patch(
        `companies/${companyId}/invitations/${invitationId}/rescind`,
      )
      return response.data
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [COMPANY_INVITATIONS_QUERY_KEY, companyId],
        (invitations: CompanyInvitation[] | undefined) => {
          if (invitations) {
            return invitations.map((i) =>
              i.invitationId === response.invitationId ? response : i,
            )
          }
          return [response]
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useCompanyInvitations -> rescindInvitation',
        'There was an error revoking the invitation. Please try again or contact support if the issue persists.',
        'Error revoking invitation',
      )
    },
  })

  return {
    isLoading,
    isError,
    invitations,
    createInvitationMutation: createInvitationMutation.mutateAsync,
    resendInvitationMutation: resendInvitationMutation.mutateAsync,
    rescindInvitationMutation: rescindInvitationMutation.mutateAsync,
    error,
    isFetched,
    refetch,
  }
}
