import { useAlert } from '@traba/context'
import { useShift } from 'src/hooks/useShifts'
import { useWorkerShift } from 'src/hooks/useWorkerShifts'
import AdjustmentModal from './AdjustmentModal'

type WorkerShiftAdjustmentFromEarningSummaryWrapperProps = {
  handleClose: () => void
  isOpen: boolean
  workerId: string
  shiftId: string
}

export const WorkerShiftAdjustmentFromEarningSummaryWrapper = ({
  handleClose,
  isOpen,
  workerId,
  shiftId,
}: WorkerShiftAdjustmentFromEarningSummaryWrapperProps) => {
  const {
    workerShift,
    isLoading: isLoadingWorkerShift,
    error: workerShiftError,
  } = useWorkerShift({
    workerId,
    shiftId,
    includeWorker: true,
    includeAccountStatus: true,
  })
  const {
    shift,
    isLoading: isLoadingShift,
    error: shiftError,
  } = useShift(shiftId)

  const { handleError } = useAlert()

  if (workerShiftError || shiftError) {
    handleError(
      workerShiftError ?? shiftError,
      'WorkerShiftAdjustmentFromEarningSummaryWrapper',
      'Please try again',
      'Error fetching worker shift adjustment data',
    )
    handleClose()
  }

  return (
    <AdjustmentModal
      isOpen={isOpen}
      handleClose={handleClose}
      workerShift={workerShift}
      shift={shift}
      isLoading={isLoadingWorkerShift || isLoadingShift}
    />
  )
}
