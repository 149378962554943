import { Shift, ShiftStatus } from '@traba/types'
import { isAfter } from 'date-fns'
import { useMemo, useState } from 'react'

export const useShowCompletedShifts = (shifts: Shift[]) => {
  const [showCompleteShifts, setShowCompleteShifts] = useState(false)
  const [showCancelledShifts, setShowCancelledShifts] = useState(false)

  /** Filter only shifts that are in the future and not canceled */
  const validShifts = useMemo(() => {
    const statuses = [ShiftStatus.ACTIVE]
    if (showCancelledShifts) {
      statuses.push(ShiftStatus.CANCELED)
    }
    if (showCompleteShifts) {
      statuses.push(ShiftStatus.COMPLETE)
    }

    return shifts?.filter(
      (shift, index, self) =>
        statuses.includes(shift.status) &&
        (showCompleteShifts
          ? true
          : isAfter(new Date(shift.endTime), new Date())) &&
        index === self.findIndex((s) => s.shiftId === shift.shiftId),
    )
  }, [shifts, showCancelledShifts, showCompleteShifts])

  return {
    validShifts,
    showCompleteShifts,
    setShowCompleteShifts,
    showCancelledShifts,
    setShowCancelledShifts,
  }
}
