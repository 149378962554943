import { Tooltip } from '@mui/material'
import { LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useMemo, useState } from 'react'
import { Player } from 'src/components/AudioPlayer/AudioPlayer'
import { Collapsible, CollapsibleIcon } from 'src/components/base'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import {
  TranscriptMessage,
  useElevenLabsConversationalAI,
  WorkerVettingPhoneCallResponse,
} from 'src/hooks/useWorkerVetting'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'

interface WorkerVettingPhoneCallItemProps {
  phoneCall: WorkerVettingPhoneCallResponse
}

export const WorkerVettingPhoneCallItem = ({
  phoneCall,
}: WorkerVettingPhoneCallItemProps) => {
  const [isTranscriptCollapsed, setIsTranscriptCollapsed] = useState(true)
  const transcript: TranscriptMessage[] = phoneCall.transcript || []

  const {
    data: audio,
    isLoading: isLoadingAudio,
    isError,
  } = useElevenLabsConversationalAI({
    conversationId: phoneCall.conversationId,
  })

  const mediaUrl = useMemo(() => {
    if (!audio) {
      return ''
    }
    const parsedData = JSON.parse(new TextDecoder().decode(audio))
    if (!parsedData?.data) {
      return ''
    }
    const audioBlob = new Blob([new Uint8Array(parsedData.data)], {
      type: 'audio/mpeg',
    })
    return URL.createObjectURL(audioBlob)
  }, [audio])

  useEffect(() => {
    return () => {
      if (mediaUrl) {
        URL.revokeObjectURL(mediaUrl)
      }
    }
  }, [mediaUrl])

  const { getDateTime } = useTimezonedDates()

  return (
    <Row
      style={{
        flexDirection: 'column',
        marginBottom: theme.space.xs,
      }}
    >
      <Row style={{ marginBottom: theme.space.xxs }} justifyEnd>
        {isLoadingAudio ? (
          <LoadingSpinner style={{ height: '24px', width: '24px' }} />
        ) : isError ? (
          <Text variant="body1">Error loading phone call audio</Text>
        ) : (
          <Player mediaUrl={mediaUrl} />
        )}
      </Row>
      <Row gap={theme.space.xs} alignCenter justifyEnd mr={theme.space.sm}>
        <CollapsibleIcon
          collapsed={isTranscriptCollapsed}
          setCollapsed={setIsTranscriptCollapsed}
          size={16}
        />
        <Text
          onClick={() =>
            setIsTranscriptCollapsed((isCollapsed) => !isCollapsed)
          }
          style={{
            cursor: 'pointer',
          }}
          variant="body2"
        >
          Transcript
        </Text>
      </Row>
      <Collapsible collapsed={isTranscriptCollapsed}>
        {transcript ? (
          <Row
            style={{
              marginLeft: theme.space.xxs,
              flexDirection: 'column',
              backgroundColor: theme.colors.Grey10,
            }}
          >
            {transcript.map((message: TranscriptMessage, index: number) => (
              <Row
                key={`transcript_item_${index}_${phoneCall.id}`}
                style={{
                  backgroundColor:
                    message.role === 'agent'
                      ? theme.colors.Grey20
                      : 'transparent',
                  padding: theme.space.xs,
                  borderRadius: '4px',
                  flexDirection: 'column',
                }}
              >
                <Text variant="body2">
                  {message.role === 'agent' ? 'Agent' : 'Worker'} •{' '}
                  {message.time_in_call_secs}s
                </Text>
                <Text>{message.message}</Text>
              </Row>
            ))}
          </Row>
        ) : (
          <Text>No transcript available</Text>
        )}
      </Collapsible>
      <Row justifyEnd>
        <Tooltip
          title={getDateTime(new Date(phoneCall.createdAt))}
          arrow
          placement="top"
        >
          <Text
            variant="body2"
            style={{
              marginTop: theme.space.xxs,
              color: theme.colors.Grey60,
              cursor: 'default',
            }}
          >
            {formatTimeDistanceToNow(phoneCall.createdAt)}
          </Text>
        </Tooltip>
      </Row>
    </Row>
  )
}
