import { theme } from '@traba/theme'
import styled from 'styled-components'

interface ResultsWrapperProps {
  openInline?: boolean
}

export const ResultsWrapper = styled.div<ResultsWrapperProps>`
  position: ${(props) => (props.openInline ? 'relative' : 'absolute')};
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
`

interface SearchResultsContainerProps {
  openInline?: boolean
}

export const SearchResultsContainer = styled.div<SearchResultsContainerProps>`
  background-color: #ffffff;
  margin-top: ${({ theme }) => theme.space.xs}px;
  padding: ${(props) => (props.openInline ? '0' : `${theme.space.xxs}px`)};
  padding-top: ${(props) =>
    props.openInline ? `${theme.space.xxs}px` : 'auto'};
  width: ${(props) => (props.openInline ? '100%' : 'auto')};
  min-width: ${(props) => (props.openInline ? 'auto' : '1024px')};
  max-width: ${(props) => (props.openInline ? 'auto' : '1200px')};
  max-height: 600px;
  border: ${(props) =>
    props.openInline
      ? `2px solid ${theme.colors.Grey20}`
      : '1px solid #cccccc'};
  box-shadow: ${(props) =>
    props.openInline ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};
  border-radius: ${({ theme }) => theme.border.radius};
  z-index: 10;
`

interface SearchEmptyWrapperProps {
  openInline?: boolean
}

export const SearchEmptyWrapper = styled.div<SearchEmptyWrapperProps>`
  position: ${(props) => (props.openInline ? 'relative' : 'absolute')};
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  background-color: #ffffff;
  box-shadow: ${(props) =>
    props.openInline ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};
  margin-top: ${({ theme }) => theme.space.xs}px;
  min-width: ${(props) => (props.openInline ? 'auto' : '400px')};
  width: ${(props) => (props.openInline ? '200px' : 'auto')};
  height: 40px;
  z-index: 10;

  border-radius: ${({ theme }) => theme.border.radius};
  padding: ${({ theme }) => theme.space.xxs}px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const WorkerItemCard = styled.div`
  width: 100%;
  max-width: 200px;
  padding-left: ${({ theme }) => theme.space.xs}px;
  font-weight: 500;
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CounterWrapper = styled.div`
  width: 30px;
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 40px;

  &:hover {
    transform: scale(1.3);
  }
`

interface WorkerCardContainerProps {
  openInline?: boolean
}

export const WorkerCardContainer = styled.div<WorkerCardContainerProps>`
  width: 300px;
  height: 580px;
  padding: 0 ${theme.space.xs}px;
  background-color: #fff;
  border-radius: ${theme.border.radius};
  border: ${(props) =>
    props.openInline
      ? `2px solid ${theme.colors.Grey20}`
      : '2px solid #cccccc'};
  box-shadow: ${(props) =>
    props.openInline ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};
  z-index: 10;
  margin-top: ${({ theme }) => theme.space.xs}px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
`
