import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'

export const DotText = () => {
  return (
    <Text
      variant="caption"
      style={{ margin: `0px ${theme.space.xxxs}px 0px ${theme.space.xxxs}px` }}
    >
      ●
    </Text>
  )
}
