import { AriaButtonProps } from '@react-types/button'
import { theme } from '@traba/theme'
import { useRef } from 'react'
import { useButton } from 'react-aria'
import styled, { css } from 'styled-components'

export const StyledCalendarButton = styled.button<{
  isDisabled?: boolean
}>`
  display: flex;
  border-radius: 5px;
  outline: none;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  width: 24px;
  height: 28px;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: ${theme.colors.Grey10};
          cursor: not-allowed;
        `
      : css`
          background-color: ${theme.colors.Grey20};
          border-color: ${theme.colors.Grey};
          &:hover {
            background-color: ${theme.colors.Violet40};
          }
          &:active {
            background-color: ${theme.colors.Violet20};
          }
        `};
`

const StyledFieldButton = styled.button<{ isPressed: boolean }>`
  border-radius: 5px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${theme.colors.Grey20};
  padding: ${theme.space.xxxs}px;
  margin-right: ${theme.space.xxxs}px;

  &:hover {
    background-color: ${theme.colors.Grey30};
  }
  .group:hover & {
    border-color: ${theme.colors.Yellow10};
  }
  .group:focus-within & {
    border-color: ${theme.colors.Violet70};
  }
`

export function FieldButton(props: AriaButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <StyledFieldButton {...buttonProps} ref={ref} isPressed={false}>
      {props.children}
    </StyledFieldButton>
  )
}

export function CalendarButton(props: AriaButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <StyledCalendarButton
      {...buttonProps}
      ref={ref}
      isDisabled={props.isDisabled}
    >
      {props.children}
    </StyledCalendarButton>
  )
}
