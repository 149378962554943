import { Menu, MenuProps } from '@mui/material'

import * as React from 'react'
import { assetNameType } from 'src/assets/svg/icons'
import Icon from '../Icon'

/**
 * Renders an icon that is linked to a menu
 * @param props
 * @returns
 */
export const IconPositionedMenu = (
  props: { iconName?: assetNameType; id: string } & Partial<MenuProps>,
) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { iconName, id, ...rest } = props
  const triggerId = 'trigger' + id
  const menuListId = 'positioned-menu' + id
  return (
    <div>
      <div
        id={triggerId}
        aria-controls={open ? menuListId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        <Icon name={iconName || 'more'} type="svg" />
      </div>

      <Menu
        id={menuListId}
        aria-labelledby={triggerId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...rest}
      >
        {props.children}
      </Menu>
    </div>
  )
}
