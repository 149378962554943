import { WorkerShiftCostCentersModal } from '@traba/react-components'
import {
  CostCenterForShiftType,
  WorkerShiftForOps as WorkerShift,
  WorkerWithSegments,
} from '@traba/types'
import { JobStatus } from '@traba/types'
import React from 'react'
import { UserRolePermission } from 'src/context/user/types'
import { useWorkersWithSegmentsMutation } from 'src/hooks/useCostCenters'
import { usePermissions } from 'src/hooks/usePermissions'
import { ConfirmShiftModal, FraudulentModal } from 'src/modals'
import AdjustBreakModal from 'src/modals/AdjustBreakModal/AdjustBreakModal'
import MakeRobocallModal from 'src/modals/MakeRobocallModal/MakeRobocallModal'
import { ManageWorkerModal } from 'src/modals/ManageWorkerModal/ManageWorkerModal'
import { openInNewTab } from 'src/utils/helperUtils'
import { ButtonVariant } from '../base/Button/types'
import { Dropdown, DropdownElement } from '../base/Dropdown'
import { useModal } from '../base/Modal/Modal'

type WorkerActionProps = {
  costCenters?: CostCenterForShiftType[]
  workerShift: WorkerShift
  workerWithSegments?: WorkerWithSegments
  openClockInModal: (workerShift: WorkerShift) => void
  openClockOutModal: (workerShift: WorkerShift) => void
  openAdjustmentModal: (workerShift: WorkerShift) => void
}

export function WorkerAction(props: WorkerActionProps): React.JSX.Element {
  const {
    costCenters,
    workerShift,
    workerWithSegments,
    openClockInModal,
    openClockOutModal,
    openAdjustmentModal,
  } = props

  const confirmShiftModal = useModal()
  const manageWorkerModal = useModal()
  const adjustBreakModal = useModal()
  const makeRobocallModal = useModal()
  const fraudulentModal = useModal()
  const editCostCenterSegmentsModal = useModal()
  const { updateWorkerShiftSegments, isUpdating } =
    useWorkersWithSegmentsMutation(
      workerShift.shiftInfo.companyId,
      workerShift.shiftId,
      workerShift.workerId,
    )

  const stripeAccess = usePermissions([UserRolePermission.StripeAccess])
  const flagShiftAccess = usePermissions([UserRolePermission.FlagShift])

  const workerActions: DropdownElement[] = [
    { label: 'Confirm Shift', handleClick: () => confirmShiftModal.open() },
    {
      label: 'Clock Worker In',
      handleClick: () => openClockInModal(workerShift),
    },
    {
      label: 'Clock Worker Out',
      handleClick: () => openClockOutModal(workerShift),
    },
    { label: 'Manage Worker', handleClick: () => manageWorkerModal.open() },
    {
      label: 'Adjust Shift',
      handleClick: () => openAdjustmentModal(workerShift),
    },
    {
      label: 'Open Stripe',
      handleClick: () =>
        openInNewTab(
          `${
            import.meta.env.VITE_STRIPE_URL ||
            'https://dashboard.stripe.com/test'
          }/connect/accounts/${workerShift.worker?.stripeAccountId}/activity`,
        ),
      disabled: !stripeAccess,
    },
    {
      label: 'Retool Worker Profile',
      handleClick: () =>
        openInNewTab(
          `https://traba.retool.com/apps/5b7d54ea-b120-11ec-a560-a355ea6c44fd/Profile%20Information?=#workerUid=${workerShift.workerId}`,
        ),
    },
    {
      label: 'Robocalls',
      handleClick: () => makeRobocallModal.open(),
    },
    {
      label: 'Set Flag On Shift',
      handleClick: () => fraudulentModal.open(),
      disabled: !flagShiftAccess,
    },
  ]

  if (costCenters?.length) {
    const isWorkerNotClockedIn = !workerShift.clockInTime
    workerActions.push({
      label: 'Edit Cost Centers',
      handleClick: () => editCostCenterSegmentsModal.open(),
      disabled: isWorkerNotClockedIn,
      toolTipPrompt: isWorkerNotClockedIn
        ? 'In order to edit Cost Centers, worker needs to be clocked in.'
        : undefined,
    })
  }

  if (
    workerShift.jobStatus === JobStatus.InProgress ||
    workerShift.jobStatus === JobStatus.OnBreak
  ) {
    workerActions.push({
      label: 'Adjust breaks',
      handleClick: () => adjustBreakModal.open(),
    })
  }

  return (
    <>
      <Dropdown
        dropdownOptions={workerActions}
        orientation={'right'}
        buttonVariant={ButtonVariant.OUTLINED}
        buttonStyling={{
          padding: '11px 12px 6px',
          marginBottom: -8,
          marginTop: -8,
        }}
      />
      <ConfirmShiftModal {...confirmShiftModal} workerShift={workerShift} />
      <AdjustBreakModal {...adjustBreakModal} workerShift={workerShift} />
      <ManageWorkerModal {...manageWorkerModal} workerShift={workerShift} />
      <MakeRobocallModal {...makeRobocallModal} workerShift={workerShift} />
      <FraudulentModal {...fraudulentModal} workerShift={workerShift} />
      {costCenters && workerWithSegments?.clockInTime && (
        <WorkerShiftCostCentersModal
          {...editCostCenterSegmentsModal}
          workerShift={{
            ...workerWithSegments,
            clockInTime: workerWithSegments.clockInTime,
          }}
          shift={workerShift.shiftInfo}
          timezone={workerShift.shiftInfo.timezone}
          segments={workerWithSegments.segments.map(
            ({ costCenter, ...rest }) => ({
              costCenterId: costCenter?.id,
              ...rest,
            }),
          )}
          costCenters={costCenters}
          updateWorkerShiftSegments={updateWorkerShiftSegments}
          areSegmentsUpdating={isUpdating}
        />
      )}
    </>
  )
}
