import { AlertColor } from '@mui/material'
import { theme } from '@traba/theme'
import { HTMLAttributes } from 'react'
import styled from 'styled-components'

export interface InlineBannerProps extends HTMLAttributes<HTMLDivElement> {
  severity?: AlertColor
  text: React.ReactNode
  subTitle?: string
}

export const InlineBanner = styled.div<Pick<InlineBannerProps, 'severity'>>`
  padding: ${({ theme }) => theme.space.xs}px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  column-gap: ${({ theme }) => theme.space.xxs}px;
  background-color: ${({ severity }) =>
    severity === 'error'
      ? theme.colors.Red10
      : severity === 'warning'
        ? theme.colors.Orange10
        : severity === 'success'
          ? theme.colors.Green10
          : theme.colors.Blue10};
`
