import { ShiftWithSlimCharges } from '@traba/types'

export function getInvoiceStripeUrl(invoiceId: string) {
  return `${
    import.meta.env.VITE_STRIPE_URL || 'https://dashboard.stripe.com/test'
  }/invoices/${invoiceId}`
}

export function getEstimatedTotalChargeInCentsFromCharges(
  shift: ShiftWithSlimCharges,
): number {
  if (!shift.charges) {
    return 0
  }
  return shift.charges
    .filter((charge) => !charge.notBillableReason)
    .reduce((sum, charge) => sum + charge.amount, 0)
}
