import { Time } from '@internationalized/date'
import { CircularProgress } from '@mui/material'
import {
  IMenuItem,
  Input,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  StripeStatusForWatchlist,
  PhoneNumberStatus,
  WatchlistRequirementFilter,
  WatchlistRequirementStatus,
  WorkerDetailsForWatchlist,
} from '@traba/types'
import { addWeeks, formatDistanceToNow, startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import {
  Button,
  Col,
  CopyTextIcon,
  Link,
  Row,
  Select,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import Pagination from 'src/components/base/Pagination/Pagination'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import Toggle from 'src/components/base/Toggle'
import { PhoneNumberWithStatus } from 'src/components/PhoneNumberWithStatus'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { useWorkersOnWatchlist } from 'src/hooks/useWorkersOnWatchlist'
import { WorkerRequirementStatusIndicator } from './WorkerRequirementStatusIndicator'

const WORKER_WATCHLIST_PAGE_SIZE = 50
const workerWatchlistLimitOptions: IMenuItem[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
]

export const WorkerWatchlist = () => {
  const today = startOfDay(new Date())
  const [until, setUntil] = useState(addWeeks(today, 1))
  const [showWorkersWithActiveAction, setShowWorkersWithActiveAction] =
    useState(true)
  const [showWorkersWithPendingAction, setShowWorkersWithPendingAction] =
    useState(true)
  const [regionIds, setRegionIds] = useState<string[]>([])
  const [selectedRequirements, setSelectedRequirements] = useState<IMenuItem[]>(
    [],
  )
  const selectedRequirementValues = useMemo(
    () =>
      selectedRequirements.map(
        (item) => item.value as WatchlistRequirementFilter,
      ),
    [selectedRequirements],
  )
  const [searchFieldText, setSearchFieldText] = useState<string>('')
  const [pageSize, setPageSize] = useState(WORKER_WATCHLIST_PAGE_SIZE)
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()
  const { isLoading, isFetching, isError, workersOnWatchlist, error } =
    useWorkersOnWatchlist(
      until,
      selectedRequirementValues,
      showWorkersWithActiveAction,
      showWorkersWithPendingAction,
      searchFieldText,
      regionIds,
      currentPage,
      pageSize,
    )

  const { regions = [], isLoading: regionsLoading } = useRegions()
  const regionsOptions: IMenuItem[] = regions.map((region) => {
    return { label: region.displayName, value: region.regionId }
  })
  const requirementOptions: IMenuItem<WatchlistRequirementFilter>[] =
    Object.values(WatchlistRequirementFilter).map((requirement) => ({
      label: requirement
        .split('_')
        .map((word) =>
          word.length > 0
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word,
        )
        .join(' '),
      value: requirement,
    }))

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker Name',
    },
    {
      key: 'requiredByTime',
      label: 'Required By',
      sortable: true,
    },
    {
      key: 'shiftsDone',
      label: 'Shifts Done',
      sortable: true,
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
    },
    {
      key: 'workerId',
      label: 'Worker Id',
    },
    {
      key: 'stripeStatus',
      label: 'Stripe',
      sortable: true,
    },
    {
      key: 'backgroundCheckStatus',
      label: 'BGC',
      sortable: true,
    },
    {
      key: 'phoneNumberStatus',
      label: 'Phone Status',
      sortable: true,
    },
  ]

  const validBackgroundCheckStatuses = useMemo(
    () => ['COMPLETE', 'CONSIDER', 'REPORT_CREATED', 'CLEAR'],
    [],
  )

  const renderNextImpactedShiftTime = (
    workerDetails: WorkerDetailsForWatchlist,
  ) => {
    if (
      !workerDetails.nextImpactedShiftId ||
      !workerDetails.nextImpactedShiftTime
    ) {
      return <Text>N/A</Text>
    }
    const formattedDate = new Date(
      workerDetails.nextImpactedShiftTime,
    ).toLocaleDateString('en-US')
    const timeToShift = formatDistanceToNow(
      workerDetails.nextImpactedShiftTime,
      { addSuffix: true },
    )

    return (
      <Link to={`/field-monitor/`} target="_blank">
        <Button
          tooltipText={timeToShift}
          style={{ padding: 0 }}
          variant={ButtonVariant.TEXT}
        >
          {formattedDate}
        </Button>
      </Link>
    )
  }

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workersOnWatchlist?.map((workerDetails) => ({
        key: workerDetails.id,
        cells: [
          {
            key: `${workerDetails.id}-name`,
            content: `${workerDetails.firstName} ${workerDetails.lastName}`,
            renderFn: () => {
              const fullName = `${workerDetails.firstName} ${workerDetails.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link to={`/workers/${workerDetails.id}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workerDetails.id}-nextImpactedShiftTime`,
            content: workerDetails.nextImpactedShiftTime,
            renderFn: () => renderNextImpactedShiftTime(workerDetails),
            sortKey: workerDetails.nextImpactedShiftTime
              ? Date.parse(
                  new Date(
                    workerDetails.nextImpactedShiftTime,
                  ).toLocaleDateString('en-US'),
                )
              : Number.MAX_VALUE,
          },
          {
            key: `${workerDetails.id}-completedShifts`,
            content: workerDetails.completedShifts,
            renderFn: () => <Text>{workerDetails.completedShifts}</Text>,
            sortKey: workerDetails.completedShifts,
          },
          {
            key: `${workerDetails.id}-phone`,
            content: workerDetails.phoneNumber,
            renderFn: () => {
              return (
                <PhoneNumberWithStatus
                  phoneNumber={workerDetails.phoneNumber}
                  phoneNumberStatus={workerDetails.phoneNumberStatus}
                />
              )
            },
          },
          {
            key: `${workerDetails.id}-id`,
            content: workerDetails.id,
            renderFn: () => (
              <Text>
                {`${workerDetails.id.slice(0, 8)}...`}
                <CopyTextIcon textToCopy={workerDetails.id} />
              </Text>
            ),
          },
          {
            key: `${workerDetails.id}-stripeStatus`,
            content: workerDetails.stripeStatus,
            renderFn: () =>
              workerDetails.stripeStatus ===
              StripeStatusForWatchlist.UP_TO_DATE ? (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.DONE}
                />
              ) : (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.NOT_DONE}
                  tooltipText={workerDetails.stripeStatus}
                />
              ),
            sortKey: workerDetails.stripeStatus,
          },
          {
            key: `${workerDetails.id}-bgcStatus`,
            content: workerDetails.backgroundCheckStatus,
            renderFn: () => {
              if (workerDetails.hasActiveBackgroundCheckAction) {
                return (
                  <WorkerRequirementStatusIndicator
                    requirementStatus={WatchlistRequirementStatus.ACTION}
                  />
                )
              }
              return validBackgroundCheckStatuses.includes(
                workerDetails.backgroundCheckStatus,
              ) ? (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.DONE}
                />
              ) : (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.NOT_DONE}
                  tooltipText={workerDetails.backgroundCheckStatus}
                />
              )
            },
            sortKey: workerDetails.backgroundCheckStatus,
          },
          {
            key: `${workerDetails.id}-phoneStatus`,
            content: workerDetails.phoneNumberStatus,
            renderFn: () => {
              if (workerDetails.hasActivePhoneNumberStatusAction) {
                return (
                  <WorkerRequirementStatusIndicator
                    requirementStatus={WatchlistRequirementStatus.ACTION}
                  />
                )
              }
              return workerDetails.phoneNumberStatus ===
                PhoneNumberStatus.VERIFIED ? (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.DONE}
                />
              ) : (
                <WorkerRequirementStatusIndicator
                  requirementStatus={WatchlistRequirementStatus.NOT_DONE}
                />
              )
            },
            sortKey: workerDetails.phoneNumberStatus,
          },
        ],
      })) || []
    )
  }, [validBackgroundCheckStatuses, workersOnWatchlist])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter>
        <Col>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Search workers"
            value={searchFieldText}
            onChange={(e) => setSearchFieldText(e.target.value)}
            onClear={() => setSearchFieldText('')}
          />
        </Col>
        <Col>
          <StateSearchSelect
            multiple
            options={regionsOptions}
            selectedItems={regionIds}
            handleSelectMultiple={setRegionIds}
            label={`Region${regionIds.length > 1 ? 's' : ''}`}
            width={'100%'}
          />
        </Col>
        <Col style={{ display: 'flex' }}>
          <DatePicker
            showTimeFieldInPopover={true}
            minDate={new Date()}
            setDate={(until) => {
              setUntil(until ?? new Date())
              setCurrentPage(0)
            }}
            isClearable={true}
            inlineLabel={true}
            label="Shifts Before"
            date={until}
            defaultTime={new Time(0, 0)}
          />
        </Col>

        <Col>
          <Row
            gap={theme.space.xxs}
            style={{
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Pagination
              dataSize={workersOnWatchlist?.length || 0}
              onPageLeft={goToPreviousPage}
              onPageRight={goToNextPage}
              page={currentPage}
              pageSize={pageSize}
            />
            <Select
              label="Limit"
              value={pageSize.toString()}
              handleSelect={(v) => setPageSize(parseInt(v))}
              menuItems={workerWatchlistLimitOptions}
              containerStyle={{ marginLeft: theme.space.xs }}
            />
          </Row>
        </Col>
      </Row>
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <SearchSelect
          multiple
          options={requirementOptions}
          selectedItems={selectedRequirements}
          handleSelectMultiple={setSelectedRequirements}
          label={`Requirements`}
          width={300}
        />
        <Toggle
          label={'Show Active Action'}
          buttonState={showWorkersWithActiveAction}
          runOnChange={() => {
            setShowWorkersWithActiveAction(!showWorkersWithActiveAction)
            setCurrentPage(0)
          }}
          containerStyle={{ justifyContent: 'center' }}
        />
        <Toggle
          label={'Show Pending Action'}
          buttonState={showWorkersWithPendingAction}
          runOnChange={() => {
            setShowWorkersWithPendingAction(!showWorkersWithPendingAction)
            setCurrentPage(0)
          }}
          containerStyle={{ justifyContent: 'center' }}
        />
      </Row>
      {isLoading || regionsLoading || isFetching ? (
        <CircularProgress />
      ) : !workersOnWatchlist || workersOnWatchlist.length === 0 ? (
        <Text>No workers found matching the criteria</Text>
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={1}
        />
      )}

      {isError && (
        <Text variant="error">
          Something went wrong: {(error as Error).message}
        </Text>
      )}
    </Col>
  )
}
