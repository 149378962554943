import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { IncentiveRules, Incentive, RuleLine } from '@traba/types'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { IMenuItem } from 'src/components/base/Select/Select'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { Col, Input, Select } from '../../components/base'
import { Field, FieldType } from './types'

export function CreateIncentiveRuleField({
  type,
  field,
  fieldOptions,
  incentiveToCreate,
  setIncentiveToCreate,
}: {
  type: IncentiveRules
  field: Field
  fieldOptions?: IMenuItem[]
  incentiveToCreate: Omit<Incentive, 'incentiveId'>
  setIncentiveToCreate: React.Dispatch<
    React.SetStateAction<Omit<Incentive, 'incentiveId'>>
  >
}) {
  // Find index of existing rule on incentiveToCreate
  const ruleLineIndex = incentiveToCreate.rules.findIndex(
    (rule) => rule.type === type,
  )

  switch (field.fieldType) {
    case FieldType.input:
      return (
        <>
          <Text
            variant="h6"
            style={{ width: 140, alignSelf: 'flex-start' }}
            mt={theme.space.xxs}
          >
            {field.fieldLabel}
          </Text>
          <Col>
            <Input
              rows={5}
              type="text"
              className="xs-12"
              value={
                (ruleLineIndex !== -1 &&
                  (
                    incentiveToCreate.rules[ruleLineIndex] as {
                      [key: string]: any
                    }
                  )[field.fieldKey]) ||
                ''
              }
              maxLength={400}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIncentiveToCreate((prevCreateIncentive) => {
                  const newRules: RuleLine[] = [...prevCreateIncentive.rules]
                  const idx = newRules.findIndex((rule) => rule.type === type)
                  if (idx !== -1) {
                    // If the rule already exists, update it
                    newRules[idx] = {
                      ...newRules[idx],
                      [field.fieldKey]: e.target.value,
                    }
                  } else {
                    // If the rule doesn't exist, add it
                    newRules.push({
                      type: type,
                      [field.fieldKey]: e.target.value,
                    })
                  }
                  return { ...prevCreateIncentive, rules: newRules }
                })
              }}
              full
            />
            {field.fieldDescription && (
              <InlineBanner
                severity="info"
                text={field.fieldDescription}
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </Col>
        </>
      )
    case FieldType.multiSelect:
      return (
        <>
          <Text
            variant="h6"
            style={{ width: 140, alignSelf: 'flex-start' }}
            mt={theme.space.xxs}
          >
            {field.fieldLabel}
          </Text>
          <Col>
            <Input
              rows={5}
              type="textarea"
              className="xs-12"
              value={
                (ruleLineIndex !== -1 &&
                  (
                    incentiveToCreate.rules[ruleLineIndex] as {
                      [key: string]: any
                    }
                  )[field.fieldKey].join('\n')) ||
                ''
              }
              maxLength={400}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIncentiveToCreate((prevCreateIncentive) => {
                  const newRules: RuleLine[] = [...prevCreateIncentive.rules]
                  const idx = newRules.findIndex((rule) => rule.type === type)
                  if (idx !== -1) {
                    // If the rule already exists, update it
                    newRules[idx] = {
                      ...newRules[idx],
                      [field.fieldKey]: e.target.value
                        ? e.target.value.split(/[\n,]+/)
                        : [], // Split on newline chars and commas
                    }
                  } else {
                    // If the rule doesn't exist, add it
                    newRules.push({
                      type: type,
                      [field.fieldKey]: e.target.value
                        ? e.target.value.split(/[\n,]+/)
                        : [], // Split on newline chars and commas
                    })
                  }
                  return { ...prevCreateIncentive, rules: newRules }
                })
              }}
              full
            />
            {field.fieldDescription && (
              <InlineBanner
                severity="info"
                text={field.fieldDescription}
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </Col>
        </>
      )
    case FieldType.int:
      return (
        <>
          <Text
            variant="h6"
            style={{ width: 140, alignSelf: 'flex-start' }}
            mt={theme.space.xxs}
          >
            {field.fieldLabel}
          </Text>
          <Col>
            <NumberInput
              value={
                (ruleLineIndex !== -1 &&
                  (
                    incentiveToCreate.rules[ruleLineIndex] as {
                      [key: string]: any
                    }
                  )[field.fieldKey]) ||
                0
              }
              setValue={(value: number | undefined) => {
                setIncentiveToCreate((prevCreateIncentive) => {
                  const newRules: RuleLine[] = [...prevCreateIncentive.rules]
                  const idx = newRules.findIndex((rule) => rule.type === type)
                  if (idx !== -1) {
                    // If the rule already exists, update it
                    newRules[idx] = {
                      ...newRules[idx],
                      [field.fieldKey]: value,
                    }
                  } else {
                    // If the rule doesn't exist, add it
                    newRules.push({
                      type: type,
                      [field.fieldKey]: value,
                    })
                  }
                  return { ...prevCreateIncentive, rules: newRules }
                })
              }}
              min={1}
              step={1}
            />
            {field.fieldDescription && (
              <InlineBanner
                severity="info"
                text={field.fieldDescription}
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </Col>
        </>
      )
    case FieldType.select:
      return (
        <>
          <Text
            variant="h6"
            style={{ width: 140, alignSelf: 'flex-start' }}
            mt={theme.space.xxs}
          >
            {field.fieldLabel}
          </Text>
          <Col>
            <Select
              fullWidth
              menuItems={fieldOptions || []}
              handleSelect={(category) =>
                setIncentiveToCreate((prevCreateIncentive) => {
                  const newRules: RuleLine[] = [...prevCreateIncentive.rules]
                  const idx = newRules.findIndex((rule) => rule.type === type)
                  if (idx !== -1) {
                    // If the rule already exists, update it
                    newRules[idx] = {
                      ...newRules[idx],
                      [field.fieldKey]: category,
                    }
                  } else {
                    // If the rule doesn't exist, add it
                    newRules.push({
                      type: type,
                      [field.fieldKey]: category,
                    })
                  }
                  return { ...prevCreateIncentive, rules: newRules }
                })
              }
              value={
                (ruleLineIndex !== -1 &&
                  (
                    incentiveToCreate.rules[ruleLineIndex] as {
                      [key: string]: any
                    }
                  )[field.fieldKey]) ||
                ''
              }
            />
            {field.fieldDescription && (
              <InlineBanner
                severity="info"
                text={field.fieldDescription}
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </Col>
        </>
      )
  }
}
