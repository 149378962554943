import { Text, DevelopmentFallbackComponent } from '@traba/react-components'
import { theme } from '@traba/theme'
import { FallbackProps } from 'react-error-boundary'

export function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  console.error(error) // log to dd
  return import.meta.env.VITE_ENV !== 'production' ? (
    <DevelopmentFallbackComponent
      error={error}
      resetErrorBoundary={resetErrorBoundary}
    />
  ) : (
    <Text variant="h2" mt={theme.space.xs}>
      Something went wrong. Contact EPD if this continues and include the full
      error below.
      {error.message}
    </Text>
  )
}
