import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { calendarStyleCombos } from '@traba/theme'
import { WorkersOnSchedule } from '@traba/types'
import { useState } from 'react'
import { getQueryParams } from './useApi'

export type WorkersOnScheduleParams = {
  onOrAfter?: Date
  onOrBefore?: Date
}

const getWorkersOnSchedule = async (
  shiftRequestParentId: string,
  { onOrAfter, onOrBefore }: WorkersOnScheduleParams,
) => {
  const queryParams = getQueryParams([
    ['onOrAfter', onOrAfter?.toISOString()],
    ['onOrBefore', onOrBefore?.toISOString()],
  ])
  try {
    const res = await trabaApi.get(
      `shift-request-parents/${shiftRequestParentId}/get-workers-on-schedule${queryParams}`,
    )
    return res.data
  } catch (error) {
    console.error(
      'Error querying workers on schedule: ',
      error,
      'Query body: ',
      { onOrAfter, onOrBefore },
    )
    throw error
  }
}

export function useWorkersOnSchedule(
  shiftRequestParentId: string,
  { onOrAfter, onOrBefore }: WorkersOnScheduleParams,
) {
  const [availableStyleCombos, setAvailableStyleCombos] =
    useState(calendarStyleCombos)
  const [colorMap, setColorMap] =
    useState<Record<string, { backgroundColor: string; borderColor: string }>>()
  const queryKey = [
    'workers',
    {
      shiftRequestParentId,
      onOrAfter: onOrAfter?.getDate(),
      onOrBefore: onOrBefore?.getDate(),
    },
  ]

  const {
    isLoading,
    isError,
    error,
    isFetched,
    isFetching,
    data: rawWorkers,
    refetch,
  } = useQuery<WorkersOnSchedule | undefined, Error>({
    queryKey,
    queryFn: () =>
      getWorkersOnSchedule(shiftRequestParentId, { onOrAfter, onOrBefore }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const getStylesForShiftRequest = (shiftRequestId: string) => {
    if (colorMap?.[shiftRequestId]) {
      return colorMap?.[shiftRequestId]
    } else {
      const color = availableStyleCombos[0]
      setAvailableStyleCombos(availableStyleCombos.slice(1))
      setColorMap({
        ...colorMap,
        [shiftRequestId]: color,
      })
      return color
    }
  }

  return {
    isLoading,
    isError,
    error,
    isFetched,
    scheduledWorkers: rawWorkers?.scheduledWorkersInfo,
    replacementWorkers: rawWorkers?.replacementWorkersInfo,
    refetch,
    isFetching,
    getStylesForShiftRequest,
  }
}
