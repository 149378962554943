import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  AssigneeStatus,
  ShiftAssignmentResponse,
  ShiftAssignmentStatus,
} from '@traba/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { QueryParamArray } from 'src/types'
import { getErrorMessage } from 'src/utils/errorUtils'
import { ONE_MINUTE_IN_MS } from '../libs/constants'
import { getQueryParams } from './useApi'

async function getShiftAssignments(
  shiftIds?: string[],
  after?: string,
  before?: string,
  regionIds?: string[],
  assigneeStatuses?: AssigneeStatus[],
  statuses?: ShiftAssignmentStatus[],
  internalUserIds?: string[],
  includeShift?: boolean,
): Promise<ShiftAssignmentResponse[] | undefined> {
  try {
    const shiftIdsArray = [
      ...((shiftIds
        ? shiftIds?.map((shiftId) => ['shiftIds[]', shiftId])
        : []) as QueryParamArray),
    ]

    const regionIdsArray = [
      ...((regionIds
        ? regionIds?.map((regionId) => ['regionIds[]', regionId])
        : []) as QueryParamArray),
    ]

    const assigneeStatusesArray = [
      ...((assigneeStatuses
        ? assigneeStatuses?.map((status) => ['assigneeStatuses[]', status])
        : []) as QueryParamArray),
    ]

    const shiftAssigmentStatusesArray = [
      ...((statuses
        ? statuses?.map((status) => ['shiftAssigmentStatuses[]', status])
        : []) as QueryParamArray),
    ]

    const internalUserIdsArray = [
      ...((internalUserIds
        ? internalUserIds?.map((internalUserId) => [
            'internalUserIds[]',
            internalUserId,
          ])
        : []) as QueryParamArray),
    ]

    const queryString = getQueryParams([
      ...shiftIdsArray,
      ['after', after],
      ['before', before],
      ...regionIdsArray,
      ...assigneeStatusesArray,
      ...shiftAssigmentStatusesArray,
      ...internalUserIdsArray,
      ['includeShift', includeShift],
    ])

    const res = await trabaApi.get(`assignments/shift${queryString}`)

    return res.data
  } catch (error: any) {
    const errorMessage = `useAssignments -> getShiftAssignments() ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function createShiftAssignmentMaps(
  shiftAssignments: ShiftAssignmentResponse[],
): {
  shiftAssignmentsMapByShiftId: Map<string, ShiftAssignmentResponse>
  shiftAssignmentsMapByInternalUser: Map<string, ShiftAssignmentResponse[]>
} {
  const shiftAssignmentsMapByShiftId = new Map<
    string,
    ShiftAssignmentResponse
  >()
  const shiftAssignmentsMapByInternalUser = new Map<
    string,
    ShiftAssignmentResponse[]
  >()

  for (const shiftAssignment of shiftAssignments) {
    if (!shiftAssignmentsMapByShiftId.has(shiftAssignment.shiftId)) {
      shiftAssignmentsMapByShiftId.set(shiftAssignment.shiftId, shiftAssignment)
    }

    for (const assignee of shiftAssignment.assignees) {
      if (!shiftAssignmentsMapByInternalUser.has(assignee.internalUserId)) {
        shiftAssignmentsMapByInternalUser.set(assignee.internalUserId, [
          shiftAssignment,
        ])
      } else {
        shiftAssignmentsMapByInternalUser
          .get(assignee.internalUserId)
          ?.push(shiftAssignment)
      }
    }
  }

  return {
    shiftAssignmentsMapByShiftId,
    shiftAssignmentsMapByInternalUser,
  }
}

export function useShiftAssignmentFilters({
  shiftIds,
  after,
  before,
  regionIds,
  assigneeStatuses,
  statuses,
  internalUserIds,
  includeShift,
}: {
  shiftIds?: string[]
  after?: string
  before?: string
  regionIds?: string[]
  assigneeStatuses?: AssigneeStatus[]
  statuses?: ShiftAssignmentStatus[]
  internalUserIds?: string[]
  includeShift?: boolean
}) {
  if (!shiftIds && !after && !before) {
    throw new Error('shiftIds, after and before must be provided')
  }
  const {
    isLoading,
    isError,
    data: shiftAssignments = [],
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftAssignmentResponse[] | undefined, Error>(
    [
      'shift_assignments',
      {
        shiftIds,
        after,
        before,
        regionIds,
        assigneeStatuses,
        statuses,
        internalUserIds,
        includeShift,
      },
    ],
    () =>
      getShiftAssignments(
        shiftIds,
        after,
        before,
        regionIds,
        assigneeStatuses,
        statuses,
        internalUserIds,
        includeShift,
      ),
    { staleTime: ONE_MINUTE_IN_MS },
  )

  const { shiftAssignmentsMapByShiftId, shiftAssignmentsMapByInternalUser } =
    createShiftAssignmentMaps(shiftAssignments)

  return {
    isLoading,
    isError,
    data: shiftAssignments,
    error,
    isFetched,
    refetch,
    shiftAssignmentsMapByShiftId,
    shiftAssignmentsMapByInternalUser,
  }
}

export const useShiftAssignments = () => {
  const { showError, showSuccess } = useAlert()

  const [createShiftAssignmentLoading, setCreateShiftAssignmentLoading] =
    useState<boolean>(false)
  const createShiftAssignment = async ({
    shiftId,
    internalUserId,
    assignedBy,
    status,
  }: {
    shiftId: string
    internalUserId: string
    assignedBy: string
    status?: ShiftAssignmentStatus
  }) => {
    setCreateShiftAssignmentLoading(true)
    try {
      await trabaApi.post(`assignments/shift/${shiftId}`, {
        internalUserId,
        assignedBy,
        ...(status ? { status } : {}),
      })
      showSuccess(
        `Created assignment for shift ${shiftId}`,
        'Successfully Created Shift Assignment',
      )
    } catch (e) {
      showError(getErrorMessage(e), 'Error Creating Shift Assignment')
    } finally {
      setCreateShiftAssignmentLoading(false)
    }
  }

  const createShiftAssignments = async ({
    shiftIds,
    internalUserIds,
    assignedBy,
    status,
  }: {
    shiftIds: string[]
    internalUserIds: string[]
    assignedBy: string
    status?: ShiftAssignmentStatus
  }) => {
    setCreateShiftAssignmentLoading(true)
    try {
      await trabaApi.post(`assignments/shift`, {
        internalUserIds,
        shiftIds,
        assignedBy,
        ...(status ? { status } : {}),
      })
      showSuccess(
        `Created assignment for shifts ${shiftIds.toString()}`,
        'Successfully Created Shift Assignments',
      )
    } catch (e) {
      showError(getErrorMessage(e), 'Error Creating Shift Assignment')
    } finally {
      setCreateShiftAssignmentLoading(false)
    }
  }

  const [updateShiftAssignmentLoading, setUpdateShiftAssignmentLoading] =
    useState<boolean>(false)
  const updateShiftAssignment = async ({
    shiftId,
    internalUserId,
    status,
    assigneeStatus,
  }: {
    shiftId: string
    internalUserId: string
    status?: ShiftAssignmentStatus
    assigneeStatus?: AssigneeStatus
  }) => {
    setUpdateShiftAssignmentLoading(true)
    try {
      await trabaApi.patch(`assignments/shift/${shiftId}`, {
        internalUserId,
        ...(status ? { status } : {}),
        ...(assigneeStatus ? { assigneeStatus } : {}),
      })
      showSuccess(
        `Updated assignment for user ${internalUserId} for shift ${shiftId}`,
        'Successfully Updated Shift Assignment',
      )
    } catch (e) {
      showError(getErrorMessage(e), 'Error Updating Shift Assignment')
    } finally {
      setUpdateShiftAssignmentLoading(false)
    }
  }

  return {
    createShiftAssignment,
    createShiftAssignments,
    updateShiftAssignment,
    createShiftAssignmentLoading,
    updateShiftAssignmentLoading,
  }
}
