import { useDragAndDrop } from '@formkit/drag-and-drop/react'
import {
  ButtonVariant,
  Col,
  Draggable,
  Input,
  Row,
  SearchSelect,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IMenuItem,
  InputStatus,
  ReportColumn,
  ReportColumnKey,
} from '@traba/types'
import { startCase, toLower } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Icon, Button } from 'src/components/base'

interface ReportColumnListProps {
  columns?: ReportColumn[]
  showColumnErrors: boolean
  handleColumnSelect: (value: IMenuItem | undefined, index: number) => void
  handleDisplayNameChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => void
  handleAddColumn: () => void
  handleDeleteColumn: (index: number) => void
  handleColumnReorder: (columns: ReportColumn[]) => void
}

export function ReportColumnList({
  columns = [],
  showColumnErrors,
  handleColumnSelect,
  handleDisplayNameChange,
  handleAddColumn,
  handleDeleteColumn,
  handleColumnReorder,
}: ReportColumnListProps) {
  const [dragging, setDragging] = useState<ReportColumn | null>(null)

  const [ref, reportColumns, setReportColumns] = useDragAndDrop<
    HTMLDivElement,
    Partial<ReportColumn>
  >(columns, {
    dragHandle: '.dragColumn',
    dragPlaceholderClass: 'dragging',
    onDragstart: (_, state) => {
      const currentValue = state.currentTargetValue as ReportColumn | undefined
      if (currentValue) {
        setDragging(currentValue)
      }
    },
    handleDragend: () => {
      setDragging(null)
      handleColumnReorder(reportColumns)
    },
  })

  useEffect(() => {
    setReportColumns(columns)
  }, [columns, setReportColumns])

  const reportColumnKeys = useMemo(() => {
    return reportColumns.map(({ columnKey }) => columnKey)
  }, [reportColumns])

  const reportColumnOptions = useMemo(() => {
    return Object.values(ReportColumnKey)
      .filter((key) => !reportColumnKeys.includes(key))
      .map((key) => ({
        label: startCase(toLower(key)),
        value: key,
      }))
  }, [reportColumnKeys])

  return (
    <>
      <Col ref={ref} gap={theme.space.sm}>
        {reportColumns.map(({ columnKey, displayName }, index) => {
          const isDragging =
            dragging !== null && dragging.columnKey === columnKey

          const selectItem = columnKey
            ? {
                value: columnKey,
                label: startCase(toLower(columnKey)),
              }
            : undefined

          const errorProps =
            showColumnErrors && !columnKey
              ? {
                  errorMessage:
                    'You must select a column type or delete this column',
                  inputStatus: InputStatus.error,
                }
              : {}

          return (
            <Draggable
              key={columnKey || `item-${index}`}
              className="dragColumn"
              isDragging={isDragging}
              isDraggingClassName="dragging"
              dragHandleStyle={{ height: '48px' }}
            >
              <Row gap={theme.space.xs} fullWidth alignCenter justifyCenter>
                <Col style={{ width: '50%' }}>
                  <SearchSelect
                    options={reportColumnOptions}
                    handleSelect={(value) => handleColumnSelect(value, index)}
                    selectStyle={{ height: '48px' }}
                    selectItem={selectItem}
                    onlyShowLabel
                    {...errorProps}
                  />
                </Col>
                <Input
                  label="Column Name"
                  name="displayName"
                  value={displayName}
                  onChange={(e) => handleDisplayNameChange(e, index)}
                  width="50%"
                  containerStyle={{ marginTop: 0 }}
                />
                <Icon
                  name="trash"
                  type="svg"
                  size={theme.space.sm}
                  color={theme.colors.red}
                  onClick={() => handleDeleteColumn(index)}
                />
              </Row>
            </Draggable>
          )
        })}
      </Col>
      <Row>
        <Button
          variant={ButtonVariant.TEXT}
          leftIcon={<Icon name="plus_active" />}
          labelStyle={{ color: theme.colors.brand }}
          style={{ paddingTop: 0 }}
          onClick={handleAddColumn}
          type="button"
        >
          Add Column
        </Button>
      </Row>
    </>
  )
}
