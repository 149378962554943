import { FileType } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon, Row } from 'src/components/base'
import * as S from 'src/components/base/Modal/Modal.styles'
import { UploadVideo } from './UploadVideo'

export type UploadVideoModalProps = {
  companyId: string
  fileType: FileType
  onClose: () => void
  onUploadSuccess?: (videoId: string) => void
}

export const UploadVideoModal = (props: UploadVideoModalProps) => {
  return (
    <S.ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.med }}>
        <Text variant="h2">Upload training video</Text>
        <Icon
          name="cancel"
          onClick={props.onClose}
          style={{
            height: theme.space.xs,
            width: theme.space.xs,
            cursor: 'pointer',
          }}
        />
      </Row>
      <UploadVideo {...props} />
    </S.ModalPaddingContainer>
  )
}
