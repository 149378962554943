import { Alert, AlertTitle } from '@mui/material'
import Fade from '@mui/material/Fade'
import { useAlert } from '@traba/context'
import { Z_INDEXES } from '@traba/theme'
import { useCallback, useEffect } from 'react'

export const Toast = () => {
  const { state, cancelAlert } = useAlert()
  const { active, severity, message, title, durationInMs } = state

  useEffect(() => {
    if (active && durationInMs < Infinity) {
      const timer = setTimeout(() => {
        cancelAlert()
      }, durationInMs)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [active, cancelAlert, durationInMs])

  const handleClose = useCallback(() => {
    cancelAlert()
  }, [cancelAlert])

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 100,
        width: '100%',
        display: 'flex',
        pointerEvents: durationInMs === Infinity ? 'auto' : 'none',
        justifyContent: 'center',
        zIndex: Z_INDEXES.ALERT,
      }}
    >
      <Fade in={active}>
        <Alert
          severity={severity}
          style={{
            minWidth: 600,
            justifyContent: 'center',
          }}
          {...(durationInMs === Infinity ? { onClose: handleClose } : {})}
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Fade>
    </div>
  )
}
