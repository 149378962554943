import { InputAdornment } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { ButtonVariant, Text, Row, Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { BreakType } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { getBreaksForWorkerShift } from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import { AdjustmentSummary } from 'src/components/AdjustmentForm/components/AdjustmentSummary'
import { DatePicker, Select } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { BreakAdjuster } from 'src/components/BreakAdjuster/BreakAdjuster'
import { WorkerShiftHistory } from 'src/components/WorkerShiftHistory'
import { UpdateShiftParams } from 'src/hooks/useWorkerShifts'
import { toDate } from 'src/utils/dateUtils'
import { FullWidthTextField, MediumSizedButton, ShowMoreToggle } from './styles'

type PendingClockOutAdjustFormProps = {
  handleClose: () => void
  handleConfirmAndEndShift: (
    params: Pick<
      UpdateShiftParams,
      'clockInTime' | 'clockOutTime' | 'breaks' | 'shiftInfo'
    >,
  ) => Promise<void>
  workerShift: WorkerShift
  loading?: boolean
}

export function PendingClockOutAdjustForm({
  handleClose,
  handleConfirmAndEndShift,
  workerShift,
  loading,
}: PendingClockOutAdjustFormProps) {
  const { hotSettings } = useHotSettings()
  const { clockOutTimeBeforeWorkerEdit, shiftInfo } = workerShift
  const timezone = workerShift.shiftInfo.timezone || ''

  const breakTypes = (
    Object.keys(BreakType) as Array<keyof typeof BreakType>
  ).map((bt) => {
    return { label: bt, value: bt }
  })

  const originalClockInOrShiftStart: Date = useMemo(() => {
    return workerShift.clockInTime ?? shiftInfo.startTime ?? new Date()
  }, [workerShift.clockInTime, shiftInfo.startTime])
  const originalClockOutOrShiftEnd: Date = useMemo(() => {
    return (
      workerShift.clockOutTime ??
      clockOutTimeBeforeWorkerEdit ??
      shiftInfo.endTime ??
      new Date()
    )
  }, [
    workerShift.clockOutTime,
    clockOutTimeBeforeWorkerEdit,
    shiftInfo.endTime,
  ])

  const [showSummary, setShowSummary] = useState<boolean>(false)

  const [clockInTime, setClockInTime] = useState<Date>(
    toDate(originalClockInOrShiftStart),
  )
  const onChangeClockInTime = useCallback((newTime: Date | null) => {
    if (newTime) {
      setClockInTime(newTime)
    }
  }, [])
  const [clockOutTime, setClockOutTime] = useState<Date>(
    toDate(originalClockOutOrShiftEnd),
  )
  const onChangeClockOutTime = useCallback((newTime: Date | null) => {
    if (newTime) {
      setClockOutTime(newTime)
    }
  }, [])

  const [breakType, setBreakType] = useState<string>(shiftInfo.breakType ?? '')

  const originalBreaks = getBreaksForWorkerShift(
    workerShift,
    hotSettings?.enableWorkerEditTime,
  )

  const [breaks, setBreaks] = useState<ShiftTime[]>(originalBreaks || [])

  const originalPayRate = shiftInfo.payRate
  const [payRate, setPayRate] = useState<number>(originalPayRate)
  const onChangePayRate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const parsedRate = Math.abs(+ev.currentTarget.value)
    setPayRate(Number(parsedRate.toFixed(2)))
  }

  const originalMinimumPaidTime = shiftInfo.minimumPaidTime ?? 0
  const [minPaidTime, setMinPaidTime] = useState<number>(
    originalMinimumPaidTime,
  )

  const [showShiftHistoryDetails, setShowShiftHistoryDetails] =
    useState<boolean>(true)
  const toggleShowHistoryDetails = useCallback(() => {
    setShowShiftHistoryDetails((prevVal) => !prevVal)
  }, [])
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(true)
  const toggleShowMoreOptions = useCallback(() => {
    setShowMoreOptions((prevVal) => !prevVal)
  }, [])

  const onConfirm = useCallback(async () => {
    await handleConfirmAndEndShift({
      clockOutTime,
      breaks,
      // add optional params if they've changed from original values
      clockInTime:
        originalClockInOrShiftStart !== clockInTime ? clockInTime : undefined,
      shiftInfo: {
        payRate: originalPayRate !== payRate ? payRate : undefined,
        minimumPaidTime:
          originalMinimumPaidTime !== minPaidTime ? minPaidTime : undefined,
      },
    })
  }, [
    handleConfirmAndEndShift,
    clockOutTime,
    breaks,
    originalClockInOrShiftStart,
    clockInTime,
    originalPayRate,
    payRate,
    originalMinimumPaidTime,
    minPaidTime,
  ])

  return (
    <Row flexCol fullWidth fullHeight justifyBetween>
      <Row alignCenter justifyBetween>
        <Text variant="h5">Shift History</Text>
        <ShowMoreToggle
          variant={ButtonVariant.TEXT}
          onClick={toggleShowHistoryDetails}
        >
          {showShiftHistoryDetails ? 'Hide Details' : 'Show Details'}
        </ShowMoreToggle>
      </Row>
      <Row>
        {showShiftHistoryDetails && (
          <WorkerShiftHistory workerShift={workerShift} />
        )}
      </Row>
      {showSummary ? (
        <AdjustmentSummary
          workerShift={workerShift}
          adjustedStartTime={clockInTime}
          adjustedEndTime={clockOutTime}
          adjustedBreakType={breakType as BreakType}
          adjustedBreaks={breaks}
          adjustedPayRate={payRate}
          adjustedMinPaidTime={minPaidTime}
          adjustedUnitsWorked={undefined}
        />
      ) : (
        <>
          <Row mb={theme.space.xxs} mt={theme.space.sm}>
            <Text variant="h5">Start/End Time</Text>
          </Row>
          <Col mb={theme.space.med}>
            <Row mb={theme.space.sm} justifyStart>
              <Text variant="caption">SHIFT LOCAL TIME</Text>
            </Row>

            <Row mb={theme.space.sm}>
              <DatePicker
                showTimeFieldInPopover={true}
                setDate={onChangeClockInTime}
                isClearable={false}
                inlineLabel={true}
                label="Start Time"
                date={clockInTime}
                timezone={timezone}
              />
            </Row>
            <Row>
              <DatePicker
                showTimeFieldInPopover={true}
                setDate={onChangeClockOutTime}
                isClearable={false}
                inlineLabel={true}
                label="End Time"
                date={clockOutTime}
                timezone={timezone}
              />
            </Row>
          </Col>
          <Divider />
          <Row justifyEnd>
            <ShowMoreToggle
              variant={ButtonVariant.TEXT}
              onClick={toggleShowMoreOptions}
            >
              {showMoreOptions ? 'Hide More Options' : 'Show More Options'}
            </ShowMoreToggle>
          </Row>
          {showMoreOptions ? (
            <>
              <Row mb={theme.space.xs}>
                <Text variant="h5">Pay Rate</Text>
              </Row>
              <Row mb={theme.space.med}>
                <FullWidthTextField
                  margin="dense"
                  placeholder="Pay rate"
                  label="Pay rate"
                  value={payRate}
                  onChange={onChangePayRate}
                  inputMode="decimal"
                  inputProps={{ step: 'any' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    type: 'number',
                  }}
                  variant="outlined"
                />
              </Row>
              <Divider />
              <Row mt={theme.space.med} mb={theme.space.xs}>
                <Text variant="h5">Min Paid Time</Text>
              </Row>
              <Row mb={theme.space.med}>
                <FullWidthTextField
                  margin="dense"
                  placeholder="Minimum Paid Time (Minutes)"
                  label="Minimum Paid Time (Minutes)"
                  value={minPaidTime}
                  onChange={(e) => setMinPaidTime(+e.target.value)}
                  inputMode="decimal"
                  inputProps={{ min: 0, step: 1 }}
                  InputProps={{ type: 'number' }}
                  variant="outlined"
                />
              </Row>
              <Divider />
            </>
          ) : null}
        </>
      )}
      <Divider />
      <Row my={theme.space.med} gap={theme.space.xs}>
        <Text variant="h5">Breaks</Text>
        <Row ml={theme.space.xs} style={{ width: '25%' }}>
          <Select
            fullWidth
            label="Break Type"
            menuItems={breakTypes}
            value={breakType}
            handleSelect={setBreakType}
            disabled
          />
        </Row>
      </Row>
      <Col mb={theme.space.med}>
        <BreakAdjuster
          breaks={breaks}
          setBreaks={setBreaks}
          timezone={timezone}
          defaultBreakTime={
            toDate(workerShift.clockInTime ?? shiftInfo.startTime) ?? new Date()
          }
        />
      </Col>

      <Divider />
      <Row fullWidth mt={theme.space.med} pb={theme.space.xs} justifyBetween>
        <MediumSizedButton
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            showSummary ? setShowSummary(false) : handleClose()
          }}
        >
          {showSummary ? 'Back' : 'Cancel'}
        </MediumSizedButton>
        <MediumSizedButton
          onClick={() => {
            showSummary ? onConfirm() : setShowSummary(true)
          }}
          loading={loading}
        >
          {showSummary ? 'Confirm' : 'Continue'}
        </MediumSizedButton>
      </Row>
    </Row>
  )
}
