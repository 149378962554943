import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { Button } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { useModal } from '../../../../components/base/Modal/Modal'
import AcceptShiftModal from '../../../../modals/AcceptShiftModal/AcceptShiftModal'

export const AddWorkersToShift = ({
  shift,
  workers,
  openModal,
}: {
  shift: Shift
  workers: PopulatedWorker[]
  openModal: () => void
}) => {
  const acceptShiftModal = useModal()

  const parseSelectedWorkers = () => {
    return workers.map((w) => w.id || w.workerId)
  }

  const handleAddToShift = () => {
    acceptShiftModal.open()
  }

  return (
    <>
      <Button
        onClick={handleAddToShift}
        ml={theme.space.xxs}
        disabled={workers.length === 0}
      >
        {workers.length === 0
          ? 'Select workers'
          : `Add ${workers.length} workers`}
      </Button>

      {workers.length > 0 && (
        <AcceptShiftModal
          {...acceptShiftModal}
          workerIds={parseSelectedWorkers()}
          shift={shift}
        />
      )}
      <Button onClick={openModal} ml={theme.space.xxs}>
        Add workers to multiple shifts
      </Button>
    </>
  )
}
