import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { ConnectionContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

type CompanyConnectionModalProps = {
  companyId: string
  handleClose: () => void
  isOpen: boolean
}

export const CompanyConnectionModal = (props: CompanyConnectionModalProps) => {
  const { isOpen, handleClose, companyId } = props
  const [selectedWorker, setSelectedWorker] = useState<PopulatedWorker[]>([])

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={'Create Connection for Company'}
    >
      <Row flexCol fullHeight fullWidth className="modalWrapper">
        <Text variant="h5" style={{ marginRight: theme.space.xs }}>
          Select a worker to make the connection to
        </Text>
        <Row alignCenter mb={theme.space.sm}>
          <SearchWorkers
            checkedWorkers={selectedWorker}
            setCheckedWorkers={setSelectedWorker}
            hideApprovedOnlyToggle
            hideInstructionText
            selectOneOnly
          />
        </Row>
        {selectedWorker.length > 0 && (
          <ConnectionContent
            companyId={companyId}
            handleClose={handleClose}
            workerId={selectedWorker[0].id || selectedWorker[0].workerId}
          />
        )}
      </Row>
    </Modal>
  )
}
