import { Button } from '@traba/react-components'
import { Row } from 'src/components/base'

interface Props {
  isLoadingButton: boolean
  disabledButton: boolean
  buttonTitle: string
  onClick: () => void
}

export const CreateShiftRequestButton = ({
  isLoadingButton,
  disabledButton,
  onClick,
  buttonTitle,
}: Props) => {
  return (
    <Row justifyEnd>
      <Button
        style={{ width: '250px' }}
        onClick={onClick}
        loading={isLoadingButton}
        disabled={disabledButton}
      >
        {buttonTitle}
      </Button>
    </Row>
  )
}
