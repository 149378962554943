import { Phone } from '@mui/icons-material'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftEligibility } from '@traba/types'
import { useState } from 'react'
import { WorkerWithEligibility } from 'src/hooks/useWorkerShiftsEligibilities'
import { useUserContext } from '../../context/user/UserContext'
import { Icon } from '../base'
import EligibilityIcon from '../base/EligibilityIcon/EligibilityIcon'
import { WorkerNoteDrawer } from '../WorkerNoteDrawer'

type WorkerActionsProps = {
  data: WorkerWithEligibility
}

type TooltipContentProps = {
  eligibility?: WorkerShiftEligibility & {
    includeInExplore?: boolean
    waitlistOnly?: boolean
    canJoinAsPaidBackup?: boolean
  }
}

export const TooltipContent: React.FC<TooltipContentProps> = ({
  eligibility,
}) => {
  if (!eligibility) {
    return null
  }

  const criteriaEntries = Object.entries(eligibility.eligibilityCriteria)
  const failedEntries = [...criteriaEntries].filter(([_, value]) => !value)

  return (
    <>
      <Text variant="h6">Eligibility Status</Text>
      <List sx={{ '& .MuiListItemText-primary': { fontSize: '0.9rem' } }}>
        {failedEntries.length ? (
          failedEntries.map(([key, value]) => (
            <ListItem key={key} disableGutters dense>
              <ListItemText
                primary={key}
                primaryTypographyProps={{
                  variant: 'body2',
                  sx: { color: value ? 'inherit' : 'red' },
                }}
              />
            </ListItem>
          ))
        ) : (
          <Text>Worker passed all eligibility checks</Text>
        )}
      </List>
      {eligibility.includeInExplore !== undefined && (
        <>
          <Text variant="h6">App Visibility Status</Text>
          <Text>
            Can see it? {eligibility.includeInExplore ? 'YES' : 'NO'}{' '}
            {eligibility.canJoinAsPaidBackup
              ? 'as paid backup or waitlist'
              : eligibility.waitlistOnly
                ? 'as waitlist only'
                : null}
          </Text>
        </>
      )}
    </>
  )
}

const WorkerActions = ({ data }: WorkerActionsProps) => {
  const [workerNotesOpen, setWorkerNotesOpen] = useState<boolean>(false)
  const { state } = useUserContext()

  const handleAddNotesClick = () => {
    setWorkerNotesOpen(!workerNotesOpen)
  }

  const handlePhoneCallTrack = () => {
    window.analytics.track('Bugle Phone Call Started', {
      initiatedBy: state.userProfile?.email,
    })
  }

  return (
    <>
      <EligibilityIcon eligibility={data.eligibility} />
      <a
        href={`tel:${data.worker.phoneNumber}`}
        style={{ textDecoration: 'none' }}
        onClick={handlePhoneCallTrack}
      >
        <IconButton size="small" sx={{ maxWidth: 56 }}>
          <Phone color="primary" />
        </IconButton>
      </a>
      <IconButton
        size="small"
        sx={{ maxWidth: 56 }}
        onClick={handleAddNotesClick}
      >
        <Icon
          name="message"
          style={{ color: theme.palette.primary.main }}
          width={20}
          color="primary"
        />
      </IconButton>
      {workerNotesOpen && (
        <WorkerNoteDrawer
          workerId={data.worker.id || data.worker.workerId}
          isOpen={workerNotesOpen}
          setIsOpen={setWorkerNotesOpen}
          hideFAB
        />
      )}
    </>
  )
}

export default WorkerActions
