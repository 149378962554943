import {
  BreakpointProps,
  TrabaTheme,
  addPixelUnit,
  hideOnBreakpoints,
  styleColOnBreakpoints,
} from '@traba/theme'
import React from 'react'

import styled from 'styled-components'

type Props = {
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
  width?: string | number
  children: React.ReactNode
  style?: { [key: string]: string | number }
  className?: string
} & BreakpointProps

const StyledCol = styled.div<Props>`
  flex-direction: column;

  /* Margins */
  margin-top: ${(props) => addPixelUnit(props.mt ?? props.my ?? 0)};
  margin-bottom: ${(props) => addPixelUnit(props.mb ?? props.my ?? 0)};
  margin-left: ${(props) => addPixelUnit(props.ml ?? props.mx ?? 0)};
  margin-right: ${(props) => addPixelUnit(props.mr ?? props.mx ?? 0)};

  /* Paddings */
  padding-top: ${(props) => addPixelUnit(props.pt ?? props.py ?? 0)};
  padding-bottom: ${(props) => addPixelUnit(props.pb ?? props.py ?? 0)};
  padding-left: ${(props) => addPixelUnit(props.pl ?? props.px ?? 0)};
  padding-right: ${(props) => addPixelUnit(props.pr ?? props.px ?? 0)};

  ${(props) =>
    props.width ? `width: ${addPixelUnit(props.width)} !important;` : ''}

  /* Device Size */
  ${(props: Props & { theme: TrabaTheme }) => hideOnBreakpoints(props)}
  ${(props: Props & { theme: TrabaTheme }) => styleColOnBreakpoints(props)}
`

export default function Col({ className = '', ...props }: Props) {
  return <StyledCol {...props} className={`Col ${className}`} />
}
