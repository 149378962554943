import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Option, some } from 'fp-ts/Option'
import { Row } from '../../base'
import DatePicker from '../../base/AriaDatePicker/DatePicker'
import Checkbox from '../../base/Checkbox'

export type AdjustDateProps = {
  isBulkAdjustment: boolean
  shouldAdjustStartTime: boolean
  shouldAdjustEndTime: boolean
  onStartTimeAdjustmentChange: () => void
  onEndTimeAdjustmentChange: () => void
  adjustedStartTime: Date
  setClockInTime: React.Dispatch<React.SetStateAction<Option<Date>>>
  setClockOutTime: React.Dispatch<React.SetStateAction<Option<Date>>>
  timezone: string
  adjustedEndTime: Date
}

export function AdjustDate(props: AdjustDateProps) {
  const {
    isBulkAdjustment,
    shouldAdjustStartTime,
    shouldAdjustEndTime,
    onStartTimeAdjustmentChange,
    onEndTimeAdjustmentChange,
    adjustedStartTime,
    adjustedEndTime,
    setClockInTime,
    setClockOutTime,
    timezone,
  } = props

  return (
    <>
      {}
      <Row
        style={{
          marginBottom: theme.space.xs,
          justifyContent: 'start',
        }}
      >
        <Text variant="caption">SHIFT LOCAL TIME</Text>
      </Row>
      {isBulkAdjustment && (
        <Checkbox
          label="Adjust Start Time"
          textvariant="body1"
          checked={shouldAdjustStartTime}
          onChange={onStartTimeAdjustmentChange}
          labelStyle={{ marginBottom: theme.space.med }}
        />
      )}
      {shouldAdjustStartTime && (
        <Row style={{ marginBottom: theme.space.med }}>
          <div style={{ width: '50%' }}>
            <DatePicker
              showTimeFieldInPopover={true}
              setDate={(clockInTime) => {
                if (clockInTime) {
                  setClockInTime(some(clockInTime))
                }
              }}
              isClearable={false}
              inlineLabel={true}
              label="Start Time"
              date={adjustedStartTime}
              timezone={timezone}
            />
          </div>
        </Row>
      )}
      {isBulkAdjustment && (
        <Checkbox
          label="Adjust End Time"
          textvariant="body1"
          checked={shouldAdjustEndTime}
          onChange={onEndTimeAdjustmentChange}
          labelStyle={{ marginBottom: theme.space.med }}
        />
      )}
      {shouldAdjustEndTime && (
        <Row>
          <div style={{ width: '50%' }}>
            <DatePicker
              showTimeFieldInPopover={true}
              setDate={(clockOutTime) => {
                if (clockOutTime) {
                  setClockOutTime(some(clockOutTime))
                }
              }}
              isClearable={false}
              inlineLabel={true}
              label="End Time"
              date={adjustedEndTime}
              timezone={timezone}
            />
          </div>
        </Row>
      )}
    </>
  )
}
