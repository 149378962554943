import { CircularProgress } from '@mui/material'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { theme } from '@traba/theme'
import {
  RobocallCommunication,
  SentinelCheckpointBasedEventType,
  SentinelNotification,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { useMemo } from 'react'
import { useWorkerShiftsById } from 'src/hooks/useWorkerShifts'
import { useApi } from '../../../hooks/useApi'
import { ONE_MINUTE_IN_MS } from '../../../libs/constants'
import { Row } from '../../base'
import { SentinelWorkerDetailsTable } from './SentinelWorkerDetailsTable'
import { SentinelWorkerTransitsTable } from './SentinelWorkerTransitsTable'

export const SentinelWorkerDetailsSection = ({
  shiftId,
  workerIds,
  setSelectedWorkerShifts,
  notification,
  slim,
}: {
  shiftId: string
  workerIds: string[]
  setSelectedWorkerShifts: (workerShifts: WorkerShift[]) => void
  notification: SentinelNotification
  slim?: boolean
}) => {
  const { data: robocalls } = useApi<RobocallCommunication[]>(
    `communication/robocalls?shiftId=${shiftId}`,
    undefined,
    {
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  const response = useWorkerShiftsById(shiftId, workerIds)
  const workerShifts = useMemo(() => {
    return response
      .map((ws) => ws.data)
      .filter((ws): ws is WorkerShift => ws !== undefined)
  }, [response])
  const handleSelectWorkers = (workersSelected: GridRowSelectionModel) => {
    const selectedWorkerShifts = workerShifts.filter((ws) =>
      workersSelected.includes(ws.workerId),
    )
    setSelectedWorkerShifts(selectedWorkerShifts)
  }
  const isWorkerShiftTransitStatusNotification =
    notification.eventType ===
    SentinelCheckpointBasedEventType.WORKERS_TRANSIT_STATUS

  return (
    <Row mx={theme.space.xs} my={theme.space.xxs}>
      {response.some(({ isLoading }) => isLoading) ? (
        <CircularProgress />
      ) : isWorkerShiftTransitStatusNotification ? (
        <SentinelWorkerTransitsTable
          workerShifts={workerShifts}
          handleSelectWorkers={handleSelectWorkers}
          shiftId={notification.data.shift.shiftId}
          slim={slim}
        />
      ) : (
        <SentinelWorkerDetailsTable
          workerShifts={workerShifts}
          robocalls={robocalls}
          handleSelectWorkers={handleSelectWorkers}
          notification={notification}
          slim={slim}
        />
      )}
    </Row>
  )
}
