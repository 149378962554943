import {
  GridColDef,
  GridRenderCellParams,
  gridNumberComparator,
  GridRowSelectionModel,
  GridSortCellParams,
} from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { LoadingSpinner, Row, Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import {
  JobStatus,
  PaidBackup,
  PaidBackupStatus,
  TransitMetricChange,
  TransitResolution,
  Worker,
  WorkerShiftForOps,
  WorkerShiftTransit,
} from '@traba/types'
import { addSeconds } from 'date-fns'
import { useMemo } from 'react'
import { WorkerNameWithIcons } from 'src/components/WorkerNameWithIcons/WorkerNameWithIcons'
import { usePaidBackups } from 'src/hooks/usePaidBackups'
import { useShift } from 'src/hooks/useShifts'
import useTimezonedDates, {
  TimezonedDateFns,
} from 'src/hooks/useTimezonedDates'
import { useWorkerShiftTransit } from 'src/hooks/useWorkerShiftTransit'
import { kmToMiles } from 'src/utils/helperUtils'
import { Link } from '../../base'
import { getTransitStatusCriticalLevel } from '../utils'
import {
  TransitTableNegativeUpIcon,
  TransitTableNeutralIcon,
  TransitTablePositiveDownIcon,
} from './styles'
import { SentinelTableDataGridStyle } from './styles'
import { WorkerShiftTransitProgressBar } from './WorkerShiftTransit/WorkerShiftTransitProgressBar'

function getEta(
  recordedAt: Date,
  remainingDuration: number,
  tz: TimezonedDateFns,
) {
  const eta = addSeconds(recordedAt, remainingDuration)
  return tz.getTime(eta)
}

function getEtaTime(recordedAt?: Date, remainingDuration?: number) {
  if (!recordedAt || !remainingDuration) {
    return 0
  }
  const eta = addSeconds(recordedAt, remainingDuration)
  return eta.getTime()
}

function compareEtaByTime(
  _: unknown,
  __: unknown,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
) {
  const eta1 = getEtaTime(
    param1.api.getCellValue(param1.id, 'latestRemainingMetricsTimestamp'),
    param1.api.getCellValue(param1.id, 'latestRemainingDurationSeconds'),
  )
  const eta2 = getEtaTime(
    param2.api.getCellValue(param2.id, 'latestRemainingMetricsTimestamp'),
    param2.api.getCellValue(param2.id, 'latestRemainingDurationSeconds'),
  )
  return gridNumberComparator(eta1, eta2, param1, param2)
}

function compareLatestStatusByCriticalLevel(
  _: unknown,
  __: unknown,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
) {
  const status1 = param1.api.getCellValue(param1.id, 'latestTransitStatus')
  const status2 = param2.api.getCellValue(param2.id, 'latestTransitStatus')
  return gridNumberComparator(
    getTransitStatusCriticalLevel(status1),
    getTransitStatusCriticalLevel(status2),
    param1,
    param2,
  )
}

function compareOriginalDistance(
  _: unknown,
  __: unknown,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
) {
  const dist1 =
    param1.api.getCellValue(param1.id, 'initialRemainingDistanceMeters') || 0
  const dist2 =
    param2.api.getCellValue(param2.id, 'initialRemainingDistanceMeters') || 0
  return gridNumberComparator(dist1, dist2, param1, param2)
}

function compareLatestDistance(
  _: unknown,
  __: unknown,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
) {
  const dist1 =
    param1.api.getCellValue(param1.id, 'latestRemainingDistanceMeters') || 0
  const dist2 =
    param2.api.getCellValue(param2.id, 'latestRemainingDistanceMeters') || 0
  return gridNumberComparator(dist1, dist2, param1, param2)
}

function compareArrivalByTime(
  _: unknown,
  __: unknown,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
) {
  const getArrivalTime = (param: GridSortCellParams) =>
    new Date(param.api.getCellValue(param.id, 'arrivedAt') || 0).getTime()
  return gridNumberComparator(
    getArrivalTime(param1),
    getArrivalTime(param2),
    param1,
    param2,
  )
}

function makeWorkerDetailsColumns(
  isTransitTab: boolean,
  minNumSegments: number,
  tz: TimezonedDateFns,
) {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      flex: 2,
      minWidth: 100,
      renderCell: (params) => (
        <Link
          to={`/workers/${params.row.id}`}
          target="_blank"
          style={{ alignSelf: 'center', width: 100 }}
        >
          <Text
            variant="body1"
            style={{
              textAlign: 'left',
              width: 100,
            }}
          >
            <WorkerNameWithIcons
              name={params.row.workerName}
              maxLength={theme.space.xs}
              isFirstShiftWithCompany={
                params.row.isFirstTimeWorkerShiftWithCompany
              }
              isPaidBackup={params.row.isPaidBackup}
            />
          </Text>
        </Link>
      ),
    },
    {
      field: 'eta',
      headerName: 'ETA',
      flex: 1,
      minWidth: 190,
      sortable: true,
      sortComparator: compareEtaByTime,
      renderCell: (params) => {
        let color
        switch (params.row.expectedTransitResolution) {
          case TransitResolution.NOT_LEFT:
            color = theme.colors.Grey90
            break
          case TransitResolution.TOO_EARLY:
          case TransitResolution.ON_TIME:
            color = theme.colors.Green80
            break
          case TransitResolution.AT_RISK:
            color = theme.colors.Yellow70
            break
          case TransitResolution.LATE:
            color = theme.colors.Red80
            break
        }
        let resolution = 'No ETA'
        if (params.row.expectedTransitResolution) {
          resolution = params.row.expectedTransitResolution.replace('_', ' ')
        }
        const eta = getEta(
          params.row.latestRemainingMetricsTimestamp,
          params.row.latestRemainingDurationSeconds,
          tz,
        )
        return (
          <Row gap={theme.space.xxxs} alignCenter>
            <Text style={{ color: color }}>{`${resolution}: ${eta}`}</Text>
            {getTransitChangeIcon(params.row.etaChange)}
          </Row>
        )
      },
    },

    {
      field: 'latestStatus',
      headerName: 'Latest Status',
      flex: 1,
      minWidth: 140,
      sortComparator: compareLatestStatusByCriticalLevel,
      renderCell: (params) => {
        let latestStatus = 'No Status'
        if (params.row.latestTransitStatus) {
          latestStatus = params.row.latestTransitStatus.replace('_', ' ')
        }
        return <Text>{latestStatus}</Text>
      },
    },
    {
      field: 'originalDist',
      headerName: 'Original \n Dist.',
      flex: 1,
      minWidth: 100,
      sortComparator: compareOriginalDistance,
      renderCell: (params) => {
        let distanceInMiles = 0
        if (params.row.initialRemainingDistanceMeters) {
          distanceInMiles = kmToMiles(
            params.row.initialRemainingDistanceMeters / 1000,
          )
        }
        return <Text>{`${distanceInMiles.toFixed(2)} Mi`}</Text>
      },
    },
    {
      field: 'latestDist',
      headerName: 'Latest \n Dist.',
      flex: 1,
      minWidth: 50,
      sortComparator: compareLatestDistance,
      renderCell: (params) => {
        let distanceInMiles = 0
        if (params.row.latestRemainingDistanceMeters) {
          distanceInMiles = kmToMiles(
            params.row.latestRemainingDistanceMeters / 1000,
          )
        }
        return (
          <Row gap={theme.space.xxxs} alignCenter>
            <Text>{`${distanceInMiles.toFixed(2)} Mi`}</Text>{' '}
            {getTransitChangeIcon(params.row.remainingDistanceChange)}
          </Row>
        )
      },
    },
    ...(isTransitTab
      ? [
          {
            field: 'arrived',
            headerName: 'Arrived At',
            flex: 1,
            minWidth: 100,
            sortComparator: compareArrivalByTime,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Text>
                  {params.row.arrivedAt
                    ? tz.getTime(params.row.arrivedAt)
                    : '-'}
                </Text>
              )
            },
          },
        ]
      : []),
    {
      field: 'progress',
      headerName: 'Progress (each segment = 10 min)',
      flex: 10,
      minWidth: 500,
      sortable: false,
      renderCell: (params) => {
        return (
          <WorkerShiftTransitProgressBar
            workerShiftTransitHistory={params.row.transitHistory ?? []}
            minNumSegments={minNumSegments}
            initialDistance={params.row.initialRemainingDistanceMeters}
            tz={tz}
          />
        )
      },
    },
  ]

  return columns
}

function makeWorkerDetailsRow({
  worker,
  workerShiftTransit,
  isPaidBackup = false,
  isFirstTimeWorkerShiftWithCompany = false,
}: {
  worker: Worker
  workerShiftTransit?: WorkerShiftTransit
  isPaidBackup?: boolean
  isFirstTimeWorkerShiftWithCompany?: boolean
}) {
  const { uid, id, firstName, lastName } = worker
  const workerId = isPaidBackup ? id : uid
  return {
    id: workerId,
    workerId,
    workerName: `${firstName} ${lastName}`,
    isFirstTimeWorkerShiftWithCompany,
    isPaidBackup,
    latestRemainingDurationSeconds:
      workerShiftTransit?.latestRemainingDurationSeconds,
    latestRemainingDistanceMeters:
      workerShiftTransit?.latestRemainingDistanceMeters,
    latestRemainingMetricsTimestamp:
      workerShiftTransit?.latestRemainingMetricsTimestamp,
    initialRemainingDistanceMeters:
      workerShiftTransit?.initialRemainingDistanceMeters,
    remainingDistanceChange: workerShiftTransit?.remainingDistanceChange,
    etaChange: workerShiftTransit?.etaChange,
    expectedTransitResolution: workerShiftTransit?.expectedTransitResolution,
    latestTransitStatus: workerShiftTransit?.latestTransitStatus,
    transitHistory: workerShiftTransit?.workerShiftTransitHistory,
    arrivedAt: workerShiftTransit?.arrivedAt,
  }
}

function getTransitChangeIcon(transitMetricChange: TransitMetricChange) {
  switch (transitMetricChange) {
    case TransitMetricChange.INCREASING:
      return <TransitTableNegativeUpIcon />
    case TransitMetricChange.DECREASING:
      return <TransitTablePositiveDownIcon />
    case TransitMetricChange.STAGNANT:
    case TransitMetricChange.NOT_STARTED:
    default:
      return <TransitTableNeutralIcon />
  }
}

function makeTransitTabWorkerShiftRows(
  workerShifts: WorkerShiftForOps[],
  workerTransitByWorkerId: Record<string, WorkerShiftTransit>,
) {
  const processedWorkerIdSet = new Set<string>()
  const workerShiftRows = workerShifts
    .filter((ws) => ws.jobStatus !== JobStatus.Canceled)
    .map((ws) => {
      processedWorkerIdSet.add(ws.workerId)
      const workerShiftTransit = workerTransitByWorkerId?.[ws.workerId]
      return makeWorkerDetailsRow({
        worker: ws.worker,
        workerShiftTransit,
        isFirstTimeWorkerShiftWithCompany: ws.isFirstTimeWorkerShiftWithCompany,
      })
    })
  return {
    workerShiftRows,
    processedWorkerIdSet,
  }
}

function makeTransitNotificationWorkerShiftRows(
  workerShifts: WorkerShiftForOps[],
  workerTransitByWorkerId: Record<string, WorkerShiftTransit>,
) {
  const processedWorkerIdSet = new Set<string>()
  const workerShiftRows = workerShifts
    .filter((ws) => {
      const workerShiftTransit = workerTransitByWorkerId?.[ws.workerId]
      return (
        ws.jobStatus === JobStatus.ToDo &&
        !!workerShiftTransit &&
        !workerShiftTransit.arrivedAt
      )
    })
    .map((ws) => {
      processedWorkerIdSet.add(ws.workerId)
      const workerShiftTransit = workerTransitByWorkerId?.[ws.workerId]
      return makeWorkerDetailsRow({
        worker: ws.worker,
        workerShiftTransit,
        isFirstTimeWorkerShiftWithCompany: ws.isFirstTimeWorkerShiftWithCompany,
      })
    })
  return {
    workerShiftRows,
    processedWorkerIdSet,
  }
}

function makeTransitTabPaidBackupRows(
  paidBackups: PaidBackup[],
  workerTransitByWorkerId: Record<string, WorkerShiftTransit>,
) {
  const paidBackupRows = paidBackups
    .filter((pb) => pb.status !== PaidBackupStatus.CANCELED)
    .map((pb) => {
      const workerShiftTransit = workerTransitByWorkerId?.[pb.workerId]
      return makeWorkerDetailsRow({
        worker: pb.worker,
        workerShiftTransit,
        isPaidBackup: true,
      })
    })
  return paidBackupRows
}

function makeTransitNotificationPaidBackupRows(
  paidBackups: PaidBackup[],
  workerTransitByWorkerId: Record<string, WorkerShiftTransit>,
) {
  const paidBackupRows = paidBackups
    .filter((pb) => {
      const workerShiftTransit = workerTransitByWorkerId?.[pb.workerId]
      return (
        pb.status === PaidBackupStatus.TO_DO &&
        !!workerShiftTransit &&
        !workerShiftTransit.arrivedAt
      )
    })
    .map((pb) => {
      const workerShiftTransit = workerTransitByWorkerId?.[pb.workerId]
      return makeWorkerDetailsRow({
        worker: pb.worker,
        workerShiftTransit,
        isPaidBackup: true,
      })
    })
  return paidBackupRows
}
export function SentinelWorkerTransitsTable({
  workerShifts,
  handleSelectWorkers,
  enableSelection = true,
  isTransitTab = false,
  shiftId,
  slim,
}: {
  workerShifts: WorkerShiftForOps[]
  handleSelectWorkers?: (workersSelected: GridRowSelectionModel) => void
  enableSelection?: boolean
  isTransitTab?: boolean
  shiftId: string
  slim?: boolean
}) {
  const { isLoading, workerShiftTransitByWorkerId: workerTransitByWorkerId } =
    useWorkerShiftTransit(shiftId)

  const { shift } = useShift(shiftId)
  const { paidBackups = [] } = usePaidBackups(shiftId)
  const tz = useTimezonedDates(shift?.timezone)

  const { rows, maxNumSegmentsInWorkerShifts } = useMemo(() => {
    const { workerShiftRows, processedWorkerIdSet } = isTransitTab
      ? makeTransitTabWorkerShiftRows(
          workerShifts,
          workerTransitByWorkerId ?? {},
        )
      : makeTransitNotificationWorkerShiftRows(
          workerShifts,
          workerTransitByWorkerId ?? {},
        )

    const filteredPaidBackups = paidBackups.filter(
      (pb) => !processedWorkerIdSet.has(pb.workerId),
    )

    const paidBackupRows = isTransitTab
      ? makeTransitTabPaidBackupRows(
          filteredPaidBackups,
          workerTransitByWorkerId ?? {},
        )
      : makeTransitNotificationPaidBackupRows(
          filteredPaidBackups,
          workerTransitByWorkerId ?? {},
        )

    const rows = [...workerShiftRows, ...paidBackupRows]
    let maxNumSegmentsInWorkerShifts = 0
    for (const workerId in workerTransitByWorkerId) {
      const historyLength =
        workerTransitByWorkerId[workerId].workerShiftTransitHistory?.length ?? 0
      if (historyLength > maxNumSegmentsInWorkerShifts) {
        maxNumSegmentsInWorkerShifts = historyLength
      }
    }
    return { rows, maxNumSegmentsInWorkerShifts }
  }, [workerShifts, paidBackups, workerTransitByWorkerId, isTransitTab])

  const columns = useMemo(
    () =>
      makeWorkerDetailsColumns(isTransitTab, maxNumSegmentsInWorkerShifts, tz),
    [isTransitTab, maxNumSegmentsInWorkerShifts, tz],
  )
  return (
    <Row
      style={{
        height: slim ? 'auto' : 300,
        width: '100%',
        overflow: 'auto',
      }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <DataGrid
          {...SentinelTableDataGridStyle}
          rows={rows}
          columns={columns}
          checkboxSelection={enableSelection}
          onRowSelectionModelChange={handleSelectWorkers}
          localeText={{
            footerRowSelected: (count: number) =>
              `${count} worker${makePlural(count)} selected`,
          }}
        />
      )}
    </Row>
  )
}
