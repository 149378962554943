import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus } from '@traba/types'
import { WorkerTier } from '@traba/types'
import { Worker } from '@traba/types'
import { Link, useParams } from 'react-router-dom'
import {
  AccountStatusBadge,
  Button,
  Col,
  CopyTextIcon,
  Icon,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DotText } from 'src/components/base/DotText'
import { PageHeader } from 'src/components/base/PageHeader'
import { useApi } from 'src/hooks/useApi'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import AuditLogsScreen from './AuditLogsScreen'

export default function WorkerLogsScreen() {
  const { workerId = '' } = useParams<{ workerId: string }>()
  const { data: worker, isLoading: isLoadingWorker } = useApi<
    Worker & AccountStatus
  >(
    `/workers/${workerId}/worker-and-profile`,
    {},
    { staleTime: FIVE_MINUTES_IN_MS },
  )
  const { data: workerTier, isLoading: isLoadingWorkerTier } = useApi<{
    tier: WorkerTier
  }>(`/workers/${workerId}/tier`, {}, { staleTime: FIVE_MINUTES_IN_MS })

  if (isLoadingWorker || isLoadingWorkerTier) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="large" />
      </Row>
    )
  }
  if (!worker) {
    return <div>Worker not found</div>
  }

  const WorkerLogsHeader = () => {
    return (
      <PageHeader
        avatarString={worker.firstName}
        avatarUrl={worker.photoUrl}
        children={
          <Col ml={theme.space.xs}>
            <Row alignCenter>
              <Link to={`/workers/${workerId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  <Text variant="h4">
                    {worker.firstName} {worker.lastName}
                  </Text>
                </Button>
              </Link>
              <Row alignCenter ml={theme.space.xs}>
                <AccountStatusBadge
                  accountStatus={worker.accountStatus}
                  approvalStatusReason={worker.approvalStatusReason}
                  accountSuspensionExpiration={worker.suspensionExpiration}
                />
              </Row>
            </Row>
            <Row alignCenter mr={theme.space.xs}>
              <Text variant="h7">Worker Id: {workerId}</Text>
              <CopyTextIcon textToCopy={workerId} />
            </Row>
            <Row alignCenter>
              <Text
                variant="body1"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  name="calendar_active"
                  style={{ height: '17px', marginLeft: 2, marginRight: 6 }}
                />
                Joined on: {formatDateTimeWithTimezone(worker.createdAt)}
              </Text>
              <DotText />
              <Text variant="body1">
                {workerTier ? `${workerTier.tier}` : '-'}
              </Text>
            </Row>
          </Col>
        }
      />
    )
  }

  return (
    <AuditLogsScreen
      headerContent={<WorkerLogsHeader />}
      logType="WORKER"
      searchId={workerId}
    />
  )
}
