export function VolumeCross({ color = '#1AB373' }: { color: string }) {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.888 7.00638H10.1502C9.32063 7.00638 8.497 7.15311 7.71367 7.44046C6.34652 7.94197 5.35162 9.1935 5.12369 10.6985L5.11618 10.7481C4.99057 11.5775 4.99057 12.4225 5.11618 13.2519L5.12369 13.3015C5.35162 14.8065 6.34652 16.058 7.71367 16.5595C8.497 16.8469 9.32063 16.9936 10.1502 16.9936H10.888C11.3329 16.9936 11.7592 17.1811 12.0719 17.5142L12.6134 18.091C14.2098 19.7918 16.9599 18.9873 17.4939 16.6633C18.198 13.5986 18.198 10.4014 17.4939 7.33668C16.9599 5.01271 14.2098 4.20823 12.6134 5.90899L12.0719 6.48581C11.7592 6.81891 11.3329 7.00638 10.888 7.00638Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M25.022 10L21.022 14M25.022 14L21.022 10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
