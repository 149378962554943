import { WorkerShiftEligibility } from '@traba/types'
import { useEffect, useState, useMemo } from 'react'
import { fetchEligibilities } from 'src/hooks/useWorkerShiftsEligibilities'
import { WorkerOnShift } from '../types'

export const useEligibilitiesForWorkers = (
  selectedShifts: Record<string, string[]>,
  workerIds: string[],
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [workersEligibilities, setWorkersEligibilities] = useState<
    WorkerShiftEligibility[]
  >([])
  const [workersAlreadyOnShifts, setWorkersAlreadyOnShifts] = useState<
    WorkerOnShift[]
  >([])

  const selectedShiftIds = useMemo(
    () => Object.values(selectedShifts).flat(),
    [selectedShifts],
  )

  useEffect(() => {
    const fetchEligibilityAndWorkerData = async () => {
      if (workerIds.length === 0 || !selectedShiftIds) {
        return
      }

      try {
        setIsLoading(true)
        const eligibilityPromises = selectedShiftIds.map((shiftId: string) =>
          fetchEligibilities(workerIds, shiftId),
        )
        const eligibilityResponses = await Promise.all(eligibilityPromises)
        const eligibilityData = eligibilityResponses.flatMap(
          (response) => response,
        )
        setWorkersEligibilities(eligibilityData)

        // check for workers already on shift
        const workersAlreadyOnShift = eligibilityData
          .filter(
            (eligibility) =>
              eligibility.workerAlreadySignedUpForShift &&
              !eligibility.workerAlreadySignedUpForPaidBackup,
          )
          .map((eligibility) => ({
            workerId: eligibility.workerId,
            shiftId: eligibility.shiftId,
            paidBackup: eligibility.workerAlreadySignedUpForPaidBackup,
          }))

        setWorkersAlreadyOnShifts(workersAlreadyOnShift)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setIsLoading(false)
      }
    }

    fetchEligibilityAndWorkerData()
  }, [selectedShiftIds, JSON.stringify(workerIds)])

  return {
    isLoading,
    workersEligibilities,
    workersAlreadyOnShifts,
  }
}
