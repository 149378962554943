import { CircularProgress, Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback } from 'react'
import { Icon, Row } from '../../base'

type UnreadSentinelNotificationLabelProps = {
  sentinelNotificationCount: number
  onClickLabel: () => void
  isMuted: boolean
  isLoading: boolean
}

export const UnreadSentinelNotificationLabel = (
  props: UnreadSentinelNotificationLabelProps,
) => {
  const { sentinelNotificationCount, onClickLabel, isMuted, isLoading } = props

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      onClickLabel()
    },
    [onClickLabel],
  )

  if (!sentinelNotificationCount && !isMuted) {
    return null
  }

  if (isLoading) {
    return <CircularProgress size={theme.space.med} />
  }

  return (
    <Row
      justifyCenter
      alignCenter
      style={{
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: !isMuted
          ? theme.colors.Violet50
          : theme.colors.Violet20,
        color: theme.colors.Violet,
        ...(!isMuted && { backgroundColor: theme.colors.Violet50 }),
      }}
      ml={theme.space.xxs}
      onClick={handleClick}
    >
      <Tooltip
        title={
          isMuted
            ? 'Sentinel notifications muted'
            : `${sentinelNotificationCount} shift notifications`
        }
      >
        <div
          onClick={(e: any) => {
            e.preventDefault()
          }}
        >
          {isMuted ? (
            <Icon
              type="svg"
              name="volumeSlash"
              color={theme.colors.Grey50}
              yOffset={-3}
            />
          ) : (
            <Text variant="h6" style={{ color: 'white' }}>
              {sentinelNotificationCount}
            </Text>
          )}
        </div>
      </Tooltip>
    </Row>
  )
}
