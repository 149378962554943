import { useUserContext } from 'src/context/user/UserContext'

export const useAnalytics = () => {
  const { state } = useUserContext()

  const useTrackAnalytics = (event: string, properties?: any) => {
    window.analytics.track(event, {
      initiatedBy: state.userProfile?.email,
      ...properties,
    })
  }

  return { trackAnalytics: useTrackAnalytics }
}
