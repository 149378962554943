import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { addDays, addMinutes } from 'date-fns'
import React, { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { Button, Col, DatePicker, Input, Row, Select } from '../../base'
import { useModal } from '../../base/Modal/Modal'
import * as S from '../styles'
import { DEFAULT_MAX_WORKERS } from './ActionsSection'
import BugleScheduleModal from './BugleScheduleModal'

interface PushInputProps {
  parameters: any
  targetShiftId: string
  targetCompanyId: string
  savedSearchId?: string
  disabled?: boolean
  totalFound?: number
  sentinelNotificationToUserId?: string
}

export const TITLE_LOCAL_STORAGE_KEY = 'bugle_push_input_title'
export const SCREEN_LOCAL_STORAGE_KEY = 'bugle_push_input_screen'
export const BODY_LOCAL_STORAGE_KEY = 'bugle_push_input_body'

const PushInput: React.FC<PushInputProps> = ({
  parameters,
  savedSearchId,
  targetShiftId,
  targetCompanyId,
  disabled,
  totalFound,
  sentinelNotificationToUserId,
}) => {
  // from https://github.com/trabapro/traba-server-node/blob/3dca0d33e55f6dd84b0305aef40772e4a9876cc1/src/models/communications/dto/bugle.ts#L64

  const SCREEN_OPTIONS = [
    {
      value: 'ShiftDetails',
      label: 'Shift Details Page',
    },
    {
      value: 'PersonalInformation',
      label: 'Personal Information Page',
    },
  ]

  const bodyLocalStorageFormat = `${BODY_LOCAL_STORAGE_KEY}_${targetShiftId}`
  const titleLocalStorageFormat = `${TITLE_LOCAL_STORAGE_KEY}_${targetShiftId}`
  const screenLocalStorageFormat = `${SCREEN_LOCAL_STORAGE_KEY}_${targetShiftId}`

  const [title, setTitle] = useState<string>(
    localStorage.getItem(titleLocalStorageFormat) || '',
  )
  const [screen, setScreen] = useState<string>(
    localStorage.getItem(screenLocalStorageFormat) || '',
  )
  const [body, setBody] = useState<string>(
    localStorage.getItem(bodyLocalStorageFormat) || '',
  )
  const [date, setDate] = useState<Date | null>(new Date())
  const confirmationModal = useModal()

  const [maxWorkers, setMaxWorkers] = useState<number | undefined>(
    DEFAULT_MAX_WORKERS,
  )

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTitle = e.target.value
    setTitle(newTitle)
    localStorage.setItem(titleLocalStorageFormat, newTitle)
  }

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value
    setBody(newMessage)
    localStorage.setItem(bodyLocalStorageFormat, newMessage)
  }

  const handleScreenChange = (newScreen: string) => {
    setScreen(newScreen)
    localStorage.setItem(screenLocalStorageFormat, newScreen)
  }

  const handleSendClick = () => {
    confirmationModal.open()
  }

  const pushData = {
    title,
    body,
    screen,
  }

  return (
    <div>
      <Row>
        <Col style={{ marginRight: theme.space.xs }}>
          <Input
            style={{ height: theme.space.lg }}
            width="100%"
            label="Title"
            onChange={handleTitleChange}
            value={title}
          />
        </Col>
        <Col>
          <Select
            dropdownStyle={{
              height: theme.space.lg,
              overflow: 'hidden',
            }}
            label="Screen"
            menuItems={SCREEN_OPTIONS}
            value={screen}
            handleSelect={handleScreenChange}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: theme.space.xs }}>
        <S.TextInput
          label="Body"
          type="textarea"
          onChange={handleBodyChange}
          value={body}
        />
      </Row>
      <Row fullWidth justifyBetween alignCenter>
        <Col mr={theme.space.xs}>
          <div style={{ marginTop: theme.space.xs }}>
            <DatePicker
              date={date}
              setDate={setDate}
              label="Schedule time"
              showTimeFieldInPopover
              granularity="minute"
              minDate={addMinutes(new Date(), -1)}
              maxDate={addDays(new Date(), 14)}
            />
          </div>
        </Col>

        <Col ml={theme.space.xs}>
          <DeprecatedNumberInput
            label={`Max workers (Default: ${DEFAULT_MAX_WORKERS})`}
            setter={(value) => {
              setMaxWorkers(value || undefined)
            }}
            value={maxWorkers}
          />
          {!!totalFound && (
            <Text variant="caption" ml={theme.space.xxxs}>
              Message
              {maxWorkers
                ? ` up to ${maxWorkers} `
                : ` approximately ${totalFound} `}
              workers
            </Text>
          )}
          <Button
            variant={ButtonVariant.FILLED}
            style={{ width: 220 }}
            onClick={handleSendClick}
            mt={theme.space.xxs}
            disabled={disabled || !body || !date || !title || !screen}
          >
            Schedule pushes
          </Button>
        </Col>
      </Row>
      {date && (
        <BugleScheduleModal
          {...confirmationModal}
          bugleType={'PUSH'}
          pushData={pushData}
          parameters={parameters}
          savedSearchId={savedSearchId}
          targetShiftId={targetShiftId}
          targetCompanyId={targetCompanyId}
          targetDate={date}
          listCount={totalFound}
          maxWorkers={maxWorkers}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      )}
    </div>
  )
}

export default PushInput
