import { Checkbox, Tooltip } from '@mui/material'
import { Row, SvgIcon, Button, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WorkerIncentive,
  WorkerIncentiveReviewAction,
  WorkerIncentiveStatus,
} from '@traba/types'
import { useCallback } from 'react'
import { getMoneyString } from 'src/utils/stringUtils'
import { PendingIncentiveItemHeader } from './PendingIncentiveItemHeaderInfo'
import { PendingIncentiveItemTable } from './PendingIncentiveItemTable'
import { CollapseButton, PendingIncentiveItemWrapper } from './styles'

interface PendingIncentiveItemProps {
  pendingIncentive: WorkerIncentive
  isLoading?: boolean
  isSelected?: boolean
  onSelect?: () => void
  disableSelectMore?: boolean
  disableSelectMoreText?: string
  handleActionSubmission: (
    workerIncentive: WorkerIncentive,
    action: WorkerIncentiveReviewAction,
  ) => void
  handleExpandClick: (wi: string) => void
  collapsed: boolean
}

export function PendingIncentiveItem({
  pendingIncentive,
  isLoading,
  isSelected,
  onSelect,
  disableSelectMore,
  disableSelectMoreText,
  handleActionSubmission,
  handleExpandClick,
  collapsed,
}: PendingIncentiveItemProps) {
  const handleCollapseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      handleExpandClick(
        pendingIncentive.id ?? pendingIncentive.workerIncentiveId,
      )
    },
    [handleExpandClick, pendingIncentive.id],
  )

  const handleDismiss = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleActionSubmission(
        pendingIncentive,
        WorkerIncentiveReviewAction.DISMISS,
      )
      event.stopPropagation()
    },
    [handleActionSubmission, pendingIncentive],
  )

  const handlePayout = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleActionSubmission(
        pendingIncentive,
        WorkerIncentiveReviewAction.PAYOUT,
      )
      event.stopPropagation()
    },
    [handleActionSubmission, pendingIncentive],
  )

  const handleReverse = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleActionSubmission(
        pendingIncentive,
        WorkerIncentiveReviewAction.REVERSE,
      )
      event.stopPropagation()
    },
    [handleActionSubmission, pendingIncentive],
  )
  const shouldPayout =
    pendingIncentive.status === WorkerIncentiveStatus.Complete &&
    (!pendingIncentive.transactions ||
      pendingIncentive.transactions.length === 0)

  return (
    <PendingIncentiveItemWrapper>
      <label htmlFor={`checkbox-${pendingIncentive.id}`}>
        <Row
          fullWidth
          alignCenter
          justifyBetween
          style={onSelect ? { cursor: 'pointer' } : undefined}
        >
          <Row gap={theme.space.xxs}>
            {onSelect && (
              <Tooltip
                title={
                  disableSelectMore && !isSelected
                    ? disableSelectMoreText
                    : undefined
                }
              >
                <div>
                  <Checkbox
                    id={`checkbox-${pendingIncentive.id}`}
                    onChange={onSelect}
                    checked={isSelected}
                    disabled={disableSelectMore && !isSelected}
                  />
                </div>
              </Tooltip>
            )}

            <PendingIncentiveItemHeader pendingIncentive={pendingIncentive} />
          </Row>

          <Row gap={theme.space.xs} justifyEnd>
            <Button
              variant={
                shouldPayout ? ButtonVariant.FILLED : ButtonVariant.OUTLINED
              }
              disabled={isLoading}
              slim
              style={{ width: 150 }}
              leftIcon={
                isLoading || shouldPayout ? undefined : <SvgIcon name="undo" />
              }
              onClick={shouldPayout ? handlePayout : handleReverse}
            >
              {(isLoading ? '...' : shouldPayout ? 'Payout ' : 'Reverse ') +
                getMoneyString(pendingIncentive.total)}
            </Button>
            <Button
              variant={ButtonVariant.OUTLINED}
              slim
              leftIcon={isLoading ? undefined : <SvgIcon name="cancel" />}
              style={{ width: 120 }}
              onClick={handleDismiss}
            >
              {isLoading ? '...' : 'Dismiss'}
            </Button>
            <CollapseButton onClick={handleCollapseClick}>
              <SvgIcon
                name={collapsed ? 'chevronDown' : 'chevronUp'}
                size={12}
                color={theme.colors.Grey50}
              />
            </CollapseButton>
          </Row>
        </Row>
      </label>

      <PendingIncentiveItemTable
        pendingIncentive={pendingIncentive}
        collapsed={collapsed}
      />
    </PendingIncentiveItemWrapper>
  )
}
