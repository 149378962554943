import { Row } from '@traba/react-components'
import { truncateString } from '@traba/utils'
import { useMemo } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import { useWorkerDrugScreeningResults } from 'src/hooks/useDrugScreening'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'

interface DrugScreenTabProps {
  workerId: string
}

const DRUG_SCREENING_RESULTS_HEADERS: DataTableHeader[] = [
  {
    key: 'createdAt',
    label: 'Tested At',
    sortable: true,
  },
  {
    key: 'expiresAt',
    label: 'Test Expiry',
    sortable: true,
  },
  {
    key: 'id',
    label: 'Result ID',
  },
  {
    key: 'drugPanelType',
    label: 'Type',
    sortable: true,
  },
  {
    key: 'drugScreeningResultStatus',
    label: 'Result Status',
    sortable: true,
  },
  {
    key: 'requestingCompanyName',
    label: 'Company',
    sortable: true,
  },
  {
    key: 'verificationStatus',
    label: 'Ops Approval Status',
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: 'Last Updated',
    sortable: true,
  },
  {
    key: 'resultFileUrl',
    label: 'Report',
  },
]

export default function WorkerDrugScreeningResults({
  workerId,
}: DrugScreenTabProps) {
  const { isLoading, data: drugScreeningResults } =
    useWorkerDrugScreeningResults(workerId)

  const tableRows = useMemo(
    () =>
      drugScreeningResults?.map((drugScreen) => {
        const {
          id,
          drugPanelType,
          drugScreeningResultStatus,
          createdAt,
          expiresAt,
          verificationStatus,
          requestingCompanyName,
          updatedAt,
          resultFileUrl,
        } = drugScreen

        return {
          key: id,
          cells: [
            {
              renderFn: () => formatDateTimeWithTimezone(createdAt),
              sortKey: new Date(createdAt).getTime(),
            },
            {
              renderFn: () => formatDateTimeWithTimezone(expiresAt),
              sortKey: new Date(expiresAt ?? '').getTime(),
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    {truncateString(id ?? '')}
                    <CopyTextIcon textToCopy={id} />
                  </Row>
                )
              },
            },
            {
              renderFn: () => drugPanelType,
              sortKey: drugPanelType,
            },
            {
              renderFn: () => drugScreeningResultStatus,
              sortKey: drugScreeningResultStatus,
            },
            {
              renderFn: () => requestingCompanyName,
              sortKey: requestingCompanyName,
            },
            {
              renderFn: () => verificationStatus,
              sortKey: verificationStatus,
            },
            {
              renderFn: () => formatDateTimeWithTimezone(updatedAt),
              sortKey: new Date(updatedAt ?? '').getTime(),
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    {resultFileUrl ? (
                      <a
                        href={
                          resultFileUrl.startsWith('http')
                            ? resultFileUrl
                            : `https://${resultFileUrl}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Report
                      </a>
                    ) : (
                      'No Report'
                    )}
                  </Row>
                )
              },
            },
          ],
        }
      }) ?? [],
    [drugScreeningResults],
  )

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  if (drugScreeningResults && drugScreeningResults.length === 0) {
    return <Row>No results found</Row>
  }

  return <DataTable rows={tableRows} headers={DRUG_SCREENING_RESULTS_HEADERS} />
}
