import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BugleBlastStatus, BugleMessageType, Shift } from '@traba/types'
import { compact } from 'lodash'
import { useState } from 'react'
import { getMoneyString } from '../../../utils/stringUtils'
import { Col, Row } from '../../base'

import BugleStatisticsDistributionBar from '../components/BugleStatisticsDistributionBar'
import BugleStatisticsRow from '../components/BugleStatisticsRow'
import useBugleSchedule from '../hooks/useBugleSchedule'
import { useBugleStatisticsByShiftId } from '../hooks/useBugleStatistics'
import * as S from '../styles'

type BugleStatisticsScreenProps = {
  shift: Shift
}

export function BugleStatisticsScreen({ shift }: BugleStatisticsScreenProps) {
  const [previewBugleId, setPreviewBugleId] = useState<string | undefined>()

  const { isLoading, data: bugleStatistics } = useBugleStatisticsByShiftId(
    shift.shiftId,
  )

  const {
    isFetchingBugleSchedule,
    isFetchingSearchParams,
    searchParams,
    bugleSchedule,
  } = useBugleSchedule({
    shiftId: shift.shiftId,
  })

  if (isLoading || isFetchingBugleSchedule || isFetchingSearchParams) {
    return (
      <S.StatisticsContainer>
        <Row fullHeight fullWidth alignCenter justifyCenter>
          <CircularProgress />
        </Row>
      </S.StatisticsContainer>
    )
  }

  if (!bugleStatistics) {
    return (
      <S.StatisticsContainer>
        <Row fullHeight fullWidth alignCenter justifyCenter>
          <Text variant="h6">No bugles have been sent for this shift</Text>
        </Row>
      </S.StatisticsContainer>
    )
  }

  const handleSetSelection = (bugleId?: string) =>
    setPreviewBugleId(bugleId === previewBugleId ? undefined : bugleId)

  const bugleWithParams = compact(
    Object.values(bugleStatistics?.bugleBlasts || {}).map((bugleBlastStats) => {
      const bugleBlast = bugleSchedule?.find(
        (bugleBlastDoc) =>
          bugleBlastDoc.bugleBlastId === bugleBlastStats.bugleBlastId,
      )
      if (!bugleBlast) {
        return undefined
      }

      const params = searchParams.find(
        (param) => param.searchParamsId === bugleBlast.searchParamsId,
      )
      return {
        bugleStatistics: bugleBlastStats.statistics,
        params,
        ...bugleBlast,
      }
    }),
  ).filter(
    (b) =>
      b.status === BugleBlastStatus.COMPLETED &&
      b.message.type === BugleMessageType.SMS,
  )

  const hasBuglesInProgress = bugleSchedule?.find(
    (b) => b.status === BugleBlastStatus.IN_PROGRESS,
  )

  return (
    <S.StatisticsContainer>
      <Row alignStart fullWidth pb={theme.space.xs} alignEnd>
        <Text variant="h3">Bugle SMS statistics</Text>
      </Row>
      <Row
        style={{
          height: 235,
          width: '100%',
          border: `1px solid ${theme.border.color}`,
          borderRadius: theme.border.radius,
        }}
      >
        <Row>
          <Row
            style={{
              borderRight: `1px solid ${theme.border.color}`,
              width: 200,
            }}
            px={theme.space.xs}
            py={theme.space.xs}
            flexCol
            justifyBetween
          >
            <Col>
              <Text>Workers notified</Text>
              <Text variant="h4">
                {bugleStatistics.aggregatedStatistics.messagesSent}
              </Text>
            </Col>
            <Col>
              <Text>No. of bugles</Text>
              <Text variant="h4">
                {Object.keys(bugleStatistics.bugleBlasts).length}
              </Text>
            </Col>
            <Col>
              <Text>Total cost</Text>
              <Text variant="h4">
                {getMoneyString(
                  Math.abs(bugleStatistics.aggregatedStatistics.messagesCost),
                )}
              </Text>
            </Col>
          </Row>
        </Row>
        <Row fullWidth alignCenter>
          <BugleStatisticsDistributionBar
            bugleStatistics={bugleStatistics.aggregatedStatistics}
            showLabels
            inProgressBugles={!!hasBuglesInProgress}
            totalSent={bugleStatistics.aggregatedStatistics.messagesSent}
          />
        </Row>
      </Row>
      <Row alignStart fullWidth py={theme.space.xs}>
        <Text variant="h3">Completed Bugles</Text>
      </Row>
      <ul
        style={{
          width: '100%',
        }}
      >
        {bugleWithParams.map((bugle) => (
          <BugleStatisticsRow
            key={bugle.bugleBlastId}
            bugle={bugle}
            onToggle={() => handleSetSelection(bugle.bugleBlastId)}
            isExpanded={previewBugleId === bugle.bugleBlastId}
          />
        ))}
      </ul>
    </S.StatisticsContainer>
  )
}
