import { Text } from '@traba/react-components'
import { AcceptShiftBypassSetting } from '@traba/types'
import { GenericAcceptShiftBypasses } from '@traba/types'
import { WorkerShiftEligibility } from '@traba/types'
import { getMapFromBypassSettings } from 'src/utils/workerEligibilityUtils'

export type MissingCriteriaProps = {
  eligibility?: WorkerShiftEligibility
  bypasses: GenericAcceptShiftBypasses
  acceptShiftBypassSettings: AcceptShiftBypassSetting[]
}

export default function MissingCriteria({
  eligibility,
  bypasses,
  acceptShiftBypassSettings,
}: MissingCriteriaProps) {
  if (!eligibility) {
    return null
  }
  const criteriaMap = getMapFromBypassSettings(acceptShiftBypassSettings)
  return (
    <>
      {Object.entries(eligibility?.eligibilityCriteria)
        .filter(([_, value]) => !value)
        .map(([key, criteriaValue]) => {
          const bypass = criteriaMap.get(key)
          if (!bypass) {
            return (
              <Text
                variant="error"
                style={{
                  fontSize: 10,
                  lineHeight: 'unset',
                }}
              >
                {key}
              </Text>
            )
          }
          const isCriteriaBypassed =
            !!bypass.bypassKey && bypasses[bypass.bypassKey]

          if (!criteriaValue && !isCriteriaBypassed) {
            return (
              <Text
                variant="error"
                style={{
                  fontSize: 10,
                  lineHeight: 'unset',
                }}
              >
                {bypass.label ?? key}
              </Text>
            )
          }
          return null
        })}
    </>
  )
}
