const complaintDisplayNames: { [key: string]: string } = {
  invalid_clock_times: 'Invalid Clock In/Out Times',
  other: 'Other: Unclassified',
  other_business_charge: 'Other: Business Charge',
  other_business_discount: 'Other: Business Discount',
  other_worker_comp: 'Other: Worker Comp',
  theft_damages: 'Theft or Damages',
}

export function complaintDisplayName(complaintId: string): string {
  const displayName =
    complaintDisplayNames[complaintId] ?? complaintId.replaceAll('_', ' ')

  return displayName.toUpperCase()
}
