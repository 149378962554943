import { css } from 'styled-components'
import { TrabaTheme, addPixelUnit } from './theme'

export type SpacingProps = {
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  m?: string | number
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
  p?: string | number
}

export const spacingStyles = (
  props: SpacingProps & { theme: TrabaTheme },
) => css`
  /* Margins */
  margin-top: ${addPixelUnit(props.mt ?? props.my ?? props.m ?? 0)};
  margin-bottom: ${addPixelUnit(props.mb ?? props.my ?? props.m ?? 0)};
  margin-left: ${addPixelUnit(props.ml ?? props.mx ?? props.m ?? 0)};
  margin-right: ${addPixelUnit(props.mr ?? props.mx ?? props.m ?? 0)};

  /* Paddings */
  padding-top: ${addPixelUnit(props.pt ?? props.py ?? props.p ?? 0)};
  padding-bottom: ${addPixelUnit(props.pb ?? props.py ?? props.p ?? 0)};
  padding-left: ${addPixelUnit(props.pl ?? props.px ?? props.p ?? 0)};
  padding-right: ${addPixelUnit(props.pr ?? props.px ?? props.p ?? 0)};
`
