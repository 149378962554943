import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { useState } from 'react'
import { Col, Modal, Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatDurationBetweenDates } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { useAnalytics } from '../../hooks/useAnalytics'

type ClockInModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
  sentinelNotificationToUserId?: string
}

export default function ClockInModal({
  isOpen,
  handleClose,
  workerShift,
  sentinelNotificationToUserId,
}: ClockInModalProps) {
  const [clockInTime, setClockInTime] = useState<Date>(
    workerShift.clockInTime
      ? new Date(workerShift.clockInTime)
      : new Date(workerShift.shiftInfo.startTime),
  )

  const { refetchActiveQueries } = useActiveQueries()
  const curClockIn = workerShift?.clockInTime
    ? new Date(workerShift?.clockInTime)
    : undefined

  const timeAdjustedString = formatDurationBetweenDates(
    curClockIn || new Date(),
    clockInTime,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const { showSuccess, showError } = useAlert()
  const { trackAnalytics } = useAnalytics()
  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const handleClockIn = async () => {
    const suffix = curClockIn ? '' : '/start-shift'
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}${suffix}`,
        {
          clockInTime: clockInTime,
          sentinelNotificationToUserId,
        },
      )
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken ClockIn', {
          sentinelNotificationToUserId,
          clockInTime,
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
        })
      }
      showSuccess(
        `${curClockIn ? 'Updated clock-in' : 'Clocked in worker'} successfully`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Clocking In Worker')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Update Clock In'}
      size={MODAL_SIZE.LARGE}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col>
          <Row flexCol style={{ marginBottom: '16px' }}>
            <Text variant="h5">Original</Text>
            <Row justifyBetween style={{ marginTop: '24px' }}>
              <Col>
                <Text variant="caption">SHIFT LOCAL TIME</Text>
                <Text style={{ marginTop: '16px' }} variant="body1">
                  {curClockIn
                    ? `${tz.getDate(curClockIn)}, ${tz.getTime(curClockIn)}`
                    : '-'}
                </Text>
              </Col>
            </Row>
          </Row>
          <Row flexCol style={{ marginTop: '32px' }}>
            <Text variant="h5">New</Text>
            <Row justifyBetween style={{ marginTop: '24px' }}>
              <Col>
                <Text style={{ marginBottom: '16px' }} variant="caption">
                  SHIFT LOCAL TIME
                </Text>
                <div style={{ width: '80%' }}>
                  <DatePicker
                    showTimeFieldInPopover={true}
                    setDate={(clockInTime) => {
                      if (clockInTime) {
                        setClockInTime(clockInTime)
                      }
                    }}
                    isClearable={false}
                    inlineLabel={true}
                    label="Time"
                    date={clockInTime}
                    timezone={workerShift.shiftInfo.timezone}
                  />
                </div>
              </Col>
            </Row>
          </Row>
          {!!workerShift?.clockInTime && (
            <Row style={{ marginTop: '50px' }}>
              <Text style={{ marginRight: '8px' }} variant="h7">
                Time Adjusted:
              </Text>
              <Text variant="h5">{` ${timeAdjustedString}`}</Text>
            </Row>
          )}
        </Col>
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={handleClockIn}
          loading={loading}
        />
      </Col>
    </Modal>
  )
}
