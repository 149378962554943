export function Copy({
  color = '#7A8A99',
  size = 24,
  ...props
}: {
  size: number
  color: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.42631 10.2682C5.06441 8.72538 5.06441 7.11974 5.42631 5.57691C5.92742 3.44059 7.59548 1.77254 9.7318 1.27142C11.2746 0.909526 12.8803 0.909525 14.4231 1.27142C16.5594 1.77254 18.2275 3.44059 18.7286 5.57692C19.0905 7.11974 19.0905 8.72538 18.7286 10.2682C18.2275 12.4045 16.5594 14.0726 14.4231 14.5737C12.8803 14.9356 11.2746 14.9356 9.7318 14.5737M5.42631 10.2682C5.92742 12.4045 7.59548 14.0726 9.7318 14.5737M5.42631 10.2682C5.19844 9.29678 5.11405 8.30045 5.17313 7.31057C5.08958 7.32762 5.00622 7.3459 4.92307 7.36541C3.09194 7.79493 1.66217 9.22469 1.23265 11.0558C0.92245 12.3782 0.92245 13.7545 1.23265 15.0769C1.66217 16.9081 3.09194 18.3378 4.92307 18.7674C6.24549 19.0775 7.62175 19.0775 8.94417 18.7674C10.7753 18.3378 12.2051 16.9081 12.6346 15.0769C12.6541 14.9938 12.6724 14.9104 12.6894 14.8269C11.6996 14.886 10.7032 14.8016 9.7318 14.5737"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}
