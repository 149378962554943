import { CircularProgress, Fab } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import {
  InternalUser,
  InternalUserRole,
  InternalUserStatus,
} from '@traba/types'
import { ShiftAssignmentResponse } from '@traba/types'
import { useState } from 'react'
import { Button, Col, Icon, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useUserContext } from '../../../context/user/UserContext'
import { OpsExtendedShift } from '../../../hooks/useShifts'

interface AssignmentsDrawerProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  shiftAssignmentsMapByInternalUser: Map<string, ShiftAssignmentResponse[]>
  shiftsList?: OpsExtendedShift[]
  onClose?: () => void
}

const internalUserTeamOptions = [
  { label: 'All', value: '' },
  { label: 'Scaled Ops', value: InternalUserRole.SCALED_OPS },
  { label: 'Market Ops', value: InternalUserRole.MARKET_OPS },
]

export const AssignmentsDrawer = (props: AssignmentsDrawerProps) => {
  const { isOpen, onClose, setIsOpen, shiftAssignmentsMapByInternalUser } =
    props
  const { state } = useUserContext()
  const loggedInUserRole = state.userProfile?.internalUser?.role
  const [selectedRole, setSelectedRole] = useState<InternalUserRole>(
    loggedInUserRole || InternalUserRole.SCALED_OPS,
  )

  const { isLoadingInternalUsers, internalUserByIdMap } = useInternalUsers({
    statuses: [InternalUserStatus.Active],
    roles: selectedRole
      ? [selectedRole]
      : [
          InternalUserRole.OPS,
          InternalUserRole.SCALED_OPS,
          InternalUserRole.MARKET_OPS,
        ],
  })

  if (isLoadingInternalUsers) {
    return (
      <Row alignCenter justifyCenter fullWidth>
        <CircularProgress />
      </Row>
    )
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        variant="persistent"
      >
        <Row flexCol style={{ width: 500 }}>
          <Row
            fullWidth
            style={{ padding: theme.space.xxs }}
            justifyBetween
            alignCenter
          >
            <Text variant="h4" style={{ marginLeft: theme.space.med }}>
              Assignments
            </Text>
            <Button
              variant={ButtonVariant.TRANSPARENT}
              onClick={() => setIsOpen(!isOpen)}
              leftIcon={<Icon name="cancel" />}
            >
              Close
            </Button>
          </Row>
          <Col my={theme.space.xs} px={theme.space.xs} style={{ height: 60 }}>
            <Select
              label="Ops Team"
              menuItems={internalUserTeamOptions}
              value={selectedRole}
              handleSelect={(val) => setSelectedRole(val as InternalUserRole)}
            />
          </Col>
          <Col style={{ padding: theme.space.med }}>
            <ul>
              {shiftAssignmentsMapByInternalUser.size > 0 ? (
                Array.from(shiftAssignmentsMapByInternalUser).map(
                  ([internalUserId, shiftAssignments]) => {
                    const internalUser = internalUserByIdMap.get(internalUserId)
                    if (!internalUser) {
                      return null
                    }
                    return (
                      <AssigneeDisplay
                        key={internalUserId}
                        internalUser={internalUser}
                        shiftAssignments={shiftAssignments}
                        shiftsList={props.shiftsList}
                      />
                    )
                  },
                )
              ) : (
                <Text variant="h5">No Assignments Yet</Text>
              )}
            </ul>
          </Col>
        </Row>
      </Drawer>

      <Fab
        color="default"
        style={{
          margin: 0,
          top: 20,
          right: 20,
          bottom: 'auto',
          left: 'auto',
          height: '50px',
          overflow: 'auto',
          position: 'fixed',
          zIndex: Z_INDEXES.FLOATING_BUTTON,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="userProfile" size={18} />
      </Fab>
    </>
  )
}

const AssigneeDisplay = ({
  internalUser,
  shiftAssignments,
  shiftsList,
}: {
  internalUser: InternalUser
  shiftAssignments: ShiftAssignmentResponse[]
  shiftsList?: OpsExtendedShift[]
}) => {
  const getSlotsForShift = (shift: {
    overbookSlotsRequested?: number
    slotsRequested: number
  }) => shift?.overbookSlotsRequested || shift?.slotsRequested || 0

  const isShiftAssignedToUser = (shift: OpsExtendedShift) =>
    shiftAssignments.some(
      (shiftAssignment) =>
        shiftAssignment.shiftId === shift.id &&
        shiftAssignment.assignees.some(
          (assignee) => assignee.internalUserId === internalUser.id,
        ),
    )

  const numberOfSlots =
    shiftsList?.reduce((acc, shift) => {
      return acc + (isShiftAssignedToUser(shift) ? getSlotsForShift(shift) : 0)
    }, 0) ||
    shiftAssignments.reduce((acc, shiftAssignment) => {
      return (
        acc +
        (shiftAssignment.shift ? getSlotsForShift(shiftAssignment.shift) : 0)
      )
    }, 0)

  return (
    <li style={{ marginBottom: theme.space.xs }}>
      <Text variant="h6">
        {internalUser.firstName} {internalUser.lastName} | Shifts Assigned:{' '}
        {shiftAssignments.length} | Slots: {numberOfSlots}
      </Text>
      <ul>
        {shiftAssignments.map((shiftAssignment) => {
          const shift = shiftsList
            ? shiftsList?.find((shift) => shift.id === shiftAssignment.shiftId)
            : shiftAssignment.shift
          if (!shift) {
            return null
          }

          const numSlots = getSlotsForShift(shift)
          return (
            <li key={shiftAssignment.id}>
              <Text variant="caption">
                Shift: {shiftAssignment.shiftId} | Slots: {numSlots}
              </Text>
            </li>
          )
        })}
      </ul>
    </li>
  )
}
