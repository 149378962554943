import { FilterField, FilterFieldType } from 'src/hooks/useFilteredData'
import { Col, Input, Row, Select } from '../base'

export type FilterFieldsProps = {
  filterFields: FilterField[]
  filterValues: Record<string, any>
  setFilterValue: (key: string, value: any) => void
}

export default function FilterFields({
  filterFields,
  setFilterValue,
  filterValues,
}: FilterFieldsProps) {
  return (
    <Row justifyEnd fullWidth>
      {filterFields.map((field) => {
        if (field.type === FilterFieldType.Text) {
          return (
            <Col ml={5} key={field.key}>
              <Input
                sm
                full
                label={field.label}
                value={filterValues[field.key]}
                onChange={(ev: any) =>
                  setFilterValue(field.key, ev.target.value)
                }
              />
            </Col>
          )
        }

        if (field.type === FilterFieldType.Select) {
          return (
            <Col ml={5} key={field.key}>
              <Select
                fullWidth
                showEmptyOption
                label={field.label}
                value={filterValues[field.key] || ''}
                handleSelect={(value) => setFilterValue(field.key, value)}
                menuItems={field.options || []}
              />
            </Col>
          )
        }

        return null
      })}
    </Row>
  )
}
