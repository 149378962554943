import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { BusinessComplaint, Complaint } from '@traba/types'
import { useQuery } from 'react-query'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'
import { getQueryParams } from './useApi'

async function getComplaints(): Promise<Complaint[] | undefined> {
  try {
    const res = await trabaApi.get(`complaints`)
    return res.data
  } catch (error) {
    console.error('useComplaints -> getComplaints() ERROR', error)
  }
}

async function getBusinessComplaintsByShiftId(
  shiftId: string,
): Promise<BusinessComplaint[] | undefined> {
  const queryString = getQueryParams([['shiftId', shiftId]])
  try {
    const res = await trabaApi.get(
      `companies/business-complaints/${queryString}`,
    )
    return res.data
  } catch (error: any) {
    console.error(
      'useBusinessComplaints -> getBusinessComplaints ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
  }
}

export function useComplaints() {
  const {
    isLoading,
    isError,
    data: complaints,
    error,
    isFetched,
    refetch,
  } = useQuery<Complaint[] | undefined, Error>(`complaints`, getComplaints, {
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    complaints,
    error,
    isFetched,
    refetch,
  }
}

export function useBusinessComplaints(shiftId: string) {
  const {
    isLoading,
    isError,
    data: businessComplaints,
    error,
    isFetched,
    refetch,
  } = useQuery<BusinessComplaint[] | undefined, Error>(
    [],
    () => getBusinessComplaintsByShiftId(shiftId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    businessComplaints,
    error,
    isFetched,
    refetch,
  }
}
