import { MenuItem } from '@mui/material'
import { Col, Row, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { OpsAttributeLevel } from '@traba/types'
import { AttributeBadgeMenuOption } from './types'
import {
  getMenuOptionFromAttributeLevel,
  getSubtitleForMenuOption,
  getTitleForMenuOption,
} from './utils'
interface AttributeBadgeMenuItemProps {
  item: AttributeBadgeMenuOption
  attributeType: string
  attributeLevel: OpsAttributeLevel
  handleSecondaryMenuOpen: (event: React.MouseEvent<HTMLElement>) => void
  onClick: (item: AttributeBadgeMenuOption) => void
}

function leftIconForMenuOption(
  item: AttributeBadgeMenuOption,
): React.ReactNode {
  switch (item) {
    case AttributeBadgeMenuOption.EXPERT:
      return (
        <Text variant="h6" mr={theme.space.xs}>
          3
        </Text>
      )
    case AttributeBadgeMenuOption.COMPETENT:
      return (
        <Text variant="h6" mr={theme.space.xs}>
          2
        </Text>
      )
    case AttributeBadgeMenuOption.BEGINNER:
      return (
        <Text variant="h6" mr={theme.space.xs}>
          1
        </Text>
      )
    default:
      return null
  }
}

export function AttributeBadgeMenuItem(props: AttributeBadgeMenuItemProps) {
  const {
    item,
    attributeType,
    attributeLevel,
    handleSecondaryMenuOpen,
    onClick,
  } = props

  const title = getTitleForMenuOption(item)
  const subtitle = getSubtitleForMenuOption(item, attributeType)
  const leftIcon = leftIconForMenuOption(item)

  return (
    <MenuItem
      key={item}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (item === AttributeBadgeMenuOption.VERIFY) {
          handleSecondaryMenuOpen(event)
        } else {
          onClick(item)
        }
      }}
      selected={item === getMenuOptionFromAttributeLevel(attributeLevel)}
    >
      {leftIcon}
      <Col>
        {item === AttributeBadgeMenuOption.REVOKE ? (
          <Row>
            <Text variant="body1" color={theme.colors.Red80}>
              {title}
            </Text>
            <SvgIcon
              name="block"
              color={theme.colors.Red80}
              style={{ marginLeft: theme.space.xxs }}
            />
          </Row>
        ) : (
          <Text variant="body1">{title}</Text>
        )}
        <Text variant="body3">{subtitle}</Text>
      </Col>
    </MenuItem>
  )
}
