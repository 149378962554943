import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { BreakType } from '@traba/types'
import { Money } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { Worker } from '@traba/types'
import { differenceInMinutes } from 'date-fns'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Col, Row } from 'src/components/base'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { getTotalPaidTimeInMinutes } from 'src/utils/dateUtils'
import {
  calculateWorkerPayandFees,
  getChargedUnits,
} from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'

export type BulkAdjustWorkerTableProps = {
  workerShifts: WorkerShift[]
  shouldDisplayAdjustedValues: boolean
  adjustedStartTime: Date
  adjustedEndTime: Date
  adjustedBreakType: BreakType
  adjustedBreaks: ShiftTime[]
  adjustedPayRate: number
  unitsPerWorker: number | undefined
  adjustedMinPaidTime: number
  adjustmentReason: string
  adjustedUnitsWorked: number | undefined
}

export default function BulkAdjustWorkerTable({
  workerShifts,
  shouldDisplayAdjustedValues,
  adjustedStartTime,
  adjustedEndTime,
  adjustedBreakType,
  adjustedBreaks,
  adjustedPayRate,
  adjustedMinPaidTime,
  adjustedUnitsWorked,
}: BulkAdjustWorkerTableProps) {
  // Assumption: All workerShifts have the same timezone, as they all stem from the same shift.
  const timezone =
    workerShifts.length > 0 ? workerShifts[0].shiftInfo.timezone : undefined
  const tz = useTimezonedDates(timezone)

  const headers = [
    {
      key: 'name',
      label: 'Worker Name',
    },
    {
      key: 'startTime',
      label: 'Start Time',
    },
    {
      key: 'endTime',
      label: 'End Time',
    },
    {
      key: 'breaks',
      label: 'Breaks',
    },
    {
      key: 'payRate',
      label: 'Pay Rate',
    },
    {
      key: 'instantPayFee',
      label: 'IP Fee',
    },
    {
      key: 'grossPay',
      label: 'Gross Pay',
    },
    {
      key: 'netPay',
      label: 'Net Pay',
    },
  ]

  if (shouldDisplayAdjustedValues) {
    headers.push({
      key: 'newNetPay',
      label: 'New Net Pay',
    })
  }

  const generateAndReturnTableCells = (
    workerShift: WorkerShift,
    worker: Worker,
  ): TableRow => {
    const adjustedTimeWorked = getTotalPaidTimeInMinutes(
      adjustedStartTime,
      adjustedEndTime,
      adjustedBreaks,
      adjustedBreakType,
      workerShift.shiftInfo.scheduledBreaks,
    )
    const { netPay: adjustedNetPay } = calculateWorkerPayandFees({
      chargedUnits: getChargedUnits({
        payType: workerShift.shiftInfo.payType,
        unitsCompletedByWorker: adjustedUnitsWorked,
        minimumPaidTime:
          adjustedMinPaidTime || workerShift.shiftInfo.minimumPaidTime,
        slotsRequested: workerShift.shiftInfo.slotsRequested,
        numberOfUnitsInShift: workerShift.shiftInfo.numberOfUnits,
        totalTimeWorked: adjustedTimeWorked,
        payByUnitMinimumPay: workerShift.shiftInfo.minimumPayByUnitPay,
        payRate: workerShift.shiftInfo.payRate,
      }),
      payRate: adjustedPayRate,
      payType: workerShift.shiftInfo.payType,
      timeWorkedInMinutes: adjustedTimeWorked,
      trustAndSafetyFeeHourly: workerShift.shiftInfo.trustAndSafetyFeeHourly,
    })

    const formattedMoney = (money: Money | undefined) => {
      return money ? getMoneyString(money) : '-'
    }

    const formattedDate = (date: Date | null | undefined) => {
      return date ? tz.getTime(date) : '-'
    }

    const calculateBreaks = () => {
      return (
        <>
          <p data-tooltip-id={`adjustBreaksTooltip-${workerShift.workerId}`}>
            {workerShift.breaks?.length ?? '0'}
          </p>
          {workerShift.breaks && workerShift.breaks?.length > 0 && (
            <ReactTooltip
              place="top"
              id={`adjustBreaksTooltip-${workerShift.workerId}`}
            >
              {workerShift.breaks.map((b, i) => {
                if (!b.startTime || !b.endTime) {
                  return null
                }
                return (
                  <p style={{ lineHeight: '24px' }}>
                    Break {i + 1} (
                    {differenceInMinutes(
                      new Date(b.endTime),
                      new Date(b.startTime),
                    )}{' '}
                    min) {tz.getShiftTime(b.startTime, b.endTime)}
                  </p>
                )
              })}
            </ReactTooltip>
          )}
        </>
      )
    }

    const cells = [
      { renderFn: () => worker.firstName + ' ' + worker.lastName },
      { renderFn: () => formattedDate(workerShift.clockInTime) },
      { renderFn: () => formattedDate(workerShift.clockOutTime) },
      { renderFn: () => calculateBreaks() },
      { renderFn: () => `$${workerShift.shiftInfo.payRate}` },
      { renderFn: () => formattedMoney(workerShift.instantPayFee) },
      { renderFn: () => formattedMoney(workerShift.grossPay) },
      { renderFn: () => formattedMoney(workerShift.netPay) },
    ]

    if (shouldDisplayAdjustedValues) {
      cells.push({
        renderFn: () => (
          <Text variant="body1" style={{ fontWeight: '700' }}>
            {formattedMoney(adjustedNetPay)}
          </Text>
        ),
      })
    }

    return { key: workerShift.workerId, cells }
  }

  const rows: TableRow[] = workerShifts.map((ws) => {
    if (ws.worker) {
      return generateAndReturnTableCells(ws, ws.worker)
    } else {
      return {
        key: '',
        cells: [
          {
            renderFn: () => <></>,
          },
        ],
      }
    }
  })

  return (
    <Col>
      <Row mb={theme.space.sm}>
        <Text variant="h5">Worker Table</Text>
      </Row>
      <Row>
        <DataTable headers={headers} rows={rows} />
      </Row>
    </Col>
  )
}
