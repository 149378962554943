import { Text } from '@traba/react-components'
import { Row, Col } from '@traba/react-components'
import { PendingClockOutsResponse } from '@traba/types'
import { Link } from 'react-router-dom'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import CopyTextIcon from '../base/CopyTextIcon/CopyTextIcon'
import { DotText } from '../base/DotText'
import { TitleLink } from './styles'

interface PendingClockOutItemHeaderInfoProps {
  pendingClockOutSummary: PendingClockOutsResponse
}

export default function PendingClockOutItemHeaderInfo({
  pendingClockOutSummary,
}: PendingClockOutItemHeaderInfoProps) {
  const { workerShift, worker } = pendingClockOutSummary
  const { shiftInfo, workerId, shiftId } = workerShift

  const tz = useTimezonedDates()

  return (
    <Col>
      <Row alignCenter>
        <Link to={`/workers/${workerId}`} target="_blank">
          <TitleLink>
            {worker.firstName} {worker.lastName}
          </TitleLink>
        </Link>
        <CopyTextIcon textToCopy={workerId} />
        <DotText />
        <Link to={`/field-monitor/${shiftId}`}>
          <TitleLink>
            {shiftInfo.shiftRole} at {shiftInfo.employerName}
          </TitleLink>
        </Link>
        <CopyTextIcon textToCopy={shiftId} />
      </Row>
      <Row alignCenter>
        <Text>{tz.getShiftDate(shiftInfo.startTime, shiftInfo.endTime)}</Text>
        <DotText />
        <Text> {tz.getShiftTime(shiftInfo.startTime, shiftInfo.endTime)}</Text>
        <DotText />
        <Text>{shiftId}</Text>
      </Row>
    </Col>
  )
}
