import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import {
  Dialog,
  MediaUploader,
  MODAL_SIZE,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerCertificationStatus } from '@traba/types'
import { useState } from 'react'
import { DatePicker } from 'src/components/base'
import { useCertifications } from 'src/hooks/useCertifications'

interface WorkerCertificationsAddModalProps {
  showAddModal: boolean
  onCloseModal: () => void

  workerId: string
  refetch: () => void
}

export const WorkerCertificationsAddModal = (
  props: WorkerCertificationsAddModalProps,
) => {
  const { showAddModal, onCloseModal, workerId, refetch } = props
  const { handleUpload } = useFileUploader()
  const { certifications } = useCertifications()
  const { showError, showSuccess } = useAlert()
  const [certFile, setCertFile] = useState<File | undefined>()
  const [certUrl, setCertUrl] = useState<string>('')
  const [selectedCertificationType, setSelectedCertificationType] =
    useState<string>()
  const [issueDate, setIssueDate] = useState<Date | null>()
  const [expirationDate, setExpirationDate] = useState<Date | null>()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const requireExpiration =
    certifications?.find((cert) => cert.type === selectedCertificationType)
      ?.expirationDateInput === 'REQUIRED'
  const requireIssue =
    certifications?.find((cert) => cert.type === selectedCertificationType)
      ?.issueDateInput === 'REQUIRED'

  async function onChangeCertFile(f: File | undefined) {
    setIsUploading(true)
    if (!f) {
      return
    }
    setCertFile(f)
    if (f) {
      const url = await handleUpload({
        fileType: FileType.WORKER_CERTIFICATIONS,
        media: f,
        userId: workerId,
      })

      setCertUrl(url)
    }
    setIsUploading(false)
  }

  const disableConfirm =
    !certUrl ||
    isUploading ||
    (requireExpiration && !expirationDate) ||
    (requireIssue && !issueDate)

  const resetStates = () => {
    setCertFile(undefined)
    setCertUrl('')
    setIssueDate(null)
    setExpirationDate(null)
    setSelectedCertificationType(undefined)
  }

  const onConfirm = async () => {
    try {
      if (!certUrl) {
        showError('Please upload a certification photo')
      }
      await trabaApi.post(`/workers/${workerId}/worker-certifications`, {
        certificationType: selectedCertificationType,
        issueDate: issueDate?.toISOString(),
        expirationDate: expirationDate?.toISOString(),
        imageUrl: certUrl,
        imageUrls: [certUrl],
        status: WorkerCertificationStatus.Approved,
      })
      refetch()
      showSuccess('Certification uploaded successfully')
      resetStates()
    } catch (error) {
      showError('Error uploading certification')
    }
    onCloseModal()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showAddModal}
      onClose={() => {
        resetStates()
        onCloseModal()
      }}
      onConfirmCTA="Confirm"
      dialogTitle={`Remove selected workers`}
      formId="create-roster"
      confirmDisabled={disableConfirm}
      onConfirm={onConfirm}
      size={MODAL_SIZE.LARGE}
    >
      <Text variant="h5">Select the certification you want to add </Text>
      {certifications?.map((certification, index) => (
        <Row
          key={index}
          alignCenter
          onClick={() => setSelectedCertificationType(certification.type)}
          mr={theme.space.xs}
          my={theme.space.xxs}
          gap={theme.space.xxs}
        >
          <RadioButton
            selected={selectedCertificationType === certification.type}
          />
          <Text variant="body2">{certification.name}</Text>
        </Row>
      ))}
      <MediaUploader
        id="worker-certification-uploader"
        label="Select certification photo (required)"
        file={certFile}
        onChange={onChangeCertFile}
        onDelete={() => setCertFile(undefined)}
        onError={(error) => {
          showError(error, 'File upload/delete error. Please try again.')
        }}
        fileType="image"
        maxFileSizeMB={5}
        marginTop={theme.space.xs}
      />
      <Text variant="body1" mt={theme.space.xs}>
        Expiration Date {requireExpiration && '(*Required)'}
      </Text>
      <DatePicker
        date={expirationDate}
        granularity="day"
        setDate={(newDate) => setExpirationDate(newDate)}
        isClearable={false}
        aria-label="Expiration Date"
        isRequired={requireExpiration}
      />
      <Text variant="body1" mt={theme.space.sm}>
        Issued Date {requireIssue && '(*Required)'}
      </Text>
      <DatePicker
        date={issueDate}
        setDate={(newDate) => setIssueDate(newDate)}
        isClearable={false}
        aria-label="Issued Date"
        isRequired={requireIssue}
        granularity="day"
      />
    </Dialog>
  )
}
