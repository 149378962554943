import { ChangeMemberRoleModalContent } from '@traba/react-components'
import { User, UserRole } from '@traba/types'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyUserMutations } from 'src/hooks/useCompanyUsers'

export type UserEditModalProps = {
  user: User
  handleClose: () => void
  isOpen: boolean
}

export function ChangeUserRoleModal({
  user,
  handleClose,
  isOpen,
}: UserEditModalProps) {
  const { changeCompanyMemberRole, isChangeCompanyMemberRoleLoading } =
    useCompanyUserMutations(user.companyId || '')

  const handleChangeMemberRole = useCallback(
    (role: UserRole) => {
      changeCompanyMemberRole(
        { role, uid: user.uid },
        {
          onSettled: handleClose,
        },
      )
      window.analytics.track(`User Saved Member Role Change`, {
        member: user,
        role,
      })
    },
    [user, changeCompanyMemberRole, handleClose],
  )

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM_LOCKED}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <ChangeMemberRoleModalContent
        member={user}
        handleClose={handleClose}
        onChangeMemberRole={handleChangeMemberRole}
        loading={isChangeCompanyMemberRoleLoading}
      />
    </Modal>
  )
}
