import {
  MultiDatePicker,
  Text,
  Col,
  InfoTooltip,
  SvgIcon,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  combineTwoDatesForDateAndTime,
  getEndDateError,
  getFirstShiftDateError,
  getNextStartAndEndTime,
} from '@traba/utils'
import { addMinutes, differenceInMinutes, isBefore, subMinutes } from 'date-fns'
import { isUndefined } from 'lodash'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'

interface ShiftTimePickerProps {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: React.Dispatch<
    React.SetStateAction<CreateShiftRequest[]>
  >
  businessStartTime: Date | null
  setBusinessStartTime: React.Dispatch<React.SetStateAction<Date | null>>
  timezone: string
  isInvalidBuffer: boolean
  setIsInvalidBuffer: React.Dispatch<React.SetStateAction<boolean>>
  selectedSingleShiftDates: Date[] | null
  setSelectedSingleShiftDates: Dispatch<SetStateAction<Date[] | null>>
}

export const ShiftTimePickerWithSchedules = ({
  createShiftRequests,
  setCreateShiftRequests,
  businessStartTime,
  setBusinessStartTime,
  timezone,
  setIsInvalidBuffer,
  selectedSingleShiftDates,
  setSelectedSingleShiftDates,
}: ShiftTimePickerProps) => {
  const [buffer, setBuffer] = useState<number>(0)

  const validateBuffer = () => {
    // Validate buffer value
    if (buffer < 0 || buffer > 120) {
      return setIsInvalidBuffer(true)
    }
    setIsInvalidBuffer(false)
  }

  const handleBufferUpdate = (newBuffer: number) => {
    if (isUndefined(newBuffer)) {
      return
    }

    validateBuffer()
    const currentStartTime =
      businessStartTime ||
      new Date(createShiftRequests[0].schedules[0].startTime)
    // Assign business start time if it doesn't exist
    if (!businessStartTime) {
      setBusinessStartTime(currentStartTime)
    }
    // Use currentStartTime instead of businessStartTime to handle for rerender dependency when setting state above
    const newStartTime = subMinutes(currentStartTime.getTime(), buffer)
    handleStartTimeChange(newStartTime, true) // update startTime to adjust for the buffer
  }

  const handleStartTimeChange = (
    newDate: Date | null,
    isFourDigitYear?: boolean,
  ) => {
    if (newDate && isFourDigitYear) {
      let newEndTime = new Date(createShiftRequests[0].schedules[0].endTime)

      if (isBefore(createShiftRequests[0].schedules[0].endTime, newDate)) {
        const shiftLength = differenceInMinutes(
          createShiftRequests[0].schedules[0].endTime,
          createShiftRequests[0].schedules[0].startTime,
        )
        newEndTime = addMinutes(newDate, shiftLength)
      }

      if (buffer === 0) {
        setBusinessStartTime(new Date(newDate))
      }

      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  startTime: new Date(newDate),
                  endTime: newEndTime,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            startTime: new Date(newDate),
            endTime: newEndTime,
          }
          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })
    }
  }

  const handleEndTimeChange = (newDate: Date | null) => {
    if (newDate) {
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  endTime: newDate,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            endTime: newDate,
          }

          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })

      if (
        createShiftRequests[0].schedules[0].recurringSchedule?.endDate &&
        isBefore(
          createShiftRequests[0].schedules[0].recurringSchedule.endDate,
          newDate,
        )
      ) {
        setCreateShiftRequests((prev) => {
          return prev.map((sr) => ({
            ...sr,
            schedule: {
              ...sr.schedules[0],
              recurringSchedule: {
                repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                freq: 'WEEKLY',
                interval: 1,
                endDate: newDate,
              },
            },
            schedules: [
              {
                ...sr.schedules[0],
                recurringSchedule: {
                  repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                  freq: 'WEEKLY',
                  interval: 1,
                  endDate: newDate,
                },
              },
            ],
          }))
        })
      }
    }
  }

  const handleBusinessStartTimeChange = (newDate: Date | null) => {
    // Business start time change logic
    if (!newDate) {
      return
    }
    setBusinessStartTime(newDate)
    handleStartTimeChange(new Date(newDate.getTime() - buffer * 60000), true)
  }

  const handleBufferChange = (newBuffer: number) => {
    // Buffer change logic
    setBuffer(newBuffer)
    handleBufferUpdate(newBuffer)
  }

  //cannot set a buffer for a shift that starts in less than 2 hours
  const twoHoursFromNow = new Date()
  twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)

  const fieldDisabled = isBefore(
    createShiftRequests[0].schedules[0].startTime,
    twoHoursFromNow,
  )
  const firstShiftDateError = getFirstShiftDateError(
    createShiftRequests[0],
  )?.message

  const endDateError = getEndDateError(createShiftRequests[0])?.message

  return (
    <>
      {selectedSingleShiftDates && (
        <>
          <Row alignCenter gap={theme.space.xxxs}>
            <SvgIcon name="calendar" color={theme.colors.Violet} />
            <Text variant="h6">
              Which dates? ({selectedSingleShiftDates?.length} selected)
            </Text>
          </Row>
          <MultiDatePicker
            selectedDates={
              selectedSingleShiftDates ?? [
                createShiftRequests[0].schedules[0].startTime,
              ]
            }
            onSelectDates={(dates: Date[]) => {
              setSelectedSingleShiftDates(dates)
              setBusinessStartTime(null)
              setCreateShiftRequests((prev) => {
                const shiftDurationInMinutes = differenceInMinutes(
                  prev[0].schedules[0].endTime,
                  prev[0].schedules[0].startTime,
                )
                const newStartTime = combineTwoDatesForDateAndTime(
                  dates[0],
                  prev[0].schedules[0].startTime,
                )
                const newEndTime = addMinutes(
                  newStartTime,
                  shiftDurationInMinutes,
                )
                return prev.map((sr) => ({
                  ...sr,
                  schedules: [
                    {
                      ...sr.schedules[0],
                      startTime: newStartTime,
                      endTime: newEndTime,
                    },
                  ],
                }))
              })
            }}
          />
        </>
      )}
      <Row justifyBetween my={theme.space.sm} wrap>
        {/* Start Time Input Section */}
        <InfoTooltip
          title={
            'You can only edit the start time when an early arrival buffer is 0. Edit the buffer or the business start time if there is a buffer to modify this value.'
          }
        />
        <ShiftPostingInputContainerSection
          label={'First shift start time'}
          input={
            <Col>
              <DatePicker
                date={createShiftRequests[0].schedules[0].startTime}
                showTimeFieldInPopover={true}
                setDate={handleStartTimeChange}
                isClearable={false}
                timezone={timezone}
                aria-label="Start Time"
              />
              <Text variant="error">{firstShiftDateError}</Text>
            </Col>
          }
        />
        {/* Business Start Time Input Section */}
        {businessStartTime !== createShiftRequests[0].schedules[0].startTime &&
          buffer !== 0 &&
          businessStartTime && (
            <>
              <InfoTooltip
                title={
                  'The business start time is what is shown to the business on the biz app. They do not see buffers or modified shift times.'
                }
              />
              <ShiftPostingInputContainerSection
                label="Business Start Time"
                input={
                  <DatePicker
                    date={businessStartTime}
                    showTimeFieldInPopover={true}
                    setDate={handleBusinessStartTimeChange}
                    isClearable={false}
                    timezone={timezone}
                    aria-label="Start Time"
                  />
                }
              />
            </>
          )}
        {/* Buffer Input Section */}
        <InfoTooltip
          title={
            'The early arrival buffer can only be <= 2 hours. You can only edit this value for start times <= 2 hours from now. Note that this amount of time is a cost to Traba (cost = buffer/60 * hourlyRate * workers)!'
          }
        />
        <ShiftPostingInputContainerSection
          label="Early Arrival Buffer"
          input={
            <Row alignCenter>
              <NumberInput
                value={buffer}
                setValue={(value) => {
                  if (value) {
                    handleBufferChange(value)
                  } else {
                    handleBufferChange(0)
                  }
                }}
                min={0}
                max={120}
                step={1}
                placeholder={'e.g. 15'}
                onError={() => setIsInvalidBuffer(true)}
                disabled={fieldDisabled}
              />
            </Row>
          }
        />
        {/* End Time Input Section */}
        <ShiftPostingInputContainerSection
          label="First shift end Time"
          input={
            <Col>
              <DatePicker
                date={createShiftRequests[0].schedules[0].endTime}
                showTimeFieldInPopover={true}
                setDate={handleEndTimeChange}
                isClearable={false}
                timezone={timezone}
                aria-label="End Time"
              />
              {<Text variant="error">{endDateError}</Text>}
            </Col>
          }
        />
      </Row>
    </>
  )
}
