import { LinearProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { GenericAcceptShiftBypasses, Shift } from '@traba/types'
import { Row } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { useEligibilitiesForWorkers } from './hooks/useEligibilitiesForWorkers'
import { WorkerOnShift } from './types'
import { WorkersRestrictionsReview } from './WorkersRestrictionsReview'

export const ReviewStep = ({
  selectedShifts,
  shifts,
  workers,
  workerShiftsToCancel,
  setWorkerShiftsToCancel,
  bypasses,
  setBypasses,
  hasUncheckedBypasses,
  hasRequiredMultiShift,
}: {
  selectedShifts: Record<string, string[]>
  shifts: Shift[]
  workers: PopulatedWorker[]
  workerShiftsToCancel: WorkerOnShift[]
  setWorkerShiftsToCancel: React.Dispatch<React.SetStateAction<WorkerOnShift[]>>
  bypasses: GenericAcceptShiftBypasses
  setBypasses: React.Dispatch<React.SetStateAction<GenericAcceptShiftBypasses>>
  hasUncheckedBypasses: boolean
  hasRequiredMultiShift: boolean
}) => {
  const { isLoading, workersEligibilities } = useEligibilitiesForWorkers(
    selectedShifts,
    workers.map((worker) => worker.id || worker.workerId),
  )

  const selectedShiftsIds = Object.values(selectedShifts).flat()
  const selectedShiftsData = shifts.filter((shift) =>
    selectedShiftsIds.includes(shift.id),
  )

  if (isLoading) {
    return (
      <Row fullWidth justifyCenter alignCenter style={{ flex: 1 }}>
        <Text>
          Checking eligibility... <LinearProgress />
        </Text>
      </Row>
    )
  }
  return (
    <WorkersRestrictionsReview
      workers={workers}
      shifts={selectedShiftsData}
      workerShiftsToCancel={workerShiftsToCancel}
      setWorkerShiftsToCancel={setWorkerShiftsToCancel}
      eligibilityData={workersEligibilities}
      setBypasses={setBypasses}
      bypasses={bypasses}
      hasUncheckedBypasses={hasUncheckedBypasses}
      hasRequiredMultiShift={hasRequiredMultiShift}
    />
  )
}
