import { theme } from '@traba/theme'
import { WorkerShiftAvailability } from '@traba/types'
import { useState } from 'react'

import { Input } from 'src/components/base'
import { RosterRow } from 'src/components/Rosters/RosterRow'
import { RosterInfo } from './types'

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function filterRostersByNameOrWorkerName(
  searchFilter: string,
  rosters: RosterInfo[],
) {
  if (!searchFilter.length) {
    return rosters
  }
  const escapedSearchFilter = escapeRegExp(searchFilter)
  return rosters.filter(
    (r) =>
      new RegExp(escapedSearchFilter, 'i').test(r.name) ||
      r.workers.some((worker) =>
        new RegExp(escapedSearchFilter, 'i').test(
          `${worker.firstName} ${worker.lastName}`,
        ),
      ),
  )
}

interface SelectRostersViewProps {
  rosters: RosterInfo[]
  selectedRosters: Set<string>
  setSelectedRosters: React.Dispatch<React.SetStateAction<Set<string>>>
  workerDistancesByWorkerId?: Record<string, number>
  workerAvailabilitiesByWorkerId?: Map<string, WorkerShiftAvailability>
  companyId?: string
  roleId?: string
}

export const SelectRostersView: React.FC<SelectRostersViewProps> = (props) => {
  const {
    rosters,
    selectedRosters,
    setSelectedRosters,
    workerDistancesByWorkerId,
    workerAvailabilitiesByWorkerId,
    companyId,
    roleId,
  } = props

  const [searchRostersFilter, setSearchRostersFilter] = useState('')
  const [displayedRosters, setDisplayedRosters] =
    useState<RosterInfo[]>(rosters)

  return (
    <>
      <Input
        placeholder="Search..."
        name="rosterSearch"
        containerStyle={{
          marginTop: 0,
          marginBottom: theme.space.xxs,
        }}
        type="text"
        defaultValue=""
        width="100%"
        value={searchRostersFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newSearchFilter = e.target.value
          e.preventDefault()
          setSearchRostersFilter(newSearchFilter)
          const filteredRosters = filterRostersByNameOrWorkerName(
            newSearchFilter,
            rosters,
          )
          setDisplayedRosters(filteredRosters)
        }}
      />

      {displayedRosters.map((roster) => (
        <RosterRow
          key={roster.id}
          roster={roster}
          selected={selectedRosters.has(roster.id)}
          workerDistancesByWorkerId={workerDistancesByWorkerId}
          workerAvailabilitiesByWorkerId={workerAvailabilitiesByWorkerId}
          companyId={companyId}
          roleId={roleId}
          onClick={() => {
            if (selectedRosters.has(roster.id)) {
              const updatedSelectedRosters = new Set(selectedRosters)
              updatedSelectedRosters.delete(roster.id)
              setSelectedRosters(updatedSelectedRosters)
            } else {
              setSelectedRosters(new Set(selectedRosters.add(roster.id)))
            }
          }}
        />
      ))}
    </>
  )
}
