import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftWithSlimCharges } from '@traba/types'
import { useMemo } from 'react'
import { Col, Row } from 'src/components/base/'
import Checkbox from 'src/components/base/Checkbox'
import { ShiftDateCell, ShiftTimesCell } from 'src/components/ShiftTableCells'
import { EmploymentTypeBadge } from '../../../../components/base/Badge/EmploymentTypeBadge'

function SelectedShift(props: {
  shift: ShiftWithSlimCharges
  onUnselect: () => void
}) {
  const { shift, onUnselect } = props

  const shiftName = `${shift.shiftRole || shift.role?.roleName} ${` @ ${shift.shortLocation ?? ''}`}`

  return (
    <Card
      style={{
        backgroundColor: theme.colors.White,
        padding: theme.space.xs,
        marginBottom: theme.space.xxs,
      }}
    >
      <Row mb={theme.space.xxs} gap={theme.space.xxs}>
        <Row style={{ flex: 1 }}>
          <Checkbox checked onChange={onUnselect} />
          <Text variant="h6">{shiftName}</Text>
        </Row>
        <Row>
          <ShiftDateCell shift={shift} />
        </Row>
      </Row>
      <Row style={{ textAlign: 'right' }}>
        {shift.shiftEmploymentType && (
          <EmploymentTypeBadge
            employmentTypes={[shift.shiftEmploymentType]}
            showAll
          />
        )}
        <ShiftTimesCell shift={shift} />
      </Row>
    </Card>
  )
}

type SelectedShiftsProps = {
  selectedShifts: ShiftWithSlimCharges[]
  removeSelectedShiftId: (id: string) => void
}

export default function SelectedShifts(props: SelectedShiftsProps) {
  const { selectedShifts, removeSelectedShiftId } = props

  // Group shifts by invoiceGroupId
  const groupIdToShiftsMap = useMemo(() => {
    return selectedShifts.reduce((acc, shift) => {
      const groupId = shift.invoiceGroupName || 'ungrouped'
      if (!acc.has(groupId)) {
        acc.set(groupId, [])
      }
      acc.get(groupId)!.push(shift) // using non-null assertion because of the check in the block above
      return acc
    }, new Map<string, ShiftWithSlimCharges[]>())
  }, [selectedShifts])

  return (
    <Col
      style={{
        maxHeight: '20em',
        padding: theme.space.xxs,
      }}
    >
      {Array.from(groupIdToShiftsMap.entries()).map(([groupId, shifts]) => (
        <Col key={groupId} mb={theme.space.xs}>
          <Text variant="h5" mb={theme.space.xxs}>
            {groupId === 'ungrouped' ? 'Ungrouped Shifts' : `Group: ${groupId}`}
          </Text>
          {shifts.map((shift: ShiftWithSlimCharges) => (
            <SelectedShift
              key={shift.id}
              shift={shift}
              onUnselect={() => removeSelectedShiftId(shift.id)}
            />
          ))}
        </Col>
      ))}
    </Col>
  )
}
