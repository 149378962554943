import { SvgIconProps } from '../SvgIconProps'

export default function Bugle({ size, color = '#781DD2' }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68877 8.81604L6.95786 9.12782C6.13615 9.47834 5.37786 9.96133 4.71466 10.5566C3.55719 11.5956 3.06272 13.1672 3.42744 14.6478L3.43946 14.6966C3.64045 15.5125 3.97203 16.2898 4.42186 16.9996L4.44877 17.0421C5.26504 18.3301 6.74161 19.0608 8.29263 18.9445C9.18132 18.8778 10.0548 18.6647 10.8765 18.3142L11.6074 18.0024C12.048 17.8145 12.5439 17.8067 12.9843 17.981L13.747 18.2828C15.9957 19.1726 18.4042 17.2706 18.0213 14.9074C17.5163 11.7909 16.2618 8.85004 14.3619 6.32861C12.9211 4.41661 9.88134 4.83867 8.96724 7.07764L8.65722 7.83699C8.47819 8.27549 8.12943 8.62807 7.68877 8.81604Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M16.3576 6.08367L18.3488 3.10571"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M19.578 13.6331L23.1056 14.2569"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M18.3501 9.69531L21.1095 8.51823"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M0.998246 16.9316C1.34703 15.6648 3.35195 14.9546 3.35195 14.9546L4.85609 17.6938C4.85609 17.6938 3.35232 18.8874 2.13478 18.6726C1.2883 18.5232 0.770077 17.7603 0.998246 16.9316Z"
        fill={color}
      />
      <path
        d="M5.08838 22.6716L3.51379 18.3464"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
