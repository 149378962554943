import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'

export const useWorkerShiftsInShiftRequest = (
  shiftRequestId: string,
  multipleWorkerShifts: WorkerShift[],
) => {
  const workerIds = multipleWorkerShifts.map((ws) => ws.workerId)
  const { data: allWorkerShifts } = useQuery<WorkerShift[], Error>({
    queryKey: [`shift-request${shiftRequestId}`],
    queryFn: () => getWorkerShiftsByWorkerIds(workerIds, shiftRequestId),
  })
  return allWorkerShifts
}

const getWorkerShiftsByWorkerIds = async (
  workerIds: string[],
  shiftRequestId: string,
): Promise<WorkerShift[]> => {
  try {
    const response = await trabaApi.post(
      `/worker-shifts/shift-request/${shiftRequestId}`,
      {
        workerIds,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
