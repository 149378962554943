import { Button, ButtonVariant, Row, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Icon } from 'src/components/base'
import { useVettingCampaignMutations } from 'src/hooks/useVettingCampaigns'
import { ConfirmVettingCampaignAIVettingModal } from 'src/modals/ConfirmVettingCampaignAIVettingModal/ConfirmVettingCampaignAIVettingModal'
import { ConfirmVettingCampaignSyncModal } from 'src/modals/ConfirmVettingCampaignSyncModal/ConfirmVettingCampaignSyncModal'

export const VettingCampaignActions = ({
  campaignId,
  disabled = false,
}: {
  campaignId: string
  disabled?: boolean
}) => {
  const {
    syncVettingCampaign,
    isSyncPending,
    startAIVetting,
    isStartAIVettingPending,
  } = useVettingCampaignMutations()

  const [isConfirmSyncModalOpen, setIsConfirmSyncModalOpen] = useState(false)
  const [isConfirmAIVettingModalOpen, setIsConfirmAIVettingModalOpen] =
    useState(false)
  const handleSyncClick = () => {
    setIsConfirmSyncModalOpen(true)
  }
  const handleAIVettingClick = () => {
    setIsConfirmAIVettingModalOpen(true)
  }
  return (
    <>
      <Row
        justifyEnd
        alignCenter
        gap={theme.space.xsmed}
        mt={theme.space.xxxs}
        mb={theme.space.xxxs}
        style={{ width: '100%' }}
      >
        <Button
          leftIcon={
            <SvgIcon
              name="refresh"
              color={disabled ? theme.colors.Grey40 : theme.colors.Violet}
              style={{ transform: 'scaleX(-1)' }}
            />
          }
          style={{
            width: '86px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '16px',
          }}
          loading={isSyncPending}
          variant={ButtonVariant.BRANDNEW}
          onClick={handleSyncClick}
          disabled={disabled}
        >
          Sync
        </Button>
        <Button
          style={{
            color: theme.colors.Violet,
            backgroundColor: theme.colors.White,
            width: '40px',
            height: '40px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '40px',
          }}
          variant={ButtonVariant.OUTLINED}
          onClick={handleAIVettingClick}
        >
          <Icon name="aiPlatform" color={theme.colors.Violet} />
        </Button>
      </Row>
      <ConfirmVettingCampaignSyncModal
        isOpen={isConfirmSyncModalOpen}
        handleClose={() => setIsConfirmSyncModalOpen(false)}
        onSync={() => syncVettingCampaign(campaignId)}
      />
      <ConfirmVettingCampaignAIVettingModal
        isOpen={isConfirmAIVettingModalOpen}
        handleClose={() => setIsConfirmAIVettingModalOpen(false)}
        onSubmit={(startAIVettingRequest) =>
          startAIVetting({
            vettingCampaignId: campaignId,
            ...startAIVettingRequest,
          })
        }
        loading={isStartAIVettingPending}
      />
    </>
  )
}
