import { INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS } from '@traba/consts'
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import {
  formatDateString,
  getShiftTimeString,
  isRecurringShiftRequest,
} from '@traba/utils'
import { addDays, addWeeks, isAfter } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ONE_MONTH_IN_DAYS, useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { getPayRateString } from 'src/utils/stringUtils'
import { DataTable, Row, ShiftStatusBadge } from '../base'
import DateRangePicker from '../base/AriaDatePicker/DateRangePicker'

interface Props {
  company: Company
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}
export const ScheduleDetailsShiftsTab = ({
  company,
  shiftRequestParent,
}: Props) => {
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [includeCanceled, setIncludeCanceled] = useState(true)
  const { rawShifts, hasNextPage, fetchNextPage, isFetching } =
    useCompanyShifts(
      {
        shiftRequestParentIds: [shiftRequestParent.shiftRequestParentId],
        companyId: company.companyId,
        startAfter: dateRange[0] ?? new Date(),
        endBefore: dateRange[1] ?? addDays(new Date(), ONE_MONTH_IN_DAYS),
      },
      true,
    )
  const shifts = rawShifts?.pages.map((page) => page?.data).flat()
  const firstRecurringShiftRequest = shiftRequestParent.shiftRequests.find(
    isRecurringShiftRequest,
  )
  const scheduleStart = firstRecurringShiftRequest?.schedules[0].startTime

  const shiftsToDisplay = useMemo(() => {
    if (includeCanceled) {
      return shifts
    }
    return shifts?.filter((shift) => shift.status !== ShiftStatus.CANCELED)
  }, [includeCanceled, shifts])
  const shiftsTableHeaders = [
    { label: 'Date', key: 'date', sortable: true },
    { label: 'Time', key: 'time' },
    { label: 'Roles', key: 'roles' },
    { label: 'Pay rate', key: 'payRate' },
    { label: 'Slots', key: 'slots' },
    { label: 'Status', key: 'status' },
  ]

  const formatShiftDate = (date: Date, timeZone: string) =>
    formatDateString(date, { timeZone, month: 'long', year: 'numeric' })
  const shiftTableRows = useMemo(() => {
    return shiftsToDisplay?.map((shift) => ({
      key: shift.shiftId,
      cells: [
        {
          key: 'date',
          renderFn: () => (
            <Text>{formatShiftDate(shift.startTime, shift.timezone)}</Text>
          ),
        },
        {
          key: 'time',
          renderFn: () => (
            <Text>
              {getShiftTimeString(
                shift.startTime,
                shift.endTime,
                shift.timezone,
              )}
            </Text>
          ),
        },
        {
          key: 'roles',
          renderFn: () => (
            <Row
              alignCenter
              onClick={() => {
                navigate(`/field-monitor/${shift.shiftId}`)
              }}
            >
              <Text>{shift.shiftRole}</Text>
              <SvgIcon name="link" />
            </Row>
          ),
        },
        {
          key: 'payRate',
          renderFn: () => (
            <Text>{getPayRateString(shift.payType, shift.payRate)}</Text>
          ),
        },
        {
          key: 'slots',
          renderFn: () => (
            <Text>{`${shift.slotsFilled} / ${shift.slotsRequested}`}</Text>
          ),
        },
        {
          key: 'status',
          renderFn: () => <ShiftStatusBadge shiftStatus={shift.status} />,
        },
      ],
    }))
  }, [shifts])
  const noShiftsToShowForNext3Weeks =
    (!shiftTableRows || shiftTableRows.length === 0) &&
    scheduleStart &&
    isAfter(
      scheduleStart,
      addWeeks(new Date(), INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS),
    )

  return (
    <>
      <Row alignCenter justifyBetween>
        <DateRangePicker
          label="Date range"
          inlineLabel={true}
          dateRange={dateRange}
          setDateRange={setDateRange}
          granularity="day"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xs }}
        />
        <Toggle
          label={'Included canceled'}
          buttonState={includeCanceled}
          runOnChange={() => setIncludeCanceled((prev) => !prev)}
          containerStyle={{ justifyContent: 'center' }}
        />
      </Row>
      <DataTable
        initialSortByAscOrder={false}
        headers={shiftsTableHeaders}
        rows={shiftTableRows ?? []}
      />
      {noShiftsToShowForNext3Weeks && (
        <Text>
          Shifts will show up here 3 weeks before first shift start time
        </Text>
      )}
      {dateRange[0] === null &&
        dateRange[1] === null &&
        (isFetching ? (
          <LoadingSpinner />
        ) : hasNextPage ? (
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              hasNextPage && fetchNextPage()
            }}
          >
            load more
          </Button>
        ) : (
          <Text
            style={{ textAlign: 'center' }}
            variant="body2"
            mt={theme.space.xxs}
          >
            {'< -  No more shifts to load  -> '}
          </Text>
        ))}
    </>
  )
}
