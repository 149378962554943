import { BreakType, ScheduledBreak, ShiftTime, Timestamp } from '@traba/types'
import { getShiftDateString } from '@traba/utils'
import {
  differenceInMinutes,
  format,
  formatDistanceToNow,
  parse,
  subMinutes,
} from 'date-fns'
import { Option, match } from 'fp-ts/Option'
import { calculatePaidShiftTime, getTotalBreakTime } from './moneyUtils'

export const SIX_HOURS_IN_MINUTES = 6 * 60
export const ONE_DAY_IN_MINUTES = 24 * 60

export function getTimeInTimezone(
  date: Date | undefined | null,
  timezone: string,
  showTimezone = true,
): string {
  if (!date) {
    return ''
  }
  const parsedDate = new Date(date)
  const timezoneOverride = localStorage.getItem('timezoneOverride')
  return parsedDate.toLocaleString('en-US', {
    timeZone: timezoneOverride || timezone,
    timeZoneName: showTimezone ? 'short' : undefined,
    hour: 'numeric',
    minute: '2-digit',
  })
}

export function formatDateTimeWithTimezone(
  date: Date | undefined | null,
  timezone?: string,
): string {
  if (!date) {
    return ''
  }

  const parsedDate = new Date(date)
  const timezoneOverride = localStorage.getItem('timezoneOverride')
  return parsedDate.toLocaleString('en-US', {
    ...(timezone ? { timeZone: timezoneOverride || timezone } : {}),
    timeZoneName: 'short',
  })
}

export function getDateTimeWithTimezone(date: Date | undefined): string {
  if (!date) {
    return ''
  }

  const parsedDate = new Date(date)
  return format(parsedDate, 'MMM dd, yyyy, hh:mm a O')
}

export function getDateInTimezone(
  date: Date | null | undefined,
  timezone: string,
  showTimezone = true,
): string {
  if (!date) {
    return ''
  }

  const parsedDate = new Date(date)
  const timezoneOverride = localStorage.getItem('timezoneOverride')
  return parsedDate.toLocaleDateString('en-US', {
    timeZone: timezoneOverride || timezone,
    timeZoneName: showTimezone ? 'short' : undefined,
  })
}

export function buildShiftDateStringWithTimezoneOverride(
  startTime: Date | string,
  endTime: Date | string,
  timezone: string,
  additionalOptions?: Intl.DateTimeFormatOptions,
) {
  if (typeof startTime === 'string') {
    startTime = new Date(startTime)
  }
  if (typeof endTime === 'string') {
    endTime = new Date(endTime)
  }
  const timezoneOverride = localStorage.getItem('timezoneOverride')
  const timezoneToDisplay = timezoneOverride || timezone
  return getShiftDateString(
    startTime,
    endTime,
    timezoneToDisplay,
    additionalOptions,
  )
}

export function getShiftTimeString(
  startTime: Date | string,
  endTime: Date | string,
  timezone: string,
  withoutAbv?: boolean,
) {
  try {
    if (typeof startTime === 'string') {
      startTime = new Date(startTime)
    }
    if (typeof endTime === 'string') {
      endTime = new Date(endTime)
    }

    const timezoneOverride = localStorage.getItem('timezoneOverride')
    const timezoneToDisplay = timezoneOverride || timezone

    const formattedStartTime = startTime.toLocaleString('en-US', {
      timeZone: timezoneToDisplay,
      hour: 'numeric',
      minute: '2-digit',
    })
    const formattedEndTime = endTime.toLocaleString('en-US', {
      timeZone: timezoneToDisplay,
      timeZoneName: withoutAbv ? undefined : 'short',
      hour: 'numeric',
      minute: '2-digit',
    })

    return `${formattedStartTime} - ${formattedEndTime}`
  } catch (err) {
    console.error(
      'dateUtils -> getShiftTimeString() ERROR. Returning empty time string instead.',
      { startTime, endTime },
      err,
    )
    return ''
  }
}

export function formatDuration(m: number) {
  const hours = Math.floor(m / 60)
  const minutes = Math.round(m % 60)
  return `${hours > 0 ? `${hours}h` : ''} ${minutes <= 9 ? '0' : ''}${minutes}m`
}

/**
 * Returns the total amount of time worked excluding breaks formatted in minutes
 */
export function getTotalPaidTimeInMinutes(
  startTime: Date,
  endTime: Date,
  breaks: ShiftTime[],
  breakType: BreakType,
  scheduledBreaks: ScheduledBreak[],
) {
  const totalWorkedShiftTime = differenceInMinutes(
    endTime.setSeconds(0),
    startTime.setSeconds(0),
  )
  const totalBreakTime = getTotalBreakTime(breaks, breakType, scheduledBreaks)
  const totalPaidTime = calculatePaidShiftTime(
    totalBreakTime,
    totalWorkedShiftTime,
    breakType,
  )
  return totalPaidTime
}

export function formatDurationBetweenDates(d1: Date, d2: Date) {
  const timeAdjusted = differenceInMinutes(d1, d2)
  return `${timeAdjusted <= 0 ? '+' : '-'} ${formatDuration(
    Math.abs(timeAdjusted),
  )}`
}

export function toDate(
  timestampOrString?: Timestamp | string | Date | null,
): Date {
  try {
    if (!timestampOrString) {
      throw new Error('timestampOrString is null')
    }

    if (timestampOrString instanceof Date) {
      return timestampOrString
    }

    return typeof (timestampOrString as Timestamp)?.toDate === 'function'
      ? (timestampOrString as Timestamp).toDate()
      : new Date(timestampOrString as string)
  } catch (err) {
    console.error('dateUtils -> toDate() ERROR. Returning original error.', err)
    return timestampOrString as Date
  }
}

export function extractOptionalDate(
  o: Option<Date>,
  defaultDate: Date | undefined | null,
) {
  const matchF = match<Date, Date | undefined | null>(
    () => defaultDate,
    (d: Date) => d,
  )
  return matchF(o)
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const dateRangeOverlaps = (
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date,
): boolean => {
  if (a_start <= b_start && b_start <= a_end) {
    return true // b starts in a
  }

  if (a_start <= b_end && b_end <= a_end) {
    return true // b ends in a
  }

  if (b_start < a_start && a_end < b_end) {
    return true // a in b
  }

  return false
}

export const isOverlap = (
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date,
): boolean => {
  const bufferedStart1 = subMinutes(start1, 30) // Subtract 30 minutes from the start time

  return dateRangeOverlaps(bufferedStart1, end1, start2, end2)
}

export const formatTimeDistanceToNow = (
  timeToFormat: Date | string,
  showPrefix?: boolean,
) => {
  const formattedTimeDistance = formatDistanceToNow(new Date(timeToFormat), {
    addSuffix: true,
  })

  return showPrefix
    ? formattedTimeDistance.replace('minutes', 'mins')
    : formattedTimeDistance
        .replace('about ', '')
        .replace('minutes', 'mins')
        .replace('less than a minute ago', 'just now')
}

export const getMinDate = (date1: Date, date2: Date) => {
  return date1 < date2 ? date1 : date2
}

export const parseDateString = (dateString: string): Date | null => {
  // List of possible date formats
  const dateFormats = [
    'MMM do', // Feb 28th
    'MMM d', // Feb 28
    'MMMM do', // February 28th
    'MMMM d', // February 28
    'MM/dd', // 02/28
    'M/d', // 2/28
    'M/d/yy', // 2/28/24
    'M/d/yyyy', // 2/28/2024
    'MM/dd/yyyy', // 02/28/2024
    'MMM dd, yyyy', // Feb 28, 2024
    'MMMM do, yyyy', // February 28th, 2024
    'yyyy-MM-dd', // 2024-02-28
    'yyyy/MM/dd', // 2024/02/28
    'dd/MM/yyyy', // 28/02/2024
    'dd-MM-yyyy', // 28-02-2024
  ]

  // Try parsing the date string with different formats
  for (const format of dateFormats) {
    const date = parse(dateString, format, new Date())
    if (!isNaN(date.getTime())) {
      return date
    }
  }

  return null
}

export const parseTimeString = (timeString: string): Date | null => {
  // List of possible time formats
  const timeFormats = [
    'h:mm a', // 12-hour format with AM/PM, no leading 0, e.g. 1:45 PM
    'hh:mm a', // 12-hour format with AM/PM, e.g. 01:45 PM
    'HH:mm', // 24-hour format, e.g. 13:45
  ]

  // Try parsing the time string with different formats
  for (const format of timeFormats) {
    const time = parse(timeString, format, new Date())
    if (!isNaN(time.getTime())) {
      return time
    }
  }

  return null
}

export const parseDateTimeString = (
  dateString: string,
  timeString: string,
): Date | null => {
  // Try parsing the date string with different formats
  const parsedDate = parseDateString(dateString)

  // Try parsing the time string with different formats
  const parsedTime = parseTimeString(timeString)

  const parsedDateTime = new Date(
    `${parsedDate?.toDateString()} ${parsedTime?.toTimeString().split(' ')[0]}`,
  )
  if (!isNaN(parsedDateTime.getTime())) {
    return parsedDateTime
  }
  return null
}
