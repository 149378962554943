import React from 'react'
import styled from 'styled-components'

const DividerWrapper = styled.div.attrs((props) => ({
  style: props.style,
}))`
  display: flex;
`
const NoTextDividerWrapper = styled.div.attrs((props) => ({
  style: props.style,
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SingleDivider = styled.span`
  :first-child {
    margin: 12px 10px 0 0;
  }

  :last-child {
    margin: 12px 0 0 10px;
  }
  border-bottom: 1px solid #e2e6e9;
  width: 30%;
  height: 1px;
`
const NoTextDivider = styled.span<{ color?: string }>`
  border-bottom: 1px solid ${(props) => props.color || '#e2e6e9'};
  height: 1px;
`

const DividerText = styled.span`
  text-align: center;
  width: 40%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #66788a;

  text-align: center;
  letter-spacing: -0.004em;
`

type Props = {
  text?: string
  wrapperStyle?: React.CSSProperties
  dividerColor?: string
}

export default function Divider(props: Props) {
  if (props.text) {
    return (
      <DividerWrapper style={props.wrapperStyle}>
        <SingleDivider />
        <DividerText>{props.text}</DividerText>
        <SingleDivider />
      </DividerWrapper>
    )
  }
  return (
    <NoTextDividerWrapper style={props.wrapperStyle}>
      <NoTextDivider color={props.dividerColor} />
    </NoTextDividerWrapper>
  )
}
