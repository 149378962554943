import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ChatMessageDeliveryStatus } from '@traba/types'
import { Icon, Row } from 'src/components/base'

export const BULLET_CHAR = '\u2022'

interface Props {
  deliveryStatus?: ChatMessageDeliveryStatus
}

const MessageItemStatus = ({ deliveryStatus }: Props) => {
  switch (deliveryStatus) {
    case ChatMessageDeliveryStatus.UNDELIVERED:
      return (
        <Row alignCenter>
          <Text variant={'body2'} mx={theme.space.xxxs}>
            {BULLET_CHAR}
          </Text>
          <Icon
            name="clock"
            size={16}
            color={theme.colors.Grey60}
            style={{ marginTop: theme.space.xxxs }}
          />
        </Row>
      )
    case ChatMessageDeliveryStatus.MODERATED_EXPLICIT:
    case ChatMessageDeliveryStatus.MODERATED_PAY_QUESTIONS:
      return (
        <Row alignCenter>
          <Text variant={'body2'} mx={theme.space.xxxs}>
            {BULLET_CHAR}
          </Text>
          <Icon
            name="info"
            size={16}
            color={theme.colors.Red60}
            style={{ marginTop: theme.space.xxxs }}
          />
        </Row>
      )
    default:
      return (
        <Row alignCenter>
          <Text variant={'body2'} mx={theme.space.xxxs}>
            {BULLET_CHAR}
          </Text>
          <Icon name="checkmark" size={16} color={theme.colors.Green70} />
        </Row>
      )
  }
}

export default MessageItemStatus
