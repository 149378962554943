import { theme } from '@traba/theme'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  width: ${({ width = 400 }: { width?: number }) => width}px;
  padding-top: ${theme.space.xs}px;
  padding: ${theme.space.med}px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const DrawerBody = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
