import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { WorkerScores } from '@traba/types'
import { useQuery } from 'react-query'

const getBulkWorkerScores = async (workerIds: string[]) => {
  try {
    const { data } = await trabaApi.post(`workers/scores`, { workerIds })
    return data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

export const useBulkWorkerScores = (workerIds: string[]) => {
  const {
    isLoading,
    isError,
    data: bulkWorkerScores,
    error,
    isFetched,
  } = useQuery(['bulkWorkerScores', { workerIds }], () =>
    getBulkWorkerScores(workerIds),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    bulkWorkerScores,
  }
}

export const indexByWorkerId = (
  bulkWorkerScores: (WorkerScores & { workerId: string })[],
) => {
  return Object.fromEntries(
    bulkWorkerScores?.map((workerScores) => [
      workerScores.workerId,
      workerScores,
    ]) ?? [],
  )
}
