import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftSignupNotification } from '@traba/types'
import { ChangeEvent, useState } from 'react'
import { Button, Input, Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'

type FFListFeedbackModalProps = {
  notification: WorkerShiftSignupNotification
  handleClose: () => void
  isOpen: boolean
  isForAttributeList: boolean
}

export default function FFListFeedbackModal({
  handleClose,
  isOpen,
  notification,
  isForAttributeList,
}: FFListFeedbackModalProps) {
  const [feedback, setFeedback] = useState<string>('')
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Forward fill feedback'}
      size={MODAL_SIZE.EXTRA_SMALL}
    >
      <Row
        style={{
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          alignContent: 'center',
        }}
      >
        <Text>Why is the position wrong? What is the correct position?</Text>
        <Input
          full
          label="Feedback"
          value={feedback}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            setFeedback(ev.target.value)
          }}
        />
        <Row
          style={{ marginTop: theme.space.med, marginBottom: theme.space.med }}
        >
          <Button
            full
            onClick={() => {
              window.analytics.track('FF Notification Feedback', {
                workerId: notification.workerId,
                shiftId: notification.shiftId,
                v1Index: notification.immutableIndex,
                attributeIndex: notification.attributeListIndex,
                workerShiftSignupNotificationId:
                  notification.workerShiftSignupNotificationId,
                isForAttributeList,
                feedback,
              })
              handleClose()
            }}
          >
            Submit
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}
