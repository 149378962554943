import { theme } from '@traba/theme'
import { Col } from 'src/components/base'
import styled from 'styled-components'

export const MainWrapper = styled.div`
  height: 100%;
`

export const ContentCol = styled(Col)`
  overflow: auto;
`

export const Content = styled.div`
  padding: ${theme.space.xs}px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    padding: ${theme.space.med}px;
  }
`
