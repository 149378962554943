import { Text } from '@traba/react-components'
import React from 'react'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'

interface Props {
  isLoading: boolean
}

export const AnnouncementEmptyLoading = ({ isLoading }: Props) => {
  return isLoading ? (
    <CircularProgress size="small" />
  ) : (
    <Text>No announcements for this shift yet</Text>
  )
}
