import {
  MAX_ANNOUNCEMENT_MESSAGE_LENGTH,
  MIN_MINUTES_FOR_FUTURE_ANNOUNCEMENT,
  SCHEDULING_OPTION,
} from '@traba/consts'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { MediaUploader } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExtendedShift } from '@traba/types'
import { InputStatus } from '@traba/types'
import { addMinutes } from 'date-fns'
import { useMemo, useState } from 'react'
import { Row, Input, Button, DatePicker } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { IMenuItem, SelectDropdown } from 'src/components/base/Select/Select'
import SelectableCard from 'src/components/base/SelectableCard/SelectableCard'
import Toggle from 'src/components/base/Toggle'
import { useUserContext } from 'src/context/user/UserContext'
import { useShiftAnnouncements } from 'src/hooks/useShiftAnnouncements'
import { getAnnouncementPrefillSuggestions } from 'src/utils/announcementsUtils'

const SCHEDULING_OPTIONS: IMenuItem[] = [
  {
    label: SCHEDULING_OPTION.SEND_NOW,
    value: SCHEDULING_OPTION.SEND_NOW,
  },
  {
    label: SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE,
    value: SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE,
  },
]

interface Props {
  shift: ExtendedShift
  onSubmitOrCancel: () => void
}

export const AddAnnouncementSection = ({ shift, onSubmitOrCancel }: Props) => {
  const [content, setContent] = useState('')
  const [file, setFile] = useState<File | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [suggestions, setSuggestions] = useState(
    getAnnouncementPrefillSuggestions(shift),
  )
  const [showInBizPortal, setShowInBizPortal] = useState(false)
  const { state } = useUserContext()
  const { internalUsers } = useInternalUsers({
    emails: [state.userProfile?.email ?? ''],
  })
  const { handleUpload } = useFileUploader()
  const { showError, handleError, showSuccess } = useAlert()
  const { refetchAnnouncements, sendAnnouncement, isSendingAnnouncement } =
    useShiftAnnouncements(shift.id)
  const [selectedSchedulingOption, setSelectedSchedulingOption] =
    useState<SCHEDULING_OPTION>(SCHEDULING_OPTION.SEND_NOW)
  const [scheduledFor, setScheduledFor] = useState<Date | null>(null)
  const [showScheduledForDatePicker, setShowScheduledForDatePicker] =
    useState<boolean>(false)

  useMemo(() => {
    switch (selectedSchedulingOption) {
      case SCHEDULING_OPTION.SEND_NOW: {
        setShowScheduledForDatePicker(false)
        setScheduledFor(null)
        break
      }
      case SCHEDULING_OPTION.SCHEDULE_FOR_FUTURE: {
        setShowScheduledForDatePicker(true)
        break
      }
    }
  }, [selectedSchedulingOption])

  async function onChangeFile(f: File | undefined) {
    setFile(f)
    if (f) {
      const url = await handleUpload({
        fileType: FileType.SHIFT_IMAGES,
        media: f,
        userId: internalUsers?.[0].id,
        resizeOptions: {
          maxWidth: 800,
          maxHeight: 800,
          quality: 80,
          compressionFormat: 'JPEG',
        },
      })
      setImageUrl(url)
    }
  }

  async function onDeleteFile() {
    setFile(undefined)
    setImageUrl(undefined)
  }

  function getMinDateForScheduleAhead() {
    return addMinutes(new Date(), MIN_MINUTES_FOR_FUTURE_ANNOUNCEMENT)
  }

  return (
    <>
      <Row mt={theme.space.xs}>
        <Toggle
          label={'Show in business portal'}
          buttonState={showInBizPortal}
          runOnChange={() => setShowInBizPortal(!showInBizPortal)}
        />
      </Row>
      <Row mb={theme.space.ms} mt={theme.space.xs}>
        <Text variant="h6">Schedule your announcement</Text>
      </Row>
      <Row>
        <SelectDropdown
          handleSelect={(value) =>
            setSelectedSchedulingOption(value as SCHEDULING_OPTION)
          }
          menuItems={SCHEDULING_OPTIONS}
          value={selectedSchedulingOption}
          style={{
            minWidth: 205,
          }}
        />
      </Row>
      {showScheduledForDatePicker && (
        <Row mt={theme.space.sm}>
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={(dateTime) => {
              setScheduledFor(dateTime)
            }}
            isClearable={false}
            inlineLabel={true}
            label="When?"
            date={scheduledFor}
            defaultDate={new Date()}
            timezone={shift.timezone}
            minDate={getMinDateForScheduleAhead()}
          />
        </Row>
      )}
      <Text variant="h6" mt={theme.space.xs}>
        Quick fills
      </Text>
      <Text variant="body2" mb={theme.space.xs}>
        Select from below to pre-fill the announcement.
      </Text>
      <Row wrap style={{ gap: theme.space.med }} mb={theme.space.sm}>
        {suggestions.map((suggestion) => (
          <SelectableCard
            onMouseEnter={() => {
              if (!suggestion.selected) {
                setContent(`${content}${suggestion.content}`)
              }
            }}
            onMouseLeave={() => {
              if (!suggestion.selected) {
                setContent(content.replace(suggestion.content, ''))
              }
            }}
            key={suggestion.title}
            label={suggestion.title}
            onClick={() => {
              if (suggestion.selected) {
                setContent(content.replace(suggestion.content, ''))
              }

              setSuggestions(
                suggestions.map((s) =>
                  s.title === suggestion.title
                    ? { ...s, selected: !s.selected }
                    : s,
                ),
              )
            }}
            selected={suggestion.selected}
          />
        ))}
      </Row>
      <Input
        rows={5}
        label="Your message to workers on this shift"
        type="textarea"
        className="xs-12"
        value={content}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setContent(e.target.value)}
        inputStatus={
          content && content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={`Please limit your message to under ${MAX_ANNOUNCEMENT_MESSAGE_LENGTH} characters. You have used ${content.length} characters.`}
        full
      />
      <MediaUploader
        fileType="image"
        label="Add image"
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={(error) => {
          showError(error, 'Something went wrong')
        }}
        maxFileSizeMB={10}
        initialPreviewSrc={imageUrl}
      />
      <Row justifyEnd my={theme.space.sm}>
        <Button
          onClick={onSubmitOrCancel}
          variant={ButtonVariant.OUTLINED}
          style={{ marginRight: theme.space.xxs }}
        >
          Cancel
        </Button>
        <Button
          loading={isSendingAnnouncement}
          disabled={
            content === '' ||
            content.length > MAX_ANNOUNCEMENT_MESSAGE_LENGTH ||
            isSendingAnnouncement
          }
          onClick={() =>
            sendAnnouncement({
              shiftId: shift.id,
              content,
              imageUrl: imageUrl,
              opsOnly: !showInBizPortal,
              scheduledFor: scheduledFor ?? new Date(),
              onSubmitOrCancel,
              showSuccess,
              handleError,
              refetch: refetchAnnouncements,
            })
          }
          variant={ButtonVariant.FILLED}
          style={{
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
          }}
        >
          Send
        </Button>
      </Row>
      <Divider />
    </>
  )
}
