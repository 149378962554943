import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Badge,
  Button,
  Col,
  CopyTextIcon,
  Icon,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import HeaderWithDetailItem from 'src/components/ShiftDetails/ShiftDetailsItem'
import { VettingCampaignStatusBadge } from 'src/components/VettingCampaignStatusBadge'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useWorkerVettingCampaign } from 'src/hooks/useWorkerVetting'
import {
  WorkerVettingCampaignStatus,
  WorkerVettingSessionStatus,
} from 'src/types/worker-vetting'
import WorkerVettingSessions from './components/WorkerVettingSessions/WorkerVettingSessions'

export default function VettingCampaignDetailsScreen() {
  const { campaignId } = useParams<{ campaignId: string }>()
  const navigate = useNavigate()
  const tz = useTimezonedDates()

  const {
    campaign,
    isLoading: campaignLoading,
    cancelWorkerVettingCampaign,
  } = useWorkerVettingCampaign(campaignId || '')

  const { refetchActiveQueries } = useActiveQueries()

  if (campaignLoading) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <CircularProgress
            size={75}
            sx={{
              position: 'absolute',
              top: '200px',
              left: '50%',
              marginTop: '-18px',
              marginLeft: '-18px',
            }}
          />
        </Row>
      </MainLayout>
    )
  }
  if (!campaign) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <Text variant="h4">No Campaign found with id: {campaignId}</Text>
        </Row>
      </MainLayout>
    )
  }

  const handleCancelCampaign = async () => {
    await cancelWorkerVettingCampaign(campaignId || '')
    await refetchActiveQueries()
  }

  const workerVettingSessions = campaign.workerVettingSessions || []

  const endStateSessions = workerVettingSessions.filter(
    (session) => session.status !== WorkerVettingSessionStatus.active,
  )

  return (
    <MainLayout title="Worker Vetting Campaign Details">
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Go back
      </Button>
      <Divider />
      <Col mt={theme.space.sm} mb={theme.space.sm}>
        <Row justifyBetween alignCenter>
          <Row alignCenter>
            <Text variant="h4" style={{ marginRight: theme.space.xs }}>
              {campaign.title}
            </Text>
            <VettingCampaignStatusBadge status={campaign.status} />
          </Row>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={handleCancelCampaign}
            disabled={campaign.status !== WorkerVettingCampaignStatus.active}
          >
            Cancel Campaign
          </Button>
        </Row>
      </Col>
      {/* Info Fields */}
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <HeaderWithDetailItem title="Campaign Id">
            {campaign.id} <CopyTextIcon textToCopy={campaign.id} />
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Category">
            {campaign.type}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem
            title="Vetting Sessions"
            tooltipText="This is all complete, exited, and canceled sessions"
          >
            <Text variant="body1">
              {endStateSessions.length}/{workerVettingSessions.length}
            </Text>
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Initial Message">
            {campaign.initialMessage}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Created By">
            {campaign.initiatedBy}
          </HeaderWithDetailItem>
          {campaign.customQualifiedExitMessage && (
            <HeaderWithDetailItem title="Custom qualified exit message">
              {campaign.customQualifiedExitMessage}
            </HeaderWithDetailItem>
          )}
        </Col>
        <Col>
          <HeaderWithDetailItem title="Created At">
            {tz.getDateTime(campaign.createdAt)}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Deadline">
            {tz.getDateTime(campaign.deadline)}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Shift Id">
            {campaign.shiftId}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Role Id">
            {campaign.roleId}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Regions">
            {campaign.regionIds.map((regionId) => (
              <Badge
                title={regionId}
                key={regionId}
                style={{ marginRight: theme.space.xxs }}
              />
            ))}
          </HeaderWithDetailItem>
          {campaign.customUnqualifiedExitMessage && (
            <HeaderWithDetailItem title="Custom Unqualified exit message">
              {campaign.customUnqualifiedExitMessage}
            </HeaderWithDetailItem>
          )}
        </Col>
        <Col>
          <HeaderWithDetailItem title="Prompt Details">
            {campaign.promptDetails}
          </HeaderWithDetailItem>
          <HeaderWithDetailItem title="Questions">
            <ul>
              {campaign.questions.map((question, index) => (
                <li key={index}>{question}</li>
              ))}
            </ul>
          </HeaderWithDetailItem>
        </Col>
      </Row>
      <Divider />
      {/* Worker Vetting Sessions */}
      <WorkerVettingSessions campaign={campaign} />
    </MainLayout>
  )
}
