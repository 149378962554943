import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { Col, Modal } from 'src/components/base'
import { ModalProps } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import Toggle from 'src/components/base/Toggle'
import { UserRolePermission } from 'src/context/user/types'
import { useApiWithFeedback } from 'src/hooks/useApi'
import { usePermissions } from 'src/hooks/usePermissions'

export type ShiftSettingsModalProps = {
  shift: Shift
} & Omit<ModalProps, 'children'>

export default function ShiftSettingsModal({
  shift,
  ...props
}: ShiftSettingsModalProps) {
  const [hasPreShiftConfirmationRobocall, setHasPreShiftConfirmationRobocall] =
    useState(shift.hasPreShiftConfirmationRobocall ?? true) // This defaults to TRUE

  const manageShiftAccess = usePermissions([
    UserRolePermission.ManageShiftSettings,
  ])

  const { isLoading: isLoadingPatchShift, apiCall: patchShift } =
    useApiWithFeedback('patch', `shifts?shiftIds=${shift.shiftId}`)

  async function handleTogglePreShiftConfirmationRobocall() {
    const updatedFlagValue = !hasPreShiftConfirmationRobocall
    try {
      setHasPreShiftConfirmationRobocall(updatedFlagValue)
      await patchShift({
        shiftUpdates: { hasPreShiftConfirmationRobocall: updatedFlagValue },
      })
    } catch (err) {
      setHasPreShiftConfirmationRobocall(!updatedFlagValue)
    }
  }

  return (
    <Modal
      {...props}
      size={MODAL_SIZE.EXTRA_SMALL}
      title="Shift Settings"
      style={{ maxWidth: theme.media.minSmall }}
    >
      <Col
        style={{
          alignItems: 'space-between',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space.sm,
        }}
      >
        <Toggle
          label={'Pre-shift confirmation Robocalls'}
          buttonState={hasPreShiftConfirmationRobocall}
          runOnChange={handleTogglePreShiftConfirmationRobocall}
          disabled={!manageShiftAccess}
          color={theme.colors.White}
        />
      </Col>
      {isLoadingPatchShift && (
        <CircularProgress
          size={'20px'}
          style={{ marginTop: 12, marginLeft: 6 }}
        />
      )}
    </Modal>
  )
}
