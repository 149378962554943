import { CircularProgress, Switch } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { DEFAULT_TIMEZONE } from '@traba/consts'
import { useAlert } from '@traba/context'
import { useDeepEffect } from '@traba/hooks'
import {
  RoleSearchSelect,
  SearchSelect,
  SupervisorForShiftSearchSelect,
  Text,
} from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  GenderPreference,
  RequiredMultiShiftType,
  Company,
  Role,
  ForwardFillMax,
  InvoiceChargeCycle,
  Locations,
  ParentInvoiceGroup,
  PaymentType,
  Roster,
  ShiftNotificationStatus,
  Shift,
  ShiftPayType,
  ShiftRequest,
  TierLevel,
  Worker,
  User,
  ShiftInvitationDto,
  ShiftSignupStatus,
} from '@traba/types'
import {
  getRoleSearchSelectErrorMessage,
  getSelectSupervisorForBookingShiftErrorMessage,
} from '@traba/utils'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { compact } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WeekdayStr } from 'rrule'
import { Autocomplete } from 'src/components/Autocomplete/Autocomplete'
import { Button, Col, Icon, Input, Row, Select } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useModal } from 'src/components/base/Modal/Modal'
import { MultiSelector } from 'src/components/base/MultiSelector/MultiSelector.ui'
import { IMenuItem } from 'src/components/base/Select/Select'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { WeeklyPayWarning } from 'src/components/WeeklyPayWarning'
import { useUserContext } from 'src/context/user/UserContext'
import { getQueryParams } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import {
  CreateShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import {
  calculateEstimatedPay,
  validatePayRate,
} from 'src/modals/EditShiftModal/utils'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { formatDuration } from 'src/utils/dateUtils'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import {
  calculateBilledShiftTime,
  calculatePaidShiftTime,
  convertCentsToDollars,
  convertPayRateToCents,
  getScheduledBreakTotal,
} from 'src/utils/moneyUtils'
import {
  MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB,
  genderPreferenceOptions,
  paymentTypeOptions,
  minimumTierOptions,
  validateDynamicOverbookAllowed,
  validateOverbook,
  validateSlots,
  signupStatusOptions,
  forwardFillTypeOptions,
} from 'src/utils/shiftFormUtils'
import {
  getAddressString,
  getUniqueValidatedEmails,
  validateEmail,
} from 'src/utils/stringUtils'

import { SearchInviteWorkersSection } from '../SearchInviteWorkers/SearchInviteWorkersSection'
import { SelectBreakForm } from '../SelectBreakForm'
import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'
import { ShiftScheduleSummary } from '../ShiftScheduleSummary'
import { SummaryLine } from './PostShiftForm.styles'
import ShiftTimePicker from './ShiftTimePicker'
import {
  initialShiftRequest,
  populateCreateShiftRequestFromShiftRequestAndShift,
  validateShiftCreationForm,
} from './utils'

type PostShiftFormProps = {
  company: Company
  locations?: Locations[]
  roles?: Role[]
  companyUsers?: User[]
  rosters?: Roster[]
  workersWorkedWithCompany?: Record<string, Worker[]>
  minHourlyPayRate: number
}

export const PostShiftForm = (props: PostShiftFormProps) => {
  const {
    company,
    locations,
    roles,
    companyUsers = [],
    rosters,
    minHourlyPayRate,
  } = props
  const navigate = useNavigate()
  const { state } = useUserContext()
  /* Input States */
  /** The params here are stored in the URI so it can be shared */
  const [search, setSearch] = useSearchParams()

  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()

  const [createShiftRequest, setCreateShiftRequest] =
    useState<CreateShiftRequest>(initialShiftRequest(company))
  const { activeInvoiceGroups, refetch: refetchInvoiceGroups } =
    useInvoiceGroups(company.companyId)

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const [businessStartTime, setBusinessStartTime] = useState<Date | null>(null)
  const [buttonTitle, setButtonTitle] = useState<string>('Create Shift Request')
  const [isInvalidBuffer, setIsInvalidBuffer] = useState<boolean>(false)
  const [duplicateShiftIdInput, setDuplicateShiftIdInput] = useState<string>(
    search.get('duplicateShiftId') || '',
  )

  const { showError } = useAlert()
  const { virtualRosters } = useVirtualRosters(
    company.companyId,
    createShiftRequest.locationId,
    createShiftRequest.roleId,
  )

  const createOrEditInvoiceGroupModal = useModal()

  /*
   * Options
   */
  const locationOptions: IMenuItem[] =
    locations?.map((location) => {
      return {
        label: getAddressString(location.address),
        secondaryLabel: location.name,
        value: location.locationId,
      }
    }) || []

  const roleMap: Map<string, Role> = useMemo(
    () => new Map(roles?.map((role) => [role.roleId, role]) || []),
    [roles],
  )

  const roleSearchSelectErrorMsg = getRoleSearchSelectErrorMessage(
    createShiftRequest.locationId,
    roleMap.get(createShiftRequest.roleId),
  )

  const additionalEmailsOptions = useMemo(() => {
    return compact(
      companyUsers
        .filter(
          (companyUser) => companyUser.uid !== createShiftRequest.supervisorId,
        )
        .map((companyUser) => companyUser.email),
    )
  }, [companyUsers, createShiftRequest.supervisorId])

  const REPEAT_ON_OPTIONS: { value: WeekdayStr; label: string }[] = [
    { value: 'MO', label: 'Mon' },
    { value: 'TU', label: 'Tue' },
    { value: 'WE', label: 'Wed' },
    { value: 'TH', label: 'Thu' },
    { value: 'FR', label: 'Fri' },
    { value: 'SA', label: 'Sat' },
    { value: 'SU', label: 'Sun' },
  ]

  const payTypeOptions = [
    { value: ShiftPayType.HOURLY, label: 'Hourly' },
    { value: ShiftPayType.UNIT, label: 'Unit' },
  ]

  const rmsaShiftOptions = [
    { value: RequiredMultiShiftType.None, label: 'None' },
    { value: RequiredMultiShiftType.ALL_IN_REQUEST, label: 'All In Request' },
  ]

  const invoiceOptions = [
    { value: InvoiceChargeCycle.WEEKLY, label: 'Weekly' },
    { value: InvoiceChargeCycle.GROUPED, label: 'Grouped' },
  ]

  /*************** */

  const totalShiftTime = differenceInMinutes(
    createShiftRequest.schedules[0].endTime,
    createShiftRequest.schedules[0].startTime,
  )

  const totalBreakTime = getScheduledBreakTotal(
    createShiftRequest.scheduledBreaks || [],
  )

  const totalBilledTime = calculateBilledShiftTime(
    businessStartTime,
    createShiftRequest.schedules[0].endTime,
    totalBreakTime,
    createShiftRequest.breakType,
  )

  const totalPaidTime = calculatePaidShiftTime(
    totalBreakTime,
    totalShiftTime,
    createShiftRequest.breakType,
  )

  const { payString: estimatedPay } = calculateEstimatedPay(
    createShiftRequest.payRate,
    createShiftRequest.payType,
    totalPaidTime,
    createShiftRequest.numberOfUnits || 0,
    createShiftRequest.slotsRequested,
  )

  const selectedLocation = locations?.find(
    (loc) => loc.locationId === createShiftRequest.locationId,
  )
  const selectedLocationMenuItem = locationOptions.find(
    (loc) => loc.value === createShiftRequest.locationId,
  )
  const selectedParkingLocationMenuItem = locationOptions.find(
    (loc) => loc.value === createShiftRequest.parkingLocationId,
  )

  const selectedRole = roleMap.get(createShiftRequest.roleId)

  useEffect(() => {
    if (search.get('duplicateShiftId')) {
      handleDuplicateShift()
    }
  }, [])

  /**
   * Handlers
   */
  const handleWeekdaySelector = (selectedWeekdays: WeekdayStr[]) => {
    setCreateShiftRequest((prev) => {
      return {
        ...prev,
        schedules: [
          {
            ...prev.schedules[0],
            recurringSchedule: {
              freq: 'WEEKLY',
              interval: 1,
              endDate:
                createShiftRequest.schedules[0].recurringSchedule?.endDate ||
                createShiftRequest.schedules[0].endTime,
              repeatOn: selectedWeekdays,
            },
          },
        ],
      }
    })
  }

  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequest((prev) => {
        return {
          ...prev,
          parentInvoiceGroupId: newGroup.id,
        }
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const [duplicateLoading, setDuplicateLoading] = useState(false)

  const handleDuplicateShift = async () => {
    setDuplicateLoading(true)
    try {
      const res = await trabaApi.get<Shift | undefined>(
        `shifts/${duplicateShiftIdInput}`,
      )
      const shiftToDuplicate = res.data
      if (shiftToDuplicate === undefined) {
        throw Error('shift-not-found')
      }
      if (shiftToDuplicate?.companyId !== company.companyId) {
        showError(
          `The selected shift belongs to a different company, please select one for ${company.employerName}`,
          'Shift Does Not Belong To Company',
        )
        throw Error('wrong-company')
      }
      const shiftRequestResponse = await trabaApi.get<ShiftRequest | undefined>(
        `shift-requests/${shiftToDuplicate.shiftRequestId}`,
      )
      if (!shiftRequestResponse.data) {
        showError('Shift request not found')
        throw Error('shift-request-not-found')
      }
      const shiftRequest = shiftRequestResponse.data
      const duplicatedShiftReq =
        populateCreateShiftRequestFromShiftRequestAndShift(
          shiftRequest,
          shiftToDuplicate,
          createShiftRequest,
        )
      setCreateShiftRequest(duplicatedShiftReq)
      if (shiftToDuplicate.businessStartTime) {
        setBusinessStartTime(shiftToDuplicate.businessStartTime)
      }
      //If there are invitations we must fetch the workers and set invitedWorkers
      if (
        shiftRequest.shiftInvitations &&
        shiftRequest.shiftInvitations.length > 0
      ) {
        const workerIds: string[] = shiftRequest.shiftInvitations.flatMap(
          (invitation) => invitation.workers.map((worker) => worker.workerId),
        )

        const workersResponse = await trabaApi.post<PopulatedWorker[]>(
          '/workers/query-workers-with-details',
          {
            workerIds,
          },
        )
        const invitedWorkers = workersResponse.data

        if (invitedWorkers.length !== workerIds.length) {
          showError('Error fetching invited workers')
        }
        setWorkersToInvite(invitedWorkers)
      }

      setSearch({ duplicateShiftId: duplicateShiftIdInput })
      window.analytics?.track(`Ops User Duplicated Shift`, {
        shiftId: duplicateShiftIdInput,
        initiatedBy: state.userProfile?.email || 'OPS',
      })
    } catch (err: unknown) {
      if (err instanceof Error && err.message === 'wrong-company') {
        setDuplicateShiftIdInput('')
      }
      showError(getErrorMessage(err), 'Error duplicating shift')
    } finally {
      setDuplicateLoading(false)
    }
  }

  useDeepEffect(() => {
    const handleInvitedWorkersSelection = () => {
      if (!workersToInvite.length) {
        setCreateShiftRequest((prevCreateShiftRequest) => ({
          ...prevCreateShiftRequest,
          shiftInvitations: undefined,
        }))
        return
      }
      setCreateShiftRequest((prevCreateShiftRequest) => ({
        ...prevCreateShiftRequest,
        shiftInvitations: [
          {
            batch: 1,
            workers: workersToInvite
              .filter((worker) => !!worker.id)
              .map((worker, index) => {
                const shiftInvitation: ShiftInvitationDto = {
                  workerId: worker.id || worker.uid,
                  index: index + 1,
                }
                return shiftInvitation
              }),
          },
        ],
      }))
    }

    handleInvitedWorkersSelection()
  }, [workersToInvite])

  const { createShiftReqLoading, createShiftRequest: submitShiftRequest } =
    useShiftRequests()

  const timezone = selectedLocation?.timezone || 'America/New_York'
  const tz = useTimezonedDates(timezone)

  const addEarlyArrivalBuffer = async (shiftRequestId: string) => {
    if (!businessStartTime) {
      return
    }

    try {
      const shiftsResponse = await trabaApi.get<Shift[]>(
        `/shift-requests/${shiftRequestId}/shifts`,
      )
      const shifts = shiftsResponse.data
      const shiftUpdates = {
        businessStartTime: businessStartTime
          ? new Date(businessStartTime)
          : undefined,
        startTime: new Date(createShiftRequest.schedules[0].startTime),
        endTime: new Date(createShiftRequest.schedules[0].endTime),
      }
      const shiftIds = shifts.map((s) => s.shiftId).join(',')
      const queryParamsString = getQueryParams([['shiftIds', shiftIds]])
      const res = await trabaApi.patch(`shifts${queryParamsString}`, {
        shiftUpdates,
      })
      return res.data
    } catch (e) {
      showError('Error adding early arrival buffer')
    }
  }

  const handleRoleChange = (roleId: string) => {
    const newRole = roleMap.get(roleId)
    if (!newRole) {
      return
    }

    setCreateShiftRequest((prev) => {
      return {
        ...prev,
        roleId,
        payRate: newRole.defaultPayRate,
        hourlyRate: newRole.defaultPayRate,
        videoIds: newRole.videoIds ?? [],
        genderPreference: newRole.genderPreference,
      }
    })
  }

  const handleSubmitShiftRequest = async (navigateToCompany: boolean) => {
    try {
      setButtonTitle('Creating Shift Request...')
      const res = await submitShiftRequest(createShiftRequest)
      await addEarlyArrivalBuffer(res.shiftRequestId)
      setButtonTitle('Create Shift Request')
      if (navigateToCompany) {
        navigate(`/field-monitor/${res.firstShiftId}`)
      }
    } catch (e) {
      setButtonTitle('Create Shift Request')
      //Do nothing
    }
  }

  const onChangeSupervisor = useCallback(
    (supervisorId: string) => {
      setCreateShiftRequest((prev) => {
        return {
          ...prev,
          supervisorId: supervisorId,
        }
      })
    },
    [setCreateShiftRequest],
  )

  const supervisorSearchSelectErrorMsg = useMemo(
    () =>
      getSelectSupervisorForBookingShiftErrorMessage({
        locationId: createShiftRequest.locationId,
        supervisor: companyUsers.find(
          (s) => s.uid === createShiftRequest.supervisorId,
        ),
        isRegionalAccessEnabled: hotSettings?.enableRegionalAccessPhase2,
      }),
    [
      companyUsers,
      createShiftRequest.locationId,
      createShiftRequest.supervisorId,
      hotSettings?.enableRegionalAccessPhase2,
    ],
  )

  const oldOverbook = (
    <ShiftPostingInputContainerSection
      label="Overbook Slots (optional)"
      input={
        <Row style={{ width: 300 }}>
          <DeprecatedNumberInput
            value={createShiftRequest.overbookSlotsRequested}
            setter={(numReq) => {
              setCreateShiftRequest((prev) => {
                return {
                  ...prev,
                  overbookSlotsRequested: numReq > 0 ? numReq : undefined,
                }
              })
            }}
            placeholder={`e.g. ${createShiftRequest.slotsRequested + 1}`}
            error={
              !validateOverbook(
                createShiftRequest.slotsRequested,
                createShiftRequest.overbookSlotsRequested,
              )
            }
            helperText="Must be ≥ slots requested"
          />
        </Row>
      }
    />
  )

  const updatedDynamicOverbooking = (
    <>
      <ShiftPostingInputContainerSection
        label="Enable dynamic overbooking"
        input={
          <Row alignCenter>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={createShiftRequest.enableDynamicOverbooking}
              onClick={() => {
                if (
                  !createShiftRequest.enableDynamicOverbooking &&
                  createShiftRequest.slotsRequested <
                    MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
                ) {
                  alert(
                    'Dynamic overbooking can only be enabled on shifts with 5 or more slots requested.',
                  )
                  return
                }
                setCreateShiftRequest((prev) => {
                  return {
                    ...prev,
                    enableDynamicOverbooking: !prev.enableDynamicOverbooking,
                    // Whenever this switch is toggled, completely reset overbook
                    // slots. This dramatically simplifies state management.
                    overbookSlotsRequested: undefined,
                  }
                })
              }}
            />
            <InfoTooltip
              title={`When enabled, this shift's overbook slots will be continuously adjusted (no
                         action needed on your part). If you want to override this and manually set the
                         overbook slots, disable this and add your desired overbook slots in the
                         "Overbook Slots" field that appears.`}
              tooltipProps={{
                leaveDelay: 500,
                placement: 'top',
                arrow: true,
              }}
            />
          </Row>
        }
        right
      />
      {!createShiftRequest.enableDynamicOverbooking && oldOverbook}
    </>
  )

  if (isLoadingHotSettings) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxxl} />
      </Row>
    )
  }

  const earlyArrivalTimeBufferInMinutes = getEarlyArrivalTimeBufferInMinutes({
    shiftStartTime: createShiftRequest.schedules[0].startTime,
    businessStartTime,
  })

  return (
    <Col>
      <ShiftPostingInputContainer title="Duplicate Shift">
        <Row alignCenter justifyCenter fullWidth>
          <ShiftPostingInputContainerSection
            label="Shift ID"
            input={
              <Row style={{ width: 300 }}>
                <Input
                  value={duplicateShiftIdInput}
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => setDuplicateShiftIdInput(e.target.value)}
                  full
                />
              </Row>
            }
          />
          <Button
            onClick={handleDuplicateShift}
            variant={ButtonVariant.FILLED}
            style={{ marginLeft: theme.space.xs }}
            disabled={!duplicateShiftIdInput}
            loading={duplicateLoading}
          >
            Duplicate
          </Button>
        </Row>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Shift Details">
        <Col>
          <Row
            fullWidth
            style={{ justifyContent: 'space-around' }}
            mb={theme.space.sm}
          >
            <ShiftPostingInputContainerSection
              label="Location"
              input={
                <Row style={{ width: 300, textWrap: '' }}>
                  <SearchSelect
                    options={locationOptions}
                    selectItem={selectedLocationMenuItem}
                    handleSelect={(item) => {
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          locationId: item?.value || '',
                          schedules: [
                            {
                              ...prev.schedules[0],
                              timeZone: timezone,
                            },
                          ],
                        }
                      })
                    }}
                    width={300}
                    shouldAlsoSearchSecondaryLabel={true}
                  />
                </Row>
              }
            />
            <ShiftPostingInputContainerSection
              label="Supervisor"
              input={
                <SupervisorForShiftSearchSelect
                  placeholder="-"
                  selectedSupervisorId={createShiftRequest.supervisorId}
                  selectedLocationId={createShiftRequest.locationId}
                  allSupervisors={companyUsers}
                  handleSupervisorChange={onChangeSupervisor}
                  disabled={!createShiftRequest.locationId}
                  disabledTooltipText="Please select a work site location before selecting a supervisor."
                  label=""
                  errorMessage={supervisorSearchSelectErrorMsg}
                  showEmailForUser
                  style={{ minWidth: '40%' }}
                />
              }
            />
          </Row>
          <Row
            fullWidth
            style={{ justifyContent: 'space-around' }}
            mb={theme.space.sm}
          >
            <ShiftPostingInputContainerSection
              label="Role"
              input={
                <RoleSearchSelect
                  roles={roles ?? []}
                  selectedRoleId={createShiftRequest.roleId}
                  selectedLocationId={createShiftRequest.locationId}
                  handleRoleChange={handleRoleChange}
                  errorMessage={roleSearchSelectErrorMsg}
                />
              }
            />
            <ShiftPostingInputContainerSection
              label="Parking Location (optional)"
              input={
                <Row style={{ width: 300 }}>
                  <SearchSelect
                    options={locationOptions}
                    selectItem={selectedParkingLocationMenuItem}
                    handleSelect={(item) => {
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          parkingLocationId: item?.value,
                        }
                      })
                    }}
                    width={300}
                    shouldAlsoSearchSecondaryLabel={true}
                    showClearButton
                  />
                </Row>
              }
            />
          </Row>
          {createShiftRequest.supervisorId && (
            <Row fullWidth style={{ justifyContent: 'space-around' }}>
              <ShiftPostingInputContainerSection
                label="Additional team members (optional)"
                input={
                  <Row style={{ width: 350 }}>
                    <Autocomplete
                      label="Email addresses"
                      value={createShiftRequest.additionalEmails || []}
                      options={additionalEmailsOptions}
                      onChangeValues={(_, value) => {
                        setCreateShiftRequest((prevCreateShiftRequest) => ({
                          ...prevCreateShiftRequest,
                          additionalEmails: getUniqueValidatedEmails(value),
                        }))
                      }}
                      errorMessage="Email address is invalid"
                      validateInput={validateEmail}
                    />
                  </Row>
                }
              />
            </Row>
          )}
        </Col>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Slots">
        <Col>
          <Row fullWidth style={{ justifyContent: 'space-around' }}>
            <ShiftPostingInputContainerSection
              label="Slots Requested"
              input={
                <Row style={{ width: 300 }}>
                  <DeprecatedNumberInput
                    value={createShiftRequest.slotsRequested}
                    setter={(numReq) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          slotsRequested: numReq,
                          minSlotsRequested: numReq,
                        }
                      })
                    }
                    placeholder="Slots Requested"
                    error={
                      !validateSlots(
                        createShiftRequest.slotsRequested,
                        createShiftRequest.overbookSlotsRequested,
                      ) ||
                      !validateDynamicOverbookAllowed(
                        createShiftRequest.slotsRequested,
                        !!createShiftRequest.enableDynamicOverbooking,
                      )
                    }
                    helperText={`Must be ${
                      createShiftRequest.enableDynamicOverbooking
                        ? '≥ 5 because dynamic overbooking is enabled.'
                        : '> 0 and ≤ overbook slots'
                    }`}
                  />
                </Row>
              }
            />
            {hotSettings?.shouldUseNodeOverbooking
              ? updatedDynamicOverbooking
              : oldOverbook}
          </Row>
          <Row mt={theme.space.lg} fullWidth justifyCenter>
            <ShiftPostingInputContainerSection
              label="Paid Backups"
              input={
                <Row style={{ width: 300 }}>
                  <NumberInput
                    label="Add Paid Backups?"
                    value={createShiftRequest.paidBackupSlotsRequested}
                    setValue={(numReq) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          paidBackupSlotsRequested: numReq,
                        }
                      })
                    }
                    step={1}
                    min={0}
                  />
                </Row>
              }
            />
            <ShiftPostingInputContainerSection
              label="Paid Backup Pay"
              input={
                <Row style={{ width: 300 }}>
                  <NumberInput
                    value={
                      createShiftRequest.paidBackupPayAmount ||
                      createShiftRequest.paidBackupPayAmount === 0
                        ? convertCentsToDollars(
                            createShiftRequest.paidBackupPayAmount,
                          )
                        : undefined
                    }
                    setValue={(numReq) =>
                      setCreateShiftRequest({
                        ...createShiftRequest,
                        paidBackupPayAmount:
                          numReq || numReq === 0
                            ? convertPayRateToCents(numReq)
                            : undefined,
                      })
                    }
                    isMoney={true}
                    step={0.01}
                    min={createShiftRequest.paidBackupSlotsRequested ? 1 : 0}
                    max={100}
                  />
                </Row>
              }
            />
          </Row>
        </Col>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Schedule">
        <Col>
          <ShiftTimePicker
            createShiftRequest={createShiftRequest}
            setCreateShiftRequest={setCreateShiftRequest}
            businessStartTime={businessStartTime}
            setBusinessStartTime={setBusinessStartTime}
            timezone={timezone}
            isInvalidBuffer={isInvalidBuffer}
            setIsInvalidBuffer={setIsInvalidBuffer}
          />
          <Row
            alignCenter
            mt={theme.space.med}
            style={{ justifyContent: 'space-around' }}
          >
            <ShiftPostingInputContainerSection
              label="Recurring Schedule"
              input={
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={createShiftRequest.schedules[0].isRecurringSchedule}
                  onClick={() => {
                    setCreateShiftRequest((prev) => {
                      const newSchedule = {
                        ...prev.schedules[0],
                        isRecurringSchedule:
                          !prev.schedules[0].isRecurringSchedule,
                      }
                      if (
                        prev.schedules[0].isRecurringSchedule &&
                        !!newSchedule.recurringSchedule
                      ) {
                        // If we are switching to not recurring we also clear recurring schedule
                        delete newSchedule.recurringSchedule
                      }
                      return {
                        ...prev,
                        schedule: newSchedule,
                        schedules: [newSchedule],
                      }
                    })
                  }}
                />
              }
              right
            />

            <ShiftPostingInputContainerSection
              label="Validate Schedule Bypass"
              input={
                <Row alignCenter>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={createShiftRequest.shouldBypassValidateSchedule}
                    onClick={() => {
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          shouldBypassValidateSchedule:
                            !createShiftRequest.shouldBypassValidateSchedule,
                        }
                      })
                    }}
                  />
                  <InfoTooltip
                    title={
                      'Allow for posting shifts in the past or for further than 1 year'
                    }
                    tooltipProps={{
                      leaveDelay: 500,
                      placement: 'top',
                      arrow: true,
                    }}
                  />
                </Row>
              }
              right
            />
          </Row>
          {createShiftRequest.schedules[0].isRecurringSchedule && (
            <Col
              mt={theme.space.sm}
              style={{
                border: `2px dashed ${theme.colors.Grey30} `,
                padding: theme.space.sm,
                borderRadius: theme.space.xs,
              }}
            >
              <Row alignCenter>
                <Text variant="h5" style={{ marginRight: theme.space.med }}>
                  Until When?
                </Text>
                <ShiftPostingInputContainerSection
                  label="End Date"
                  input={
                    <DatePicker
                      date={
                        createShiftRequest.schedules[0].recurringSchedule
                          ?.endDate
                      }
                      minDate={addMinutes(
                        createShiftRequest.schedules[0].endTime,
                        1,
                      )}
                      showTimeFieldInPopover={false}
                      granularity="day"
                      setDate={(date, isFourDigitYear) => {
                        if (date && isFourDigitYear) {
                          setCreateShiftRequest((prevShiftRequest) => ({
                            ...prevShiftRequest,
                            schedule: {
                              ...prevShiftRequest.schedules[0],
                              recurringSchedule: {
                                repeatOn:
                                  prevShiftRequest.schedules[0]
                                    .recurringSchedule?.repeatOn || [],
                                freq: 'WEEKLY',
                                interval: 1,
                                endDate: date,
                              },
                            },
                            schedules: [
                              {
                                ...prevShiftRequest.schedules[0],
                                recurringSchedule: {
                                  repeatOn:
                                    prevShiftRequest.schedules[0]
                                      .recurringSchedule?.repeatOn || [],
                                  freq: 'WEEKLY',
                                  interval: 1,
                                  endDate: date,
                                },
                              },
                            ],
                          }))
                        }
                      }}
                      isClearable={false}
                      timezone={timezone}
                      label="End Date"
                    />
                  }
                />
              </Row>
              <Row mt={theme.space.sm} alignCenter>
                <Text variant="h5" style={{ marginRight: theme.space.med }}>
                  Which days?
                </Text>

                <MultiSelector
                  options={REPEAT_ON_OPTIONS}
                  selectedOptions={
                    createShiftRequest.schedules[0].recurringSchedule
                      ?.repeatOn || []
                  }
                  onChange={handleWeekdaySelector}
                />
                <Button
                  onClick={() =>
                    handleWeekdaySelector(['MO', 'TU', 'WE', 'TH', 'FR'])
                  }
                  style={{ marginLeft: theme.space.med }}
                >
                  Select Weekdays
                </Button>
              </Row>
              <Row mt={theme.space.med} style={{ width: 600 }}>
                <ShiftScheduleSummary
                  shiftRequest={createShiftRequest}
                  shiftTimeZone={selectedLocation?.timezone || DEFAULT_TIMEZONE}
                />
              </Row>
            </Col>
          )}
          <SelectBreakForm
            createShiftRequest={createShiftRequest}
            setCreateShiftRequest={setCreateShiftRequest}
            defaultBreaks={company?.defaultBreaks || undefined}
            key={`breaks_enabled_${
              !!createShiftRequest.scheduledBreaks &&
              createShiftRequest.scheduledBreaks.length > 0
            }`}
          />
        </Col>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Payment">
        <Row fullWidth>
          <Col>
            <ShiftPostingInputContainerSection
              label="Pay Type"
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={payTypeOptions}
                    fullWidth
                    handleSelect={(payType) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          payType: payType as ShiftPayType,
                        }
                      })
                    }
                    value={createShiftRequest.payType}
                  />
                </Row>
              }
            />
            <Row style={{ marginTop: theme.space.sm }}>
              <ShiftPostingInputContainerSection
                label="Payment Type (optional)"
                input={
                  <Row style={{ width: 300, flexDirection: 'column' }}>
                    <Select
                      menuItems={paymentTypeOptions}
                      fullWidth
                      handleSelect={(paymentType) =>
                        setCreateShiftRequest((prev) => {
                          return {
                            ...prev,
                            paymentType: paymentType as PaymentType,
                          }
                        })
                      }
                      showEmptyOption
                      value={createShiftRequest.paymentType || ''}
                    />
                    {createShiftRequest.paymentType ===
                      PaymentType.AUTO_WEEKLY && (
                      <WeeklyPayWarning mt={theme.space.xs} />
                    )}
                  </Row>
                }
              />
            </Row>
            <Row style={{ marginTop: theme.space.sm }} fullWidth>
              <Row alignCenter>
                <ShiftPostingInputContainerSection
                  label={`Pay rate per ${
                    createShiftRequest.payType === ShiftPayType.HOURLY
                      ? 'Hour'
                      : 'Unit'
                  }`}
                  input={
                    <NumberInput
                      customErrorMessage={
                        createShiftRequest.payType === ShiftPayType.HOURLY
                          ? 'To post a lower rate, adjust the min hourly rate company setting.'
                          : 'Make sure to set both Pay Per Unit and Total Units.'
                      }
                      isMoney
                      value={createShiftRequest.payRate}
                      setValue={(payRate) =>
                        setCreateShiftRequest((prev) => {
                          return {
                            ...prev,
                            // if the pay rate is zero, we don't need to charge a trust and safety fee
                            ...(payRate === 0
                              ? {
                                  trustAndSafetyFeeHourly: {
                                    amount: 0,
                                    currency: 'USD',
                                  },
                                }
                              : {
                                  trustAndSafetyFeeHourly: {
                                    amount: 50,
                                    currency: 'USD',
                                  },
                                }),
                            payRate: payRate || 0,
                            hourlyRate: payRate || 0,
                          }
                        })
                      }
                      step={0.01}
                      required
                      placeholder="Pay Rate"
                      error={
                        !validatePayRate({
                          payType: createShiftRequest.payType,
                          payRate: createShiftRequest.payRate,
                          minHourlyPayRate: minHourlyPayRate,
                          numberOfUnits: createShiftRequest.numberOfUnits,
                        })
                      }
                    />
                  }
                />
                {selectedRole?.defaultPayRate ===
                  createShiftRequest.payRate && (
                  <Text
                    variant="caption"
                    style={{ marginLeft: theme.space.xs }}
                  >
                    THIS IS THE DEFAULT PAY FOR THE ROLE
                  </Text>
                )}
              </Row>
              {createShiftRequest.payType === ShiftPayType.UNIT && (
                <Row ml={theme.space.xs}>
                  <ShiftPostingInputContainerSection
                    label={`Total Units`}
                    input={
                      <DeprecatedNumberInput
                        helperText="This field is required"
                        error={!createShiftRequest.numberOfUnits}
                        value={createShiftRequest.numberOfUnits}
                        setter={(units) =>
                          setCreateShiftRequest((prev) => {
                            return {
                              ...prev,
                              numberOfUnits: units,
                            }
                          })
                        }
                        placeholder="Num Units"
                      />
                    }
                  />
                </Row>
              )}
            </Row>
          </Col>
          <Col
            style={{
              backgroundColor: theme.colors.Grey20,
              borderRadius: theme.space.xs,
              padding: theme.space.sm,
            }}
          >
            <SummaryLine>
              <Text variant="h5">Summary</Text>
            </SummaryLine>
            <SummaryLine>
              <Text variant="h6">Shift Length:</Text>
              <Text variant="h7">{formatDuration(totalShiftTime)}</Text>
            </SummaryLine>
            <SummaryLine>
              <Text variant="h6">Break(s):</Text>
              <Text variant="h7">{formatDuration(totalBreakTime)}</Text>
            </SummaryLine>
            <SummaryLine>
              <Text variant="h6">Total paid time:</Text>
              <Text variant="h7">{formatDuration(totalPaidTime)}</Text>
            </SummaryLine>
            {totalBilledTime !== null && (
              <SummaryLine>
                <Text variant="h6">Total billed time:</Text>
                <Text variant="h7">{formatDuration(totalBilledTime)}</Text>
              </SummaryLine>
            )}
            <SummaryLine>
              <Text variant="h6">
                Total estimated pay per{' '}
                {createShiftRequest.payType === ShiftPayType.HOURLY
                  ? 'worker shift'
                  : 'shift'}
                :
              </Text>
              <Text variant="h7">{estimatedPay}</Text>
            </SummaryLine>
          </Col>
        </Row>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Shift Filling">
        <Col>
          <ShiftPostingInputContainerSection
            label={`Shift Signup Status`}
            input={
              <Row
                style={{
                  alignItems: 'center',
                  width: 300,
                  marginBottom: theme.space.med,
                }}
              >
                <Select
                  menuItems={signupStatusOptions}
                  fullWidth
                  handleSelect={(signupStatus) =>
                    setCreateShiftRequest((prev) => {
                      return {
                        ...prev,
                        signupStatus: signupStatus as ShiftSignupStatus,
                      }
                    })
                  }
                  value={
                    createShiftRequest.signupStatus || ShiftSignupStatus.ALLOWED
                  }
                />
              </Row>
            }
          />
          <ShiftPostingInputContainerSection
            label={`Forward Fill Type`}
            input={
              <Row style={{ width: 300, marginBottom: theme.space.med }}>
                <Select
                  menuItems={forwardFillTypeOptions}
                  fullWidth
                  handleSelect={(forwardFillMax) =>
                    setCreateShiftRequest((prev) => {
                      return {
                        ...prev,
                        forwardFillMax: forwardFillMax as ForwardFillMax,
                      }
                    })
                  }
                  value={
                    createShiftRequest.forwardFillMax || ForwardFillMax.NONE
                  }
                />
              </Row>
            }
          />
          <HorizontalRule marginTop={theme.space.med} />
          <Row
            style={{ justifyContent: 'flex-start' }}
            mt={theme.space.sm}
            mb={theme.space.xs}
          >
            <ShiftPostingInputContainerSection
              label={`Minimum Tier`}
              input={
                <Row style={{ width: 200, marginRight: theme.space.med }}>
                  <Select
                    menuItems={minimumTierOptions}
                    fullWidth
                    handleSelect={(tier) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          minimumAcceptedTier: tier as TierLevel,
                        }
                      })
                    }
                    value={createShiftRequest.minimumAcceptedTier}
                  />
                </Row>
              }
            />

            <ShiftPostingInputContainerSection
              label={`Unproven Threshold`}
              input={
                <Row style={{ width: 100, marginRight: theme.space.med }}>
                  <DeprecatedNumberInput
                    value={createShiftRequest.unprovenWorkerThreshold}
                    setter={(threshold) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          unprovenWorkerThreshold: threshold,
                        }
                      })
                    }
                    step={0.01}
                    decimals={2}
                    placeholder="eg. 0.5"
                    helperText="Must be between 0 and 1"
                    error={
                      createShiftRequest.unprovenWorkerThreshold !==
                        undefined &&
                      (createShiftRequest.unprovenWorkerThreshold < 0 ||
                        createShiftRequest.unprovenWorkerThreshold > 1)
                    }
                  />
                </Row>
              }
            />
            <ShiftPostingInputContainerSection
              label={`Show RMSA`}
              input={
                <Row style={{ width: 200, marginRight: theme.space.med }}>
                  <Select
                    menuItems={rmsaShiftOptions}
                    fullWidth
                    handleSelect={(rmsaOption) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          requiredMultiShiftType:
                            rmsaOption as RequiredMultiShiftType,
                        }
                      })
                    }
                    value={createShiftRequest.requiredMultiShiftType}
                  />
                </Row>
              }
            />
            <ShiftPostingInputContainerSection
              label={`Gender Preference`}
              input={
                <Row style={{ width: 150, marginRight: theme.space.med }}>
                  <Select
                    menuItems={genderPreferenceOptions}
                    fullWidth
                    showEmptyOption
                    handleSelect={(genderPreference) =>
                      setCreateShiftRequest((prev) => {
                        return {
                          ...prev,
                          genderPreference:
                            genderPreference as GenderPreference,
                        }
                      })
                    }
                    value={createShiftRequest.genderPreference || ''}
                  />
                </Row>
              }
            />
            <ShiftPostingInputContainerSection
              label="Forward Fill Notifications"
              input={
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={
                    createShiftRequest.notificationStatus ===
                    ShiftNotificationStatus.ALLOWED
                  }
                  onClick={() => {
                    setCreateShiftRequest((prev) => {
                      return {
                        ...prev,
                        notificationStatus:
                          createShiftRequest.notificationStatus ===
                          ShiftNotificationStatus.ALLOWED
                            ? ShiftNotificationStatus.DISALLOWED
                            : ShiftNotificationStatus.ALLOWED,
                      }
                    })
                  }}
                />
              }
              right
            />
          </Row>
          <Row
            style={{ justifyContent: 'flex-start' }}
            mt={theme.space.sm}
            mb={theme.space.xs}
          >
            <ShiftPostingInputContainerSection
              label="Require W9 Authorization"
              input={
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={createShiftRequest.requireW9Authorization}
                  onClick={() => {
                    setCreateShiftRequest((prev) => {
                      return {
                        ...prev,
                        requireW9Authorization: !prev.requireW9Authorization,
                      }
                    })
                  }}
                />
              }
              right
            />
          </Row>
          {(createShiftRequest.forwardFillMax === ForwardFillMax.INVITED_ONLY ||
            createShiftRequest.forwardFillMax ===
              ForwardFillMax.INVITED_FIRST) && (
            <>
              <HorizontalRule marginTop={theme.space.med} />
              <SearchInviteWorkersSection
                rosters={virtualRosters.concat(rosters ?? []) || []}
                allWorkersToInvite={workersToInvite}
                setAllWorkersToInvite={setWorkersToInvite}
              />
            </>
          )}
        </Col>
      </ShiftPostingInputContainer>
      <ShiftPostingInputContainer title="Invoicing">
        <Col>
          <ShiftPostingInputContainerSection
            label={`Invoice Type`}
            input={
              <Row style={{ width: 300 }}>
                <Select
                  menuItems={invoiceOptions}
                  fullWidth
                  handleSelect={(invoiceOption) =>
                    setCreateShiftRequest((prev) => {
                      return {
                        ...prev,
                        invoiceChargeCycle: invoiceOption as InvoiceChargeCycle,
                      }
                    })
                  }
                  value={createShiftRequest.invoiceChargeCycle}
                />
              </Row>
            }
          />

          {createShiftRequest.invoiceChargeCycle ===
            InvoiceChargeCycle.GROUPED && (
            <>
              <Row mt={theme.space.xs}>
                <ShiftPostingInputContainerSection
                  label={`Invoice Group`}
                  input={
                    <Row style={{ width: 300 }}>
                      <Select
                        menuItems={activeInvoiceGroups.map((group) => ({
                          value: group.id,
                          label: group.name,
                        }))}
                        fullWidth
                        handleSelect={(groupId) =>
                          setCreateShiftRequest((prev) => {
                            return {
                              ...prev,
                              parentInvoiceGroupId: groupId,
                            }
                          })
                        }
                        value={createShiftRequest.parentInvoiceGroupId || ''}
                      />
                    </Row>
                  }
                />
              </Row>

              <Row>
                <Button
                  leftIcon={
                    <Icon
                      name="plus"
                      style={{ marginRight: theme.space.xxs }}
                    />
                  }
                  onClick={() => {
                    createOrEditInvoiceGroupModal.open()
                  }}
                  reverse
                  variant={ButtonVariant.TEXT}
                  iconPadding="0px"
                  style={{ paddingLeft: '0px', color: theme.colors.Violet }}
                >
                  Create new invoice group
                </Button>
              </Row>
            </>
          )}
        </Col>
      </ShiftPostingInputContainer>
      <Row justifyEnd>
        <Button
          style={{ width: '250px' }}
          onClick={() => setShowConfirmationDialog(true)}
          loading={createShiftReqLoading}
          disabled={
            !validateShiftCreationForm(
              createShiftRequest,
              minHourlyPayRate,
              hotSettings?.paidBackupPayAmountMax,
            ) ||
            !validateDynamicOverbookAllowed(
              createShiftRequest.slotsRequested,
              !!createShiftRequest.enableDynamicOverbooking,
            ) ||
            isInvalidBuffer ||
            !!roleSearchSelectErrorMsg ||
            !!supervisorSearchSelectErrorMsg
          }
        >
          {buttonTitle}
        </Button>
      </Row>
      <ConfirmationDialog
        title="Confirm Shift Request Creation"
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        content={
          <Row style={{ width: 500, minHeight: 200 }}>
            <Col>
              <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
                Shift Summary
              </Text>
              <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
                Date:{' '}
                {tz.getShiftDate(
                  createShiftRequest.schedules[0].startTime,
                  createShiftRequest.schedules[0].endTime,
                  { weekday: 'short' },
                )}
              </Text>
              <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
                Time:{' '}
                {tz.getShiftTime(
                  createShiftRequest.schedules[0].startTime,
                  createShiftRequest.schedules[0].endTime,
                )}
              </Text>
              {earlyArrivalTimeBufferInMinutes !== undefined && (
                <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
                  Early Arrival Buffer: {earlyArrivalTimeBufferInMinutes}{' '}
                  minutes
                </Text>
              )}
              <Text variant="h7" style={{ marginBottom: theme.space.xxs }}>
                Pay: {estimatedPay}
              </Text>
              {createShiftRequest.paymentType === PaymentType.AUTO && (
                <Text variant="error">
                  You are about to post a shift with payment type AUTOMATIC, are
                  you sure?
                </Text>
              )}
              <ShiftScheduleSummary
                shiftRequest={createShiftRequest}
                shiftTimeZone={selectedLocation?.timezone || DEFAULT_TIMEZONE}
              />
            </Col>
          </Row>
        }
        onConfirm={() => handleSubmitShiftRequest(true)}
        secondaryConfirmButton
        secondaryConfirmButtonText="Confirm & Post Again"
        secondaryConfirmButtonAction={() => handleSubmitShiftRequest(false)}
      />
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={company.companyId}
      />
    </Col>
  )
}
