import { ExtendedShift } from '@traba/types'
import { RoleAttributeCategory } from '@traba/types'
import { getAddressString } from './stringUtils'

interface PrefillOption {
  title: string
  content: string
  selected: boolean
}

export const getAnnouncementPrefillSuggestions = (shift: ExtendedShift) => {
  const { attireDescription, requiredAttributes } = shift
  const results: PrefillOption[] = [
    ...(shift.location
      ? [
          {
            title: 'Location Reminder',
            content: `Location for this shift is: ${getAddressString(
              shift.location.address,
            )}. `,
            selected: false,
          },
        ]
      : []),
    ...(shift.location.locationInstructions
      ? [
          {
            title: 'Arrival Instruction',
            content: shift.location.locationInstructions,
            selected: false,
          },
        ]
      : []),
    ...(shift.parkingLocation
      ? [
          {
            title: 'Parking Location Reminder',
            content: `Parking location for this shift is: ${getAddressString(
              shift.parkingLocation.address,
            )}. `,
            selected: false,
          },
        ]
      : []),
    ...(shift.parkingLocation?.locationInstructions
      ? [
          {
            title: 'Parking Instruction',
            content: shift.parkingLocation.locationInstructions,
            selected: false,
          },
        ]
      : []),
    {
      title: 'Attire Reminder',
      content: `Attire requirement for this shift is: ${attireDescription}. `,
      selected: false,
    },
  ]

  const gears: string[] = []
  if (requiredAttributes) {
    requiredAttributes.map((attr) => {
      if (
        typeof attr === 'object' &&
        attr.category === RoleAttributeCategory.Gear
      ) {
        gears.push(attr.type.replace('_', ' '))
      }
    })
  }

  if (gears.length > 0) {
    results.push({
      title: 'Gear Reminder',
      content: `Remember to bring ${gears.join(', ')}. `,
      selected: false,
    })
  }
  return results
}
