import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Transaction } from '@traba/types'
import { WorkerShiftTimeToDestination } from '@traba/types'
import { Worker } from '@traba/types'
import { JobStatus } from '../types'

export type PopulatedTransaction = Transaction & {
  worker?: Worker
  shiftId: string
  timezone: string
}

/**
 * Get array of transactions, each populated with worker and timezone
 * @param workerShifts
 * @returns array of populated transactions
 */
export function getPopulatedTransactionsFromWorkerShifts(
  workerShifts: WorkerShift[],
) {
  return workerShifts.flatMap((ws) =>
    getPopulatedTransactionsFromWorkerShift(ws),
  )
}

/**
 * Get transactions from worker shift, each populated with `workerName`, `worker` object and `timezone`
 * @param workerShift
 * @returns array of populated transactions for worker shift
 */
export function getPopulatedTransactionsFromWorkerShift(
  workerShift: WorkerShift,
): PopulatedTransaction[] {
  if (workerShift.transactions) {
    return workerShift.transactions?.map((t) => ({
      ...t,
      workerName: `${workerShift.worker?.firstName} ${workerShift.worker?.lastName}`,
      worker: workerShift.worker,
      shiftId: workerShift.shiftId,
      timezone: workerShift.shiftInfo.timezone,
    }))
  }

  return []
}

/**
 * Get the number of transactions for a given array of worker shifts
 * @param workerShifts
 * @returns number of transactions
 */
export function getNumberOfTransactions(workerShifts: WorkerShift[]) {
  return workerShifts.reduce(
    (acc, curr) => acc + (curr.transactions?.length ?? 0),
    0,
  )
}

export function getWorkerShiftStringId(workerId: string, shiftId: string) {
  return `${workerId}_${shiftId}`
}

export function getExpectedWorkerShifts(workerShifts?: WorkerShift[]) {
  return workerShifts?.filter(
    (ws) =>
      ws.jobStatus !== JobStatus.NoShow &&
      ws.jobStatus !== JobStatus.Canceled &&
      ws.jobStatus !== JobStatus.Rejected &&
      ws.jobStatus !== JobStatus.Appeased,
  )
}

export function canClockIn(jobStatus: JobStatus): boolean {
  return jobStatus === JobStatus.ToDo || jobStatus === JobStatus.InProgress
}

export function getWorkerShiftTimeToDestinationKey({
  workerId,
  shiftId,
}: {
  workerId: string
  shiftId: string
}) {
  return `${workerId}-${shiftId}`
}

export function buildWorkerShiftTimeToDestinationMap(
  workerShiftTimeToDestinations: WorkerShiftTimeToDestination[],
) {
  return new Map<string, WorkerShiftTimeToDestination>(
    workerShiftTimeToDestinations?.map((workerShiftTimeToDestination) => {
      return [
        getWorkerShiftTimeToDestinationKey({
          workerId: workerShiftTimeToDestination.workerId,
          shiftId: workerShiftTimeToDestination.shiftId,
        }),
        workerShiftTimeToDestination,
      ]
    }) || [],
  )
}
