import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { canClockIn } from 'src/utils/workerShiftUtils'
import { Button, Icon } from './base'
import { ButtonVariant } from './base/Button/types'

interface InlineClockInProps {
  workerShift: WorkerShift
  openModal: (workerShift: WorkerShift) => void
}

export default function InlineClockIn(props: InlineClockInProps) {
  const { workerShift, openModal } = props
  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const showClockIn = canClockIn(workerShift.jobStatus)
  const clockInButtonText = workerShift.clockInTime
    ? tz.getTime(workerShift.clockInTime)
    : showClockIn
      ? 'Clock In'
      : '-'
  return (
    <Button
      disabled={!showClockIn || !!workerShift.clockOutTime}
      variant={(workerShift.clockInTime ? 'text' : 'outlined') as ButtonVariant}
      rightIcon={
        workerShift.clockInTime && !workerShift.clockOutTime ? (
          <Icon name="edit_active" />
        ) : undefined
      }
      ml={theme.space.xxs}
      style={{
        padding: theme.space.xxs,
        height: theme.space.sm,
      }}
      onClick={() => openModal(workerShift)}
    >
      {clockInButtonText}
    </Button>
  )
}
