import {
  FormControl,
  InputLabel,
  SelectChangeEvent,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { WorkerProfile, RoleAttribute, WorkerProfileStatus } from '@traba/types'
import { AxiosError } from 'axios'
import { useState, useCallback, useMemo } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import {
  CreateOrUpdateWorkerProfilePayload,
  createOrUpdateWorkerProfiles,
} from 'src/hooks/useWorkerProfile'

export function WorkerAttributeAdder(props: {
  workerId: string
  workerAttributes: WorkerProfile[]
  roleAttributes: RoleAttribute[]
}) {
  const { workerId, workerAttributes, roleAttributes } = props
  const [isLoading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const { handleError } = useAlert()
  const { hotSettings } = useHotSettings()
  const filteredRoleAttributes = useMemo(() => {
    return roleAttributes.filter(
      (ra) =>
        !hotSettings?.allowStoringRequiredAttributeLevel ||
        !ra.allowOpsLeveling,
    )
  }, [roleAttributes, hotSettings])
  const updateAttributeMutation = useMutation<
    WorkerProfile[] | undefined,
    AxiosError,
    CreateOrUpdateWorkerProfilePayload[]
  >(
    (workerProfiles: CreateOrUpdateWorkerProfilePayload[]) => {
      setLoading(true)
      return createOrUpdateWorkerProfiles(workerId, workerProfiles)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`workerProfile_${workerId}`)
        setLoading(false)
      },
      onError: (error) => {
        handleError(
          error,
          'WorkerAttributeAdder -> createOrUpdateWorkerProfiles()',
          error.message,
          'Error Creating Attribute',
        )
      },
    },
  )
  const addAttribute = useCallback(
    (workerAttribute: CreateOrUpdateWorkerProfilePayload) => {
      updateAttributeMutation.mutate([workerAttribute])
    },
    [updateAttributeMutation],
  )

  const groupedAttributes: Record<string, RoleAttribute[]> = {}
  for (const roleAttribute of filteredRoleAttributes) {
    if (
      workerAttributes
        .filter((workerAttribute) => {
          return workerAttribute.value === true
        })
        .map((workerAttribute) => workerAttribute.profileField)
        .includes(roleAttribute.type)
    ) {
      continue
    }
    const category = roleAttribute.category
    if (groupedAttributes[category]) {
      groupedAttributes[category].push(roleAttribute)
    } else {
      groupedAttributes[category] = [roleAttribute]
    }
  }
  if (isLoading) {
    return <CircularProgress size="small" />
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel htmlFor="add-attribute-select">Add More</InputLabel>
      <Select
        defaultValue=""
        id="add-attribute-select"
        label="Add Attribute"
        onChange={(event: SelectChangeEvent) => {
          const attributeType = event.target.value
          attributeType &&
            addAttribute({
              category: filteredRoleAttributes.find(
                (roleAttribute) => roleAttribute.type === attributeType,
              )!.category,
              profileField: attributeType,
              value: true,
              status: WorkerProfileStatus.Verified,
            })
        }}
      >
        {Object.keys(groupedAttributes).map((category) => {
          return [
            <ListSubheader
              sx={{
                fontWeight: 400,
                color: '#000',
              }}
            >
              {category}
            </ListSubheader>,
            [
              ...groupedAttributes[category].map((roleAttribute) => {
                return (
                  <MenuItem value={roleAttribute.type}>
                    <Text>{roleAttribute.displayName}</Text>
                  </MenuItem>
                )
              }),
            ],
          ]
        })}
      </Select>
    </FormControl>
  )
}
