import { useWorkerScores } from 'src/hooks/useWorkerMetrics'
import { toPercentString } from 'src/utils/stringUtils'

export default function WorkerBusinessApprovalScore({
  workerId,
}: {
  workerId: string
}) {
  const { workerScores } = useWorkerScores(workerId)
  return (
    <>
      {workerScores?.businessApprovalScore != null
        ? `${toPercentString(workerScores.businessApprovalScore)}%`
        : '-'}
    </>
  )
}
