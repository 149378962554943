export function Traba({
  size = 24,
  color = '#08105E',
}: {
  size: number
  color: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="39" height="40" rx="19.5" fill="#F2F3F7" />
      <path
        d="M20.352 8H15V12.4381V17.8383L15.0064 22.9113C15.1017 28.4847 19.6337 32.9998 25.1891 32.9998V27.5997C22.5194 27.5997 20.352 25.4062 20.352 22.719V17.8383H25.1891V12.4381H20.352V8Z"
        fill={color}
      />
      <path
        d="M20.352 8H15V12.4381V17.8383L15.0064 22.9113C15.1017 28.4847 19.6337 32.9998 25.1891 32.9998V27.5997C22.5194 27.5997 20.352 25.4062 20.352 22.719V17.8383H25.1891V12.4381H20.352V8Z"
        fill={color}
      />
    </svg>
  )
}
