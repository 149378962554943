import { theme } from '@traba/theme'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Row } from 'src/components/base'
import { Sidebar } from 'src/components/layout/Sidebar/Sidebar'
import { SentinelNotificationsDrawer } from 'src/components/Sentinel/SentinelNotificationsDrawer'
import { useHotSettings } from 'src/hooks/useHotSettings'
import * as S from './MainLayout.styles'

type MainLayoutProps = {
  children: React.ReactNode
  title?: string
}

const isDev = import.meta.env.VITE_ENV !== 'production'

export function MainLayout({ children, title }: MainLayoutProps) {
  const cachedIsSidebarCollapsed = JSON.parse(
    localStorage.getItem('isSidebarCollapsed') ?? 'false',
  )
  const [isSidebarCollapsed, _setIsSidebarCollapsed] = useState(
    cachedIsSidebarCollapsed,
  )

  const { hotSettings } = useHotSettings()

  const [
    isSentinelNotificationDrawerOpen,
    setIsSentinelNotificationDrawerOpen,
  ] = useState<boolean>(false)

  function setIsSidebarCollapsed(newValue: boolean) {
    _setIsSidebarCollapsed(newValue)
    localStorage.setItem('isSidebarCollapsed', JSON.stringify(newValue))
  }

  return (
    <>
      <Helmet defer={false}>
        <title>{(isDev ? '[INTERNAL] ' : '') + (title ?? 'Ops Console')}</title>
      </Helmet>
      {isDev && (
        <Row
          style={{
            padding: theme.space.xxxs,
            backgroundColor: theme.colors.MidnightBlue,
            color: theme.colors.White,
            fontSize: theme.space.xs,
          }}
          justifyCenter
        >
          You are in development mode.
        </Row>
      )}
      <Row fullHeight fullWidth>
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          setIsCollapsed={setIsSidebarCollapsed}
        />
        <S.ContentCol>
          <S.Content>{children}</S.Content>
        </S.ContentCol>
        {hotSettings?.enableSentinelNotificationUI && (
          <SentinelNotificationsDrawer
            isOpen={isSentinelNotificationDrawerOpen}
            setIsOpen={setIsSentinelNotificationDrawerOpen}
          />
        )}
      </Row>
    </>
  )
}
