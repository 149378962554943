import { theme } from '@traba/theme'
import styled from 'styled-components'

export const BarContainer = styled.div`
  background-color: ${theme.colors.Grey10};
  margin-bottom: ${theme.space.xxs}px;
`

export const DistributionBar = styled.div`
  display: flex;
  height: 15px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
`
export const DistributionBarMarkers = styled.div`
  width: 100%;
`

export const BarSegment = styled.div<{
  width: number
  backgroundColor: string
}>`
  width: ${({ width }) => width}%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const DottedLine = styled.div`
  border-bottom: 2px dotted ${theme.colors.Grey30};
  width: 45%;
`

export const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid ${theme.colors.Grey30};
`

export const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid ${theme.colors.Grey30};
`
