import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'
import { Button } from './Button'
import { ButtonVariant } from './Button/types'
import Icon from './Icon'

export type BackButtonProps = {
  // Navigate to this url if there is no history present
  // for example if user landed on this page directly
  noHistoryUrl: string

  // If present, will perform this action for the back button instead of going back in history/noHistoryUrl
  actionOverride?: () => void
}

export default function BackButton(props: BackButtonProps) {
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.actionOverride !== undefined) {
      props.actionOverride()
      return
    }

    const hasPrevPage = window.history.state.idx > 0
    if (hasPrevPage) {
      navigate(-1)
    } else {
      navigate(props.noHistoryUrl)
    }
  }

  return (
    <Button
      variant={ButtonVariant.TEXT}
      onClick={handleClick}
      style={{ paddingTop: 0 }}
    >
      <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
      Go back
    </Button>
  )
}
