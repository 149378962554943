import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { assetNameType } from 'src/assets/svg/SvgIconProps'
import { Col, Icon, Row } from '../../base'

interface SectionHeaderProps {
  title: string
  description: string
  isExpanded: boolean
  onToggle: (e: React.MouseEvent<HTMLDivElement>) => void
  icon: assetNameType
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  isExpanded,
  onToggle,
  icon,
}) => {
  return (
    <Row
      fullWidth
      justifyBetween
      px={theme.space.xxs}
      py={theme.space.xxs}
      style={{ cursor: 'pointer' }}
      onClick={onToggle}
    >
      <Col>
        <Row alignCenter justifyStart>
          <Icon
            name={icon}
            style={{
              marginRight: theme.space.xxs,
              width: 14,
              height: 14,
            }}
            color={theme.colors.Blue100}
          ></Icon>
          <Text variant={'h6'} mr={theme.space.xxs}>
            {title}
          </Text>
        </Row>
        <Text variant="label">{description}</Text>
      </Col>
      {isExpanded ? <ExpandLess /> : <ExpandMore />}
    </Row>
  )
}

export { SectionHeader }
