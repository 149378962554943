import { Text } from '@traba/react-components'
import * as S from './SelectableCard.styles'

export interface SelectableCardProps extends React.DOMAttributes<any> {
  label: string
  selected: boolean
  slim?: boolean
  onClick: () => void
}

export const SelectableCard = (props: SelectableCardProps) => {
  const { label, slim } = props
  return (
    <S.SelectableCardContainer {...props}>
      <Text variant="body1" style={{ fontSize: slim ? 12 : 14 }}>
        {label}
      </Text>
    </S.SelectableCardContainer>
  )
}

export default SelectableCard
