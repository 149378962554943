import { trabaApi } from '@traba/api-utils'
import { CompanyCategory } from '@traba/types'
import { CompanyResponse } from '@traba/types'
import { useQuery } from 'react-query'
import { QueryParamArray } from 'src/types'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'
import { getQueryParams } from './useApi'

async function getCompanies({
  isApproved,
  categories,
  employerName,
  identifiersOnly,
}: {
  isApproved?: boolean
  categories?: CompanyCategory[]
  employerName?: string
  identifiersOnly?: boolean
}): Promise<CompanyResponse[] | undefined> {
  try {
    const categoriesArray = [
      ...((categories
        ? categories?.map((category) => ['companyCategories[]', category])
        : []) as QueryParamArray),
    ]
    const queryString = getQueryParams([
      ...categoriesArray,
      ['employerName', employerName],
      ['isApproved', isApproved],
      ['identifiersOnly', identifiersOnly],
    ])
    const res = await trabaApi.get(`companies/${queryString}`)

    return res.data
  } catch (error: any) {
    console.error(
      'useCompanies -> getCompanies() ERROR',
      error.message ?? error,
    )
  }
}

export function useCompanies({
  isApproved,
  categories,
  employerName,
  identifiersOnly,
}: {
  isApproved?: boolean
  categories?: CompanyCategory[]
  employerName?: string
  identifiersOnly?: boolean
}) {
  const {
    isLoading,
    isError,
    data: companies,
    error,
    isFetched,
    refetch,
  } = useQuery<CompanyResponse[] | undefined, Error>(
    [`companies`, isApproved, categories, employerName, identifiersOnly],
    () =>
      getCompanies({
        isApproved,
        categories,
        employerName,
        identifiersOnly,
      }),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    companies,
    error,
    isFetched,
    refetch,
  }
}
