import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button, Row } from 'src/components/base'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'
import { NumberInput } from './base/Input/NumberInput'
import Toggle from './base/Toggle'

interface BackfillSettingsSelectorProps {
  allowsBackfill: boolean | undefined
  lateTolerance: number | undefined
  onSave: (backFillSettings: {
    allowsBackfill: boolean
    lateTolerance: number
  }) => Promise<void>
}

export const BackfillSettingsSelector = (
  props: BackfillSettingsSelectorProps,
) => {
  const { allowsBackfill, lateTolerance, onSave } = props
  const { showError } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()

  const [loading, setLoading] = useState<boolean>(false)
  const [newAllowsBackfill, setNewAllowsBackfill] = useState<boolean>(
    allowsBackfill ?? false,
  )
  const [newLateTolerance, setNewLateTolerance] = useState<number>(
    lateTolerance ?? 0,
  )
  const [allowsBackfillChangesMade, setAllowsBackfillChangesMade] =
    useState<boolean>(false)

  function toggleAllowsBackfill() {
    if (!allowsBackfillChangesMade) {
      setAllowsBackfillChangesMade(true)
    }
    // ensure that it toggles the state by taking in the old state value
    setNewAllowsBackfill((oldValue) => !oldValue)
  }

  function handleLateToleranceChange(lateTolerance: number | undefined) {
    if (lateTolerance === undefined) {
      return
    }
    if (!allowsBackfillChangesMade) {
      setAllowsBackfillChangesMade(true)
    }
    setNewLateTolerance(lateTolerance)
  }

  async function handleSave() {
    try {
      setLoading(true)
      await onSave({
        allowsBackfill: newAllowsBackfill,
        lateTolerance: newLateTolerance,
      })
      refetchActiveQueries()
    } catch (err) {
      showError(getErrorMessage(err), 'Error Updating Backfill Settings')
    } finally {
      setLoading(false)
    }
  }
  return (
    <Row justifyBetween gap={theme.space.xs} alignCenter>
      <Toggle
        label={'Allows backfill'}
        buttonState={newAllowsBackfill}
        // no-op, need to hit save button to save both settings
        runOnChange={toggleAllowsBackfill}
      />
      <Text variant="h6">Backfill Late Tolerance</Text>
      <NumberInput
        width={'80px'}
        value={newLateTolerance}
        setValue={handleLateToleranceChange}
        decimals={0}
        placeholder="60 minutes"
        min={0}
        max={1000}
        step={1}
      />

      <Button
        loading={loading}
        disabled={!allowsBackfillChangesMade}
        onClick={() => handleSave()}
      >
        Save
      </Button>
    </Row>
  )
}
