import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CircularProgress, Tooltip } from '@mui/material'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BugleStatistics, BugleStatisticsType } from '@traba/types'
import { truncateString } from '@traba/utils'
import { format } from 'date-fns'
import { CopyTextIcon, Row } from '../../base'
import { CircleDiv } from '../../base/Icon'
import {
  DataTableHeader,
  TableCell,
  TableRow,
} from '../../base/Table/DataTable'
import { VirtualizedTable } from '../../base/Table/VirtualizedTable'
import { useBugleStatisticsTypeWorkersComms } from '../hooks/useBugleStatistics'

function getBugleBlastStatusColor(status: BugleStatisticsType) {
  switch (status) {
    case BugleStatisticsType.SIGNED_FOR_SHIFT:
      return theme.colors.Green80
    case BugleStatisticsType.UNRESPONSIVE:
      return theme.colors.Red80
    case BugleStatisticsType.UNSUBSCRIBED:
      return theme.colors.Grey60
    case BugleStatisticsType.UNDELIVERED:
      return theme.colors.Orange70
    default:
      return theme.colors.Blue80
  }
}

const headers: (DataTableHeader | string)[] = [
  {
    key: 'toNumber',
    label: 'Phone Number',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'sentAt',
    label: 'Sent At',
  },
  {
    key: 'errorCode',
    label: 'Error Code',
  },
  {
    key: 'errorMessage',
    label: 'Error Message',
  },
  {
    key: 'workerId',
    label: 'Worker ID',
  },
]

export default function BugleStatisticsStatusRow({
  bugleBlastId,
  shiftId,
  bugleStatistics,
  type,
  isExpanded,
  onToggleType,
}: {
  bugleBlastId: string
  shiftId: string
  bugleStatistics: BugleStatistics
  type: BugleStatisticsType
  isExpanded: boolean
  onToggleType: () => void
}) {
  const { isLoading, isError, data } = useBugleStatisticsTypeWorkersComms(
    bugleBlastId,
    shiftId,
    type,
    isExpanded,
  )

  const rows: TableRow[] =
    data?.map((workerComm) => {
      const cells: TableCell[] = [
        {
          renderFn: () => {
            return (
              <Row alignCenter>
                <Anchor
                  style={{ width: 120 }}
                  href={`tel:${workerComm.toNumber}`}
                >
                  {workerComm.toNumber}
                </Anchor>
                <CopyTextIcon textToCopy={workerComm.toNumber} />
              </Row>
            )
          },
        },
        {
          renderFn: () => {
            return (
              <Text style={{ width: 160 }}>{workerComm.status || '-'}</Text>
            )
          },
        },
        {
          renderFn: () => {
            return (
              <Text style={{ width: 160 }}>
                {format(new Date(workerComm.createdAt), 'MMM dd hh:mm a zzz')}
              </Text>
            )
          },
        },
        {
          renderFn: () => {
            return (
              <Text style={{ width: 160 }}>{workerComm.errorCode || '-'}</Text>
            )
          },
        },
        {
          renderFn: () => {
            return (
              <Tooltip title={workerComm.errorMessage || 'No error message'}>
                <div>
                  <Text style={{ width: 260 }}>
                    {truncateString(workerComm.errorMessage || '-', 24, true)}
                  </Text>
                </div>
              </Tooltip>
            )
          },
        },
        {
          renderFn: () => {
            return (
              <Row alignCenter style={{ width: 160 }}>
                {truncateString(workerComm?.workerId ?? '', 12)}
                <CopyTextIcon textToCopy={workerComm?.workerId} />
              </Row>
            )
          },
        },
      ]
      return {
        key: workerComm.workerId,
        cells,
      }
    }) || []

  /** Parse the bugle results based on the type */
  const getCounterFromType = (type: BugleStatisticsType) => {
    switch (type) {
      case BugleStatisticsType.SIGNED_FOR_SHIFT:
        return bugleStatistics.signedForShift
      case BugleStatisticsType.UNRESPONSIVE:
        return bugleStatistics.unresponsive
      case BugleStatisticsType.UNSUBSCRIBED:
        return bugleStatistics.unsubscribed
      case BugleStatisticsType.UNDELIVERED:
        return bugleStatistics.undelivered
    }
  }

  const getTitle = (type: BugleStatisticsType) => {
    switch (type) {
      case BugleStatisticsType.SIGNED_FOR_SHIFT:
        return 'Signed up for shift'
      case BugleStatisticsType.UNRESPONSIVE:
        return 'Unresponsive'
      case BugleStatisticsType.UNSUBSCRIBED:
        return 'Unsubscribed'
      case BugleStatisticsType.UNDELIVERED:
        return 'Undelivered'
      default:
        return 'Invalid type'
    }
  }
  const statusColor = getBugleBlastStatusColor(type)
  const counter = getCounterFromType(type)
  const percentage = (counter / bugleStatistics.messagesSent) * 100

  return (
    <Row
      flexCol
      mb={theme.space.xs}
      style={{
        border: `1px solid ${theme.border.color}`,
        borderRadius: theme.border.radius,
        backgroundColor: theme.colors.Grey20,
      }}
    >
      <Row
        justifyBetween
        px={theme.space.xs}
        py={theme.space.xxs}
        style={{
          cursor: 'pointer',
          borderRadius: theme.border.radius,
          backgroundColor: theme.colors.White,
        }}
        onClick={onToggleType}
      >
        <Row alignCenter>
          <CircleDiv
            color={statusColor}
            backgroundColor={statusColor}
            size={18}
          />
          <Text variant="h5" ml={theme.space.xxs}>
            {getTitle(type)}
          </Text>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </Row>
        <Row alignCenter>
          <Text
            mr={theme.space.xxs}
            style={{
              color: theme.colors.Blue100,
              fontWeight: 600,
            }}
            variant="h4"
          >
            {counter}
          </Text>
          <Text variant="h6" color={statusColor} style={{ width: 50 }}>
            {percentage.toFixed(2)}%
          </Text>
        </Row>
      </Row>
      {isExpanded && !isLoading && !isError && (
        <Row
          px={theme.space.xs}
          py={theme.space.xs}
          style={{
            borderBottomLeftRadius: theme.border.radius,
            borderBottomRightRadius: theme.border.radius,
          }}
        >
          {data?.length ? (
            <VirtualizedTable
              headers={headers.map((header) =>
                typeof header === 'string'
                  ? header
                  : header.label?.toString() || '',
              )}
              rows={rows}
            />
          ) : (
            <Text>No notifications in this category</Text>
          )}
        </Row>
      )}
      {isExpanded && isLoading && (
        <Row
          px={theme.space.xs}
          py={theme.space.xs}
          style={{
            border: `1px solid ${theme.border.color}`,
            borderBottomLeftRadius: theme.border.radius,
            borderBottomRightRadius: theme.border.radius,
            height: 200,
            overflowY: 'auto',
          }}
        >
          <CircularProgress />
        </Row>
      )}
      {isExpanded && isError && (
        <Row
          px={theme.space.xs}
          py={theme.space.xs}
          style={{
            border: `1px solid ${theme.border.color}`,
            borderBottomLeftRadius: theme.border.radius,
            borderBottomRightRadius: theme.border.radius,
            height: 200,
            overflowY: 'auto',
          }}
        >
          <Text variant="h6">Error fetching data</Text>
        </Row>
      )}
    </Row>
  )
}
