import { IncentiveInfo } from './incentive'
import { Address } from './locations'
import { TransferType } from './payments'
import { ShiftStatus } from './shift-enums'
import { ScheduledBreak, Shift } from './shifts'
import { WorkerIncentive } from './worker-incentive'
import { ShiftTime } from './worker-shift-adjustments'

export enum StripePaymentLogEventType {
  PAYOUT_CREATED = 'payout.created',
  PAYOUT_UPDATED = 'payout.updated',
  PAYOUT_PAID = 'payout.paid',
  PAYOUT_FAILED = 'payout.failed',
  PAYOUT_CANCELED = 'payout.canceled',
  TRANSFER_REVERSED = 'transfer.reversed',
}

export enum TrabaPaymentLogEventType {
  TRANSFER_CREATED = 'TRANSFER_CREATED',
  PAYMENT_EARNED = 'PAYMENT_EARNED',
}

export type PaymentLogEventType =
  | TrabaPaymentLogEventType
  | StripePaymentLogEventType

export enum TrabaEntityType {
  WORKER_SHIFT = 'WORKER_SHIFT',
  WORKER_INCENTIVE = 'WORKER_INCENTIVE',
}

export enum PaymentLogReasons {
  IP_FEE = 'IP_FEE',
  IP_TOP_UP = 'IP_TOP_UP',
  SHIFT_ADJUSTMENT = 'SHIFT_ADJUSTMENT',
}

export type WorkerShiftForPaymentLog = {
  shiftId: string
  startTime?: null | Date
  endTime?: null | Date
  timeWorked?: number
  employerName: string
  unitsWorked?: number
  breaks?: ShiftTime[] | null
  totalPaidTime?: number
  totalPaidUnits?: number
  timezone: string
  scheduledBreaks: Array<ScheduledBreak>
  shiftRole: string
  shortLocation?: string
  status: ShiftStatus
  companyLogo?: string
}

export type ShiftForPaymentLog = Pick<
  Shift,
  | 'employerName'
  | 'timezone'
  | 'scheduledBreaks'
  | 'shiftRole'
  | 'shortLocation'
  | 'status'
  | 'startTime'
  | 'endTime'
  | 'payRate'
> & {
  address: Address
}

export type WorkerIncentiveForPaymentLog = Pick<WorkerIncentive, 'earnedAt'> &
  Pick<
    IncentiveInfo,
    'categoryId' | 'category' | 'type' | 'typeId' | 'description' | 'valueType'
  > &
  Partial<ShiftForPaymentLog>

export const PAID_BACK_UP_CATEGORY_ID = 'EWI-APP-PB'

export type PaymentLog = {
  id: string
  createdAt: Date
  updatedAt: Date
  amount: number
  currency: string
  eventType: PaymentLogEventType
  transferType?: TransferType
  reason?: PaymentLogReasons
  eventSource: string
  workerId: string
  stripeAccountId?: string
  trabaEntityId: string
  trabaEntityType: TrabaEntityType
  payoutMethod?: string
  runningTotalEarned: number
  runningTotalStripePaidOut: number
  runningTotalStripePending: number
  runningTotalStripeBalance: number
  runningTotalTrabaPending: number
  payoutFailedReason?: string
  payoutFailureCode?: string
  estimatedPayoutArrivalDate?: Date
  workerShiftId?: string
  workerIncentiveId?: string
  workerShift?: WorkerShiftForPaymentLog
  workerIncentive?: WorkerIncentiveForPaymentLog
}

export type PaymentLogEarnings = {
  latestPaymentLogCreatedAt: Date
  containsShiftAdjustment: boolean
  totalEarned: number
  trabaEntityType: TrabaEntityType
  trabaEntityId: string
  workerShift?: WorkerShiftForPaymentLog
  workerIncentive?: WorkerIncentiveForPaymentLog
}
