export enum RulesFlag {
  Undetermined = 'UNDETERMINED',
  Clear = 'CLEAR',
  Fraudulent = 'FRAUDULENT',
}

export type WardenMode = 'OFF' | 'FLAG_ONLY' | 'FLAG_AND_HALT'

export type RulesOutputElement = {
  key: string
  rawScore: number
  weightedScore: number
}

export type FlagOutputElement = {
  rulesFlag: RulesFlag
}

export type LocationPointType = 'START_SHIFT' | 'END_SHIFT'

export enum LocationPointTypeEnum {
  START_SHIFT = 'START_SHIFT',
  END_SHIFT = 'END_SHIFT',
}

export type LocationPoint = {
  collectedAt: Date
  milesFromShiftLocation: number
  type: LocationPointType
  latitude: number
  longitude: number
}

export type RulesOutput = Array<RulesOutputElement> | FlagOutputElement

export type RulesSummary = {
  id: string
  workerShiftId: string
  createdAt: Date
  udpatedAt: Date
  score: number
  rulesOutput: RulesOutput
  mode: WardenMode
  thresholdAtEvaluation: number
  evaluatedAt: Date
  exceedsThreshold: boolean
  rulesFlag?: RulesFlag
}
