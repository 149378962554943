import { SvgIconProps } from '../SvgIconProps'

export default function SystemMessage({
  size,
  color = '#08105E',
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35197 0H0V4.43814V9.83831L0.00635625 14.9113C0.1017 20.4847 4.6337 24.9998 10.1891 24.9998V19.5997C7.51944 19.5997 5.35197 17.4062 5.35197 14.719V9.83831H10.1891V4.43814H5.35197V0Z"
        fill={color}
      />
    </svg>
  )
}
