import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { CopyTextIcon, DataTable } from 'src/components/base'
import { truncateString } from 'src/utils/stringUtils'

export type BulkActionShiftTableProps = {
  workerShifts?: WorkerShift[]
}

export default function BulkActionShiftTable({
  workerShifts,
}: BulkActionShiftTableProps) {
  if (!workerShifts) {
    return null
  }

  return (
    <DataTable
      headers={[
        { label: 'Shift Name', sortable: true },
        'Shift ID',
        'Company Id',
      ]}
      rows={workerShifts?.map((workerShift) => {
        return {
          key: workerShift.shiftId,
          cells: [
            {
              sortKey: `${workerShift.shiftInfo.shiftRole}`,
              renderFn: () => <div>{workerShift.shiftInfo.shiftRole}</div>,
            },
            {
              renderFn: () => (
                <>
                  {truncateString(workerShift.shiftId)}
                  <CopyTextIcon textToCopy={workerShift.shiftId} />{' '}
                </>
              ),
            },
            {
              renderFn: () => (
                <>
                  {truncateString(workerShift.shiftInfo.companyId)}
                  <CopyTextIcon textToCopy={workerShift.shiftInfo.companyId} />
                </>
              ),
            },
          ],
        }
      })}
    />
  )
}
