import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BusinessCancellationReasons,
  CancellationSource,
  Shift,
  ShiftRequest,
} from '@traba/types'
import { useMemo, useState } from 'react'
import { Button, Modal, Row, Col } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { cancelShiftsById } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useShiftRequestsWithShifts } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useShiftRequestWithShifts'
import ShiftRequestCard from 'src/screens/FieldMonitorScreen/components/AddWorkers/ShiftRequestCard'
import * as S from 'src/screens/FieldMonitorScreen/styles'
import { getShiftRequestWithShifts } from 'src/utils/shiftUtils'

type BulkCancelShiftsModalProps = {
  handleClose: () => void
  isOpen: boolean
  shiftsList: Shift[]
}

export default function BulkCancelShiftsModal({
  isOpen,
  handleClose: parentHandleClose,
  shiftsList,
}: BulkCancelShiftsModalProps) {
  if (!isOpen) {
    return null
  }
  return (
    <BulkCancelShiftsModalComponent
      isOpen={isOpen}
      handleClose={parentHandleClose}
      shiftsList={shiftsList}
    />
  )
}

function BulkCancelShiftsModalComponent({
  isOpen,
  handleClose,
  shiftsList,
}: BulkCancelShiftsModalProps) {
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedShifts, setSelectedShifts] = useState<
    Record<string, string[]>
  >({})

  const shiftRequestIds = useMemo(
    () => shiftsList.map((shift: Shift) => shift.shiftRequestId),
    [shiftsList],
  )
  const hasShiftsSelected = Object.values(selectedShifts).some(
    (value) => value.length > 0,
  )
  const {
    shiftRequests,
    shifts,
    isLoading: isLoadingShiftRequests,
  } = useShiftRequestsWithShifts(shiftRequestIds)
  const disableCancelButton =
    !hasShiftsSelected || isLoadingShiftRequests || isLoading
  const disableBackButton = isLoadingShiftRequests || isLoading
  const { showError, showSuccess } = useAlert()

  const uniqueShiftRequests = useMemo(
    () =>
      shiftRequests.filter(
        (request, index, self) =>
          index ===
          self.findIndex((t) => t.shiftRequestId === request.shiftRequestId),
      ),
    [shiftRequests],
  )

  function handlePrevious() {
    if (step === 1) {
      return handleClose()
    }
    setStep(step - 1)
  }

  async function handleNext() {
    // Confirmation step
    const cancellationReason =
      BusinessCancellationReasons.WORKERS_NO_LONGER_NEEDED
    if (step === 2) {
      setIsLoading(true)
      try {
        await cancelShiftsById(
          Object.values(selectedShifts).flat(),
          cancellationReason,
          CancellationSource.Ops,
          new Date(),
        )
        showSuccess('Successfully bulk cancelled shifts', 'Success!')
      } catch (e) {
        showError(
          'Failed to cancel shifts. Please check the statuses of the shifts you requested to cancel.',
        )
      } finally {
        setIsLoading(false)
      }
      return handleClose()
    }

    // Selection step
    const companyIds = new Set(
      Object.values(selectedShifts)
        .filter((shiftIds) => shiftIds.length > 0)
        .map(
          (shiftIds) =>
            shifts.find((s) => s.shiftId === shiftIds[0])?.companyId,
        ),
    )
    // Check if selected shifts are under same company
    if (companyIds.size > 1) {
      setError('Cannot proceed with shifts from different companies')
      return showError(
        'Cannot proceed with shifts from different companies. Please select shifts from the same company.',
      )
    }
    setError('')
    return setStep(step + 1)
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      style={{ width: 1300 }}
      title="Bulk Cancel Shifts"
    >
      <Row flexCol style={{ flex: 1 }} px={theme.space.med}>
        {step === 1
          ? SelectCancelShiftsComponent({
              isLoadingShiftRequests,
              uniqueShiftRequests,
              shifts,
              selectedShifts,
              setSelectedShifts,
            })
          : ConfirmCancelShiftsComponent({ selectedShifts, shifts })}
      </Row>
      {!!error && (
        <Row my={theme.space.xs}>
          <Text variant="error">{error}</Text>
        </Row>
      )}
      <Row
        mt={theme.space.xs}
        fullWidth
        style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '200px' }}
          onClick={handlePrevious}
          disabled={disableBackButton}
        >
          {(step === 1 && 'Discard') || 'Back'}
        </Button>
        <Row>
          <Button
            style={{ width: '220px' }}
            variant={ButtonVariant.CANCEL}
            disabled={disableCancelButton}
            onClick={handleNext}
          >
            Cancel Shifts
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}

function SelectCancelShiftsComponent({
  isLoadingShiftRequests,
  uniqueShiftRequests,
  shifts,
  selectedShifts,
  setSelectedShifts,
}: {
  isLoadingShiftRequests: boolean
  uniqueShiftRequests: ShiftRequest[]
  shifts: Shift[]
  selectedShifts: Record<string, string[]>
  setSelectedShifts: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >
}) {
  return (
    <>
      <Text variant="h6" mt={theme.space.xxxs}>
        Select the shift dates you want to bulk cancel, or select all shifts in
        the shift request
      </Text>
      <Col
        mb={theme.space.med}
        style={{ justifyContent: 'flex-start', flex: 1 }}
      >
        {isLoadingShiftRequests ? (
          <Row fullHeight fullWidth justifyCenter alignCenter>
            <CircularProgress size={24} />
          </Row>
        ) : (
          uniqueShiftRequests.map((request, index) => (
            <ShiftRequestCard
              key={`${request.shiftRequestId}_${index}_s_request`}
              shiftRequest={getShiftRequestWithShifts(request, shifts)}
              selectedShifts={selectedShifts}
              setSelectedShifts={setSelectedShifts}
              // Not interested in conflicting shifts when cancelling
              conflictingShiftIds={[]}
              isCancellation={true}
            />
          ))
        )}
      </Col>
    </>
  )
}

function ConfirmCancelShiftsComponent({
  selectedShifts,
  shifts,
}: {
  selectedShifts: Record<string, string[]>
  shifts: Shift[]
}) {
  // Object that maps shiftId to shift
  const tz = useTimezonedDates(shifts[0].timezone)
  const shiftIdToShiftMap = shifts.reduce<Record<string, Shift>>(
    (acc, shift) => {
      acc[shift.shiftId] = shift
      return acc
    },
    {},
  )
  const totalCount = Object.values(selectedShifts).flat().length
  return (
    <>
      <Text variant="h4" mt={theme.space.xxxs}>
        Are you sure you want to bulk cancel following shifts?
      </Text>
      <Col>
        {Object.values(selectedShifts).flatMap((shiftIds) =>
          shiftIds.map((shiftId) => {
            const shift = shiftIdToShiftMap[shiftId]
            const shiftTimeStr = `${tz.getDate(
              shift.startTime,
              false,
            )} ${tz.getShiftTime(shift.startTime, shift.endTime)}`
            const shiftSlotStr = `${shift.slotsFilled} / ${shift.slotsRequested} filled, `
            return (
              <S.ListItemWithStatus>
                <Row
                  alignCenter
                  justifyBetween
                  fullWidth
                  py={theme.space.xxs}
                  px={theme.space.xs}
                >
                  <Text>
                    {`${shiftTimeStr} - ${shift.employerName}, ${shift.shiftRole} - ${shiftSlotStr}`}
                    <a
                      href={`/field-monitor/${shiftId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {shiftId}
                    </a>
                  </Text>
                </Row>
              </S.ListItemWithStatus>
            )
          }),
        )}
      </Col>
      <Text variant="h4" mb={theme.space.med}>
        Total Shifts to be Cancelled: {totalCount}{' '}
      </Text>
    </>
  )
}
