import { Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyNote, NoteType, ShiftNote, WorkerNote } from '@traba/types'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import { useState } from 'react'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useNotes from 'src/hooks/useNotes'
import { Button, Col, Icon, Row } from './base'
import { ButtonVariant } from './base/Button/types'
import { CompanyFirstLetter } from './CompanyLogo/CompanyLogo.styles'

interface PostedNoteProps {
  note: ShiftNote | WorkerNote | CompanyNote
  noteType: NoteType
}

export const PostedNote = (props: PostedNoteProps) => {
  const { note, noteType } = props
  const { deleteNote } = useNotes()
  const { refetchActiveQueries } = useActiveQueries()
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

  const postedAt = format(new Date(note.createdAt), 'hh:mm b LL/dd/yyyy')

  const deleteSelectedNote = async () => {
    const isConfirmed = window.confirm(`Do you want to delete this note?`)
    if (!isConfirmed) {
      return
    }
    setDeleteLoading(true)
    try {
      await deleteNote(note.noteId, noteType)
    } catch {
      //Already handled in deleteNote
    }
    setDeleteLoading(false)
    refetchActiveQueries()
  }

  return (
    <Col
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        backgroundColor: theme.colors.Grey10,
        paddingTop: theme.space.xxs,
        paddingBottom: theme.space.xxs,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        marginBottom: theme.space.xxs,
      }}
    >
      <Row alignCenter justifyBetween mb={theme.space.xxs}>
        <CardTile size={'32px'}>
          <CompanyFirstLetter fontSize={'20px'}>
            {note.author[0].toLocaleUpperCase()}
          </CompanyFirstLetter>
        </CardTile>
        <Col ml={theme.space.xxs}>
          <Text variant="caption" style={{ color: theme.colors.Violet }}>
            {note.author}
          </Text>
          <Text variant="caption" style={{ color: theme.colors.Blue100 }}>
            {postedAt}
          </Text>
        </Col>
        <Button
          variant={ButtonVariant.TRANSPARENT}
          onClick={deleteSelectedNote}
          style={{ backgroundColor: theme.colors.Grey10 }}
          loading={deleteLoading}
        >
          <Icon name="trash" />
        </Button>
      </Row>
      <Row
        style={{
          overflowX: 'auto',
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(note.note, {
              FORBID_ATTR: ['class'],
            }),
          }}
          style={{ fontSize: 12 }}
        />
      </Row>
    </Col>
  )
}
