import { Avatar, Tooltip } from '@mui/material'
import { SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getMissingRequirementsTooltipForApprovedWorker } from 'src/utils/helperUtils'
import { getAccountStatusColor } from '../../utils/workerUtils'
import { Col, Row, Link } from '../base'
import { PhoneNumberWithStatus } from '../PhoneNumberWithStatus'
import * as S from './styles'

interface WorkerCardProps {
  worker: PopulatedWorker
}

const WorkerCard = ({ worker }: WorkerCardProps) => {
  const missingReqsTooltipText = getMissingRequirementsTooltipForApprovedWorker(
    {
      accountStatus: worker.accountStatus.accountStatus,
      backgroundCheckStatus: worker.accountStatus.backgroundCheck?.status,
      backgroundCheckAdjudication:
        worker.accountStatus.backgroundCheck?.adjudication,
      backgroundCheckAssessment:
        worker.accountStatus.backgroundCheck?.assessment,
      payoutsEnabled: worker.accountStatus.payment?.payoutsEnabled,
      payoutsDisabledDeadline:
        worker.accountStatus.payment?.payoutsDisabledDeadline,
      phoneNumberStatus: worker.accountStatus.phoneNumberStatus,
    },
  )
  const tooltipContent = missingReqsTooltipText ? (
    <span style={{ whiteSpace: 'pre-line' }}>{missingReqsTooltipText}</span>
  ) : null

  return (
    <S.WorkerCardContainer>
      <S.AvatarWrapper>
        <Avatar src={worker.photoUrl} style={{ width: 120, height: 120 }} />
      </S.AvatarWrapper>
      <S.InfoWrapper>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Name</Text>
          <Text>
            {worker.firstName} {worker.lastName}
          </Text>
        </Col>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Phone</Text>
          <PhoneNumberWithStatus
            phoneNumber={worker.phoneNumber}
            phoneNumberStatus={worker.accountStatus.phoneNumberStatus}
          />
        </Col>
        <Col mb={theme.space.xxs}>
          <Text variant="h7">Id</Text>
          <Text>{worker.id}</Text>
        </Col>
        <Row justifyBetween fullWidth mb={theme.space.xxs}>
          <Col>
            <Text variant="h7">Status</Text>
            <Row>
              <Tooltip arrow title={tooltipContent}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.space.us,
                    marginLeft: theme.space.us,
                  }}
                >
                  {tooltipContent && (
                    <SvgIcon
                      name="alert"
                      size={16}
                      color={theme.colors.Red50}
                    />
                  )}
                  <Text
                    variant="h7"
                    style={{
                      color: getAccountStatusColor(
                        worker.accountStatus?.accountStatus,
                      ),
                    }}
                  >
                    {worker.accountStatus?.accountStatus}
                  </Text>
                </div>
              </Tooltip>
            </Row>
          </Col>
        </Row>
        <Row justifyBetween fullWidth mb={theme.space.xxs}>
          <Col>
            <Text variant="h7">Tier</Text>
            <Text>{worker.workerMetric?.tierLevel}</Text>
          </Col>
          <Col>
            <Text variant="h7">Region</Text>
            <Text>{worker.regionId}</Text>
          </Col>
        </Row>
      </S.InfoWrapper>
      <Row my={theme.space.xs}>
        <Link to={`/workers/${worker.id}`} target="_blank">
          Go to profile
        </Link>
      </Row>
    </S.WorkerCardContainer>
  )
}

export default WorkerCard
