import { useWorkerScores } from 'src/hooks/useWorkerMetrics'
import { toPercentString } from 'src/utils/stringUtils'

export default function WorkerReliabilityScore({
  workerId,
}: {
  workerId: string
}) {
  const { workerScores } = useWorkerScores(workerId)
  return workerScores?.reliabilityScore != null
    ? `${toPercentString(workerScores.reliabilityScore)}%`
    : '-'
}
