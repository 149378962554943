export type WorkerCommunication = {
  id: string
  createdAt: Date
  updatedAt: Date
  body?: string
  media?: string
  bugleBlastId?: string
  shiftId?: string
  workerId?: string
  toNumber: string
  fromNumber: string
  status: TwilioMessageStatus | OpenPhoneMessageStatus
  messageSid: string
  medium: WorkerCommunicationMedium
  messageProvider: MessageProvider
  messageSource: MessageSource
  price?: number
  priceUnit?: string
  errorCode?: string
  errorMessage?: string
}

export type WorkerInfoForCommunication = {
  firstName: string
  lastName: string
  email: string
  photoUrl?: string
}

export enum WorkerCommunicationMedium {
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  ROBOCALL = 'ROBOCALL',
  OPENPHONE_CALL = 'OPENPHONE_CALL',
}

export enum MessageProvider {
  TWILIO = 'TWILIO',
  OPENPHONE = 'OPENPHONE',
}

export enum MessageSource {
  TRABA = 'TRABA',
  WORKER = 'WORKER',
}

export enum TwilioMessageStatus {
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  RECEIVING = 'receiving',
  RECEIVED = 'received',
  ACCEPTED = 'accepted',
  SCHEDULED = 'scheduled',
  READ = 'read',
  PARTIALLY_DELIVERED = 'partially_delivered',
  CANCELED = 'canceled',
}

export enum OpenPhoneMessageObject {
  MESSAGE = 'message',
  CALL = 'call',
}

export enum OpenPhoneMessageDirection {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export enum OpenPhoneMessageStatus {
  RECEIVED = 'received',
  DELIVERED = 'delivered',
  RINGING = 'ringing',
  COMPLETED = 'completed',
}

export enum OpenPhoneMessageType {
  MESSAGE_RECEIVED = 'message.received',
  MESSAGE_DELIVERED = 'message.delivered',
  CALL_RINGING = 'call.ringing',
  CALL_INCOMING = 'call.incoming',
  CALL_COMPLETED = 'call.completed',
  CALL_RECORDING_COMPLETED = 'call.recording.completed',
  CONTACT_UPDATED = 'contact.updated',
}

export enum MessageLogsFilters {
  OPEN_PHONE_MESSAGES = 'openPhoneMessages',
  OPEN_PHONE_CALLS = 'openPhoneCalls',
  SYSTEM_MESSAGES = 'systemMessages',
  BUGLE_SMS = 'bugleSms',
}
