import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ARCHIVED_REGION_ID } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  COMPANY_WIDE_ID,
  Locations,
  OpsCreateLocationDto,
  OpsUpdateLocationDto,
  RegionIdToLocationMapOps,
} from '@traba/types'
import {
  isLocationActive,
  isLocationArchived,
  isLocationValid,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY } from './useCompanyShiftsLocationsForSupervisor'
import { COMPANY_SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY } from './useCompanyShiftsSupervisorsForLocation'
import { useCompanyUsers } from './useCompanyUsers'

const COMPANY_LOCATIONS_QUERY_KEY = 'locations'

async function getLocations(
  companyId: string,
): Promise<Locations[] | undefined> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/locations`)
    return res.data || []
  } catch (error: any) {
    console.error(
      'useLocations -> getLocations() ERROR',
      error.message ?? error,
    )
  }
}

interface CreateLocationParams {
  companyId: string
  newLocation: OpsCreateLocationDto
}

const createLocation = async ({
  companyId,
  newLocation,
}: CreateLocationParams) => {
  if (!companyId) {
    throw new Error('companyId is required')
  }

  const response = await trabaApi.post(
    `companies/${companyId}/locations`,
    newLocation,
  )
  return response.data
}

interface EditLocationParams {
  companyId: string
  locationId: string
  updatedLocation: OpsUpdateLocationDto
}

const editLocation = async ({
  companyId,
  locationId,
  updatedLocation,
}: EditLocationParams) => {
  if (!companyId || !locationId) {
    throw new Error('companyId and locationId are required')
  }

  const response = await trabaApi.patch(
    `companies/${companyId}/locations/${locationId}`,
    updatedLocation,
  )
  return response.data
}

interface ArchiveLocationParams {
  companyId: string
  locationId: string
}

const archiveLocation = async ({
  companyId,
  locationId,
}: ArchiveLocationParams) => {
  if (!companyId || !locationId) {
    throw new Error('companyId and locationId are required')
  }

  const response = await trabaApi.patch(
    `companies/${companyId}/locations/${locationId}/archive`,
  )
  return response.data
}

export function useLocations(companyId: string) {
  const queryClient = useQueryClient()
  const { refetch: refetchCompanyUsers } = useCompanyUsers(companyId)
  const { showError, handleError, showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched,
    refetch,
  } = useQuery<Locations[] | undefined, Error>({
    queryKey: [COMPANY_LOCATIONS_QUERY_KEY, companyId],
    queryFn: () => getLocations(companyId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!companyId,
  })

  const createLocationMutation = useMutation<
    Locations,
    AxiosError,
    CreateLocationParams
  >({
    mutationFn: createLocation,
    onSuccess: (response, variables) => {
      queryClient.setQueryData(
        [COMPANY_LOCATIONS_QUERY_KEY, companyId],
        (currentLocations: Locations[] | undefined) => {
          return currentLocations ? [...currentLocations, response] : [response]
        },
      )

      // refetch members if users were assigned to the new location
      // b/c this is a *new* location, we only need to refetch if users were assigned to it
      // (aka when userIds is not empty), otherwise current users are unaffected
      if (
        variables.newLocation.userIds &&
        variables.newLocation.userIds.length > 0
      ) {
        refetchCompanyUsers()
      }

      showSuccess('', 'Location Created')
    },
    onError: (error) => {
      handleError(
        error,
        'useLocations -> createLocation()',
        JSON.stringify(error),
        'Error creating location',
      )
    },
  })

  const editLocationMutation = useMutation<any, AxiosError, EditLocationParams>(
    {
      mutationFn: editLocation,
      onSuccess: (response, variables) => {
        if (!response.updatedLocation) {
          return
        }
        let successMessage =
          'Arrival Location Changes were successfully saved. Future shifts tied to this location have been updated.'
        if (response.archivedLocationId) {
          successMessage +=
            ' Previous or ongoing shifts wont be updated. If you used the ops override, any shifts that have not started will be updated. Otherwise, only shifts starting later than 2 hours from now will be updated.' +
            ' Please update the shift area fence as well if necessary'
        }

        // refetch members as updated assigned members may have changed
        refetchCompanyUsers()

        // invalidate future shifts for the users that were replaced & their replacements
        const idsToInvalidateForFutureShifts =
          variables.updatedLocation.replacementSupervisorsForUsers?.flatMap(
            ({ oldSupervisorId, newSupervisorId }) => [
              oldSupervisorId,
              newSupervisorId,
            ],
          ) || []

        for (const userId of idsToInvalidateForFutureShifts) {
          queryClient.invalidateQueries({
            queryKey: [
              COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY,
              companyId,
              userId,
            ],
          })
        }

        // invalidate the future shifts for this location if supervisors were replaced
        if (idsToInvalidateForFutureShifts.length > 0) {
          queryClient.invalidateQueries({
            queryKey: [
              COMPANY_SHIFTS_SUPERVISORS_FOR_LOCATION_QUERY_KEY,
              companyId,
              response.updatedLocation.locationId,
            ],
          })
        }

        // update location
        refetch()
        showSuccess(successMessage, 'Edit location successful')
      },
      onError: (error) => {
        if (error.message === 'edit-location/exceeds-radius-threshold') {
          showError(
            'The new arrival location you entered is greater than 1 mile from the original address. You can override this by using the ops override checkbox.',
            'Location edit exceeds radius threshold.',
          )
        } else {
          handleError(
            error,
            'useLocations -> editLocation()',
            JSON.stringify(error),
            'Error editing location',
          )
        }
      },
    },
  )

  const archiveLocationMutation = useMutation<
    any,
    AxiosError,
    ArchiveLocationParams
  >({
    mutationFn: archiveLocation,
    onSuccess: () => {
      // refetch members as updated assigned members may have changed
      refetchCompanyUsers()

      // refetch locations
      refetch()

      showSuccess('', 'Location successfully archived')
    },
    onError: (error) => {
      if (error.message === 'archive/active-shifts') {
        showError(
          'You cannot delete a location that is tied to shifts in the future.',
          'Unable to archive location',
        )
      } else {
        handleError(
          error,
          'useLocations -> archiveLocation()',
          JSON.stringify(error),
          'Error archiving location',
        )
      }
    },
  })

  const validLocations = useMemo(
    () => locations?.filter(isLocationValid) || [],
    [locations],
  )
  const activeLocations = useMemo(
    () => locations?.filter(isLocationActive) || [],
    [locations],
  )
  const activeValidLocations = useMemo(
    () => activeLocations.filter(isLocationValid),
    [activeLocations],
  )

  return {
    isLoading,
    isError,
    locations,
    validLocations,
    activeValidLocations,
    error,
    isFetched,
    refetch,
    archiveLocation: archiveLocationMutation.mutate,
    createLocationAsync: createLocationMutation.mutateAsync,
    editLocation: editLocationMutation.mutate,
  }
}

export function useRegionLocationMap(companyId: string) {
  const { isLoading, validLocations, activeValidLocations } =
    useLocations(companyId)

  const regionLocationMap = useMemo(
    () =>
      validLocations.reduce<RegionIdToLocationMapOps>((acc, location) => {
        const key = isLocationArchived(location)
          ? ARCHIVED_REGION_ID
          : location.regionId || COMPANY_WIDE_ID
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(location)
        return acc
      }, {}),
    [validLocations],
  )

  const activeRegionsWithLocationsMap = useMemo(
    () =>
      activeValidLocations.reduce<Record<string, Locations[]>>(
        (acc, location) => {
          if (!isLocationArchived(location)) {
            const key = location.regionId || COMPANY_WIDE_ID
            if (!acc[key]) {
              acc[key] = []
            }
            acc[key].push(location)
          }
          return acc
        },
        {},
      ),
    [activeValidLocations],
  )

  return { isLoading, regionLocationMap, activeRegionsWithLocationsMap }
}
