import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { format } from 'date-fns'
import { useState } from 'react'
import { Col, Modal, Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatDurationBetweenDates } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'

type ConfirmShiftModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
}

export default function ConfirmShiftModal({
  isOpen,
  handleClose,
  workerShift,
}: ConfirmShiftModalProps) {
  const [confirmationTime, setConfirmationTime] = useState<Date>(
    new Date(workerShift.shiftInfo.startTime),
  )
  const curConfirmationTime = workerShift.confirmedAt
    ? new Date(workerShift.confirmedAt)
    : undefined

  const timeAdjustedString = formatDurationBetweenDates(
    curConfirmationTime || new Date(),
    confirmationTime,
  )
  const { refetchActiveQueries } = useActiveQueries()

  const [loading, setLoading] = useState<boolean>(false)

  const { showError } = useAlert()

  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const handleConfirmShift = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}`,
        {
          isShiftConfirmed: true,
          confirmationSource: 'Ops',
          confirmedAt: confirmationTime,
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Confirming Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      size={MODAL_SIZE.LARGE}
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Update Shift Confirmation'}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col>
          <Row flexCol style={{ marginTop: '32px', marginBottom: '16px' }}>
            <Text variant="h5">Original</Text>
            <Row justifyBetween style={{ marginTop: '24px' }}>
              <Col>
                <Text variant="caption">YOUR TIME</Text>
                <Text style={{ marginTop: '16px' }} variant="body1">
                  {curConfirmationTime
                    ? format(curConfirmationTime, 'MMM d yyyy, hh:mm aa')
                    : '-'}
                </Text>
              </Col>
              <Col>
                <Text variant="caption">SHIFT LOCAL TIME</Text>
                <Text style={{ marginTop: '16px' }} variant="body1">
                  {curConfirmationTime
                    ? `${tz.getDate(curConfirmationTime)}, ${tz.getTime(
                        curConfirmationTime,
                      )}`
                    : '-'}
                </Text>
              </Col>
            </Row>
          </Row>
          <Row flexCol style={{ marginTop: '32px' }}>
            <Text variant="h5">New</Text>
            <Row justifyBetween style={{ marginTop: '24px' }}>
              <Col>
                <Text style={{ marginBottom: '16px' }} variant="caption">
                  SHIFT LOCAL TIME
                </Text>
                <div style={{ width: '80%' }}>
                  <DatePicker
                    showTimeFieldInPopover={true}
                    setDate={(confirmationTime) => {
                      if (confirmationTime) {
                        setConfirmationTime(confirmationTime)
                      }
                    }}
                    isClearable={false}
                    inlineLabel={true}
                    label="Time"
                    date={confirmationTime}
                    timezone={workerShift.shiftInfo.timezone}
                  />
                </div>
              </Col>
            </Row>
          </Row>
          {!!workerShift?.confirmedAt && (
            <Row style={{ marginTop: '50px' }}>
              <Text style={{ marginRight: '8px' }} variant="h7">
                Time Adjusted:
              </Text>
              <Text variant="h5">{` ${timeAdjustedString}`}</Text>
            </Row>
          )}
        </Col>
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={handleConfirmShift}
          loading={loading}
        />
      </Col>
    </Modal>
  )
}
