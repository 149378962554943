import { useParams } from 'react-router-dom'
import { ShiftInfoDetailsHeader } from '../../components/ShiftInfoDetailsHeader/ShiftInfoDetailsHeader'
import AuditLogsScreen from './AuditLogsScreen'

export default function ShiftLogsScreen() {
  const { shiftId = '' } = useParams<{ shiftId: string }>()

  return (
    <AuditLogsScreen
      headerContent={<ShiftInfoDetailsHeader shiftId={shiftId} />}
      logType="SHIFT"
      searchId={shiftId}
    />
  )
}
