import {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@mui/material'
import { theme } from '@traba/theme'

export default function ToggleButtonGroup(props: MuiToggleButtonGroupProps) {
  const { children, ...rest } = props
  return (
    <MuiToggleButtonGroup
      fullWidth={props.fullWidth}
      sx={{
        '& .MuiToggleButton-root': {
          backgroundColor: theme.colors.White,
        },
        '& .MuiToggleButton-root:hover': {
          color: theme.colors.brand,
        },
        '& .Mui-selected': {
          backgroundColor: theme.colors.Violet,
          color: theme.colors.White,
        },
        '& .Mui-selected:hover': {
          backgroundColor: theme.colors.Violet50,
          color: theme.colors.White,
        },
      }}
      {...rest}
    >
      {children}
    </MuiToggleButtonGroup>
  )
}
