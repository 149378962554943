import { Mark } from '@mui/base'
import Slider from '@mui/material/Slider'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import * as React from 'react'
import { Col } from '.'

type NumberSliderProps = {
  value: number
  setValue: React.Dispatch<React.SetStateAction<number>>
  rangeMin: number
  rangeMax: number
  step: number
  label?: string
}
//Note there is not error handling around the props passed having to be valid but
//the dev should use it correctly
export default function NumberSlider(props: NumberSliderProps) {
  const { rangeMin, rangeMax, step, setValue, label } = props
  const marks: Mark[] = []

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number)
  }

  for (let i = rangeMin; i <= rangeMax; i = i + step) {
    marks.push({ value: i, label: `${i}` })
  }

  return (
    <Col style={{ display: 'flex' }}>
      {!!label && (
        <Text variant="h5" style={{ marginBottom: '8px' }}>
          {label}
        </Text>
      )}

      <span style={{ margin: `0 ${theme.space.xxs}px` }}>
        <Slider
          aria-label="Always visible"
          defaultValue={props.value}
          max={rangeMax}
          min={rangeMin}
          step={step}
          marks={marks}
          valueLabelDisplay="auto"
          onChange={handleChange}
        />
      </span>
    </Col>
  )
}
