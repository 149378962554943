import { WifiOff } from '@mui/icons-material'

import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNetworkState } from 'react-use'
import { SidebarIconName } from 'src/assets/svg/icons'
import { Icon, Link, Logo, Row } from 'src/components/base'
import Toggle from 'src/components/base/Toggle'
import SettingsContext from 'src/context/settingsContext/SettingsContext'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useGoogleAuth } from 'src/hooks/useAuth'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { hasPermissions } from 'src/hooks/usePermissions'
import { getLocalTimezone } from 'src/utils/dateUtils'
import { NavigationDropdown } from './NavigationDropdown'
import * as S from './Sidebar.styles'

type SidebarProps = {
  isCollapsed?: boolean
  setIsCollapsed: (newValue: boolean) => void
}

type SidebarOption = {
  onClick?: (event: any) => Promise<void> | void
  route?: string
  icon: SidebarIconName
  label: string
  permissions?: UserRolePermission[]
  hidden?: boolean
  subOptions?: (SidebarOption & { route: string })[]
}

export function Sidebar({ isCollapsed, setIsCollapsed }: SidebarProps) {
  const userContext = useUserContext()
  const location = useLocation()
  const localTimezone = getLocalTimezone()
  const { handleLogout } = useGoogleAuth()
  const settingsContext = useContext(SettingsContext)
  const networkState = useNetworkState()
  const { hotSettings } = useHotSettings()

  const [animate, setAnimate] = useState(false)
  const { state } = useUserContext()

  const today = new Date()
  const isAprilFools = today.getMonth() === 3 && today.getDate() === 1

  useEffect(() => {
    const timestamp = localStorage.getItem('activateSidebarAnimation')
    if (timestamp) {
      const timeDifference = Date.now() - parseInt(timestamp)
      // Less than 5 minutes
      if (timeDifference < 1 * 60 * 1000) {
        setAnimate(true)
      }
    }
  }, [isCollapsed])

  const handleAnimation = () => {
    alert('Why did you press it?!?!')
    // Set the current timestamp in localStorage
    localStorage.setItem('activateSidebarAnimation', Date.now().toString())
    setAnimate(true)
    window.analytics.track(`User Clicked Change DoNotPRess`, {
      email: state.userProfile?.email,
    })
  }

  function toggleTimezone() {
    const { timezoneOverride } = settingsContext

    const updatedTimezoneOverride =
      localTimezone !== timezoneOverride ? localTimezone : null

    settingsContext.setSettings({
      ...settingsContext,
      timezoneOverride: updatedTimezoneOverride,
    })
  }

  const vettingCampaignSidebarOption: SidebarOption = {
    route: '/vetting',
    icon: 'vetting',
    label: 'Vetting',
  }

  const isDev = import.meta.env.VITE_ENV !== 'production'
  const sidebarOptions: SidebarOption[] = [
    {
      route: '/field-monitor',
      icon: 'monitor',
      label: 'Field Monitor',
    },
    {
      route: '/workers',
      icon: 'workers',
      label: 'Workers Search',
    },
    {
      route: '/companies',
      icon: 'company',
      label: 'Companies',
    },
    {
      route: '/action-center/clearing-house',
      icon: 'dashboard',
      label: 'Action Center',
      permissions: [UserRolePermission.ViewActionCenter],
    },
    ...(hotSettings?.enableVettingCampaigns
      ? [vettingCampaignSidebarOption]
      : []),
    {
      route: '/worker-vetting/campaigns',
      icon: 'aiPlatform',
      label: 'AI Platform',
    },
    {
      route: '/incentives',
      icon: 'incentives',
      label: 'Incentives',
      permissions: [UserRolePermission.ViewActionCenter],
      hidden: !hotSettings?.showIncentivesInOpsConsole,
    },
    {
      route: '/billing',
      icon: 'billing',
      label: 'Billing',
      permissions: [UserRolePermission.ViewInvoices],
    },
    {
      icon: 'settings',
      label: 'Tools',
      subOptions: [
        { route: '/tools', icon: 'settings', label: 'Misc tools' },
        { route: '/regions', icon: 'dashboard', label: 'Regions management' },
        {
          route: '/company-worker-feedback',
          icon: 'dashboard',
          label: 'Company worker feedback',
        },
        {
          route: '/timesheet',
          icon: 'timesheet',
          label: 'Timesheet Automation',
        },
        ...(isDev
          ? [
              {
                route: '/demo-builder',
                icon: 'settings' as SidebarIconName,
                label: 'Demo Account Builder',
              },
            ]
          : []),
      ],
    },
    {
      onClick: handleLogout,
      icon: 'logout',
      label: 'Logout',
    },
  ]

  return (
    <S.SidebarWrapper isCollapsed={isCollapsed} isDev={isDev} animate={animate}>
      <div>
        <Row mb={10} ml={12} alignStart>
          <S.SideNavWrapper
            $isInverted={!isCollapsed}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Icon name="sideNav" type="svg" size={18} />
          </S.SideNavWrapper>
          {isCollapsed && (
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <Logo style={{ marginLeft: 18, marginTop: -1 }} />
              {isDev && <h2 style={{ marginLeft: theme.space.xxs }}>dev</h2>}
            </Link>
          )}
        </Row>
        {!networkState.online && (
          <Row
            fullWidth
            justifyBetween={isCollapsed}
            justifyCenter={!isCollapsed}
            alignCenter
            px={isCollapsed ? theme.space.med : 0}
            py={theme.space.xxs}
            mt={theme.space.xs}
            style={{
              backgroundColor: '#fff',
              borderRadius: theme.border.radius,
            }}
          >
            {isCollapsed ? (
              <Text
                style={{ color: theme.colors.Red60, fontWeight: 550 }}
                mr={theme.space.xxs}
              >
                Offline mode
              </Text>
            ) : null}
            <WifiOff style={{ transform: 'scaleX(-1)' }} color="error" />
          </Row>
        )}

        <S.SidebarLinks>
          {sidebarOptions
            .filter(
              (option) =>
                !option.hidden &&
                (!option.permissions ||
                  (option.permissions &&
                    hasPermissions(
                      userContext.state.userProfile,
                      option.permissions,
                    ))),
            )
            .map((option, idx) => {
              if (option.route) {
                const isActive = location.pathname.startsWith(option.route)
                return (
                  <S.LinkWrapper
                    onClick={option.onClick ? option.onClick : undefined}
                    key={idx}
                  >
                    <S.Link
                      $isActive={isActive}
                      to={option.route}
                      className="sidebar-link"
                    >
                      <Icon
                        name={`${option.icon}${isActive ? '_active' : ''}`}
                        style={{ marginRight: theme.space.xxs }}
                      />
                      {isCollapsed ? option.label : null}
                    </S.Link>
                  </S.LinkWrapper>
                )
              }
              if (option.subOptions) {
                return (
                  <NavigationDropdown
                    label={option.label}
                    items={option.subOptions}
                    isCollapsed={!!isCollapsed}
                    key="tools-dropdown"
                  />
                )
              }
              return (
                <div key={`${option.label}-div`}>
                  <S.LinkWrapper key={option.label} onClick={option.onClick}>
                    <S.Link
                      className="sidebar-link"
                      to=""
                      onClick={(ev) => ev.preventDefault()}
                    >
                      <Icon
                        name={option.icon}
                        style={{ marginRight: theme.space.xxs }}
                      />
                      {isCollapsed ? option.label : null}
                    </S.Link>
                  </S.LinkWrapper>
                </div>
              )
            })}
          {isAprilFools && (
            <button
              onClick={handleAnimation}
              style={{
                marginTop: '20px',
                borderRadius: '50%',
                backgroundColor: animate ? 'gray' : 'ORANGE',
                color: 'white',
                width: '100%',
                height: '70px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                cursor: 'pointer',
              }}
              disabled={animate}
            >
              {animate ? 'YOU PRESSED IT :)' : 'DO NOT PRESS'}
            </button>
          )}
        </S.SidebarLinks>
      </div>

      {/* Timezone Toggle */}
      <Row mb={theme.space.xxs} alignCenter justifyCenter>
        <Toggle
          label={isCollapsed ? 'Local Timezone' : ''}
          labelPlacement="end"
          buttonState={settingsContext.timezoneOverride === localTimezone}
          runOnChange={toggleTimezone}
          containerStyle={{
            paddingLeft: theme.space.xxs,
            paddingRight: theme.space.xxs,
          }}
          labelStyle={{
            marginRight: 0,
            ...(isCollapsed ? {} : { marginLeft: 0 }),
          }}
        />
      </Row>
    </S.SidebarWrapper>
  )
}
