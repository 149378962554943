import { useLocation } from 'react-router-dom'

/**
 * Transforms pure number amounts in strings with pixel unit added
 * E.g. 5 becomes "5px"
 * @param measure measure amount
 * @returns string value
 */
export function addPixelUnit(measure: string | number) {
  if (isNaN(Number(measure)) && typeof measure === 'string') {
    return measure
  }
  return `${measure}px`
}

export function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}

/**
 * Generate a SELECT list from a TypeScript enum
 */
export function enumToSelectItems<
  K extends keyof T,
  T extends Record<K, string | number>,
>(enumeration: T): Array<{ value: T[K]; label: string }> {
  return [
    ...Object.entries(enumeration).map(([key, value]) => ({
      value: value as T[K],
      label: key,
    })),
  ]
}

/**
 * Converts MegaBytes to Bytes
 * @param sizeInMB size in MB
 * @returns size in Bytes
 */
export function megabytesToBytes(sizeInMB: number) {
  return sizeInMB * 1000000
}
