import { Text } from '@traba/react-components'
import { ExtendedShift } from '@traba/types'
import React, { useState } from 'react'
import { Row } from 'src/components/base'
import { useShiftAnnouncements } from 'src/hooks/useShiftAnnouncements'
import { AddAnnouncementSection } from './AddAnnouncementSection'
import { AnnouncementEmptyLoading } from './AnnouncementEmptyLoading'
import { AnnouncementItem } from './AnnouncementItem'

interface Props {
  shiftId: string
  shift: ExtendedShift
}

export const AnnouncementsSection = ({ shiftId, shift }: Props) => {
  const { announcements, isLoading } = useShiftAnnouncements(shiftId)

  const [isAdding, setIsAdding] = useState(false)

  return (
    <div>
      <Row justifyBetween mb="xs">
        <Text variant="body2">
          Send an announcement to all workers on this shift, and view past
          announcements here.
        </Text>

        {!isAdding && (
          <Text
            variant="link"
            onClick={() => {
              setIsAdding(true)
            }}
          >
            {'+ New announcement'}
          </Text>
        )}
      </Row>
      {isAdding && (
        <AddAnnouncementSection
          shift={shift}
          onSubmitOrCancel={() => {
            setIsAdding(false)
          }}
        />
      )}
      {announcements && announcements.length > 0 ? (
        announcements.map((announcement) => (
          <AnnouncementItem
            announcement={announcement}
            timezone={shift.timezone}
          />
        ))
      ) : isAdding ? null : (
        <AnnouncementEmptyLoading isLoading={isLoading} />
      )}
    </div>
  )
}
