import { InputStatus } from '@traba/types'

export type RequiredProps = {
  required?: boolean | undefined
  inputStatus?: InputStatus
  sm?: boolean
}

export const Required = ({ required }: RequiredProps) => {
  if (!required) {
    return null
  }
  return <div style={{ display: 'inline-block' }}>{` *`}</div>
}
