import { Skeleton } from '@mui/material'
import { Table, Td, Tr } from 'src/components/base'

const DEFAULT_PAGE_SIZE = 100

type NotificationTableSkeletonProps = {
  headers: string[]
}

export default function NotificationsTableSkeleton({
  headers,
}: NotificationTableSkeletonProps) {
  return (
    <Table headers={headers}>
      {[...Array(DEFAULT_PAGE_SIZE)].map((_, i) => (
        <Tr key={`notification_header_${i}`}>
          {headers.map((_, idx) => (
            <Td key={`notification_header_item_${idx}`}>
              <Skeleton width="100%" height={40} />
            </Td>
          ))}
        </Tr>
      ))}
    </Table>
  )
}
