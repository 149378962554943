import { GenderPreference } from '@traba/types'
import { WorkerGender } from '@traba/types'
import Badge from '.'

export type GenderBadgeProps = {
  gender?: WorkerGender | GenderPreference
}

const genderToBadgeVariantDict: Record<WorkerGender, string> = {
  [WorkerGender.Male]: 'info',
  [WorkerGender.Female]: 'success',
  [WorkerGender.Other]: 'disabled',
  [WorkerGender.PreferNotToSay]: 'disabled',
}

export default function GenderBadge({ gender }: GenderBadgeProps) {
  return (
    <Badge
      title={gender || 'Not Filled'}
      variant={gender ? genderToBadgeVariantDict[gender] : 'disabled'}
    />
  )
}
