import CircularProgress from '@mui/material/CircularProgress'
import { LoadingSpinner, Tab, TabPanel, Tabs } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IMenuItem,
  InternalUser,
  NotificationSettingsLevel,
  SentinelActionTaken,
  SentinelCheckpointBasedEventType,
} from '@traba/types'
import { formatInTimeZone } from 'date-fns-tz'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import { VolumeCross } from 'src/assets/svg/icons/VolumeCross'
import {
  useSentinelMutations,
  useSentinelNotificationForId,
  useSentinelNotificationsForShifts,
} from 'src/hooks/useSentinelNotifications'
import { useShift } from 'src/hooks/useShifts'
import { useWaitlist } from 'src/hooks/useWaitlist'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import WaitlistTable from 'src/screens/ShiftDetailsScreen/components/WaitlistTable/WaitlistTable'
import { internalUserHasSupervisorPrivileges } from 'src/utils/internalUserUtils'
import { getShiftInfoString } from 'src/utils/shiftUtils'
import { useHotSettings } from '../../hooks/useHotSettings'
import {
  Button,
  Col,
  Collapsible,
  CollapsibleIcon,
  CopyTextIcon,
  Icon,
  Row,
} from '../base'
import { ButtonVariant } from '../base/Button/types'
import { DotText } from '../base/DotText'
import { WorkerCommunications } from '../WorkerCommunications/WorkerCommunications'
import {
  calculateStatusCounts,
  constructStatusOptions,
  WorkerTable,
} from '../WorkerTable/WorkerTable'
import { SentinelActions } from './components/SentinelActions'
import { SentinelNotificationsList } from './components/SentinelNotificationsList'
import { SentinelWorkerTransitsTable } from './components/SentinelWorkerTransitsTable'
import { SentinelWorkerMap } from './SentinelWorkerMap'
import { titleForEventActionType } from './utils'

interface SentinelShiftNotificationsDropdownProps {
  shiftId: string
  timezone: string
  internalUser: InternalUser
  setIsLoadingShiftNotifications?: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

enum ExpandedSection {
  WORKER_MAP = 'WORKER_MAP',
  WORKERS_LIST = 'WORKERS_LIST',
  WAITLIST = 'WAITLIST',
  NONE = 'NONE',
}

export const SentinelShiftNotificationsDropdown = (
  props: SentinelShiftNotificationsDropdownProps,
) => {
  const {
    shiftId,
    timezone,
    internalUser,
    onClose,
    setIsLoadingShiftNotifications,
  } = props
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>(
    ExpandedSection.NONE,
  )
  const [selectedWorkerIds, setSelectedWorkerIds] = useState<string[]>([])
  const { hotSettings } = useHotSettings()
  const { enableEchoMessagesInSentinel, enableWorkerShiftTransitNotification } =
    hotSettings || {}

  const [tabIdx, setTabIdx] = React.useState(0)
  const handleTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: number,
  ) => {
    setTabIdx(val)
  }

  const handleExpandSection = (section: ExpandedSection) => {
    setExpandedSection(
      expandedSection === section ? ExpandedSection.NONE : section,
    )
  }

  const { data, isLoading: isLoadingShiftNotifications } =
    useSentinelNotificationsForShifts({
      shiftIds: [shiftId],
      limit: 20,
      startAt: 0,
      internalUserId: internalUser.id,
    })

  const { shift, isLoading: isLoadingShift } = useShift(shiftId)
  const { workerShifts = [], isLoading: isLoadingWorkerShifts } =
    useWorkerShifts(shiftId)
  const {
    waitlistWorkers,
    isLoading: isLoadingWaitlist,
    removeFromWaitlist,
  } = useWaitlist(shiftId)

  useEffect(() => {
    setIsLoadingShiftNotifications &&
      setIsLoadingShiftNotifications(isLoadingShiftNotifications)
  }, [isLoadingShiftNotifications, setIsLoadingShiftNotifications])

  const sortedNotifications = useMemo(() => {
    let notifications = data?.shifts[0]?.notifications ?? []
    if (!enableWorkerShiftTransitNotification) {
      notifications = notifications.filter(
        (notification) =>
          notification.eventType !==
          SentinelCheckpointBasedEventType.WORKERS_TRANSIT_STATUS,
      )
    }
    return notifications.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
  }, [data])

  const actionsTaken = useMemo(() => {
    const actions = data?.shifts[0]?.actionsTaken ?? []
    return actions.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
  }, [data])

  const [selectedNotificationId, setSelectedNotificationId] = useState<string>(
    sortedNotifications?.[0]?.id ?? '',
  )

  // TODO(joey) extract to function?
  const notificationSettings = data?.allShiftSettings[shiftId]
  const {
    shiftSettings: shiftNotificationSettings,
    userSpecificSettings = {},
  } = notificationSettings ?? {}
  const userNotificationSettings = userSpecificSettings[internalUser.id] ?? {}

  const { data: selectedNotification, isLoading } =
    useSentinelNotificationForId({
      notificationId: selectedNotificationId,
      internalUserId: internalUser.id,
    })
  const { updateNotificationSettings } = useSentinelMutations()

  const updateShiftNotificationSettings = useCallback(
    async (
      settingsLevel: NotificationSettingsLevel,
      shiftSilenced: boolean,
    ) => {
      await updateNotificationSettings({
        internalUserId: internalUser.id,
        shiftId: shiftId,
        settingsLevel: settingsLevel,
        notificationSettings: {
          shiftSilenced: shiftSilenced,
        },
      })
    },
    [internalUser, shiftId, updateNotificationSettings],
  )

  const [statusFilters, setStatusFilters] = useState<IMenuItem[]>([])

  const statusCounts = useMemo(
    () => calculateStatusCounts(workerShifts),
    [workerShifts],
  )
  const statusOptions = useMemo(
    () => constructStatusOptions(statusCounts),
    [statusCounts],
  )

  if (isLoadingShiftNotifications) {
    if (!setIsLoadingShiftNotifications) {
      return <CircularProgress />
    }
    // Return early here since we're displaying the circular progress in a higher component
    return
  }

  const setSelectedNotification = (selectedNotificationId: string) => {
    setSelectedNotificationId(selectedNotificationId)
    setSelectedWorkerIds([])
  }

  if (!sortedNotifications.length) {
    onClose()
    return null
  }

  const isWorkerMapExpanded = expandedSection === ExpandedSection.WORKER_MAP
  const isWorkersListExpanded = expandedSection === ExpandedSection.WORKERS_LIST
  const isWaitlistExpanded = expandedSection === ExpandedSection.WAITLIST

  const showWorkerMap =
    !!selectedNotification?.data?.relevantWorkerShifts?.length

  return (
    <div
      style={{
        background: `linear-gradient(to right, ${theme.colors.Violet20}, ${theme.colors.TrabaBlue})`,
        paddingBottom: theme.space.xs,
        width: '100%',
      }}
    >
      <Row fullWidth alignCenter justifyBetween px={theme.space.xs}>
        {isLoadingShift || isLoadingWorkerShifts ? (
          <CircularProgress size={theme.space.sm} />
        ) : (
          !!shift && (
            <Row justifyCenter>
              <Text
                variant="body1"
                style={{ marginLeft: 10, color: theme.colors.Grey90 }}
              >
                {'Copy Shift Info'}
              </Text>
              <CopyTextIcon
                textToCopy={getShiftInfoString(shift, workerShifts)}
                iconColor={theme.colors.Grey90}
              />
            </Row>
          )
        )}
        <Row alignCenter pr={theme.space.xs}>
          <Button
            variant={ButtonVariant.TEXT}
            leftIcon={<VolumeCross color="white" />}
            textColor={'white'}
            textHoverColor={theme.colors.MidnightBlue}
            onClick={() => {
              updateShiftNotificationSettings(
                NotificationSettingsLevel.INTERNAL_USER,
                !userNotificationSettings?.shiftSilenced,
              )
            }}
          >
            {`${
              userNotificationSettings?.shiftSilenced ? 'Unmute' : 'Mute'
            } for me`}
          </Button>
          {internalUserHasSupervisorPrivileges(internalUser) && (
            <Button
              variant={ButtonVariant.TEXT}
              leftIcon={<VolumeCross color="white" />}
              textColor={'white'}
              textHoverColor={theme.colors.MidnightBlue}
              onClick={() => {
                updateShiftNotificationSettings(
                  NotificationSettingsLevel.SHIFT,
                  !shiftNotificationSettings?.shiftSilenced,
                )
              }}
            >
              {`${
                shiftNotificationSettings?.shiftSilenced ? 'Unmute' : 'Mute'
              } for everyone`}
            </Button>
          )}
          {onClose && (
            <Icon
              name="cancel"
              color="white"
              size={14}
              type="svg"
              onClick={() => onClose()}
            />
          )}
        </Row>
      </Row>
      <Row
        style={{
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
        mx={theme.space.xs}
        px={theme.space.xs}
      >
        <Tabs value={tabIdx} onChange={handleTabChange}>
          <Tab label="Notifications" />
          <Tab label={`Actions Taken (${actionsTaken.length})`} />
          {enableEchoMessagesInSentinel && <Tab label={'Messages Log'} />}
          {enableWorkerShiftTransitNotification && <Tab label="Transit" />}
        </Tabs>
      </Row>
      <TabPanel value={tabIdx} index={0}>
        <Row
          style={{ backgroundColor: theme.colors.White, overflow: 'hidden' }}
          mx={theme.space.xs}
        >
          <Col width="70%">
            <SentinelNotificationsList
              notifications={sortedNotifications}
              selectedNotificationId={selectedNotificationId}
              setSelectedNotificationId={setSelectedNotification}
              shiftNotificationSettings={shiftNotificationSettings}
              userNotificationSettings={userNotificationSettings}
              internalUser={internalUser}
              shiftId={shiftId}
            />
          </Col>
          <Col width="30%">
            {!!sortedNotifications?.length && !!selectedNotification && (
              <div key={selectedNotification?.sentinelNotificationToUser.id}>
                <SentinelActions
                  loading={isLoading}
                  notification={selectedNotification}
                  setSelectedWorkerIds={setSelectedWorkerIds}
                  goToFullLog={() => setTabIdx(2)}
                />
              </div>
            )}
          </Col>
        </Row>

        <Row alignCenter justifyBetween mx={theme.space.xs}>
          <Col>
            <Row
              pb={theme.space.xxs}
              px={theme.space.xs}
              justifyEnd
              alignCenter
              style={{ backgroundColor: theme.colors.White }}
            >
              <Text
                variant="h6"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleExpandSection(ExpandedSection.WORKERS_LIST)
                }
              >
                {isLoadingWorkerShifts
                  ? '...'
                  : `Workers (${workerShifts?.length ?? 0})`}
              </Text>
              {workerShifts.length > 0 && (
                <CollapsibleIcon
                  collapsed={!isWorkersListExpanded}
                  setCollapsed={() =>
                    handleExpandSection(ExpandedSection.WORKERS_LIST)
                  }
                  size={16}
                />
              )}
              <Text
                variant="h6"
                ml={theme.space.sm}
                style={{ cursor: 'pointer' }}
                onClick={() => handleExpandSection(ExpandedSection.WAITLIST)}
              >
                {isLoadingWaitlist
                  ? '...'
                  : `Waitlist (${waitlistWorkers?.length ?? 0})`}
              </Text>
              {waitlistWorkers && waitlistWorkers?.length > 0 && (
                <CollapsibleIcon
                  collapsed={!isWaitlistExpanded}
                  setCollapsed={() =>
                    handleExpandSection(ExpandedSection.WAITLIST)
                  }
                  size={16}
                />
              )}
              {showWorkerMap && (
                <>
                  <Text
                    variant="h6"
                    ml={theme.space.sm}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleExpandSection(ExpandedSection.WORKER_MAP)
                    }
                  >
                    Worker Map
                  </Text>
                  <CollapsibleIcon
                    collapsed={!isWorkerMapExpanded}
                    setCollapsed={() =>
                      handleExpandSection(ExpandedSection.WORKER_MAP)
                    }
                    size={16}
                  />
                </>
              )}
            </Row>

            <Row fullWidth style={{ backgroundColor: theme.colors.White }}>
              {isWorkersListExpanded &&
                !!workerShifts.length &&
                shift &&
                (isLoadingWorkerShifts || isLoadingShift ? (
                  <LoadingSpinner />
                ) : (
                  <Row px={theme.space.xs} justifyCenter fullWidth>
                    <WorkerTable
                      workerShifts={workerShifts}
                      shift={shift}
                      statusOptions={statusOptions}
                      statusCounts={statusCounts}
                      statusFilters={statusFilters}
                      setStatusFilters={setStatusFilters}
                      slim
                    />
                  </Row>
                ))}
              {isWaitlistExpanded &&
                !!waitlistWorkers?.length &&
                shift &&
                (isLoadingWaitlist ||
                isLoadingShift ||
                isLoadingWorkerShifts ? (
                  <LoadingSpinner />
                ) : (
                  <WaitlistTable
                    waitlistWorkers={waitlistWorkers}
                    shift={shift}
                    removeFromWaitlist={removeFromWaitlist}
                  />
                ))}
              {showWorkerMap && isWorkerMapExpanded && (
                <SentinelWorkerMap
                  workerIds={selectedWorkerIds}
                  sentinelNotificationId={selectedNotification?.id}
                />
              )}
            </Row>
          </Col>
        </Row>
      </TabPanel>
      <TabPanel value={tabIdx} index={1}>
        <Row
          style={{ backgroundColor: 'white', overflow: 'hidden' }}
          mx={theme.space.xs}
          py={theme.space.sm}
        >
          <SentinelActionsTakenList
            actionsTaken={actionsTaken}
            timezone={timezone}
          />
        </Row>
      </TabPanel>
      {enableEchoMessagesInSentinel && (
        <TabPanel value={tabIdx} index={2}>
          <Row
            style={{ backgroundColor: 'white', overflow: 'hidden' }}
            mx={theme.space.xs}
            py={theme.space.xxs}
          >
            <WorkerCommunications shiftId={shiftId} embedded />
          </Row>
        </TabPanel>
      )}
      {shift?.shiftId && workerShifts && (
        <TabPanel value={tabIdx} index={3}>
          <Row
            style={{ backgroundColor: 'white', overflow: 'hidden' }}
            mx={theme.space.xs}
            py={theme.space.sm}
          >
            <SentinelWorkerTransitsTable
              workerShifts={workerShifts}
              shiftId={shift.shiftId}
              enableSelection={false}
              isTransitTab={true}
            />
          </Row>
        </TabPanel>
      )}
    </div>
  )
}

const SentinelActionsTakenList = ({
  actionsTaken,
  timezone,
}: {
  actionsTaken: SentinelActionTaken[]
  timezone: string
}) => {
  return (
    <ul>
      {actionsTaken.length ? (
        actionsTaken.map((action: SentinelActionTaken) => (
          <SentinelActionsTakenItem action={action} timezone={timezone} />
        ))
      ) : (
        <Text variant="body1" mx={theme.space.sm}>
          No actions taken
        </Text>
      )}
    </ul>
  )
}

const SentinelActionsTakenItem = ({
  action,
  timezone,
}: {
  action: SentinelActionTaken
  timezone: string
}) => {
  const [isPropertiesCollapsed, setIsPropertiesCollapsed] = useState(true)

  return (
    <li key={`sentinel_action_${action}_${action.createdAt}`}>
      <Col>
        <Row
          fullWidth
          alignCenter
          mx={theme.space.xs}
          gap={theme.space.sm}
          my={theme.space.xxs}
        >
          <DotText />
          <Col width={200}>
            <Text variant="body1">
              {titleForEventActionType(action.actionType)}
            </Text>
          </Col>
          <Col width={300}>
            <Text>
              {formatInTimeZone(
                action.createdAt,
                timezone,
                'hh:mm:ss aa zzz -- MMM dd',
              )}
            </Text>
          </Col>
          <Col>
            <CollapsibleIcon
              collapsed={isPropertiesCollapsed}
              setCollapsed={setIsPropertiesCollapsed}
              size={16}
            />
          </Col>
        </Row>
        <Collapsible collapsed={isPropertiesCollapsed}>
          <Row fullWidth mx={theme.space.lg}>
            {action.properties ? (
              <JSONPretty
                id="json-pretty"
                data={action.properties}
                theme={{
                  key: 'font-weight:bold',
                }}
              />
            ) : (
              <Text>No extra action properties</Text>
            )}
          </Row>
        </Collapsible>
      </Col>
    </li>
  )
}
