import {
  BreakType,
  ForwardFillMax,
  JobStatus,
  ScheduledBreak,
  Shift,
  ShiftPayType,
  ShiftRequest,
  ShiftTag,
  WorkerShiftForOps as WorkerShift,
  ExtendedShift,
  // ScheduleEligibilityCriteria,
} from '@traba/types'
import { getTotalScheduledBreakTime, getTimeUntilShiftOpen } from '@traba/utils'
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns'
import { ONE_DAY_IN_MINUTES, SIX_HOURS_IN_MINUTES, toDate } from './dateUtils'
import { getExpectedWorkerShifts } from './workerShiftUtils'
const ZERO_PERCENT = 0
const TWENTY_FIVE_PERCENT = 0.25
const THIRTY_FIVE_PERCENT = 0.35

export function shouldShowOverbookWarning(shift: Shift) {
  const { slotsFilled, overbookSlotsRequested } = shift
  return !!(overbookSlotsRequested && slotsFilled > overbookSlotsRequested)
}

const tagToEmoji = {
  [ShiftTag.Ignore]: '🚫',
  [ShiftTag.NoBackfill]: '🔒',
  [ShiftTag.VIP]: '⭐️',
  [ShiftTag.National]: '🇺🇸',
  [ShiftTag.Enterprise1]: '🔴',
  [ShiftTag.Enterprise2]: '🟢',
  [ShiftTag.Enterprise3]: '🔵',
  [ShiftTag.Enterprise4]: '🟠',
  [ShiftTag.Enterprise5]: '⚫',
  [ShiftTag.Pod0]: '0️⃣',
  [ShiftTag.Pod1]: '1️⃣',
  [ShiftTag.Pod2]: '2️⃣',
  [ShiftTag.Pod3]: '3️⃣',
  [ShiftTag.Pod4]: '4️⃣',
  [ShiftTag.Pod5]: '5️⃣',
  [ShiftTag.Pod6]: '6️⃣',
  [ShiftTag.Pod7]: '7️⃣',
  [ShiftTag.Pod8]: '8️⃣',
  [ShiftTag.Pod9]: '9️⃣',
  [ShiftTag.Pod10]: '🔟',
  [ShiftTag.Growth]: '🚀',
}

export function getTagEmojiString(
  tags: ShiftTag[],
  showInvitationTag?: boolean,
  showRmsaTag?: boolean,
) {
  const invitationString = showInvitationTag ? '💌' : ''
  const rmsaString = showRmsaTag ? '🗓' : ''
  const emojiTags = tags.map((tag) => tagToEmoji[tag] || '❔')
  if (invitationString.length) {
    emojiTags.push(invitationString)
  }
  if (rmsaString.length) {
    emojiTags.push(rmsaString)
  }
  const emojiString = emojiTags.join(' ')
  return emojiString.length ? emojiString + ' ' : ''
}

export function getShiftInfoString(
  shift: ExtendedShift,
  workerShifts: WorkerShift[],
) {
  const startDate = new Date(shift.startTime)
  const humanStartDate = startDate.toLocaleDateString('en-US', {
    timeZone: shift.timezone,
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
  const humanStartTime = startDate.toLocaleTimeString('en-US', {
    timeZone: shift.timezone,
    timeZoneName: 'short',
  })
  const expectedToWork = getExpectedWorkerShifts(workerShifts)
  const confirmedWS = expectedToWork?.filter((ws) => ws.isShiftConfirmed)
  const shiftInfoString = `
    ${shift.employerName} | ${shift.regionId} | ${shift.shiftRole} ${
      shift.forwardFillMax !== 'NONE' ? `| ${shift.forwardFillMax}` : ''
    }
    Start: ${humanStartDate} ${humanStartTime}
    Slots: ${shift.slotsFilled} / ${shift.slotsRequested} ${
      shift.overbookSlotsRequested ? `/ OB${shift.overbookSlotsRequested}` : ''
    }
    Confirmed: ${confirmedWS?.length} / ${expectedToWork?.length}
    Ops Deeplink: https://${location.hostname}/field-monitor/${shift.id}
    Employment Type: ${shift.shiftEmploymentType?.replaceAll('_', ' ')}
  `

  return shiftInfoString
}

export function getEstimatedGrossPayForWorkerShift(shift: Shift): number {
  switch (shift.payType) {
    case ShiftPayType.HOURLY: {
      const estimatedPaidShiftTimeInMinutes = Math.max(
        calculateEstimatedPaidShiftTime(shift),
        shift.minimumPaidTime || 0,
      )
      const estimatedGrossPayForWorkerShift =
        shift.payRate * (estimatedPaidShiftTimeInMinutes / 60)

      return estimatedGrossPayForWorkerShift
    }
    case ShiftPayType.UNIT:
      if (shift.numberOfUnits === undefined) {
        throw new Error('pay by unit shift has no numberOfUnits')
      }
      return (shift.numberOfUnits * shift.payRate) / shift.slotsRequested
  }
}

export function getEstimatedRevenuePerWorkerShift(shift: Shift): number {
  return getEstimatedGrossPayForWorkerShift(shift) * shift.calculatedMarkup
}

export function getPayTypeLabel(shift: Shift): string {
  switch (shift.payType) {
    case ShiftPayType.HOURLY:
      return 'Hours'
    case ShiftPayType.UNIT:
      return 'Units'
  }
}

// below copied over from Node server: timesheetUtils.ts. Can potentially move some of this to shared repo.
export interface ICalculateEstimatedPaidShiftTime {
  startTime: Date
  endTime: Date
  scheduledBreaks: ScheduledBreak[]
  breakType?: BreakType
}

export function calculateEstimatedPaidShiftTime(
  shiftInfo: ICalculateEstimatedPaidShiftTime,
): number {
  const { startTime, endTime, scheduledBreaks = [], breakType } = shiftInfo

  const totalScheduledBreakTime = getTotalScheduledBreakTime(scheduledBreaks)
  const totalShiftLengthInMinutes = differenceInMinutes(
    new Date(endTime),
    new Date(startTime),
  )

  // If a shifts breaks are paid, breaks should not be deducted to get the total paid time.
  // Otherwise, if they are unpaid or manual unpaid, we should deduct the scheduled breaks for the total estimated paid time.
  const shiftLengthMinusBreaks: number =
    breakType === BreakType.PAID
      ? totalShiftLengthInMinutes
      : totalShiftLengthInMinutes - totalScheduledBreakTime

  return shiftLengthMinusBreaks
}

export function isInvitationShift(shift: Shift) {
  return isInvitedOnlyShift(shift) || isInvitedFirstShift(shift)
}

export function isInvitedOnlyShift(shift: Shift) {
  return shift.forwardFillMax === ForwardFillMax.INVITED_ONLY
}

export function isInvitedFirstShift(shift: Shift) {
  return shift.forwardFillMax === ForwardFillMax.INVITED_FIRST
}

export function shouldShowInvitationTag(shift: Shift) {
  const isNowBeforeOpenTime = isBefore(
    new Date(),
    getTimeUntilShiftOpen(new Date(shift.createdAt), new Date(shift.startTime)),
  )
  return (
    isInvitedOnlyShift(shift) ||
    (isInvitedFirstShift(shift) && isNowBeforeOpenTime)
  )
}

export function shouldShowRmsaTag(shift: Shift) {
  return !!shift.isInRequiredMultiShift
}

export function openTimeForNonInvited(createdAt: Date, startTime: Date) {
  const diffInMinutesFromCreateToStart = differenceInMinutes(
    toDate(startTime),
    toDate(createdAt),
  )

  const invitedFirstGracePeriod =
    diffInMinutesFromCreateToStart <= SIX_HOURS_IN_MINUTES
      ? ZERO_PERCENT
      : diffInMinutesFromCreateToStart <= ONE_DAY_IN_MINUTES
        ? TWENTY_FIVE_PERCENT
        : THIRTY_FIVE_PERCENT

  const shiftOpenTime = addMinutes(
    toDate(createdAt),
    Math.floor(diffInMinutesFromCreateToStart * invitedFirstGracePeriod),
  )

  return shiftOpenTime
}

// This calculation was moved to the front end because of a race condition/differences
// in transaction retry logic between FS and PG on shift acceptance or cancellation.
export function calculateShiftReliabilityAverage(workerShifts?: WorkerShift[]) {
  if (!workerShifts?.length) {
    return null
  }
  const { reliabilitySum, slotsFilled } = workerShifts.reduce(
    (acc, ws) => {
      let { reliabilitySum, slotsFilled } = acc
      if (ws.jobStatus !== JobStatus.Canceled) {
        reliabilitySum += ws.reliability ?? 0.66
        slotsFilled++
      }
      return { reliabilitySum, slotsFilled }
    },
    { reliabilitySum: 0, slotsFilled: 0 },
  )

  return slotsFilled ? reliabilitySum / slotsFilled : 0
}

export const getShiftRequestWithShifts = (
  request: ShiftRequest,
  shifts: Shift[],
) => ({
  ...request,
  shifts: shifts.filter(
    (shift) => shift.shiftRequestId === request.shiftRequestId,
  ),
})

export const isShiftIdVirtualized = (shiftId: string) => {
  return shiftId.includes('_')
}
