import { InputAdornment, TextField } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreateLineItemDto, EditLineItemDto, LineItemType } from '@traba/types'
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, DatePicker, Input, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import RadioGroup from 'src/components/base/RadioGroup'
import { getLocalTimezone } from 'src/utils/dateUtils'
import { InvoiceDetailsBodyLineItem } from './InvoiceLineItemsTable'

export type AddOrEditLineItemModalProps = {
  isOpen: boolean
  isLoading: boolean
  lineItem?: InvoiceDetailsBodyLineItem
  handleAdd: (lineItem: CreateLineItemDto) => Promise<void>
  handleEdit: (updates: EditLineItemDto) => Promise<void>
  handleClose: () => void
}

export default function AddOrEditLineItemModal(
  props: AddOrEditLineItemModalProps,
) {
  const creating = !props.lineItem
  const [description, setDescription] = useState<string | undefined>(
    props.lineItem?.stripeDescription,
  )
  // set initial state after initial render
  useEffect(() => {
    setDescription(props.lineItem?.stripeDescription)
  }, [props.lineItem?.stripeDescription])

  const [amountString, setAmountString] = useState<string | undefined>(
    undefined,
  )
  const [lineItemType, setLineItemType] = useState<string>(LineItemType.BONUS)
  const [date, setDate] = useState<Date>(new Date())
  const [shiftId, setShiftId] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const NEGATIVE_CHARGE_ERROR =
    'Negative charge line items are not allowed. By selecting a credit, our system will automatically generate negative values. Please remove the - in amount.'

  const addLineItem = () => {
    if (!description || !amountString) {
      return
    }

    if (amountString.startsWith('-')) {
      setErrorMessage(NEGATIVE_CHARGE_ERROR)
      return
    }

    // Need to do this because of weird floating point math situation
    const [dollars, cents] = amountString.split('.')
    const chargeAmountString = `${dollars}${cents || '00'}`
    const chargeAmountCents = Math.abs(+chargeAmountString)

    props.handleAdd({
      description,
      stripeDescription: description,
      date,
      type: lineItemType as LineItemType,
      shiftId,
      chargeToBusiness: {
        amount:
          lineItemType === LineItemType.BONUS
            ? -chargeAmountCents
            : chargeAmountCents,
        currency: 'USD',
      },
    })

    setErrorMessage(null)
  }

  const hasChanges = description !== props.lineItem?.stripeDescription

  const editLineItem = () => {
    // No changes
    if (!hasChanges || !props.lineItem || !props.lineItem.id) {
      return
    }
    props.handleEdit({
      id: props.lineItem.id,
      stripeDescription: description,
      description,
    })
  }

  const handleClose = () => {
    // Reset state if modal is opened again
    setDescription(props.lineItem?.stripeDescription)
    props.handleClose()
  }

  return (
    <Modal
      title={creating ? 'Add line item' : 'Edit line item'}
      isOpen={props.isOpen}
      handleClose={handleClose}
      size={creating ? MODAL_SIZE.SMALL : MODAL_SIZE.DIALOG}
    >
      <Text variant="h6">Stripe item description</Text>
      <Input
        containerStyle={{
          marginTop: theme.space.xxs,
          marginBottom: theme.space.sm,
        }}
        full
        rows={2}
        type="textarea"
        value={description}
        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
          setDescription(ev.target.value)
        }
      />
      {creating && (
        <>
          <Text variant="h6">Amount</Text>
          <TextField
            margin="dense"
            value={amountString}
            onChange={(ev) => setAmountString(ev.currentTarget.value)}
            inputMode="decimal"
            inputProps={{ step: 'any' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              type: 'number',
            }}
            variant="outlined"
            style={{ width: '200px', marginBottom: theme.space.sm }}
          />
          {errorMessage && (
            <Text variant="error" mb={theme.space.sm}>
              {errorMessage}
            </Text>
          )}
        </>
      )}
      {creating && (
        <>
          <Text variant="h6">Type</Text>
          <RadioGroup
            value={lineItemType}
            onChange={(event) => setLineItemType(event.target.value)}
            style={{ marginBottom: theme.space.sm }}
            options={[
              {
                label: <Text variant="body1">Credit (Traba pays)</Text>,
                value: LineItemType.BONUS,
              },
              {
                label: <Text variant="body1">Debit (Business pays)</Text>,
                value: LineItemType.PENALTY,
              },
            ]}
          />
        </>
      )}
      {creating && (
        <>
          <Text variant="h6" mb={theme.space.xxs}>
            Date
          </Text>
          <DatePicker
            showTimeFieldInPopover={false}
            granularity={'day'}
            setDate={(date) => {
              if (date) {
                setDate(date)
              }
            }}
            isClearable={false}
            inlineLabel={false}
            date={date}
            width={200}
            timezone={getLocalTimezone()}
          />
        </>
      )}
      {creating && (
        <>
          <Text variant="h6" mt={theme.space.sm}>
            Shift ID
          </Text>
          <Input
            containerStyle={{
              marginBottom: theme.space.sm,
              marginTop: theme.space.xxs,
            }}
            width={'200px'}
            value={shiftId}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setShiftId(ev.target.value)
            }
          />
        </>
      )}
      <HorizontalRule
        marginTop={theme.space.sm}
        marginBottom={theme.space.sm}
      />
      <Row justifyEnd>
        <Button
          slim
          variant={ButtonVariant.OUTLINED}
          style={{ width: '100px', marginRight: theme.space.xs }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          slim
          style={{ width: '100px' }}
          onClick={creating ? addLineItem : editLineItem}
          disabled={
            creating ? !description || !amountString || !shiftId : !hasChanges
          }
          loading={props.isLoading}
        >
          {creating ? 'Add' : 'Save'}
        </Button>
      </Row>
    </Modal>
  )
}
