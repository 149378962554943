import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { compact } from 'lodash'
import { useState } from 'react'
import { Autocomplete } from 'src/components/Autocomplete/Autocomplete'
import { Button, Col, Icon, Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { validateEmail, validateUniqueEmails } from 'src/utils/inputUtils'

type BillingContactsModalProps = React.HTMLProps<HTMLElement> & {
  isOpen: boolean
  setShowModal: (value: boolean) => void
  addNewEmails: (emails: string[]) => void
  billingEmails: string[]
  companyId: string
}
export const BillingContactsModal: React.FC<BillingContactsModalProps> = (
  props,
) => {
  const [emailsToAdd, setEmailsToAdd] = useState<string[]>([])

  const { companyUsers } = useCompanyUsers(props.companyId)
  const members = (companyUsers ?? []).filter((users) => !users.role)

  const onClick = () => {
    props.addNewEmails(emailsToAdd)
    props.setShowModal(false)
    setEmailsToAdd([])
  }

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM}
      isOpen={props.isOpen}
      handleClose={() => props.setShowModal(false)}
      style={{ padding: theme.space.sm }}
    >
      <div style={{ width: '100%' }}>
        <Row style={{ marginBottom: theme.space.xs }}>
          <Col style={{ flexGrow: 1 }}>
            <Text variant="h4">Add billing contacts</Text>
          </Col>
          <Col style={{ flexGrow: 0 }}>
            <Icon
              name="cancel"
              style={{ cursor: 'pointer' }}
              onClick={() => props.setShowModal(false)}
            />
          </Col>
        </Row>
        <hr
          style={{
            borderBottom: `1px solid ${theme.colors.Grey20}`,
            marginBottom: theme.space.xs,
          }}
        />
        <Row style={{ marginBottom: theme.space.xs }}>
          <Col style={{ width: '100%' }}>
            <Autocomplete
              label="Email addresses"
              value={emailsToAdd}
              options={compact(
                members
                  .filter(
                    // Only show additional contact users (not users with role accounts) and emails which are not already added as billing contacts
                    (m) =>
                      !m.role && !props.billingEmails.includes(m.email || ''),
                  )
                  .map((m) => m.email),
              )}
              onChangeValues={(_, value) => {
                setEmailsToAdd(validateUniqueEmails(value))
              }}
              errorMessage="Email address is invalid"
              validateInput={validateEmail}
            />
          </Col>
        </Row>
        <Row justifyEnd>
          <Button onClick={onClick} disabled={emailsToAdd.length === 0}>
            Add
          </Button>
        </Row>
      </div>
    </Modal>
  )
}
