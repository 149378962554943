import { Checkbox, ListItem } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShift } from '@traba/types'
import { format } from 'date-fns-tz'
import { useCallback } from 'react'
import { Col, Row } from 'src/components/base'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import * as S from '../../styles'
import { SelectAllControlProps } from './ShiftRequestCard'

type setWorkerShiftsToCancel = React.Dispatch<React.SetStateAction<Set<string>>>

interface WorkerShiftRequestCardProps {
  shiftRequestId: string
  workerShifts: WorkerShift[]
  selectedShifts: Set<string>
  setSelectedShifts: setWorkerShiftsToCancel
}

const SelectAllWorkerShiftsControl = ({
  isSelected,
  onSelectAll,
  disabled,
  text,
}: SelectAllControlProps) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      onClick={() => (disabled ? undefined : onSelectAll())}
    >
      <Checkbox checked={isSelected} disabled={disabled} />
      <Text variant="body1" style={{ fontWeight: 500 }}>
        {text}
      </Text>
    </ListItem>
  )
}

interface WorkerShiftListProps {
  shifts: WorkerShift[]
  selectedShifts: Set<string>
  onShiftToggle: (shiftId: string) => void
  handleSelectAllToggle: () => void
  disabledSelectAll: boolean
}

const WorkerShiftList = ({
  shifts,
  selectedShifts,
  onShiftToggle,
  handleSelectAllToggle,
  disabledSelectAll,
}: WorkerShiftListProps) => {
  const tz = useTimezonedDates(shifts[0].shiftInfo.timezone)

  return (
    <>
      <SelectAllWorkerShiftsControl
        isSelected={!disabledSelectAll && selectedShifts.size === shifts.length}
        onSelectAll={handleSelectAllToggle}
        disabled={disabledSelectAll}
        text="Select all future worker shifts in this shift request"
      />
      <Col pl={theme.space.xs}>
        <ul>
          {shifts.map((shift) => {
            const isSelected = selectedShifts.has(shift.shiftId)

            const handleClick = () => onShiftToggle(shift.shiftId)

            return (
              <Row key={shift.id} justifyStart alignCenter pr={theme.space.xs}>
                <Checkbox checked={isSelected} onClick={handleClick} />
                <S.StyledListItem
                  key={shift.shiftId}
                  disableGutters
                  disablePadding
                  sx={{
                    backgroundColor: isSelected
                      ? theme.colors.Grey20
                      : 'transparent',
                  }}
                >
                  <Text variant="body1" style={{ fontWeight: 500 }}>
                    {`${format(new Date(shift.shiftInfo.startTime), 'E')} - ${tz.getDate(shift.shiftInfo.startTime, false)} ${tz.getShiftTime(
                      shift.shiftInfo.startTime,
                      shift.shiftInfo.endTime,
                    )}`}
                  </Text>
                  <Text ml={theme.space.xxs}> (shiftId: {shift.shiftId}) </Text>
                </S.StyledListItem>
              </Row>
            )
          })}
        </ul>
      </Col>
    </>
  )
}

export const WorkerShiftRequestCard = ({
  shiftRequestId,
  workerShifts,
  selectedShifts,
  setSelectedShifts,
}: WorkerShiftRequestCardProps) => {
  const handleShiftCheckboxToggle = (shiftId: string): void => {
    if (selectedShifts.has(shiftId)) {
      selectedShifts.delete(shiftId)
    } else {
      selectedShifts.add(shiftId)
    }
    setSelectedShifts(new Set(selectedShifts))
  }

  const handleSelectAllToggle = useCallback(() => {
    if (selectedShifts.size === workerShifts.length) {
      setSelectedShifts(new Set())
    } else {
      setSelectedShifts(new Set(workerShifts.map((ws) => ws.shiftId)))
    }
  }, [workerShifts, setSelectedShifts, selectedShifts])

  // Check if there is no shift to select
  const disabledSelectAll = workerShifts.length === 0

  const rowStyle = {
    backgroundColor: !workerShifts?.length
      ? theme.colors.Grey10
      : theme.colors.Green10,
  }

  return (
    <Row my={theme.space.xxs} fullWidth>
      <S.CardWrapper>
        <Row
          justifyBetween
          alignCenter
          fullWidth
          px={theme.space.xs}
          py={theme.space.xxxs}
          style={rowStyle}
        >
          <Text variant="h6">Shift request: {shiftRequestId}</Text>
        </Row>

        {!!workerShifts?.length && (
          <Row pl={theme.space.xs} py={theme.space.xxs} flexCol>
            <WorkerShiftList
              shifts={workerShifts}
              selectedShifts={selectedShifts}
              onShiftToggle={handleShiftCheckboxToggle}
              handleSelectAllToggle={handleSelectAllToggle}
              disabledSelectAll={disabledSelectAll}
            />
          </Row>
        )}

        {!workerShifts?.length && (
          <Text variant="body1" px={theme.space.xs} py={theme.space.xs}>
            This shift request has no upcoming shifts
          </Text>
        )}
      </S.CardWrapper>
    </Row>
  )
}
