import { trabaApi } from '@traba/api-utils'
import { OUTSIDE_REGION_ID } from '@traba/consts'
import { useAlert } from '@traba/context'
import { BackgroundCheckFlow, OperationType, Region } from '@traba/types'
import { TierLevel } from '@traba/types'
import { AxiosError, AxiosResponse } from 'axios'
import { compact } from 'lodash'
import pLimit from 'p-limit'
import { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { IMenuItem } from '../components/base/Select/Select'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'
export const bgcFlowTypeItems: IMenuItem[] = [
  {
    label: 'Early',
    value: BackgroundCheckFlow.early,
  },
  {
    label: 'Late',
    value: BackgroundCheckFlow.late,
  },
]
export const workerTierItems: IMenuItem[] = [
  {
    label: 'Unproven',
    value: TierLevel.UNPROVEN,
  },
  {
    label: 'Tier One',
    value: TierLevel.TIER_ONE,
  },
  {
    label: 'Tier Two',
    value: TierLevel.TIER_TWO,
  },
  {
    label: 'Tier Three',
    value: TierLevel.TIER_THREE,
  },
  {
    label: 'Tier Four',
    value: TierLevel.TIER_FOUR,
  },
  {
    label: 'Tier Five',
    value: TierLevel.TIER_FIVE,
  },
]

export function useRegions() {
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isGettingRegion, setIsGettingRegion] = useState(false)
  const { showError, showSuccess } = useAlert()

  const {
    isLoading,
    isError,
    data: regions,
    error,
    isFetched,
  } = useQuery<Region[] | undefined, Error>('regions', getRegions, {
    staleTime: FIVE_MINUTES_IN_MS,
  })

  async function getRegions(): Promise<Region[] | undefined> {
    try {
      const res = await trabaApi.get(`regions`)
      return res.data
    } catch (error: any) {
      showError('Error getting regions', error.message ?? error)
    }
  }

  async function getRegionByPostalCode(
    postalCode: string,
  ): Promise<Region | undefined> {
    try {
      const res = await trabaApi.get(`regions/postal-code/${postalCode}`)
      return res.data
    } catch (error: any) {
      showError('Error getting region by postal code', error.message ?? error)
    }
  }

  const getRegion = async (regionId: string) => {
    try {
      setIsGettingRegion(true)
      const res = await trabaApi.get(`regions/${regionId}`)
      return res.data
    } catch (error: any) {
      showError('Error getting region', error.message ?? error)
    } finally {
      setIsGettingRegion(false)
    }
  }
  async function updateRegion(regionId: string, data: Partial<Region>) {
    try {
      setIsUpdating(true)
      await trabaApi.patch(`regions/${regionId}`, data)
      showSuccess('Region updated successfully')
    } catch (error: any) {
      showError('Error updating region', error.message ?? error)
    } finally {
      setIsUpdating(false)
    }
  }

  async function updateRegionPostalCodes(
    regionId: string,
    postalCodes: string[],
    operationType: OperationType,
    shouldNotifyWorkers: boolean,
  ) {
    try {
      setIsUpdating(true)
      await trabaApi.patch(`regions/postal-code/${regionId}`, {
        postalCodes,
        operationType,
        shouldNotifyWorkers,
      })
      showSuccess('Region postal codes updated successfully')
    } catch (error: any) {
      showError('Error updating region postal codes', error.message ?? error)
    } finally {
      setIsUpdating(false)
    }
  }

  async function createRegion(
    data: Omit<Region, 'regionCode'>,
    createReferralIncentive: boolean,
  ) {
    try {
      setIsCreating(true)
      await trabaApi.post('regions', { ...data, createReferralIncentive })
      showSuccess('Region created successfully')
    } catch (error: any) {
      showError('Error creating region', error.message ?? error)
    } finally {
      setIsCreating(false)
    }
  }

  const acceptingSignupsMutation = useMutation<
    AxiosResponse<any, any>,
    AxiosError,
    { regionId: string; acceptingSignups: boolean }
  >(
    ({ regionId, acceptingSignups }) =>
      trabaApi.patch(`/regions/${regionId}/acceptingSignups`, {
        acceptingSignups,
      }),
    {
      onSuccess: (_response, { acceptingSignups, regionId }) => {
        showSuccess(
          `${acceptingSignups ? 'Enabled' : 'Disabled'} worker signups for region ${regionId}`,
        )
      },
      onError(error, variables) {
        showError(
          `Error updating accepting signups for region ${variables.regionId}`,
          error.message ?? error,
        )
      },
    },
  )

  const findPostalCodesInOtherRegions = async (
    postalCodes: string[],
  ): Promise<{ regionId: string; postalCode: string }[]> => {
    const limit = pLimit(10)
    const result = compact(
      await Promise.all(
        postalCodes.map(async (p) =>
          limit(async () => {
            const regionWithCode = await getRegionByPostalCode(p)

            if (!regionWithCode) {
              return
            }
            return {
              postalCode: p,
              regionId: regionWithCode.regionId,
            }
          }),
        ),
      ),
    )
    return result
  }

  return {
    isLoading: isLoading || regions?.length === 0,
    isError,
    regions,
    error,
    isFetched,
    isUpdating,
    updateRegion,
    getRegion,
    isGettingRegion,
    getRegionByPostalCode,
    updateRegionPostalCodes,
    createRegion,
    isCreating,
    updateRegionAcceptingSignups: acceptingSignupsMutation.mutateAsync,
    findPostalCodesInOtherRegions,
  }
}

export function useValidRegionsMap() {
  const { isLoading, regions = [] } = useRegions()

  const regionMap = useMemo(() => {
    // Don't show outside region
    const validRegions = (regions || []).filter(
      (region) => region.regionId !== OUTSIDE_REGION_ID,
    )
    return validRegions.reduce<Record<string, Region>>((acc, region) => {
      acc[region.regionId] = region
      return acc
    }, {})
  }, [regions])

  return { isLoading, regionMap }
}
