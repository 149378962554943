import styled from 'styled-components'

export const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.colors.White};
`
export const SelectedRadioButton = styled.div<{ size?: number }>`
  border-radius: 50%;
  width: ${({ size }) => size || 30}px;
  height: ${({ size }) => size || 30}px;
  background: ${({ theme }) => theme.colors.Violet};
  &:hover {
    background: ${({ theme }) => theme.colors.Violet70};
  }
  display: flex;
  justify-self: flex-end;
`

export const NotSelectedRadioButton = styled.div<{
  disabled?: boolean
  size?: number
}>`
  border-radius: 50%;
  width: ${({ size }) => size || 30}px;
  height: ${({ size }) => size || 30}px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.Grey20 : theme.colors.White};
  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.Grey20 : theme.colors.Grey10};
  }
  display: flex;
  justify-self: flex-end;
`

export const RadioButtonInside = styled.div<{ size?: number }>`
  border-radius: 50%;
  width: ${({ size }) => size || 10}px;
  height: ${({ size }) => size || 10}px;
  background: ${({ theme }) => theme.colors.White};
  margin: auto;
`
