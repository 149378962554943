export default function Filter({
  color = '#7A8A99',
  size = 10,
  ...props
}: {
  size: number
  color: string
  strokeWidth: number
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.65752 1H3.2157C1.59022 1 0.643667 2.8362 1.58662 4.16022L3.34203 6.62501C3.82456 7.30254 4.08387 8.11365 4.08387 8.94544V11.2195C4.08387 12.2029 4.88101 13 5.86434 13C6.84767 13 7.64482 12.2029 7.64482 11.2195V9.00389C7.64482 8.13604 7.92734 7.29178 8.44967 6.59873L10.2547 4.20375C11.2479 2.88587 10.3078 1 8.65752 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
