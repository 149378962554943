import { datadogRum } from '@datadog/browser-rum'
import { useReducer, createContext, useContext } from 'react'
import { UserState, UserAction, UserDispatch, UserReducer } from './types'

const initialState: UserState = {
  userProfile: null,
  isLoadingUser: true,
  isUserLoggedIn: false,
}

function userReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'EDIT_USER':
      return {
        ...state,
        isLoadingUser: false,
        userProfile: { ...state.userProfile, ...action.value },
      }
    case 'USER_LOGIN':
      datadogRum.setGlobalContextProperty('internalUser', {
        userEmail: state.userProfile?.email,
      })
      return {
        ...state,
        isUserLoggedIn: true,
      }
    case 'USER_LOGOUT':
      return {
        ...state,
        isLoadingUser: false,
        isUserLoggedIn: false,
        userProfile: null,
      }
    default:
      throw new Error()
  }
}

const UserContext = createContext<{
  state: UserState
  dispatch: UserDispatch
}>({
  state: initialState,
  dispatch: () => null,
})

export function useUserContext() {
  return useContext(UserContext)
}

export default function UserContextProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [state, dispatch] = useReducer<UserReducer>(userReducer, initialState)

  const exportedContext = {
    state,
    dispatch,
  }

  return (
    <UserContext.Provider value={exportedContext}>
      {children}
    </UserContext.Provider>
  )
}
