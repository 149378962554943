import {
  NavigationControl,
  Map as ReactMapGl,
  MapProps as ReactMapGlProps,
} from 'react-map-gl'

export type MapState = {
  latitude: number
  longitude: number
}

type DraggableMapProps = {
  mapState: MapState
  width: number | string
  height: number | string
  setMapState: (mapState: MapState) => void
} & ReactMapGlProps

const MAP_ZOOM = 14.5

export const DraggableMap = (props: DraggableMapProps) => {
  const { mapState, setMapState, width, height, ...rest } = props

  return (
    <ReactMapGl
      mapboxAccessToken={`${import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}`}
      attributionControl={false}
      {...mapState}
      initialViewState={{ zoom: MAP_ZOOM }}
      style={{ width: width, height: height }}
      onMove={(event) => setMapState(event.viewState)}
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      {...rest}
    >
      {props.children}
      <NavigationControl position="top-left" showCompass={false} />
    </ReactMapGl>
  )
}
