import { InternalUser } from './internal-users'
import { Shift } from './shifts'

export enum ShiftAssignmentStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
}

export enum AssigneeStatus {
  Assigned = 'ASSIGNED',
  UnAssigned = 'UNASSIGNED',
}

export type ShiftAssignmentResponse = {
  id: string
  createdAt: Date
  updatedAt: Date
  status: ShiftAssignmentStatus
  shiftId: string
  completedAt: Date | null
  assignees: ShiftAssigneeResponse[]
  shift?: Shift
}

export type ShiftAssigneeResponse = {
  id: string
  assignedBy: string
  assignedAt: Date
  updatedAt: Date
  assigneeStatus: AssigneeStatus
  shiftAssignmentId: string
  internalUserId: string
  internalUser: InternalUser
}
