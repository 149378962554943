import CircularProgress from '@mui/material/CircularProgress'
import ToggleButton from '@mui/material/ToggleButton/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType, FormatLineItemType } from '@traba/types'
import { AxiosError } from 'axios'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Pagination from 'src/components/base/Pagination/Pagination'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import useInvoiceCreation from 'src/hooks/useInvoiceCreation'
import { useBasicPagination } from 'src/hooks/usePagination'
import InvoiceDetailsBody from 'src/screens/BillingScreen/components/InvoiceDetailsBody'

type InvoiceCreatorPreviewProps = {
  goBackToShiftSelection: () => void
  companyId: string
  shiftIdMap: Map<string, string[]>
  validateInvoiceGroupIds?: string[]
  shiftEmploymentType: EmploymentType
}

const PAGE_SIZE = 1

export default function InvoiceCreatorPreview(
  props: InvoiceCreatorPreviewProps,
) {
  const {
    goBackToShiftSelection,
    companyId,
    shiftIdMap,
    validateInvoiceGroupIds,
    shiftEmploymentType,
  } = props

  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination(PAGE_SIZE)

  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)

  const currentInvoiceGroupId = validateInvoiceGroupIds
    ? validateInvoiceGroupIds[currentPage]
    : undefined

  const currentInvoiceShifts = currentInvoiceGroupId
    ? shiftIdMap.get(currentInvoiceGroupId)
    : shiftIdMap.get('ungrouped')

  // Reset page when query changes
  useEffect(() => {
    setCurrentPage(0)
  }, [validateInvoiceGroupIds, setCurrentPage])

  const navigate = useNavigate()

  const { showSuccess, showError } = useAlert()

  const [selectorBreakdownType, setSelectorBreakdownType] = useState<
    'shift' | 'worker'
  >('shift')

  const {
    lineItemsPreview,
    isLoading: isLoadingLineItemsPreview,
    error,
    createInvoiceDraft,
  } = useInvoiceCreation({
    companyId,
    shiftIds: currentInvoiceShifts || [],
    validateInvoiceGroupId: currentInvoiceGroupId,
    formatByType:
      selectorBreakdownType === 'shift'
        ? FormatLineItemType.SHIFT
        : FormatLineItemType.WORKER_SHIFT,
    employmentType: shiftEmploymentType,
  })

  const handleCreateDraft = async () => {
    setIsCreatingInvoice(true)

    await createInvoiceDraft(undefined, {
      onSuccess: () => {
        // Invoice creation happens async so we wait a couple
        // seconds before redirecting -- some large invoices still could take longer though
        setTimeout(() => {
          setIsCreatingInvoice(false)
          showSuccess(
            "Invoice creation in progress, please refresh the invoice browser in a few minutes if it's not ready yet.",
          )

          // Remove the invoiceGroupId we created an invoice for and update currentPage
          if (validateInvoiceGroupIds) {
            validateInvoiceGroupIds.splice(currentPage, 1)
            setCurrentPage((prevIndex) => Math.max(prevIndex - 1, 0))
          }

          // We should only navigate away to the billing/company page after we finished creating all draft invoices
          if (
            !validateInvoiceGroupIds ||
            validateInvoiceGroupIds?.length === 0
          ) {
            navigate(`/billing?company=${companyId}`)
          }
        }, 3000)
      },
      onError: (error: AxiosError) => {
        console.error('Error creating invoice', error)
        showError(`Error creating invoice: ${error.message}`)
        setIsCreatingInvoice(false)
      },
    })
  }

  const InvoicePreviewContainer = (props: { children: ReactNode }) => {
    return (
      <Row style={{ alignItems: 'stretch' }}>
        <Col
          style={{
            flex: 0,
            minWidth: '25em',
            padding: theme.space.med,
            alignItems: 'stretch',
            justifyContent: 'stretch',
            backgroundColor: theme.colors.Grey10,
            borderRadius: theme.space.xxs,
          }}
        >
          <Text variant="h4" style={{ marginBottom: theme.space.xs }}>
            Group line items by
          </Text>

          <Row>
            <ToggleButtonGroup fullWidth>
              <ToggleButton
                value="shift"
                selected={selectorBreakdownType === 'shift'}
                onClick={() => setSelectorBreakdownType('shift')}
              >
                Shift
              </ToggleButton>
              <ToggleButton
                value="worker"
                selected={selectorBreakdownType === 'worker'}
                onClick={() => setSelectorBreakdownType('worker')}
              >
                Worker Shift
              </ToggleButton>
            </ToggleButtonGroup>
          </Row>
        </Col>
        <Col
          style={{
            flex: 1,
            display: 'flex',
            paddingLeft: theme.space.med,
            paddingRight: theme.space.med,
            width: '100%',
          }}
        >
          {props.children}
        </Col>
      </Row>
    )
  }

  if (isLoadingLineItemsPreview) {
    return (
      <InvoicePreviewContainer>
        <CircularProgress size={20} />
      </InvoicePreviewContainer>
    )
  }

  if (error) {
    return (
      <InvoicePreviewContainer>
        <InlineBanner
          text={`Error fetching preview: ${error.message}`}
          severity="error"
        />
      </InvoicePreviewContainer>
    )
  }

  if (!lineItemsPreview) {
    return (
      <InvoicePreviewContainer>
        <InlineBanner text={`Error finding line items`} severity="error" />
      </InvoicePreviewContainer>
    )
  }

  return (
    <InvoicePreviewContainer>
      {/* Only Paginate For Invoice Groups */}
      {validateInvoiceGroupIds && (
        <Row justifyBetween>
          <Text variant="h3">
            Invoice {validateInvoiceGroupIds[currentPage]}
          </Text>
          <Pagination
            page={currentPage}
            pageSize={1}
            onPageLeft={goToPreviousPage}
            onPageRight={goToNextPage}
            dataSize={validateInvoiceGroupIds.length}
            totalFound={validateInvoiceGroupIds.length}
            showItemRange={false}
            style={{ fontSize: 'x-large' }}
          />
        </Row>
      )}

      <Row fullWidth>
        <InvoiceDetailsBody
          lineItemsEditable={false}
          companyId={companyId}
          stripeMemo={lineItemsPreview.stripeInvoiceMemo}
          // Current fix while we use stripe description
          lineItems={lineItemsPreview.lineItems}
          totalCharge={{
            amount: lineItemsPreview.lineItems.reduce(
              (total, item) => total + item.chargeToBusiness.amount,
              0,
            ),
            currency: lineItemsPreview.lineItems[0]?.chargeToBusiness.currency,
          }}
        />
      </Row>
      <Row gap={theme.space.xs}>
        <Col>
          <Button
            onClick={goBackToShiftSelection}
            variant={ButtonVariant.OUTLINED}
            full
          >
            Back to Shift Selection
          </Button>
        </Col>
        <Col>
          <Button onClick={handleCreateDraft} full>
            {isCreatingInvoice ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              'Create Draft Invoice'
            )}
          </Button>
        </Col>
      </Row>
    </InvoicePreviewContainer>
  )
}
