import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AlertIcon } from 'src/assets/svg/icons/AlertIcon'
import { Row } from './base'
import { InformationIcon } from './base/Input/Input.styles'

export function InlineMessage({ text }: { text: string }) {
  return (
    <Row alignCenter>
      <InformationIcon>
        <AlertIcon color={theme.colors.Grey50} />
      </InformationIcon>
      <Text variant="body2">{text}</Text>
    </Row>
  )
}
