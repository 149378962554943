import { Text } from '@traba/react-components'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { Row } from '../../components/base'

export default function CompanyWorkerFeedbackScreen() {
  const RETOOL_URL =
    'https://traba.retool.com/apps/Company%20Worker%20Feedback%20Explorer?_embed=true'

  return (
    <MainLayout title="Company Worker Feedback">
      <Row fullWidth alignCenter justifyBetween>
        <Text variant="h4">Company Worker Feedback (Retool)</Text>
      </Row>
      <Row>
        <iframe
          title="Retool Company Worker Feedback"
          src={RETOOL_URL}
          width="100%"
          height="900px"
        />
      </Row>
    </MainLayout>
  )
}
