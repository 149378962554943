import { set } from 'lodash'
import { useState } from 'react'
import { IMenuItem } from 'src/components/base/Select/Select'

export type QueryItem = {
  key: string
  value?: any
}

export enum QueryBuilderItemType {
  Date = 'date',
  DateRange = 'dateRange',
  Select = 'select',
  SearchSelect = 'searchSelect',
  Boolean = 'boolean',
  Number = 'number',
  TextInput = 'textInput',
}

export type QueryBuilderItem = QueryItem & {
  label: string
  type?: QueryBuilderItemType
  options?: IMenuItem[]
  disabled?: boolean
}

export function convertQueryArrayToObject(query: QueryItem[]) {
  const queryObject: Record<string, any> = {}
  query.forEach((q) => {
    if (q.key) {
      set(queryObject, q.key, q.value)
    }
  })
  return queryObject
}

export function useQueryBuilder(
  defaultQueryValue: QueryItem[] = [{ key: '', value: '' }],
) {
  const [query, setQuery] = useState<QueryItem[]>(defaultQueryValue)

  /**
   * Changes the value prop of a query item object based on its key
   * @param k key
   * @param v value
   */
  const setQueryItemValue = (k: string, v: any) => {
    let updatedQuery
    if (!query.find((queryItem) => queryItem.key === k)) {
      updatedQuery = [
        ...query,
        {
          key: k,
          value: v,
        },
      ]
    } else {
      updatedQuery = query.map((queryItem) => {
        if (queryItem.key === k) {
          return {
            key: k,
            value: v,
          }
        }

        return queryItem
      })
    }

    setQuery(updatedQuery)
  }

  /**
   * Changes the key prop from a query item object based on its index
   * @param i index
   * @param k key
   */
  const setQueryItemKey = (i: number, k: string) => {
    const updatedQuery = [...query]
    updatedQuery[i] = {
      ...query[i],
      key: k,
      value: null,
    }
    setQuery(updatedQuery)
  }

  /**
   * Removes query item based on its key
   * @param k key of item to be deleted
   */
  const removeQueryItem = (k: string) => {
    const updatedQuery = query.filter((q) => q.key !== k)
    setQuery(updatedQuery)
  }

  return {
    query,
    queryObject: convertQueryArrayToObject(query),
    setQuery,
    setQueryItemValue,
    setQueryItemKey,
    removeQueryItem,
  }
}
