import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyNote, NoteType } from '@traba/types'
import { Col, Row } from 'src/components/base'
import useNotes from 'src/hooks/useNotes'
import { sortByProp } from 'src/utils/helperUtils'
import NoteDrawer from './NoteDrawer/NoteDrawer'
import { NotePlaceholder } from './NotePlaceholder'
import { PostedNote } from './PostedNote'

interface CompanyNoteDrawerProps {
  companyId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideFAB?: boolean
  noCloseButton?: boolean
  noteCount?: number
}

export const CompanyNoteDrawer = (props: CompanyNoteDrawerProps) => {
  const { companyId, isOpen, setIsOpen, hideFAB, noCloseButton, noteCount } =
    props
  const { useCompanyNotes } = useNotes()
  const { companyNotes = [], isLoading } = useCompanyNotes(companyId)
  companyNotes.sort(sortByProp('createdAt', 'DESC'))
  const createNoteParams = { id: companyId, noteType: NoteType.Company }
  return (
    <NoteDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      createNoteParameters={createNoteParams}
      title="Company Notes"
      hideFAB={hideFAB}
      noCloseButton={noCloseButton}
      noteCount={noteCount || 0}
      companyNotes={companyNotes.length}
    >
      {companyNotes.length > 0 ? (
        <Col>
          <Row alignCenter mb={theme.space.xs}>
            <Text variant="h4">Past Notes ({companyNotes.length})</Text>
          </Row>
          {companyNotes.map((companyNote: CompanyNote) => {
            return (
              <PostedNote
                note={companyNote}
                noteType={NoteType.Company}
                key={companyNote.noteId}
              />
            )
          })}
        </Col>
      ) : isLoading ? (
        <CircularProgress size={theme.space.med} />
      ) : (
        <NotePlaceholder subtitle="Company Notes Will Show Up Here" />
      )}
    </NoteDrawer>
  )
}
