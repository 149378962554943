import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useMemo, useState } from 'react'
import { useWorkerCommunicationsForShiftId } from '../../hooks/useWorkerCommunications'
import {
  MessageSource,
  WorkerCommunication,
  WorkerInfoForCommunication,
} from '../../types/worker-communications'
import { formatTimeDistanceToNow } from '../../utils/dateUtils'
import { openInNewTab } from '../../utils/helperUtils'
import { truncateString } from '../../utils/stringUtils'
import { Avatar, CopyTextIcon, Row } from '../base'
import { WorkerCommunicationsWorkerMessages } from './WorkerCommunicationsWorkerMessages'

interface WorkerCommunicationsProps {
  shiftId: string
  embedded?: boolean
  expanded?: boolean
  filters?: { [key: string]: boolean }
}

interface WorkerCommunicationsWorkerInfoRowProps {
  wc: WorkerCommunication & WorkerInfoForCommunication
  isSelected: boolean
  handleSelect: (workerId?: string) => void
  workerPhoneNumber: string
}

const WorkerCommunicationsWorkerInfoRow = ({
  wc,
  isSelected,
  handleSelect,
  workerPhoneNumber,
}: WorkerCommunicationsWorkerInfoRowProps) => {
  return (
    <Row
      key={wc.id}
      py={theme.space.xxs}
      px={theme.space.xxs}
      style={{
        height: 68,
        cursor: 'pointer',
        border: `1px solid ${
          isSelected ? theme.colors.Violet70 : theme.colors.Grey30
        }`,
        ...(isSelected && {
          borderLeft: `4px solid ${theme.colors.Violet70}`,
          boxShadow: `0px 1px 0px 0px ${theme.colors.Violet20}`,
          backgroundColor: theme.colors.Violet10,
        }),
      }}
      alignCenter
      justifyBetween
      onClick={() => handleSelect(wc.workerId)}
      fullWidth
    >
      <Row alignCenter fullWidth>
        <Avatar
          round
          size={50}
          src={wc.photoUrl}
          name={wc.firstName}
          onClick={() => openInNewTab(`/workers/${wc.workerId}`)}
        />
        <Row flexCol ml={theme.space.xxs} fullWidth>
          <Row fullWidth justifyBetween pr={theme.space.xs}>
            {wc.firstName ? (
              <Text variant="h6">
                {wc.firstName} {wc.lastName}
              </Text>
            ) : (
              <Text>Unknown Worker</Text>
            )}
            <Text>
              {workerPhoneNumber}{' '}
              <CopyTextIcon textToCopy={workerPhoneNumber} />
            </Text>
          </Row>
          <Text>{truncateString(wc.body || '', 60)}</Text>
        </Row>
      </Row>
      <Text variant="label" style={{ width: 120 }}>
        {formatTimeDistanceToNow(new Date(wc.createdAt))}
      </Text>
    </Row>
  )
}

export function WorkerCommunications({
  shiftId,
  embedded,
  expanded,
  filters,
}: WorkerCommunicationsProps) {
  const { isLoading, workerCommunications, refetch } =
    useWorkerCommunicationsForShiftId(shiftId)
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>()

  const workerIds = useMemo(() => {
    if (workerCommunications) {
      return workerCommunications.map((wc) => wc.workerId)
    }
    return []
  }, [workerCommunications])

  useEffect(() => {
    if (workerIds && workerIds.length > 0) {
      setSelectedWorkerId(workerIds[0])
    }
    window.analytics.track('Worker Communications Viewed', {
      shiftId,
      workerIds,
    })
  }, [shiftId, workerIds])

  if (isLoading) {
    return (
      <Row
        pt={theme.space.xs}
        px={theme.space.xs}
        alignCenter
        justifyCenter
        style={{
          height: 300,
        }}
      >
        <LoadingSpinner />
      </Row>
    )
  }

  return (
    <Row flexCol fullWidth>
      <Row fullWidth>
        {!workerCommunications ||
          (workerCommunications.length === 0 && (
            <Row
              pt={theme.space.xs}
              px={theme.space.xs}
              alignCenter
              justifyCenter
            >
              <Text>
                No communications found for this shift, please try again later
              </Text>
            </Row>
          ))}
        <Row
          flexCol
          fullWidth
          style={
            embedded
              ? {
                  overflowY: 'auto',
                  height: expanded ? '60vh' : 550,
                }
              : {}
          }
        >
          {workerCommunications &&
            workerCommunications.map((wc) => {
              const selected = wc.workerId === selectedWorkerId
              const workerPhoneNumber =
                wc.messageSource === MessageSource.WORKER
                  ? wc.fromNumber
                  : wc.toNumber
              return (
                <WorkerCommunicationsWorkerInfoRow
                  key={`$worker-communications-${wc.id}`}
                  wc={wc}
                  isSelected={selected}
                  handleSelect={setSelectedWorkerId}
                  workerPhoneNumber={workerPhoneNumber}
                />
              )
            })}
        </Row>
        {workerCommunications &&
          workerCommunications.length > 0 &&
          workerCommunications[0].workerId && (
            <WorkerCommunicationsWorkerMessages
              workerId={selectedWorkerId || workerCommunications[0].workerId}
              embedded={embedded}
              expanded={expanded}
              filters={filters}
            />
          )}
      </Row>
      <Row fullWidth justifyEnd py={theme.space.xxs} px={theme.space.xxs}>
        <Button onClick={() => refetch()} variant={ButtonVariant.OUTLINED}>
          Refresh
        </Button>
      </Row>
    </Row>
  )
}
