import { Text } from '@traba/react-components'
import styled from 'styled-components'

export const TooltipHeader = styled(Text).attrs({
  variant: 'h7',
})`
  color: ${({ theme }) => theme.colors.White};
`

export const TooltipText = styled(Text).attrs({
  variant: 'h7',
})`
  color: ${({ theme }) => theme.colors.White};
  font-weight: 300;
`
