import { CardTile } from '@traba/react-components'
import { Td } from 'src/components/base'
import styled from 'styled-components'

export const TransactionIconWrapper = styled(CardTile)`
  border-width: 1px;
  width: 24px;
  height: 24px;
  font-size: 9px;
  padding-bottom: 2px;
  margin-right: 8px;
`

export const TransactionTypeTd = styled(Td)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`
