import { Text } from '@traba/react-components'

export const PriceLine = ({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) => {
  return (
    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
      <Text
        variant="caption"
        style={{
          lineHeight: '24px',
          flex: 1,
        }}
      >
        {label}
      </Text>
      <Text
        variant="h7"
        style={{
          marginLeft: '8px',
        }}
      >
        {children}
      </Text>
    </div>
  )
}
