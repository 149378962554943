import differenceInMinutes from 'date-fns/differenceInMinutes'

export function getEarlyArrivalTimeBufferInMinutes({
  businessStartTime,
  shiftStartTime,
}: {
  businessStartTime?: Date | null
  shiftStartTime: Date | null
}) {
  if (!businessStartTime || !shiftStartTime) {
    return undefined
  }
  return differenceInMinutes(
    new Date(businessStartTime),
    new Date(shiftStartTime),
  )
}
