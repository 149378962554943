import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import DemoAccountsTable from 'src/components/DemoAccount/DemoAccountsTable'
import DemoCreateForm from 'src/components/DemoAccount/DemoCreateForm'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'

function DemoAccountScreen() {
  return (
    <MainLayout title="Create Demo Account">
      <Row justifyBetween mb={theme.space.xxs}>
        <Text variant="h4" mb={theme.space.med}>
          Create a Demo Account
        </Text>
      </Row>
      <Row fullWidth justifyBetween>
        <DemoCreateForm />
        <DemoAccountsTable />
      </Row>
    </MainLayout>
  )
}

export default DemoAccountScreen
