import { Engineering } from '@mui/icons-material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerSearchParams } from '@traba/types'
import { Shift } from '@traba/types'
import React, { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { Button, Icon, Row } from '../../base'
import * as S from '../styles'
import PushInput from './PushInput'
import SMSInput from './SMSInput'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const DEFAULT_MAX_WORKERS = 1500

export const enum bugleType {
  SMS = 'SMS',
  PUSH = ' PUSH',
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ width: '100%', height: '100%' }}
    >
      <S.ActionTabContainer>{value === index && children}</S.ActionTabContainer>
    </div>
  )
}

interface WorkerActionsSectionProps {
  isFirstSectionComplete: boolean
  isSecondSectionComplete: boolean
  isLoading: boolean
  onExport: () => void
  parameters: WorkerSearchParams
  shift: Shift
  paramsSet: string
  totalFound?: number
  deepLink?: string
  presetSavedSearchId?: string
  sentinelNotificationToUserId?: string
}

const WorkerActionsSection: React.FC<WorkerActionsSectionProps> = ({
  isFirstSectionComplete,
  isSecondSectionComplete,
  isLoading,
  onExport,
  parameters,
  shift,
  totalFound,
  deepLink,
  presetSavedSearchId,
  sentinelNotificationToUserId,
}) => {
  const [value, setValue] = useState(0)
  const { hotSettings } = useHotSettings()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const disabled = !isFirstSectionComplete || !isSecondSectionComplete

  if (disabled) {
    return (
      <S.ActionsContainer>
        <Text>Please complete the previous sections</Text>
      </S.ActionsContainer>
    )
  }

  return (
    <S.ActionsContainer>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="SMS" />
        <Tab label="PUSH" />
        <Tab label="Export" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <SMSInput
          parameters={parameters}
          targetShiftId={shift.shiftId}
          targetCompanyId={shift.companyId}
          disabled={disabled}
          totalFound={totalFound}
          deepLink={deepLink}
          savedSearchId={presetSavedSearchId}
          sentinelNotificationToUserId={sentinelNotificationToUserId}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {hotSettings?.enableOpsConsoleBuglePush ? (
          <PushInput
            parameters={parameters}
            targetShiftId={shift.shiftId}
            targetCompanyId={shift.companyId}
            disabled={disabled}
            totalFound={totalFound}
            savedSearchId={presetSavedSearchId}
          />
        ) : (
          <Text variant="label">
            Coming soon <Engineering />
          </Text>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Text variant="label">
          Use this to export the list of workers into a CSV
        </Text>
        <Row fullWidth justifyEnd>
          <Button
            leftIcon={<Icon name="download" />}
            variant={ButtonVariant.OUTLINED}
            loading={isLoading}
            style={{ width: 180 }}
            onClick={onExport}
            mr={theme.space.xs}
            disabled={disabled}
          >
            Export
          </Button>
        </Row>
      </TabPanel>
    </S.ActionsContainer>
  )
}

export default WorkerActionsSection
