import { Radio } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ParentInvoiceGroup } from '@traba/types'
import { InputStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import { Modal, Col, Row, Input, Icon, Button } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { getErrorMessage } from 'src/utils/errorUtils'

type CreateOrEditInvoiceGroupModalProps = {
  companyId: string
  isOpen: boolean
  group?: ParentInvoiceGroup // if editing
  handleClose: (newGroup?: ParentInvoiceGroup) => void
}

export const CreateOrEditInvoiceGroupModal = (
  props: CreateOrEditInvoiceGroupModalProps,
) => {
  const { companyId, group: existingGroup } = props
  const editing = !!existingGroup

  const [name, setName] = useState<string | undefined>(
    existingGroup?.name ?? undefined,
  )
  const [description, setDescription] = useState<string | undefined>(
    existingGroup?.description ?? undefined,
  )
  const [recurring, setRecurring] = useState(existingGroup?.recurring ?? false)

  const [nameTouched, setNameTouched] = useState(false)
  const [upsertingGroup, setUpsertingGroup] = useState(false)

  const { createInvoiceGroup, editInvoiceGroup } = useInvoiceGroups(companyId)
  const { showError } = useAlert()

  function handleCreate() {
    if (!name) {
      return
    }
    setUpsertingGroup(true)
    createInvoiceGroup(
      { companyId: props.companyId, name, description, recurring },
      {
        onSettled: (newGroup?: ParentInvoiceGroup) => {
          setUpsertingGroup(false)
          props.handleClose(newGroup)
        },
        onError: (err: any) => {
          showError(getErrorMessage(err), 'Unable to create invoice group')
        },
      },
    )
  }

  function handleEdit() {
    if (!editing) {
      return
    }
    setUpsertingGroup(true)
    editInvoiceGroup(
      {
        groupId: existingGroup.id,
        updateGroupData: { name, description, recurring },
      },
      {
        onSettled: () => {
          setUpsertingGroup(false)
          props.handleClose()
        },
        onError: (err: any) => {
          showError(getErrorMessage(err), 'Unable to edit invoice group')
        },
      },
    )
  }

  useEffect(() => {
    setName(existingGroup?.name ?? undefined)
  }, [existingGroup])

  return (
    <Modal
      isOpen={props.isOpen}
      size={MODAL_SIZE.MEDIUM}
      handleClose={props.handleClose}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col>
          <Row mb={theme.space.xs}>
            <Col style={{ flexGrow: 1 }}>
              <Text variant="h4">
                {editing ? 'Edit' : 'Create'} invoice group
              </Text>
            </Col>
            <Col style={{ flexGrow: 0 }}>
              <Icon
                name="cancel"
                style={{ cursor: 'pointer' }}
                onClick={props.handleClose}
              />
            </Col>
          </Row>
          <Row mb={theme.space.xs}>
            <div style={{ flex: 1 }}>
              <Input
                label="Group name"
                value={name}
                inputStatus={
                  nameTouched && !name ? InputStatus.error : InputStatus.default
                }
                onChange={(e: any) => setName(e.target.value)}
                onBlur={() => setNameTouched(true)}
                errorMessage={!name ? REQUIRED_FIELD_MESSAGE : undefined}
                full
                containerStyle={{
                  marginTop: theme.space.xs,
                  width: '100%',
                }}
              />
            </div>
          </Row>

          <Row>
            <div style={{ flex: 1 }}>
              <Input
                type="textarea"
                label="Description"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                full
                rows={2}
                containerStyle={{
                  marginTop: theme.space.xs,
                  width: '100%',
                }}
              />
            </div>
          </Row>

          <Row>
            <Text
              variant="h6"
              style={{
                marginTop: theme.space.xs,
                marginBottom: theme.space.xs,
              }}
            >
              Type
            </Text>
          </Row>

          <Row alignCenter style={{ columnGap: theme.space.xl }}>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => setRecurring(false)}
              >
                <Radio checked={!recurring} />
                <Text variant="body1">One-time purchase order</Text>
              </Row>
            </Col>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={() => setRecurring(true)}
              >
                <Radio checked={recurring} />
                <Text variant="body1">Reusable invoice group</Text>
              </Row>
            </Col>
          </Row>
        </Col>

        <Row justifyEnd>
          <Button
            onClick={editing ? handleEdit : handleCreate}
            disabled={!name}
            loading={upsertingGroup}
          >
            {editing ? 'Save' : 'Create'}
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
