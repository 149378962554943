import { theme } from '@traba/theme'
import { ChangeEvent } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Button, Input } from '../../../../components/base'
import * as S from './styles'

interface SearchInputProps {
  searchText: string
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
  handleClear: () => void
  checkedWorkersCount: number
  onBlur?: () => void
  actionButton?: React.ReactNode
  disabled?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchText,
  handleSearch,
  handleClear,
  checkedWorkersCount,
  actionButton,
  disabled,
}) => {
  return (
    <S.InputWrapper>
      <Input
        label={'Search Workers'}
        id="search_worker"
        width="250px"
        containerStyle={{ width: '250px' }}
        value={searchText}
        onChange={handleSearch}
        autoComplete="off"
        onFocus={handleSearch}
        disabled={disabled}
      />
      <Button
        onClick={handleClear}
        ml={theme.space.xxs}
        variant={ButtonVariant.OUTLINED}
        disabled={!searchText && checkedWorkersCount === 0}
      >
        Clear
      </Button>
      {actionButton}
    </S.InputWrapper>
  )
}

export { SearchInput }
