import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BugleBlast,
  BugleBlastStatus,
  BugleStatistics,
  BugleStatisticsType,
  SearchParams,
} from '@traba/types'
import { truncateString } from '@traba/utils'
import { format } from 'date-fns'
import { useState } from 'react'

import { Col, Row } from '../../base'

import BugleStatisticsDistributionBar from '../components/BugleStatisticsDistributionBar'
import BugleStatisticsStatusRow from './BugleStatisticsRowType'

export default function BugleStatisticsRow({
  bugle,
  isExpanded,
  onToggle,
}: {
  bugle: BugleBlast & {
    bugleStatistics: BugleStatistics
    params?: SearchParams
  }
  isExpanded?: boolean
  onToggle?: () => void
}) {
  const [expandedType, setExpandedType] = useState<
    BugleStatisticsType | undefined
  >(undefined)

  const handleToggleType = (type: BugleStatisticsType) => {
    if (expandedType === type) {
      return setExpandedType(undefined)
    }
    setExpandedType(type)
  }

  return (
    <Row
      fullWidth
      style={{
        border: `1px solid ${theme.border.color}`,
        borderRadius: theme.border.radius,
        backgroundColor: isExpanded ? theme.colors.Grey10 : theme.colors.White,
        maxHeight: isExpanded ? 800 : 56,
      }}
      mb={theme.space.xs}
      flexCol
    >
      <Row
        fullWidth
        style={{
          borderBottom: isExpanded ? `1px solid ${theme.border.color}` : 'none',
          cursor: 'pointer',
          height: 56,
        }}
        onClick={onToggle}
      >
        <Row
          style={{ width: '65%' }}
          px={theme.space.xs}
          py={theme.space.xs}
          justifyBetween
        >
          <Text variant="h6" style={{ width: 350 }}>
            {truncateString(bugle.message.body, 50, true)}
          </Text>
          <Row style={{ width: 20 }} mr={theme.space.xs}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </Row>
          <Text>{format(new Date(bugle.createdAt), 'MMM dd hh:mm a zzz')}</Text>
          <Text>{bugle.opsSender}</Text>
        </Row>
        <Row
          style={{ width: '35%', borderLeft: `1px solid ${theme.BorderColor}` }}
          justifyBetween
          px={theme.space.xs}
          py={theme.space.xs}
        >
          <Text
            ml={theme.space.xs}
            variant="h5"
            style={{
              width: 100,
              textAlign: 'start',
            }}
          >
            {bugle.workerCount} sent
          </Text>

          {!isExpanded && bugle.workerCount && (
            <Row style={{ width: '80%' }}>
              <BugleStatisticsDistributionBar
                bugleStatistics={bugle.bugleStatistics}
                totalSent={bugle.workerCount}
              />
            </Row>
          )}
        </Row>
      </Row>
      {isExpanded && bugle.bugleStatistics && bugle.workerCount && (
        <Row
          fullWidth
          px={theme.space.xs}
          py={theme.space.xs}
          style={{ overflowY: 'auto' }}
        >
          <Col>
            <Col mb={theme.space.xxs}>
              <Text>Sent</Text>
              <Text variant="h4">{bugle.bugleStatistics.messagesSent}</Text>
            </Col>
            <Row mb={theme.space.xs}>
              <BugleStatisticsDistributionBar
                bugleStatistics={bugle.bugleStatistics}
                totalSent={bugle.workerCount}
              />
            </Row>
            {Object.values(BugleStatisticsType).map((type) => (
              <BugleStatisticsStatusRow
                key={type}
                bugleStatistics={bugle.bugleStatistics}
                type={type}
                isExpanded={expandedType === type}
                onToggleType={() => handleToggleType(type)}
                shiftId={bugle.shiftId}
                bugleBlastId={bugle.bugleBlastId}
              />
            ))}
          </Col>
        </Row>
      )}
      {isExpanded &&
        (!bugle.bugleStatistics ||
          bugle.status === BugleBlastStatus.IN_PROGRESS) && (
          <Row fullWidth px={theme.space.xs} py={theme.space.xs}>
            <Text variant="h6">Bugle in progress</Text>
          </Row>
        )}
    </Row>
  )
}
