export function ThumbsUp({
  color = '#1AB373',
}: {
  size: number
  color: string
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00941 2.92573L4.14049 7.64522C4.04767 7.87963 4 8.12947 4 8.38158V12C4 13.1046 4.89543 14 6 14H11.5739C12.437 14 13.2027 13.4464 13.4732 12.6268L14.1332 10.6268C14.5602 9.3328 13.5965 8 12.2339 8H10.8369C9.73229 8 8.83686 7.10457 8.83686 6V3.46518C8.83686 2.65599 8.18087 2 7.37167 2C6.77071 2 6.23068 2.36698 6.00941 2.92573Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M2 9V13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
