import { Button, ButtonVariant } from '../Button/Button'
import Row from '../Row'

export const ModalButtons = (props: {
  handleClose: () => void
  handleConfirm: () => Promise<void> | void
  loading?: boolean
  isDisabled?: boolean
  confirmText?: string
  closeText?: string
}) => {
  const {
    handleClose,
    handleConfirm,
    loading,
    confirmText,
    isDisabled,
    closeText,
  } = props
  return (
    <Row
      mt={16}
      fullWidth
      style={{
        justifyContent: 'space-between',
        alignSelf: 'flex-end',
      }}
    >
      <Button
        variant={ButtonVariant.OUTLINED}
        style={{ minWidth: '200px' }}
        onClick={handleClose}
      >
        {closeText || 'Cancel'}
      </Button>
      <Button
        style={{ minWidth: '200px' }}
        onClick={handleConfirm}
        loading={loading}
        disabled={isDisabled}
      >
        {confirmText || 'Confirm'}
      </Button>
    </Row>
  )
}
