import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Trash } from 'src/assets/svg/icons/Trash'
import { Button, Table, Td, Tr } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useBilling } from 'src/hooks/useBilling'

export const BillingContactsTable: React.FC<
  React.HTMLProps<HTMLElement> & {
    companyId: string
    isLoading: boolean
    billingEmails: string[]
  }
> = (props) => {
  const { patchBilling } = useBilling(props.companyId)

  if (props.isLoading) {
    return <CircularProgress size={theme.space.lg} />
  }

  const removeEmail = async (emailToRemove: string) => {
    await patchBilling({
      additionalEmails: props.billingEmails.filter(
        (currentEmail) => currentEmail != emailToRemove,
      ),
    })
  }

  return (
    <>
      <Table headers={['Email', '']}>
        {props.billingEmails.map((email) => (
          <Tr key={email}>
            <div
              style={{
                paddingTop: theme.space.xxs,
                paddingBottom: theme.space.xxs,
              }}
            >
              <Td style={{ width: '100%' }}>{email}</Td>
              <Td>
                <Button
                  style={{ height: 48, width: 48, marginLeft: theme.space.sm }}
                  onClick={async () => {
                    removeEmail(email)
                  }}
                  variant={ButtonVariant.OUTLINED}
                >
                  <Trash size={theme.space.xs} color={theme.colors.Grey50} />
                </Button>
              </Td>
            </div>
          </Tr>
        ))}
      </Table>
      {props.billingEmails.length === 0 && (
        <Text style={{ padding: theme.space.xs }}>No billing contacts</Text>
      )}
    </>
  )
}
