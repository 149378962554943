import { Button, ButtonVariant, Row, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback, useState } from 'react'
import { EarningsSummaryResponse } from 'src/hooks/useEarningsSummary'
import { WorkerShiftEditedTimesTable } from './WorkerShiftEditedTimesTable'

export interface EarningsSummaryItemProps {
  earningSummaryWorkerShift: EarningsSummaryResponse
}

export function WorkerEditedTimeView({
  earningSummaryWorkerShift,
}: EarningsSummaryItemProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggleCollapsed = useCallback(() => {
    setCollapsed((currVal) => !currVal)
  }, [])

  return (
    <>
      <Row fullWidth mt={theme.space.xs} px={theme.space.sm}>
        <Button
          variant={ButtonVariant.BRANDLINK}
          rightIcon={
            <SvgIcon
              color={theme.colors.brand}
              name={collapsed ? 'chevronDown' : 'chevronUp'}
            />
          }
          onClick={toggleCollapsed}
        >
          {`${collapsed ? 'Hide' : 'Show'} worker edited times`}
        </Button>
      </Row>
      {!collapsed && (
        <Row alignCenter justifyCenter fullWidth mt={theme.space.xs}>
          <WorkerShiftEditedTimesTable
            earningSummaryWorkerShift={earningSummaryWorkerShift}
          />
        </Row>
      )}
    </>
  )
}
