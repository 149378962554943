import { BackgroundCheckStatus, PhoneNumberStatus } from './account-status'

export enum WatchlistRequirementFilter {
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  PHONE_NUMBER = 'PHONE_NUMBER',
  STRIPE = 'STRIPE',
}

export enum StripeStatusForWatchlist {
  NO_ACCOUNT = 'NO_ACCOUNT',
  PAYOUTS_DISABLED = 'PAYOUTS_DISABLED',
  PAYOUTS_DISABLED_SOON = 'PAYOUTS_DISABLED_SOON',
  UP_TO_DATE = 'UP_TO_DATE',
}

export enum WatchlistRequirementStatus {
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE',
  ACTION = 'ACTION',
}

export type WorkerDetailsForWatchlist = {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  completedShifts: number
  nextImpactedShiftId?: string
  nextImpactedShiftTime?: Date
  backgroundCheckStatus: BackgroundCheckStatus
  stripeStatus: StripeStatusForWatchlist
  phoneNumberStatus: PhoneNumberStatus
  hasActivePhoneNumberStatusAction: boolean
  hasActiveBackgroundCheckAction: boolean
}

export type WatchlistSearchParams = {
  nextShiftStartTimeBefore: Date
  requirementFilters: WatchlistRequirementFilter[]
  showWorkersWithActiveAction?: boolean
  showWorkersWithPendingAction?: boolean
  workerSearchText?: string
  regions?: string[]
  limit?: number
  offset?: number
}
