import { CircularProgress } from '@mui/material'
import { Modal } from '@traba/react-components'
import { InvoiceStatus } from '@traba/types'
import { useState } from 'react'
import { Button } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'

import CustomReportContainer from './CustomReportContainer'

export default function useCustomReportActions({
  successMessage,
  errorMessage,
  buttonVariant,
  buttonText,
  companyId,
  invoiceId,
  invoiceStatus,
  invoiceCreatedAt,
}: {
  successMessage: string
  errorMessage: string
  buttonVariant: ButtonVariant
  buttonText: string
  companyId?: string
  invoiceId: string
  invoiceStatus?: InvoiceStatus
  invoiceCreatedAt?: Date
}) {
  const [showModal, setShowModal] = useState(false)
  const [showButtonLoading, setShowButtonLoading] = useState(false)

  const modal = (
    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
      {showModal && (
        <CustomReportContainer
          successMessage={successMessage}
          errorMessage={errorMessage}
          invoiceId={invoiceId}
          invoiceStatus={invoiceStatus}
          invoiceCreatedAt={invoiceCreatedAt}
          companyId={companyId}
          handleShowButtonLoading={setShowButtonLoading}
          showModal={showModal}
          handleShowModal={setShowModal}
        />
      )}
    </Modal>
  )

  const button = ({
    disabled,
    tooltipText,
  }: {
    disabled?: boolean
    tooltipText?: string
  }) => {
    return (
      <Button
        variant={buttonVariant}
        onClick={() => setShowModal(true)}
        disabled={disabled}
        tooltipText={tooltipText}
      >
        {showButtonLoading ? (
          <CircularProgress color="inherit" size={10} />
        ) : (
          buttonText
        )}
      </Button>
    )
  }

  return {
    button,
    isLoading: showButtonLoading,
    modal,
  }
}
