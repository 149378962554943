import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material'
import styled from 'styled-components'

export const SliderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.space.sm}px 0
    ${({ theme }) => theme.space.med}px;
  padding: 2px;
  /* box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.Grey}; */
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  border-radius: 8px;
`

export const Buttons = styled(MuiTabs)`
  /* box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.Grey}; */
  max-width: 762px;
`
export const SliderButtonStyle = styled(MuiTab)`
  font-family: Poppins;
  letter-spacing: -0.004em;
  color: ${({ theme }) => theme.colors.Grey60};
  min-width: unset;
  > * {
    font-weight: 600;
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.Violet10};
    border-radius: 8px;
    border: none;
  }
  &.MuiButtonBase-root {
    text-transform: none;
  }
  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.MidnightBlue};
  }
`
