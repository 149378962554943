import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import InvoiceCreator from './components/InvoiceCreator'

export default function InvoicesCreationScreen() {
  return (
    <MainLayout title="New Invoice">
      <InvoiceCreator />
    </MainLayout>
  )
}
