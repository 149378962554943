import { Text, TextProps } from '@traba/react-components'
import { theme } from '@traba/theme'

export const WeeklyPayWarning = (props: TextProps) => (
  <Text variant="caption" color={theme.colors.Orange60} {...props}>
    ACTION REQUIRED: You must add a shift badge to the company that specifies
    the day payouts will process. If no shift badge exists for that payout
    cycle, ask an admin to create one for you.
  </Text>
)
