import { useHotSettings } from '@traba/hooks'
import { DotMenu } from '@traba/react-components'
import { COMPANY_WIDE_TEXT, Role } from '@traba/types'
import { RoleAttribute, RoleAttributeStatus } from '@traba/types'
import { getLocationNameOrTruncatedAddress } from '@traba/utils'
import { AttributeBadge } from 'src/components/AttributeBadge/AttributeBadge'
import { Badge, CopyTextIcon, Row } from 'src/components/base'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { UserRolePermission } from 'src/context/user/types'
import { usePermissions } from 'src/hooks/usePermissions'
import { truncateString } from 'src/utils/stringUtils'
import CompanyRoleBadges from './CompanyRoleBadges'

interface CompanyRolesTableProps {
  roles: Role[]
  roleAttributes: RoleAttribute[]
  onPressRoleEdit: (role: Role) => void
  onPressRoleConfig: (role: Role) => void
  handleArchiveRoleClick: (role: Role) => void
}

export default function CompanyRolesTable({
  roles,
  roleAttributes,
  onPressRoleEdit,
  onPressRoleConfig,
  handleArchiveRoleClick,
}: CompanyRolesTableProps) {
  const manageRoleSettings = usePermissions([
    UserRolePermission.ManageRoleSettings,
  ])
  const { hotSettings } = useHotSettings()

  const headers: (DataTableHeader | string)[] = [
    {
      key: 'roleName',
      label: 'Role Name',
      sortable: true,
    },
    {
      key: 'roleLocation',
      label: 'Location',
      sortable: true,
    },
    {
      key: 'roleId',
      label: 'Role ID',
      sortable: true,
    },
    {
      key: 'roleDescription',
      label: 'Role Description',
    },
    {
      key: 'defaultPayRate',
      label: 'Default Pay Rate',
    },
    {
      key: 'requiredAttire',
      label: 'Required Attire',
    },
    // TODO: Add freeformAttributes to display
    {
      key: 'requiredAttributes',
      label: hotSettings?.allowStoringRequiredAttributeLevel
        ? 'Requested Attributes'
        : 'Required Attributes',
    },
    ...(hotSettings?.allowStoringRequiredAttributeLevel
      ? [
          {
            key: 'requiredAttributeLevels',
            label: 'Required Attribute Levels',
          },
        ]
      : []),
    {
      key: 'requiredCertifications',
      label: 'Required Certifications',
    },
    {
      key: 'BGCRequirement',
      label: 'BGC Requirement',
    },
    {
      key: 'shiftBadges',
      label: 'Shift badges',
    },
    {
      key: 'genderPreference',
      label: 'Gender Preference',
    },
    {
      label: '',
    },
  ]

  const rows: TableRow[] = roles?.map((role) => {
    const {
      extraBGCRequirement,
      roleName,
      roleId,
      location,
      roleDescription,
      defaultPayRate,
      requiredAttire,
      requiredAttributes,
      requiredAttributeLevels,
      requiredCertifications,
      genderPreference,
    } = role

    const locationName = location
      ? getLocationNameOrTruncatedAddress(location)
      : COMPANY_WIDE_TEXT

    const cells: TableCell[] = [
      {
        sortKey: roleName,
        renderFn: () => roleName,
      },
      {
        sortKey: locationName === COMPANY_WIDE_TEXT ? '' : locationName,
        renderFn: () => (
          <Badge
            sm
            variant={location ? 'business' : 'darkOrange'}
            title={locationName}
          />
        ),
      },
      {
        sortKey: roleId,
        renderFn: () => (
          <Row alignCenter>
            {truncateString(roleId)}
            <CopyTextIcon textToCopy={roleId} />
          </Row>
        ),
      },
      {
        renderFn: () => roleDescription,
      },
      {
        renderFn: () => defaultPayRate,
      },
      {
        renderFn: () => requiredAttire,
      },
      {
        renderFn: () => (
          <Row wrap>
            {requiredAttributes
              ?.filter(
                (attribute) =>
                  !hotSettings?.allowStoringRequiredAttributeLevel ||
                  !requiredAttributeLevels?.some(
                    (level) => level.attribute.type === attribute.type,
                  ),
              )
              .map((attribute) => {
                const roleAttribute = roleAttributes?.find(
                  (sa) => sa.type === attribute.type,
                )
                const name =
                  roleAttribute?.displayName ?? roleAttribute?.type ?? ''
                const active =
                  roleAttribute?.status === RoleAttributeStatus.Active
                return <Badge sm title={`${active ? '🔒' : ''} ${name}`} />
              })}
          </Row>
        ),
      },
      ...(hotSettings?.allowStoringRequiredAttributeLevel
        ? [
            {
              renderFn: () => (
                <Row wrap>
                  {requiredAttributeLevels?.map((attribute) => {
                    const roleAttribute = roleAttributes?.find(
                      (sa) => sa.type === attribute.attribute.type,
                    )
                    if (!roleAttribute) {
                      return null
                    }
                    return (
                      <AttributeBadge
                        key={
                          roleAttribute?.displayName ?? attribute.attribute.type
                        }
                        attributeName={
                          roleAttribute?.displayName ?? attribute.attribute.type
                        }
                        attributeLevel={attribute.level}
                        attributeType={attribute.attribute.type}
                        levelType={roleAttribute?.levelType}
                        category={attribute.attribute.category}
                        fontSize={10}
                        iconSize={10}
                        readOnly
                        sm
                      />
                    )
                  })}
                </Row>
              ),
            },
          ]
        : []),
      {
        renderFn: () =>
          requiredCertifications?.map((c) => (
            <Badge sm title={c.replace('_', ' ')} />
          )),
      },
      {
        renderFn: () => extraBGCRequirement,
      },
      {
        renderFn: () => <CompanyRoleBadges roleId={roleId} />,
      },
      {
        renderFn: () => genderPreference,
      },
      {
        renderFn: () => (
          <DotMenu
            type="roles"
            dotMenuKey={roleId}
            menuItems={[
              {
                title: 'Edit Role',
                onClick: () => onPressRoleEdit(role),
                disabled: !manageRoleSettings,
              },
              {
                title: 'Role Settings',
                onClick: () => onPressRoleConfig(role),
                disabled: !manageRoleSettings,
              },
              {
                title: 'Archive role',
                onClick: () => handleArchiveRoleClick(role),
                disabled: !manageRoleSettings,
              },
            ]}
          />
        ),
      },
    ]
    return {
      key: role.roleId,
      cells,
    }
  })

  return (
    <DataTable
      headers={headers}
      rows={rows}
      initialSortByColumnIndex={1} // sort by role name column
    />
  )
}
