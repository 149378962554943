import { useHotSettings } from '@traba/hooks'
import { DotMenu } from '@traba/react-components'
import { theme } from '@traba/theme'
import { User } from '@traba/types'
import { useCallback, useState } from 'react'
import { ArchiveUserModal } from '../ArchiveUserModal'
import { ChangeUserRoleModal } from '../ChangeUserRoleModal'
import { DeleteUserConfirmationDialog } from '../DeleteUserConfirmationDialog'
import { EditUserModal } from '../EditUserModal'
import { UserEditNotificationsModal } from '../UserEditNotificationsModal'

enum COMPANY_MEMBER_UPDATE_ACTIONS {
  'EDIT_USER_NOTIFICATIONS',
  'EDIT_USER',
  'CHANGE_USER_ROLE',
  'ARCHIVE_USER',
  'DEACTIVATE_USER',
  'DELETE_USER',
}

interface EditCompanyMemberActionsMenuProps {
  member: User
  disabled?: boolean
}

export function EditCompanyMemberActionsMenu({
  member,
  disabled,
}: EditCompanyMemberActionsMenuProps) {
  const { hotSettings } = useHotSettings()
  const [activeUpdateAction, setActiveUpdateAction] =
    useState<COMPANY_MEMBER_UPDATE_ACTIONS>()

  const onCloseActionModal = useCallback(() => {
    setActiveUpdateAction(undefined)
  }, [])

  const onSelectEditUserNotifications = useCallback(() => {
    setActiveUpdateAction(COMPANY_MEMBER_UPDATE_ACTIONS.EDIT_USER_NOTIFICATIONS)
    window.analytics.track(`Edit User Notifications Clicked`, {
      member,
    })
  }, [member])

  const onSelectEditUser = useCallback(() => {
    setActiveUpdateAction(COMPANY_MEMBER_UPDATE_ACTIONS.EDIT_USER)
    window.analytics.track(`Edit User Clicked`, {
      member,
    })
  }, [member])

  const onSelectArchiveUser = useCallback(() => {
    setActiveUpdateAction(COMPANY_MEMBER_UPDATE_ACTIONS.ARCHIVE_USER)
    window.analytics.track(`Archive User Clicked`, {
      member,
    })
  }, [member])

  const onSelectChangeUserRole = useCallback(() => {
    setActiveUpdateAction(COMPANY_MEMBER_UPDATE_ACTIONS.CHANGE_USER_ROLE)
    window.analytics.track(`Change User Role Clicked`, {
      member,
    })
  }, [member])

  const onSelectDeleteUser = useCallback(() => {
    setActiveUpdateAction(COMPANY_MEMBER_UPDATE_ACTIONS.DELETE_USER)
    window.analytics.track(`Delete User Clicked`, {
      member,
    })
  }, [member])

  const menuItems = [
    {
      title: 'Edit User Notifications',
      onClick: onSelectEditUserNotifications,
    },
    ...(hotSettings?.enableRegionalAccessPhase2
      ? [
          {
            title: 'Edit User',
            onClick: onSelectEditUser,
          },
        ]
      : [
          {
            title: 'Change User Role',
            onClick: onSelectChangeUserRole,
          },
        ]),
    {
      title: 'Archive member',
      onClick: onSelectArchiveUser,
      color: theme.colors.Red60,
    },
    {
      title: 'Delete member',
      onClick: onSelectDeleteUser,
      color: theme.colors.Red60,
    },
  ]

  return (
    <>
      <DotMenu
        type="active-business-member"
        dotMenuKey={member.uid}
        menuItems={menuItems}
        buttonIconName="edit"
        disabled={disabled}
        style={{ padding: '8px', width: '38px', height: '34px' }}
      />
      <UserEditNotificationsModal
        user={member}
        handleClose={onCloseActionModal}
        isOpen={
          activeUpdateAction ===
          COMPANY_MEMBER_UPDATE_ACTIONS.EDIT_USER_NOTIFICATIONS
        }
      />
      {hotSettings?.enableRegionalAccessPhase2 ? (
        <EditUserModal
          user={member}
          handleClose={onCloseActionModal}
          isOpen={
            activeUpdateAction === COMPANY_MEMBER_UPDATE_ACTIONS.EDIT_USER
          }
        />
      ) : (
        <ChangeUserRoleModal
          user={member}
          handleClose={onCloseActionModal}
          isOpen={
            activeUpdateAction ===
            COMPANY_MEMBER_UPDATE_ACTIONS.CHANGE_USER_ROLE
          }
        />
      )}
      <ArchiveUserModal
        user={member}
        handleClose={onCloseActionModal}
        isOpen={
          activeUpdateAction === COMPANY_MEMBER_UPDATE_ACTIONS.ARCHIVE_USER
        }
      />
      <DeleteUserConfirmationDialog
        user={member}
        onClose={onCloseActionModal}
        isOpen={
          activeUpdateAction === COMPANY_MEMBER_UPDATE_ACTIONS.DELETE_USER
        }
      />
    </>
  )
}
