import { Button, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'

type ManageWorkerModalButtonsProps = {
  handleClose: () => void
  disable?: boolean
}

export default function ManageWorkerModalButtons(
  props: ManageWorkerModalButtonsProps & {
    handleConfirm: () => Promise<void> | void
    loading: boolean
    disableSubmit?: boolean
  },
) {
  const { handleClose, handleConfirm, loading, disableSubmit } = props
  return (
    <Row
      mt={16}
      fullWidth
      style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
    >
      <Button
        variant={ButtonVariant.OUTLINED}
        style={{ width: '200px' }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        style={{ width: '200px' }}
        onClick={handleConfirm}
        loading={loading}
        disabled={disableSubmit}
      >
        Confirm
      </Button>
    </Row>
  )
}
