import { theme } from '@traba/theme'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  width: 500px;
  padding: ${({ theme }) => `${theme.space.xs}px`};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    padding: ${({ theme }) => `${theme.space.xs / 2}px`};
  }

  @media (max-width: 480px) {
    padding: ${({ theme }) => `${theme.space.xs / 2}px`};
    width: 100%;
  }
`

export const DrawerHeader = styled.div`
  display: 'flex';
  align-items: 'center';
  padding: ${theme.space.xxs}px;
  justify-content: 'flex-start';
`

export const SubHeaderWrapper = styled.div`
  margin-bottom: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
`

export const ExpandedWrapper = styled.div`
  padding: ${theme.space.med}px;
  width: 100%;
  overflow-wrap: break-word;
  flex: 1;
  display: flex;
`

//A styled component that rotates child 90 degrees on y axis
export const Rotated = styled.div`
  transform: rotateY(180deg);
`
