import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { isEmpty } from 'lodash'
import * as S from './ShiftDetails.styles'

export type HeaderWithDetailItemProps = {
  title: string
  children?: React.ReactNode | string
  renderTextWrapper?: boolean
  tooltipText?: string
}

export default function HeaderWithDetailItem({
  renderTextWrapper = true,
  title,
  children,
  tooltipText,
}: HeaderWithDetailItemProps) {
  return (
    <>
      <S.HeaderWrapper>
        <Text variant="caption">{title.toUpperCase()}</Text>
        {tooltipText && <InfoTooltip title={tooltipText} />}
      </S.HeaderWrapper>
      <S.SubWrapper>
        {renderTextWrapper ? (
          <Text variant="body1" style={{ fontWeight: '500' }}>
            {!isEmpty(children) ? children : '-'}
          </Text>
        ) : !isEmpty(children) ? (
          children
        ) : (
          '-'
        )}
      </S.SubWrapper>
    </>
  )
}
