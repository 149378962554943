import { ShiftWithSlimCharges } from '@traba/types'
import { useMemo } from 'react'
import { Button, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { ShiftDateCell, ShiftTimesCell } from 'src/components/ShiftTableCells'
import InvoiceStatusBadge from 'src/screens/BillingScreen/components/InvoiceStatusBadge'
import { getEstimatedTotalChargeInCentsFromCharges } from 'src/utils/billingUtils'
import { convertUSDCentsToMoney } from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import { EmploymentTypeBadge } from '../../../../components/base/Badge/EmploymentTypeBadge'

type InvoiceCreatorShiftsTableProps = {
  shifts: ShiftWithSlimCharges[]
  selectedShifts: ShiftWithSlimCharges[]
  onSelectShiftIds: (shiftIds: string[]) => void
  disableSelect?: boolean
}

export default function InvoiceCreatorShiftsTable(
  props: InvoiceCreatorShiftsTableProps,
) {
  const { shifts, selectedShifts, onSelectShiftIds, disableSelect } = props

  const headers: (DataTableHeader | string)[] = [
    { key: 'shiftName', label: 'Shift Name' },
    { key: 'shiftEmploymentType', label: 'Type' },
    { key: 'date', label: 'Date' },
    { key: 'startAndEndTime', label: 'Start - End Time' },
    { key: 'region', label: 'Region' },
    { key: 'invoiceStatus', label: 'Invoice Status' },
    { key: 'totalCharge', label: 'Total Charge' },
  ]

  const shiftToRow = (shift: ShiftWithSlimCharges) => {
    const shiftName = `${shift.shiftRole || shift.role?.roleName} ${` @ ${shift.shortLocation ?? ''}`}`

    const cells: TableCell[] = [
      // Shift Name
      {
        renderFn: () => (
          <Row justifyStart key={shift.id}>
            <Link
              to={`/field-monitor/${shift.id}`}
              style={{ maxWidth: '20em', overflow: 'ellipsis' }}
            >
              <Button
                style={{ padding: 0 }}
                labelStyle={{ textAlign: 'start' }}
                variant={ButtonVariant.TEXT}
              >
                {shiftName}
              </Button>
            </Link>
            <CopyTextIcon textToCopy={shift.id} />
          </Row>
        ),
      },
      // Shift type
      {
        renderFn: () =>
          shift.shiftEmploymentType && (
            <EmploymentTypeBadge
              employmentTypes={[shift.shiftEmploymentType]}
              showAll
            />
          ),
      },
      // Shift Date
      {
        renderFn: () => <ShiftDateCell shift={shift} />,
      },
      // Shift Times
      {
        renderFn: () => <ShiftTimesCell shift={shift} />,
      },
      // Shift Region
      {
        renderFn: () => shift.regionId,
      },
      // Invoice Status
      {
        renderFn: () =>
          shift.invoiceStatus ? (
            <InvoiceStatusBadge status={shift.invoiceStatus} />
          ) : (
            '-'
          ),
      },
      // Charge Amount
      {
        renderFn: () =>
          getMoneyString(
            convertUSDCentsToMoney(
              getEstimatedTotalChargeInCentsFromCharges(shift),
            ),
          ),
      },
    ]
    return {
      key: shift.id,
      cells,
    }
  }

  const rows: TableRow[] = useMemo(
    () => shifts?.map((shift) => shiftToRow(shift)),
    [shifts],
  )

  const selectedRows: TableRow[] = useMemo(
    () => selectedShifts?.map((shift) => shiftToRow(shift)),
    [selectedShifts],
  )

  /**
   * When selecting a new shift, check if the new shift has a different employment type
   * than the currently selected shifts. If so, alert the user and do not select the new shift.
   */
  const onSelectRows = (rows: TableRow[]) => {
    const selectedRows = rows.map((row) => row.key)
    const isSelectingShifts = selectedRows.length > selectedShifts.length
    const conflictingShifts = selectedRows.filter((row) => {
      const selectingShift = shifts.find((shift) => shift.id === row)
      const conflictingShift = selectedShifts.find(
        (shift) =>
          !!selectingShift?.shiftEmploymentType &&
          shift.shiftEmploymentType !== selectingShift.shiftEmploymentType,
      )
      return !!conflictingShift && isSelectingShifts
    })
    if (conflictingShifts.length > 0) {
      alert(`Cannot select shifts with different employment types.`)
      return
    }

    onSelectShiftIds(selectedRows)
  }

  return (
    <DataTable
      allowSelect={!disableSelect}
      headers={headers}
      rows={rows}
      onSelectRows={onSelectRows}
      selectedRows={selectedRows}
    />
  )
}
