import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { useState } from 'react'
import { Button, Row } from 'src/components/base'
import { useAnalytics } from '../../../hooks/useAnalytics'

export type BulkRobocallProps = {
  handleClose: () => void
  workerShifts: WorkerShift[]
  sentinelNotificationToUserId?: string
}

export default function BulkRobocall({
  handleClose,
  workerShifts,
  sentinelNotificationToUserId,
}: BulkRobocallProps) {
  const [isLoadingPreShiftRobocall, setIsLoadingPreShiftRobocall] =
    useState(false)
  const { showError, showSuccess } = useAlert()
  const { trackAnalytics } = useAnalytics()

  async function makePreShiftRobocall() {
    try {
      setIsLoadingPreShiftRobocall(true)
      await trabaApi.post(
        `/communication/robocall/pre-shift-confirmation?shiftId=${
          workerShifts[0].shiftId
        }&workerIds=${workerShifts.map((ws) => ws.workerId).join(',')}`,
        { sentinelNotificationToUserId },
      )
      if (sentinelNotificationToUserId) {
        trackAnalytics('Sentinel Action Taken Bulk Robocall', {
          sentinelNotificationToUserId,
          workerIds: workerShifts.map((ws) => ws.workerId),
          shiftId: workerShifts[0].shiftId,
        })
      }
      showSuccess('Pre-shift robocall is on its way', 'Success', 2000)
    } catch (err: any) {
      showError(err, 'Error sending pre-shift robocall', 2000)
    } finally {
      setIsLoadingPreShiftRobocall(false)
      handleClose()
    }
  }

  return (
    <Row fullWidth alignStart justifyBetween>
      <Text variant="h5">
        Make pre-shift confirmation robocall for all workers above
      </Text>
      <Button
        slim
        loading={isLoadingPreShiftRobocall}
        onClick={makePreShiftRobocall}
        disabled={!workerShifts.length}
      >
        Make Call
      </Button>
    </Row>
  )
}
