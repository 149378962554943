import { IconButton, Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InternalUser, InternalUserRole } from '@traba/types'
import { getInitials } from 'src/utils/stringUtils'
import { Row } from './base'

type ShiftAssignmentLabelProps = {
  internalUser: InternalUser
}

export const ShiftAssignmentLabel = (props: ShiftAssignmentLabelProps) => {
  const { internalUser } = props
  const initials = getInitials(internalUser.firstName, internalUser.lastName)
  const backgroundColor =
    internalUser.role === InternalUserRole.SCALED_OPS
      ? theme.colors.Violet10
      : theme.colors.Green10

  return (
    <Tooltip title={internalUser.email}>
      <div>
        <Row
          justifyCenter
          alignCenter
          style={{
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor,
            color: theme.colors.Violet,
          }}
        >
          <IconButton>
            <Text>{initials}</Text>
          </IconButton>
        </Row>
      </div>
    </Tooltip>
  )
}
