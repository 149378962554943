import { Text, Row, Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { useFormik } from 'formik'
import React, { HTMLInputTypeAttribute, useState } from 'react'
import { Icon, Input } from 'src/components/base'
import { Button } from 'src/components/base/Button'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { useBilling } from 'src/hooks/useBilling'
import {
  INVALID_EMAIL_MESSAGE,
  INVALID_PHONE_MESSAGE,
  rePhoneNumber,
} from 'src/utils/inputUtils'
import * as yup from 'yup'
import { DetailRow } from './DetailRow'

interface CompanyContactDetailsProps {
  companyId: string
  employerName: string
}

const containerStyle: React.CSSProperties = {
  borderRadius: theme.space.xxs,
  border: `2px solid ${theme.colors.Grey20}`,
  padding: theme.space.sm,
}

const editButtonStyle: React.CSSProperties = {
  width: '200px',
}

const iconStyle: React.CSSProperties = {
  marginRight: theme.space.xxs,
}

export function CompanyContactDetails({
  companyId,
  employerName,
}: CompanyContactDetailsProps) {
  const { billing, patchBilling } = useBilling(companyId)
  const [isEditing, setIsEditing] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: billing?.invoiceCompanyName ?? employerName,
      contactName: billing?.contactName ?? '',
      phoneNumber: billing?.paymentSettings.phoneNumber ?? '',
      email: billing?.paymentSettings.email ?? '',
    },
    validationSchema: yup.object({
      phoneNumber: yup.string().matches(rePhoneNumber, INVALID_PHONE_MESSAGE),
      email: yup.string().email(INVALID_EMAIL_MESSAGE),
    }),
    onSubmit: ({ companyName, contactName, phoneNumber, email }) => {
      patchBilling({
        invoiceCompanyName: companyName,
        contactName,
        paymentSettings: {
          phoneNumber,
          email,
        },
      })
      setIsEditing(false)
    },
  })

  const formInputs: {
    label: string
    key: keyof typeof formik.values
    type: HTMLInputTypeAttribute
  }[] = [
    { label: 'Company Name', key: 'companyName', type: 'text' },
    { label: 'Contact Name', key: 'contactName', type: 'text' },
    { label: 'Phone Number', key: 'phoneNumber', type: 'tel' },
    { label: 'Email', key: 'email', type: 'email' },
  ]

  const EditDetailsButton = () => (
    <Button
      variant={ButtonVariant.OUTLINED}
      onClick={() => setIsEditing(true)}
      slim
      style={editButtonStyle}
    >
      <Icon name="edit" style={iconStyle} />
      Edit Details
    </Button>
  )

  const SaveChangesButton = () => (
    <Button
      variant={ButtonVariant.FILLED}
      slim
      style={editButtonStyle}
      type="submit"
    >
      Save Changes
    </Button>
  )

  return (
    <form style={containerStyle} onSubmit={formik.handleSubmit}>
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h5">Company Contact Details</Text>
        {isEditing ? <SaveChangesButton /> : <EditDetailsButton />}
      </Row>
      <Divider />
      <Row flexCol gap={theme.space.sm} mt={theme.space.xs}>
        {formInputs.map(({ label, key, type }) => (
          <DetailRow
            key={key}
            label={label}
            value={formik.values[key]}
            editing={isEditing}
          >
            <Col width={'100%'}>
              <Input
                label={label}
                name={key}
                value={formik.values[key]}
                onChange={formik.handleChange}
                inputStatus={
                  formik.errors[key] ? InputStatus.error : InputStatus.default
                }
                errorMessage={formik.errors[key]}
                width="100%"
                type={type}
              />
            </Col>
          </DetailRow>
        ))}
      </Row>
    </form>
  )
}
