import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import { useCompany } from 'src/hooks/useCompany'

type CompanyData = {
  company?: Company
  isLoading: boolean
}
export default function WorkerHoursAtCompany(props: {
  companyId: string
  minutes: number
}) {
  const companyData = useCompany(props.companyId)

  return (
    <Text variant="h7" mb={theme.space.xs} key={props.companyId}>
      {getDisplayName(props.companyId, companyData)}:{' '}
      {formatTime(props.minutes)}
    </Text>
  )
}

const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  if (hours === 0 && remainingMinutes === 0) {
    return '0 minutes'
  }
  if (hours === 0) {
    return `${remainingMinutes} minutes`
  }
  if (remainingMinutes === 0) {
    return `${hours} hours`
  }
  return `${hours} hours ${remainingMinutes} minutes`
}

function getDisplayName(companyId: string, companyData: CompanyData) {
  return companyData?.company?.employerName || companyId
}
