import { EligibilityCriteria } from './workers'

export enum WorkerShiftSignupNotificationStatus {
  Queued = 'QUEUED',
  Sent = 'SENT',
  SendError = 'SEND_ERROR',
  TooManyNotificationsAlreadySent = 'TOO_MANY_NOTIFICATIONS_ALREADY_SENT',
  WorkerIneligible = 'WORKER_INELIGIBLE',
}

export type WorkerShiftSignupNotification = {
  workerId: string
  shiftId: string
  immutableIndex: number
  attributeListIndex?: number
  status?: WorkerShiftSignupNotificationStatus
  workerShiftSignupNotificationId: string
  createdAt: Date
  scheduledFor?: Date
  sentAt?: Date
  lastSendAttemptedAt?: Date
  deleteAfter: Date
  eligibilityCriteria: EligibilityCriteria
  renotifiedBySmsAt?: Date
}

export enum ShiftSignupNotificationStatusStatus {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Started = 'STARTED',
}

export type ShiftSignupNotificationsStatus = {
  shiftId: string
  startIndexInclusive: number
  numWorkersInList: number
  status: ShiftSignupNotificationStatusStatus
  lastFavoriteIndex: number
  shiftSignupNotificationsStatusId: string
  createdAt: Date
}
