import { Alert, Snackbar } from '@mui/material'
import { Text } from '@traba/react-components'
import { useState } from 'react'
import { Button } from 'src/components/base'
import { useGoogleAuth } from 'src/hooks/useAuth'

export default function LoginScreen() {
  const [openToast, showToast] = useState(false)
  const { loginWithGoogle } = useGoogleAuth()
  const handleClose = () => {
    showToast(false)
  }

  return (
    <div
      style={{
        backgroundColor: '#F5EBFF',
        margin: 'auto',
        padding: '8px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Text variant="h1" mb={32}>
        Login to Ops Console
      </Text>

      <Button onClick={loginWithGoogle}>Login with Google</Button>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          User is not part of Traba Ops!
        </Alert>
      </Snackbar>
    </div>
  )
}
