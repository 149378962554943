import { theme } from '@traba/theme'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  width: ${({ width = 400 }: { width?: number }) => width}px;
  padding-top: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
`

export const SubHeaderWrapper = styled.div`
  margin-bottom: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
`

export const ListStyle = {
  backgroundColor: theme.colors.Grey10,
  borderBottomLeftRadius: theme.space.xxs,
  borderBottomRightRadius: theme.space.xxs,
  paddingTop: theme.space.xxs,
  paddingBottom: theme.space.xxs,
  marginBottom: theme.space.xs,
}
