import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'

import { CustomizedBadge } from './components/CustomizedBadge/CustomizedBadge'
import { ExistingCertifications } from './components/ExistingCertifications'
import { InternalUsersTable } from './components/InternalUsersTable/InternalUsersTable'
import VerifyUserEmail from './components/VerifyUserEmail'

export const ToolsScreen = () => {
  return (
    <MainLayout title="Tools">
      <Row mb={theme.space.xs} gap={theme.space.xs}>
        <VerifyUserEmail />
        <CustomizedBadge />
      </Row>
      <Row mb={theme.space.xs} gap={theme.space.xs}>
        <InternalUsersTable />
        <ExistingCertifications />
      </Row>
    </MainLayout>
  )
}
