import { Row, Button, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'

export const PendingIncentiveItemWrapper = styled(Row)`
  border-radius: 8px;
  width: 100%;
  min-width: 1000px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  padding: 16px;
  flex-direction: column;
`

export const TitleLink = styled(Button).attrs({
  textColor: theme.colors.brand,
  textHoverColor: theme.colors.MidnightBlue,
  variant: ButtonVariant.BRANDLINK,
})`
  padding: 0;
`

export const CollapseButton = styled(Button).attrs({
  variant: ButtonVariant.OUTLINED,
})`
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
