import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import {
  InvoiceStatementConfiguration,
  ReportConfiguration,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'

export async function getInvoiceStatementConfiguration(
  companyId?: string,
): Promise<InvoiceStatementConfiguration[] | undefined> {
  if (!companyId) {
    return undefined
  }
  try {
    const res = await trabaApi.get(
      `companies/${companyId}/invoice-statement-configuration`,
    )
    return res.data
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export async function getReportConfiguration(
  companyId?: string,
): Promise<ReportConfiguration | undefined> {
  if (!companyId) {
    return undefined
  }
  try {
    const res = await trabaApi.get(
      `companies/${companyId}/report-configuration`,
    )
    return res.data
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export function useReportConfiguration(companyId?: string) {
  const {
    isLoading,
    isError,
    data: invoiceStatementConfigurations,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<InvoiceStatementConfiguration[] | undefined, AxiosError>(
    ['invoice_statement_configuration', companyId],
    () => getInvoiceStatementConfiguration(companyId),
    { enabled: companyId !== undefined },
  )

  const {
    isLoading: isLoadingReportConfig,
    isError: isReportConfigError,
    data: reportConfiguration,
    error: reportConfigError,
    isFetched: isReportConfigFetched,
    isFetching: isReportConfigFetching,
    refetch: refetchReportConfig,
  } = useQuery<ReportConfiguration | undefined, AxiosError>(
    ['report_configuration', companyId],
    () => getReportConfiguration(companyId),
    { enabled: companyId !== undefined },
  )

  const upsertReportConfigurationMutation = useMutation<
    Partial<ReportConfiguration>,
    AxiosError,
    Partial<ReportConfiguration>
  >(
    (configuration) => {
      return trabaApi.post(`companies/${companyId}/report-configuration`, {
        configuration: configuration,
      })
    },
    {
      onSuccess: () => {
        refetchReportConfig()
      },
      onError: (error) => {
        console.error('Error saving report configuration:', error)
        throw error
      },
    },
  )

  const upsertInvoiceStatementConfigurationMutation = useMutation<
    InvoiceStatementConfiguration[],
    AxiosError,
    InvoiceStatementConfiguration[],
    InvoiceStatementConfiguration[]
  >(
    (configurations) =>
      trabaApi.post(`companies/${companyId}/invoice-statement-configuration`, {
        configurations: configurations.map(
          ({ id, groupBy, columns, displayName, showTotals }) => ({
            id,
            groupBy,
            columns,
            displayName,
            showTotals,
          }),
        ),
      }),
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  const deleteInvoiceStatementConfigurationMutation = useMutation<
    void,
    AxiosError,
    string
  >(
    (id: string) =>
      trabaApi.delete(
        `companies/${companyId}/invoice-statement-configuration/${id}`,
      ),
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  return {
    isLoading,
    isError,
    invoiceStatementConfigurations,
    error,
    isFetched,
    refetch,
    isFetching,
    upsertInvoiceStatementConfigurations:
      upsertInvoiceStatementConfigurationMutation.mutateAsync,
    deleteInvoiceStatementConfiguration:
      deleteInvoiceStatementConfigurationMutation.mutateAsync,
    isLoadingReportConfig,
    isReportConfigError,
    reportConfiguration,
    reportConfigError,
    isReportConfigFetched,
    isReportConfigFetching,
    refetchReportConfig,
    upsertReportConfiguration: upsertReportConfigurationMutation.mutateAsync,
  }
}
