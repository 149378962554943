import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ShiftRequestEditInput } from '@traba/types'

export const useShiftRequestEditsMutation = (
  shiftRequestParentId: string,
  companyId: string,
) => {
  const QueryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()
  const mutation = useMutation({
    mutationFn: async (shiftRequestEdit: { edit: ShiftRequestEditInput }) => {
      const { edit } = shiftRequestEdit
      const {
        minSlotsRequested: _minSlotsRequested,
        hourlyRate: _hourlyRate,
        ...rest
      } = edit
      const response = await trabaApi.post(`shift-request-edits`, {
        edit: rest,
      })
      return response.data
    },
    onSuccess: () => {
      showSuccess('Shift request edited successfully.')
    },
    onError: (error) => {
      Sentry.captureException(error)
      showError('Failed to edit, please try again.')
    },
    onSettled: () => {
      QueryClient.invalidateQueries({
        queryKey: ['shift-request-parent', shiftRequestParentId],
      })
      QueryClient.invalidateQueries({
        queryKey: ['company-shifts', companyId],
      })
    },
  })

  return {
    editShiftRequest: mutation.mutateAsync,
    isPending: mutation.isPending,
  }
}
