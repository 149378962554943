import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  DrugPanelType,
  DrugScreeningOrder,
  DrugScreeningResult,
} from '@traba/types'

interface CreateDrugScreeningOrderParams {
  workerId: string
  drugPanelType: DrugPanelType
  requestingCompanyId?: string
  expirationDays: number
}

export const drugScreeningKeys = {
  all: ['drug-screening'] as const,
  orders: {
    all: () => [...drugScreeningKeys.all, 'orders'] as const,
    detail: (orderId: string) =>
      [...drugScreeningKeys.all, 'orders', orderId] as const,
  },
  results: {
    all: () => [...drugScreeningKeys.all, 'results'] as const,
  },
}

export const useWorkerDrugScreeningOrders = (workerId: string) => {
  return useQuery({
    queryKey: drugScreeningKeys.orders.all(),
    queryFn: () =>
      trabaApi.get<DrugScreeningOrder[]>(
        `/drug-screening/get-orders/ops/${workerId}`,
      ),
    select: (response) => response.data,
    staleTime: FIVE_MINUTES_IN_MS,
  })
}

export const useWorkerDrugScreeningResults = (workerId: string) => {
  return useQuery({
    queryKey: drugScreeningKeys.results.all(),
    queryFn: () =>
      trabaApi.get<DrugScreeningResult[]>(
        `/drug-screening/get-results/ops/${workerId}`,
      ),
    select: (response) => response.data,
  })
}

export const useOrderNewDrugScreeningOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateDrugScreeningOrderParams) => {
      const response = await trabaApi.post(
        `/drug-screening/order/ops/${params.workerId}`,
        {
          drugPanelType: params.drugPanelType,
          requestingCompanyId: params.requestingCompanyId,
          expirationDays: params.expirationDays,
        },
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.orders.all(),
      })
    },
  })
}

export const useRefreshDrugScreeningOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (orderId: string) => {
      const response = await trabaApi.post(
        `/drug-screening/refresh-order/${orderId}`,
      )
      return response.data
    },
    onSuccess: (_, orderId) => {
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.orders.all(),
      })
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.orders.detail(orderId),
      })
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.results.all(),
      })
    },
  })
}

export const useCancelDrugScreeningOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (orderId: string) => {
      const response = await trabaApi.post(
        `/drug-screening/cancel-order/${orderId}`,
      )
      return response.data
    },
    onSuccess: (_, orderId) => {
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.orders.all(),
      })
      queryClient.invalidateQueries({
        queryKey: drugScreeningKeys.orders.detail(orderId),
      })
    },
  })
}
