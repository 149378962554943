import { useIncentives } from 'src/hooks/useIncentives'
import WorkerIncentivesTable from '../../../components/WorkerIncentives/WorkerIncentivesTable'

interface WorkerIncentivesProps {
  workerId: string
}

export const WorkerIncentivesTab = ({ workerId }: WorkerIncentivesProps) => {
  const { useGetWorkerIncentivesForWorkerId } = useIncentives()

  const {
    data: workerIncentives,
    isLoading: isLoadingWorkerIncentives,
    refetch,
  } = useGetWorkerIncentivesForWorkerId(workerId)

  return (
    <WorkerIncentivesTable
      workerIncentives={workerIncentives}
      isLoading={isLoadingWorkerIncentives}
      refetch={refetch}
    />
  )
}
