import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { NoteType, ShiftNote } from '@traba/types'
import { Col, Row } from 'src/components/base'
import useNotes from 'src/hooks/useNotes'
import { sortByProp } from 'src/utils/helperUtils'
import NoteDrawer from './NoteDrawer/NoteDrawer'
import { NotePlaceholder } from './NotePlaceholder'
import { PostedNote } from './PostedNote'

interface ShiftNoteDrawerProps {
  shiftId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideFAB?: boolean
  dualNote?: boolean
  noCloseButton?: boolean
}

export const ShiftNoteDrawer = (props: ShiftNoteDrawerProps) => {
  const { shiftId, isOpen, setIsOpen, hideFAB, dualNote, noCloseButton } = props
  const { useShiftNotes } = useNotes()
  const { shiftNotes = [], isLoading } = useShiftNotes(shiftId)
  shiftNotes.sort(sortByProp('createdAt', 'DESC'))
  const createNoteParams = { id: shiftId, noteType: NoteType.Shift }

  return (
    <NoteDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      createNoteParameters={createNoteParams}
      title="Shift Notes"
      hideFAB={hideFAB}
      dualNote={dualNote}
      noteCount={shiftNotes.length}
      noCloseButton={noCloseButton}
    >
      {shiftNotes.length > 0 ? (
        <Col style={{ maxWidth: '100%' }}>
          <Row alignCenter mb={theme.space.xs}>
            <Text variant="h4">Past Notes ({shiftNotes.length})</Text>
          </Row>
          {shiftNotes.map((shiftNote: ShiftNote) => (
            <PostedNote
              key={shiftNote.noteId}
              note={shiftNote}
              noteType={NoteType.Shift}
            />
          ))}
        </Col>
      ) : isLoading ? (
        <CircularProgress size={theme.space.med} />
      ) : (
        <NotePlaceholder subtitle="Shift Notes Will Show Up Here" />
      )}
    </NoteDrawer>
  )
}
