import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from 'src/components/base'

interface ShiftSettingsSectionProps {
  children: React.ReactNode
}

export const ShiftSettingsSection: React.FC<ShiftSettingsSectionProps> = (
  props,
) => {
  const { children } = props
  return (
    <Row flexCol justifyStart style={{ flex: 1 }} py={theme.space.xxs}>
      <Text variant="h5" mb={theme.space.xs}>
        Shift Settings
      </Text>
      {children}
    </Row>
  )
}
