import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  WatchlistRequirementFilter,
  WatchlistSearchParams,
  WorkerDetailsForWatchlist,
} from '@traba/types'

async function getWorkersOnWatchlist({
  nextShiftStartTimeBefore,
  requirementFilters,
  showWorkersWithActiveAction,
  showWorkersWithPendingAction,
  workerSearchText,
  regions,
  limit,
  offset,
}: WatchlistSearchParams) {
  try {
    const res = await trabaApi.post('/workers/watchlist', {
      nextShiftStartTimeBefore,
      requirementFilters,
      showWorkersWithActiveAction,
      showWorkersWithPendingAction,
      workerSearchText,
      regions,
      limit,
      offset,
    })
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkersOnWatchlist() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    Sentry.captureException(error)
    console.error(errorMessage)
  }
}

export function useWorkersOnWatchlist(
  nextShiftStartTimeBefore: Date,
  requirementFilters: WatchlistRequirementFilter[],
  showWorkersWithActiveAction: boolean,
  showWorkersWithPendingAction: boolean,
  workerSearchText: string,
  regionIds: string[],
  page: number,
  pageSize: number,
) {
  const params: WatchlistSearchParams = {
    nextShiftStartTimeBefore,
    requirementFilters,
    showWorkersWithActiveAction,
    showWorkersWithPendingAction,
    workerSearchText,
    regions: regionIds,
    limit: pageSize,
    offset: page * pageSize,
  }

  const queryKey = ['workersOnWatchlist', params]
  const queryFn = () => getWorkersOnWatchlist(params)

  const {
    isLoading,
    isFetching,
    isError,
    data: workersOnWatchlist,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerDetailsForWatchlist[], Error>({
    queryKey,
    queryFn,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isFetching,
    isError,
    workersOnWatchlist,
    error,
    isFetched,
    refetch,
  }
}
