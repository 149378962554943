import { CircularProgress as MUICircularProgress } from '@mui/material'

const CIRCULAR_PROGRESS_SIZE = {
  small: 24,
  medium: 36,
  large: 48,
}
export function CircularProgress(props: {
  size: 'small' | 'medium' | 'large'
  color?: string
}) {
  let componentSize = CIRCULAR_PROGRESS_SIZE.small
  switch (props.size) {
    case 'small':
      componentSize = CIRCULAR_PROGRESS_SIZE.small
      break
    case 'medium':
      componentSize = CIRCULAR_PROGRESS_SIZE.medium
      break
    case 'large':
      componentSize = CIRCULAR_PROGRESS_SIZE.large
      break
    default:
  }
  return (
    <MUICircularProgress
      size={componentSize}
      sx={{
        color: props.color || 'ButtonHighlight',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: componentSize * -1,
        marginLeft: componentSize * -1,
      }}
    />
  )
}
