import { ShiftStatus } from '@traba/types'
import Badge from '.'

export type ShiftStatusBadgeProps = {
  shiftStatus: ShiftStatus
}

const shiftStatusToBadgeVariantDict: Record<ShiftStatus, string> = {
  [ShiftStatus.ACTIVE]: 'info',
  [ShiftStatus.CANCELED]: 'opaqueRed',
  [ShiftStatus.COMPLETE]: 'success',
}

export default function ShiftStatusBadge({
  shiftStatus,
}: ShiftStatusBadgeProps) {
  return (
    <Badge
      title={shiftStatus?.replaceAll('_', ' ')}
      variant={shiftStatusToBadgeVariantDict[shiftStatus]}
    />
  )
}
