import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import { useInternalUsers } from '@traba/hooks'
import { MediaUploader } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Button } from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import { getErrorMessage } from 'src/utils/errorUtils'
import { openInNewTab } from 'src/utils/helperUtils'

export default function BusinessTimesheetUpload() {
  const { showError } = useAlert()
  const { handleUpload } = useFileUploader()
  const { state } = useUserContext()
  const { internalUsers } = useInternalUsers({
    emails: [state.userProfile?.email ?? ''],
  })

  const [file, setFile] = useState<File | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function transformTimesheet() {
    if (!file) {
      return
    }
    if (!internalUsers || !internalUsers.length) {
      showError(
        'You are not authorized to upload a business timesheet.',
        'Internal User Not Found',
      )
      return
    }
    setIsLoading(true)
    try {
      const fileUrl = await handleUpload({
        fileType: FileType.BUSINESS_TIMESHEETS,
        media: file,
        userId: internalUsers[0].id,
        skipResize: true,
      })
      const { data } = await trabaApi.put(
        '/ai-platform/timesheet-automation/transform',
        { fileUrl, fileType: file.type },
      )
      const { link } = data
      openInNewTab(link)
      return link
    } catch (error) {
      showError(getErrorMessage(error), 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <MediaUploader
        label="Upload Business Timesheet"
        file={file}
        onChange={setFile}
        onDelete={() => setFile(undefined)}
        onError={(error) => {
          showError(error, 'Something went wrong')
        }}
        fileType="pdf"
      />
      <Card
        style={{
          marginTop: theme.space.lg,
          width: '50%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Upload a Business-Origin Timesheet!
        <br />
        <br />
        Analyze and transform the business-origin timesheet into a Traba
        Timesheet that you can upload on the previous tab.
      </Card>
      <Button
        mt={theme.space.lg}
        disabled={!file}
        loading={isLoading}
        onClick={transformTimesheet}
      >
        Transform and Download
      </Button>
    </>
  )
}
