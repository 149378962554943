import { theme } from '@traba/theme'
import circle from '@turf/circle'
import { useState } from 'react'
import { Layer, Marker, MarkerDragEvent, Source } from 'react-map-gl'
import { Location } from 'src/assets/svg/icons/Location'
import { getDistanceBetweenCoords } from 'src/utils/helperUtils'
import { Button } from '../base'
import { DraggableMap, MapState } from './DraggableMap'

type Props = {
  pinLatitude: number
  pinLongitude: number
  width: number | string
  height: number | string
  fenceRadiusMetres?: number
  onMovePin: (latitude: number, longitude: number) => void
}

const RESET_BUTTON_DISTANCE_THRESHOLD_KMS = 0.002

export const DraggableMapPinInput = (props: Props) => {
  const [mapState, setMapState] = useState<MapState>({
    longitude: props.pinLongitude,
    latitude: props.pinLatitude,
  })

  const [isMarkerDragging, setIsMarkerDragging] = useState(false)

  const onMarkerDrag = (event: MarkerDragEvent) => {
    props.onMovePin(event.lngLat.lat, event.lngLat.lng)
  }

  const returnToMarker = () => {
    setMapState({
      latitude: props.pinLatitude,
      longitude: props.pinLongitude,
    })
  }

  const isMapOriginFarFromPin =
    getDistanceBetweenCoords(
      {
        latitude: props.pinLatitude,
        longitude: props.pinLongitude,
      },
      {
        latitude: mapState.latitude,
        longitude: mapState.longitude,
      },
    ) > RESET_BUTTON_DISTANCE_THRESHOLD_KMS

  const resetToMarkerButton = (
    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
      <div style={{ padding: theme.space.xs }}>
        <Button onClick={returnToMarker}>Return to Marker</Button>
      </div>
    </div>
  )

  const circleToPolygonPoints = props.fenceRadiusMetres
    ? circle([props.pinLongitude, props.pinLatitude], props.fenceRadiusMetres, {
        units: 'meters',
      })
    : null

  return (
    <DraggableMap
      setMapState={setMapState}
      width={props.width}
      height={props.height}
      mapState={mapState}
    >
      <Marker
        longitude={props.pinLongitude}
        latitude={props.pinLatitude}
        anchor="bottom"
        draggable
        onDrag={onMarkerDrag}
        onDragStart={() => setIsMarkerDragging(true)}
        onDragEnd={() => setIsMarkerDragging(false)}
      >
        <Location
          mouseDownColor={theme.colors.Violet20}
          mouseDownStrokeColor={theme.colors.Violet}
          isMouseDown={isMarkerDragging}
        />
      </Marker>
      {circleToPolygonPoints && (
        <Source
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: circleToPolygonPoints?.geometry,
              },
            ],
          }}
        >
          <Layer
            {...{
              id: 'data',
              type: 'fill',
              paint: {
                'fill-color': theme.colors.Violet70,
                'fill-opacity': 0.4,
              },
            }}
          />
        </Source>
      )}
      {isMapOriginFarFromPin && resetToMarkerButton}
    </DraggableMap>
  )
}
