import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Shift } from '@traba/types'
import { JobStatus } from '@traba/types'
import { CompletedAdjustments, HandleAdjustmentParams } from '@traba/types'
import { FutureAdjustmentForm } from 'src/components/AdjustmentForm/FutureShiftAdjustmentForm'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getQueryParams } from 'src/hooks/useApi'
import { extractOptionalDate } from 'src/utils/dateUtils'
import { AdjustmentForm } from '../../components/AdjustmentForm/AdjustmentForm'

type BulkAdjustModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShifts: WorkerShift[]
  shift: Shift
}

export default function BulkAdjustModal({
  workerShifts,
  isOpen,
  handleClose,
  shift,
}: BulkAdjustModalProps) {
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()

  const handleAdjustment = async ({
    shouldAdjustPayment,
    shouldInstantPay,
    adjustmentReason,
    clockInTime,
    clockOutTime,
    breaks,
    minPaidTime,
    unitsWorked,
    breakType,
    payRate,
    adjustmentLabel,
  }: HandleAdjustmentParams) => {
    const queryString = getQueryParams([
      ['shouldAdjustPayment', shouldAdjustPayment.toString()],
      ['shouldInstantPay', shouldInstantPay.toString()],
    ])
    const clockInTimeValue = extractOptionalDate(clockInTime, undefined)
    const clockOutTimeValue = extractOptionalDate(clockOutTime, undefined)

    const shiftId = workerShifts[0].shiftId
    const result = await trabaApi.post(
      `/workers/worker-shifts/${shiftId}/adjustments${
        shouldAdjustPayment ? `${queryString}` : ''
      }`,
      {
        workerIds: workerShifts.map((ws) => ws.workerId),
        workerShiftAdjustment: {
          adjustmentReason,
          adjustmentLabel,
          source: `OPS`,
          clockInTime: clockInTimeValue,
          clockOutTime: clockOutTimeValue,
          breaks,
          unitsWorked,
          shiftInfo: {
            payRate,
            minimumPaidTime: minPaidTime,
            breakType,
          },
        },
      },
    )
    const { failedWorkerIdsAndReasons } = result.data as CompletedAdjustments
    const workerIds = failedWorkerIdsAndReasons
      .filter(Boolean)
      .map(([workerId]: [string, string]) => workerId)
      .join(', ')
    if (failedWorkerIdsAndReasons.length > 0) {
      showError(workerIds, 'Error Adjusting Workers')
    }
    refetchActiveQueries()
    handleClose()
  }
  const isFutureAdjustment = workerShifts.every(
    (workerShift) => workerShift.jobStatus === JobStatus.ToDo,
  )
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Make Adjustment for ${workerShifts.length} Worker${
        workerShifts.length > 1 ? 's' : ''
      }`}
      size={isFutureAdjustment ? MODAL_SIZE.EXTRA_SMALL : MODAL_SIZE.LARGE}
    >
      {isFutureAdjustment ? (
        <FutureAdjustmentForm
          handleClose={handleClose}
          shift={shift}
          workerShifts={workerShifts}
        />
      ) : (
        <AdjustmentForm
          handleClose={handleClose}
          handleAdjustment={handleAdjustment}
          workerShifts={workerShifts}
          unitsPerWorker={
            shift.numberOfUnits
              ? shift.numberOfUnits / shift.slotsRequested
              : undefined
          }
        />
      )}
    </Modal>
  )
}
