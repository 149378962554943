import {
  FAVORITE_WORKERS,
  HAS_WORKED_AS_ROLE,
  HAS_WORKED_AT_LOCATION,
  HAS_WORKED_WITH_COMPANY,
} from '@traba/consts'
import { Roster, WorkerShiftAvailability } from '@traba/types'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { RosterInfo, RosterMap } from './types'

/**
 * Extracts all unique worker IDs from a business's:
 *   - Rosters + virtual rosters
 * and returns them as an array of strings.
 */
export const getUniqueWorkerIds = (rosters: Roster[]): string[] => {
  const workerIdSet = new Set<string>()

  for (const roster of rosters) {
    for (const worker of roster.workers) {
      workerIdSet.add(worker.workerId)
    }
  }

  return Array.from(workerIdSet)
}

/**
 * Given a list of worker IDs, return a list of complete worker objects
 * pertaining to those worker IDs.
 */
const expandWorkerIdsToWorkers = (
  workerIds: string[],
  allWorkers: PopulatedWorker[],
) => {
  return allWorkers.filter((worker) => workerIds.includes(worker.id || ''))
}

/**
 * Given:
 *   1. A list of the business's rosters + virtual rosters
 *
 * ...this function combines the three, returning a map of roster IDs to a list
 * of complete PopulatedWorker objects associated with that roster.
 */
export const createRosterMap = (
  rosters: Roster[],
  allWorkers: PopulatedWorker[],
  workerAvailabilitiesByWorkerId?: Map<string, WorkerShiftAvailability>,
): RosterMap => {
  const rosterMap: RosterMap = {}

  // Handle all rosters (except virtual rosters)
  for (const roster in rosters) {
    const rosterId = rosters[roster].id
    const workers = expandWorkerIdsToWorkers(
      rosters[roster].workers.map((worker) => worker.workerId),
      allWorkers,
    )
    rosterMap[rosterId] = {
      id: rosterId,
      name: rosters[roster].rosterName,
      workers,
      ...(workerAvailabilitiesByWorkerId && {
        availableWorkers: workers.filter((w) => {
          const workerId = w.id ?? w.uid
          const availability = workerAvailabilitiesByWorkerId?.get(workerId)
          return (
            availability === undefined ||
            !availability.isAlreadySignedUpForShift
          )
        }),
      }),
    }
  }

  return rosterMap
}

/**
 * Ensures the "favorites" roster appears first in a list of rosters.
 */
export const getRostersInOrder = (rosterMap: RosterMap) => {
  if (Object.keys(rosterMap).length === 0) {
    return []
  }

  const favoritesRosterInfo: RosterInfo = rosterMap[FAVORITE_WORKERS]
  const hasWorkedWithCompanyRosterInfo: RosterInfo =
    rosterMap[HAS_WORKED_WITH_COMPANY]
  const hasWorkedAtLocationRosterInfo: RosterInfo =
    rosterMap[HAS_WORKED_AT_LOCATION]
  const hasWorkedAsRoleRosterInfo: RosterInfo = rosterMap[HAS_WORKED_AS_ROLE]
  const otherRostersInfo: RosterInfo[] = Object.keys(rosterMap)
    .filter(
      (key) =>
        ![
          FAVORITE_WORKERS,
          HAS_WORKED_WITH_COMPANY,
          HAS_WORKED_AT_LOCATION,
          HAS_WORKED_AS_ROLE,
        ].includes(key),
    )
    .map((key) => rosterMap[key])
  return [
    favoritesRosterInfo,
    hasWorkedWithCompanyRosterInfo,
    hasWorkedAtLocationRosterInfo,
    hasWorkedAsRoleRosterInfo,
    ...otherRostersInfo,
  ]
}
