import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  CreateParentInvoiceGroupOps,
  ParentInvoiceGroup,
  ParentInvoiceGroupStatus,
  UpdateParentInvoiceGroup,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QueryParamArray } from 'src/types'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getQueryParams } from './useApi'

async function getActiveInvoiceGroupsForCompany(
  companyId: string,
): Promise<ParentInvoiceGroup[]> {
  try {
    const queryString = getQueryParams([
      ['companyId', companyId],
      ['status', ParentInvoiceGroupStatus.ACTIVE],
    ])
    const res = await trabaApi.get(`invoice-groups/ops/query/${queryString}`)
    return res.data
  } catch (error: any) {
    console.error(
      'useInvoiceGroups -> getActiveInvoiceGroupsForCompany ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
    return []
  }
}

async function createInvoiceGroup(
  createGroupData: CreateParentInvoiceGroupOps,
): Promise<ParentInvoiceGroup> {
  const res = await trabaApi.post(`invoice-groups/ops`, createGroupData)
  return res.data
}

async function editInvoiceGroup({
  groupId,
  updateGroupData,
}: {
  groupId: string
  updateGroupData: UpdateParentInvoiceGroup
}): Promise<ParentInvoiceGroup> {
  const res = await trabaApi.patch(
    `invoice-groups/ops/${groupId}`,
    updateGroupData,
  )
  return res.data
}

async function removeShiftsFromInvoiceGroup({
  groupId,
  shiftIds,
}: {
  groupId: string
  shiftIds: string[]
}) {
  const shiftIdsArray = [
    ...((shiftIds
      ? shiftIds?.map((shiftId) => ['shiftIds[]', shiftId])
      : []) as QueryParamArray),
  ]
  const queryString = getQueryParams([...shiftIdsArray])
  const res = await trabaApi.delete(
    `invoice-groups/ops/${groupId}/shifts${queryString}`,
  )
  return res.data
}

async function addShiftsToInvoiceGroup({
  groupId,
  shiftIds,
}: {
  groupId: string
  shiftIds: string[]
}): Promise<ParentInvoiceGroup> {
  const res = await trabaApi.put(`invoice-groups/ops/${groupId}`, {
    shiftIds,
  })
  return res.data
}

export function useInvoiceGroups(companyId: string) {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const {
    isLoading,
    isError,
    data: activeInvoiceGroups,
    error,
    isFetched,
    refetch,
  } = useQuery<ParentInvoiceGroup[], Error>(
    [`invoice-groups/active_${companyId}`],
    () => getActiveInvoiceGroupsForCompany(companyId),
  )

  const createInvoiceGroupMutation = useMutation<
    ParentInvoiceGroup,
    AxiosError,
    CreateParentInvoiceGroupOps
  >(createInvoiceGroup, {
    onSuccess: (newGroup: ParentInvoiceGroup) => {
      queryClient.setQueryData(
        'invoice-groups/active',
        (currActiveGroups: ParentInvoiceGroup[] | undefined) => {
          return currActiveGroups ? [...currActiveGroups, newGroup] : [newGroup]
        },
      )
    },
  })

  const editInvoiceGroupMutation = useMutation<
    ParentInvoiceGroup,
    AxiosError,
    {
      groupId: string
      updateGroupData: UpdateParentInvoiceGroup
    }
  >(editInvoiceGroup, {
    onSuccess: () => {
      refetch()
    },
  })

  const removeShiftsFromInvoiceGroupMutation = useMutation<
    any,
    AxiosError,
    {
      groupId: string
      shiftIds: string[]
    }
  >(removeShiftsFromInvoiceGroup, {
    onSuccess: () => {
      showSuccess('Shift(s) removed from invoice group', 'Success')
    },
    onError: (e: any) => {
      showError(
        getErrorMessage(e),
        'Error removing shift(s) from invoice group',
      )
    },
  })

  const addShiftsToInvoiceGroupMutation = useMutation<
    ParentInvoiceGroup,
    AxiosError,
    {
      groupId: string
      shiftIds: string[]
    }
  >(addShiftsToInvoiceGroup, {
    onSuccess: () => {
      showSuccess('Shift(s) added to invoice group', 'Success')
    },
    onError: (e: any) => {
      showError(getErrorMessage(e), 'Error adding shift(s) to invoice group')
    },
  })

  return {
    isLoading,
    isError,
    activeInvoiceGroups: activeInvoiceGroups ?? [],
    error,
    isFetched,
    refetch,
    createInvoiceGroup: createInvoiceGroupMutation.mutateAsync,
    editInvoiceGroup: editInvoiceGroupMutation.mutateAsync,
    removeShiftsFromInvoiceGroup:
      removeShiftsFromInvoiceGroupMutation.mutateAsync,
    addShiftsToInvoiceGroup: addShiftsToInvoiceGroupMutation.mutateAsync,
  }
}
