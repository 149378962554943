import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WorkerJobPlacementResponseByCompanyId,
  WorkerJobPlacementResponseByWorkerId,
  WorkerJobPlacementStatus,
} from '@traba/types'
import { useState, useMemo } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable } from 'src/components/base/Table/DataTable'
import { useWorkerJobPlacements } from 'src/hooks/useWorkerJobPlacements'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { WorkerJobPlacementCreateModal } from './WorkerJobPlacementCreateModal'
import { WorkerJobPlacementTerminateModal } from './WorkerJobPlacementTerminateModal'
interface WorkerJobPlacementsProps {
  workerId: string
  status: WorkerJobPlacementStatus
}

const headers = [
  { key: 'companyName', label: 'Company Name', sortable: true },
  { key: 'companyId', label: 'Company ID', sortable: true },
  {
    key: 'workerPlacementStartDate',
    label: 'Date & Time added',
    sortable: true,
  },
  { key: 'initiatedBy', label: 'Initiated By', sortable: true },
  { key: 'action', label: 'Action', sortable: false },
]

function isWorkerJobPlacementByWorkerId(
  jobPlacement:
    | WorkerJobPlacementResponseByWorkerId
    | WorkerJobPlacementResponseByCompanyId,
): jobPlacement is WorkerJobPlacementResponseByWorkerId {
  return (
    (jobPlacement as WorkerJobPlacementResponseByWorkerId).employerName !==
      undefined &&
    (jobPlacement as WorkerJobPlacementResponseByWorkerId)
      .workerJobPlacement !== undefined
  )
}
export function WorkerJobPlacements({
  workerId,
  status,
}: WorkerJobPlacementsProps) {
  const { isLoading, jobPlacements, refetch } = useWorkerJobPlacements(
    workerId,
    status,
  )

  const createWorkerJobPlacementModal = useModal()
  const terminateWorkerJobPlacementModal = useModal()
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined)
  const [selectedCompanyName, setSelectedCompanyName] = useState('')

  const tableRows = useMemo(() => {
    return (
      jobPlacements?.map((jobPlacement) => {
        const { id, companyId, startDate, endDate, initiatedBy } =
          jobPlacement.workerJobPlacement

        const workerPlacementStartDate = new Date(startDate)
        const workerPlacementEndDate = endDate ? new Date(endDate) : undefined

        const displayName = isWorkerJobPlacementByWorkerId(jobPlacement)
          ? jobPlacement.employerName
          : `${jobPlacement.employeeFirstName} ${jobPlacement.employeeLastName}`

        return {
          key: id,
          cells: [
            {
              renderFn: () => (
                <Row justifyBetween>
                  <Link to={`/companies/${companyId}`} target="_blank">
                    <Button variant={ButtonVariant.TEXT}>{displayName}</Button>
                  </Link>
                </Row>
              ),
              sortKey: displayName,
            },
            {
              renderFn: () => (
                <>
                  {truncateString(companyId, 6)}
                  <CopyTextIcon textToCopy={companyId} />
                </>
              ),
              sortKey: companyId,
            },
            {
              renderFn: () =>
                status === WorkerJobPlacementStatus.ACTIVE_WORKER
                  ? formatDateTimeWithTimezone(workerPlacementStartDate)
                  : workerPlacementEndDate
                    ? formatDateTimeWithTimezone(workerPlacementEndDate)
                    : 'No end date provided',
              sortKey: Date.parse(startDate.toString()),
            },
            {
              renderFn: () => initiatedBy,
              sortKey: initiatedBy,
            },
            {
              renderFn: () => (
                <Row justifyBetween>
                  <Button
                    style={{
                      color: 'currentColor',
                      textDecoration: 'underline',
                    }}
                    variant={ButtonVariant.TEXT}
                    onClick={() => {
                      setSelectedCompanyId(companyId)
                      setSelectedCompanyName(displayName)
                      terminateWorkerJobPlacementModal.open()
                      refetch()
                    }}
                  >
                    {status === WorkerJobPlacementStatus.ACTIVE_WORKER
                      ? 'Terminate'
                      : undefined}
                  </Button>
                </Row>
              ),
            },
          ],
        }
      }) ?? []
    )
  }, [jobPlacements, status, refetch, terminateWorkerJobPlacementModal])

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  return (
    <>
      <Row pb={theme.space.xs} fullWidth alignCenter justifyBetween>
        <Text variant="h5">
          {status === WorkerJobPlacementStatus.ACTIVE_WORKER
            ? 'Hired'
            : 'Terminated'}
        </Text>
        {status === WorkerJobPlacementStatus.ACTIVE_WORKER && (
          <Button
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={createWorkerJobPlacementModal.open}
          >
            Add Company
          </Button>
        )}
      </Row>
      <DataTable
        initialSortByColumnIndex={0}
        rows={tableRows}
        headers={headers}
      />
      <WorkerJobPlacementCreateModal
        workerId={workerId}
        isOpen={createWorkerJobPlacementModal.isOpen}
        handleClose={createWorkerJobPlacementModal.handleClose}
      />
      {selectedCompanyId && (
        <WorkerJobPlacementTerminateModal
          workerId={workerId}
          companyName={selectedCompanyName}
          companyId={selectedCompanyId}
          isOpen={terminateWorkerJobPlacementModal.isOpen}
          handleClose={terminateWorkerJobPlacementModal.handleClose}
        />
      )}
    </>
  )
}
