import { Checkbox, CircularProgress } from '@mui/material'
import { Tooltip } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerEditStatus } from '@traba/types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { UserRolePermission } from 'src/context/user/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import {
  EarningsSummaryResponse,
  useEarnings,
} from 'src/hooks/useEarningsSummary'
import { usePermissions } from 'src/hooks/usePermissions'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useWorkerShiftEarning } from 'src/hooks/useWorkers'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import { Badge, Button, Col, Icon, Row } from '../base'
import { ButtonVariant } from '../base/Button/types'
import CopyTextIcon from '../base/CopyTextIcon/CopyTextIcon'
import { DotText } from '../base/DotText'
import JobStatusBadge from '../JobStatusBadge'
import { WorkerPaymentTable } from '../WorkerPaymentTable'
import EarningsFormattedPayment from './components/EarningsFormattedPayment'
import { earningsPaymentBadgeVariant } from './EarningsSummaryItem.helpers'
import * as S from './EarningsSummaryItem.styles'
import { EarningsSummaryItemTable } from './EarningsSummaryItemTable'
import { WorkerEditedTimeView } from './WorkerEditedTimeView'

export interface EarningsSummaryItemProps {
  earningsSummary: EarningsSummaryResponse
  isLoading?: boolean
  isSelected?: boolean
  onSelect?: (
    earningsSummary: EarningsSummaryResponse,
    isSelected: boolean,
  ) => void
  shouldInstantPay: boolean
  setPayoutResponses?: React.Dispatch<
    React.SetStateAction<
      | {
          fulfilled: any[]
          rejected: any[]
        }
      | undefined
    >
  >
  setSelectedEarningsForWorkerShiftAdjustment: (
    earningSummaryResponse: EarningsSummaryResponse,
  ) => void
  selectable?: boolean
  hideEarningSummary?: boolean
}

export default function EarningsSummaryItem({
  earningsSummary,
  isLoading,
  isSelected,
  onSelect,
  shouldInstantPay,
  setPayoutResponses,
  selectable = true,
  setSelectedEarningsForWorkerShiftAdjustment,
  hideEarningSummary,
}: EarningsSummaryItemProps) {
  const { workerShift } = earningsSummary
  const { shift } = workerShift

  const { paymentLoading, handlePayout } = useEarnings()
  const { showSuccess, showError } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()

  const handleSelectClick = () => {
    if (selectable && onSelect) {
      onSelect(earningsSummary, !isSelected)
    }
  }

  const [collapsed, setCollapsed] = useState<boolean>(true)
  const tz = useTimezonedDates(shift.timezone)
  const stripeAccess = usePermissions([UserRolePermission.StripeAccess])

  const { totalOwed, totalPaid } = earningsSummary

  const shiftNeedsPaymentFix = totalPaid !== totalOwed

  const {
    workerEarning,
    refetchWorkerEarning,
    isLoading: workerEarningLoading,
  } = useWorkerShiftEarning(earningsSummary.workerId, earningsSummary.shiftId)

  const workerId = earningsSummary.workerId
  const shiftId = earningsSummary.shiftId

  const handlePayment = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const isConfirmed = window.confirm(
      `Do you want to payout this worker shift?`,
    )
    if (!isConfirmed) {
      return
    }
    const res = await handlePayout([earningsSummary], shouldInstantPay)
    event.stopPropagation()
    if (setPayoutResponses) {
      setPayoutResponses(res)
    }
  }

  const [resyncLoading, setResyncLoading] = useState<boolean>(false)
  const handleResync = async () => {
    setResyncLoading(true)
    try {
      await trabaApi.post(`payments/worker/${workerId}/shift/${shiftId}/resync`)
      showSuccess(
        `Resynced for worker ${workerId} shift ${shiftId}`,
        'Payment Resynced Successfully',
      )
      await refetchActiveQueries()
    } catch (e) {
      showError(getErrorMessage(e), 'Error Resyncing Payment')
    } finally {
      setResyncLoading(false)
    }
  }

  const hasAnyInstantPayFlag = Object.values(
    workerShift?.endShiftFlags?.instantPayFlags ?? [],
  ).some((flag) => flag === true)

  const [showEarningSummary, setShowEarningSummary] =
    useState<boolean>(!hideEarningSummary)

  return (
    <S.EarningsSummaryItemContainer>
      <label htmlFor={`checkbox-${earningsSummary.workerShiftId}`}>
        <Row
          fullWidth
          alignCenter
          justifyBetween
          style={selectable ? { cursor: 'pointer' } : {}}
        >
          {selectable && (
            <Checkbox
              id={`checkbox-${earningsSummary.workerShiftId}`}
              onChange={handleSelectClick}
              checked={isSelected}
            />
          )}
          <Col>
            <Row alignCenter>
              <Link to={`/workers/${earningsSummary.workerId}`} target="_blank">
                <Button
                  textColor={theme.colors.brand}
                  textHoverColor={theme.colors.MidnightBlue}
                  style={{ padding: 0 }}
                  variant={ButtonVariant.TEXT}
                >
                  {workerShift?.worker?.firstName}{' '}
                  {workerShift?.worker?.lastName}
                </Button>
              </Link>
              <CopyTextIcon textToCopy={earningsSummary.workerId} />
              <DotText />
              <Link to={`/field-monitor/${earningsSummary.shiftId}`}>
                <Button
                  textColor={theme.colors.brand}
                  textHoverColor={theme.colors.MidnightBlue}
                  style={{ padding: 0 }}
                  variant={ButtonVariant.TEXT}
                >
                  {shift.role?.roleName} at {shift.company?.employerName}
                </Button>
              </Link>
              <CopyTextIcon textToCopy={earningsSummary.shiftId} />
              <EarningsFormattedPayment
                transferredPay={earningsSummary.totalPaid}
                owedPay={earningsSummary.totalOwed}
              />
              <Row ml={theme.space.xs} gap={theme.space.xxxs}>
                <JobStatusBadge
                  jobStatus={earningsSummary.workerShift.jobStatus}
                />
                <Tooltip title={earningsSummary.paymentStatusReason ?? ''}>
                  <div>
                    <Badge
                      title={earningsSummary.paymentStatus}
                      variant={
                        earningsPaymentBadgeVariant[
                          earningsSummary.paymentStatus
                        ]
                      }
                    />
                  </div>
                </Tooltip>

                {workerShift.rulesSummary?.exceedsThreshold && (
                  <Badge title="Warden (Legacy)" variant="brand" />
                )}
                {!workerShift.rulesSummary &&
                  !workerShift.wardenResult?.shouldProcessPayment && (
                    <Badge title="SuperWarden" variant="brand" />
                  )}
                {workerShift.isBackfill && (
                  <Badge title="Backfill" variant="warning" />
                )}
                {workerShift.workerEditStatus === WorkerEditStatus.FLAGGED && (
                  <Badge title="Worker Edited" variant="warning" />
                )}
                {hasAnyInstantPayFlag && (
                  <Tooltip
                    title={`Instant Pay was flagged with the following: ${Object.entries(
                      workerShift?.endShiftFlags?.instantPayFlags ?? {},
                    )
                      .filter(([_key, value]) => value === true)
                      .map(([key]) => key)
                      .join(', ')}`}
                  >
                    <div>
                      <Badge title="IP Flagged" variant="blue" />
                    </div>
                  </Tooltip>
                )}
                {workerShift?.endShiftFlags?.instantPayFlags
                  ?.workerReportedEndTime && (
                  <Tooltip title="Instant Pay for this shift was flagged as the worker reported their own clock out time after leaving the shift geofence.">
                    <div>
                      <Badge title="Geo Flagged" variant="orange" />
                    </div>
                  </Tooltip>
                )}
              </Row>
            </Row>
            <Row alignCenter>
              <Text>{tz.getShiftDate(shift.startTime, shift.endTime)} </Text>
              <DotText />
              <Text> {tz.getShiftTime(shift.startTime, shift.endTime)} </Text>
              <DotText />
              <Text>{earningsSummary.shiftId}</Text>
            </Row>
          </Col>
          <Row>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={handleResync}
              loading={resyncLoading}
              mr={theme.space.xxs}
            >
              <Icon name="refresh" />
            </Button>
            <Button
              variant={ButtonVariant.OUTLINED}
              mr={theme.space.xs}
              onClick={() =>
                setSelectedEarningsForWorkerShiftAdjustment(earningsSummary)
              }
              style={{ marginRight: theme.space.xs }}
            >
              Adjust Shift
            </Button>
            {shiftNeedsPaymentFix && (
              <Button
                mr={theme.space.xs}
                loading={paymentLoading}
                onClick={(event) => {
                  handlePayment(event)
                }}
                disabled={!stripeAccess || isLoading}
              >
                Payout {getMoneyString((totalOwed - totalPaid) / 100)}
              </Button>
            )}
            <Button
              variant={ButtonVariant.OUTLINED}
              style={{
                width: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={(event) => {
                setCollapsed(!collapsed)
                refetchWorkerEarning()
                event.stopPropagation()
              }}
            >
              <Icon
                type="svg"
                size={12}
                name={collapsed ? 'chevronDown' : 'chevronUp'}
              />
            </Button>
          </Row>
        </Row>
      </label>
      {!collapsed && (
        <Col mt={theme.space.sm}>
          <EarningsSummaryItemTable
            workerShiftId={earningsSummary.workerShiftId}
            earningSummaryWorkerShift={workerShift}
          />

          {workerShift.workerEditStatus === WorkerEditStatus.FLAGGED && (
            <WorkerEditedTimeView earningSummaryWorkerShift={earningsSummary} />
          )}

          <Row>
            <Button
              variant={ButtonVariant.TEXT}
              textColor={theme.colors.brand}
              textHoverColor={theme.colors.brand}
              rightIcon={
                <Icon
                  type="svg"
                  color={theme.colors.brand}
                  name={showEarningSummary ? 'chevronUp' : 'chevronDown'}
                />
              }
              onClick={() => setShowEarningSummary(!showEarningSummary)}
            >
              {`${showEarningSummary ? 'Hide' : 'Show'} earning history`}
            </Button>
          </Row>
          {showEarningSummary && (
            <Row alignCenter justifyCenter>
              {workerEarningLoading ? (
                <CircularProgress size={36} sx={{ position: 'absolute' }} />
              ) : (
                workerEarning && (
                  <WorkerPaymentTable
                    workerEarning={workerEarning}
                    totalPaid={earningsSummary.totalPaid}
                    refetchWorkerEarnings={refetchWorkerEarning}
                    key={`table_${earningsSummary.workerShiftId}_${earningsSummary.totalOwed}_${earningsSummary.totalPaid}`}
                  />
                )
              )}
            </Row>
          )}
        </Col>
      )}
    </S.EarningsSummaryItemContainer>
  )
}
