import { useAlert } from '@traba/context'
import {
  Dialog,
  MODAL_SIZE,
  Row,
  SearchSelect,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { DrugPanelType, IMenuItem } from '@traba/types'
import { addDays } from 'date-fns'
import { useState } from 'react'
import { DatePicker } from 'src/components/base'
import { useCompanies } from 'src/hooks/useCompanies'
import { useOrderNewDrugScreeningOrder } from 'src/hooks/useDrugScreening'

interface DrugScreeningOrderModalProps {
  showModal: boolean
  onCloseModal: () => void
  workerId: string
  workerName: string
}

export const DrugScreeningOrderModal = ({
  showModal,
  onCloseModal,
  workerId,
  workerName,
}: DrugScreeningOrderModalProps) => {
  const { showError, showSuccess } = useAlert()
  const { companies } = useCompanies({
    identifiersOnly: true,
    isApproved: true,
  })

  const startOfToday = new Date().setHours(0, 0, 0, 0)
  const [selectedPanelType, setSelectedPanelType] = useState<IMenuItem>()
  const [selectedCompanyId, setSelectedCompanyId] = useState<IMenuItem>()
  const [expirationDate, setExpirationDate] = useState<Date>(
    addDays(startOfToday, 4),
  )

  const drugPanelOptions = Object.entries(DrugPanelType).map(
    ([key, value]) => ({
      label: key.replace(/_/g, ' '),
      value,
    }),
  )
  const companyOptions =
    companies?.map((company) => ({
      label: company.employerName,
      value: company.id,
    })) ?? []

  const resetStates = () => {
    setSelectedPanelType(undefined)
    setSelectedCompanyId(undefined)
    setExpirationDate(addDays(startOfToday, 4))
  }

  const { mutate: createDrugScreeningOrder } = useOrderNewDrugScreeningOrder()

  const onConfirm = async () => {
    if (!selectedPanelType) {
      showError('Please select a drug panel type')
      return
    }

    createDrugScreeningOrder(
      {
        workerId,
        drugPanelType: selectedPanelType.value as DrugPanelType,
        requestingCompanyId: selectedCompanyId?.value,
        expirationDays: expirationDate.getDate() - new Date().getDate(),
      },
      {
        onSuccess: () => {
          showSuccess('Drug screening order created successfully')
          resetStates()
          onCloseModal()
        },
        onError: (error) => {
          showError(`Error creating drug screening order: ${error.message}`)
        },
      },
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showModal}
      onClose={() => {
        resetStates()
        onCloseModal()
      }}
      onConfirmCTA="Order"
      dialogTitle={`Order New Drug Screen for ${workerName}`}
      formId="create-drug-screen-order"
      confirmDisabled={!selectedPanelType}
      onConfirm={onConfirm}
      size={MODAL_SIZE.MEDIUM}
    >
      <Row flexCol gap={theme.space.xs}>
        <Row flexCol gap={theme.space.xs}>
          <Text variant="h6">Drug Panel Type</Text>
          <SearchSelect
            options={drugPanelOptions}
            selectItem={selectedPanelType}
            handleSelect={setSelectedPanelType}
            label="Select Type"
            required
          />
        </Row>

        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6">Requesting Business</Text>
          <SearchSelect
            options={companyOptions}
            selectItem={selectedCompanyId}
            handleSelect={setSelectedCompanyId}
            label="Select Business"
            required
          />
        </Row>

        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6">Last Date to finish drug screening</Text>
          <DatePicker
            date={expirationDate}
            setDate={(newDate) => newDate && setExpirationDate(newDate)}
            isClearable={false}
            aria-label="Expiration Date"
            isRequired={true}
            granularity="day"
            defaultDate={expirationDate}
            minDate={addDays(startOfToday, 4)}
            maxDate={addDays(startOfToday, 11)}
          />
        </Row>
      </Row>
    </Dialog>
  )
}
