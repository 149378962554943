import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftWithSlimCharges } from '@traba/types'
import { Row, Col, CopyTextIcon } from 'src/components/base'
import InvoiceCreatorShiftsTable from '../InvoiceCreatorShiftsTable'

type InvoiceGroupSectionProps = {
  groupName: string
  groupId: string
  shifts: ShiftWithSlimCharges[]
  selected: boolean
  onSelect: () => void
  selectedShifts: ShiftWithSlimCharges[]
  onSelectShiftIds: (shiftIds: string[]) => void
}

export default function InvoiceGroupSection(props: InvoiceGroupSectionProps) {
  const { groupName, groupId, shifts, selectedShifts, onSelectShiftIds } = props
  return (
    <Col
      style={{
        border: '1px solid',
        borderColor: theme.colors.Grey20,
        borderRadius: '4px',
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        paddingTop: theme.space.xs,
        paddingBottom: theme.space.xs,
      }}
    >
      <Row>
        <Col>
          <Text variant="h5">{groupName}</Text>
          <Text variant="label">
            ID: {groupId} <CopyTextIcon textToCopy={groupId} />
          </Text>
        </Col>
      </Row>
      <Row mt={theme.space.xxs}>
        <InvoiceCreatorShiftsTable
          shifts={shifts}
          selectedShifts={selectedShifts}
          onSelectShiftIds={onSelectShiftIds}
        />
      </Row>
    </Col>
  )
}
