import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'

import { SetStateAction } from 'react'
import { Button, Input, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'

import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'

interface Props {
  handleDuplicateShift: () => Promise<void>
  duplicateShiftIdInput: string
  setDuplicateShiftIdInput: (value: SetStateAction<string>) => void
  duplicateLoading: boolean
}

export const DuplicateShiftSection = ({
  handleDuplicateShift,
  setDuplicateShiftIdInput,
  duplicateShiftIdInput,
  duplicateLoading,
}: Props) => {
  return (
    <ShiftPostingInputContainer title="Duplicate shift">
      <Row alignCenter justifyCenter fullWidth>
        <ShiftPostingInputContainerSection
          label="Shift ID"
          input={
            <Row style={{ minWidth: '25%' }}>
              <Input
                value={duplicateShiftIdInput}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => setDuplicateShiftIdInput(e.target.value)}
                full
              />
            </Row>
          }
        />
        <Tooltip
          title={
            duplicateShiftIdInput
              ? ''
              : 'Need to input a shift ID to duplicate from '
          }
        >
          <Button
            onClick={handleDuplicateShift}
            variant={ButtonVariant.FILLED}
            style={{ marginLeft: theme.space.xs }}
            disabled={!duplicateShiftIdInput}
            loading={duplicateLoading}
          >
            Duplicate
          </Button>
        </Tooltip>
      </Row>
    </ShiftPostingInputContainer>
  )
}
