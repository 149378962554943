import { ModalButtons } from '@traba/react-components'
import { useState } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'

type ConfirmVettingCampaignSyncModalProps = {
  handleClose: () => void
  isOpen: boolean
  onSync: () => Promise<void>
}

export const ConfirmVettingCampaignSyncModal = ({
  handleClose,
  isOpen,
  onSync,
}: ConfirmVettingCampaignSyncModalProps) => {
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    try {
      await onSync()
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_EXTRA_SMALL}
      title="Sync vetting data to worker profiles?"
    >
      <ModalButtons
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        loading={loading}
      />
    </Modal>
  )
}
