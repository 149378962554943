import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  ChatReadStatus,
  ChatSummariesResponse,
  ChatSummary,
  UpdateChatRequest,
} from '@traba/types'
import { useQuery } from 'react-query'
import { getErrorMessage } from 'src/utils/errorUtils'

const getChats = async ({
  companyId,
  workerId,
}: {
  companyId?: string
  workerId?: string
}) => {
  let path = '/chats'
  if (companyId) {
    path += `?displayingToCompany=true&companyId=${companyId}`
  } else if (workerId) {
    path += `?displayingToCompany=false&workerId=${workerId}`
  }

  const response = await trabaApi.get(path)
  return response.data
}

export const updateChat = async (
  request: UpdateChatRequest,
  summaryIds: string[],
) => {
  const response = await trabaApi.patch(
    `/chats?summaryIds=${summaryIds}`,
    request,
  )
  return response.data
}

export const useChats = (request: {
  companyId?: string
  workerId?: string
}) => {
  const { showError } = useAlert()
  const {
    isLoading,
    isError,
    data: chats,
    refetch,
    error,
    isFetched,
  } = useQuery<ChatSummariesResponse, Error>(['chats', request.companyId], () =>
    getChats(request),
  )

  if (isError) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useChats ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    showError(
      fullErrorMessage,
      `Error Fetching chats for company ${request.companyId ?? ''} and worker ${
        request.workerId ?? ''
      }`,
    )
  }

  return {
    isLoading,
    isError,
    error,
    isFetched,
    refetch,
    chats: chats?.summaries,
    unreadChats: chats?.summaries.filter(
      (chat: ChatSummary) => chat.readStatus === ChatReadStatus.UNREAD,
    ),
  }
}
