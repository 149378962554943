import { useAlert } from '@traba/context'
import { RadioButton, SelectDropdown, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BreakType, Company } from '@traba/types'
import { getBreakOptions } from '@traba/utils'
import { SetStateAction, useState } from 'react'
import { Button, Col, Row } from 'src/components/base'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import Toggle from 'src/components/base/Toggle'
import { useHotSettings } from 'src/hooks/useHotSettings'

type BreaksSettingsSectionProps = {
  company: Company
  handleSaveButton(
    values: Partial<Company>,
    setLoadingState: (value: SetStateAction<boolean>) => void,
  ): Promise<void>
}

export default function BreaksSettingsSection({
  company,
  handleSaveButton,
}: BreaksSettingsSectionProps) {
  const { hotSettings } = useHotSettings()
  const [defaultBreaksCount, setDefaultBreaksCount] = useState<
    number | undefined
  >(company.defaultBreaks?.count)
  const [defaultBreaksLength, setDefaultBreaksLength] = useState<
    number | undefined
  >(company.defaultBreaks?.breakLength)
  const [defaultBreakType, setDefaultBreakType] = useState<
    BreakType | undefined
  >(company.breakType)
  const [autoDeductManualBreaks, setAutoDeductManualBreaks] = useState<
    boolean | undefined
  >(company.autoDeductManualBreaks)
  const [loading, setLoading] = useState<boolean>(false)

  const { showError } = useAlert()

  const handleDefaultBreaksSave = () => {
    if (
      (defaultBreaksCount === undefined && defaultBreaksLength !== undefined) ||
      (defaultBreaksCount !== undefined && defaultBreaksLength === undefined)
    ) {
      showError(
        'Both Default Breaks Count and Default Breaks Length must be set or unset together',
        'Error',
      )
      return
    }
    handleSaveButton(
      {
        defaultBreaks:
          defaultBreaksCount !== undefined && defaultBreaksLength !== undefined
            ? {
                count: defaultBreaksCount,
                breakLength: defaultBreaksLength,
              }
            : null,
        breakType:
          defaultBreakType !== company.breakType ? defaultBreakType : undefined,
        autoDeductManualBreaks:
          autoDeductManualBreaks !== company.autoDeductManualBreaks
            ? autoDeductManualBreaks
            : undefined,
      },
      (loading) => {
        setLoading(loading)
      },
    )
  }

  const isBreakUnchanged =
    defaultBreaksCount === company.defaultBreaks?.count &&
    defaultBreaksLength === company.defaultBreaks?.breakLength &&
    defaultBreakType === company.breakType &&
    autoDeductManualBreaks === company.autoDeductManualBreaks
  const isBreakSaveInvalid =
    (defaultBreakType === BreakType.AUTO_UNPAID ||
      defaultBreakType === BreakType.MANUAL_UNPAID) &&
    (defaultBreaksCount === undefined || defaultBreaksLength === undefined)
  const isBreakSaveDisabled = isBreakUnchanged || isBreakSaveInvalid

  return (
    <>
      <Text variant="h4" mt={theme.space.sm}>
        Default Breaks Settings
      </Text>
      <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
        Break type:
      </Text>
      <Col
        style={{
          display: 'flex',
          flex: '0 1 auto',
          rowGap: theme.space.xs,
        }}
      >
        <Row
          alignCenter
          style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
          onClick={() => {
            setDefaultBreakType(BreakType.AUTO_UNPAID)
            setAutoDeductManualBreaks(false)
          }}
        >
          <RadioButton selected={defaultBreakType === BreakType.AUTO_UNPAID} />
          <Col>
            <Text variant="h5">Default auto unpaid breaks</Text>
            <Text variant="body1">
              Auto unpaid breaks are automatically added to the worker shifts
            </Text>
          </Col>
        </Row>
        {hotSettings?.enableReturnOfManualBreak && (
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => {
              setDefaultBreakType(BreakType.MANUAL_UNPAID)
            }}
          >
            <RadioButton
              selected={defaultBreakType === BreakType.MANUAL_UNPAID}
            />
            <Col>
              <Text variant="h5">Manual breaks</Text>
              <Text variant="body1">
                Workers must clock in and out for their breaks, they will not be
                auto added to the worker shift. If the "Auto add if a worker
                forgets" toggle is on, the default length unpaid break will be
                added, if off and the worker forgets, no break will be added to
                the worker shift.
              </Text>
            </Col>
          </Row>
        )}
        <Row
          alignCenter
          style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
          onClick={() => {
            setDefaultBreakType(BreakType.NONE)
            setDefaultBreaksLength(undefined)
            setDefaultBreaksCount(undefined)
            setAutoDeductManualBreaks(false)
          }}
        >
          <RadioButton
            selected={
              defaultBreakType !== BreakType.AUTO_UNPAID &&
              defaultBreakType !== BreakType.MANUAL_UNPAID
            }
          />
          <Col>
            <Text variant="h5">None</Text>
            <Text variant="body1">
              No default break type. This will reset the default length and
              default count.
            </Text>
          </Col>
        </Row>
        {/* NOTE: we are purposely not showing PAID break type because it is not being used. We may want to add it back if we allow businesses to use them again. */}
      </Col>
      <Row
        // alignCenter
        mr={theme.space.sm}
        mt={theme.space.sm}
        mb={theme.space.sm}
      >
        <Col>
          <Text
            variant="h6"
            style={{
              marginBottom: theme.space.xxs,
            }}
          >
            Default Length
          </Text>
          <SelectDropdown
            style={{ width: 200 }}
            menuItems={getBreakOptions()}
            placeholder="Duration"
            value={defaultBreaksLength?.toString() || 'N/A'}
            handleSelect={(val) => {
              if (!setDefaultBreaksLength) {
                return
              }
              const breakLength = parseInt(val)
              setDefaultBreaksLength(breakLength)
            }}
            disabled={defaultBreakType === BreakType.NONE}
          />
        </Col>
        <Col>
          <Text
            variant="h6"
            style={{
              marginBottom: theme.space.xxs,
            }}
          >
            Default Count
          </Text>
          <NumberInput
            width={'200px'}
            value={defaultBreaksCount}
            setValue={setDefaultBreaksCount}
            disabled={defaultBreakType === BreakType.NONE}
          />
        </Col>
      </Row>

      {hotSettings?.enableReturnOfManualBreak && (
        <Row style={{ marginBottom: theme.space.sm }}>
          <Toggle
            label="Auto add if a worker forgets (manual break)"
            buttonState={autoDeductManualBreaks ?? false}
            runOnChange={() => setAutoDeductManualBreaks((prev) => !prev)}
            disabled={defaultBreakType !== BreakType.MANUAL_UNPAID}
          />
        </Row>
      )}

      <Button
        mb={theme.space.sm}
        style={{ width: 200, height: 50 }}
        loading={loading}
        disabled={isBreakSaveDisabled}
        onClick={handleDefaultBreaksSave}
      >
        Save
      </Button>
    </>
  )
}
