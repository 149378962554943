import {
  PayoutMethod,
  PayoutStatus,
  TransactionType,
  TransferStatus,
} from '@traba/types'
import { FilterFieldType } from 'src/hooks/useFilteredData'

export const transactionsTableFilterFields = [
  {
    key: 'workerName',
    label: 'Worker Name',
    type: FilterFieldType.Text,
  },
  {
    key: 'status',
    label: 'Status',
    type: FilterFieldType.Select,
    options: [
      {
        label: 'Failed',
        value: TransferStatus.FAILED,
      },
      {
        label: 'Paid',
        value: TransferStatus.PAID,
      },
      {
        label: 'Pending',
        value: TransferStatus.PENDING,
      },
      {
        label: 'Reversed',
        value: TransferStatus.REVERSED,
      },
      {
        label: 'Succeeded',
        value: TransferStatus.SUCCEEDED,
      },
      {
        label: 'Canceled',
        value: PayoutStatus.CANCELED,
      },
      {
        label: 'In Transit',
        value: PayoutStatus.IN_TRANSIT,
      },
    ],
  },
  {
    key: 'method',
    label: 'Instant Pay?',
    type: FilterFieldType.Select,
    options: [
      {
        label: 'Yes',
        value: PayoutMethod.INSTANT_PAYOUT,
      },
      {
        label: 'No',
        value: PayoutMethod.STANDARD,
      },
    ],
  },
  {
    key: 'type',
    label: 'Type',
    type: FilterFieldType.Select,
    options: [
      {
        label: 'Transfer',
        value: TransactionType.TRANSFER,
      },
      {
        label: 'Payout',
        value: TransactionType.PAYOUT,
      },
    ],
  },
]
