import { Menu, MenuItem } from '@mui/material'
import { theme } from '@traba/theme'
import React from 'react'
import { Button, Icon } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'

export default function MuteNotificationDropdown({
  isMutedForMe,
  isMutedForEveryone,
  onMuteForMe,
  onMuteForEveryone,
}: {
  isMutedForMe: boolean
  isMutedForEveryone: boolean
  onMuteForMe: () => void
  onMuteForEveryone?: () => void
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    null | SVGElement | HTMLButtonElement
  >(null)

  const open = !!anchorEl

  function handleClick(
    event: React.MouseEvent<SVGElement | HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        slim
        variant={ButtonVariant.TEXT}
        iconPadding={`0 10px 0 0`}
        rightIcon={
          <Icon type="svg" name="chevronDown" color={theme.colors.Red60} />
        }
        onClick={handleClick}
        textColor={theme.colors.Red60}
        textHoverColor={theme.colors.Red80}
      >
        {isMutedForEveryone || isMutedForMe ? 'Unmute' : 'Mute'}
      </Button>

      <Menu
        id={`mute-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `mute-menu`,
        }}
      >
        <MenuItem
          onClick={() => {
            onMuteForMe()
            handleClose()
          }}
        >
          {`${isMutedForMe ? 'Unmute' : 'Mute'} for me`}
        </MenuItem>
        {onMuteForEveryone && (
          <MenuItem
            onClick={() => {
              onMuteForEveryone()
              handleClose()
            }}
          >
            {`${isMutedForEveryone ? 'Unmute' : 'Mute'} for everyone`}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
