import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useMemo } from 'react'
import { Row } from '../base'

export const SentinelWorkerMap = ({
  workerIds,
  sentinelNotificationId,
}: {
  workerIds: string[]
  sentinelNotificationId?: string
}) => {
  /*
   * Build embed URL for map
   */
  const embedURL = useMemo(() => {
    const embedURL = new URL(`https://embed.hypertrack.com/views/drivers`)
    embedURL.searchParams.append(
      'publishable_key',
      `${import.meta.env.VITE_HYPERTRACK_PUBLISHABLE_KEY}`,
    )
    embedURL.searchParams.append('view', 'devices')
    embedURL.searchParams.append('driver-view', 'map')

    const driverListState = {
      driver_handles: workerIds,
    }

    embedURL.searchParams.append(
      'driver-list-state',
      window.btoa(JSON.stringify(driverListState)),
    )
    embedURL.searchParams.append('hide-driver-create', 'true')
    embedURL.searchParams.append('hide-view-switcher', 'true')
    embedURL.searchParams.append('hide-driver-export', 'true')
    embedURL.searchParams.append('hide-filters', 'true')
    embedURL.searchParams.append('hide-header', 'true')

    return embedURL
  }, [workerIds])

  return workerIds.length ? (
    <Row fullWidth>
      <iframe
        id={`iframe_sentinel_worker_map_${sentinelNotificationId}`}
        title={`sentinel_worker_map`}
        src={embedURL.href}
        width="100%"
        height="600px"
      />
    </Row>
  ) : (
    <Row justifyEnd fullWidth mx={theme.space.xs}>
      <Text>No worker IDs selected</Text>
    </Row>
  )
}
