import { Text } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { ParentInvoiceGroup } from '@traba/types'
import { CopyTextIcon } from 'src/components/base'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { truncateString } from 'src/utils/stringUtils'

type CompanyInvoiceGroupsTableProps = {
  invoiceGroups: ParentInvoiceGroup[]
  handleEditInvoiceGroupClick: (group: ParentInvoiceGroup) => void
  handleArchiveInvoiceGroupClick: (group: ParentInvoiceGroup) => void
}

export default function CompanyInvoiceGroupsTable(
  props: CompanyInvoiceGroupsTableProps,
) {
  const headers: (DataTableHeader | string)[] = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'id',
      label: 'ID',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
    },
    {
      key: '',
      label: '',
    },
  ]

  const rows: TableRow[] = props.invoiceGroups.map((group) => {
    const cells: TableCell[] = [
      {
        sortKey: group.name,
        renderFn: () => <Text variant="h7">{group.name}</Text>,
      },
      {
        renderFn: () => (
          <>
            {truncateString(group.id, 20)}{' '}
            <CopyTextIcon textToCopy={group.id} />
          </>
        ),
      },
      {
        renderFn: () => group.description,
      },
      {
        sortKey: group.recurring ? 'Reusable' : 'One-time',
        renderFn: () => (group.recurring ? 'Reusable' : 'One-time'),
      },
      {
        renderFn: () => (
          <DotMenu
            type="invoice-groups"
            dotMenuKey={group.id}
            menuItems={[
              {
                title: 'Edit Invoice Group',
                onClick: () => props.handleEditInvoiceGroupClick(group),
              },
              {
                title: 'Archive Invoice Group',
                onClick: () => props.handleArchiveInvoiceGroupClick(group),
              },
            ]}
          />
        ),
      },
    ]

    return {
      key: group.id,
      cells,
    }
  })

  return (
    <DataTable
      headers={headers}
      rows={rows}
      initialSortByColumnIndex={1} // sort by name column
    />
  )
}
