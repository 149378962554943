import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon, Row } from 'src/components/base'
import * as S from './Pagination.styles'

export type PaginationProps = {
  page: number
  pageSize: number
  onPageLeft: () => void
  onPageRight: () => void
  dataSize: number
  totalFound?: number
  disabled?: boolean
  showItemRange?: boolean
  style?: React.CSSProperties
}

export default function Pagination(props: PaginationProps) {
  const {
    page,
    pageSize,
    dataSize,
    onPageLeft,
    onPageRight,
    totalFound,
    disabled,
    showItemRange = true,
    style,
  } = props
  const enabledColor = theme.colors.Grey60
  const disabledColor = theme.colors.Grey20
  const disableRight =
    disabled ||
    (totalFound && totalFound <= pageSize * (page + 1)) ||
    dataSize < pageSize
  const lastPage = dataSize < pageSize
  const currPageSize = page * pageSize
  const startRange = currPageSize + 1
  const endRange = Math.min(currPageSize + pageSize, currPageSize + dataSize)
  // Handles undefined case in case there are any issues with the count query.
  // This should basically never happen unless there are issues fetching paginated data as well.
  const paginationMessage =
    !showItemRange && totalFound
      ? `${startRange} of ${totalFound}`
      : totalFound !== undefined
        ? `${startRange} - ${endRange} of ${totalFound}`
        : `${startRange} - ${endRange}`

  return (
    <Row justifyBetween style={{ width: '180px' }}>
      <S.StyledSvg
        style={{ paddingRight: 2 }}
        onClick={disabled || page > 0 ? () => onPageLeft() : undefined}
      >
        <Icon
          type="svg"
          name="left"
          color={page > 0 && !disabled ? enabledColor : disabledColor}
        />
      </S.StyledSvg>
      <Text variant={'h5'} style={{ fontSize: style ? style.fontSize : '' }}>
        {!dataSize ? '...' : paginationMessage}
      </Text>
      <S.StyledSvg
        style={{ paddingLeft: 2 }}
        onClick={disableRight || lastPage ? undefined : () => onPageRight()}
      >
        <Icon
          type="svg"
          name="right"
          color={disableRight || lastPage ? disabledColor : enabledColor}
        />
      </S.StyledSvg>
    </Row>
  )
}
