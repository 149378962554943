import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BugleBlastStatus } from '@traba/types'
import { Shift } from '@traba/types'
import { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import useTimezonedDates from '../../../hooks/useTimezonedDates'
import { Button, Row } from '../../base'
import useBugleSchedule from '../hooks/useBugleSchedule'
import * as S from '../styles'

type ScheduledScreenProps = {
  shift: Shift
}

const getBugleBlastStatusColor = (status: BugleBlastStatus) => {
  switch (status) {
    case BugleBlastStatus.CANCELED:
    case BugleBlastStatus.FAILED:
      return theme.colors.red
    case BugleBlastStatus.IN_PROGRESS:
      return theme.colors.Green80
    default:
      return theme.colors.Blue80
  }
}

const ScheduledScreen = ({ shift }: ScheduledScreenProps) => {
  const [previewBugleId, setPreviewBugleId] = useState<string | undefined>()
  const tz = useTimezonedDates(shift.timezone)

  const {
    isFetchingBugleSchedule,
    isFetchingSearchParams,
    searchParams,
    bugleSchedule,
    handleCancelBugle,
    updatingBugleIds,
  } = useBugleSchedule({
    shiftId: shift.shiftId,
  })

  const handleSetSelection = (bugleId?: string) => {
    if (bugleId === previewBugleId) {
      return setPreviewBugleId(undefined)
    }
    setPreviewBugleId(bugleId)
  }

  const bugleWithParams = bugleSchedule
    ?.map((b) => ({
      ...b,
      params: searchParams.find((s) => s.searchParamsId === b.searchParamsId),
    }))
    // Sort by descending creation time
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )

  return (
    <S.ScheduleSectionContainer>
      {isFetchingBugleSchedule || isFetchingSearchParams ? (
        <Row fullHeight fullWidth alignCenter justifyCenter>
          <CircularProgress />{' '}
        </Row>
      ) : bugleWithParams && !!bugleWithParams.length ? (
        bugleWithParams.map((b) => {
          const isSelected = previewBugleId === b.bugleBlastId
          return (
            <S.ScheduleCardContainer
              isSelected={isSelected}
              previewBugleId={previewBugleId}
            >
              <Row justifyBetween fullWidth>
                <Text variant="h5"> {b.message.type}</Text>
                <Text variant="h6">{b.bugleBlastId}</Text>
              </Row>
              <Row alignCenter pt={theme.space.xxs} pl={theme.space.xxs}>
                <Text variant="h6">Creation time:</Text>
                <Text ml={theme.space.xxs}>{tz.getDateTime(b.createdAt)}</Text>
              </Row>
              <Row alignCenter pt={theme.space.xxs} pl={theme.space.xxs}>
                <Text variant="h6">Scheduled start time:</Text>
                <Text ml={theme.space.xxs}>
                  {tz.getDateTime(b.scheduledStart)}
                </Text>
              </Row>
              <Row alignCenter pt={theme.space.xxs} pl={theme.space.xxs}>
                <Text variant="h6">Message:</Text>
                <Text ml={theme.space.xxs}>{b.message.body}</Text>
              </Row>
              <Row
                alignCenter
                fullWidth
                justifyBetween
                pt={theme.space.xxs}
                pl={theme.space.xxs}
              >
                <Row alignCenter>
                  <Text variant="h6">Status:</Text>
                  <Text
                    ml={theme.space.xxs}
                    style={{
                      color: getBugleBlastStatusColor(b.status),
                      fontWeight: 600,
                    }}
                  >
                    {b.status}
                  </Text>
                </Row>
                <Row alignCenter pr={theme.space.xxs}>
                  <Text variant="h6">Initiated by:</Text>
                  <Text ml={theme.space.xxs}>{b.opsSender}</Text>
                </Row>
              </Row>
              {isSelected && b.params && (
                <S.ParamListGrid>
                  {b.params.presetTitle && (
                    <Row alignCenter>
                      <Text variant="h6">Preset Search:</Text>
                      <Text ml={theme.space.xxs}>{b.params.presetTitle}</Text>
                    </Row>
                  )}
                  {b.params.parameters &&
                    Object.keys(b.params.parameters).map((key) => {
                      const parameters = b.params?.parameters as Record<
                        string,
                        any
                      >
                      const paramValue = Array.isArray(parameters[key])
                        ? parameters[key].join(', ')
                        : parameters[key].toString()

                      return (
                        <S.ParamListItem key={key}>
                          <Text variant="h6">{key}:</Text>
                          <Text variant="body1" ml={theme.space.xxs}>
                            {paramValue}
                          </Text>
                        </S.ParamListItem>
                      )
                    })}
                </S.ParamListGrid>
              )}

              <Row fullWidth justifyBetween alignCenter mt={theme.space.xs}>
                <Button
                  variant={ButtonVariant.TRANSPARENT}
                  onClick={() =>
                    isSelected
                      ? handleSetSelection(undefined)
                      : handleSetSelection(b.bugleBlastId)
                  }
                >
                  {isSelected ? 'Hide Preview' : 'Show Preview'}
                </Button>
                {b.status === BugleBlastStatus.SCHEDULED && (
                  <Button
                    variant={ButtonVariant.REDOUTLINED}
                    onClick={() => handleCancelBugle(b.bugleBlastId)}
                    loading={updatingBugleIds.includes(b.bugleBlastId)}
                    ml={theme.space.xs}
                  >
                    Cancel Bugle
                  </Button>
                )}
              </Row>
            </S.ScheduleCardContainer>
          )
        })
      ) : (
        <Text>No Bugle blasts scheduled for this shift</Text>
      )}
    </S.ScheduleSectionContainer>
  )
}

export { ScheduledScreen }
