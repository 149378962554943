import { Text } from '@traba/react-components'
import { isEqual } from 'lodash'
import { Td } from 'src/components/base'

export function SummaryRow<T>({
  original,
  adjusted,
  originalString,
  adjustedString,
}: {
  original: T
  adjusted: T
  originalString: string
  adjustedString: string
}) {
  const changed = !isEqual(original, adjusted)
  return (
    <>
      <Td>
        <Text variant={changed ? 'strikethrough' : 'body1'}>
          {originalString}
        </Text>
      </Td>
      <Td>
        <Text variant="body1" style={{ fontWeight: changed ? '600' : '300' }}>
          {changed ? adjustedString : '-'}
        </Text>
      </Td>
    </>
  )
}
