import { useAlert } from '@traba/context'
import { Button, ButtonVariant, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { EditScheduleModal } from 'src/components/Schedule/EditScheduleModal'
import { ScheduleDetailsContentSection } from 'src/components/Schedule/ScheduleDetailsContentSection'
import { ScheduleDetailTabs } from 'src/components/Schedule/ScheduleDetailTabs'
import { ScheduleHeader } from 'src/components/Schedule/ScheduleHeader'
import { useCompany } from 'src/hooks/useCompany'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'

export default function ScheduleScreen() {
  const { shiftRequestParentId } = useParams<{ shiftRequestParentId: string }>()
  const { shiftRequestParent, isLoading } = useShiftRequestParent(
    shiftRequestParentId ?? '',
  )
  const { company } = useCompany(shiftRequestParent?.companyId ?? '')
  const { showError } = useAlert()
  const navigate = useNavigate()
  const [showEditModal, setShowEditModal] = useState(false)
  if (isLoading || !shiftRequestParent || !company) {
    return <LoadingSpinner />
  }

  return (
    <MainLayout title={`${shiftRequestParent.title} `}>
      <ScheduleHeader
        shiftRequestParent={shiftRequestParent}
        company={company}
      />
      <Row justifyBetween mb={theme.space.sm} wrap>
        <Button variant={ButtonVariant.OUTLINED} onClick={() => navigate(-1)}>
          GO BACK
        </Button>
        <Row gap={theme.space.xxs}>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              if (!shiftRequestParentId) {
                showError(
                  'Shift request parent id is missing, please refresh and try again',
                )
              }
              navigate(
                `/post/${company.companyId}?shiftRequestParentId=${shiftRequestParentId}`,
              )
            }}
          >
            Add a one-off shift
          </Button>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              setShowEditModal(true)
            }}
          >
            Edit schedule
          </Button>
        </Row>
      </Row>
      <Divider />
      <ScheduleDetailsContentSection shiftRequestParent={shiftRequestParent} />
      <ScheduleDetailTabs
        company={company}
        shiftRequestParent={shiftRequestParent}
      />
      <EditScheduleModal
        handleClose={() => {
          setShowEditModal(false)
        }}
        shiftRequestParent={shiftRequestParent}
        company={company}
        isOpen={showEditModal}
      />
    </MainLayout>
  )
}
