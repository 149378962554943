import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { isSameDay } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { useShiftRequestParentEndDateMutation } from 'src/hooks/useShiftRequestParent'

interface Props {
  timezone?: string
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSuccess: () => void
  onBack: () => void
}

export const EditScheduleEndDate = (props: Props) => {
  const { timezone, shiftRequestParent, onBack, onSuccess } = props
  const { updateShiftRequestParentEndDate } =
    useShiftRequestParentEndDateMutation(
      shiftRequestParent.shiftRequestParentId,
      shiftRequestParent.companyId,
    )
  const { showError, showSuccess } = useAlert()
  const originalEndDate =
    shiftRequestParent.shiftRequests[0].schedules[0].recurringSchedule?.endDate
  const [endDate, setEndDate] = useState(originalEndDate)
  const [isConfirming, setIsConfirming] = useState(false)
  const onConfirm = async () => {
    if (originalEndDate === undefined && endDate === undefined) {
      return showError(`No changes made`)
    }
    if (originalEndDate && endDate && isSameDay(originalEndDate, endDate)) {
      return showError(`Selected new end date is same as current end date`)
    }
    try {
      setIsConfirming(true)
      await updateShiftRequestParentEndDate({
        endDate,
      })
      onSuccess()
      showSuccess('Schedule end date updated successfully')
    } catch (e) {
      showError('Failed to update shift request')
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <>
      <Row alignCenter fullWidth>
        <DatePicker
          date={endDate}
          minDate={new Date()}
          showTimeFieldInPopover={false}
          granularity="day"
          setDate={(date, isFourDigitYear) => {
            if (date && isFourDigitYear) {
              setEndDate(date)
            }
          }}
          isClearable={false}
          timezone={timezone}
          label="End date"
        />
      </Row>
      <Row
        alignCenter
        my={theme.space.lg}
        onClick={() => setEndDate(undefined)}
      >
        <RadioButton selected={!endDate} />
        <Text
          color={theme.colors.MidnightBlue}
          variant="h5"
          ml={theme.space.xs}
        >
          No end date
        </Text>
      </Row>
      <Row justifyBetween mt={theme.space.lg}>
        <Button onClick={onBack} variant={ButtonVariant.OUTLINED}>
          Back
        </Button>
        <Button onClick={onConfirm} loading={isConfirming}>
          Confirm update
        </Button>
      </Row>
    </>
  )
}
