import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerJobPlacementAction } from '@traba/types'
import { useState } from 'react'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerJobPlacementContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface CompanyJobPlacementCreateModalProps {
  companyId: string
  handleClose: () => void
  isOpen: boolean
}

export const CompanyJobPlacementCreateModal = (
  props: CompanyJobPlacementCreateModalProps,
) => {
  const { isOpen, handleClose, companyId } = props
  const [selectedWorkers, setSelectedWorkers] = useState<PopulatedWorker[]>([])

  function handleCloseModal() {
    handleClose()
    setSelectedWorkers([])
  }

  return (
    <Modal
      handleClose={handleCloseModal}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={'Add workers as hired for company'}
    >
      <Row flexCol fullHeight fullWidth className="modalWrapper">
        <Text variant="h5" style={{ marginRight: theme.space.xs }}>
          Select a worker
        </Text>
        <Row alignCenter mb={theme.space.sm}>
          <SearchWorkers
            checkedWorkers={selectedWorkers}
            setCheckedWorkers={setSelectedWorkers}
            hideApprovedOnlyToggle
            hideInstructionText
            selectOneOnly
          />
        </Row>
        {selectedWorkers.length > 0 && (
          <WorkerJobPlacementContent
            companyId={companyId}
            handleClose={handleCloseModal}
            workerId={selectedWorkers[0].id || selectedWorkers[0].workerId}
            action={WorkerJobPlacementAction.CREATE_WORKER}
          />
        )}
      </Row>
    </Modal>
  )
}
