import {
  SentinelCheckpointBasedEventType,
  SentinelEventActionType,
  SentinelEventCustomActionType,
  SentinelNotificationEventType,
  SentinelTriggerBasedEventType,
  TransitStatus,
} from '@traba/types'

export const titleForEventActionType = (
  actionType: SentinelEventActionType | SentinelEventCustomActionType,
) => {
  switch (actionType) {
    // Communication actions
    case SentinelEventActionType.BUGLE_SMS:
      return 'Send Bugle SMS'
    case SentinelEventActionType.BUGLE_PUSH:
      return 'Send Bugle Push Notification'
    case SentinelEventActionType.BACKFILL_CALLS:
      return 'Trigger Backfill Calls'
    case SentinelEventActionType.TRIGGER_ROBOCALLS_TO_WORKER:
      return 'Trigger Robocalls to Workers'
    case SentinelEventActionType.DIRECT_SMS:
      return 'Direct SMS'
    case SentinelEventActionType.DIRECT_PUSH:
      return 'Direct Push'
    case SentinelEventActionType.REQUEST_ETA:
      return 'Request ETA'
    // Worker shift actions
    case SentinelEventActionType.ADD_WORKER_TO_SHIFT:
      return 'Add Worker to Shift'
    case SentinelEventActionType.CANCEL_WORKERS:
      return 'Cancel Workers'
    case SentinelEventActionType.CLOCK_IN_WORKERS:
      return 'Clock In Worker'
    case SentinelEventActionType.CLOCK_OUT_WORKERS:
      return 'Clock Out Worker'
    case SentinelEventActionType.ABANDON_WORKERS:
      return 'Abandon Worker'
    case SentinelEventActionType.NO_SHOW_WORKERS:
      return 'No Show Worker'
    // Shift actions
    case SentinelEventActionType.EDIT_SHIFT:
      return 'Edit Shift'
    case SentinelEventActionType.PAY_AND_DISMISS_PAID_BACKUP:
      return 'Pay & Dismiss Paid Backup'
    case SentinelEventActionType.CONVERT_PAID_BACKUP_TO_WORKER_SHIFT:
      return 'Convert Paid Backup to Worker Shift'
    case SentinelEventActionType.CANCEL_PAID_BACKUP:
      return 'Cancel Paid Backup'
    // Custom actions
    case SentinelEventCustomActionType.OPENPHONE_LINK:
      return 'Go to Openphone'
    default:
      return actionType
  }
}

export const iconNameForEventActionType = (
  actionType: SentinelEventActionType,
) => {
  switch (actionType) {
    case SentinelEventActionType.EDIT_SHIFT:
      return 'edit'
    case SentinelEventActionType.BACKFILL_CALLS:
      return 'phone'
    default:
      return undefined
  }
}

export const showWorkerDetailsListForNotificationEventType = (
  eventType?: SentinelNotificationEventType,
) => {
  if (!eventType) {
    return false
  }

  switch (eventType) {
    case SentinelTriggerBasedEventType.WORKER_LATE_CANCELED:
    case SentinelTriggerBasedEventType.WORKER_LEFT_JOBSITE:
    case SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL:
    case SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP:
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_CALLED:
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_MESSAGED:
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_CALLED:
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_MESSAGED:
    case SentinelTriggerBasedEventType.WORKER_CONFIRMED_OVER_SLOTS_REQUESTED:
    case SentinelCheckpointBasedEventType.WORKERS_PAYMENT_STOPPED:
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CONFIRMED:
    case SentinelCheckpointBasedEventType.WORKERS_LOCATION_STATUS:
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_IN:
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_OUT:
    case SentinelCheckpointBasedEventType.WORKERS_TRANSIT_STATUS:
      return true
    case SentinelCheckpointBasedEventType.SHIFT_NOT_FILLED:
    default:
      return false
  }
}

export const showPaidBackupListForNotificationEventType = (
  eventType?: SentinelNotificationEventType,
) => {
  if (!eventType) {
    return false
  }

  switch (eventType) {
    case SentinelCheckpointBasedEventType.PAID_BACKUP_CANCELLATION_PENDING:
    case SentinelTriggerBasedEventType.PAID_BACKUP_ON_SHIFT_CALLED:
    case SentinelTriggerBasedEventType.PAID_BACKUP_ON_SHIFT_MESSAGED:
      return true
    default:
      return false
  }
}

export const showWorkerCommunicationsForNotificationEventType = (
  eventType?: SentinelNotificationEventType,
) => {
  if (!eventType) {
    return false
  }

  switch (eventType) {
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_CALLED:
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_MESSAGED:
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_CALLED:
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_MESSAGED:
    case SentinelTriggerBasedEventType.PAID_BACKUP_ON_SHIFT_CALLED:
    case SentinelTriggerBasedEventType.PAID_BACKUP_ON_SHIFT_MESSAGED:
      return true
    default:
      return false
  }
}

export const enableActionForWorkersSelected = (
  actionType: SentinelEventActionType | undefined,
  workersSelected: number,
) => {
  if (!actionType) {
    return false
  }

  switch (actionType) {
    // No worker selection required
    case SentinelEventActionType.EDIT_SHIFT:
    case SentinelEventActionType.BUGLE_SMS:
    case SentinelEventActionType.BUGLE_PUSH:
    case SentinelEventActionType.BACKFILL_CALLS:
      return true
    // Allow bulk
    case SentinelEventActionType.TRIGGER_ROBOCALLS_TO_WORKER:
    case SentinelEventActionType.CANCEL_WORKERS:
    case SentinelEventActionType.DIRECT_SMS:
    case SentinelEventActionType.DIRECT_PUSH:
      return workersSelected > 0
    // Disallow bulk
    case SentinelEventActionType.ADD_WORKER_TO_SHIFT:
    case SentinelEventActionType.REQUEST_ETA:
    case SentinelEventActionType.CLOCK_IN_WORKERS:
    case SentinelEventActionType.CLOCK_OUT_WORKERS:
    case SentinelEventActionType.ABANDON_WORKERS:
    case SentinelEventActionType.NO_SHOW_WORKERS:
      return workersSelected === 1
    // Disallow bulk for paid backup actions
    case SentinelEventActionType.CONVERT_PAID_BACKUP_TO_WORKER_SHIFT:
    case SentinelEventActionType.CANCEL_PAID_BACKUP:
      return workersSelected === 1
    default:
      return false
  }
}

export const isActionImplemented = (actionType: SentinelEventActionType) => {
  switch (actionType) {
    case SentinelEventActionType.REQUEST_ETA:
    case SentinelEventActionType.PAY_AND_DISMISS_PAID_BACKUP:
      return false
    default:
      return true
  }
}

export function getTransitStatusCriticalLevel(
  transitStatus?: TransitStatus,
): number {
  switch (transitStatus) {
    case TransitStatus.NOT_LEFT:
      return 0
    case TransitStatus.IN_TRANSIT:
      return 1
    case TransitStatus.SLOWED:
      return 2
    case TransitStatus.STOPPED:
      return 3
    case TransitStatus.MOVING_AWAY:
      return 4
    case TransitStatus.LOST_CONNECTION:
      return 5
    default:
      return -1
  }
}
