import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CustomizedBadgeVariant } from '@traba/types'
import { Badge, Button, Input, Row } from 'src/components/base'
import { RadioButton } from 'src/components/RadioButton/RadioButton'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { getErrorMessage } from 'src/utils/errorUtils'
import { getBadgeDetails } from './BadgePreview'

interface Props {
  setSelectedVariant: React.Dispatch<
    React.SetStateAction<CustomizedBadgeVariant | null>
  >
  selectedVariant: CustomizedBadgeVariant | null
  badgeName: string
  setBadgeName: React.Dispatch<React.SetStateAction<string>>
  shortDescription: string
  setShortDescription: React.Dispatch<React.SetStateAction<string>>
  longDescription: string
  setLongDescription: React.Dispatch<React.SetStateAction<string>>
  setIsCreatingNew: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateNewBadgeSection = ({
  badgeName,
  setBadgeName,
  selectedVariant,
  setSelectedVariant,
  shortDescription,
  setShortDescription,
  longDescription,
  setLongDescription,
  setIsCreatingNew,
}: Props) => {
  const { showError, showSuccess } = useAlert()
  const { refetch } = useCustomBadges()
  const variantValues = Object.values(CustomizedBadgeVariant).map((value) =>
    getBadgeDetails(value),
  )

  return (
    <>
      <Text variant="h5" mt={theme.space.med}>
        Create new badge
      </Text>
      <Text variant="h6" my={theme.space.xxs}>
        Select badge type & color
      </Text>
      <Row justifyBetween my={theme.space.xxs} wrap>
        {variantValues.map(({ variant, badgeStyleVariant, badgeText }) => (
          <Row
            key={badgeText}
            alignCenter
            onClick={() => setSelectedVariant(variant)}
            mr={theme.space.xs}
            my={theme.space.xxs}
            gap={theme.space.xxs}
          >
            <RadioButton selected={selectedVariant === variant} />
            <Badge variant={badgeStyleVariant} title={badgeText} />
          </Row>
        ))}
      </Row>

      <Text variant="h6" mb={theme.space.xxs} mt={theme.space.xxs}>
        Badge Name
      </Text>
      <Input
        maxLength={20}
        label="Badge Name"
        width="100%"
        value={badgeName}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setBadgeName(e.target.value.toLocaleUpperCase())
        }
      />
      <Text variant="body3">Character limit: 20</Text>
      <Text variant="h6" mb={theme.space.xxs} mt={theme.space.sm}>
        Add short explanation
      </Text>
      <Input
        maxLength={50}
        label="Short description"
        width="100%"
        value={shortDescription}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setShortDescription(e.target.value)
        }
      />
      <Text variant="body3">Character limit: 50</Text>
      <Text variant="h6" mb={theme.space.xxs} mt={theme.space.sm}>
        Add full text explanation (optional, shown in a bottomsheet)
      </Text>
      <Input
        label="Full text explanation"
        width="100%"
        value={longDescription}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setLongDescription(e.target.value)
        }
      />
      <Button
        mt={theme.space.sm}
        onClick={async () => {
          try {
            await trabaApi.post(`shift-badge`, {
              badgeName,
              shortDescription,
              longDescription,
              variant: selectedVariant,
            })
            showSuccess('Badge created successfully')
            refetch()
            setIsCreatingNew(false)
          } catch (error) {
            const errorMessage = getErrorMessage(error)
            showError(errorMessage, 'Error Creating Shift Badges')
          }
        }}
      >
        Create badge
      </Button>
    </>
  )
}
