import { InfoTooltip } from '@traba/react-components'
import { AccountApprovalStatus, ApprovalStatusReason } from '@traba/types'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import Badge from '.'

export type AccountStatusBadgeProps = {
  accountStatus: AccountApprovalStatus
  approvalStatusReason?: ApprovalStatusReason
  accountSuspensionExpiration?: Date
}

const accountStatusToBadgeVariantDict: Record<AccountApprovalStatus, string> = {
  [AccountApprovalStatus.Approved]: 'success',
  [AccountApprovalStatus.Banned]: 'danger',
  [AccountApprovalStatus.Rejected]: 'opaqueRed',
  [AccountApprovalStatus.Suspended]: 'warning',
  [AccountApprovalStatus.Unapproved]: 'info',
  [AccountApprovalStatus.Terminated]: 'danger',
  [AccountApprovalStatus.TerminationPending]: 'warning',
}

export default function AccountStatusBadge({
  accountStatus,
  approvalStatusReason,
  accountSuspensionExpiration,
}: AccountStatusBadgeProps) {
  const tooltipTitle = `Reasons: ${approvalStatusReason}`
  const tooltipSubtitle = accountSuspensionExpiration
    ? `Suspension Expires On: ${formatDateTimeWithTimezone(accountSuspensionExpiration)}`
    : ''
  return (
    <>
      <Badge
        title={accountStatus?.replaceAll('_', ' ')}
        variant={accountStatusToBadgeVariantDict[accountStatus]}
      />
      {approvalStatusReason && (
        <InfoTooltip title={tooltipTitle} subTitle={tooltipSubtitle} />
      )}
    </>
  )
}
