import { useEffect, useRef } from 'react'

/**
 * `useDeepEffect` is a custom React hook that triggers a side effect function
 * when the dependencies change. Unlike `useEffect`, `useDeepEffect` performs
 * a deep comparison on the dependencies to determine if they have changed. Intended for
 * use when you have arrays or objects as dependencies.
 *
 * @param {() => void} fn - The side effect function to be executed when dependencies change.
 * @param {React.DependencyList | undefined} deps - The list of dependencies to watch for changes.
 */
export const useDeepEffect = (
  fn: () => void,
  deps: React.DependencyList | undefined,
) => {
  const isFirst = useRef(true)
  const prevDeps = useRef(deps)

  useEffect(() => {
    const isFirstEffect = isFirst.current
    const isSame = prevDeps.current?.every(
      (obj, index) => deps && isEqual(obj, deps[index]),
    )

    isFirst.current = false
    prevDeps.current = deps

    if (isFirstEffect || !isSame) {
      return fn()
    }
  }, deps)
}

function isEqual(x: any, y: any): boolean {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => isEqual(x[key], y[key]))
    : x === y
}
