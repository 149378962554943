import { useState, useEffect } from 'react'

function useFieldsConfig(
  key: string,
  preSelectedFields: string[] = [],
): [string[], (fields: string[]) => void] {
  const [selectedFields, setSelectedFields] = useState(() => {
    const storedSelectedFields = localStorage.getItem(key)
    const initialFields = storedSelectedFields
      ? JSON.parse(storedSelectedFields)
      : []
    const mergedFields = Array.from(
      new Set([...initialFields, ...preSelectedFields]),
    )
    return mergedFields
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(selectedFields))
  }, [selectedFields, key])

  return [selectedFields, setSelectedFields]
}

export { useFieldsConfig }
