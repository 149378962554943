import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AdjudicationStatus,
  BackgroundCheckAssessment,
  BackgroundCheckStatus,
  DetailedBackgroundCheckStatus,
} from '@traba/types'
import { calculateDetailedBackgroundCheckStatus } from 'src/utils/backgroundCheckUtils'
import { Dropdown, DropdownElement } from '../../../components/base/Dropdown'

export type BackgroundCheckStatusBadgeProps = {
  status: BackgroundCheckStatus
  adjudication?: AdjudicationStatus
  assessment?: BackgroundCheckAssessment
  hasActiveAction: boolean
  onActionUpdate: (hasActiveAction: boolean) => void
  isLoading: boolean
}

type StatusBadgeConfig = {
  variant: string
  title: string
}

const bgcStatusToBadgeConfig: Record<
  DetailedBackgroundCheckStatus,
  StatusBadgeConfig
> = {
  [DetailedBackgroundCheckStatus.PENDING]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.SUSPENDED]: {
    variant: 'danger',
    title: 'Suspended',
  },
  [DetailedBackgroundCheckStatus.CANCELED]: {
    variant: 'danger',
    title: 'Canceled',
  },
  [DetailedBackgroundCheckStatus.INVITATION_CREATED]: {
    variant: 'danger',
    title: 'Not Started',
  },
  [DetailedBackgroundCheckStatus.DISPUTE]: {
    variant: 'warning',
    title: 'Dispute',
  },
  [DetailedBackgroundCheckStatus.CONSIDER]: {
    variant: 'warning',
    title: 'Consider',
  },
  [DetailedBackgroundCheckStatus.REPORT_CREATED]: {
    variant: 'info',
    title: 'Started',
  },
  [DetailedBackgroundCheckStatus.CLEAR]: { variant: 'success', title: 'Clear' },
  [DetailedBackgroundCheckStatus.COMPLETE]: {
    variant: 'success',
    title: 'Complete',
  },
  [DetailedBackgroundCheckStatus.POST_ADVERSE_ACTION]: {
    variant: 'danger',
    title: 'Post-adverse action',
  },
  [DetailedBackgroundCheckStatus.PRE_ADVERSE_ACTION]: {
    variant: 'warning',
    title: 'Pre-adverse action',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE]: {
    variant: 'success',
    title: 'Eligible',
  },
  [DetailedBackgroundCheckStatus.REVIEW]: {
    variant: 'warning',
    title: 'Review',
  },
  [DetailedBackgroundCheckStatus.ESCALATED]: {
    variant: 'danger',
    title: 'Escalated',
  },
  [DetailedBackgroundCheckStatus.ELIGIBLE_AFTER_REVIEW]: {
    variant: 'success',
    title: 'Eligible After Review',
  },
}

const getDropdownOptions = (
  detailedStatus: DetailedBackgroundCheckStatus,
  hasActiveAction: boolean,
  isLoading: boolean,
  onActionUpdate: (hasActiveAction: boolean) => void,
): DropdownElement[] => {
  const isPushDisabled =
    detailedStatus !== DetailedBackgroundCheckStatus.PENDING &&
    detailedStatus !== DetailedBackgroundCheckStatus.INVITATION_CREATED

  return [
    {
      label: hasActiveAction ? 'Lift Action' : 'Push in App',
      handleClick: () => onActionUpdate(hasActiveAction),
      disabled: (!hasActiveAction && isPushDisabled) || isLoading,
      icon: () =>
        hasActiveAction ? (
          <SvgIcon name="unblock" />
        ) : (
          <SvgIcon name="notification" />
        ),
    },
  ]
}

export default function BackgroundCheckStatusBadge({
  status,
  adjudication,
  assessment,
  hasActiveAction,
  onActionUpdate,
  isLoading,
}: BackgroundCheckStatusBadgeProps) {
  const detailedStatus = calculateDetailedBackgroundCheckStatus(
    status,
    adjudication,
    assessment,
  )
  let badgeConfig = bgcStatusToBadgeConfig[detailedStatus] ?? {
    variant: 'info',
    title: 'Unknown',
  }

  if (hasActiveAction) {
    badgeConfig = {
      variant: 'danger',
      title: 'Pushed',
    }
  }

  const dropdownOptions = getDropdownOptions(
    detailedStatus,
    hasActiveAction,
    isLoading,
    onActionUpdate,
  )

  if (isLoading) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }

  return (
    <Dropdown
      orientation="right"
      dropdownOptions={dropdownOptions}
      buttonStyling={{
        whiteSpace: 'nowrap',
        minWidth: 'fit-content',
      }}
      listContainerStyling={{ width: '175px' }}
      listItemStyling={{
        alignItems: 'center',
        color: theme.colors.Violet80,
        fontWeight: '500',
        fontSize: 14,
      }}
      useBadge
      badgeVariant={badgeConfig.variant}
      title={badgeConfig.title}
    />
  )
}
