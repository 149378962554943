import { RemoveWorkersFromScheduleModalContent } from '@traba/react-components'
import { CancelRecurringWorkerShiftRequest } from '@traba/types'
import { filterShiftsInFuture } from '@traba/utils'
import { useMemo } from 'react'
import { useShiftRequestsWithShifts } from 'src/screens/FieldMonitorScreen/components/AddWorkers/hooks/useShiftRequestWithShifts'

interface Props {
  shiftRequestId?: string
  workerIds?: string[]
  showRemoveWorkersModal: boolean
  onCloseModal: () => void
  refetchWorkersOnSchedule: () => void
  cancelRecurringWorkerShift: (
    request: CancelRecurringWorkerShiftRequest,
  ) => Promise<void>
}

export const RemoveWorkersFromScheduleModal = (props: Props) => {
  const {
    shiftRequestId,
    workerIds,
    showRemoveWorkersModal,
    onCloseModal,
    refetchWorkersOnSchedule,
    cancelRecurringWorkerShift,
  } = props

  const shiftRequestIds = useMemo(() => {
    return shiftRequestId ? [shiftRequestId] : []
  }, [shiftRequestId])
  const { shifts, isLoading } = useShiftRequestsWithShifts(shiftRequestIds)
  const futureShifts = filterShiftsInFuture(shifts)

  return (
    <RemoveWorkersFromScheduleModalContent
      shifts={futureShifts}
      workerIds={workerIds}
      isLoading={isLoading}
      showRemoveWorkersModal={showRemoveWorkersModal}
      onCloseModal={onCloseModal}
      shiftRequestId={shiftRequestId}
      refetchWorkersOnSchedule={refetchWorkersOnSchedule}
      cancelRecurringWorkerShift={cancelRecurringWorkerShift}
      isFromOpsConsole
    />
  )
}
