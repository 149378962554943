import {
  Button,
  ButtonVariant,
  Card,
  Row,
  ScheduleDays,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { formatScheduleDateRange, isRecurringShiftRequest } from '@traba/utils'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge } from '../base'
import { ScheduleListItem } from '../Schedule/ScheduleListItem'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest | undefined
}

export const ScheduleInfoCard = (props: Props) => {
  const { shiftRequestParent } = props
  const navigate = useNavigate()

  const firstRecurringShiftRequest = useMemo(
    () =>
      shiftRequestParent?.shiftRequests?.find((sr) =>
        sr.schedules.some((s) => s.isRecurringSchedule),
      ),
    [shiftRequestParent],
  )

  if (!shiftRequestParent || !firstRecurringShiftRequest) {
    return null
  }
  const roleNames = shiftRequestParent.shiftRequests
    .filter(isRecurringShiftRequest)
    .map((sr) => `${sr.slotsRequested} ${sr.shiftRole}`)

  return (
    <Card
      style={{
        backgroundColor: theme.colors.Grey10,
        marginBottom: theme.space.xs,
      }}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter gap={theme.space.xxs}>
          <Text variant="h6">{shiftRequestParent.title}</Text>
          <Badge title="schedule" variant="brand" />
        </Row>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() =>
            navigate(`/schedule/${shiftRequestParent.shiftRequestParentId}`)
          }
        >
          Go to full schedule
        </Button>
      </Row>
      <Row alignCenter>
        <ScheduleListItem
          label="SCHEDULE RANGE"
          content={formatScheduleDateRange(
            firstRecurringShiftRequest?.schedules,
          )}
        />
        <ScheduleListItem
          label="ROLES FOR EACH SHIFT"
          content={roleNames.join(', ')}
        />
        <ScheduleListItem
          label="WEEKLY PATTERN"
          content={
            <Row wrap>
              {firstRecurringShiftRequest.schedules.map((schedule, index) => (
                <ScheduleDays
                  repeatOn={schedule.recurringSchedule?.repeatOn || []}
                  key={index}
                />
              ))}
            </Row>
          }
        />
      </Row>
    </Card>
  )
}
