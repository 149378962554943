import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift, ShiftRequest } from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getShiftRequestWithShifts } from 'src/utils/shiftUtils'
import { Col, Row } from '../../../../components/base'
import { isOverlap } from '../../../../utils/dateUtils'
import { SearchWorkers } from '../../../ShiftDetailsScreen/components/SearchWorkers'
import ShiftRequestCard from './ShiftRequestCard'

export const findOverlap = (
  selectedShiftId: string,
  nonSelectedShift: Shift,
  allShifts: Shift[],
) => {
  const selectedShift = allShifts.find(
    (shift) => shift.shiftId === selectedShiftId,
  )
  return (
    selectedShift &&
    isOverlap(
      new Date(selectedShift.startTime),
      new Date(selectedShift.endTime),
      new Date(nonSelectedShift.startTime),
      new Date(nonSelectedShift.endTime),
    )
  )
}

export const ListStep = ({
  checkedWorkers,
  setCheckedWorkers,
  validateSetWorkers,
  shiftRequests,
  shifts,
  selectedShifts,
  setSelectedShifts,
  error,
  isLoadingShiftRequests,
}: {
  checkedWorkers: PopulatedWorker[]
  setCheckedWorkers: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
  validateSetWorkers?: () => void
  shiftRequests: ShiftRequest[]
  shifts: Shift[]
  selectedShifts: Record<string, string[]>
  setSelectedShifts: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >
  error: string
  isLoadingShiftRequests: boolean
}) => {
  const [conflictingShiftIds, setConflictingShiftsIds] = useState<string[]>([])

  /** Each time a shift is selected, mark all other shifts that conflict */
  useEffect(() => {
    const selectedShiftIds = Object.values(selectedShifts).flat()
    const shiftsWithConflicts: string[] = []
    const nonSelectedShifts = shifts.filter(
      (shift) => !selectedShiftIds.includes(shift.shiftId),
    )
    for (const nonSelectedShift of nonSelectedShifts) {
      let overlapFound = false
      for (const selectedShiftId of selectedShiftIds) {
        if (findOverlap(selectedShiftId, nonSelectedShift, shifts)) {
          shiftsWithConflicts.push(nonSelectedShift.shiftId)
          overlapFound = true
          break
        }
      }
      if (overlapFound) {
        continue
      }
    }
    setConflictingShiftsIds(shiftsWithConflicts)
  }, [selectedShifts, shiftRequests, shifts])

  const uniqueShiftRequests = useMemo(
    () =>
      shiftRequests.filter(
        (request, index, self) =>
          index ===
          self.findIndex((t) => t.shiftRequestId === request.shiftRequestId),
      ),
    [shiftRequests],
  )

  return (
    <>
      <Row flexCol fullWidth>
        <SearchWorkers
          checkedWorkers={checkedWorkers}
          validateSetWorkers={validateSetWorkers}
          setCheckedWorkers={setCheckedWorkers}
          disableSideBar
        />
        {!!error && (
          <Row my={theme.space.xs}>
            <Text variant="error">{error}</Text>
          </Row>
        )}
      </Row>
      <Text variant="h6" mt={theme.space.xs}>
        Select the shift dates you want to add the worker to, or select all
        shifts in the shift request
      </Text>
      <Col
        mb={theme.space.med}
        style={{ justifyContent: 'flex-start', flex: 1 }}
      >
        {isLoadingShiftRequests ? (
          <Row fullHeight fullWidth justifyCenter alignCenter>
            <CircularProgress size={24} />
          </Row>
        ) : (
          uniqueShiftRequests.map((request, index) => (
            <ShiftRequestCard
              key={`${request.shiftRequestId}_${index}_s_request`}
              shiftRequest={getShiftRequestWithShifts(request, shifts)}
              selectedShifts={selectedShifts}
              setSelectedShifts={setSelectedShifts}
              conflictingShiftIds={conflictingShiftIds}
            />
          ))
        )}
      </Col>
    </>
  )
}
