import { createContext, useState } from 'react'
import { io, Socket } from 'socket.io-client'

const sockets: { [key: string]: Socket } = {}

export function connectSocket(key: string, url: string): Socket {
  if (sockets[key]) {
    return sockets[key]
  }

  console.log('Connecting socket to url: ', url)

  const socket = io(url, {
    withCredentials: true,
    transports: ['websocket'],
  })
  sockets[key] = socket

  return socket
}

export const WebSocketContext = createContext<{
  sockets: { [key: string]: Socket | null }
  connect: (key: string, url: string) => void
}>({
  sockets: {},
  connect: () => {
    return
  },
})

export function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const [socketsState, setSocketsState] = useState<{
    [key: string]: Socket | null
  }>({})

  const connect = (key: string, url: string) => {
    if (!url) {
      return
    }
    if (!socketsState[key]) {
      const socketInstance = connectSocket(key, url)
      setSocketsState((prevSockets) => ({
        ...prevSockets,
        [key]: socketInstance,
      }))
    }
  }

  return (
    <WebSocketContext.Provider value={{ sockets: socketsState, connect }}>
      {children}
    </WebSocketContext.Provider>
  )
}
