import { createContext, useContext, useReducer } from 'react'

export type SentinelContextAction =
  | {
      type: 'SET_SHIFT_IDS'
      payload: string[]
    }
  | {
      type: 'PROCESS_WEBSOCKET_NOTIFICATION'
      payload: string
    }

interface SentinelContextState {
  shiftIds: string[]
  receivedNotifications: string[]
}

const initialState: SentinelContextState = {
  shiftIds: [],
  receivedNotifications: [],
}

function sentinelReducer(
  state: SentinelContextState,
  action: SentinelContextAction,
): SentinelContextState {
  switch (action.type) {
    case 'SET_SHIFT_IDS':
      return { ...state, shiftIds: action.payload }
    case 'PROCESS_WEBSOCKET_NOTIFICATION':
      return {
        ...state,
        receivedNotifications: [...state.receivedNotifications, action.payload],
      }
    default:
      throw new Error(`Unhandled action: ${action}`)
  }
}

const SentinelContext = createContext<{
  state: SentinelContextState
  dispatch: React.Dispatch<SentinelContextAction>
}>({ state: initialState, dispatch: () => null })

export function SentinelContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [state, dispatch] = useReducer(sentinelReducer, initialState)

  return (
    <SentinelContext.Provider value={{ state, dispatch }}>
      {children}
    </SentinelContext.Provider>
  )
}

export function useSentinelContext() {
  return useContext(SentinelContext)
}
