import { PushNotificationDto } from './push-notification'
import { RobocallDto, RobocallSubject } from './robocall'

export enum CommunicationMedium {
  PushNotification = 'PushNotification',
  RoboCall = 'RoboCall',
  SMS = 'SMS',
}

export enum CommunicationSubject {
  RequestToSignupForShift = 'RequestToSignupForShift',
  StripeStandardPayoutCreated = 'StripeStandardPayoutCreated',
  StripeInstantPayoutCreated = 'StripeInstantPayoutCreated',
  StripeInstantPayFlagged = 'StripeInstantPayFlagged',
  StripePayoutPaid = 'StripePayoutPaid',
  ShiftChanged = 'ShiftChanged',
  ShiftAccepted = 'ShiftAccepted',
  ShiftIncidentForgiven = 'ShiftIncidentForgiven',
  PaymentAdjusted = 'PaymentAdjusted',
  StripeTransferCreated = 'StripeTransferCreated',
  CertificationApproved = 'CertificationApproved',
  CertificationRejected = 'CertificationRejected',
  SlotOpenedForWaitlistMember = 'SlotOpenedForWaitlistMember',
  RequestToConfirmTrainingShift = 'RequestToConfirmTrainingShift',
  RequestToClockInTrainingShift = 'RequestToClockInTrainingShift',
  MessageSummary = 'MessageSummary',
  AccountStatusChange = 'AccountStatusChange',
  ShiftBackfill = 'ShiftBackfill',
  BusinessAnnouncementPosted = 'BusinessAnnouncementPosted',
  BuglePush = 'BuglePush',
  OpsCancel = 'OpsCancel',
  LeftShiftWorkArea = 'LeftShiftWorkArea',
  WORKER_SHIFT_REJECTED = 'WorkerShiftRejected',
  PAID_BACKUP_DISMISSED = 'PaidBackupDismissed',
  PAID_BACKUP_CONVERTED = 'PaidBackupConverted',
  PAID_BACKUP_CREATED = 'PaidBackupCreated',
  SHIFT_CONFIRM = 'ShiftConfirm',
  SHIFT_REMINDER = 'ShiftReminder',
  SHIFT_INVITATION_SENT = 'ShiftInvitationSent',
  PAID_BACKUP_INVITATION_SENT = 'PaidBackupInvitationSent',
  SENTINEL_DIRECT_PUSH_NOTIFICATION = 'SentinelDirectPushNotification',
}

export type CommunicationContents = PushNotificationDto | RobocallDto // | OtherTypes (i.e. texting)

export interface Communication {
  communicationId: string
  workerId: string
  shiftId?: string
  subject: CommunicationSubject | RobocallSubject
  sentAt: Date
  medium: CommunicationMedium
  data: CommunicationContents
  relatedShiftId?: string
  createdAt: Date
  deleteAfter: Date
}

export interface RobocallCommunication extends Communication {
  data: RobocallDto
}

export interface SlackUser {
  id: string
  team_id: string
  name: string
  deleted: false
  real_name: string
  profile: {
    first_name: string
    last_name: string
    image_24: string
    image_32: string
    image_48: string
    image_72: string
    image_192: string
    image_512: string
    image_1024: string
    status_text_canonical: string
    team: string
  }
}
