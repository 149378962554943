import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Incentive, IncentiveStatus } from '@traba/types'
import { Shift } from '@traba/types'
import { useEffect, useState } from 'react'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { useIncentives } from 'src/hooks/useIncentives'
import { Col, Icon, IconButton, Row } from '../../components/base'
import IncentiveItem from '../../screens/ShiftDetailsScreen/components/IncentiveItem/IncentiveItem'

type IncentivesSectionProps = {
  shiftList: string[]
  shift: Shift
}

function IncentivesSection({ shiftList, shift }: IncentivesSectionProps) {
  const [incentives, setIncentives] = useState<Incentive[]>([])

  const {
    bonusAmount,
    setBonusAmount,
    handleIncentiveCreationForShift,
    getIncentivesForShift,
    isLoadingIncentives,
    updateIncentiveById,
  } = useIncentives()

  const fetchIncentives = async () => {
    const incentivesData = await getIncentivesForShift(shift.shiftId)
    setIncentives(incentivesData)
  }

  // Fetch incentives when the component mounts
  useEffect(() => {
    if (!isLoadingIncentives) {
      fetchIncentives()
    }
  }, [shift])

  const IncentivesLoading = () => (
    <Row flexCol>
      <CircularProgress size={20} />
    </Row>
  )

  const IncentivesDisplay = () => (
    <Col>
      <Text variant="body1" mb={theme.space.xxs}>
        This shift already has an incentive active:
      </Text>
      {incentives.map((incentive) => (
        <Row key={incentive.incentiveId}>
          <IncentiveItem incentive={incentive} />
          <Icon
            name={'trash'}
            onClick={() => handleDisableIncentive(incentive.incentiveId)}
            style={{ width: 18, cursor: 'pointer' }}
          />
        </Row>
      ))}
    </Col>
  )

  const handleSetAmount = (amount: number) => {
    setBonusAmount(amount < 0 ? 0 : amount >= 5 ? 5 : amount)
  }

  const handleAddIncentive = async () => {
    if (
      window.confirm(
        `Are you sure you want to add an incentive of $${bonusAmount}/hr to this shift?`,
      )
    ) {
      await handleIncentiveCreationForShift(shift)
    }
    await fetchIncentives()
  }

  const handleDisableIncentive = async (id: string) => {
    if (window.confirm('Are you sure you want to disable this incentive?')) {
      await updateIncentiveById(id, { status: IncentiveStatus.Inactive })
    }
    await fetchIncentives()
  }

  return (
    <Row flexCol justifyStart style={{ flex: 1 }} py={theme.space.xxs}>
      <Text variant="h5" mb={theme.space.xs}>
        Incentives
      </Text>
      {isLoadingIncentives ? (
        <IncentivesLoading />
      ) : shiftList.length > 1 ? (
        <Text variant="body1">
          Please select a single shift to manage its incentive
        </Text>
      ) : (
        <Row>
          {incentives.length > 0 && <IncentivesDisplay />}
          {!incentives.length && (
            <Row flexCol>
              <Row alignCenter>
                <Text variant="h6" mr={theme.space.xxs}>
                  Hourly Bonus
                </Text>
                <DeprecatedNumberInput
                  value={bonusAmount}
                  setter={handleSetAmount}
                  step={0.5}
                  width={80}
                  alignCenter
                  money
                />
                {!!bonusAmount && (
                  <Row ml={theme.space.xxs}>
                    <IconButton
                      iconName="checkmark"
                      onClick={handleAddIncentive}
                      style={{
                        width: 20,
                        cursor: 'pointer',
                        marginRight: theme.space.xxs,
                      }}
                      disabled={isLoadingIncentives}
                    />
                    <IconButton
                      iconName="cancel"
                      onClick={() => handleSetAmount(0)}
                      style={{ width: 20, cursor: 'pointer' }}
                      disabled={isLoadingIncentives}
                    />
                  </Row>
                )}
              </Row>
            </Row>
          )}
        </Row>
      )}
    </Row>
  )
}

export default IncentivesSection
