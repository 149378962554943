import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { BGCRequirement } from '@traba/types'
import { Col, Row } from '../base'
import { RadioButton } from '../RadioButton/RadioButton'

interface BGCRequirementSelectorProps {
  title: string
  selectedExtraBGCRequirement?: BGCRequirement
  handleSelectExtraBGCRequirement: (BGCRequirement: BGCRequirement) => void
}

export const BGCRequirementSelector: React.FC<BGCRequirementSelectorProps> = (
  props,
) => {
  const {
    handleSelectExtraBGCRequirement,
    selectedExtraBGCRequirement,
    title,
  } = props
  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        {title}
      </Text>
      <Row alignCenter style={{ columnGap: theme.space.xl }}>
        <Col style={{ display: 'flex', flex: '0 1 auto' }}>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() =>
              handleSelectExtraBGCRequirement(BGCRequirement.STANDARD)
            }
          >
            <RadioButton
              selected={selectedExtraBGCRequirement === BGCRequirement.STANDARD}
            />
            <Text variant="body1">Standard</Text>
          </Row>
        </Col>
        <Col style={{ display: 'flex', flex: '0 1 auto' }}>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() =>
              handleSelectExtraBGCRequirement(BGCRequirement.CLEAN)
            }
          >
            <RadioButton
              selected={selectedExtraBGCRequirement === BGCRequirement.CLEAN}
            />
            <Text variant="body1">Clean</Text>
          </Row>
        </Col>
      </Row>
    </>
  )
}
