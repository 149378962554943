import * as Sentry from '@sentry/react'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ONE_MINUTE_IN_MS } from '@traba/consts'
import { ActionSource, AuditLog } from '@traba/types'
import { omitBy } from 'lodash'
import { getQueryParams } from './useApi'

const AUDIT_LOGS_QUERY_KEY = 'audit_logs'

export type AuditSearchParams = {
  beforeCreationDate: Date
  afterCreationDate: Date
  event?: AuditLog['event']
  shiftId?: string
  workerId?: string
  companyId?: string
  actorId?: string
  actionSource?: ActionSource
}

async function getAuditLogs({
  beforeCreationDate,
  afterCreationDate,
  event,
  shiftId,
  workerId,
  companyId,
  actorId,
  actionSource,
}: {
  beforeCreationDate: string
  afterCreationDate: string
  event?: AuditLog['event']
  shiftId?: string
  workerId?: string
  companyId?: string
  actorId?: string
  actionSource?: ActionSource
}): Promise<AuditLog[] | undefined> {
  try {
    const queryString = getQueryParams([
      ['beforeCreationDate', beforeCreationDate],
      ['afterCreationDate', afterCreationDate],
      ['event', event],
      ['shiftId', shiftId],
      ['workerId', workerId],
      ['companyId', companyId],
      ['actorId', actorId],
      ['actionSource', actionSource],
    ])
    const res = await trabaApi.get(`audit-logs${queryString}`)

    return res.data
  } catch (error: any) {
    console.error(
      'useAuditLogs -> getAuditLogs() ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
  }
}

export function useAuditLogs({
  beforeCreationDate,
  afterCreationDate,
  event,
  shiftId,
  workerId,
  companyId,
  actorId,
  actionSource,
  enabled,
}: {
  beforeCreationDate: Date
  afterCreationDate: Date
  event?: AuditLog['event']
  shiftId?: string
  workerId?: string
  companyId?: string
  actorId?: string
  actionSource?: ActionSource
  enabled?: boolean
}) {
  const inputs = {
    beforeCreationDate,
    afterCreationDate,
    event,
    shiftId,
    workerId,
    companyId,
    actorId,
    actionSource,
  }

  const queryKeys = omitBy(inputs, (value) => !value)

  const {
    isLoading,
    isError,
    data: auditLogs,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<AuditLog[] | undefined, Error>({
    queryKey: [AUDIT_LOGS_QUERY_KEY, queryKeys],
    queryFn: () =>
      getAuditLogs({
        beforeCreationDate: beforeCreationDate.toISOString(),
        afterCreationDate: afterCreationDate.toISOString(),
        event,
        shiftId,
        workerId,
        companyId,
        actorId,
        actionSource,
      }),
    staleTime: ONE_MINUTE_IN_MS,
    enabled: enabled !== false,
  })

  return {
    isLoading,
    isError,
    auditLogs,
    error,
    isFetched,
    refetch,
    isFetching,
  }
}

export function invalidateAuditLogQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries({ queryKey: [AUDIT_LOGS_QUERY_KEY] })
}
