import { ButtonVariant, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { User } from '@traba/types'
import { useCallback } from 'react'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import {
  useCompanyUserMutations,
  useCompanyUsers,
} from 'src/hooks/useCompanyUsers'

const REMINDER_SUPERVISORS_OF_FUTURE_SHIFTS_CANNOT_BE_DELETED =
  'Note: If this is a supervisor who has future shifts, you cannot delete this user and you will get an error. If you still need this user deleted, please talk to eng to help you for now.'

export type DeleteUserConfirmationDialogProps = {
  user: User
  isOpen: boolean
  onClose: () => void
}

export function DeleteUserConfirmationDialog({
  user,
  isOpen,
  onClose,
}: DeleteUserConfirmationDialogProps) {
  const { uid, firstName, lastName, companyId = '' } = user
  const { isLoading } = useCompanyUsers(companyId)
  const { deleteUser, isDeleteUserLoading } = useCompanyUserMutations(companyId)

  const handleDeleteUser = useCallback(() => {
    deleteUser({ uid, firstName, lastName }, { onSuccess: onClose })
    window.analytics.track(`User Deleted Member`, { member: user })
  }, [user, deleteUser, onClose, uid, firstName, lastName])

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDeleteUser}
      onClose={onClose}
      title="Delete Business User?"
      confirmButtonText="Delete"
      confirmButtonVariant={ButtonVariant.CANCEL}
      confirmLoading={isDeleteUserLoading || isLoading}
      content={
        <Text variant="h5" color={theme.colors.Grey90}>
          {`Are you sure you want to delete ${firstName} ${lastName}'s user?`}
          <Row pt={theme.space.xs}>
            {REMINDER_SUPERVISORS_OF_FUTURE_SHIFTS_CANNOT_BE_DELETED}
          </Row>
        </Text>
      }
    />
  )
}
