import { Routes, Route, Navigate } from 'react-router-dom'
import LoginScreen from '../screens/LoginScreen'

function LoggedOut() {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default LoggedOut
