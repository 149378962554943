import { Z_INDEXES } from '@traba/theme'
import { InputStatus } from '@traba/types'
import styled from 'styled-components'
import { RequiredProps } from '../Required'

type DatePickerWrapperProps = {
  sm?: boolean
  style?: { [key: string]: string | number }
  width?: number
}

export const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  margin-top: ${({ sm }) => (sm ? '0px' : '10px')};
  width: ${({ width = {} }) => width}px;

  .react-datepicker {
    min-width: 228px;
  }

  .react-datepicker__input-container input {
    border: 1px solid #e2e5e9;
    border-radius: 8px;
    width: 100%;
    height: ${({ sm }) => (sm ? '40px' : '50px')};
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #08105e;
    min-width: 250px;
  }

  img[alt='calendarv2'] {
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
    top: ${({ sm }) => (sm ? '12px' : '16px')};
  }

  .react-datepicker-popper {
    z-index: ${Z_INDEXES.DATE_PICKER};
  }
`
export const Label = styled.label<RequiredProps>`
  top: -10px;
  left: 12px;
  position: absolute;
  background-color: white;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;

  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
      default:
        return '#7A8A99'
    }
  }};
`
