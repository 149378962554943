import { MenuItem } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerProfile, WorkerProfileStatus } from '@traba/types'
import { AxiosError } from 'axios'
import { useState, useCallback } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { Icon } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { IconPositionedMenu } from 'src/components/base/IconPositionedMenu/IconPositionedMenu'
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'src/components/ConfirmationDialog/ConfirmationDialog'
import {
  CreateOrUpdateWorkerProfilePayload,
  createOrUpdateWorkerProfiles,
  deleteWorkerProfile,
} from 'src/hooks/useWorkerProfile'
import { WorkerProfileAttributeUIModel } from './WorkerAttributes'

export function WorkerAttributeActions(props: {
  workerId: string
  workerAttribute: WorkerProfileAttributeUIModel
}) {
  const [isLoading, setLoading] = useState(false)
  const { handleError } = useAlert()

  const [confirmationProps, setConfirmationProps] = useState<Omit<
    ConfirmationDialogProps,
    'open' | 'onClose'
  > | null>(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const queryClient = useQueryClient()
  const { workerAttribute, workerId } = props
  const updateAttributeMutation = useMutation<
    WorkerProfile[] | undefined,
    AxiosError,
    CreateOrUpdateWorkerProfilePayload[]
  >(
    (workerProfiles: CreateOrUpdateWorkerProfilePayload[]) => {
      setLoading(true)
      return createOrUpdateWorkerProfiles(workerId, workerProfiles)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`workerProfile_${workerId}`)
        setLoading(false)
      },
      onError: (error) => {
        handleError(
          error,
          'WorkerAttributeActions -> createOrUpdateWorkerProfiles()',
          error?.message,
          'Error Updating Attribute',
        )
      },
    },
  )

  const profileField = workerAttribute.attributeMetadata.profileField

  const deleteAttributeMutation = useMutation<void, AxiosError, void>(
    () => {
      setLoading(true)
      return deleteWorkerProfile(workerId, profileField)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`workerProfile_${workerId}`)
        setLoading(false)
      },
      onError: (error) => {
        const errorMessage = `Error deleting attribute ${error.message}`
        handleError(
          error,
          'WorkerAttributeActions -> deleteWorkerProfile()',
          errorMessage,
          'Error Deleting Attribute',
        )
      },
    },
  )

  const updateAttributeStatus = useCallback(
    (status: WorkerProfileStatus) => {
      updateAttributeMutation.mutate([
        {
          category: workerAttribute.attributeMetadata.category,
          profileField,
          value: workerAttribute.attributeMetadata.value,
          status,
        },
      ])
    },
    [updateAttributeMutation, workerAttribute, profileField],
  )
  if (isLoading) {
    return <CircularProgress size="small" />
  }

  return (
    <>
      {confirmationProps ? (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onClose={() => setShowConfirmationDialog(false)}
          {...confirmationProps}
        />
      ) : null}
      <IconPositionedMenu
        id={profileField}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem
          onClick={() => {
            updateAttributeStatus(WorkerProfileStatus.Verified)
          }}
        >
          <Icon name="greenCheck" />
          <Text style={{ marginLeft: theme.space.xxxs }}>Verify</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfirmationProps({
              title: `Are you sure you want to revoke ${workerAttribute.displayName} from the worker's profile?`,
              content:
                'This will notify the worker and remove them from future shifts where the attribute is required',
              onConfirm: () =>
                updateAttributeStatus(WorkerProfileStatus.Revoked),
              confirmButtonText: 'Revoke',
            })
            setShowConfirmationDialog(true)
          }}
        >
          <Icon name="blocked_active" />
          <Text style={{ marginLeft: 5 }}>Revoke</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateAttributeStatus(WorkerProfileStatus.Reported)
          }}
        >
          <Icon name="refresh" />
          <Text style={{ marginLeft: 5 }}>Reset to Reported</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfirmationProps({
              title: `Are you sure you want to remove ${workerAttribute.displayName} from the worker's profile?`,
              content:
                'This will result in resetting the attribute as if the worker never reported it.',
              onConfirm: () => deleteAttributeMutation.mutate(),
              confirmButtonText: 'Remove',
            })
            setShowConfirmationDialog(true)
          }}
        >
          <Icon name="trash" />
          <Text style={{ marginLeft: 5 }}>Remove</Text>
        </MenuItem>
      </IconPositionedMenu>
    </>
  )
}
