import { FileType } from '@traba/hooks'
import { ButtonVariant } from '@traba/react-components'
import { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Modal, Row } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import { UploadVideoModal } from 'src/components/UploadVideo/UploadVideoModal'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'

interface CompanyVideosProps {
  companyId: string
}

export default function CompanyVideos({ companyId }: CompanyVideosProps) {
  const { trainingVideos, isLoading } = useTrainingVideos(companyId)
  const [uploadVideoModalIsOpen, setUploadVideoModalIsOpen] = useState(false)

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  const tableRows: TableRow[] =
    trainingVideos?.map((video) => {
      const { id, createdAt, name, videoLink, requiredForAll } = video
      const createdAtDate = new Date(createdAt)
      return {
        key: id,
        cells: [
          {
            renderFn: () => name,
            sortKey: name,
          },
          {
            renderFn: () => (
              <>
                {truncateString(id, 10)}
                <CopyTextIcon textToCopy={id} />
              </>
            ),
            sortKey: id,
          },
          {
            renderFn: () => {
              return (
                <video width="200" controls>
                  <source src={videoLink} />
                </video>
              )
            },
          },
          {
            renderFn: () => (requiredForAll ? 'Yes' : 'No'),
          },
          {
            renderFn: () => formatDateTimeWithTimezone(createdAtDate),
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Row pb={20} fullWidth justifyEnd>
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={() => setUploadVideoModalIsOpen(true)}
        >
          Add Training Video
        </Button>
      </Row>
      <DataTable
        initialSortByColumnIndex={0} // sort by name column
        rows={tableRows}
        headers={[
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'videoId',
            label: 'Video ID',
            sortable: true,
          },
          {
            key: 'videoLink',
            label: 'Video',
          },
          {
            key: 'requiredForAll',
            label: 'Required For All',
          },
          {
            key: 'createdAt',
            label: 'Created At',
          },
        ]}
      />
      <Modal
        handleClose={() => setUploadVideoModalIsOpen(false)}
        isOpen={uploadVideoModalIsOpen}
      >
        <UploadVideoModal
          companyId={companyId}
          fileType={FileType.TRAINING_VIDEOS}
          onClose={() => setUploadVideoModalIsOpen(false)}
        />
      </Modal>
    </>
  )
}
