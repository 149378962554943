import { useInternalUsers } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InternalUser,
  InternalUserRank,
  InternalUserRole,
  InternalUserStatus,
} from '@traba/types'
import { useState } from 'react'
import { Col, Input, Modal, Row, Select } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { ModalButtons } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'

interface InternalUserModal {
  isOpen: boolean
  handleClose: () => void
  user?: InternalUser // if editing
}

const InternalUserModal = (props: InternalUserModal) => {
  const { user, isOpen, handleClose } = props
  const { createInternalUser, patchInternalUser, refetchInternalUsers } =
    useInternalUsers({})

  const [firstName, setFirstName] = useState<string>(user?.firstName || '')
  const [lastName, setLastName] = useState<string>(user?.lastName || '')
  const [email, setEmail] = useState<string>(user?.email || '')
  const [role, setRole] = useState<InternalUserRole>(
    user?.role || InternalUserRole.OPS,
  )
  const [rank, setRank] = useState<InternalUserRank>(
    user?.rank || InternalUserRank.USER,
  )
  const [status, setStatus] = useState<InternalUserStatus | undefined>(
    user?.status,
  )
  const [slackId, setSlackId] = useState<string>(user?.slackId || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(
    user?.phoneNumber || '',
  )
  const [loading, setLoading] = useState<boolean>(false)

  const roleOptions = [
    { value: InternalUserRole.OPS, label: 'Ops' },
    { value: InternalUserRole.SCALED_OPS, label: 'Scaled Ops' },
    { value: InternalUserRole.MARKET_OPS, label: 'Market Ops' },
    { value: InternalUserRole.ENG, label: 'Engineering' },
    { value: InternalUserRole.EXTERNAL, label: 'External' },
    { value: InternalUserRole.SALES, label: 'Sales' },
  ]

  const statusOptions = [
    { value: InternalUserStatus.Active, label: 'Active' },
    { value: InternalUserStatus.Archived, label: 'Archived' },
  ]

  const rankOptions = [
    { value: 'SUPERVISOR', label: 'Supervisor' },
    { value: 'USER', label: 'User' },
  ]
  const isEdit = !!user

  const handleSubmit = async () => {
    setLoading(true)
    if (isEdit) {
      const update = {
        internalUserId: user.id,
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
        rank,
        slackId,
        status,
      }
      await patchInternalUser(update)
      window.analytics.track('Internal User Edited', {
        update,
      })
    } else {
      await createInternalUser({ firstName, lastName, email, role, slackId })
      window.analytics.track('Internal User Created', {
        firstName,
        lastName,
        email,
        role,
        slackId,
      })
    }
    await refetchInternalUsers()
    handleClose()
    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} size={MODAL_SIZE.SMALL} handleClose={handleClose}>
      <Col style={{ display: 'flex', alignItems: 'space-between', flex: 1 }}>
        <Row mb={theme.space.sm}>
          <Text variant="h5">{isEdit ? 'Edit' : 'Create'} Internal User</Text>
        </Row>
        <Row mb={theme.space.xs}>
          <Input
            label="First Name"
            width="100%"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setFirstName(e.target.value)
            }
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Input
            label="Last Name"
            width="100%"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setLastName(e.target.value)
            }
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Input
            label="Email"
            width="100%"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setEmail(e.target.value)
            }
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Input
            label="Slack Id"
            width="100%"
            value={slackId}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setSlackId(e.target.value)
            }
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Input
            label="Phone number"
            width="100%"
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setPhoneNumber(e.target.value)
            }
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Select
            label="Role"
            value={role}
            handleSelect={(role) => setRole(role as InternalUserRole)}
            menuItems={roleOptions}
          />
        </Row>
        <Row mb={theme.space.xs}>
          <Select
            label="Rank"
            value={rank}
            handleSelect={(rank) => setRank(rank as InternalUserRank)}
            menuItems={rankOptions}
          />
        </Row>
        {status && (
          <Row mb={theme.space.xs}>
            <Select
              label="Status"
              value={status}
              handleSelect={(status) => setStatus(status as InternalUserStatus)}
              menuItems={statusOptions}
            />
          </Row>
        )}
      </Col>
      <Row justifyEnd>
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={handleSubmit}
          loading={loading}
        />
      </Row>
    </Modal>
  )
}

export default InternalUserModal
