import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import {
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { useStatsigClient } from '@statsig/react-bindings'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CreateLineItemDto,
  EditLineItemDto,
  FormatLineItemType,
  InvoiceStatus,
  InvoiceVoidType,
  InvoiceVoidTypeDisplayNames,
  LineItemType,
} from '@traba/types'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, Button, Col, Icon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import RadioGroup from 'src/components/base/RadioGroup'
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useCompany } from 'src/hooks/useCompany'
import { useInvoice } from 'src/hooks/useInvoice'
import { useInvoiceMutations } from 'src/hooks/useInvoiceMutations'
import { useInvoiceStatement } from 'src/hooks/useInvoiceStatement'
import { getInvoiceStripeUrl } from 'src/utils/billingUtils'
import { openInNewTab } from 'src/utils/helperUtils'
import { EmploymentTypeBadge } from '../../../../components/base/Badge/EmploymentTypeBadge'
import InvoiceDetailsBody from '../../components/InvoiceDetailsBody'
import InvoiceStatusBadge from '../../components/InvoiceStatusBadge'
import useCustomReportActions from '../hooks/useCustomReportActions'
import useInvoiceActions from '../hooks/useInvoiceActions'

export type InvoiceDetailsProp = {
  invoiceId: string
}

const voidReasons = Object.values(InvoiceVoidType).reduce(
  (components, key) => ({
    ...components,
    [key]: {
      value: key,
      label: (
        <Text style={{ color: theme.colors.MidnightBlue }}>
          {InvoiceVoidTypeDisplayNames[key]}
        </Text>
      ),
    },
  }),
  {} as {
    [key in InvoiceVoidType]: {
      value: InvoiceVoidType
      label: ReactJSXElement
    }
  },
)

export default function InvoiceDetails(props: InvoiceDetailsProp) {
  const { invoiceId } = props
  const {
    invoice,
    error,
    refetch,
    isLoading: isLoadingInvoice,
  } = useInvoice(invoiceId)
  const {
    voidInvoice,
    deleteInvoice,
    finalizeInvoice,
    regenerateInvoice,
    resyncInvoice,
    addLineItemToInvoice,
    editLineItemOnInvoice,
    deleteLineItemFromInvoice,
    resendInvoiceEmail,
    updateMemoOnInvoice,
  } = useInvoiceMutations(invoiceId)

  const navigate = useNavigate()
  const { showError, showSuccess } = useAlert()
  const { company, isFetching: isFetchingCompany } = useCompany(
    invoice?.companyId ?? '',
  )
  const { hotSettings } = useHotSettings()

  const {
    isLoading: isLoadingInvoiceStatement,
    downloadUrl,
    createInvoiceStatement,
    isCreatingInvoiceStatement,
  } = useInvoiceStatement(invoiceId)

  const { client: statsig } = useStatsigClient()

  const downloadInvoiceStatement = useCallback(() => {
    openInNewTab(downloadUrl)
  }, [downloadUrl])

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [confirmationProps, setConfirmationProps] = useState<Omit<
    ConfirmationDialogProps,
    'open' | 'onClose'
  > | null>(null)

  const onRegenerateInvoice = async () => {
    if (selectedVoidReason) {
      const newInvoice = await regenerateInvoice({
        shouldRegenerateLineItems: regenerateLineItems,
        voidReason: selectedVoidReason,
      })
      navigate(`/billing/invoices/${newInvoice.invoiceId}`)
    }
  }

  const canRegenerateWithExistingLineItems = !invoice?.lineItems.some(
    (li) => li.chargeIds?.length === 0,
  )

  const [regenerateLineItems, setRegenerateLineItems] = useState(false)
  const [selectedVoidReason, setSelectedVoidReason] = useState<
    InvoiceVoidType | undefined
  >(undefined)

  const voidOptions = Object.values(voidReasons)
  const voidModalContent = (
    <>
      <Text variant="h6">
        Please select a reason for voiding / regenerating this invoice:
      </Text>
      <RadioGroup
        options={voidOptions}
        value={selectedVoidReason}
        onChange={(e) =>
          setSelectedVoidReason(e.target.value as InvoiceVoidType)
        }
      />
    </>
  )

  const invoiceLineItemFormatType = invoice?.lineItems.some(
    (item) => item.type === LineItemType.WORKER_SHIFT,
  )
    ? FormatLineItemType.WORKER_SHIFT
    : FormatLineItemType.SHIFT

  const [lineItemFormatType, setLineItemFormatType] = useState(
    invoiceLineItemFormatType,
  )

  const regenerateInvoiceModalContent = (
    <div>
      <Text variant="h7" mb={theme.space.xs}>
        Regenerating this invoice will create a new draft version of it. When
        the new version is finalized, the original will be voided.
      </Text>

      <Text variant="h7" mb={theme.space.xs}>
        Do you also need to resync line items? If you have made any changes to
        the shift and want the new draft version to reflect those changes,
        select yes.
      </Text>

      {!canRegenerateWithExistingLineItems && (
        <Badge
          variant="info"
          title="This invoice was generated before charge model and contains line items without charges. You cannot regenerate this invoice. Please void and recreate a new invoice with the appropriate shifts."
          disableUppercase
          style={{ marginBottom: theme.space.xs }}
        />
      )}

      <RadioGroup
        value={regenerateLineItems.toString()}
        onChange={(e) => setRegenerateLineItems(e.target.value === 'true')}
        options={[
          ...(canRegenerateWithExistingLineItems
            ? [
                {
                  label: (
                    <Text style={{ color: theme.colors.MidnightBlue }}>
                      No, regenerate with current line items
                    </Text>
                  ),
                  value: 'false',
                },
              ]
            : []),
          {
            label: (
              <Text style={{ color: theme.colors.MidnightBlue }}>
                Yes, resync line items
              </Text>
            ),
            value: 'true',
          },
        ]}
      ></RadioGroup>
      {voidModalContent}
    </div>
  )

  const showResyncPrompt =
    !invoice?.requiresResync && invoiceLineItemFormatType === lineItemFormatType

  const resyncModalContent = (
    <div>
      {
        <Text
          variant="h5"
          color={theme.colors.Red50}
          style={{
            marginBottom: theme.space.sm,
            visibility: showResyncPrompt ? 'visible' : 'hidden',
            height: showResyncPrompt ? 'auto' : '0px', // maintains parent width to make it less jarring when hiding resync prompt
          }}
        >
          A resync is not required for this invoice unless you want to regroup
          line items.
        </Text>
      }

      <Text variant="body1" style={{ marginBottom: theme.space.xs }}>
        Group line items by
      </Text>

      <ToggleButtonGroup fullWidth>
        <ToggleButton
          value="shift"
          selected={lineItemFormatType === FormatLineItemType.SHIFT}
          onClick={() => setLineItemFormatType(FormatLineItemType.SHIFT)}
        >
          Shift
        </ToggleButton>
        <ToggleButton
          value="worker"
          selected={lineItemFormatType === FormatLineItemType.WORKER_SHIFT}
          onClick={() => setLineItemFormatType(FormatLineItemType.WORKER_SHIFT)}
        >
          Worker Shift
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )

  const finalizeModalContent = (
    <>
      Are you sure you want to finalize this invoice?
      {!!invoice?.fromInvoice?.id && (
        <Text>
          This action will void the previous version of the invoice:
          <Anchor
            href={`/billing/invoices/${invoice?.fromInvoice?.id}`}
            variant="link"
          >
            {' '}
            {invoice?.fromInvoice?.id}
          </Anchor>
        </Text>
      )}
    </>
  )

  const {
    button: VoidButton,
    modal: voidModal,
    isLoading: voidLoading,
  } = useInvoiceActions({
    actionFn: async () => {
      if (selectedVoidReason) {
        await voidInvoice({
          voidReason: selectedVoidReason,
        })
      }
    },
    refetchFn: refetch,
    successMessage: 'Successfully Voided Invoice',
    errorMessage: 'Error Voiding Invoice',
    modalConfirmationTitle: 'Void Invoice?',
    modalContent: voidModalContent,
    buttonVariant: ButtonVariant.CANCEL,
    buttonText: 'Void',
    disabled: selectedVoidReason === undefined,
  })

  const {
    button: RegenerateButton,
    modal: regenerateModal,
    isLoading: regenerateLoading,
  } = useInvoiceActions({
    actionFn: () => onRegenerateInvoice(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refetchFn: () => {},
    successMessage: 'Successfully Regenerated Invoice',
    errorMessage: 'Error Regenerating Invoice',
    modalConfirmationTitle: 'Regenerate Invoice',
    modalContent: regenerateInvoiceModalContent,
    buttonVariant: ButtonVariant.FILLED,
    buttonText: 'Regenerate',
    disabled: selectedVoidReason === undefined,
  })

  const {
    button: DeleteButton,
    modal: deleteModal,
    isLoading: deleteLoading,
  } = useInvoiceActions({
    actionFn: async () => {
      await deleteInvoice()
    },
    refetchFn: refetch,
    successMessage: 'Successfully Deleted Invoice',
    errorMessage: 'Error Deleting Invoice',
    modalConfirmationTitle: 'Delete Invoice?',
    modalContent: 'Are you sure you want to delete this invoice?',
    buttonVariant: ButtonVariant.CANCEL,
    buttonText: 'Delete',
    successFn: () => navigate('/billing'),
  })

  const {
    button: FinalizeButton,
    modal: finalizeModal,
    isLoading: finalizeLoading,
  } = useInvoiceActions({
    actionFn: async () => {
      await finalizeInvoice()
    },
    refetchFn: refetch,
    successMessage: 'Successfully Finalized Invoice',
    errorMessage: 'Error Finalizing Invoice',
    modalConfirmationTitle: 'Finalize Invoice?',
    modalContent: finalizeModalContent,
    buttonVariant: ButtonVariant.FILLED,
    buttonText: 'Finalize',
  })

  const {
    button: ResendEmailButton,
    modal: resendEmailModal,
    isLoading: resendEmailLoading,
  } = useInvoiceActions({
    actionFn: async () => {
      await resendInvoiceEmail()
    },
    refetchFn: refetch,
    successMessage: 'Successfully Sent Email',
    errorMessage: 'Error Sending Email',
    modalConfirmationTitle: 'Resend New Invoice Email?',
    modalContent:
      'Are you sure you want to resend the "New Invoice" email to the business?',
    buttonVariant: ButtonVariant.PURPLEGRADIENT,
    buttonText: 'Resend Email',
  })

  const {
    button: GenerateReportButton,
    modal: generateReportModal,
    isLoading: reportLoading,
  } = useCustomReportActions({
    invoiceId,
    companyId: company?.companyId,
    successMessage: 'Report downloading.',
    errorMessage: 'There was an issue generating this report.',
    buttonVariant: ButtonVariant.PURPLEGRADIENT,
    buttonText: 'Generate Custom Report',
    invoiceStatus: invoice?.status,
    invoiceCreatedAt: invoice?.createdAt,
  })

  const {
    button: ManualResyncButton,
    modal: manualResyncModal,
    isLoading: manualResyncLoading,
  } = useInvoiceActions({
    actionFn: async () => {
      await resyncInvoice({
        formatByType: lineItemFormatType,
      })
    },
    refetchFn: refetch,
    successMessage: 'Successfully Resynced Invoice',
    errorMessage: 'Error Resyncing Invoice',
    modalConfirmationTitle: 'Resync Invoice?',
    modalContent: resyncModalContent,
    buttonVariant: ButtonVariant.FILLED,
    buttonText: 'Resync',
  })

  const addLineItem = async (lineItem: CreateLineItemDto) => {
    await addLineItemToInvoice(lineItem, {
      onSettled: async () => {
        await refetch()
      },
      onError: () => {
        showError('Failed to add line item to invoice')
      },
    })
  }

  const editLineItem = async (updates: EditLineItemDto) => {
    await editLineItemOnInvoice(
      { updates: [updates] },
      {
        onSettled: async () => {
          await refetch()
        },
        onError: () => {
          showError('Failed to edit line item on invoice')
        },
      },
    )
  }

  const deleteLineItem = async (lineItemId: string) => {
    await deleteLineItemFromInvoice([lineItemId], {
      onSettled: async () => {
        const isLastLineItem = invoice?.lineItems?.length === 1
        if (isLastLineItem) {
          showSuccess('Last Line Item deleted so invoice will be deleted')
          setTimeout(() => navigate('/billing'), 1000)
        } else {
          await refetch()
        }
      },
      onError: () => {
        showError('Failed to delete line item from invoice')
      },
    })
  }

  const updateMemo = async (newMemo: string) => {
    await updateMemoOnInvoice(newMemo, {
      onError: (err) => {
        showError(`Failed to update memo: ${err.message}`)
      },
    })
    await refetch()
  }

  const createStatement = useCallback(async () => {
    const statement = await createInvoiceStatement()
    openInNewTab(statement.downloadUrl)
  }, [createInvoiceStatement])

  if (isLoadingInvoice || isFetchingCompany) {
    return <CircularProgress size={20} />
  } else if (error || !invoice || !company) {
    const errorMessage = error?.message ?? 'fetching invoice failed'
    return <Badge variant="danger" title={'Error: ' + errorMessage}></Badge>
  }

  const isAnyButtonLoading =
    voidLoading ||
    finalizeLoading ||
    regenerateLoading ||
    deleteLoading ||
    manualResyncLoading ||
    reportLoading

  const invoiceActions = (
    <>
      {hotSettings?.enableGeneratingCustomReports && <GenerateReportButton />}
      {statsig.checkGate('invoice_statements') && (
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={createStatement}
          disabled={isCreatingInvoiceStatement}
          loading={isCreatingInvoiceStatement}
        >
          Create New PDF
        </Button>
      )}
      {invoice.status !== InvoiceStatus.DRAFT && (
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={downloadInvoiceStatement}
          disabled={isLoadingInvoiceStatement}
          loading={isLoadingInvoiceStatement}
        >
          Download PDF
        </Button>
      )}
      {invoice.status === InvoiceStatus.OPEN && (
        <>
          <VoidButton disabled={isAnyButtonLoading} />
          <RegenerateButton disabled={isAnyButtonLoading} />
          <ResendEmailButton disabled={resendEmailLoading} />
        </>
      )}
      {invoice.status === InvoiceStatus.DRAFT && (
        <>
          <DeleteButton disabled={isAnyButtonLoading} />
          <ManualResyncButton disabled={isAnyButtonLoading} />
          <FinalizeButton
            disabled={isAnyButtonLoading || !!invoice.requiresResync}
            tooltipText={
              invoice.requiresResync
                ? 'Please resync the invoice before finalizing'
                : ''
            }
          />
        </>
      )}
      <Link
        to={getInvoiceStripeUrl(invoice.stripeInvoiceId)}
        target="_blank"
        // Need flex so it doesn't expand to full width
        style={{ display: 'flex' }}
      >
        <Button variant={ButtonVariant.OUTLINED}>Open on Stripe</Button>
      </Link>
    </>
  )

  const header = (
    <Row
      style={{
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        paddingBottom: theme.space.xs,
      }}
      alignCenter
    >
      <Col style={{ flex: 1 }}>
        <Row gap={theme.space.xs} alignCenter>
          <Text variant="h4">Invoice {invoice.invoiceId}</Text>
          <InvoiceStatusBadge status={invoice.status} />
          {invoice.employmentType && (
            <EmploymentTypeBadge
              employmentTypes={[invoice.employmentType]}
              showAll
            />
          )}
          {!!invoice.fromInvoice?.id && (
            <Badge
              title="revision"
              variant="info"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/billing/invoices/${invoice.fromInvoice?.id}`)
              }}
            />
          )}
        </Row>
        <Row>
          <Anchor href={`/companies/${invoice.companyId}`}>
            <Icon name="link" style={{ marginRight: theme.space.xxxs }} />
            {company.employerName}
          </Anchor>
        </Row>
      </Col>
      <Row style={{ marginLeft: theme.space.xs }} gap={theme.space.xxs}>
        {invoiceActions}
      </Row>
    </Row>
  )

  return (
    <>
      <Col>
        {header}
        <HorizontalRule marginBottom={theme.space.xxxs} />
        <InvoiceDetailsBody
          lineItemsEditable={invoice.status === InvoiceStatus.DRAFT}
          companyId={invoice.companyId}
          stripeInvoiceNumber={invoice.stripeInvoiceNumber}
          invoiceId={invoice.invoiceId}
          dueDate={invoice.dueDate}
          stripeMemo={invoice.stripeMemo}
          lineItems={invoice.lineItems}
          totalCharge={invoice.totalCharge}
          handleAddLineItem={addLineItem}
          handleEditLineItem={editLineItem}
          handleDeleteLineItem={(lineItemId: string) => {
            setConfirmationProps({
              title: 'Are you sure you want to delete the line item?',
              content: 'This will permanently remove it from the invoice.',
              onConfirm: () => deleteLineItem(lineItemId),
              confirmButtonText: 'Delete',
            })
            setShowConfirmationDialog(true)
          }}
          handleUpdateMemo={updateMemo}
        />
      </Col>
      {regenerateModal}
      {voidModal}
      {deleteModal}
      {finalizeModal}
      {resendEmailModal}
      {manualResyncModal}
      {generateReportModal}
      {confirmationProps ? (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onClose={() => setShowConfirmationDialog(false)}
          {...confirmationProps}
        />
      ) : null}
    </>
  )
}
