import { ClickAwayListener, Popper } from '@mui/material'
import * as React from 'react'
import Icon, { Award } from '../Icon'
import * as S from './Badge.styles'
export interface BadgeProps {
  variant?: string
  round?: boolean
  sm?: boolean
  title: string
  style?: React.CSSProperties
  dismissible?: boolean
  onDismiss?: () => void
  onClick?: () => void
  short?: boolean
  /** If provided, is rendered in a popout */
  badgeDetails?: JSX.Element
  disableUppercase?: boolean
}

export default function Badge({
  title,
  variant = 'info',
  round,
  dismissible,
  onDismiss,
  disableUppercase,
  ...props
}: BadgeProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    },
    [setAnchorEl, anchorEl],
  )

  const handleClickAway = React.useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])
  const icon =
    variant === 'certification' ? (
      <Award
        color={S.colorVariants[variant].color}
        strokeWidth={2}
        size={12}
        style={{ marginRight: 6 }}
      />
    ) : null
  const badge = (
    <S.BadgeContainer
      dismissible={dismissible}
      round={round}
      variant={variant}
      clickable={!!props.badgeDetails}
      onClick={handleClick}
      disableUppercase={disableUppercase}
      {...props}
    >
      {icon}
      {title}
      {dismissible && (
        <Icon
          onClick={onDismiss}
          type="svg"
          name="cancel"
          style={{
            marginLeft: 10,
            cursor: 'pointer',
          }}
          color={S.colorVariants[variant].color}
          size={9}
        />
      )}
    </S.BadgeContainer>
  )
  if (props.badgeDetails) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          {badge}
          <Popper
            id="badge-details-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
          >
            {props.badgeDetails}
          </Popper>
        </div>
      </ClickAwayListener>
    )
  } else {
    return badge
  }
}
