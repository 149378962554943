import { StateSearchSelect } from '../base/SearchSelect/StateSearchSelect'
import { IMenuItem } from '../base/Select/Select'

type RegionsSelectorProps = {
  regionMenuOptions: IMenuItem[]
  selectedItems: string[]
  handleSelectMultiple: (regions: string[]) => void
  width?: string | number
  multipleNoneSelectedLabel?: string
}

export default function RegionsSelector(props: RegionsSelectorProps) {
  const {
    regionMenuOptions,
    selectedItems,
    handleSelectMultiple,
    width,
    multipleNoneSelectedLabel,
  } = props

  return (
    <StateSearchSelect
      multiple
      onlyShowLabel
      options={regionMenuOptions}
      selectedItems={selectedItems}
      handleSelectMultiple={handleSelectMultiple}
      multipleNoneSelectedLabel={multipleNoneSelectedLabel ?? 'All'}
      label={'Regions'}
      width={width ?? 260}
      showClearButton
    />
  )
}
