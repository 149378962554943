import { DialogActions, DialogProps as MuiDialogProps } from '@mui/material'
import styled from 'styled-components'
import Icon from '../Icon'

export interface DialogProps extends MuiDialogProps {
  children?: any
  dialogTitle: string | JSX.Element
  onClose: () => void
  onDismiss?: () => void
  confirmDisabled?: boolean
  confirmLoading?: boolean
  onConfirm?: () => void
  onConfirmCTA: string
  style?: { [key: string]: string | number }
  formId?: string
}

export const ButtonIcon = styled(Icon)`
  height: ${({ theme }) => theme.space.xs}px;
  width: ${({ theme }) => theme.space.xs}px;
  cursor: pointer;
`

export const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.sm}px
    ${({ theme }) => theme.space.med}px;
`

export const DialogStyling = styled.dialog`
  @media (min-width: ${({ theme }) => theme.media.minMedium}) {
    .MuiDialog-root {
      top: 15%;
      bottom: 15%;
    }
  }
`
