import * as S from './CostSummary.styles'

export const FunnyMoney = ({
  amount,
  superSize,
  strikeThrough,
  style,
}: {
  amount: number | number[]
  superSize?: boolean
  strikeThrough?: boolean
  style?: React.CSSProperties
}) => {
  const amountArray = Array.isArray(amount) ? amount : [amount]
  const dedupedArray = amountArray.filter(
    (a, i, arr) => i < 1 || arr[i - 1] !== a,
  )

  const magicComponents = dedupedArray.map((value, index) => {
    const splitMoney = value.toFixed(2).split('.')
    return (
      <S.FunnyMoneyWrapper strikeThrough={!!strikeThrough} style={style}>
        {index > 0 && <>{' - '}</>}
        <S.LargeAmountSuper key={index}>$</S.LargeAmountSuper>
        {superSize ? (
          <S.LargeAmount>{splitMoney[0]}</S.LargeAmount>
        ) : (
          <>{splitMoney[0]}</>
        )}

        <S.LargeAmountSub>.{splitMoney[1]}</S.LargeAmountSub>
      </S.FunnyMoneyWrapper>
    )
  })
  return <>{magicComponents}</>
}
