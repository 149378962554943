import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import {
  Row,
  Text,
  Input,
  Button,
  Col,
  ButtonVariant,
  LoadingSpinner,
  MediaUploader,
  SelectDropdown,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AddressDto,
  CompanyCategory,
  CoordinatesDto,
  FullAddress,
  InputStatus,
} from '@traba/types'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete'
import { useDemoAccountsMutations } from 'src/hooks/useDemoAccounts'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import * as yup from 'yup'

const DemoableCompanyCategoryValues: Partial<{
  [key in CompanyCategory]: string
}> = {
  [CompanyCategory.FOOD_PRODUCTION]: 'Food Production',
  [CompanyCategory.MANUFACTURING]: 'Manufacturing',
  [CompanyCategory.THREE_PL]: '3PL',
  [CompanyCategory.DISTRIBUTION]: 'Distribution',
}

const DEFAULT_COORDS = {
  latitude: 25.765523,
  longitude: -80.1951192,
}

const DEFAULT_EXPIRATION_DAYS = 7
const DEFAULT_WORKER_COUNT = 100

const INITIAL_DEMO_ACCOUNT_DATA = {
  companyName: '',
  companyLogo: '',
  category: '' as CompanyCategory,
  address: {
    city: '',
    street1: '',
    street2: '',
    postalCode: '',
    state: '',
  } as AddressDto,
  coords: {
    latitude: DEFAULT_COORDS.latitude,
    longitude: DEFAULT_COORDS.longitude,
  } as CoordinatesDto,
  address2: {
    city: '',
    street1: '',
    street2: '',
    postalCode: '',
    state: '',
  } as AddressDto,
  coords2: {
    latitude: DEFAULT_COORDS.latitude,
    longitude: DEFAULT_COORDS.longitude,
  } as CoordinatesDto,
}

const validationSchema = yup.object({
  companyName: yup.string().required(REQUIRED_FIELD_MESSAGE),
  companyLogo: yup.string().required(REQUIRED_FIELD_MESSAGE),
  category: yup.string().required(REQUIRED_FIELD_MESSAGE),
  address: yup.object({
    city: yup.string().required(REQUIRED_FIELD_MESSAGE),
    street1: yup.string().required(REQUIRED_FIELD_MESSAGE),
    street2: yup.string(),
    postalCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
    state: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  address2: yup.object({
    city: yup.string().required(REQUIRED_FIELD_MESSAGE),
    street1: yup.string().required(REQUIRED_FIELD_MESSAGE),
    street2: yup.string(),
    postalCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
    state: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
})

export default function DemoCreateForm() {
  const { showError } = useAlert()
  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false)
  const [logoFile, setLogoFile] = useState<File | undefined>()
  const { handleUpload } = useFileUploader()
  const { createDemoAccount, isCreatingDemo } = useDemoAccountsMutations()

  const formik = useFormik({
    initialValues: INITIAL_DEMO_ACCOUNT_DATA,
    validationSchema,
    onSubmit: async (values) => {
      await createDemoAccount({
        ...values,
        daysToExpiration: DEFAULT_EXPIRATION_DAYS, // Default expiration days
        workerCount: DEFAULT_WORKER_COUNT, // Default worker count
      })
    },
  })

  const handleChangePlacesAutocomplete = (
    val: FullAddress,
    fieldPrefix: string,
    version = '',
  ) => {
    formik.setFieldValue(`${fieldPrefix}.street1`, val.street1)
    formik.setFieldValue(`${fieldPrefix}.street2`, val.street2)
    formik.setFieldValue(`${fieldPrefix}.city`, val.city)
    formik.setFieldValue(`${fieldPrefix}.state`, val.state)
    formik.setFieldValue(`${fieldPrefix}.postalCode`, val.postalCode)
    formik.setFieldValue(`coords${version}.latitude`, val.location?.latitude)
    formik.setFieldValue(`coords${version}.longitude`, val.location?.longitude)
  }

  async function onChangeLogoFile(f: File | undefined) {
    if (!f) {
      return
    }
    setLogoFile(f)
    if (f) {
      setCompanyLogoLoading(true)
      const url = await handleUpload({
        fileType: FileType.COMPANY_IMAGES,
        media: f,
        userId: 'demo-account',
      })
      setCompanyLogoLoading(false)
      formik.setFieldValue('companyLogo', url)
    }
  }

  async function onDeleteFile(
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  ) {
    setFile(undefined)
  }

  const { touched, errors } = formik
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        width: '50%',
        marginRight: theme.space.lg,
      }}
    >
      <Col style={{ width: '100%' }} mt={theme.space.xs}>
        <Text variant="h6">Company Name</Text>
        <Input
          full
          {...formik.getFieldProps('companyName')}
          inputStatus={
            touched.companyName && errors.companyName
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.companyName}
        />
      </Col>
      <Col style={{ width: '100%' }} mt={theme.space.xs}>
        <Text variant="h6">Company Logo</Text>
        <MediaUploader
          id="company-logo-uploader"
          label="Upload Logo"
          file={logoFile}
          onChange={onChangeLogoFile}
          onDelete={() => onDeleteFile(setLogoFile)}
          onError={(error) => {
            showError(error, 'File upload/delete error. Please try again.')
          }}
          fileType="image"
          maxFileSizeMB={1}
        />
        {companyLogoLoading && <LoadingSpinner />}
      </Col>
      <Col style={{ width: '100%' }} mt={theme.space.xs}>
        <Text variant="h6">Category</Text>
        <SelectDropdown
          label="Category"
          value={formik.values.category}
          handleSelect={(value) => formik.setFieldValue('category', value)}
          menuItems={Object.entries(DemoableCompanyCategoryValues).map(
            ([key, label]) => ({
              value: key,
              label,
            }),
          )}
          inputStatus={
            touched.category && errors.category
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.category}
        />
      </Col>
      <Col style={{ width: '100%' }} mt={theme.space.xs}>
        <Text variant="h6">Address</Text>
        <PlacesAutocomplete
          onSelect={(val) => handleChangePlacesAutocomplete(val, 'address')}
          onChange={(val) => formik.setFieldValue('address.street1', val)}
          value={formik.values.address.street1}
          label="Street Address"
          errorMessage={
            touched.address?.street1 && errors.address?.street1
              ? errors.address?.street1
              : null
          }
        />
        <Input
          full
          label="Floor/Suite (optional)"
          {...formik.getFieldProps('address.street2')}
          inputStatus={
            touched.address?.street2 && errors.address?.street2
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.street2}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="City"
          {...formik.getFieldProps('address.city')}
          inputStatus={
            touched.address?.city && errors.address?.city
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.city}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="State / Province"
          {...formik.getFieldProps('address.state')}
          inputStatus={
            touched.address?.state && errors.address?.state
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.state}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          type="text"
          pattern="\d*"
          maxLength={5}
          full
          label="ZIP Code"
          {...formik.getFieldProps('address.postalCode')}
          inputStatus={
            touched.address?.postalCode && errors.address?.postalCode
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address?.postalCode}
          containerStyle={{ marginTop: theme.space.xs }}
        />
      </Col>
      <Col style={{ width: '100%' }} mt={theme.space.xs}>
        <Text variant="h6">Address 2</Text>
        <PlacesAutocomplete
          onSelect={(val) =>
            handleChangePlacesAutocomplete(val, 'address2', '2')
          }
          onChange={(val) => formik.setFieldValue('address2.street1', val)}
          value={formik.values.address2.street1}
          label="Street Address"
          errorMessage={
            touched.address2?.street1 && errors.address2?.street1
              ? errors.address2?.street1
              : null
          }
        />
        <Input
          full
          label="Floor/Suite (optional)"
          {...formik.getFieldProps('address2.street2')}
          inputStatus={
            touched.address2?.street2 && errors.address2?.street2
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address2?.street2}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="City"
          {...formik.getFieldProps('address2.city')}
          inputStatus={
            touched.address2?.city && errors.address2?.city
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address2?.city}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          full
          label="State / Province"
          {...formik.getFieldProps('address2.state')}
          inputStatus={
            touched.address2?.state && errors.address2?.state
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address2?.state}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          type="text"
          pattern="\d*"
          maxLength={5}
          full
          label="ZIP Code"
          {...formik.getFieldProps('address2.postalCode')}
          inputStatus={
            touched.address2?.postalCode && errors.address2?.postalCode
              ? InputStatus.error
              : InputStatus.default
          }
          errorMessage={errors.address2?.postalCode}
          containerStyle={{ marginTop: theme.space.xs }}
        />
      </Col>
      <Row fullWidth justifyBetween mt={theme.space.med}>
        <Button
          loading={formik.isSubmitting || companyLogoLoading || isCreatingDemo}
          onClick={async () => {
            await createDemoAccount({
              ...formik.values,
              daysToExpiration: DEFAULT_EXPIRATION_DAYS, // Default expiration days
              workerCount: DEFAULT_WORKER_COUNT, // Default worker count
            })
          }}
          variant={ButtonVariant.FILLED}
          type="submit"
          style={{ height: theme.space.xl, minWidth: '20%' }}
        >
          Create
        </Button>
      </Row>
    </form>
  )
}
