import { PaymentStatus } from '@traba/types'

export const earningsPaymentBadgeVariant: Record<PaymentStatus, string> = {
  [PaymentStatus.ManualComplete]: 'info',
  [PaymentStatus.NeedsReview]: 'pink',
  [PaymentStatus.Overpaid]: 'warning',
  [PaymentStatus.Paid]: 'success',
  [PaymentStatus.PayoutFailed]: 'opaqueRed',
  [PaymentStatus.Underpaid]: 'danger',
  [PaymentStatus.PayoutDisabled]: 'opaqueRed',
}
