import { CircularProgress, Switch, Tooltip } from '@mui/material'
import { Text, Dialog, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { OperationType, Region, RegionStatus } from '@traba/types'
import { compact, debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useUserContext } from 'src/context/user/UserContext'
import {
  internalUserHasOpsAdminPrivileges,
  internalUserHasOpsLeadPrivileges,
} from 'src/utils/internalUserUtils'
import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Select,
} from '../../components/base'
import Checkbox from '../../components/base/Checkbox'
import Divider from '../../components/base/Divider'
import { NumberInput } from '../../components/base/Input/NumberInput'
import { MODAL_SIZE } from '../../components/base/Modal/types'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import {
  bgcFlowTypeItems,
  useRegions,
  workerTierItems,
} from '../../hooks/useRegions'
import { getInvalidPostalCodes } from '../../utils/helperUtils'
import CreateRegionModal from './CreateRegionModal'

type UpdateRegionFieldOptions =
  | 'status'
  | 'backgroundCheckFlow'
  | 'minimumAcceptedTier'
  | 'unprovenWorkerThreshold'
  | 'overbookMultiplier'
  | 'showSearch'
  | 'activateSearch'

function RowItem({
  label,
  value,
}: {
  label: string
  value: string | number | boolean
}) {
  return (
    <Row
      fullWidth
      style={{
        borderBottom: `1px solid ${theme.colors.Grey20}`,
        minimumHeight: 40,
      }}
      alignCenter
      justifyStart
    >
      <Text
        ml={theme.space.xs}
        mt={theme.space.xxs}
        style={{ width: 300 }}
        variant="label"
      >
        {label}
      </Text>
      <Text>{typeof value === 'boolean' ? (value ? 'YES' : 'NO') : value}</Text>
    </Row>
  )
}

function RegionSettingsCard({
  displayRegion,
  handleUpdateField,
  handleUpdateAcceptingSignups,
  isUpdatingRegion,
  isGettingRegion,
}: {
  displayRegion: Region
  isUpdatingRegion: boolean
  isGettingRegion: boolean
  handleUpdateField: (
    field: UpdateRegionFieldOptions,
    update: string | boolean | number,
  ) => void
  handleUpdateAcceptingSignups: (
    regionId: string,
    acceptingSignups: boolean,
  ) => void
}) {
  const [unprovenWorkerThreshold, setUnprovenWorkerThreshold] = useState<
    number | undefined
  >(displayRegion.unprovenWorkerThreshold)
  const [overbookMultiplier, setOverbookMultiplier] = useState<
    number | undefined
  >(displayRegion.overbookMultiplier)
  // Debounce the saveToDatabase function with a small delay
  const debouncedSaveToDatabase = debounce(handleUpdateField, 1000)

  const handleSetUnprovenThreshold = (value = 0) => {
    const valueToSet = value < 0 ? 0 : value > 1 ? 1 : value
    setUnprovenWorkerThreshold(valueToSet)
    debouncedSaveToDatabase('unprovenWorkerThreshold', valueToSet)
  }

  const handleSetOverbookMultiplier = (value = 0) => {
    const valueToSet = value < 0 ? 0 : value > 2 ? 2 : value
    setOverbookMultiplier(valueToSet)
    debouncedSaveToDatabase('overbookMultiplier', valueToSet)
  }

  // Update the local state when the displayRegion changes
  useEffect(() => {
    setUnprovenWorkerThreshold(displayRegion.unprovenWorkerThreshold)
    setOverbookMultiplier(displayRegion.overbookMultiplier)
  }, [displayRegion])

  return (
    <Row
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        borderRadius: theme.border.radius,
        width: 800,
      }}
      py={theme.space.xs}
      flexCol
    >
      <Text variant="h6" px={theme.space.xs} mb={theme.space.xs}>
        Region settings
      </Text>
      <Divider />
      <Row justifyStart flexCol>
        <Row mt={theme.space.xxs}>
          <Col ml={theme.space.xs}>
            <Text mt={theme.space.xxs} style={{ width: 300 }} variant="h7">
              Worker signups
            </Text>
            <Row alignCenter>
              <Text variant="label">
                Current status:{' '}
                {displayRegion.acceptingSignups ? 'Enabled' : 'Disabled'}{' '}
                {displayRegion.acceptingSignups ? (
                  <Icon name="greenCheck" />
                ) : (
                  <Icon name="close_filled" style={{ width: 14, height: 14 }} />
                )}
              </Text>
            </Row>
          </Col>
          <Row alignCenter mr={theme.space.xs}>
            <Button
              variant={ButtonVariant.PURPLEGRADIENT}
              onClick={() =>
                handleUpdateAcceptingSignups(displayRegion.regionId, false)
              }
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 160 }}
              disabled={!displayRegion.acceptingSignups}
              loading={isUpdatingRegion || isGettingRegion}
            >
              Disable
            </Button>
            <Button
              onClick={async () =>
                handleUpdateAcceptingSignups(displayRegion.regionId, true)
              }
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 160 }}
              disabled={displayRegion.acceptingSignups}
              loading={isUpdatingRegion || isGettingRegion}
            >
              Enable
            </Button>
          </Row>
        </Row>
        <Row>
          <Col ml={theme.space.xs}>
            <Text mt={theme.space.xxs} style={{ width: 300 }} variant="h7">
              Business postings
            </Text>
            <Row alignCenter>
              <Text variant="label">
                Current status:{' '}
                {displayRegion.status === RegionStatus.active
                  ? 'Enabled'
                  : 'Disabled'}{' '}
                {displayRegion.status === RegionStatus.active ? (
                  <Icon name="greenCheck" />
                ) : (
                  <Icon name="close_filled" style={{ width: 14, height: 14 }} />
                )}
              </Text>
            </Row>
          </Col>
          <Row alignCenter mr={theme.space.xs}>
            <Button
              variant={ButtonVariant.PURPLEGRADIENT}
              onClick={() => handleUpdateField('status', RegionStatus.inactive)}
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 160 }}
              disabled={displayRegion.status !== RegionStatus.active}
              loading={isUpdatingRegion || isGettingRegion}
            >
              Disable
            </Button>
            <Button
              onClick={() => handleUpdateField('status', RegionStatus.active)}
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 160 }}
              disabled={displayRegion.status === RegionStatus.active}
              loading={isUpdatingRegion || isGettingRegion}
            >
              Launch
            </Button>
          </Row>
        </Row>
        <Row>
          <Col ml={theme.space.xs}>
            <Text mt={theme.space.xxs} style={{ width: 400 }} variant="h7">
              Activate Worker Search (show this region in search when a company
              has it turned on)
            </Text>
            <Row alignCenter>
              <Text variant="label">
                Current status:{' '}
                {displayRegion.activateSearch ? 'Enabled' : 'Disabled'}{' '}
                {displayRegion.activateSearch ? (
                  <Icon name="greenCheck" />
                ) : (
                  <Icon name="close_filled" style={{ width: 14, height: 14 }} />
                )}
              </Text>
            </Row>
          </Col>
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={displayRegion.activateSearch}
            onClick={() =>
              handleUpdateField('activateSearch', !displayRegion.activateSearch)
            }
          />
        </Row>
        <Row>
          <Col ml={theme.space.xs}>
            <Text mt={theme.space.xxs} style={{ width: 400 }} variant="h7">
              Show Worker Search (turn search on for all companies in this
              region)
            </Text>
            <Row alignCenter>
              <Text variant="label">
                Current status:{' '}
                {displayRegion.showSearch ? 'Enabled' : 'Disabled'}{' '}
                {displayRegion.showSearch ? (
                  <Icon name="greenCheck" />
                ) : (
                  <Icon name="close_filled" style={{ width: 14, height: 14 }} />
                )}
              </Text>
            </Row>
          </Col>
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={displayRegion.showSearch}
            onClick={() =>
              handleUpdateField('showSearch', !displayRegion.showSearch)
            }
          />
        </Row>
        <Row mx={theme.space.xs} mt={theme.space.xs} fullWidth justifyBetween>
          <Text my={theme.space.xxs} variant="h7">
            Unproven threshold
          </Text>
          <NumberInput
            value={unprovenWorkerThreshold}
            setValue={handleSetUnprovenThreshold}
            decimals={2}
            containerStyle={{ width: 200 }}
            min={0}
            max={1}
            step={0.01}
          />
        </Row>
        <Row mx={theme.space.xs} mt={theme.space.xs} fullWidth justifyBetween>
          <Text my={theme.space.xxs} variant="h7">
            Overbooking multiplier
          </Text>
          <NumberInput
            value={overbookMultiplier}
            setValue={handleSetOverbookMultiplier}
            decimals={2}
            containerStyle={{ width: 200 }}
            min={0}
            max={2}
            step={0.01}
          />
        </Row>
        <Col mx={theme.space.xs} mb={theme.space.xxs}>
          <Text my={theme.space.xxs} style={{ width: 300 }} variant="h7">
            Background check type
          </Text>
          <Select
            fullWidth
            value={displayRegion.backgroundCheckFlow || ''}
            handleSelect={(value) =>
              handleUpdateField('backgroundCheckFlow', value)
            }
            menuItems={bgcFlowTypeItems}
            disabled={isUpdatingRegion || isGettingRegion}
          />
        </Col>
        <Col mx={theme.space.xs} my={theme.space.xxs}>
          <Text my={theme.space.xxs} style={{ width: 300 }} variant="h7">
            Minimum worker tier
          </Text>
          <Select
            fullWidth
            value={displayRegion.minimumAcceptedTier || ''}
            handleSelect={(value) =>
              handleUpdateField('minimumAcceptedTier', value)
            }
            menuItems={workerTierItems}
            disabled={isUpdatingRegion || isGettingRegion}
          />
        </Col>
      </Row>
    </Row>
  )
}

function RegionsScreen() {
  const { state } = useUserContext()
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<Region>()
  // Check postal code state
  const [showPostalCodeModal, setShowPostalCodeModal] = useState(false)
  const [postalCodeToSearch, setPostalCodeToSearch] = useState('')
  const [postalCodeRegion, setPostalCodeRegion] = useState('')
  // Manage postal codes state
  const [postalCodesToUpdate, setPostalCodesToUpdate] = useState<string>()
  const [showManagePostalCodesModal, setShowManagePostalCodesModal] =
    useState(false)
  const [shouldNotifyWorkers, setShouldNotifyWorkers] = useState(false)
  const [hasInvalidPostalCodes, setHasInvalidPostalCodes] = useState('')
  const [postalCodesToSkip, setPostalCodesToSkip] =
    useState<{ regionId: string; postalCode: string }[]>()
  // Create region state
  const [showCreateRegionModal, setShowCreateRegionModal] = useState(false)

  const {
    regions = [],
    isLoading: isLoadingRegions,
    updateRegion,
    isUpdating,
    getRegion,
    isGettingRegion,
    getRegionByPostalCode,
    updateRegionPostalCodes,
    updateRegionAcceptingSignups,
  } = useRegions()
  const navigate = useNavigate()

  const canCreateRegions = useMemo(
    () => internalUserHasOpsAdminPrivileges(state.userProfile?.internalUser),
    [state.userProfile?.internalUser],
  )

  const canEditRegions = useMemo(
    () => internalUserHasOpsLeadPrivileges(state.userProfile?.internalUser),
    [state.userProfile?.internalUser],
  )

  // Find postal code
  const handlePostalCodeToSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCodeToSearch(e.target.value)
  }
  const handleGetPostalCodeRegion = async () => {
    const region = await getRegionByPostalCode(postalCodeToSearch)
    setPostalCodeRegion(
      region?.regionId || 'No region found for this postal code',
    )
  }

  const handleTogglePostalCodeModal = () => {
    setShowPostalCodeModal(!showPostalCodeModal)
    setPostalCodeToSearch('')
    setPostalCodeRegion('')
  }

  const handleLoadPostalCodeRegion = async () => {
    const region = await getRegionByPostalCode(postalCodeToSearch)
    setSelectedRegion(region)
    setShowPostalCodeModal(false)
  }

  // Postal codes update related logic
  const handlePostalCodesToUpdate = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPostalCodesToUpdate(e.target.value)
    const postalCodesInput = e.target.value.split(',').map((p) => p.trim())
    const invalidPostalCodes = getInvalidPostalCodes(postalCodesInput)
    setHasInvalidPostalCodes(invalidPostalCodes.join(', '))
  }

  const runUpdateRegionPostalCodes = async (operationType: OperationType) => {
    const postalCodes = postalCodesToUpdate
      ? postalCodesToUpdate.split(',').map((p) => p.trim())
      : []

    // check if the postal codes are already in use in other region
    const postalCodesInRegions = compact(
      await Promise.all(
        postalCodes.map(async (p) => {
          const regionWithCode = await getRegionByPostalCode(p)

          // if the postal code is already in use in the region we are updating we allow it to be removed
          const validCodeForRegion =
            !regionWithCode ||
            (regionWithCode.regionId === displayRegion.regionId &&
              operationType === OperationType.REMOVE)
          if (validCodeForRegion) {
            return
          }
          return {
            postalCode: p,
            regionId: regionWithCode.regionId,
          }
        }),
      ),
    )

    if (postalCodesInRegions.length) {
      return setPostalCodesToSkip(postalCodesInRegions)
    }

    setPostalCodesToSkip(undefined)
    setPostalCodesToUpdate(undefined)
    await updateRegionPostalCodes(
      displayRegion.regionId,
      postalCodes,
      operationType,
      shouldNotifyWorkers,
    )
    handleRefetchRegion(displayRegion.regionId)
    handleToggleManageModal()
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleDialogConfirm = () => {
    runUpdateRegionPostalCodes(OperationType.ADD)
    setOpenDialog(false)
  }

  const handleToggleManageModal = () => {
    setShowManagePostalCodesModal(!showManagePostalCodesModal)
  }

  // Create region related logic
  const handleToggleCreateRegionModal = () => {
    setShowCreateRegionModal(!showCreateRegionModal)
  }

  // Region related logic
  const displayRegion = selectedRegion || regions[0]

  const handleSelectedRegionChange = (region: Region | undefined) => {
    setSelectedRegion(region)
    setPostalCodesToUpdate(undefined)
  }

  const handleRefetchRegion = async (regionId: string) => {
    const region = await getRegion(regionId)
    setSelectedRegion(region)
  }

  const handleUpdateAcceptingSignups = async (
    regionId: string,
    acceptingSignups: boolean,
  ) => {
    await updateRegionAcceptingSignups({ regionId, acceptingSignups })
    handleRefetchRegion(regionId)
  }

  const updateRegionField = async (
    field: UpdateRegionFieldOptions,
    update: string | boolean | number,
  ) => {
    await updateRegion(displayRegion.regionId, { [field]: update })
    handleRefetchRegion(displayRegion.regionId)
  }

  if (isLoadingRegions) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <CircularProgress
            size={75}
            sx={{
              position: 'absolute',
              top: '200px',
              left: '50%',
              marginTop: '-18px',
              marginLeft: '-18px',
            }}
          />
        </Row>
      </MainLayout>
    )
  }

  if (!regions.length) {
    return (
      <MainLayout>
        <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
          <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
          Go back
        </Button>
        <Row center alignCenter>
          <Text variant="h4">No regions found</Text>
        </Row>
      </MainLayout>
    )
  }

  const selectedItem = selectedRegion
    ? {
        label: selectedRegion.displayName,
        value: selectedRegion.regionId,
      }
    : regions.length
      ? { label: regions[0].displayName, value: regions[0].regionId }
      : undefined

  return (
    <MainLayout title={`Regions - ${selectedItem?.label}`}>
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Go back
      </Button>
      <Row fullWidth alignCenter justifyBetween>
        <Text variant="h4">Regions</Text>
        <Tooltip
          title={
            "You don't have the required permissions to create a new region"
          }
          disableHoverListener={canCreateRegions}
        >
          <div>
            <Button
              variant={ButtonVariant.PURPLEGRADIENT}
              onClick={handleToggleCreateRegionModal}
              ml={theme.space.xs}
              style={{ height: theme.space.xl, width: 180 }}
              disabled={!canCreateRegions}
            >
              Create New
            </Button>
          </div>
        </Tooltip>
      </Row>

      <Row fullWidth alignCenter my={theme.space.xs}>
        <Text mr={theme.space.xs}>Select a region</Text>
        <SearchSelect
          options={regions.map((r) => ({
            label: r.displayName,
            value: r.regionId,
          }))}
          selectItem={selectedItem}
          handleSelect={(item) =>
            handleSelectedRegionChange(
              regions.find((r) => r.regionId === item?.value),
            )
          }
          label={'Target regions'}
          onlyShowLabel
          width={350}
          disabled={isLoadingRegions}
        />
      </Row>

      <Row fullWidth>
        <Row mr={theme.space.xxs}>
          <Row
            style={{
              border: `2px solid ${theme.colors.Grey20}`,
              borderRadius: theme.border.radius,
              width: 500,
            }}
            py={theme.space.xs}
            flexCol
          >
            <Text ml={theme.space.xs} mb={theme.space.xxs} variant="h6">
              Region details
            </Text>
            <Row
              flexCol
              style={{
                borderTop: `2px solid ${theme.colors.Grey20}`,
                borderRadius: theme.border.radius,
              }}
            >
              <RowItem
                label="Display name"
                value={displayRegion.displayName || ''}
              />
              <RowItem label="Region ID" value={displayRegion.regionId || ''} />
              <RowItem label="Status" value={displayRegion.status || ''} />
              <RowItem
                label="Accepting signups"
                value={!!displayRegion.acceptingSignups}
              />
              <RowItem
                label="Background Check type"
                value={displayRegion.backgroundCheckFlow || ''}
              />
              <RowItem
                label="Minimum accepted tier"
                value={displayRegion.minimumAcceptedTier || ''}
              />

              <RowItem
                label="Unproven worker threshold"
                value={displayRegion.unprovenWorkerThreshold ?? ''}
              />
              <RowItem
                label="Overbook multiplier"
                value={displayRegion.overbookMultiplier ?? ''}
              />
              <RowItem
                label="Country Code"
                value={displayRegion.countryCode ?? ''}
              />
            </Row>
          </Row>
        </Row>
        <Row flexCol fullWidth ml={theme.space.xxs}>
          <Row
            style={{
              border: `2px solid ${theme.colors.Grey20}`,
              borderRadius: theme.border.radius,
            }}
            fullWidth
            py={theme.space.xs}
            mb={theme.space.xxs}
          >
            <Col>
              <Text variant="h6" mb={theme.space.xxs} px={theme.space.xs}>
                Manage postal codes
              </Text>
              <Divider />
              <Row
                fullWidth
                justifyBetween
                my={theme.space.xs}
                px={theme.space.xs}
              >
                {
                  <Tooltip
                    title={
                      "You don't have the required permissions to edit postal codes"
                    }
                    disableHoverListener={canEditRegions}
                  >
                    <div>
                      <Button
                        slim
                        variant={ButtonVariant.TEXT}
                        onClick={handleToggleManageModal}
                        disabled={!canEditRegions}
                      >
                        Click to add or remove postal codes
                      </Button>
                    </div>
                  </Tooltip>
                }
                <Text
                  variant="brand"
                  style={{ cursor: 'pointer' }}
                  onClick={handleTogglePostalCodeModal}
                >
                  Check postal code region
                </Text>
              </Row>
              <Row px={theme.space.xs}>
                <textarea
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    maxHeight: '500px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: theme.space.xxs,
                    boxSizing: 'border-box',
                  }}
                  value={
                    displayRegion.postalCodes.join(', ') || 'no postal codes'
                  }
                  onChange={() => null}
                />
              </Row>
            </Col>
          </Row>
          {!isLoadingRegions && (
            <RegionSettingsCard
              displayRegion={displayRegion}
              handleUpdateField={updateRegionField}
              handleUpdateAcceptingSignups={handleUpdateAcceptingSignups}
              isUpdatingRegion={isUpdating}
              isGettingRegion={isGettingRegion}
            />
          )}
        </Row>
      </Row>

      <Modal
        isOpen={showManagePostalCodesModal}
        handleClose={handleToggleManageModal}
        size={MODAL_SIZE.LARGE}
        style={{ width: 800 }}
        title="Manage a region postal codes"
      >
        <Col style={{ flex: 1 }} py={theme.space.med}>
          <Input
            rows={6}
            type="textarea"
            className="xs-12"
            value={postalCodesToUpdate || ''}
            maxLength={5000}
            onChange={handlePostalCodesToUpdate}
            full
          />
          {hasInvalidPostalCodes && (
            <Text variant="error" mt={theme.space.xs}>
              Invalid postal codes: {hasInvalidPostalCodes}
            </Text>
          )}
          {postalCodesToSkip && (
            <Col style={{ flex: 1 }}>
              <Text variant="body1" mt={theme.space.xs}>
                The following postal codes are already in use in other regions,
                please remove from the update or the original region and try
                again:
              </Text>
              <Col style={{ flex: 1, overflowY: 'auto' }}>
                {postalCodesToSkip.map((p) => (
                  <Row key={p.postalCode}>
                    <Text variant="error" mt={theme.space.xs}>
                      {p.postalCode} - {p.regionId}
                    </Text>
                  </Row>
                ))}
              </Col>
            </Col>
          )}
        </Col>
        <Row mt={theme.space.xs} alignCenter justifyEnd>
          <Checkbox
            checked={shouldNotifyWorkers}
            onChange={() => setShouldNotifyWorkers(!shouldNotifyWorkers)}
            label={'Notify workers in those regions'}
            style={{ marginRight: theme.space.med }}
          />
          <Button
            onClick={() => setOpenDialog(true)}
            style={{ marginRight: theme.space.xs }}
            disabled={!postalCodesToUpdate || !!hasInvalidPostalCodes}
            loading={isUpdating}
          >
            Add Codes
          </Button>
          <Button
            onClick={() => runUpdateRegionPostalCodes(OperationType.REMOVE)}
            variant={ButtonVariant.CANCEL}
            disabled={!postalCodesToUpdate || !!hasInvalidPostalCodes}
            loading={isUpdating}
          >
            Remove Codes
          </Button>
          <Dialog
            dialogTitle={'Zip Code Validation'}
            open={openDialog}
            onClose={() => handleDialogClose()}
            onConfirm={() => handleDialogConfirm()}
          >
            Please confirm that you have verified that the zip codes you are
            adding to the region are areas where Traba can operate.
          </Dialog>
        </Row>
      </Modal>

      <Modal
        isOpen={showPostalCodeModal}
        handleClose={handleTogglePostalCodeModal}
        size={MODAL_SIZE.DIALOG}
        title="Check postal code region"
      >
        <Col style={{ flex: 1 }} py={theme.space.med}>
          <Row justifyStart>
            <Input
              placeholder="postal code"
              containerStyle={{ width: 200, marginRight: theme.space.xxs }}
              width="200px"
              onChange={handlePostalCodeToSearch}
            />
            <Button onClick={handleGetPostalCodeRegion}>Search</Button>
          </Row>
          <Row mt={theme.space.xs}>
            {postalCodeRegion && (
              <Col>
                <Text my={theme.space.xxs}>This postal code is in use in:</Text>
                <Text variant="h7" my={theme.space.xxs}>
                  {' '}
                  {postalCodeRegion}
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        <Row fullWidth justifyEnd py={theme.space.xs} px={theme.space.xs}>
          {postalCodeRegion && (
            <Button
              onClick={handleLoadPostalCodeRegion}
              variant={ButtonVariant.TRANSPARENT}
            >
              Load this region
            </Button>
          )}
        </Row>
      </Modal>

      <CreateRegionModal
        show={showCreateRegionModal}
        handleClose={handleToggleCreateRegionModal}
        handleSetRegion={(regionId: string) => handleRefetchRegion(regionId)}
      />
    </MainLayout>
  )
}

export default RegionsScreen
