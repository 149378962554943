export enum TrainingShiftStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  NoShow = 'NO_SHOW',
  Canceled = 'CANCELED',
}

export enum FeedbackType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export type TrainingShift = {
  createdAt: Date
  updatedAt?: Date
  startTime: Date
  clockInAt: Date | null
  clockOutAt: Date | null
  workerId: string
  status: TrainingShiftStatus | string
  quizScore: number | null
  feedbackType: FeedbackType | string | null
  isConfirmed: boolean | null
}
