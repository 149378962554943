import { ListItem, ListItemText } from '@mui/material'
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { createOrGetZealAccountLink, useZeal } from 'src/hooks/useZeal'

interface W2WorkerDetailsProps {
  workerId: string
}

export const W2WorkerDetails = (props: W2WorkerDetailsProps) => {
  const { workerId } = props
  const { isZealLoading, zealEmployeeData } = useZeal({
    workerId,
    enabled: true,
  })
  const [zealLink, setZealLink] = useState<string | undefined>(undefined)

  if (isZealLoading) {
    return <LoadingSpinner />
  }
  return (
    <ListItem disableGutters>
      <ListItemText
        style={{
          paddingLeft: theme.space.xs,
        }}
      >
        <Text variant="h5" mb={theme.space.xs}>
          W2 Worker Management
        </Text>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Zeal onboarding complete:
          </Text>
          <Text>{zealEmployeeData?.onboarded ? 'YES' : 'No'}</Text>
        </Row>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Employment status:
          </Text>
          <Text>{zealEmployeeData?.employment_status}</Text>
        </Row>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Start date:
          </Text>
          <Text>
            {zealEmployeeData?.start_date
              ? zealEmployeeData.start_date.toString()
              : 'Missing start date'}
          </Text>
        </Row>
        <Row alignCenter mb={theme.space.xxs}>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            E-Verify status:
          </Text>
          <Text>{zealEmployeeData?.everify_status}</Text>
        </Row>

        {zealLink ? (
          <Row>
            <Text variant="body1">Copy generated link</Text>
            <CopyTextIcon textToCopy={zealLink} />
          </Row>
        ) : (
          <Button
            full
            variant={ButtonVariant.OUTLINED}
            onClick={async () => {
              const link = await createOrGetZealAccountLink(workerId)
              setZealLink(link)
            }}
          >
            Generate link to Zeal for worker
          </Button>
        )}
      </ListItemText>
    </ListItem>
  )
}
