import { Company } from './companies'
import { Shift } from './shifts'
import { WorkerShiftForOps as WorkerShift } from './worker-shift'
import { BasicOpsWorkerDetails, Worker } from './workers'

export enum PaidBackupStatus {
  ADDED_TO_SHIFT = 'ADDED_TO_SHIFT',
  CANCELED = 'CANCELED',
  DISMISSED = 'DISMISSED',
  WAITING_AT_SHIFT = 'WAITING_AT_SHIFT',
  NO_SHOW = 'NO_SHOW',
  TO_DO = 'TO_DO',
}

export enum PaidBackupSource {
  OPS = 'OPS',
  SYSTEM = 'SYSTEM',
  WORKER = 'WORKER',
}

export enum PaidBackupConfirmationSource {
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  IN_APP = 'IN_APP',
}

export enum PaidBackupCancellationSource {
  OPS = 'OPS',
  SYSTEM = 'SYSTEM',
  WORKER = 'WORKER',
}

export enum PaidBackupCancellationType {
  UNDER_TWENTY_FOUR_HR = 'UNDER_TWENTY_FOUR_HR',
  UNDER_TWELVE_HR = 'UNDER_TWELVE_HR',
  OVER_TWENTY_FOUR_HR = 'OVER_TWENTY_FOUR_HR',
}

export type PaidBackup = {
  id: string
  createdAt: Date
  updatedAt?: Date
  shiftId: string
  shiftInfo: Shift
  workerId: string
  worker: Worker & BasicOpsWorkerDetails
  source: PaidBackupSource
  status: PaidBackupStatus
  workerShiftId?: string
  workerShift?: WorkerShift
  checkedInAt?: Date
  longitudeAtCheckIn?: number
  latitudeAtCheckIn?: number
  milesFromShiftLocationAtCheckIn?: number
  companyId: string
  company: Company
  isConfirmed?: boolean
  confirmedAt?: Date
  confirmationSource?: PaidBackupConfirmationSource
  tripId?: string
  workerETA?: Date
  onTimeStatus?: string
  workerSiteArrivalTime?: Date
  locationTrackingRate?: number
  cancellationType?: PaidBackupCancellationType
  cancellationSource?: PaidBackupCancellationSource
  cancellationReason?: string
}
