export interface BackIconProps {
  size: number
  color: string
  strokeWidth?: number
  style?: React.CSSProperties
  onClick?: () => void
}

export const Back: React.FC<BackIconProps> = (props) => {
  const { size, color, strokeWidth = 1.5, style, onClick } = props
  return (
    <svg
      onClick={onClick}
      style={{ ...style }}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14 8L3 8M3 8L7.58824 12M3 8L7.58824 4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
