import { WorkerMetrics, WorkerMetric, WorkerScores } from '@traba/types'

export function convertWorkerMetricsToWorkerScores(
  { perfectShiftsStreak }: WorkerMetrics,
  allTimeMetrics: WorkerMetric,
): WorkerScores {
  return {
    reliabilityScore: allTimeMetrics.reliabilityScore ?? 0,
    businessApprovalScore: allTimeMetrics.businessApprovalScore ?? 0,
    numberOfCompletions: allTimeMetrics.completedShifts ?? 0,
    numberOfBlocks: allTimeMetrics.blockedCount ?? 0,
    numberOfNoShows: allTimeMetrics.noShowShifts ?? 0,
    numberOfUniqueBusinesses:
      allTimeMetrics.uniqueWorkedCompanyIds?.length ?? 0,
    numberOfLateCancels: allTimeMetrics.lateCancellation ?? 0,
    overallRating: allTimeMetrics.overallRating ?? 0,
    onTimeArrivalRate: allTimeMetrics.onTimeRate ?? 0,
    highlyRatedBehaviorAttributes: Object.entries(
      allTimeMetrics.highlyRatedBehaviorAttributes ?? {},
    ).map(([type, count]) => ({
      count: count as number,
      behaviorAttributeType: type,
    })),
    highlyRatedRoleAttributes: Object.entries(
      allTimeMetrics.highlyRatedRoleAttributes ?? {},
    ).map(([type, count]) => ({
      count: count as number,
      roleAttributeType: type,
    })),
    perfectShiftsStreak: perfectShiftsStreak ?? 0,
    roleCounts: Object.entries(allTimeMetrics.roleCounts ?? {}).map(
      ([roleId, count]) => ({
        count: count as number,
        roleId,
      }),
    ),
    companyCounts: Object.entries(
      allTimeMetrics.totalCompletesByCompany ?? {},
    ).map(([companyId, count]) => ({
      count: count as number,
      companyId,
    })),
  }
}
