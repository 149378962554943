import { SvgIconProps } from '../SvgIconProps'

export default function Right({
  size,
  color,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <svg viewBox="0 0 16 16" width={size} height={size} fill="none" {...rest}>
      <path
        d="M5 3L10 8L5 13"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
