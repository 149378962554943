import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Incentive, IncentiveTypeIds } from '@traba/types'
import { IncentiveStatusBadge, Row } from 'src/components/base'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getMoneyString } from 'src/utils/stringUtils'

type IncentiveItemProps = {
  incentive: Incentive
}

export default function IncentiveItem({ incentive }: IncentiveItemProps) {
  return (
    <Row
      mr={8}
      flexCol
      style={{
        border: '1px solid #eee',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <Text variant="h7">
        {incentive.title}{' '}
        <IncentiveStatusBadge
          incentiveStatus={incentive.status}
          style={{ marginLeft: 10 }}
        />
      </Text>
      <Text>{incentive.description}</Text>
      <Row fullWidth justifyBetween>
        <Text variant="body2" mt={theme.space.xxs}>
          TYPE ID:{' '}
          <Text variant="brand" style={{ fontWeight: 600 }}>
            {incentive.typeId}
          </Text>{' '}
        </Text>
        <Text variant="body2" mt={theme.space.xxs}>
          Value:{' '}
          <Text variant="money">{getMoneyString(incentive.total ?? 0)}</Text>{' '}
          {incentive.typeId === IncentiveTypeIds.shift_hrly ? '/hr' : 'total'}
        </Text>
      </Row>
      <Text variant="body3" mt={theme.space.xxs}>
        Active from {formatDateTimeWithTimezone(incentive.startTime)} until{' '}
        {formatDateTimeWithTimezone(incentive.endTime)}
      </Text>
    </Row>
  )
}
