import { SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'

export const TransitTablePositiveDownIcon = styled(SvgIcon).attrs({
  name: 'downArrow',
  color: theme.colors.Green80,
})``

export const TransitTableNegativeUpIcon = styled(SvgIcon).attrs({
  name: 'upArrow',
  color: theme.colors.Red80,
})``

export const TransitTableNeutralIcon = styled(SvgIcon).attrs({
  name: 'minus',
  color: theme.colors.Grey60,
  size: theme.space.xs,
})``

export const SentinelTableDataGridStyle = {
  disableColumnFilter: true,
  checkboxSelection: true,
  columnHeaderHeight: 40,
  rowHeight: 45,
  autoHeight: false,
  hideFooter: true,
  sx: {
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      borderTop: `1px solid ${theme.colors.Violet40}`,
    },
    '& .MuiDataGrid-footerContainer': {
      padding: '4px',
      height: '30px',
      minHeight: '30px',
      fontSize: '14px',
      borderTop: `1px solid ${theme.colors.Violet40}`,
    },
  },
  localeText: {},
}
