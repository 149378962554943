import { IconButton, List } from '@mui/material'
import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftAvailability } from '@traba/types'
import { Icon, Col, Row } from 'src/components/base'
import { RosterInfo } from 'src/screens/PostShiftScreen/components/SearchInviteWorkers/types'
import { WorkerListItem } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/WorkerListItem'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { getWorkersPlurality } from './utils/utils'

interface ExpandedRosterRowProps {
  roster: RosterInfo
  handleWorkersSelection: (
    workers: PopulatedWorker[],
    operation: 'add' | 'remove',
  ) => void
  rosterToSelectedWorkers: Record<string, Record<string, PopulatedWorker>>
  workerDistancesByWorkerId?: Record<string, number>
  workerAvailabilitiesByWorkerId?: Map<string, WorkerShiftAvailability>
  companyId?: string
  roleId?: string
}

export const ExpandedRosterRow: React.FC<ExpandedRosterRowProps> = (props) => {
  const {
    roster,
    handleWorkersSelection,
    rosterToSelectedWorkers,
    workerDistancesByWorkerId,
    workerAvailabilitiesByWorkerId,
    companyId,
    roleId,
  } = props
  const {
    id,
    name,
    availableWorkers: optionalAvailableWorkers,
    workers: allWorkers,
  } = roster
  const availableWorkers = optionalAvailableWorkers ?? allWorkers

  const allAvailableWorkersInRosterHaveBeenSelected = () =>
    Object.keys(rosterToSelectedWorkers[id]).length === availableWorkers.length

  return (
    <Card key={id} style={{ padding: 0, marginBottom: theme.space.xxs }}>
      <Row
        alignCenter
        fullWidth
        justifyBetween
        style={{ padding: theme.space.xs }}
      >
        <Row alignCenter style={{ marginRight: theme.space.xxs }}>
          <Row
            alignCenter
            justifyCenter
            style={{
              width: theme.space.lg,
              height: theme.space.lg,
              borderRadius: theme.border.radius,
              border: `2px solid ${theme.colors.Grey20}`,
            }}
          >
            <Icon name="workers" />
          </Row>
          <Col
            style={{
              marginLeft: theme.space.xs,
              bottom: 3,
            }}
          >
            <Text
              color={theme.colors.MidnightBlue}
              variant="h6"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {name}
            </Text>
            <Text
              variant="body3"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${availableWorkers.length} ${getWorkersPlurality(availableWorkers.length)} available`}
            </Text>
          </Col>
        </Row>
        <IconButton
          onClick={() =>
            handleWorkersSelection(
              availableWorkers,
              allAvailableWorkersInRosterHaveBeenSelected() ? 'remove' : 'add',
            )
          }
          style={{
            fontSize: 14,
            color: theme.colors.Violet,
            fontWeight: 600,
          }}
        >
          {allAvailableWorkersInRosterHaveBeenSelected()
            ? 'Unselect All'
            : 'Select All'}
        </IconButton>
      </Row>
      <List sx={{ maxHeight: 500, overflow: 'auto', padding: 0 }}>
        {allWorkers.map((worker: PopulatedWorker, i: number) => (
          <WorkerListItem
            key={`${id}-${worker.id}`}
            customKey={`checkbox-${id}-${worker.id}`}
            worker={worker}
            handleSelection={() =>
              handleWorkersSelection(
                [worker],
                Object.prototype.hasOwnProperty.call(
                  rosterToSelectedWorkers[id],
                  worker.id || worker.uid,
                )
                  ? 'remove'
                  : 'add',
              )
            }
            workerIsSelected={Object.prototype.hasOwnProperty.call(
              rosterToSelectedWorkers[id],
              worker.id || worker.uid,
            )}
            index={i}
            workerDistance={
              workerDistancesByWorkerId?.[worker.id ?? worker.uid]
            }
            workerAvailability={workerAvailabilitiesByWorkerId?.get(
              worker.id ?? worker.uid,
            )}
            companyId={companyId}
            roleId={roleId}
            onAvatarClick={({ id }) => window.open(`/workers/${id}`, '_blank')}
          />
        ))}
      </List>
    </Card>
  )
}
