import { CircularProgress, Fab } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InternalUser,
  SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY,
  SentinelNotificationForShiftsResponse,
  SentinelToastSettings,
  ShiftNotificationSettingsForShift,
  ShiftNotificationsWithSettings,
} from '@traba/types'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Col, Icon, Row } from 'src/components/base'
import { useSentinelContext } from 'src/context/sentinelContext/SentinelContext'
import { useUserContext } from 'src/context/user/UserContext'
import {
  useBulkSentinelNotificationSettings,
  useSentinelNotificationsForShifts,
} from 'src/hooks/useSentinelNotifications'
import { sentinelNotificationIncludesNotificationToUser } from 'src/utils/sentinelNotificationUtils'
import { ButtonVariant } from '../base/Button/types'
import { CircleDiv } from '../base/Icon'
import Toggle from '../base/Toggle'
import { ShiftWithSentinelNotifications } from './components/ShiftWithSentinelNotifications'

interface SentinelNotificationsDrawerProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onClose?: () => void
}

const mapFieldMontiorShiftsToNotificationsAndSettings = (
  shiftsWithNotifications: SentinelNotificationForShiftsResponse | undefined,
  notificationSettings: Record<string, ShiftNotificationSettingsForShift>,
  internalUserId?: string,
) => {
  const shiftToNotifications: Record<string, ShiftNotificationsWithSettings> =
    {}

  if (!internalUserId || !shiftsWithNotifications) {
    return shiftToNotifications
  }

  for (const shift of shiftsWithNotifications.shifts) {
    const shiftSettings = notificationSettings[shift.shiftId]?.shiftSettings
    const userSpecificSettings =
      notificationSettings[shift.shiftId]?.userSpecificSettings[internalUserId]

    shiftToNotifications[shift.shiftId] = {
      notifications: shift.notifications.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
      shiftSettings: shiftSettings,
      userSpecificSettings: userSpecificSettings,
    }
  }
  return shiftToNotifications
}

export const SentinelNotificationsDrawer = (
  props: SentinelNotificationsDrawerProps,
) => {
  const { isOpen, onClose, setIsOpen } = props

  const [isViewingSettings, setIsViewingSettings] = useState(false)
  const [selectedNotificationId, setSelectedNotificationId] =
    useState<string>('')

  const { state } = useUserContext()
  const { state: sentinelState } = useSentinelContext()
  const location = useLocation()
  const internalUser = state.userProfile?.internalUser

  const isViewingFieldMonitor = location.pathname === '/field-monitor'

  /*
   * If viewing field monitor, we want to match the notifications for the shifts
   * that are currently visible. Otherwise, we query the most recent notifications
   * for the current user.
   */
  const { data: notifications, isLoading } = useSentinelNotificationsForShifts({
    shiftIds: isViewingFieldMonitor ? sentinelState.shiftIds : undefined,
    internalUserId: internalUser?.id,
    enabled: !!sentinelState.shiftIds.length && isOpen,
  })

  const shiftIds = useMemo(
    () =>
      isViewingFieldMonitor
        ? sentinelState.shiftIds
        : [...new Set(notifications?.shifts.map((shift) => shift.shiftId))],
    [isViewingFieldMonitor, sentinelState.shiftIds, notifications],
  )

  const { data: notificationSettings } = useBulkSentinelNotificationSettings({
    internalUserId: internalUser?.id,
    shiftIds: shiftIds,
    enabled: !!shiftIds.length,
  })

  const shiftToNotificationsMap = useMemo(() => {
    return mapFieldMontiorShiftsToNotificationsAndSettings(
      notifications,
      notificationSettings ?? {},
      internalUser?.id,
    )
  }, [
    notifications,
    notificationSettings,
    internalUser?.id,
    sentinelState.receivedNotifications,
  ])

  if (!internalUser) {
    return null
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        variant="persistent"
        PaperProps={{
          sx: {
            width: '40%',
            boxShadow: `-10px 0 15px -5px ${theme.colors.Violet20}`,
          },
        }}
      >
        <Row alignCenter pt={theme.space.xs}>
          <Row alignCenter justifyBetween fullWidth>
            <Row gap={theme.space.sm} alignCenter>
              <Text variant="h4" style={{ marginLeft: theme.space.med }}>
                {isViewingSettings ? 'Settings' : 'Notifications'}
              </Text>
              {isViewingSettings && (
                <Button
                  variant={ButtonVariant.TEXT}
                  onClick={() => setIsViewingSettings(false)}
                >
                  <Icon
                    name="leftArrow"
                    style={{ paddingRight: theme.space.xxs }}
                  />
                  Go back
                </Button>
              )}
              {!isViewingSettings && (
                <CircleDiv size={30}>
                  <Icon
                    name="settings"
                    onClick={() => setIsViewingSettings(true)}
                  />
                </CircleDiv>
              )}
            </Row>
            <Button
              variant={ButtonVariant.TRANSPARENT}
              onClick={() => setIsOpen(!isOpen)}
              leftIcon={<Icon name="cancel" />}
            >
              Close
            </Button>
          </Row>
        </Row>

        <Col py={theme.space.xs}>
          {isLoading ? (
            <Row justifyCenter>
              <CircularProgress />
            </Row>
          ) : isViewingSettings ? (
            <SentinelSettings />
          ) : (
            <ShiftSentinelNotificationsList
              shiftToNotifications={shiftToNotificationsMap}
              selectedNotificationId={selectedNotificationId}
              setSelectedNotificationId={setSelectedNotificationId}
              internalUser={internalUser}
            />
          )}
        </Col>
      </Drawer>

      <Fab
        color="default"
        style={{
          margin: 0,
          top: 20,
          right: 80,
          bottom: 'auto',
          left: 'auto',
          height: '50px',
          overflow: 'auto',
          position: 'fixed',
          zIndex: 3,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="bell" height={18} width={18} />
      </Fab>
    </>
  )
}

const ShiftSentinelNotificationsList = ({
  shiftToNotifications,
  selectedNotificationId,
  setSelectedNotificationId,
  internalUser,
}: {
  shiftToNotifications: Record<string, ShiftNotificationsWithSettings>
  selectedNotificationId: string
  setSelectedNotificationId: React.Dispatch<React.SetStateAction<string>>
  internalUser: InternalUser
}) => {
  return Object.keys(shiftToNotifications)
    .filter((key) =>
      shiftToNotifications[key].notifications.filter((notification) =>
        sentinelNotificationIncludesNotificationToUser(
          notification,
          internalUser.id,
        ),
      ),
    )
    .map((key) => {
      return (
        <ShiftWithSentinelNotifications
          key={key}
          shiftId={key}
          notifications={shiftToNotifications[key].notifications}
          selectedNotificationId={selectedNotificationId}
          setSelectedNotificationId={setSelectedNotificationId}
          shiftNotificationSettings={
            shiftToNotifications[key].shiftSettings ?? {}
          }
          userNotificationSettings={
            shiftToNotifications[key].userSpecificSettings ?? {}
          }
          internalUser={internalUser}
        />
      )
    })
}

const SentinelSettings = () => {
  const [notificationToastSettings, setNotificationToastSettings] =
    useState<number>(
      parseInt(
        localStorage.getItem(SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY) ??
          '0',
      ),
    )

  useEffect(() => {
    localStorage.setItem(
      SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY,
      notificationToastSettings.toString(),
    )
  }, [notificationToastSettings])

  const toggleNotificationSetting = (
    currentSettings: number,
    newSetting: number,
  ) => {
    return currentSettings ^ newSetting
  }

  return (
    <Row px={theme.space.sm}>
      <Col>
        <Text variant="h5" mb={theme.space.xs}>
          Sentinel Toast Settings
        </Text>
        {Object.entries(SentinelToastSettings)
          .filter(([key]) => isNaN(Number(key)))
          .map(([type, value]) => (
            <Toggle
              key={type}
              label={type}
              buttonState={(notificationToastSettings & +value) !== 0}
              runOnChange={() =>
                setNotificationToastSettings((prevNotificationToastSettings) =>
                  toggleNotificationSetting(
                    prevNotificationToastSettings,
                    +value,
                  ),
                )
              }
              containerStyle={{
                marginBottom: theme.space.xxs,
              }}
            />
          ))}
      </Col>
    </Row>
  )
}
