import { theme } from '@traba/theme'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row } from 'src/components/base'
import Toggle from '../../components/base/Toggle'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import { ShiftInfoDetailsHeader } from '../../components/ShiftInfoDetailsHeader/ShiftInfoDetailsHeader'
import { WorkerCommunications } from '../../components/WorkerCommunications/WorkerCommunications'
import { MessageLogsFilters } from '../../types/worker-communications'

const filterList = [
  {
    name: MessageLogsFilters.OPEN_PHONE_MESSAGES,
    label: 'Op Messages',
  },
  {
    name: MessageLogsFilters.OPEN_PHONE_CALLS,
    label: 'Op Calls',
  },
  {
    name: MessageLogsFilters.BUGLE_SMS,
    label: 'Bugle SMS',
  },
]

export function MessageLogsScreen() {
  const { shiftId = '' } = useParams<{ shiftId: string }>()
  const [messageLogsFilters, setMessageLogFilters] = useState<{
    [key: string]: boolean
  }>({})

  const handleToggleFilter = (filter: string) => {
    setMessageLogFilters((prevFilters) => {
      const newFilters = Object.keys(prevFilters).reduce(
        (acc, key) => {
          acc[key] = false
          return acc
        },
        {} as { [key: string]: boolean },
      )

      return {
        ...newFilters,
        [filter]: !prevFilters[filter],
      }
    })
  }

  return (
    <MainLayout title="Audit Logs">
      <ShiftInfoDetailsHeader shiftId={shiftId} />
      <Row my={theme.space.xs}>
        {filterList.map((filter) => (
          <Row key={filter.name} mr={theme.space.xs}>
            <Toggle
              label={filter.label}
              buttonState={!!messageLogsFilters[filter.name]}
              runOnChange={() => handleToggleFilter(filter.name)}
              containerStyle={{ width: 200, justifyContent: 'center' }}
            />
          </Row>
        ))}
      </Row>
      <WorkerCommunications
        shiftId={shiftId}
        embedded
        expanded
        filters={messageLogsFilters}
      />
    </MainLayout>
  )
}
