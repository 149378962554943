import { SpacingProps } from '@traba/theme'
import { assetNameType } from 'src/assets/svg/icons'

export enum ButtonVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  TEXT = 'text',
  CANCEL = 'cancel',
  REDOUTLINED = 'redoutlined',
  PURPLEGRADIENT = 'purplegradient',
  TRANSPARENT = 'transparent',
  BRANDLINK = 'brandLink',
  LINK = 'link',
  DASHED = 'dashed',
  GREEN = 'green',
  PRESSED = 'pressed',
  BRANDNEW = 'brandNew',
  ERROR = 'error',
}
export type ButtonProps = {
  children: JSX.Element | JSX.Element[] | string | React.ReactNode | number
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  style?: React.CSSProperties
  variant?: ButtonVariant
  slim?: boolean
  reverse?: boolean
  full?: boolean
  iconPadding?: string
  disabled?: boolean
  loading?: boolean
  tooltipText?: string
  textColor?: React.CSSProperties['color']
  textHoverColor?: React.CSSProperties['color']
  labelStyle?: React.CSSProperties
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  SpacingProps

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
  iconName: assetNameType
  transparent?: boolean
}
