import { Region } from '@traba/types'
import { useMemo } from 'react'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useRegions } from './useRegions'

export default function useRegionsSelector(
  selectedRegionIds: string[],
  setRegionIds: (ids: string[]) => void,
) {
  const { regions, isLoading: isLoadingRegions } = useRegions()

  const regionToMenuItem = (region: Region) => ({
    label: region.displayName,
    value: region.regionId,
  })

  const regionIdToRegion = useMemo(
    () =>
      regions
        ? Object.fromEntries(regions.map((region) => [region.regionId, region]))
        : {},
    [regions],
  )

  const regionMenuOptions: IMenuItem[] = regions
    ? regions.map(regionToMenuItem)
    : []

  const selectedRegions = selectedRegionIds.map(
    (regionId) => regionIdToRegion[regionId],
  )

  return {
    regionMenuOptions,
    selectedRegions,
    selectedRegionIds,
    isLoadingRegions,
    setRegionIds,
  }
}
