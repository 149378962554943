import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Company, Role } from '@traba/types'
import { AxiosError } from 'axios'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

export type GeneralCampaignQuestion = {
  key: string
  displayName: string
}

export type CreateVettingCampaignRequest = {
  title: string
  companyIds: string[]
  roleIds?: string[]
  regionIds: string[]
  questionKeys?: string[]
  customQuestions?: string[]
}

export type VettingLogistics = {
  locationDetails: string
  scheduleDetails: string
  payRatePlainText: string
  jobTitle: string
  roleDetails?: string
  otherQuestions?: string[]
}

export type StartSmsAndPhoneVettingRequest = {
  vettingCampaignId: string
  vettingLogistics: VettingLogistics
  questions: string[]
  promptDetails: string
  customUnqualifiedExitMessage?: string
  customQualifiedExitMessage: string
  initialMessage: string
}

const VETTING_CAMPAIGN_QUESTIONS_KEY = 'vetting-campaign-questions'

export type VettingCampaignSearchRequest = {
  title?: string
  companyIds?: string[]
  regionIds?: string[]
}

export type VettingCampaignSearchResponse = {
  campaigns: VettingCampaign[]
}

export type VettingCampaign = {
  id: string
  createdBy: string
  createdAt: string
  updatedAt: string
  title: string
  regions: string[]
  companies: Pick<Company, 'employerName' | 'companyId'>[]
  roles: Pick<Role, 'roleName'>[]
  googleSheetsId: string
  lastGSheetSyncAt?: string
}

const FETCH_VETTING_CAMPAIGNS_QUERY_KEY = 'vetting-campaigns'

async function getVettingCampaignQuestions(): Promise<
  GeneralCampaignQuestion[]
> {
  try {
    const res = await trabaApi.get(`/workers/vetting/questions`)
    return res.data.questions
  } catch (error) {
    console.error('useVettingCampaignQuestions -> getQuestions() ERROR', error)
    Sentry.captureException(error)
    throw error
  }
}

async function getVettingCampaigns(
  vettingCampaignSearchRequest: VettingCampaignSearchRequest,
): Promise<VettingCampaign[]> {
  try {
    const res = await trabaApi.post(
      `/workers/vetting/search-campaigns`,
      vettingCampaignSearchRequest,
    )
    return res.data.campaigns
  } catch (error) {
    console.error('useVettingCampaigns -> getVettingCampaigns() ERROR', error)
    Sentry.captureException(error)
    throw error
  }
}

export function useVettingCampaignMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()
  const createVettingCampaignMutation = useMutation<
    any,
    AxiosError,
    CreateVettingCampaignRequest
  >({
    mutationFn: createVettingCampaign,
    onSuccess: (_response: any) => {
      showSuccess('Vetting Campaign created')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error creating vetting campaign')
    },
  })

  const syncVettingCampaignMutation = useMutation<any, AxiosError, string>({
    mutationFn: async (campaignId: string) => {
      const response = await trabaApi.patch(
        `workers/vetting/sync-sheet/${campaignId}`,
      )
      return response.data
    },
    onSuccess: (_response: any) => {
      showSuccess('Vetting Campaign synced successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error syncing vetting campaign')
    },
  })

  const startSmsAndPhoneVettingMutation = useMutation<
    any,
    AxiosError,
    StartSmsAndPhoneVettingRequest
  >({
    mutationFn: startSmsAndPhoneVetting,
    onSuccess: (_response: any) => {
      showSuccess('SMS and phone vetting started successfully')
      queryClient.invalidateQueries({
        queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY],
      })
    },
    onError: (error: AxiosError) => {
      showError(JSON.stringify(error), 'Error starting SMS and phone vetting')
    },
  })

  return {
    createVettingCampaign: createVettingCampaignMutation.mutateAsync,
    isCampaignCreationPending: createVettingCampaignMutation.isPending,
    syncVettingCampaign: syncVettingCampaignMutation.mutateAsync,
    isSyncPending: syncVettingCampaignMutation.isPending,
    startAIVetting: startSmsAndPhoneVettingMutation.mutateAsync,
    isStartAIVettingPending: startSmsAndPhoneVettingMutation.isPending,
  }
}

export async function createVettingCampaign(
  createVettingCampaignRequest: CreateVettingCampaignRequest,
) {
  try {
    const response = await trabaApi.post(
      `workers/vetting/create-campaign`,
      createVettingCampaignRequest,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export async function startSmsAndPhoneVetting(
  startSmsAndPhoneVettingRequest: StartSmsAndPhoneVettingRequest,
) {
  try {
    const response = await trabaApi.post(
      `workers/vetting/start-sms-and-phone-vetting`,
      startSmsAndPhoneVettingRequest,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useVettingCampaignQuestions() {
  const {
    isLoading,
    isError,
    data: generalQuestions,
    error,
    isFetched,
    refetch,
  } = useQuery<GeneralCampaignQuestion[], Error>({
    queryKey: [VETTING_CAMPAIGN_QUESTIONS_KEY],
    queryFn: () => getVettingCampaignQuestions(),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    generalQuestions,
    error,
    isFetched,
    refetch,
  }
}

export function useVettingCampaigns(
  vettingCampaignSearchRequest: VettingCampaignSearchRequest,
) {
  const {
    isLoading,
    isError,
    data: vettingCampaigns,
    error,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [FETCH_VETTING_CAMPAIGNS_QUERY_KEY, vettingCampaignSearchRequest],
    queryFn: () => getVettingCampaigns(vettingCampaignSearchRequest),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    vettingCampaigns,
    error,
    isFetched,
    refetch,
  }
}
