import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { BugleBlast, SearchParams } from '@traba/types'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

interface useBugleScheduleProps {
  shiftId: string
}

const getBugleScheduleForShiftId = async (shiftId: string) => {
  const url = `communication/bugle/shift/${shiftId}`
  const res = await trabaApi.get<BugleBlast[]>(url)
  return res.data
}

const cancelBugleScheduleById = async (bugleId: string) => {
  const url = `communication/bugle/cancel/${bugleId}`
  return trabaApi.put(url)
}

const getSearchParamsById = async (id: string) => {
  const url = `search/params/${id}`
  const res = await trabaApi.get<SearchParams>(url)
  return res.data
}

const useBugleSchedule = ({ shiftId }: useBugleScheduleProps) => {
  const [searchParams, setSearchParams] = useState<SearchParams[]>([])
  const [isFetchingSearchParams, setFetchingSearchParams] = useState(false)
  const [updatingBugleIds, setUpdatingBugleIds] = useState<string[]>([])

  const {
    data: bugleSchedule,
    isLoading: isFetchingBugleSchedule,
    isError: isBugleError,
    error: bugleError,
    refetch: refetchBugleSchedule,
  } = useQuery(
    ['bugle_schedule', shiftId],
    () => getBugleScheduleForShiftId(shiftId),
    {
      refetchOnMount: true,
    },
  )

  async function handleCancelBugle(bugleId: string) {
    const userHasConfirmed = window.confirm(
      'Are you sure you want to cancel this bugle?',
    )
    if (userHasConfirmed) {
      setUpdatingBugleIds((prev) => [...prev, bugleId])
      try {
        await cancelBugleScheduleById(bugleId)
        await refetchBugleSchedule()
        setUpdatingBugleIds((prev) => prev.filter((id) => id !== bugleId))
      } catch (error) {
        Sentry.captureException(error)
        setUpdatingBugleIds((prev) => prev.filter((id) => id !== bugleId))
      }
    }
  }

  useEffect(() => {
    const fetchSearchParams = async () => {
      if (bugleSchedule) {
        setFetchingSearchParams(true)
        try {
          const results = await Promise.all(
            bugleSchedule.map((bugleBlast) =>
              getSearchParamsById(bugleBlast.searchParamsId),
            ),
          )
          setSearchParams(results)
        } catch (error) {
          console.error(error)
        }
        setFetchingSearchParams(false)
      }
    }
    fetchSearchParams()
  }, [bugleSchedule])

  return {
    bugleSchedule,
    isFetchingBugleSchedule,
    searchParams,
    isFetchingSearchParams,
    handleCancelBugle,
    updatingBugleIds,
    isError: isBugleError,
    error: bugleError,
  }
}

export default useBugleSchedule
