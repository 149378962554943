import { Switch } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { Clickable } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CreateLineItemDto, EditLineItemDto } from '@traba/types'
import { Money } from '@traba/types'
import { ReactNode, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import {
  Button,
  Col,
  Collapsible,
  CopyTextIcon,
  Icon,
  Row,
} from 'src/components/base'
import { HorizontalRule } from 'src/components/base/HorizontalRule/HorizontalRule'
import { useModal } from 'src/components/base/Modal/Modal'
import { useBilling } from 'src/hooks/useBilling'
import { useCompany } from 'src/hooks/useCompany'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import styled from 'styled-components'
import AddOrEditLineItemModal from './AddOrEditLineItemModal'
import InvoiceLineItemsTable, {
  InvoiceDetailsBodyLineItem,
} from './InvoiceLineItemsTable'
import InvoiceMemo from './InvoiceMemo'

export type InvoiceDetailsBodySectionProps = {
  title: string
  children: ReactNode
  defaultCollapsed?: boolean
}

function InvoiceDetailsBodySection(props: InvoiceDetailsBodySectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(
    props.defaultCollapsed ?? false,
  )
  return (
    <>
      <Col
        style={{
          paddingTop: theme.space.xs,
          paddingBottom: theme.space.xs,
          paddingLeft: theme.space.xxs,
          paddingRight: theme.space.xxs,
        }}
      >
        <Clickable onClick={() => setIsCollapsed(!isCollapsed)}>
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5">{props.title}</Text>
            <Icon
              name={isCollapsed ? 'arrowBottom_primary' : 'arrowTop_primary'}
            />
          </Row>
        </Clickable>
        <Collapsible collapsed={isCollapsed}>
          <div style={{ paddingTop: theme.space.xs }}>{props.children}</div>
        </Collapsible>
      </Col>
      <HorizontalRule />
    </>
  )
}

export const GreyContainer = styled.div`
  background-color: ${theme.colors.Grey10};
  border-radius: ${theme.border.radius};
  padding: ${theme.space.sm}px;
  display: inline-block;
  white-space: pre-line;
`

type InvoiceDetailsBodyBaseProps = {
  companyId: string
  stripeInvoiceNumber?: string
  invoiceId?: string
  dueDate?: Date
  stripeMemo?: string
  lineItems: InvoiceDetailsBodyLineItem[]
  totalCharge: Money
}

type InvoiceDetailsBodyEditableLineItemProps = InvoiceDetailsBodyBaseProps & {
  lineItemsEditable: true
  handleAddLineItem: (lineItem: CreateLineItemDto) => Promise<void>
  handleEditLineItem: (updates: EditLineItemDto) => Promise<void>
  handleDeleteLineItem: (lineItemId: string) => void
  handleUpdateMemo: (newMemo: string) => Promise<void>
}

type InvoiceDetailsBodyNonEditableProps = InvoiceDetailsBodyBaseProps & {
  lineItemsEditable: false
}

export type InvoiceDetailsBodyProps =
  | InvoiceDetailsBodyNonEditableProps
  | InvoiceDetailsBodyEditableLineItemProps

export default function InvoiceDetailsBody(props: InvoiceDetailsBodyProps) {
  const {
    companyId,
    stripeInvoiceNumber,
    invoiceId,
    dueDate,
    stripeMemo,
    lineItems,
    totalCharge,
    lineItemsEditable,
  } = props
  const { getDate } = useTimezonedDates()
  const { company, isLoading: isFetchingCompany } = useCompany(companyId)
  const { billing, isLoading: isFetchingBilling } = useBilling(companyId)
  const addOrEditLineItemModal = useModal()
  const [isLoadingAddOrEditLineItem, setIsLoadingAddOrEditLineItem] =
    useState(false)
  const [lineItemIndexToEdit, setLineItemIndexToEdit] = useState<
    number | undefined
  >(undefined)
  const [hideInternalOnlyLineItems, setHideInternalOnlyLineItems] =
    useState(false)

  const handleToggleInternalOnlyLineItems = () => {
    setHideInternalOnlyLineItems(!hideInternalOnlyLineItems)
  }

  const handleAddLineItem = async (lineItem: CreateLineItemDto) => {
    setIsLoadingAddOrEditLineItem(true)
    lineItemsEditable && (await props.handleAddLineItem(lineItem))
    setIsLoadingAddOrEditLineItem(false)
    addOrEditLineItemModal.handleClose()
  }

  const handleEditLineItem = async (updates: EditLineItemDto) => {
    if (lineItemIndexToEdit !== undefined && lineItems[lineItemIndexToEdit]) {
      setIsLoadingAddOrEditLineItem(true)
      lineItemsEditable && (await props.handleEditLineItem(updates))
      setIsLoadingAddOrEditLineItem(false)
    }

    addOrEditLineItemModal.handleClose()
    setLineItemIndexToEdit(undefined)
  }

  const handleClickEditLineItemButton = (index: number) => {
    if (!lineItems[index]) {
      return
    }
    setLineItemIndexToEdit(index)
    addOrEditLineItemModal.open()
  }

  const handleAddOrEditModalClose = () => {
    addOrEditLineItemModal.handleClose()
    setLineItemIndexToEdit(undefined)
  }

  const handleUpdateMemo = async (newMemo: string) => {
    lineItemsEditable && (await props.handleUpdateMemo(newMemo))
  }

  if (isFetchingCompany || isFetchingBilling || !company || !billing) {
    return (
      <Row justifyCenter mt={theme.space.sm}>
        <CircularProgress size={20} />
      </Row>
    )
  }

  return (
    <>
      <Col
        style={{
          paddingLeft: theme.space.xs,
          paddingRight: theme.space.xs,
          width: '100%',
        }}
      >
        {/* Header */}
        <InvoiceDetailsBodySection title="Invoice Details" defaultCollapsed>
          <Col
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: theme.space.xs,
            }}
          >
            <GreyContainer>
              <Text variant="h6">Invoice header</Text>
              {invoiceId && (
                <Text variant="body1">
                  Invoice ID: {invoiceId}
                  <CopyTextIcon textToCopy={invoiceId} />
                </Text>
              )}
              {stripeInvoiceNumber && (
                <Text variant="body1">
                  Invoice Number: {stripeInvoiceNumber}
                  <CopyTextIcon textToCopy={stripeInvoiceNumber} />
                </Text>
              )}
              <Text variant="body1">
                Due Date:{' '}
                {dueDate ? (
                  getDate(dueDate)
                ) : (
                  <>
                    TBD
                    <InfoTooltip
                      title={`Company Default: ${billing.invoiceDaysUntilDue} days after finalized -- applies to any invoice created after the company setting was changed. (will not apply to this invoice if it was created before that)`}
                    />
                  </>
                )}
              </Text>
            </GreyContainer>

            <GreyContainer>
              <Text variant="h6">Billing Information</Text>
              <Text variant="body1">Company: {company.employerName}</Text>
              <Text variant="body1">
                Company ID: {companyId}
                <CopyTextIcon textToCopy={companyId} />
              </Text>
              <Text variant="body1">
                Billing Email: {billing.paymentSettings.email}
                <CopyTextIcon textToCopy={billing.paymentSettings.email} />
              </Text>
              <Text variant="body1">
                Billing Phone Number: {billing.paymentSettings.phoneNumber}
                <CopyTextIcon
                  textToCopy={billing.paymentSettings.phoneNumber}
                />
              </Text>
            </GreyContainer>
          </Col>
        </InvoiceDetailsBodySection>

        {/* Stripe Memo if exists on invoice */}
        {stripeMemo && (
          <InvoiceDetailsBodySection title="Stripe memo">
            <InvoiceMemo
              editable={lineItemsEditable}
              stripeMemo={stripeMemo}
              updateMemo={handleUpdateMemo}
            />
          </InvoiceDetailsBodySection>
        )}

        {/* Line Items */}
        <InvoiceDetailsBodySection title="Line items">
          <Col>
            {props.lineItemsEditable && (
              <Row
                justifyEnd
                mb={theme.space.xs}
                alignCenter
                style={{
                  maxWidth: '60em',
                  gap: theme.space.xxs, // Add space between items
                }}
              >
                <Text variant="h7">Hide Internal Only Line Items</Text>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={hideInternalOnlyLineItems}
                  onClick={handleToggleInternalOnlyLineItems}
                />
                <Button
                  slim
                  onClick={() => {
                    addOrEditLineItemModal.open()
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                  reverse
                  leftIcon={<Plus />}
                >
                  Add item
                </Button>
              </Row>
            )}
            <InvoiceLineItemsTable
              lineItemsEditable={props.lineItemsEditable}
              lineItems={lineItems}
              totalCharge={totalCharge}
              handleDeleteLineItem={
                lineItemsEditable
                  ? props.handleDeleteLineItem
                  : (_) => undefined
              }
              handleEditLineItem={handleClickEditLineItemButton}
              hideInternalOnlyLineItems={hideInternalOnlyLineItems}
            />
          </Col>
        </InvoiceDetailsBodySection>
      </Col>

      <AddOrEditLineItemModal
        isOpen={addOrEditLineItemModal.isOpen}
        isLoading={isLoadingAddOrEditLineItem}
        lineItem={
          lineItemIndexToEdit !== undefined
            ? lineItems[lineItemIndexToEdit]
            : undefined
        }
        handleClose={handleAddOrEditModalClose}
        handleAdd={handleAddLineItem}
        handleEdit={handleEditLineItem}
      />
    </>
  )
}
