import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { PaidBackup } from '@traba/types'
import { AxiosError } from 'axios'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export enum PaidBackupAction {
  ACCEPT_SHIFT = 'accept-shift',
  DISMISS = 'dismiss',
  CANCEL = 'cancel',
}
export const PAID_BACKUPS_QUERY_KEY = 'paidBackups'

async function getPaidBackups(
  shiftId: string,
): Promise<PaidBackup[] | undefined> {
  try {
    const { data } = await trabaApi.get(`paid-backup/shifts/${shiftId}`)
    return data
  } catch (error: any) {
    console.error(
      'usePaidBackups -> getPaidBackups() ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
  }
}

export function usePaidBackups(shiftId: string) {
  const {
    isLoading,
    isError,
    data: paidBackups,
    error,
    isFetched,
  } = useQuery<PaidBackup[] | undefined, Error>({
    queryKey: [PAID_BACKUPS_QUERY_KEY, shiftId],
    queryFn: () => getPaidBackups(shiftId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    paidBackups,
    error,
    isFetched,
  }
}

interface UpdatePaidBackupParams {
  workerId: string
  shiftId: string
  action: PaidBackupAction
}

async function updatePaidBackupApi({
  workerId,
  shiftId,
  action,
}: UpdatePaidBackupParams) {
  try {
    const { data } = await trabaApi.patch(`paid-backup/${action}`, {
      shiftId,
      workerId,
    })
    return data
  } catch (error: any) {
    console.error(
      'usePaidBackups -> updatePaidBackup() ERROR',
      error.message ?? error,
    )
    Sentry.captureException(error)
    throw error
  }
}

export function usePaidBackupsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, handleError } = useAlert()

  const updatePaidBackupMutation = useMutation<
    PaidBackup,
    AxiosError,
    UpdatePaidBackupParams
  >({
    mutationFn: updatePaidBackupApi,
    onSuccess: (_response, variables) => {
      showSuccess('Successfully updated paid backup status')

      queryClient.invalidateQueries({
        queryKey: [PAID_BACKUPS_QUERY_KEY, variables.shiftId],
      })
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'usePaidBackupsMutations',
        error.message,
        'Error updating paid backup',
      )
    },
  })

  function updatePaidBackup(
    variables: UpdatePaidBackupParams,
    onSettled?: () => void,
  ) {
    updatePaidBackupMutation.mutate(variables, { onSettled })
  }

  return {
    updatePaidBackupsMutation: updatePaidBackup,
    isUpdatingPaidBackup: updatePaidBackupMutation.isPending,
    isUpdatePaidBackupError: updatePaidBackupMutation.isError,
    updatePaidBackupError: updatePaidBackupMutation.error,
  }
}
