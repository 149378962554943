import { SHIFT_CODE_LEN } from '../libs/constants'

export const getShiftCode = (shiftId: string | undefined) => {
  if (!shiftId) {
    return ''
  }
  const normalizedShiftId = shiftId.replace(/-/g, '')
  return hexToBase64(normalizedShiftId).slice(0, SHIFT_CODE_LEN)
}

// hexToBase64 converts a hex string to a base64-encoded string.
// once converted into numbers, we convert them into their character form with CharCode which we can encode to base64 with btoa
function hexToBase64(hexStr: string) {
  // Ensure the hex string has an even length
  if (hexStr.length % 2 !== 0) {
    throw new Error('Invalid hex string')
  }

  // Convert hex string to a string of characters using fromCharCode
  const binaryString = String.fromCharCode(
    ...(hexStr.match(/.{2}/g) || []).map((byte) => parseInt(byte, 16)),
  )

  // Convert binary string to base64
  return btoa(binaryString)
}

// base64ToHex decodes the base64Str into their char representation which we then convert into their hexadecimal representation
export function base64ToHex(base64Str: string) {
  // atob will throw an error if the input string is less than 2 character long or if the input string is not in base64
  if (
    base64Str.length !== SHIFT_CODE_LEN ||
    strContainsNonBase64Char(base64Str)
  ) {
    return ''
  }

  const bin = atob(base64Str)
  const hex: string[] = []
  for (let i = 0; i < bin.length; i++) {
    const tmp = bin.charCodeAt(i).toString(16).padStart(2, '0')
    hex.push(tmp)
  }
  return hex.join('')
}

// Regex will match any character that is not in base64
export function strContainsNonBase64Char(str: string) {
  return /[^A-Za-z0-9+/=]/.test(str)
}
