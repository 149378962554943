import { Worker } from './workers'

export enum ShiftBackfillStatus {
  Queued = 'QUEUED',
  InProgress = 'IN_PROGRESS',
  Filled = 'FILLED',
  NotFilled = 'NOT_FILLED',
  Canceled = 'CANCELED',
  PastEndTime = 'PAST_END_TIME',
}

export type ShiftBackfill = {
  shiftBackfillId: string
  createdAt: Date
  shiftId: string
  status: ShiftBackfillStatus
  message: string
  listLength: number
  curIndex: number
  completedAt?: Date
}

export enum ShiftBackfillCallResponse {
  NotAnswered = 'NOT_ANSWERED',
  AnsweredAndAccepted = 'ANSWERED_AND_ACCEPTED',
  AnsweredAndDeclined = 'ANSWERED_AND_DECLINED',
  NotCalled = 'NOT_CALLED',
}

export enum ShiftBackfillCallInput {
  AcceptShift = 'ACCEPT_SHIFT',
  BusyForShift = 'BUSY_FOR_SHIFT',
  BusyForDay = 'BUSY_FOR_DAY',
  DoNotCall = 'DO_NOT_CALL',
}

export type ShiftBackfillCall = {
  shiftBackfillId: string
  createdAt: Date
  shiftId: string
  workerId: string
  calledAt?: Date
  response?: ShiftBackfillCallResponse
  callInput?: ShiftBackfillCallInput
  index: number
  phoneNumber: string
}

export type ShiftBackfillCallResponseDto = ShiftBackfillCall & {
  worker?: Worker
}
