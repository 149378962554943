import { Skeleton } from '@mui/material'
import { Td, Tr } from 'src/components/base'

export default function WorkerSearchSkeleton() {
  return (
    <>
      <Tr>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'12%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'10%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'15%'}>
          <Skeleton width="100%" height={40} />
        </Td>
      </Tr>
      <Tr>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'12%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'10%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'15%'}>
          <Skeleton width="100%" height={40} />
        </Td>
      </Tr>
      <Tr>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'16.5%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'12%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'10%'}>
          <Skeleton width="100%" height={40} />
        </Td>
        <Td width={'15%'}>
          <Skeleton width="100%" height={40} />
        </Td>
      </Tr>
    </>
  )
}
