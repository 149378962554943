import { TransitHistoryEntry } from '@traba/types'
import { getTransitStatusCriticalLevel } from '../../utils'

export function sortTransitHistoryByTimestampAndTransitStatus(
  a: TransitHistoryEntry,
  b: TransitHistoryEntry,
) {
  const timestampDiff =
    new Date(a.eventTimestamp).getTime() - new Date(b.eventTimestamp).getTime()
  // Primary sort based on timestamp
  if (timestampDiff !== 0) {
    return timestampDiff
  }
  // Secondary sort based on transitStatus using getTransitStatusCriticalLevel
  return (
    getTransitStatusCriticalLevel(a.transitStatus) -
    getTransitStatusCriticalLevel(b.transitStatus)
  )
}
