import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { AcceptShiftBypasses, Timesheet } from '@traba/types'
import { useState } from 'react'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'
import { ConfirmationStatuses } from 'src/screens/FieldMonitorScreen/components/AddWorkers/types'

const TIMESHEET_FOR_SHIFT_QUERY_KEY = 'timesheet'
const TIMESHEET_REPORT_QUERY_KEY = 'timesheet-report'

async function getTimesheetForShift(
  shiftId: string,
): Promise<Timesheet | undefined> {
  try {
    const response = await trabaApi.get(`/timesheets/${shiftId}`, {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404
      },
    })
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useTimesheetForShift = (shiftId: string) => {
  const {
    isLoading,
    isError,
    data: timesheet,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<Timesheet | undefined, Error>({
    queryKey: [TIMESHEET_FOR_SHIFT_QUERY_KEY, shiftId],
    queryFn: () => getTimesheetForShift(shiftId),
    staleTime: ONE_HOUR_IN_MS,
  })

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    timesheet,
    refetch,
  }
}

type useAddWorkersByShiftProps = {
  refetchWorkerShifts: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const useAddWorkersByShift = ({
  refetchWorkerShifts,
  setIsLoading,
}: useAddWorkersByShiftProps) => {
  const [workerShiftsWithStatus, setWorkerShiftsWithStatus] = useState<
    ConfirmationStatuses[]
  >([])
  const { showError } = useAlert()

  const getLoadingStatus = (list: ConfirmationStatuses[]) =>
    list.map((ws) => ({
      ...ws,
      status: 'loading' as ConfirmationStatuses['status'],
    }))

  /**
   * Adding workers to shifts
   */
  const handleAcceptShiftWithBypasses = async (
    workerIds: string[],
    shiftId: string,
  ) => {
    setIsLoading(true)
    const loadingState = getLoadingStatus(workerShiftsWithStatus)
    setWorkerShiftsWithStatus(loadingState)

    const bypasses: AcceptShiftBypasses = {
      allowOverbook: true,
      bypassSignupStatus: true,
      bypassFavoritesOnly: true,
      bypassFavoritesFirst: true,
      bypassUnprovenThreshold: true,
      bypassUnprovenTodoLimit: true,
      bypassExtraBGCRequirement: true,
      bypassAttributeRequirements: true,
      bypassCertifications: true,
      bypassWorkerAvailability: true,
      bypassAccountStatus: true,
      bypassMinimumAcceptedTier: true,
      bypassShiftStatus: true,
      bypassForwardFill: true,
      bypassRequiredMultiShift: true,
      bypassInvitations: true,
      bypassNoBackfill: true,
      bypassWorkerShiftIsNotBizCancelled: true,
      bypassWorkerShiftIsNotOpsCancelled: true,
    }

    try {
      const res = await trabaApi.post(
        `/worker-shifts/${shiftId}/accept-shift`,
        {
          workerIds,
          bypasses,
        },
      )

      const errorWorkers = res.data.filter((d: any) => !!d.error)
      setWorkerShiftsWithStatus((prev: ConfirmationStatuses[]) =>
        prev.map((ws) => {
          const errorFound = errorWorkers.find(
            (errorWorker: ConfirmationStatuses) =>
              errorWorker.workerId === ws.workerId && shiftId === ws.shiftId,
          )
          if (errorFound) {
            console.error(
              errorFound,
              'ERROR adding workers to shift',
              ws.shiftId,
            )
            return {
              ...ws,
              status: 'error',
              message: errorFound.message,
            }
          }
          if (workerIds.includes(ws.workerId) && shiftId === ws.shiftId) {
            return {
              ...ws,
              status: 'success',
            }
          }
          return ws
        }),
      )
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      console.error('ERROR adding workers to shifts', e)
      showError('Error adding some workers to shifts', e.message)
    }
  }

  async function handleAddWorkers(workersByShiftId: Record<string, string[]>) {
    await Promise.all(
      Object.entries(workersByShiftId).map(([shiftId, workerIds]) =>
        handleAcceptShiftWithBypasses(workerIds, shiftId),
      ),
    )
    refetchWorkerShifts()
  }

  return {
    handleAddWorkers,
    workerShiftsWithStatus,
  }
}

async function getTimesheetReport(companyId?: string, shiftIds?: string[]) {
  try {
    const res = await trabaApi.put<string>(
      `companies/${companyId}/timesheet-report`,
      {
        shiftIds,
      },
    )
    return res.data
  } catch (error) {
    console.error('Failed to get timesheet report', error)
    Sentry.captureException(error)
    throw error
  }
}

export const useTimesheetReport = (companyId?: string, shiftIds?: string[]) => {
  const { showError } = useAlert()

  const { data, error, isError, isLoading } = useQuery<string | undefined>({
    queryKey: [TIMESHEET_REPORT_QUERY_KEY, ...(shiftIds ? shiftIds : [])],
    queryFn: () => getTimesheetReport(companyId, shiftIds),
    enabled: companyId !== undefined && shiftIds !== undefined,
    staleTime: Infinity,
  })

  if (isError) {
    showError(error?.message, 'Failed to get timesheet report')
  }

  return {
    downloadUrl: data,
    isLoading,
  }
}
