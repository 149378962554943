import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Row,
  StarRating,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeStarLevels,
  ROLE_ATTRIBUTE_STAR_RATING_TYPE,
  RoleAttributeCategory,
} from '@traba/types'
import { useCallback, useState } from 'react'
import { invalidateAuditLogQueries } from 'src/hooks/useAuditLogs'
import { useWorkerProfileMutations } from 'src/hooks/useWorkerProfile'
import { WorkerRatingModal } from 'src/modals/WorkerRatingModal/WorkerRatingModal'
import {
  getLevelNumberFromStarAttributeLevel,
  getStarAttributeLevelFromNumber,
} from 'src/utils/attributeUtils'
import styled from 'styled-components'
import { useModal } from '../base/Modal/Modal'

interface WorkerRatingProps {
  workerId: string
  opsStarLevel: AttributeStarLevels | undefined
  bizRating?: number
  showBizRating?: boolean
  workerName: string
  isLoading: boolean
}

export function WorkerRating(props: WorkerRatingProps) {
  const {
    workerId,
    opsStarLevel,
    bizRating,
    showBizRating,
    workerName,
    isLoading,
  } = props

  const [newRating, setNewRating] = useState<number | null>(null)
  const [showPreviousRatings, setShowPreviousRatings] = useState(false)

  const { isOpen, handleClose: handleModalClose, open } = useModal()

  const { updateWorkerProfileOpsLevelMutation } = useWorkerProfileMutations()
  const queryClient = useQueryClient()
  const handleSaveOpsStarRating = useCallback(
    (rating: number, ratingNote: string | undefined) => {
      updateWorkerProfileOpsLevelMutation.mutate(
        {
          workerId,
          items: [
            {
              profileField: ROLE_ATTRIBUTE_STAR_RATING_TYPE,
              opsLevel: getStarAttributeLevelFromNumber(rating),
              ...(ratingNote && { opsNotes: ratingNote }),
              category: RoleAttributeCategory.TRAITS,
            },
          ],
        },
        {
          onSuccess: () => {
            invalidateAuditLogQueries(queryClient)
          },
        },
      )
    },
    [queryClient, updateWorkerProfileOpsLevelMutation, workerId],
  )

  function handleClose() {
    setNewRating(null)
    handleModalClose()
    setShowPreviousRatings(false)
  }

  function handleStarClick(val: number | null) {
    setNewRating(val)
    open()
  }

  function handleViewRatingsClick() {
    setShowPreviousRatings(true)
    open()
  }

  function handleConfirm(ratingNote: string | undefined) {
    if (newRating) {
      handleSaveOpsStarRating(newRating, ratingNote)
    }
    handleClose()
  }

  return (
    <>
      <RatingContainer>
        <Row justifyBetween alignCenter>
          <Text variant="caption">OPS RATING</Text>
          <Button
            variant={ButtonVariant.TEXT}
            slim
            onClick={handleViewRatingsClick}
          >
            View Ratings
          </Button>
        </Row>
        {!isLoading ? (
          <StarRating
            id={workerId}
            value={getLevelNumberFromStarAttributeLevel(opsStarLevel)}
            hideLabel
            showValue
            size={24}
            onValueChange={(val: number | null) => handleStarClick(val)}
          />
        ) : (
          <LoadingSpinner style={{ height: 24, width: 24 }} />
        )}
        {showBizRating && (
          <>
            <Row mt={theme.space.xs}>
              <Text variant="caption" mb={theme.space.xxxs}>
                BIZ RATING
              </Text>
            </Row>
            <StarRating
              id={workerId}
              value={bizRating ?? 0}
              readOnly
              hideLabel
              showValue
              size={24}
            />
          </>
        )}
      </RatingContainer>
      <WorkerRatingModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        updateStarRating={setNewRating}
        showPreviousRatings={showPreviousRatings}
        setShowPreviousRatings={setShowPreviousRatings}
        workerId={workerId}
        workerName={workerName}
        starRating={newRating}
      />
    </>
  )
}

export const RatingContainer = styled.div`
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  padding-left: ${({ theme }) => `${theme.space.xs}px`};
  padding-right: ${({ theme }) => `${theme.space.xs}px`};
  padding-bottom: ${({ theme }) => `${theme.space.xs}px`};
  margin-right: ${({ theme }) => `${theme.space.med}px`};
  margin-top: ${({ theme }) => `${theme.space.xs}px`};
  margin-bottom: ${({ theme }) => `${theme.space.xs}px`};
  width: auto;
`
