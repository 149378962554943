import DOMPurify from 'dompurify'
import Quill from 'quill'
import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import 'quill/dist/quill.snow.css'

interface EditorProps {
  defaultValue?: string
  onTextChange: (htmlContent: string) => void
  textAreaStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
}

// Basic rich text editor using QuillJS (https://quilljs.com/)
export const TextEditor = forwardRef<Quill, EditorProps>(
  ({ defaultValue, onTextChange, textAreaStyle, containerStyle }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const textAreaRef = useRef<HTMLDivElement | null>(null)
    const defaultValueRef = useRef<string | undefined>(defaultValue)
    const onTextChangeRef = useRef<(htmlContent: string) => void>(onTextChange)

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange
    }, [onTextChange])

    useEffect(() => {
      const container = containerRef.current
      if (container) {
        const editorContainer = container.appendChild(
          container.ownerDocument.createElement('div'),
        )
        textAreaRef.current = editorContainer

        const toolbarOptions = [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'], // remove formatting button
        ]
        const quill = new Quill(editorContainer, {
          theme: 'snow',
          modules: {
            toolbar: toolbarOptions,
          },
        })

        if (ref && typeof ref !== 'function') {
          ref.current = quill
        }

        if (defaultValueRef.current) {
          quill.setContents(
            quill.clipboard.convert({ html: defaultValueRef.current }),
          )
        }

        quill.on(Quill.events.TEXT_CHANGE, () => {
          if (onTextChangeRef.current) {
            const unsafeHTML = quill.getSemanticHTML()
            onTextChangeRef.current(DOMPurify.sanitize(unsafeHTML))
          }
        })

        return () => {
          if (ref && typeof ref !== 'function') {
            ref.current = null
          }
          container.innerHTML = ''
        }
      }
    }, [ref])

    useEffect(() => {
      if (textAreaRef.current && textAreaStyle) {
        Object.assign(textAreaRef.current.style, textAreaStyle)
      }
    }, [textAreaStyle])

    return <div ref={containerRef} style={{ ...containerStyle }}></div>
  },
)
