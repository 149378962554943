import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import Badge from '.'

const employmentTypeToBadgeVariantDict: Record<
  EmploymentType | string,
  string
> = {
  [EmploymentType.W2]: 'warning',
  [EmploymentType.CONTRACTOR_1099]: 'success',
  '1099': 'info',
}

export type EmploymentTypeBadgeProps = {
  employmentTypes: EmploymentType[]
  showAll?: boolean
}

export function EmploymentTypeBadge({
  employmentTypes,
  showAll,
}: EmploymentTypeBadgeProps) {
  const renderedEmploymentTypes = showAll
    ? employmentTypes.map((et) => (et === EmploymentType.W2 ? 'W2' : '1099'))
    : employmentTypes.filter((et) => et !== EmploymentType.CONTRACTOR_1099)

  if (renderedEmploymentTypes.length === 0) {
    return undefined
  }

  return (
    <>
      {renderedEmploymentTypes.map((et) => (
        <Row mr={theme.space.xxxs} key={et}>
          <Badge
            style={{ width: 60 }}
            title={et?.replaceAll('_', ' ')}
            variant={employmentTypeToBadgeVariantDict[et]}
          />
        </Row>
      ))}
    </>
  )
}
