import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyResponse } from '@traba/types'
import { Link } from 'react-router-dom'
import { Row, Button } from 'src/components/base'
import CompanyStatusBadge from 'src/components/base/Badge/CompanyStatusBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import CopyTextIcon from 'src/components/base/CopyTextIcon/CopyTextIcon'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import WorkerSearchSkeleton from 'src/screens/WorkerSearchScreen/components/WorkerSearchSkeleton'
import {
  getDateTimeWithTimezone,
  formatDateTimeWithTimezone,
} from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'

export default function CompanySearchResults({
  isLoading,
  error = '',
  companies = [],
}: any) {
  const numberOfResults = companies.length

  const headers: (DataTableHeader | string)[] = [
    {
      key: 'companyName',
      label: 'Company Name',
      sortable: true,
    },
    {
      key: 'companyId',
      label: 'Company ID',
      sortable: true,
    },
    {
      key: 'category',
      label: 'Category',
      sortable: true,
    },
    {
      key: 'mainLocation',
      label: 'Main Location',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      sortable: true,
    },
    {
      key: 'postShift',
      label: '',
      sortable: false,
    },
  ]

  const rows: TableRow[] = companies?.map(
    (company: CompanyResponse, index: number) => {
      const {
        employerName,
        id: companyId,
        addresses,
        category,
        isApproved,
        createdAt,
      } = company

      const address = addresses?.[0]
      const mainLocation = address ? `${address.city}, ${address.state}` : '-'
      const createdAtDateTime = getDateTimeWithTimezone(createdAt)

      const cells: TableCell[] = [
        {
          sortKey: employerName,
          renderFn: () => (
            <Link to={`/companies/${company.id}`}>
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {company.employerName}
              </Button>
            </Link>
          ),
        },
        {
          sortKey: companyId,
          renderFn: () => (
            <>
              {truncateString(company.id || '')}{' '}
              <CopyTextIcon textToCopy={company.id} />
            </>
          ),
        },
        {
          sortKey: category,
          renderFn: () => category || '-',
        },
        {
          sortKey: mainLocation,
          renderFn: () => mainLocation,
        },
        {
          sortKey: `${isApproved}_${index}`,
          renderFn: () => <CompanyStatusBadge isApproved={isApproved} />,
        },
        {
          sortKey: Date.parse(createdAtDateTime),
          renderFn: () => formatDateTimeWithTimezone(company.createdAt),
        },
        {
          renderFn: () => (
            <Row alignCenter mt={theme.space.xxxs} mb={theme.space.xxxs}>
              <Link to={`/post/${companyId}`}>
                <Button>Post Shift</Button>
              </Link>
            </Row>
          ),
        },
      ]

      return { key: companyId, cells }
    },
  )

  const searchResultsTable = isLoading ? (
    <WorkerSearchSkeleton />
  ) : (
    <DataTable
      headers={headers}
      rows={rows}
      initialSortByColumnIndex={5} // sort by createdAt column
      initialSortByAscOrder={false}
    />
  )

  return (
    <>
      <Row mt={46} mb={8}>
        <Text variant="h5">
          {isLoading ? 'Searching...' : `Results (${numberOfResults})`}
        </Text>
      </Row>
      {error ? (
        <Row mb={30}>
          <Text variant="error">
            {`Error searching for company : ${error}`}
          </Text>
        </Row>
      ) : (
        searchResultsTable
      )}
    </>
  )
}
