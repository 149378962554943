import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { WorkerSearchParams, WorkerSearchParamsRaw } from '@traba/types'
import { JobStatus, WorkerEarning } from '@traba/types'
import { Worker, WorkerShiftEligibility } from '@traba/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { sortByWorkerName } from 'src/utils/workerUtils'
import { OrFields, workerSearch, workerSearchRaw } from './searchWorkersApi'
import { WorkerSearchRelations } from './searchWorkersApi'
import {
  basePaginationParams,
  useBasicPagination,
  DEFAULT_PAGE_SIZE,
  SortOrder,
} from './usePagination'
import { convertQueryArrayToObject } from './useQueryBuilder'

/**
 * Clean up special characters in phone number field since it's not supported by the server
 * Also prepend them with a +1
 */
export function sanitizePhoneNumber(searchData: Record<string, any>) {
  const phoneKey: keyof Worker = 'phoneNumber'
  if (searchData[phoneKey]) {
    searchData[phoneKey] = searchData[phoneKey].replace(/\D|\s/g, '') // remove all special characters
    if (searchData[phoneKey].length < 11) {
      searchData[phoneKey] = '+1' + searchData[phoneKey] // only works for US-based numbers
    }
  }
  return searchData
}

export function extractArrayValues(
  form: Record<string, any>,
): Record<string, any> {
  const extractedValues: Record<string, any> = {}
  Object.keys(form).forEach((key: string) => {
    if (
      Array.isArray(form[key]) &&
      typeof form[key][0] === 'object' &&
      'value' in form[key][0]
    ) {
      const values = form[key].map((item: any) => item.value)
      extractedValues[key] = values
    } else {
      extractedValues[key] = form[key]
    }
  })
  return extractedValues
}

export enum SearchWorkersType {
  raw = 'RAW',
  basic = 'BASIC',
}

export const useSearchWorkers = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [totalFound, setTotalFound] = useState(0)
  const [workersSearchResult, setWorkersSearchResult] = useState<
    PopulatedWorker[]
  >([])
  const [activeSearchParams, setActiveSearchParams] = useState<
    Record<string, any> | any[]
  >()
  const activeSearchParamsObject = Array.isArray(activeSearchParams)
    ? convertQueryArrayToObject(activeSearchParams)
    : activeSearchParams
  const {
    getNextPaginationOffsetAndUpdatePage,
    currentPage,
    goToNextPage,
    goToPreviousPage,
  } = useBasicPagination()

  async function runSearch({
    searchData,
    direction,
    activeOrFields,
    limit,
    includes,
    sort,
    cacheKey,
    fullTextSearchParam,
  }: {
    searchData: Record<string, any>
    direction: 'next' | 'previous' | 'initial'
    activeOrFields?: OrFields[]
    limit?: number
    includes?: WorkerSearchRelations
    sort?: { sortBy: string; sortOrder: SortOrder }
    cacheKey?: string
    fullTextSearchParam?: string
  }) {
    const workersSearchResult = await workerSearch({
      parameters: sanitizePhoneNumber(extractArrayValues(searchData)),
      paginationParams: {
        ...basePaginationParams,
        limit: limit || DEFAULT_PAGE_SIZE,
        offset: getNextPaginationOffsetAndUpdatePage(direction),
        sortBy: sort?.sortBy || 'firstName',
        sortOrder: sort?.sortOrder || SortOrder.asc,
      },
      activeOrFields,
      fullTextSearchParam,
      includes: {
        workerMetric: true,
        workAvailability: true,
        ...includes,
      },
      select: {
        accountStatus: [
          'accountStatus',
          'activeEmploymentTypes',
          'phoneNumberStatus',
        ],
        payment: [
          'payoutsEnabled',
          'payoutsDisabledReason',
          'payoutsDisabledDeadline',
        ],
        worker: [
          'id',
          'firstName',
          'lastName',
          'zipCode',
          'regionId',
          'phoneNumber',
          'lastActiveAt',
          'stripeAccountId',
          'photoUrl',
        ],
        backgroundCheck: [
          'candidateId',
          'reportId',
          'status',
          'adjudication',
          'assessment',
        ],
      },
    })
    localStorage.setItem(
      cacheKey || 'cached-form-value',
      JSON.stringify(searchData),
    )
    setWorkersSearchResult(workersSearchResult.workers.sort(sortByWorkerName))
    setTotalFound(workersSearchResult.count)
  }

  async function runSearchRaw({
    searchData,
    direction,
    limit,
    sort,
    cacheKey,
  }: {
    searchData: Record<string, any>
    direction: 'next' | 'previous' | 'initial'
    activeOrFields?: OrFields[]
    limit?: number
    sort?: { sortBy: string; sortOrder: SortOrder }
    cacheKey?: string
  }) {
    const workersSearchResult = await workerSearchRaw(
      sanitizePhoneNumber(extractArrayValues(searchData)),
      {
        ...basePaginationParams,
        limit: limit || DEFAULT_PAGE_SIZE,
        offset: getNextPaginationOffsetAndUpdatePage(direction),
        sortBy: sort?.sortBy || 'firstName',
        sortOrder: sort?.sortOrder || SortOrder.asc,
      },
      {
        accountStatus: ['accountStatus'],
        worker: [
          'id',
          'firstName',
          'lastName',
          'zipCode',
          'regionId',
          'phoneNumber',
          'lastActiveAt',
          'stripeAccountId',
          'photoUrl',
        ],
      },
    )
    localStorage.setItem(
      cacheKey || 'cached-form-value',
      JSON.stringify(searchData),
    )
    setWorkersSearchResult(workersSearchResult.workers)
    setTotalFound(workersSearchResult.count)
  }

  async function searchWorkers({
    searchData,
    direction = 'initial',
    activeOrFields,
    fullTextSearchParam,
    limit,
    includes,
    sort,
    type = SearchWorkersType.basic,
    cacheKey,
  }: {
    searchData: WorkerSearchParams | WorkerSearchParamsRaw
    direction: 'next' | 'previous' | 'initial'
    activeOrFields?: OrFields[]
    fullTextSearchParam?: string
    limit?: number
    includes?: WorkerSearchRelations
    sort?: { sortBy: string; sortOrder: SortOrder }
    type?: SearchWorkersType
    cacheKey?: string
  }) {
    try {
      setActiveSearchParams(searchData)
      setIsLoading(true)
      setError('')
      if (type === SearchWorkersType.raw) {
        return await runSearchRaw({
          searchData,
          direction,
          limit,
          sort,
          cacheKey,
        })
      }
      return await runSearch({
        searchData,
        direction,
        activeOrFields,
        fullTextSearchParam,
        limit,
        includes,
        sort,
        cacheKey,
      })
    } catch (err) {
      setWorkersSearchResult([])
      setError(err as string)
    } finally {
      setIsLoading(false)
    }
  }

  function reset() {
    setWorkersSearchResult([])
    setError('')
    setTotalFound(0)
    setActiveSearchParams({})
  }

  return {
    workersSearchResult,
    totalFound,
    isLoading,
    error,
    searchWorkers,
    runSearch,
    activeSearchParamsObject,
    currentPage,
    goToNextPage,
    goToPreviousPage,
    reset,
  }
}

export const DEFAULT_INITIAL_FORM = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  workerId: '',
  email: '',
}

async function getWorkerEligibility(
  workerId: string,
  shiftId: string,
): Promise<WorkerShiftEligibility | undefined> {
  try {
    const res = await trabaApi.get(
      `/workers/${workerId}/worker-shifts/${shiftId}/check-eligibility`,
    )
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkerEligibility() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function useWorkerEligibility(workerId: string, shiftId: string) {
  const {
    isLoading,
    isError,
    data: eligibility,
    error,
    isFetched,
    refetch: refetchEligibility,
  } = useQuery<WorkerShiftEligibility | undefined, Error>(
    `eligibility_${workerId}_${shiftId}`,
    () => getWorkerEligibility(workerId, shiftId),
    { enabled: false },
  )

  return {
    isLoading,
    isError,
    eligibility,
    error,
    isFetched,
    refetchEligibility,
  }
}

async function getWorkerEarnings(
  workerId: string,
): Promise<WorkerEarning[] | undefined> {
  try {
    const res = await trabaApi.get(
      `/workers/${workerId}/worker-shifts?adjustmentsOption=SEPARATE&fetchIncentivePay=true`,
    )
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkerEarnings() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function useWorkerEarnings(workerId: string) {
  const {
    isLoading,
    isError,
    data: workerEarnings,
    error,
    isFetched,
    refetch: refetchWorkerEarnings,
  } = useQuery<WorkerEarning[] | undefined, Error>(
    `earnings_${workerId}`,
    () => getWorkerEarnings(workerId),
    { enabled: false },
  )

  const completedEarnings = workerEarnings?.filter(
    (ws) =>
      (!!ws.grossPay && ws.grossPay.amount > 0) ||
      ws.jobStatus === JobStatus.Appeased ||
      ws.transactions?.length,
  )

  return {
    isLoading,
    isError,
    workerEarnings: completedEarnings,
    error,
    isFetched,
    refetchWorkerEarnings,
  }
}

export async function updateWorkerData(
  workerId: string,
  updates: Partial<Worker>,
) {
  await trabaApi.patch(`/workers/${workerId}`, updates)
}

async function getWorkerShiftEarning(
  workerId: string,
  shiftId: string,
): Promise<WorkerEarning | undefined> {
  try {
    const res = await trabaApi.get(
      `/workers/${workerId}/worker-shifts/${shiftId}/earnings?adjustmentsOption=SEPARATE&fetchIncentives=true&fetchWorkerIncentives=true`,
    )
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkerShiftEarning() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function useWorkerShiftEarning(workerId: string, shiftId: string) {
  const {
    isLoading,
    isError,
    data: workerEarning,
    error,
    isFetched,
    refetch: refetchWorkerEarning,
  } = useQuery<WorkerEarning | undefined, Error>(
    `earnings_${workerId}_${shiftId}}`,
    () => getWorkerShiftEarning(workerId, shiftId),
    { enabled: false },
  )

  return {
    isLoading,
    isError,
    workerEarning,
    error,
    isFetched,
    refetchWorkerEarning,
  }
}

async function getWorkersWithDetails(workerIds: string[]) {
  try {
    // Is this necessary
    if (!workerIds.length) {
      return []
    }

    const res = await trabaApi.post('/workers/query-workers-with-details', {
      workerIds,
    })

    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkersWithDetails() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    Sentry.captureException(errorMessage)
  }
}

export function useWorkersWithDetails(workerIds: string[]) {
  const {
    isLoading,
    isError,
    data: workersWithDetails,
    error,
    isFetched,
    refetch: refetchWorkersWithDetails,
  } = useQuery<PopulatedWorker[] | undefined, Error>(
    ['workersWithDetails', workerIds],
    () => getWorkersWithDetails(workerIds),
  )

  return {
    isLoading,
    isError,
    workersWithDetails,
    error,
    isFetched,
    refetchWorkersWithDetails,
  }
}
