import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  AccountApprovalStatus,
  ApprovalStatusReason,
  PhoneNumberStatus,
} from '@traba/types'
import { useState } from 'react'
import { getErrorMessage } from 'src/utils/errorUtils'

export function useAccountStatus(workerId: string) {
  const { showError } = useAlert()
  const [accountStatusLoading, setAccountStatusLoading] =
    useState<boolean>(false)

  const updateAccountStatus = async (
    accountStatus: AccountApprovalStatus,
    approvalStatusReason?: ApprovalStatusReason,
  ) => {
    setAccountStatusLoading(true)
    try {
      await trabaApi.patch(`/workers/${workerId}/approval-status`, {
        accountStatus,
        ...(approvalStatusReason ? { approvalStatusReason } : {}),
      })
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Updating Account Status')
    }
    setAccountStatusLoading(false)
  }

  const [instantPayLoading, setInstantPayLoading] = useState<boolean>(false)

  const updateInstantPay = async (eligible: boolean, isPermanent?: boolean) => {
    setInstantPayLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerId}/instant-pay-eligibility?isEligible=${eligible}${
          !eligible && isPermanent ? '&isPermanent=true' : ''
        }`,
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Updating Instant Pay Eligibility')
    }
    setInstantPayLoading(false)
  }

  const {
    mutate: updatePhoneNumberStatus,
    isPending: phoneNumberStatusPending,
  } = useMutation({
    mutationFn: async (phoneNumberStatus: PhoneNumberStatus) => {
      await trabaApi.patch(`/workers/${workerId}/phone-number-status`, {
        phoneNumberStatus,
      })
    },
    onError: (error: unknown) => {
      showError(getErrorMessage(error), 'Error Updating Phone Number Status')
    },
  })

  return {
    accountStatusLoading,
    updateAccountStatus,
    instantPayLoading,
    updateInstantPay,
    phoneNumberStatusPending,
    updatePhoneNumberStatus,
  }
}
