import * as S from './RadioButton.styles'

export type RadioButtonProps = {
  selected?: boolean
  disabled?: boolean
  size?: number
}

export const RadioButton = (props: RadioButtonProps) => {
  const { selected, disabled, size } = props
  return (
    <S.RadioButtonContainer>
      {selected ? (
        <S.SelectedRadioButton size={size}>
          <S.RadioButtonInside size={size && Math.floor(size / 3)} />
        </S.SelectedRadioButton>
      ) : (
        <S.NotSelectedRadioButton disabled={disabled} size={size} />
      )}
    </S.RadioButtonContainer>
  )
}
