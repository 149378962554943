export function makeSearchParameters<T, U>(
  parameters: T,
  activeOrFields?: U[],
  fullTextSearchParam?: string,
): T {
  const parsedParameters = { ...parameters }

  if (activeOrFields?.length && fullTextSearchParam) {
    const orFieldsObject: Record<string, string> = {}
    for (const field of activeOrFields) {
      orFieldsObject[field as string] = fullTextSearchParam
    }
    return {
      ...parsedParameters,
      orFields: orFieldsObject,
    } as T
  }
  return parsedParameters
}
