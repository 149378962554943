import {
  CheckCircle,
  ErrorOutlineSharp,
  HourglassEmpty,
} from '@mui/icons-material'
import { CircularProgress, LinearProgress, Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InitialAcceptShiftBypasses } from '@traba/types'
import { GenericAcceptShiftBypasses } from '@traba/types'
import { Worker } from '@traba/types'
import { Col, Row } from 'src/components/base'
import * as S from '../../styles'
import { useEligibilitiesForWorkers } from './hooks/useEligibilitiesForWorkers'
import { ConfirmationStatuses } from './types'

const ShiftForConfirmationItem = ({
  shiftId,
  workerId,
  name,
  status,
  message = '',
  skipReason = '',
}: ConfirmationStatuses & { skipReason?: string }) => {
  const iconByStatus = {
    error: <ErrorOutlineSharp color="error" />,
    loading: <CircularProgress color="info" size={18} />,
    success: <CheckCircle color="success" />,
    pending: <HourglassEmpty color="action" />,
  }

  const rowStyle = {
    backgroundColor:
      status === 'error'
        ? theme.colors.Red10
        : skipReason
          ? theme.colors.Blue10
          : status === 'success'
            ? theme.colors.Green10
            : 'transparent',
  }

  return (
    <S.ListItemWithStatus>
      <Row
        alignCenter
        justifyBetween
        fullWidth
        py={theme.space.xxs}
        px={theme.space.xs}
        style={rowStyle}
      >
        <Text>
          {name}
          <Text variant="label"> ({workerId}) </Text>
          on shift{' '}
          <a
            href={`/field-monitor/${shiftId}`}
            target="_blank"
            rel="noreferrer"
          >
            {shiftId}
          </a>
        </Text>
        <Row>
          {skipReason && <Text mr={theme.space.xs}>{skipReason}</Text>}
          <Tooltip
            title={message || skipReason}
            leaveDelay={300}
            placement="bottom-end"
            arrow
          >
            {skipReason ? <CheckCircle color="info" /> : iconByStatus[status]}
          </Tooltip>
        </Row>
      </Row>
    </S.ListItemWithStatus>
  )
}

export const ConfirmationStep = ({
  bypasses,
  toCancelWithStatus,
  workerShiftsWithStatus,
  selectedShifts,
  checkedWorkers,
}: {
  bypasses: GenericAcceptShiftBypasses
  toCancelWithStatus: ConfirmationStatuses[]
  workerShiftsWithStatus: ConfirmationStatuses[]
  selectedShifts: Record<string, string[]>
  checkedWorkers: Worker[]
}) => {
  const sortedToCancelWithStatus = toCancelWithStatus
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
  const sortedWorkerShiftsWithStatus = workerShiftsWithStatus
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))

  const { workersAlreadyOnShifts, isLoading } = useEligibilitiesForWorkers(
    selectedShifts,
    checkedWorkers.map((w) => w.id || w.workerId),
  )

  const getSkipReason = (workerId: string, shiftId: string) =>
    workersAlreadyOnShifts.find(
      (ws) => ws.workerId === workerId && ws.shiftId === shiftId,
    )
      ? 'Worker is already on the shift'
      : ''

  if (isLoading) {
    return (
      <Row fullWidth justifyCenter alignCenter style={{ flex: 1 }}>
        <Text>
          Validating workers and shifts... <LinearProgress />
        </Text>
      </Row>
    )
  }

  return (
    <Row flexCol justifyCenter fullHeight>
      {sortedToCancelWithStatus.length > 0 && (
        <Row my={theme.space.xs} flexCol>
          <Text variant="h6">Worker Shifts to Cancel:</Text>
          <Col ml={theme.space.xs}>
            <ul style={{ listStyle: 'none' }}>
              {toCancelWithStatus.map(
                ({ shiftId, workerId, name, status, message }) => (
                  <ShiftForConfirmationItem
                    key={`${workerId}-${shiftId}_to_cancel`}
                    shiftId={shiftId}
                    workerId={workerId}
                    name={name}
                    status={status}
                    message={message}
                    skipReason={getSkipReason(workerId, shiftId)}
                  />
                ),
              )}
            </ul>
          </Col>
        </Row>
      )}

      <Row flexCol>
        <Text variant="h6">Workers and shifts selected:</Text>
        <Col ml={theme.space.xs}>
          <ul style={{ listStyle: 'none' }}>
            {sortedWorkerShiftsWithStatus.map(
              ({ shiftId, workerId, name, status, message }) => (
                <ShiftForConfirmationItem
                  key={`${shiftId}_${workerId}_worker_shifts_to_add_confirm`}
                  shiftId={shiftId}
                  workerId={workerId}
                  name={name}
                  status={status}
                  message={message}
                  skipReason={getSkipReason(workerId, shiftId)}
                />
              ),
            )}
          </ul>
        </Col>
      </Row>

      <Row my={theme.space.med} flexCol>
        <Text variant="h6">Bypasses selected:</Text>
        <Col ml={theme.space.xs}>
          <ul style={{ listStyle: 'none' }}>
            {Object.keys(bypasses).map((bk: string) => (
              <li key={`${bk}_bypasses_selected`}>
                <Text variant="brand">
                  {
                    InitialAcceptShiftBypasses.find((b) => b.bypassKey === bk)
                      ?.label
                  }
                </Text>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Row>
  )
}
