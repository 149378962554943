import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import styled from 'styled-components'

type AvatarProps = {
  name: string
  src?: string | null
  size?: number
  round?: boolean
  onClick?: () => void
}

const AvatarWrapper = styled.div<{ size?: number; round?: boolean }>`
  height: ${(props) => props.size ?? 112}px;
  width: ${(props) => props.size ?? 112}px;
  border: 4px solid #ffffff;
  border-radius: ${(props) => (props.round ? '50%' : '16px')};
  background-color: #eee;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

export default function Avatar({
  src,
  size,
  name,
  round,
  onClick,
}: AvatarProps) {
  return (
    <AvatarWrapper size={size} round={round} onClick={onClick}>
      {src ? (
        <img src={src} alt={'Worker Profile'} />
      ) : (
        <Row alignCenter center fullHeight>
          <Text
            variant="h3"
            style={{ color: theme.colors.Violet, fontSize: 50 }}
          >
            {name[0]}
          </Text>
        </Row>
      )}
    </AvatarWrapper>
  )
}
