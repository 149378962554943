import { Row } from '@traba/react-components'
import styled from 'styled-components'

export const EmptySearchResultsMessageContainer = styled(Row)`
  border-radius: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.Grey20};
  padding: ${({ theme }) => theme.space.xs}px;
  margin-top: ${({ theme }) => theme.space.xs}px;
  justify-content: center;
`
