import Badge from '.'

export type CompanyStatusBadgeProps = {
  isApproved: boolean
  style?: React.CSSProperties
}

export default function CompanyStatusBadge({
  isApproved,
  ...props
}: CompanyStatusBadgeProps) {
  return (
    <Badge
      {...props}
      title={isApproved ? `APPROVED` : `UNAPPROVED`}
      variant={isApproved ? 'success' : 'opaqueRed'}
    />
  )
}
