import { DEFAULT_TIMEZONE } from '@traba/consts'
import { ButtonVariant, Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Locations,
  RecurringSchedule,
  SupportedRRuleFrequencies,
} from '@traba/types'
import { REPEAT_ON_OPTIONS, getNextStartAndEndTime } from '@traba/utils'
import { addMinutes } from 'date-fns'
import { SetStateAction, useCallback, useMemo, useState } from 'react'
import { WeekdayStr } from 'rrule'
import { Button, Input, Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import { MultiSelector } from 'src/components/base/MultiSelector/MultiSelector.ui'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'
import { ShiftScheduleSummary } from '../ShiftScheduleSummary'

interface Props {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  timezone: string
  selectedLocation: Locations | undefined
}

export const ScheduleRecurringForm = ({
  createShiftRequests,
  setCreateShiftRequests,
  timezone,
  selectedLocation,
}: Props) => {
  const [isBiweekly, setIsBiweekly] = useState(
    createShiftRequests[0].schedules.length > 1,
  )
  const scheduleA = useMemo(
    () => createShiftRequests[0].schedules[0],
    [createShiftRequests[0].schedules],
  )
  const scheduleB = useMemo(
    () =>
      createShiftRequests[0].schedules.length > 1
        ? createShiftRequests[0].schedules[1]
        : undefined,
    [createShiftRequests[0].schedules],
  )
  const handleWeekdaySelector = useCallback(
    (selectedWeekdays: WeekdayStr[], isWeekB: boolean) => {
      const baseRecurringSchedule: RecurringSchedule = {
        endDate: scheduleA.recurringSchedule?.endDate,
        freq: scheduleA.recurringSchedule?.freq ?? 'WEEKLY',
        interval: isBiweekly ? 2 : 1,
        repeatOn: scheduleA.recurringSchedule?.repeatOn ?? [],
      }

      setCreateShiftRequests((prev) => {
        return prev.map((shiftRequest) => {
          if (isWeekB) {
            const updatedScheduleB = {
              ...shiftRequest.schedules[0],
              recurringSchedule: {
                ...baseRecurringSchedule,
                repeatOn: selectedWeekdays,
              },
            }
            const { startTime, endTime } = getNextStartAndEndTime(
              scheduleA,
              updatedScheduleB,
            )
            return {
              ...shiftRequest,
              schedules: [
                {
                  ...shiftRequest.schedules[0],
                  recurringSchedule: baseRecurringSchedule,
                },
                {
                  ...shiftRequest.schedules[0],
                  startTime,
                  endTime,
                  recurringSchedule: {
                    ...baseRecurringSchedule,
                    repeatOn: selectedWeekdays,
                  },
                },
              ],
            }
          }

          return {
            ...shiftRequest,
            schedules: [
              {
                ...shiftRequest.schedules[0],
                recurringSchedule: {
                  ...baseRecurringSchedule,
                  repeatOn: selectedWeekdays,
                },
              },
              ...(isBiweekly ? [shiftRequest.schedules[1]] : []),
            ],
          }
        })
      })
    },
    [isBiweekly, scheduleA, setCreateShiftRequests],
  )

  return (
    <Col
      mt={theme.space.sm}
      gap={theme.space.sm}
      style={{
        border: `2px dashed ${theme.colors.Grey30} `,
        padding: theme.space.sm,
        borderRadius: theme.space.xs,
      }}
    >
      <ShiftPostingInputContainerSection
        label="Schedule Name (Required)"
        input={
          <>
            <Input
              value={createShiftRequests[0].shiftRequestParentTitle}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                setCreateShiftRequests((prev) => {
                  return prev.map((shiftRequest) => {
                    return {
                      ...shiftRequest,
                      shiftRequestParentTitle: e.target.value,
                    }
                  })
                })
              }}
              full
            />
            {!createShiftRequests[0].shiftRequestParentTitle && (
              <Text variant="error">Schedule name is required</Text>
            )}
          </>
        }
      />
      <ShiftPostingInputContainerSection
        label="Repeats"
        input={
          <Row gap={theme.space.xs}>
            <Button
              variant={
                isBiweekly ? ButtonVariant.OUTLINED : ButtonVariant.FILLED
              }
              onClick={() => {
                setIsBiweekly(false)
              }}
            >
              Weekly
            </Button>
            <Button
              variant={
                isBiweekly ? ButtonVariant.FILLED : ButtonVariant.OUTLINED
              }
              onClick={() => {
                setIsBiweekly(true)
                setCreateShiftRequests((prev) => {
                  return prev.map((prevShiftRequest) => {
                    return {
                      ...prevShiftRequest,
                      schedules: [
                        {
                          ...prevShiftRequest.schedules[0],
                          recurringSchedule: {
                            freq: 'WEEKLY',
                            interval: 2,
                            endDate:
                              prevShiftRequest.schedules[0].recurringSchedule
                                ?.endDate,
                            repeatOn:
                              prevShiftRequest.schedules[0].recurringSchedule
                                ?.repeatOn || [],
                          },
                        },
                        {
                          ...prevShiftRequest.schedules[0],
                          recurringSchedule: {
                            freq: 'WEEKLY',
                            interval: 2,
                            endDate:
                              prevShiftRequest.schedules[0].recurringSchedule
                                ?.endDate,
                            repeatOn: [],
                          },
                        },
                      ],
                    }
                  })
                })
              }}
            >
              Biweekly
            </Button>
          </Row>
        }
      />

      <ShiftPostingInputContainerSection
        label="End Date"
        input={
          <Row alignCenter>
            <DatePicker
              date={
                createShiftRequests[0].schedules[0].recurringSchedule
                  ?.endDate ?? null
              }
              minDate={addMinutes(
                createShiftRequests[0].schedules[0].endTime,
                1,
              )}
              showTimeFieldInPopover={false}
              granularity="day"
              setDate={(date) => {
                setCreateShiftRequests((prev) => {
                  return prev.map((prevShiftRequest) => {
                    const updatedSchedules = prevShiftRequest.schedules.map(
                      (schedule) => {
                        const newRecurringSchedule = {
                          ...schedule.recurringSchedule,
                          freq: 'WEEKLY' as SupportedRRuleFrequencies,
                          interval: schedule.recurringSchedule?.interval || 1,
                          repeatOn: schedule.recurringSchedule?.repeatOn || [],
                          endDate: date ?? undefined,
                        }
                        if (date === null) {
                          delete newRecurringSchedule.endDate
                        }
                        return {
                          ...schedule,
                          recurringSchedule: newRecurringSchedule,
                        }
                      },
                    )
                    return {
                      ...prevShiftRequest,
                      schedules: updatedSchedules,
                    }
                  })
                })
              }}
              isClearable
              timezone={timezone}
              label="End Date"
            />
            <Text variant="body3" ml={theme.space.xs}>
              (Leave end date empty if the schedule has no end)
            </Text>
          </Row>
        }
      />

      <Row mt={theme.space.xs} alignCenter wrap>
        <Text variant="h5" style={{ marginRight: theme.space.med }}>
          {isBiweekly ? 'Week A' : 'Which days?'}
        </Text>

        <MultiSelector
          options={REPEAT_ON_OPTIONS}
          style={{ flexWrap: 'wrap' }}
          selectedOptions={scheduleA.recurringSchedule?.repeatOn || []}
          onChange={(selected) => {
            handleWeekdaySelector(selected, false)
          }}
        />

        <Button
          onClick={() =>
            handleWeekdaySelector(['MO', 'TU', 'WE', 'TH', 'FR'], false)
          }
          style={{ marginLeft: theme.space.med }}
          variant={ButtonVariant.TEXT}
        >
          Select Weekdays
        </Button>
      </Row>
      {(scheduleA.recurringSchedule?.repeatOn ?? []).length === 0 && (
        <Text variant="error">Please select at least one recurring day</Text>
      )}
      {isBiweekly && (
        <>
          <Row alignCenter wrap>
            <Text variant="h5" style={{ marginRight: theme.space.med }}>
              Week B
            </Text>
            <MultiSelector
              options={REPEAT_ON_OPTIONS}
              style={{ flexWrap: 'wrap' }}
              selectedOptions={scheduleB?.recurringSchedule?.repeatOn || []}
              onChange={(selected) => {
                handleWeekdaySelector(selected, true)
              }}
            />
            <Button
              onClick={() =>
                handleWeekdaySelector(['MO', 'TU', 'WE', 'TH', 'FR'], true)
              }
              style={{ marginLeft: theme.space.med }}
              variant={ButtonVariant.TEXT}
            >
              Select Weekdays
            </Button>
          </Row>
          {(scheduleB?.recurringSchedule?.repeatOn ?? []).length === 0 && (
            <Text variant="error">
              Please select at least one recurring day for Week B
            </Text>
          )}
        </>
      )}

      <Row mt={theme.space.sm}>
        <ShiftScheduleSummary
          shiftRequest={createShiftRequests[0]}
          shiftTimeZone={selectedLocation?.timezone || DEFAULT_TIMEZONE}
        />
      </Row>
    </Col>
  )
}
