import { Text } from '@traba/react-components'
import { TableVirtuoso } from 'react-virtuoso'
import { Td } from 'src/components/base'
import Checkbox from '../Checkbox'
import { TableRow, DataTableProps } from './DataTable'
import { TableHeader, TableHeaderItem } from './Table.styles'

interface VirtualizedTableProps extends DataTableProps {
  headers: string[]
  rows: TableRow[]
  selectedRows?: TableRow[]
  onSelectRows?: (selectedRows: TableRow[]) => void
  allowSelect?: boolean
  slim?: boolean
  style?: React.CSSProperties
}

const MAX_TABLE_HEIGHT = 700

export const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  headers,
  rows,
  allowSelect,
  selectedRows,
  onSelectRows,
  slim,
  style,
}) => {
  function onToggleRowSelection(
    ev: React.ChangeEvent<HTMLInputElement>,
    row: TableRow,
  ) {
    const { checked } = ev.target
    if (!onSelectRows) {
      return
    }

    const updatedSelectedRows = checked
      ? [...(selectedRows ?? []), row]
      : selectedRows!.filter((r) => r.key !== row.key)

    onSelectRows(updatedSelectedRows)
  }

  function onPressSelectAll() {
    if (!onSelectRows) {
      return
    }
    if (selectedRows?.length === rows.length) {
      return onSelectRows([])
    }
    onSelectRows(rows)
  }

  // The height of the table is calculated based on the number of rows.
  // However, the height is capped at 700px to prevent the table from becoming too tall.
  const bottomPadding = slim ? 50 : 150
  const height = Math.min(rows.length * 60 + bottomPadding, MAX_TABLE_HEIGHT)

  return (
    <TableVirtuoso
      style={{
        height,
        overflow: height === MAX_TABLE_HEIGHT ? 'scroll' : 'visible',
        ...style,
      }}
      data={rows}
      fixedHeaderContent={() => (
        <TableHeader>
          {allowSelect && (
            <th style={{ paddingLeft: 12 }}>
              <Checkbox
                onChange={onPressSelectAll}
                checked={selectedRows?.length === rows.length}
                labelStyle={{ top: -12 }}
              />
            </th>
          )}
          {headers.map((header, i) => (
            <th key={i} style={{ whiteSpace: 'nowrap' }}>
              <TableHeaderItem>
                <Text variant="caption">{header.toLocaleUpperCase()}</Text>
              </TableHeaderItem>
            </th>
          ))}
        </TableHeader>
      )}
      itemContent={(_, row) => (
        <>
          {allowSelect && (
            <Td>
              <Checkbox
                checked={!!selectedRows?.find((r) => r.key === row.key)}
                onChange={(ev) => onToggleRowSelection(ev, row)}
                labelStyle={{ top: -12 }}
              />
            </Td>
          )}
          {row.cells.map((cell, i) => {
            const key =
              typeof cell.sortKey === 'string' ||
              typeof cell.sortKey === 'number'
                ? cell.sortKey
                : i
            return (
              <Td key={key} style={{ whiteSpace: 'nowrap' }}>
                {cell.renderFn()}
              </Td>
            )
          })}
        </>
      )}
    />
  )
}
