import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ShiftBadge } from '@traba/types'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { ShiftBadgeContent } from 'src/modals/ShiftBadgeModal/ShiftBadgeContent'
import { getErrorMessage } from 'src/utils/errorUtils'

type Props = {
  handleClose: () => void
  isOpen: boolean
  roleId: string
}

export default function CompanyRoleShiftBadgeModal(props: Props) {
  const { handleClose, isOpen, roleId } = props
  const { shiftBadges, refetch } = useCustomBadges({
    roleId,
  })
  const { showSuccess, showError } = useAlert()

  return (
    <Modal
      size={MODAL_SIZE.LARGE}
      handleClose={handleClose}
      isOpen={isOpen}
      style={{ minHeight: `calc(100% - 500px)` }}
      title={'Role customized badges'}
    >
      <ShiftBadgeContent
        title=""
        existingBadgesTitle="Role existing badges"
        addBadgeTitle="Add badge to role"
        existingBadges={shiftBadges}
        description="Badges added here will be applied to all future shifts with this role. If you remove a badge from this role, only future shifts will be affected."
        onAddBadge={async (selectedBadgeToAdd: ShiftBadge) => {
          try {
            if (selectedBadgeToAdd?.id) {
              await trabaApi.patch(
                `shift-badge/${selectedBadgeToAdd?.id}/add`,
                {
                  roleId,
                },
              )
            }
            refetch()
            showSuccess('Successfully added badge to role', 'Success')
          } catch (error) {
            showError(getErrorMessage(error), 'Error adding badge to role')
          }
        }}
        onRemoveBadge={async (selectedExistingBadge: ShiftBadge) => {
          try {
            await trabaApi.patch(
              `shift-badge/${selectedExistingBadge?.id}/remove`,
              { roleId },
            )
            refetch()
            showSuccess('Successfully removed badge from role', 'Success')
          } catch (error) {
            showError('Error removing badge from role')
          }
        }}
      />
    </Modal>
  )
}
