import { TotalEarnings } from '@traba/types'
import { Money } from '@traba/types'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useApi } from './useApi'
import { useHotSettings } from './useHotSettings'

export const useTotalEarnings = (workerId: string) => {
  const { hotSettings } = useHotSettings()
  const isEnabled: boolean =
    workerId !== '' &&
    !!hotSettings?.newEarningsTotalsEnabled &&
    !!hotSettings?.newEarningsScreenCutoffDate

  const {
    isLoading: isLoadingTotalEarnings,
    data: totalEarnings,
    refetch: refetchTotalEarnings,
    isFetched,
  } = useApi<TotalEarnings | undefined>(
    hotSettings?.newEarningsScreenCutoffDate
      ? `workers/${workerId}/total-earnings-v2?cutOffDate=${hotSettings?.newEarningsScreenCutoffDate}`
      : `workers/${workerId}/total-earnings-v2`,
    undefined,
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: isEnabled,
    },
  )

  return {
    isLoadingTotalEarnings,
    isFetched,
    refetchTotalEarnings,
    totalEarnings,
  }
}

export const useOldEarnings = (workerId: string) => {
  const { hotSettings } = useHotSettings()
  const isEnabled: boolean =
    workerId !== '' &&
    !!hotSettings?.newEarningsTotalsEnabled &&
    !!hotSettings?.newEarningsScreenCutoffDate

  const {
    data: oldTotalEarnings,
    isLoading: isLoadingOldEarnings,
    refetch: refetchOldEarnings,
  } = useApi<Money>(
    hotSettings?.newEarningsScreenCutoffDate
      ? `/workers/${workerId}/total-earnings?cutOffDate=${hotSettings?.newEarningsScreenCutoffDate}`
      : `/workers/${workerId}/total-earnings`,
    undefined,
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: isEnabled,
    },
  )

  return {
    oldTotalEarnings,
    isLoadingOldEarnings,
    refetchOldEarnings,
  }
}
