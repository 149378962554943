import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { LocationResponse, RecordStatus } from '@traba/types'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export const COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY =
  'companyShiftsLocationsForUserKey'

interface GetShiftsLocationsParams {
  companyId: string | undefined
  supervisorId: string
  recordStatus?: RecordStatus
  minShiftStartTime?: string
}

const getCompanyShiftsLocationsForSupervisor = async ({
  companyId,
  ...queryParams
}: GetShiftsLocationsParams): Promise<LocationResponse[] | undefined> => {
  if (!companyId) {
    return undefined
  }

  try {
    const response = await trabaApi.get(
      `/companies/${companyId}/shifts-locations`,
      {
        params: queryParams,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error, {
      tags: { action: `Get Locations For Future Shifts of Company Supervisor` },
      extra: { companyId, supervisorId: queryParams.supervisorId },
    })
    throw error
  }
}

export function useCompanyShiftsLocationsForSupervisor(
  props: GetShiftsLocationsParams,
) {
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched,
    refetch,
  } = useQuery<LocationResponse[] | undefined, Error>({
    queryKey: [
      COMPANY_SHIFTS_LOCATIONS_FOR_USER_QUERY_KEY,
      props.companyId,
      props.supervisorId,
      props.minShiftStartTime,
      props.recordStatus,
    ],
    queryFn: () => getCompanyShiftsLocationsForSupervisor(props),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!props.companyId && !!props.supervisorId,
  })

  return {
    isLoading,
    isError,
    locations,
    error,
    isFetched,
    refetch,
  }
}
