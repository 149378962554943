import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { useQuery } from 'react-query'
import { useInfiniteQuery } from 'react-query'
import { ONE_MINUTE_IN_MS } from 'src/libs/constants'
import {
  MessageLogsFilters,
  WorkerCommunication,
  WorkerInfoForCommunication,
} from '../types/worker-communications'

const DEFAULT_PAGE_SIZE = 5
const WORKER_COMMUNICATIONS_QUERY_KEY = 'worker-communications'
const SHIFT_COMMUNICATIONS_QUERY_KEY = 'shift-communications'

async function getWorkerCommunicationsForWorkerId({
  workerId,
  skip = 0,
  filters,
}: {
  workerId: string
  skip: number
  filters?: { [key: string]: boolean }
}): Promise<{
  data: (WorkerCommunication & WorkerInfoForCommunication)[]
  nextPage?: number
}> {
  try {
    let url = `worker-communications/worker/${workerId}?skip=${skip}&limit=${DEFAULT_PAGE_SIZE}`
    if (filters && filters[MessageLogsFilters.BUGLE_SMS]) {
      url = url.concat('&hasBugleBlastId=true')
    }
    if (filters && filters[MessageLogsFilters.OPEN_PHONE_MESSAGES]) {
      url = url.concat('&provider=OPENPHONE&medium=SMS')
    }
    if (filters && filters[MessageLogsFilters.OPEN_PHONE_CALLS]) {
      url = url.concat('&provider=OPENPHONE&medium=OPENPHONE_CALL')
    }

    const response = await trabaApi.get(url, {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404
      },
    })
    if (response.status === 404) {
      return {
        data: [],
        nextPage: undefined,
      }
    }

    return {
      data: response.data,
      nextPage: skip + DEFAULT_PAGE_SIZE,
    }
  } catch (error) {
    Sentry.captureException(error)
    return {
      data: [],
      nextPage: undefined,
    }
  }
}

async function getWorkerCommunicationsForShiftId(
  shiftId: string,
): Promise<(WorkerCommunication & WorkerInfoForCommunication)[] | undefined> {
  try {
    const response = await trabaApi.get(
      `worker-communications/shift/${shiftId}`,
      {
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 404
        },
      },
    )
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerCommunicationsForShiftId = (shiftId: string) => {
  const {
    isLoading,
    isError,
    data: workerCommunications,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<
    (WorkerCommunication & WorkerInfoForCommunication)[] | undefined
  >(
    [SHIFT_COMMUNICATIONS_QUERY_KEY, { shiftId }],
    () => getWorkerCommunicationsForShiftId(shiftId),
    {
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    workerCommunications,
    refetch,
  }
}

export const useWorkerCommunications = (
  workerId: string,
  filters?: { [key: string]: boolean },
) => {
  const {
    isLoading,
    isError,
    error,
    isFetched,
    data: workerCommunications,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<
    {
      data: (WorkerCommunication & WorkerInfoForCommunication)[]
      nextPage?: number
    },
    Error
  >(
    [WORKER_COMMUNICATIONS_QUERY_KEY, { workerId, filters }],
    ({ pageParam }) =>
      getWorkerCommunicationsForWorkerId({
        workerId,
        filters,
        skip: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) {
          return null
        }

        return lastPage?.nextPage
      },
      staleTime: ONE_MINUTE_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workerCommunications:
      workerCommunications?.pages.flatMap((page) => page.data) ?? [],
    refetch,
    loadMore: fetchNextPage,
    hasNextPage,
  }
}
