import { Button, ButtonVariant, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { truncateString } from '@traba/utils'
import { useState, useMemo, memo } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { Dropdown, DropdownElement } from 'src/components/base/Dropdown'
import { DataTable, DataTableHeader } from 'src/components/base/Table/DataTable'
import {
  useRefreshDrugScreeningOrder,
  useWorkerDrugScreeningOrders,
  useCancelDrugScreeningOrder,
} from 'src/hooks/useDrugScreening'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { DrugScreeningOrderModal } from './DrugScreeningOrderModal'

interface DrugScreeningTabProps {
  workerId: string
  workerName: string
}

const DRUG_SCREENING_ORDER_HEADERS: DataTableHeader[] = [
  {
    key: 'action',
    label: 'Action',
  },
  {
    key: 'createdAt',
    label: 'Created',
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: 'Updated',
    sortable: true,
  },
  {
    key: 'expiresAt',
    label: 'Order Expiry',
    sortable: true,
  },
  {
    key: 'id',
    label: 'Order ID',
  },
  {
    key: 'drugPanelType',
    label: 'Type',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Order Status',
    sortable: true,
  },
  {
    key: 'requestingCompanyName',
    label: 'Company',
    sortable: true,
  },
  {
    key: 'labPassportId',
    label: 'Lab Passport ID',
    sortable: true,
  },
  {
    key: 'applicantPortalUrl',
    label: 'Applicant Portal URL',
  },
]

interface ActionButtonsProps {
  workerId: string
  onOrderNew: () => void
}

const ActionButtons = memo(function ActionButtons({
  onOrderNew,
}: ActionButtonsProps) {
  return (
    <Row
      gap={theme.space.xs}
      style={{ marginBottom: theme.space.xs, justifyContent: 'flex-end' }}
    >
      <Button variant={ButtonVariant.OUTLINED} onClick={onOrderNew}>
        Order New Drug Screen
      </Button>
    </Row>
  )
})

export default function WorkerDrugScreeningOrders({
  workerId,
  workerName,
}: DrugScreeningTabProps) {
  const [showOrderModal, setShowOrderModal] = useState(false)
  const { data: orders, isLoading } = useWorkerDrugScreeningOrders(workerId)
  const { mutate: cancelOrder } = useCancelDrugScreeningOrder()
  const { mutate: refreshOrder } = useRefreshDrugScreeningOrder()

  const getDropdownActions = useMemo(
    () => (orderId: string, status: string) => {
      const cancelPendingOrder: DropdownElement = {
        label: 'Cancel Pending Order',
        handleClick: () => cancelOrder(orderId),
      }

      const refreshOrderAction: DropdownElement = {
        label: 'Refresh Order',
        handleClick: () => refreshOrder(orderId),
      }

      return status === 'PENDING'
        ? [cancelPendingOrder, refreshOrderAction]
        : [refreshOrderAction]
    },
    [cancelOrder, refreshOrder],
  )

  const tableRows = useMemo(
    () =>
      orders?.map((drugScreen) => {
        const {
          id,
          drugPanelType,
          status,
          createdAt,
          expiresAt,
          requestingCompanyName,
          labPassportId,
          applicantPortalUrl,
          updatedAt,
        } = drugScreen

        return {
          key: id,
          cells: [
            {
              renderFn: () => (
                <Dropdown
                  dropdownOptions={getDropdownActions(id, status)}
                  orientation={'right'}
                  verticalPosition={'top'}
                  buttonVariant={ButtonVariant.OUTLINED}
                  buttonStyling={{
                    padding: `${theme.space.xsmed}px -${theme.space.xsmed}px ${theme.space.xms}px`,
                    marginBottom: -theme.space.xxs,
                    marginTop: -theme.space.xxs,
                  }}
                />
              ),
              sortKey: 'action',
            },
            {
              renderFn: () => formatDateTimeWithTimezone(createdAt),
              sortKey: new Date(createdAt).getTime(),
            },
            {
              renderFn: () => formatDateTimeWithTimezone(updatedAt),
              sortKey: new Date(updatedAt).getTime(),
            },
            {
              renderFn: () => formatDateTimeWithTimezone(expiresAt),
              sortKey: new Date(expiresAt ?? '').getTime(),
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    {truncateString(id ?? '')}
                    <CopyTextIcon textToCopy={id} />
                  </Row>
                )
              },
              sortKey: id,
            },
            {
              renderFn: () => drugPanelType,
              sortKey: drugPanelType,
            },
            {
              renderFn: () => status,
              sortKey: status,
            },
            {
              renderFn: () => requestingCompanyName,
              sortKey: requestingCompanyName,
            },
            {
              renderFn: () => labPassportId ?? 'WAIVER_NOT_SIGNED',
              sortKey: labPassportId,
            },
            {
              renderFn: () => {
                return (
                  <Row style={{ width: 80 }} alignCenter>
                    <CopyTextIcon textToCopy={applicantPortalUrl} />
                  </Row>
                )
              },
              sortKey: applicantPortalUrl,
            },
          ],
        }
      }) ?? [],
    [orders, getDropdownActions],
  )

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  return (
    <>
      <ActionButtons
        workerId={workerId}
        onOrderNew={() => setShowOrderModal(true)}
      />
      <DataTable
        rows={tableRows}
        headers={DRUG_SCREENING_ORDER_HEADERS}
        initialSortByColumnIndex={2}
        initialSortByAscOrder={false}
      />
      <DrugScreeningOrderModal
        showModal={showOrderModal}
        onCloseModal={() => setShowOrderModal(false)}
        workerId={workerId}
        workerName={workerName}
      />
    </>
  )
}
