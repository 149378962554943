import {
  UserProfile,
  UserRole,
  UserRolePermission,
} from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'

export function hasPermissions(
  user: UserProfile | undefined,
  requiredPermissions: UserRolePermission[],
): boolean {
  // Until Internal Ops have role based Access Control, no need to check permissions
  if (user?.role === UserRole.Internal) {
    return true
  }
  // When true RBAC is implemented, we will pull store permissions in userProfile
  // Could simply this to return false until that point (ie external user)
  const userPermissions: UserRolePermission[] = []
  return requiredPermissions.every((p) => userPermissions.includes(p))
}

export function usePermissions(requiredPermissions: UserRolePermission[]) {
  const userContext = useUserContext()

  return hasPermissions(userContext.state.userProfile, requiredPermissions)
}
