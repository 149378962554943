import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import * as React from 'react'
import { IconButton, Row, Button } from '../base'
import { ButtonVariant } from '../base/Button/types'

export interface ConfirmationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  content: string | React.ReactNode
  confirmButtonText?: string
  confirmButtonVariant?: ButtonVariant
  confirmLoading?: boolean
  cancelButtonText?: string
  secondaryConfirmButton?: boolean
  secondaryConfirmButtonText?: string
  secondaryConfirmButtonAction?: () => void
  disabled?: boolean
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    open,
    onClose,
    onConfirm,
    title,
    content,
    confirmButtonText,
    confirmButtonVariant,
    confirmLoading,
    cancelButtonText,
    secondaryConfirmButton,
    secondaryConfirmButtonText,
    secondaryConfirmButtonAction,
    disabled,
  } = props

  const handleClose = () => {
    onClose()
  }

  const renderSecondaryConfirmButton =
    secondaryConfirmButton &&
    secondaryConfirmButtonText &&
    secondaryConfirmButtonAction

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Row fullWidth justifyBetween>
          <DialogTitle id="alert-dialog-title">
            <Text variant="h4">{title}</Text>
          </DialogTitle>
          <IconButton
            iconName="cancel"
            transparent
            style={{ padding: `${theme.space.xxs}px ${theme.space.sm}px` }}
            onClick={handleClose}
          />
        </Row>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Row fullWidth justifyBetween>
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {cancelButtonText || 'Cancel'}
            </Button>
            <Row>
              {renderSecondaryConfirmButton && (
                <Button
                  style={{ marginRight: theme.space.xxs }}
                  variant={ButtonVariant.PURPLEGRADIENT}
                  onClick={() => {
                    secondaryConfirmButtonAction()
                    handleClose()
                  }}
                  disabled={disabled}
                >
                  {secondaryConfirmButtonText}
                </Button>
              )}
              <Button
                color="primary"
                variant={confirmButtonVariant}
                onClick={() => {
                  onConfirm()
                  handleClose()
                }}
                autoFocus
                loading={confirmLoading}
                disabled={disabled}
              >
                {confirmButtonText || 'Confirm'}
              </Button>
            </Row>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  )
}
