export enum ActionableRequirement {
  W9_FORM = 'W9_FORM',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum ActionType {
  BLOCKED_ALL = 'BLOCKED_ALL',
}

export enum ActionLiftedReason {
  WORKER_COMPLETED = 'WORKER_COMPLETED',
  OPS_LIFTED = 'OPS_LIFTED',
  EXPIRED = 'EXPIRED',
}

export interface WorkerRequirementAction {
  workerId: string
  requirement: ActionableRequirement
  actionType: ActionType
  isActive: boolean
  actionLiftedReason?: ActionLiftedReason
}
