import { IncentiveStatus } from '@traba/types'
import Badge from '.'

export type IncentiveStatusBadgeProps = {
  incentiveStatus: IncentiveStatus
  style?: React.CSSProperties
}

const incentiveStatusToBadgeVariantDict: Record<IncentiveStatus, string> = {
  [IncentiveStatus.Active]: 'success',
  [IncentiveStatus.Expired]: 'danger',
  [IncentiveStatus.Inactive]: 'opaqueRed',
}

export default function ShiftStatusBadge({
  incentiveStatus,
  ...props
}: IncentiveStatusBadgeProps) {
  return (
    <Badge
      {...props}
      title={incentiveStatus?.replaceAll('_', ' ')}
      variant={incentiveStatusToBadgeVariantDict[incentiveStatus]}
    />
  )
}
