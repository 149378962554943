import { fromDate, ZonedDateTime } from '@internationalized/date'
import { AriaTimeFieldProps, useTimeField } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useTimeFieldState } from '@react-stately/datepicker'
import { TimeValue } from '@react-types/datepicker'
import { theme } from '@traba/theme'
import { useRef } from 'react'
import styled from 'styled-components'

import { DateSegment } from './DateField'

const StyledTimeFieldDiv = styled.div`
  display: inline-flex;
  border: 1px solid ${theme.colors.Grey30};
  border-radius: 5px;
  .group:hover & {
    border-color: ${theme.colors.Grey50};
  }
  .group:focus-within & {
    border-color: ${theme.colors.Violet70};
  }
  padding-left: ${theme.space.xxxs}px;
  padding-right: ${theme.space.xxs}px;
  width: min-content;
`

interface TimeFieldProps extends AriaTimeFieldProps<TimeValue> {
  time?: Date | null
  timezone?: string
  setTime?: (newTime: Date | null) => void
  minTime?: Date
  maxTime?: Date
  disabled?: boolean
}

export default function TimeField(props: TimeFieldProps) {
  const { locale } = useLocale()

  const handleDateChange = (date: TimeValue) => {
    if (props.setTime !== undefined && !props.disabled) {
      if (date instanceof ZonedDateTime) {
        props.setTime(date?.toDate() || null)
      } else {
        props.setTime(null)
      }
    }
  }

  const modifiedProps = {
    ...props,
    value:
      props.value !== undefined
        ? props.value
        : props.time !== undefined && props.timezone
          ? props.time !== null
            ? fromDate(props.time, props.timezone)
            : null
          : undefined,
    ...(props.setTime !== undefined ? { onChange: handleDateChange } : {}),
    minValue:
      !!props.minTime && props.timezone
        ? fromDate(props.minTime, props.timezone)
        : undefined,
    maxValue:
      !!props.maxTime && props.timezone
        ? fromDate(props.maxTime, props.timezone)
        : undefined,
  }
  const state = useTimeFieldState({
    ...modifiedProps,
    locale,
  })

  const ref = useRef(null)
  const { labelProps, fieldProps } = useTimeField(props, state, ref)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="group">
      <label {...labelProps}>{props.label}</label>
      <StyledTimeFieldDiv {...fieldProps} ref={ref}>
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </StyledTimeFieldDiv>
    </div>
  )
}
