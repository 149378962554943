export default function Phone({
  color = '#7A8A99',
  size = 24,
}: {
  size: number
  color: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.11021 12.9223C9.6979 13.0259 10.3021 13.0259 10.8898 12.9223C11.8344 12.7559 12.5952 12.1217 12.8573 11.2824L12.9129 11.1044C12.9707 10.9193 13 10.7279 13 10.5356C13 9.68751 12.2416 9 11.306 9H8.69404C7.75845 9 7 9.68751 7 10.5356C7 10.7279 7.02931 10.9193 7.08709 11.1044L7.14268 11.2824C7.40475 12.1217 8.16561 12.7559 9.11021 12.9223ZM9.11021 12.9223C5.02797 12.1659 1.83405 8.97203 1.07767 4.88979M1.07767 4.88979C0.97411 4.3021 0.974111 3.6979 1.07767 3.11021C1.24412 2.16561 1.8783 1.40475 2.71761 1.14268L2.89563 1.08709C3.08069 1.02931 3.27208 1 3.46441 1C4.31249 1 5.00001 1.75845 5 2.69404L5 5.30596C5 6.24155 4.31249 7 3.4644 7C3.27208 7 3.08068 6.97069 2.89563 6.91291L2.71761 6.85732C1.87829 6.59525 1.24412 5.83439 1.07767 4.88979Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}
