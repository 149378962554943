import { theme } from '@traba/theme'
import { Modal, ModalProps } from '../Modal/Modal'
import Row from '../Row'

export interface ImagesModalProps extends Omit<ModalProps, 'children'> {
  imageUrls?: string[]
}

export default function ImagesModal({ imageUrls, ...props }: ImagesModalProps) {
  return (
    <Modal {...props}>
      {imageUrls?.map((imageUrl) => (
        <Row fullWidth justifyCenter mb={theme.space.xxs} key={imageUrl}>
          <img
            style={{
              borderRadius: theme.shape.borderRadius,
              maxHeight: '100%',
              maxWidth: '100%',
            }}
            src={imageUrl}
            alt=""
          />
        </Row>
      ))}
    </Modal>
  )
}
