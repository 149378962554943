import { ShiftTag } from '@traba/types'
import { IMenuItem } from 'src/components/base/Select/Select'

export const useShiftTags = () => {
  const shiftTagArray = Object.values(ShiftTag) as string[]

  const shiftTagMenuItems: IMenuItem[] = shiftTagArray.map((tag) => {
    return { label: tag, value: tag }
  })

  return {
    shiftTagArray,
    shiftTagMenuItems,
  }
}
