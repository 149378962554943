import * as Sentry from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { TrainingStatus, WorkerTrainingStatus } from '@traba/types'
import { useQuery } from 'react-query'

async function getWorkerTrainingStatusByWorkerId(
  workerId: string,
): Promise<WorkerTrainingStatus[] | undefined> {
  try {
    const res = await trabaApi.get(`worker-training-status/worker/${workerId}`)
    return res.data
  } catch (error) {
    Sentry.captureException(
      `useWorker -> getWorkerTrainingStatusByWorkerId() ${error}`,
    )
  }
}

export function useWorkerTrainings(workerId: string) {
  const {
    isLoading: isLoadingWorkerTrainingStatus,
    isError: isErrorWorkerTrainingStatus,
    data: workerTrainingStatus,
    error: errorWorkerTrainingStatus,
    isFetched: isFetchedWorkerTrainingStatus,
  } = useQuery<WorkerTrainingStatus[] | undefined, Error>(
    ['workerTrainingStatusByWorkerId', workerId],
    () => getWorkerTrainingStatusByWorkerId(workerId),
  )
  const completedTrainings = workerTrainingStatus?.filter(
    (training) => training.status === TrainingStatus.COMPLETED,
  )

  return {
    isLoading: isLoadingWorkerTrainingStatus,
    isError: isErrorWorkerTrainingStatus,
    workerTrainingStatus,
    completedTrainings,
    errorWorkerTrainingStatus,
    isFetchedWorkerTrainingStatus,
  }
}
