import { Text } from '@traba/react-components'
import { CopyTextIcon } from 'src/components/base'
import { truncateString } from 'src/utils/stringUtils'

export const EnvironmentCopyLink = ({
  subdirectory,
  meticulousIgnore,
}: {
  subdirectory: string
  meticulousIgnore?: boolean
}) => {
  const url = `${window.location.origin}/${subdirectory}`
  return (
    <>
      <Text
        variant="body1"
        className={meticulousIgnore ? 'meticulous-ignore' : undefined}
      >
        {truncateString(url, 20)}
      </Text>
      <CopyTextIcon textToCopy={url} />
    </>
  )
}
