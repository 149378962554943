import { CompanyCategoryValues, RoleAttribute } from '@traba/types'
import { Field } from 'src/hooks/useSearchInputItems'

export const sortByTypeAndLabel = (a: Field, b: Field) => {
  const typeOrder = ['input', 'select', 'date', 'checkbox']
  if (a.order !== undefined || b.order !== undefined) {
    if (a.order === undefined) {
      return 1
    }
    if (b.order === undefined) {
      return -1
    }
    return a.order - b.order
  }
  if (a.type !== b.type) {
    return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type)
  } else {
    return a.label.localeCompare(b.label)
  }
}
export const buildInputsList = (
  selectedFields: string[],
  allInputFields: Field[],
  roleAttributes?: RoleAttribute[],
) => {
  const orderedFields: Field[] = allInputFields.filter(
    (f) => f.order !== undefined,
  )
  let unorderedFields: Field[] = allInputFields.filter(
    (f) => f.order === undefined && selectedFields.includes(f.field),
  )

  if (roleAttributes && selectedFields.includes('workerProfileFields')) {
    const roleAttributesTypes = roleAttributes
      .filter((a) => !a.allowOpsLeveling)
      .map((a) => ({
        value: a.type,
        label: a.displayName,
      }))
    roleAttributesTypes.sort((a, b) => a.label.localeCompare(b.label))
    unorderedFields = unorderedFields.map((f) => {
      if (f.field === 'workerProfileFields') {
        return {
          ...f,
          items: roleAttributesTypes,
        }
      }
      return f
    })
  }
  if (selectedFields.includes('industries')) {
    const sortedIndustries = Object.keys(CompanyCategoryValues).sort((a, b) =>
      CompanyCategoryValues[
        a as keyof typeof CompanyCategoryValues
      ].localeCompare(
        CompanyCategoryValues[b as keyof typeof CompanyCategoryValues],
      ),
    )
    const industries = sortedIndustries.map((c) => ({
      value: c,
      label: CompanyCategoryValues[c as keyof typeof CompanyCategoryValues],
    }))
    unorderedFields = unorderedFields.map((f) => {
      if (f.field === 'industries') {
        return {
          ...f,
          items: industries,
        }
      }
      return f
    })
  }
  const sortedOrderedFields = orderedFields.sort(sortByTypeAndLabel)
  const sortedUnorderedFields = unorderedFields.sort(sortByTypeAndLabel)

  return [...sortedOrderedFields, ...sortedUnorderedFields]
}
