import { IMenuItem } from 'src/components/base/Select/Select'

export const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const INVALID_EMAIL_MESSAGE = 'Invalid email'
export const INVALID_PHONE_MESSAGE = 'Invalid phone number'

export const validateEmail = (str: string) => {
  const re = /\S+@\S+\.\S+/
  if (str && !re.test(str)) {
    throw new Error('Invalid email')
  }
  return str
}

export const validateUniqueEmails = (emails: string[]) =>
  Array.from(
    new Set(
      emails.filter((e) => {
        try {
          validateEmail(e)
          return true
        } catch (e) {
          return false
        }
      }),
    ),
  )

export const getEnumOptions = <T>(enumObject: T): IMenuItem[] => {
  return Object.entries(enumObject as Record<string, string | number>).map(
    ([key, value]) => ({
      value: key,
      label: typeof value === 'number' ? value.toString() : value,
    }),
  )
}
