import { Badge, Button, Icon, Row } from 'src/components/base'

import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { getBadgeDetails } from 'src/screens/ToolsScreen/components/CustomizedBadge/BadgePreview'
import CompanyRoleShiftBadgeModal from './CompanyRoleShiftBadgeModal'

interface Props {
  roleId: string
}

export default function CompanyRoleBadges({ roleId }: Props) {
  const { shiftBadges } = useCustomBadges({ roleId })
  const badgeModal = useModal()
  return (
    <Row>
      {shiftBadges?.map((badge) => {
        const { variant, badgeName, id } = badge
        const { badgeStyleVariant } = getBadgeDetails(variant)
        return <Badge key={id} variant={badgeStyleVariant} title={badgeName} />
      })}

      <Button
        style={{ padding: 0, marginLeft: '5px' }}
        variant={ButtonVariant.TRANSPARENT}
        onClick={() => badgeModal.open()}
      >
        <Icon name="edit_active" width={18} />
      </Button>
      <CompanyRoleShiftBadgeModal {...badgeModal} roleId={roleId} />
    </Row>
  )
}
