import { CircularProgress, IconButton } from '@mui/material'
import { useAlert } from '@traba/context'
import { RadioButton, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { KioskModeLevel, KioskModeType } from '@traba/types'
import { Company } from '@traba/types'
import { SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Icon, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useKioskLocationsMutation } from 'src/hooks/useKioskLocations'
import { useKioskPin } from 'src/hooks/useKioskPin'
import { getErrorMessage } from 'src/utils/errorUtils'
import { kioskModeTypeOptions } from 'src/utils/shiftFormUtils'

type KioskModeSettingsSectionProps = {
  company: Company
  handleCompanySaveButton(
    values: Partial<Company>,
    setLoadingState: (value: SetStateAction<boolean>) => void,
  ): Promise<void>
}

export default function KioskModeSettingsSection({
  company,
  handleCompanySaveButton,
}: KioskModeSettingsSectionProps) {
  const { hotSettings } = useHotSettings()
  const { showSuccess, showError } = useAlert()
  const {
    kioskPin: fetchedKioskPin,
    isLoading: isLoadingKioskPin,
    updateKioskPin,
  } = useKioskPin(company.companyId)
  const { mutateAsync: updateKioskLocationsKioskType } =
    useKioskLocationsMutation(company.companyId)

  const [kioskPin, setKioskPin] = useState<number>()
  const [kioskModeType, setKioskModeType] = useState<KioskModeType>(
    company.kioskModeType ?? KioskModeType.None,
  )
  const [isSavingKioskPin, setIsSavingKioskPin] = useState<boolean>(false)
  const [isEditingKioskPin, setIsEditingKioskPin] = useState<boolean>(false)
  const [kioskModeLevel, setKioskModeLevel] = useState<
    KioskModeLevel | undefined
  >(company.kioskModeLevel)
  const [resetAllLocations, setResetAllLocations] = useState<boolean>(false)

  async function handleKioskSectionSave() {
    if (isEditingKioskPin && kioskPin && validateKioskPin(kioskPin)) {
      await handleKioskPinUpdate(kioskPin)
    }

    if (
      (kioskModeType !== company.kioskModeType &&
        kioskModeLevel === KioskModeLevel.COMPANY) ||
      kioskModeLevel !== company.kioskModeLevel
    ) {
      await handleCompanySaveButton(
        {
          ...(kioskModeType !== company.kioskModeType && { kioskModeType }),
          ...(kioskModeLevel !== company.kioskModeLevel && { kioskModeLevel }),
        },
        setIsSavingKioskPin,
      )
    }

    if (
      (kioskModeLevel === KioskModeLevel.COMPANY &&
        company.kioskModeType !== kioskModeType) ||
      (kioskModeLevel === KioskModeLevel.LOCATION && resetAllLocations)
    ) {
      await updateKioskLocationsKioskType(kioskModeType)
    }

    setResetAllLocations(false)
    showSuccess('Successfully updated kiosk mode settings', 'Success')
  }

  useEffect(() => {
    if (fetchedKioskPin) {
      setKioskPin(parseInt(fetchedKioskPin))
    }
  }, [fetchedKioskPin])

  const validateKioskPin = (pin: number | undefined) => {
    if (fetchedKioskPin && pin !== parseInt(fetchedKioskPin)) {
      return pin && pin >= 1000 && pin <= 9999
    }
    return false
  }

  const hasChanges =
    kioskModeType !== company.kioskModeType ||
    kioskModeLevel !== company.kioskModeLevel ||
    (isEditingKioskPin && validateKioskPin(kioskPin)) ||
    (resetAllLocations && kioskModeLevel === KioskModeLevel.LOCATION)

  const resetIsEditingKioskPin = () => {
    fetchedKioskPin && setKioskPin(parseInt(fetchedKioskPin))
    setIsEditingKioskPin(false)
  }

  async function handleKioskPinUpdate(kioskPin: number) {
    setIsSavingKioskPin(true)
    setKioskPin(kioskPin)
    try {
      await updateKioskPin({
        companyId: company.companyId,
        kioskPin: kioskPin.toString(),
      })
      showSuccess(`Successfully updated kiosk pin to ${kioskPin}`, 'Success')
      setIsEditingKioskPin(false)
    } catch (err) {
      showError(getErrorMessage(err), 'Error updating company kiosk pin')
    }
    setIsSavingKioskPin(false)
  }

  async function handleKioskModeTypeSelect(selectedKioskModeType: string) {
    setKioskModeType(selectedKioskModeType as KioskModeType)
  }

  return (
    <>
      <Text variant="h4" mt={theme.space.sm}>
        Kiosk Mode Settings
      </Text>
      {hotSettings?.locationBasedKioskEnabled && (
        <>
          <Row mt={theme.space.xs} mb={theme.space.sm}>
            <Row onClick={() => setKioskModeLevel(KioskModeLevel.COMPANY)}>
              <RadioButton
                selected={kioskModeLevel === KioskModeLevel.COMPANY}
                size={theme.space.sm}
              />
              <Col ml={theme.space.xxs}>
                <Text variant="body1">Set at the company level</Text>
              </Col>
            </Row>
            <Row
              onClick={() => setKioskModeLevel(KioskModeLevel.LOCATION)}
              ml={theme.space.med}
            >
              <RadioButton
                selected={kioskModeLevel === KioskModeLevel.LOCATION}
                size={theme.space.sm}
              />
              <Col ml={theme.space.xxs}>
                <Text variant="body1">
                  Set at the location level (in location settings)
                </Text>
              </Col>
            </Row>
          </Row>
          {kioskModeLevel === KioskModeLevel.LOCATION && (
            <Checkbox
              checked={resetAllLocations}
              label={
                'Reset all locations to have these settings (you can still go in and edit individual locations)'
              }
              textvariant="h6"
              labelStyle={{
                color: theme.colors.MidnightBlue,
                fontWeight: 'bold',
              }}
              style={{ marginTop: theme.space.xs }}
              onChange={() =>
                setResetAllLocations(
                  (prevResetAllLocations) => !prevResetAllLocations,
                )
              }
            />
          )}
        </>
      )}
      <Row
        alignCenter
        gap={theme.space.xs}
        mt={theme.space.sm}
        mb={theme.space.sm}
      >
        <Col>
          <Text variant="caption">Kiosk Mode Type</Text>
          <Select
            containerStyle={{
              margin: `${theme.space.xs}px 0px`,
              width: '220px',
            }}
            dropdownStyle={{ height: '48px' }}
            label={'Type'}
            menuItems={kioskModeTypeOptions}
            handleSelect={handleKioskModeTypeSelect}
            value={kioskModeType}
            disabled={
              kioskModeLevel === KioskModeLevel.LOCATION && !resetAllLocations
            }
          />
        </Col>
        <Col>
          <Text variant="h6" mb={theme.space.xxs}>
            Kiosk Pin
          </Text>
          <Row gap={theme.space.xs}>
            {isLoadingKioskPin ? (
              <CircularProgress size={theme.space.sm} />
            ) : isEditingKioskPin ? (
              <>
                <NumberInput
                  width={'80px'}
                  value={kioskPin}
                  setValue={setKioskPin}
                  placeholder="1234"
                  min={1000}
                  max={9999}
                  step={1}
                />
                <IconButton
                  aria-label="cancel"
                  onClick={() => resetIsEditingKioskPin()}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <Icon name="cancel" />
                </IconButton>
              </>
            ) : (
              <>
                <Text variant="body1">{kioskPin}</Text>
                <Button
                  style={{
                    display: 'inline',
                    padding: 0,
                    marginLeft: `${theme.space.xxxs}px`,
                  }}
                  variant={ButtonVariant.TRANSPARENT}
                  onClick={() => setIsEditingKioskPin(true)}
                >
                  <Icon name="edit_active" width={theme.space.xs} />
                </Button>
              </>
            )}
          </Row>
        </Col>
        <Row>
          <Button
            ml={theme.space.xs}
            loading={isSavingKioskPin}
            disabled={!hasChanges}
            onClick={handleKioskSectionSave}
          >
            Save
          </Button>
          <Button
            variant={ButtonVariant.OUTLINED}
            ml={theme.space.xs}
            mr={theme.space.xs}
            loading={isSavingKioskPin}
            onClick={resetIsEditingKioskPin}
          >
            Cancel
          </Button>
        </Row>
      </Row>
    </>
  )
}
