import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'

interface Props {
  checked: boolean
  label: string
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

export const BypassCheckboxItem = (props: Props) => {
  const { checked, onChange, label } = props
  return (
    <Row mb={theme.space.xxs}>
      <Checkbox checked={checked} label={label} onChange={onChange} />
    </Row>
  )
}
