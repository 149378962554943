import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaymentLog, TrabaEntityType } from '@traba/types'
import { formatInTimeZone } from 'date-fns-tz'
import { Button, Col, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { getTitleForPaymentLogType } from 'src/utils/paymentHistoryUtils'
import { getMoneyString, truncateString } from 'src/utils/stringUtils'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  &:hover {
    background-color: ${theme.colors.Grey10};
  }
`

export const PaymentLogRow = ({
  paymentLog,
  timezone,
}: {
  paymentLog: PaymentLog
  timezone: string
}) => {
  const workerShift = paymentLog.workerShift
  const workerIncentive = paymentLog.workerIncentive
  return (
    <>
      <StyledRow py={theme.space.xs} justifyBetween px={theme.space.xs}>
        <Row gap={theme.space.med}>
          <Col width={200}>
            <Text variant="h7">
              {getTitleForPaymentLogType(
                paymentLog.eventType,
                paymentLog.trabaEntityType === TrabaEntityType.WORKER_SHIFT,
                paymentLog.amount,
                paymentLog.reason,
              )}
            </Text>
            {paymentLog.payoutFailedReason && (
              <Text mr={theme.space.sm}>{paymentLog.payoutFailedReason}</Text>
            )}
          </Col>
          <Col width={100}>
            <Text
              variant={paymentLog.amount > 0 ? 'h7' : 'error'}
              mr={theme.space.sm}
            >
              {getMoneyString(paymentLog.amount / 100)}
            </Text>
          </Col>
        </Row>
        <Col>
          {workerShift && (
            <Col>
              <Link
                to={`/field-monitor/${workerShift?.shiftId}`}
                target="_blank"
              >
                <Button variant={ButtonVariant.TEXT} style={{ padding: 0 }}>
                  {workerShift?.shiftRole} at {workerShift?.employerName}
                </Button>
              </Link>
              <Row>
                <Text variant="h7" mr={theme.space.xxs}>
                  {`Shift id: ${truncateString(workerShift.shiftId)}`}
                </Text>
                <CopyTextIcon textToCopy={workerShift.shiftId} />
              </Row>
            </Col>
          )}
          {workerIncentive && paymentLog.workerIncentiveId && (
            <Row>
              <Text variant="h7" mr={theme.space.xxs}>
                {`Worker Incentive: ${truncateString(
                  paymentLog.workerIncentiveId,
                )}`}
              </Text>
              <CopyTextIcon textToCopy={paymentLog.workerIncentiveId} />
            </Row>
          )}
        </Col>
        <Row>
          <Text>
            {formatInTimeZone(
              paymentLog.createdAt,
              timezone,
              'MM/dd/yyyy hh:mm:ss aa zzz',
            )}
          </Text>
        </Row>
      </StyledRow>
      <Divider />
    </>
  )
}
