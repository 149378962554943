import { Checkbox, Tooltip } from '@mui/material'
import {
  Row,
  Col,
  SvgIcon,
  Button,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { PendingClockOutsResponse } from '@traba/types'
import { useCallback, useState } from 'react'
import PendingClockOutItemHeaderInfo from './PendingClockOutItemHeaderInfo'
import { PendingClockOutsItemTable } from './PendingClockOutsItemTable'
import { CollapseButton, PendingClockOutsItemWrapper } from './styles'

interface PendingClockOutItemProps {
  pendingClockOutSummary: PendingClockOutsResponse
  isLoading?: boolean
  isSelected?: boolean
  onSelect?: () => void
  onAdjustShiftClick: () => void
  onCompleteShiftClick: () => void
  disableSelectMore?: boolean
  disableSelectMoreText?: string
}

export function PendingClockOutItem({
  pendingClockOutSummary,
  isLoading,
  isSelected,
  onSelect,
  onAdjustShiftClick,
  onCompleteShiftClick,
  disableSelectMore,
  disableSelectMoreText,
}: PendingClockOutItemProps) {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const { workerShift } = pendingClockOutSummary
  const { id: workerShiftId } = workerShift

  const handleCollapseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      setCollapsed((prevCollapse) => !prevCollapse)
    },
    [],
  )

  const handleCompleteShift = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      onCompleteShiftClick()
    },
    [onCompleteShiftClick],
  )

  return (
    <PendingClockOutsItemWrapper>
      <label htmlFor={`checkbox-${workerShiftId}`}>
        <Row
          fullWidth
          alignCenter
          justifyBetween
          style={onSelect ? { cursor: 'pointer' } : undefined}
        >
          <Row gap={theme.space.xxs}>
            {onSelect && (
              <Tooltip
                title={
                  disableSelectMore && !isSelected
                    ? disableSelectMoreText
                    : undefined
                }
              >
                <div>
                  <Checkbox
                    id={`checkbox-${workerShiftId}`}
                    onChange={onSelect}
                    checked={isSelected}
                    disabled={disableSelectMore && !isSelected}
                  />
                </div>
              </Tooltip>
            )}

            <PendingClockOutItemHeaderInfo
              pendingClockOutSummary={pendingClockOutSummary}
            />
          </Row>

          <Row gap={theme.space.xs} justifyEnd>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={onAdjustShiftClick}
            >
              Update Shift
            </Button>

            <Button loading={isLoading} onClick={handleCompleteShift}>
              Complete Shift
            </Button>

            <CollapseButton onClick={handleCollapseClick}>
              <SvgIcon
                name={collapsed ? 'chevronDown' : 'chevronUp'}
                size={12}
                color={theme.colors.Grey50}
              />
            </CollapseButton>
          </Row>
        </Row>
      </label>

      {!collapsed && (
        <Col mt={theme.space.sm}>
          <PendingClockOutsItemTable
            workerShiftId={workerShiftId}
            pendingClockOutsWorkerShift={pendingClockOutSummary}
          />
        </Col>
      )}
    </PendingClockOutsItemWrapper>
  )
}
