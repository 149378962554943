export type InternalUser = {
  id: string
  createdAt: Date
  updatedAt: Date
  email: string
  firstName: string
  lastName: string
  role: InternalUserRole
  status: InternalUserStatus
  slackId?: string
  rank: InternalUserRank
  phoneNumber?: string
}

export enum InternalUserRole {
  OPS = 'OPS',
  ENG = 'ENG',
  SALES = 'SALES',
  EXTERNAL = 'EXTERNAL',
  MARKET_OPS = 'MARKET_OPS',
  SCALED_OPS = 'SCALED_OPS',
}

export enum InternalUserStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum InternalUserRank {
  ADMIN = 'ADMIN',
  OPS_ADMIN = 'OPS_ADMIN',
  OPS_LEAD = 'OPS_LEAD',
  SUPERVISOR = 'SUPERVISOR',
  USER = 'USER',
}
