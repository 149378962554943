import { BackgroundCheck } from '@traba/types'
import { Worker } from '@traba/types'

export function sortByWorkerName(w1: Worker, w2: Worker) {
  const firstNameCompare = w1.firstName.localeCompare(w2.firstName)
  return firstNameCompare !== 0
    ? firstNameCompare
    : w1.lastName.localeCompare(w2.lastName)
}

export function getCheckrReportUrl(backgroundCheck: BackgroundCheck) {
  return `https://dashboard.checkr.com/candidates/${
    backgroundCheck.candidateId
  }/reports/${backgroundCheck.reportId}${
    import.meta.env.NODE_ENV !== 'production' ? '?test=true' : ''
  }`
}

export function getStripeUrl(worker: Worker) {
  return `${
    import.meta.env.VITE_STRIPE_URL || 'https://dashboard.stripe.com/test'
  }/connect/accounts/${worker.stripeAccountId}/activity`
}

export function getRetoolUrl(worker: Worker) {
  return `https://traba.retool.com/apps/5b7d54ea-b120-11ec-a560-a355ea6c44fd/Profile%20Information?=#workerUid=${
    worker.uid || worker.workerId || worker.id
  }`
}

export function getAccountStatusColor(status: string) {
  switch (status) {
    case 'UNAPPROVED':
      return 'blue'
    case 'APPROVED':
      return 'green'
    case 'BANNED':
      return 'red'
    case 'SUSPENDED':
      return 'orange'
    default:
      return 'grey'
  }
}
