import { Row, Text } from '@traba/react-components'

export function DetailRow({
  label,
  value,
  editing,
  showLabelOnEdit,
  children,
}: {
  label: string
  value: string
  editing: boolean
  showLabelOnEdit?: boolean
  children?: React.ReactNode
}) {
  const showLabel = !editing || showLabelOnEdit

  return (
    <Row alignCenter>
      {showLabel && (
        <Text variant="h6" style={{ width: '200px' }}>
          {label}
        </Text>
      )}
      {editing ? children : <Text variant="body1">{value}</Text>}
    </Row>
  )
}
