import { Col } from '@traba/react-components'
import { EarningsSummaryResponse } from 'src/hooks/useEarningsSummary'
import useTimezonedDates from 'src/hooks/useTimezonedDates'

import { formatDuration, getTotalPaidTimeInMinutes } from 'src/utils/dateUtils'
import { getTotalBreakTime } from 'src/utils/moneyUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import { DataTable, DataTableHeader, TableCell } from '../base/Table/DataTable'

type PendingClockOutsItemTableProps = {
  earningSummaryWorkerShift: EarningsSummaryResponse
}

export function WorkerShiftEditedTimesTable({
  earningSummaryWorkerShift,
}: PendingClockOutsItemTableProps) {
  const {
    clockInTime: clockInDateString,
    clockOutTime: clockOutTimeDatString,
    clockOutTimeBeforeWorkerEdit: clockOutTimeBeforeWorkerEditDateString,
    shift,
    breaksBeforeWorkerEdit,
    breaks,
    timeWorked,
    netPayAmount,
  } = earningSummaryWorkerShift.workerShift

  const shiftStartTime = new Date(shift.startTime)
  const shiftEndTime = new Date(shift.endTime)

  const clockInTime = clockInDateString
    ? new Date(clockInDateString)
    : undefined
  const clockOutTime = clockOutTimeDatString
    ? new Date(clockOutTimeDatString)
    : undefined
  const clockOutTimeBeforeWorkerEdit = clockOutTimeBeforeWorkerEditDateString
    ? new Date(clockOutTimeBeforeWorkerEditDateString)
    : undefined
  const { getShiftTime, getTime } = useTimezonedDates(shift.timezone)

  const firstRowHeaders: DataTableHeader[] = [
    { key: 'shiftTime', label: 'Shift Time' },
    { key: 'clockInTime', label: 'Clock In Time' },
    { key: 'clockOutTimeBeforeWorkerEdit', label: 'Clock Out Time' },
    { key: 'breakTimeBeforeWorkerEdit', label: 'Break Time' },
    { key: 'hoursWorked', label: 'Hrs Worked' },
    { key: 'placeholderForNetPay', label: '' },
  ]
  const secondRowHeaders: DataTableHeader[] = [
    { key: 'emptyCol1', label: '' },
    { key: 'emptyCol2', label: '' },
    {
      key: 'clockOutTime',
      label: 'Worker edited clock out time',
    },
    { key: 'breakTime', label: 'Worker edited break time ' },
    { key: 'hoursWorked', label: 'Hrs Worked' },
    { key: 'netPay', label: 'Net Pay' },
  ]

  const firstRowCells: TableCell[] = [
    {
      renderFn: () => {
        return getShiftTime(shiftStartTime, shiftEndTime)
      },
    },
    {
      renderFn: () => {
        return clockInTime ? getTime(clockInTime) : '--'
      },
    },
    {
      renderFn: () => {
        return clockOutTimeBeforeWorkerEdit
          ? getTime(clockOutTimeBeforeWorkerEdit)
          : '--'
      },
    },
    {
      renderFn: () => {
        const totalBreakTime = getTotalBreakTime(
          breaksBeforeWorkerEdit || [],
          shift.breakType,
          [],
        )
        return formatDuration(totalBreakTime)
      },
    },
    {
      renderFn: () => {
        const totalPaidTimeInMinutes =
          clockInTime && clockOutTimeBeforeWorkerEdit
            ? getTotalPaidTimeInMinutes(
                clockInTime,
                clockOutTimeBeforeWorkerEdit,
                breaksBeforeWorkerEdit || [],
                shift.breakType,
                [],
              )
            : undefined
        return totalPaidTimeInMinutes
          ? formatDuration(totalPaidTimeInMinutes)
          : '--'
      },
    },
    {
      renderFn: () => '',
    },
  ]
  const secondRowCells: TableCell[] = [
    {
      renderFn: () => '',
    },
    {
      renderFn: () => '',
    },
    {
      renderFn: () => {
        return clockOutTime ? getTime(clockOutTime) : '--'
      },
    },
    {
      renderFn: () => {
        const totalBreakTime = getTotalBreakTime(
          breaks || [],
          shift.breakType,
          [],
        )
        return formatDuration(totalBreakTime)
      },
    },
    {
      renderFn: () => {
        return timeWorked ? formatDuration(timeWorked) : '--'
      },
    },
    {
      renderFn: () => {
        return netPayAmount
          ? getMoneyString({
              amount: netPayAmount,
              currency: 'USD',
            })
          : '--'
      },
    },
  ]

  return (
    <Col width="100%">
      <DataTable
        tableContainerStyles={{ tableLayout: 'fixed' }}
        headers={firstRowHeaders}
        rows={[
          {
            cells: firstRowCells,
            key: `${earningSummaryWorkerShift.workerShiftId}_times_before_edit`,
          },
        ]}
      />
      <DataTable
        tableContainerStyles={{ tableLayout: 'fixed' }}
        headers={secondRowHeaders}
        rows={[
          {
            cells: secondRowCells,
            key: `${earningSummaryWorkerShift.workerShiftId}_times_after_edit`,
          },
        ]}
      />
    </Col>
  )
}
