import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { LineItemType } from '@traba/types'
import { Money } from '@traba/types'
import { useMemo } from 'react'
import { Col, Icon } from 'src/components/base'
import { DataTableHeader } from 'src/components/base/Table/DataTable'
import { Table, Td, Tr } from 'src/components/base/Table/Table'
import { getMoneyString } from 'src/utils/stringUtils'

export type InvoiceDetailsBodyLineItem = {
  id?: string
  type: LineItemType
  stripeItemId?: string
  stripeDescription: string
  description: string
  chargeToBusiness: Money
  shiftId?: string
}

export type InvoiceLineItemsTableProps = {
  lineItemsEditable: boolean
  lineItems: InvoiceDetailsBodyLineItem[]
  totalCharge: Money
  handleDeleteLineItem: (id: string) => void
  handleEditLineItem: (index: number) => void
  hideInternalOnlyLineItems: boolean
}

export default function InvoiceLineItemsTable(
  props: InvoiceLineItemsTableProps,
) {
  const headers: DataTableHeader[] = [
    {
      key: 'description',
      label: 'Description',
      sortable: false,
    },
    {
      key: 'totalCharge',
      label: 'Total Charge',
      sortable: false,
    },
    {
      key: 'actions',
      sortable: false,
    },
  ]

  const deletableLineItem = (lineItem: InvoiceDetailsBodyLineItem): boolean => {
    // Disallowing deletion of worker shift line items, as removal of those should
    // come from edits to the worker shift itself.
    return (
      props.lineItemsEditable &&
      [LineItemType.BONUS, LineItemType.PENALTY, LineItemType.SHIFT].includes(
        lineItem.type,
      )
    )
  }

  const lineItemRows = useMemo(() => {
    return props.lineItems
      .filter(
        (lineItem) =>
          !props.hideInternalOnlyLineItems ||
          (lineItem.chargeToBusiness.amount !== 0 && lineItem.stripeItemId),
      )
      .map((lineItem: InvoiceDetailsBodyLineItem, index: number) => {
        return (
          <Tr key={index.toString()}>
            <Td
              style={{
                borderRight: `1px solid ${theme.colors.Grey}`,
                paddingTop: theme.space.xxs,
                paddingBottom: theme.space.xxs,
              }}
            >
              <Col>
                <Text variant="h6">{lineItem.stripeDescription}</Text>
                {lineItem.shiftId && (
                  <Anchor href={`/field-monitor/${lineItem.shiftId}`}>
                    <Icon
                      name="link"
                      style={{ marginRight: theme.space.xxxs }}
                    />
                    Shift ID: {lineItem.shiftId}
                  </Anchor>
                )}
              </Col>
            </Td>
            <Td style={{ borderRight: `1px solid ${theme.colors.Grey}` }}>
              <Text variant="h6">
                {getMoneyString(lineItem.chargeToBusiness)}
              </Text>
            </Td>
            <Td style={{ textAlign: 'center' }}>
              {props.lineItemsEditable && (
                <Icon
                  name={'edit'}
                  onClick={() => props.handleEditLineItem(index)}
                  style={{ cursor: 'pointer' }}
                />
              )}
              {deletableLineItem(lineItem) && (
                <Icon
                  name={'trash'}
                  onClick={() =>
                    lineItem.id && props.handleDeleteLineItem(lineItem.id)
                  }
                  style={{ cursor: 'pointer', marginLeft: theme.space.xxs }}
                />
              )}
            </Td>
          </Tr>
        )
      })
  }, [props])

  return (
    <div style={{ maxWidth: '60em' }}>
      <Table headers={headers}>
        {lineItemRows}
        <Tr>
          <Td
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              paddingRight: theme.space.xs,
            }}
          >
            Total Amount Due
          </Td>
          <Td style={{ fontWeight: 'bold' }}>
            {getMoneyString(props.totalCharge)}
          </Td>
        </Tr>
      </Table>
    </div>
  )
}
