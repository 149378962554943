import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company } from '@traba/types'
import {
  BusinessCancellationReasons,
  CancellationBusinessChargeType,
  CancellationSource,
  Shift,
  ShiftCancellationCostResponse,
} from '@traba/types'
import { isBefore } from 'date-fns'
import { sum } from 'lodash'
import { useMemo, useState } from 'react'
import { Button, Col, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useApi } from 'src/hooks/useApi'
import { cancelShiftsById } from 'src/hooks/useShifts'
import { getErrorMessage } from 'src/utils/errorUtils'
import CancellationCosts from './CancellationCosts'
import { InputFields } from './InputFields'

type CancelShiftModalProps = {
  handleClose: () => void
  isOpen: boolean
  shift: Shift
  company: Company
}

type ValidReasonInput = BusinessCancellationReasons | string

type ValidCancellationSources =
  | CancellationSource.Business
  | CancellationSource.Ops
  | string

export default function CancelShiftModal({
  isOpen,
  handleClose,
  shift,
}: CancelShiftModalProps) {
  const [reasonDropdownValue, setReasonDropdownValue] =
    useState<ValidReasonInput>(BusinessCancellationReasons.OTHER)
  const [sourceDropdownValue, setSourceDropdownValue] =
    useState<ValidCancellationSources>(CancellationSource.Business)
  const [customReasonInput, setCustomReasonInput] = useState('')

  const startTime = new Date(shift.startTime)
  const [canceledAt, setCanceledAt] = useState<Date | null>(() => {
    const now = new Date()
    return isBefore(now, startTime) ? now : startTime
  })
  const [loading, setLoading] = useState<boolean>(false)

  const { showError, showSuccess } = useAlert()

  const { data: cancellationData, isLoading: shiftCancellationDataIsLoading } =
    useApi<ShiftCancellationCostResponse>(
      `companies/${shift.companyId}/shifts/${shift.shiftId}/cancellation-costs${
        canceledAt ? `?canceledAt=${canceledAt?.toISOString()}` : ``
      }`,
    )

  const isPaidCancellation = useMemo(() => {
    if (
      sourceDropdownValue !== CancellationSource.Business ||
      reasonDropdownValue === BusinessCancellationReasons.WEATHER
    ) {
      return false
    }
    const chargedCancellations = cancellationData?.cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    )
    return !!chargedCancellations && chargedCancellations.length > 0
  }, [cancellationData, reasonDropdownValue, sourceDropdownValue])

  const handleConfirm = async () => {
    setLoading(true)
    const cancellationReason =
      reasonDropdownValue === BusinessCancellationReasons.OTHER
        ? customReasonInput
        : reasonDropdownValue
    try {
      await cancelShiftsById(
        [shift.shiftId],
        cancellationReason,
        sourceDropdownValue,
        canceledAt,
      )
      showSuccess('Successfully cancelled shift', 'Success!')
    } catch (err) {
      showError(getErrorMessage(err), 'Error')
    }
    setLoading(false)
    handleClose()
  }

  const calculatedShiftCancellationData = useMemo(() => {
    const shiftCancellationData = !!cancellationData && cancellationData
    const {
      cancellations = [],
      cancellationSettings,
      cancellationChargeSummary,
    } = shiftCancellationData || {}

    const {
      cancellationBusinessTimeWindow = 18,
      cancellationBusinessChargeType = CancellationBusinessChargeType.Hourly,
      shouldWaiveCancellationFees = false,
    } = cancellationSettings || {}

    const numberWorkersToAppease = cancellations.length
    const [baseWorkerCancellation] = cancellations

    const baseWorkerPay =
      baseWorkerCancellation && baseWorkerCancellation.grossPay
        ? baseWorkerCancellation.grossPay.amount / 100
        : 0
    const baseBusinessCharge =
      baseWorkerCancellation && baseWorkerCancellation.totalCharge
        ? baseWorkerCancellation.totalCharge.amount / 100
        : 0

    const totalWorkerPay =
      sum(cancellations.map((c) => c.grossPay?.amount)) / 100
    const totalBusinessCharge =
      sum(cancellations.map((c) => c.totalCharge?.amount)) / 100
    const baseBusinessHoursCharged =
      baseWorkerCancellation && baseWorkerCancellation.totalChargedTime
        ? Math.round(10 * (baseWorkerCancellation.totalChargedTime / 60)) / 10
        : 0 // hrs
    const numberOfWorkersToChargeBusinessFor = cancellations.filter(
      (c) => c.totalCharge && c.totalCharge.amount > 0,
    ).length

    return {
      numberWorkersToAppease,
      baseWorkerPay,
      totalWorkerPay,
      baseBusinessCharge,
      totalBusinessCharge,
      cancellationBusinessTimeWindow,
      cancellationBusinessChargeType,
      cancellationChargeSummary,
      baseBusinessHoursCharged,
      shouldWaiveCancellationFees,
      numberOfWorkersToChargeBusinessFor,
    }
  }, [cancellationData])

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={'Cancel Shift'}
      size={MODAL_SIZE.MEDIUM}
      style={{ width: '700px' }}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Row fullWidth justifyBetween mb={theme.space.med}>
          <InputFields
            shift={shift}
            setSourceDropdownValue={setSourceDropdownValue}
            sourceDropdownValue={sourceDropdownValue}
            reasonDropdownValue={reasonDropdownValue}
            setReasonDropdownValue={setReasonDropdownValue}
            customReasonInput={customReasonInput}
            setCustomReasonInput={setCustomReasonInput}
            canceledAt={canceledAt}
            setCanceledAt={setCanceledAt}
          />
        </Row>
        <Divider />
        <Col mb={theme.space.med} style={{ justifyContent: 'space-between' }}>
          <Text
            variant="h5"
            mt={theme.space.lg}
            mb={theme.space.xs}
            pb={theme.space.sm}
          >
            Cancellation cost
          </Text>
          {shiftCancellationDataIsLoading ? (
            <Row mt={24} justifyBetween>
              <CircularProgress size={24} />
            </Row>
          ) : (
            <CancellationCosts
              isPaidCancellation={isPaidCancellation}
              employerName={shift.employerName}
              {...calculatedShiftCancellationData}
            />
          )}
        </Col>
        <Row fullWidth justifyBetween>
          <Button
            variant={ButtonVariant.OUTLINED}
            my={theme.space.ms}
            style={{ margin: '4px 0' }}
            onClick={handleClose}
          >
            Discard
          </Button>
          <Button
            variant={ButtonVariant.CANCEL}
            my={theme.space.ms}
            onClick={handleConfirm}
            loading={loading}
          >
            Cancel Shift
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
