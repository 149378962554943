import { ScheduledBreak } from '@traba/types'
import { BreakType } from '@traba/types'
import { Money } from '@traba/types'
import { Transaction, TransactionType } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { ShiftTime } from '@traba/types'
import { differenceInMinutes } from 'date-fns'

export const PAY_RATE_DEFAULT = 16
export const MIN_WORKER_HOURLY_PAY_DEFAULT = 13

export type ChargedUnits = {
  timeWorkedInMinutes?: number
  unitsWorked?: number
}

export function getChargedUnits({
  payType,
  unitsCompletedByWorker,
  numberOfUnitsInShift,
  totalTimeWorked,
  minimumPaidTime,
  payByUnitMinimumPay,
  payRate,
  slotsRequested,
}: {
  payType: ShiftPayType
  payRate: number | undefined
  payByUnitMinimumPay: number | undefined
  unitsCompletedByWorker: number | undefined
  slotsRequested: number
  numberOfUnitsInShift: number | undefined
  totalTimeWorked: number
  minimumPaidTime: number | undefined
}): ChargedUnits {
  switch (payType) {
    case ShiftPayType.HOURLY:
      return {
        timeWorkedInMinutes: getTimePaidInMinutes(
          totalTimeWorked,
          minimumPaidTime,
        ).totalPaidTime,
      }
    case ShiftPayType.UNIT:
      if (numberOfUnitsInShift === undefined) {
        throw new Error('Shift is missing numberOfUnits')
      }
      return {
        unitsWorked: getUnitsPaid({
          payRate,
          minimumPay: payByUnitMinimumPay,
          unitsCompletedByWorker:
            unitsCompletedByWorker ?? numberOfUnitsInShift / slotsRequested,
        }).totalPaidUnits,
      }
  }
}

// We should pay the worker for the `minimum paid time` if it is greater than the total time worked.
function getTimePaidInMinutes(
  totalTimeWorked: number,
  minimumPaidTime?: number,
): { totalPaidTime: number; isMinPaidTime: boolean } {
  if (
    minimumPaidTime &&
    typeof minimumPaidTime === 'number' &&
    minimumPaidTime > totalTimeWorked
  ) {
    return { totalPaidTime: minimumPaidTime, isMinPaidTime: true }
  }
  return { totalPaidTime: totalTimeWorked, isMinPaidTime: false }
}

function getUnitsPaid({
  payRate,
  minimumPay,
  unitsCompletedByWorker,
}: {
  payRate: number | undefined
  minimumPay: number | undefined
  unitsCompletedByWorker: number
}): {
  totalPaidUnits: number
  isMinPaidUnits: boolean
} {
  if (!payRate) {
    throw new Error('no payRate for pay by unit shift')
  }
  const minUnits = (minimumPay || 0) / payRate
  return {
    totalPaidUnits: Math.max(minUnits, unitsCompletedByWorker),
    isMinPaidUnits: unitsCompletedByWorker <= minUnits,
  }
}

export function calculateWorkerPayandFees({
  chargedUnits,
  payRate,
  payType,
  trustAndSafetyFeeHourly,
  timeWorkedInMinutes,
}: {
  chargedUnits: ChargedUnits
  payRate: number
  payType: ShiftPayType
  trustAndSafetyFeeHourly: Money
  timeWorkedInMinutes: number
}): { grossPay: Money; totalTrustAndSafetyFee: Money; netPay: Money } {
  const grossPay = calculateGrossPay({
    chargedUnits,
    payRate,
    payType,
  })
  const totalTrustAndSafetyFee = calculateTotalTrustAndSafetyFee({
    timeWorkedInMinutes,
    trustAndSafetyFeeHourly,
  })
  const netPay = calculateNetPay({ grossPay, totalTrustAndSafetyFee })

  return { grossPay, totalTrustAndSafetyFee, netPay }
}

export function getTotalBreakTime(
  breaks: ShiftTime[] | null,
  breakType: BreakType,
  scheduledBreaks: ScheduledBreak[],
): number {
  if (breakType === BreakType.PAID) {
    return 0
  }
  const totalBreakTime = breaks
    ? breaks.reduce((acc: number, b: ShiftTime) => {
        if (b.startTime && b.endTime) {
          return (
            acc +
            differenceInMinutes(new Date(b.endTime), new Date(b.startTime))
          )
        }
        return acc + 0
      }, 0)
    : 0

  const overrideScheduledBreaks = breaks && breaks.length > 0
  if (breakType === BreakType.AUTO_UNPAID && !overrideScheduledBreaks) {
    const totalScheduledBreakTime = getScheduledBreakTotal(scheduledBreaks)
    return Math.max(totalScheduledBreakTime, totalBreakTime)
  }
  return totalBreakTime
}

export const getScheduledBreakTotal = (
  scheduledBreaks: ScheduledBreak[],
): number => {
  return scheduledBreaks.reduce((acc: number, sb: ScheduledBreak) => {
    return acc + sb.breakLength * sb.count
  }, 0)
}

export function calculatePaidShiftTime(
  totalBreakTimeInMinutes: number,
  shiftLengthInMinutesIncludingBreaks: number,
  breakType: BreakType,
): number {
  // If a shifts breaks are paid, breaks should not be deducted to get the total paid time.
  // Otherwise, if they are unpaid or manual unpaid, we should deduct the scheduled breaks for the total estimated paid time.
  const shiftLengthMinusBreaks: number =
    breakType === BreakType.PAID
      ? shiftLengthInMinutesIncludingBreaks
      : shiftLengthInMinutesIncludingBreaks - totalBreakTimeInMinutes

  return shiftLengthMinusBreaks
}

export function calculateBilledShiftTime(
  businessStartTime: Date | null,
  shiftEndTime: Date,
  totalBreakTimeInMinutes: number,
  breakType: BreakType,
): number | null {
  if (!businessStartTime) {
    return null
  }

  // Calculate the total shift time from business start time to shift end time
  const totalShiftTimeInMinutes = differenceInMinutes(
    shiftEndTime,
    businessStartTime,
  )

  // If a shifts breaks are paid, breaks should not be deducted to get the total billed time.
  // Otherwise, if they are unpaid or manual unpaid, we should deduct the scheduled breaks for the total billed time.
  const totalBilledTimeInMinutes =
    breakType === BreakType.PAID
      ? totalShiftTimeInMinutes
      : totalShiftTimeInMinutes - totalBreakTimeInMinutes

  return totalBilledTimeInMinutes
}

function calculateGrossPay({
  chargedUnits,
  payRate,
  payType,
}: {
  chargedUnits: ChargedUnits
  payRate: number
  payType: ShiftPayType
}): Money {
  // get payRate into cents
  const payRateInCents = convertPayRateToCents(payRate)
  switch (payType) {
    case ShiftPayType.HOURLY: {
      if (chargedUnits.timeWorkedInMinutes === undefined) {
        throw new Error('timeWorkedInMinutes required for hourly shift')
      }
      // multiply payRateInCents * timeWorkedInMinutes / 60 minutes = decimal amount in cents
      // round decimal amount in cents to closest integer value (pay in cents)
      const hours = chargedUnits.timeWorkedInMinutes / 60
      return { amount: Math.round(payRateInCents * hours), currency: 'USD' }
    }
    case ShiftPayType.UNIT:
      if (chargedUnits.unitsWorked === undefined) {
        throw new Error('unitsWorked required for unit shift')
      }
      return {
        amount: Math.round(payRateInCents * chargedUnits.unitsWorked),
        currency: 'USD',
      }
  }
}

export function convertUSDCentsToMoney(cents: number): Money {
  return {
    amount: cents,
    currency: 'USD',
  }
}

export function convertPayRateToCents(payRate: number) {
  // we might have floating point errors that we want to round, e.g. payRate = $1.10
  return Math.round(payRate * 100)
}

export function convertCentsToDollars(cents: number) {
  return parseFloat((cents / 100).toFixed(2))
}

function calculateTotalTrustAndSafetyFee({
  timeWorkedInMinutes,
  trustAndSafetyFeeHourly,
}: {
  timeWorkedInMinutes: number
  trustAndSafetyFeeHourly: Money
}): Money {
  const hours = timeWorkedInMinutes / 60
  // multiply trustAndSafetyFeeHourlyInCents * timeWorkedInMinutes / 60 minutes = decimal amount in cents
  // round decimal amount in cents to closest integer value (trust in cents)
  const amount = Math.round(hours * trustAndSafetyFeeHourly.amount)

  return {
    amount,
    currency: trustAndSafetyFeeHourly.currency,
  }
}

function calculateNetPay({
  grossPay,
  totalTrustAndSafetyFee,
}: {
  grossPay: Money
  totalTrustAndSafetyFee: Money
}): Money {
  const netPayAmount = grossPay.amount - totalTrustAndSafetyFee.amount

  return {
    amount: netPayAmount,
    currency: grossPay.currency,
  }
}

export function calculateTransferTotal(transactions: Transaction[]): number {
  return transactions.reduce((currentSum, currentTransaction) => {
    if (
      currentTransaction.type === TransactionType.TRANSFER &&
      !currentTransaction.reversed
    ) {
      return (
        currentSum +
        currentTransaction.amount -
        (currentTransaction.amount_reversed || 0)
      )
    }
    return currentSum
  }, 0)
}

export function getMinHourlyPayRate({
  companyMinHourlyPay,
  platformMinHourlyPay,
}: {
  companyMinHourlyPay: number | undefined
  platformMinHourlyPay: number
}) {
  return companyMinHourlyPay !== undefined && companyMinHourlyPay >= 0
    ? companyMinHourlyPay
    : platformMinHourlyPay
}
