import { SearchSelect, Text, useModal } from '@traba/react-components'
import { theme } from '@traba/theme'
import { RecordStatus, Roster } from '@traba/types'
import {
  getAddressString,
  getLocationNameOrTruncatedAddress,
} from '@traba/utils'
import { useCallback, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { RosterModal } from 'src/modals/Rosters/RosterModal'
import { Button, Row, Table } from '../base'
import { ButtonVariant } from '../base/Button/types'
import { IMenuItem } from '../base/Select/Select'
import { ViewRosterRow } from './ViewRosterRow'

type RostersTableProps = {
  companyId: string
  data: Roster[]
}

export const RostersTable = (props: RostersTableProps) => {
  const { data, companyId } = props
  const [selectedVirtualRosterLocation, setSelectedVirtualRosterLocation] =
    useState<IMenuItem | undefined>(undefined)
  const [selectedVirtualRosterRole, setSelectedVirtualRosterRole] = useState<
    IMenuItem | undefined
  >(undefined)
  const createOrEditModal = useModal()
  const deleteModal = useModal()
  const { removeRoster } = useRosters(companyId)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()

  const openCreateModal = useCallback(() => {
    setSelectedRoster(undefined)
    createOrEditModal.open()
  }, [createOrEditModal])

  const openEditModal = useCallback(
    (costCenter: Roster) => {
      setSelectedRoster(costCenter)
      createOrEditModal.open()
    },
    [createOrEditModal],
  )

  const openDeleteModal = useCallback(
    (costCenter: Roster) => {
      setSelectedRoster(costCenter)
      deleteModal.open()
    },
    [deleteModal],
  )

  const { virtualRosters } = useVirtualRosters(
    companyId,
    selectedVirtualRosterLocation?.value,
    selectedVirtualRosterRole?.value,
  )

  const { locations } = useLocations(companyId)
  const { roles } = useRoles({ companyId })

  const activeLocations =
    locations?.filter(
      (location) => location.recordStatus === RecordStatus.Active,
    ) ?? []
  const activeRoles =
    roles?.filter((role) => role.recordStatus === RecordStatus.Active) ?? []

  const locationOptions: IMenuItem[] =
    activeLocations.map((location) => ({
      label: getLocationNameOrTruncatedAddress(location),
      value: location.locationId,
      secondaryLabel: getAddressString(location.address),
    })) ?? []
  const roleOptions =
    activeRoles.map((role) => ({
      label:
        role.roleName === '' || role.roleName === undefined
          ? 'N/A'
          : role.roleName,
      value: role.roleId,
    })) ?? []

  const onFilterChange = (value: IMenuItem | undefined, field: string) => {
    window.analytics.track('Ops Changed Virtual Rosters Filter', {
      field,
      value,
    })
    switch (field) {
      case 'location':
        setSelectedVirtualRosterLocation(value)
        break
      case 'role':
        setSelectedVirtualRosterRole(value)
        break
      default:
        break
    }
  }

  return (
    <>
      <RosterModal
        {...createOrEditModal}
        companyId={companyId}
        rosterToEdit={selectedRoster}
        handleClose={() => {
          createOrEditModal.handleClose()
          setSelectedRoster(undefined)
        }}
      />
      <ConfirmationDialog
        {...deleteModal}
        open={deleteModal.isOpen}
        onConfirm={() => {
          selectedRoster && removeRoster(selectedRoster.id)
          setSelectedRoster(undefined)
        }}
        onClose={() => {
          deleteModal.handleClose()
          setSelectedRoster(undefined)
        }}
        title="Delete Roster?"
        confirmButtonText="Delete"
        content={`Are you sure you want to delete ${selectedRoster?.rosterName}?`}
      />
      <Row
        justifyEnd
        alignCenter
        style={{
          paddingBottom: theme.space.xs,
          justifyContent: 'space-between',
        }}
      >
        <Text mb={theme.space.sm} variant="h4">
          Rosters
        </Text>
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={openCreateModal}
        >
          New Roster
        </Button>
      </Row>
      <Table>
        {data.map((rosterDetails: Roster) => (
          <ViewRosterRow
            key={rosterDetails.id}
            roster={rosterDetails}
            onClickDeleteRoster={openDeleteModal}
            onClickEditRoster={openEditModal}
          />
        ))}
      </Table>
      <Row
        justifyEnd
        alignCenter
        style={{
          paddingTop: theme.space.med,
          justifyContent: 'space-between',
        }}
      >
        <Text mb={theme.space.sm} variant="h4">
          Virtual Rosters
        </Text>
      </Row>
      <Row
        style={{
          paddingBottom: theme.space.xs,
        }}
      >
        <SearchSelect
          style={{ minWidth: 200, marginRight: theme.space.sm }}
          options={locationOptions}
          selectItem={selectedVirtualRosterLocation}
          handleSelect={(value) => onFilterChange(value, 'location')}
          label="Location"
        />
        <SearchSelect
          style={{ minWidth: 200, marginRight: theme.space.sm }}
          options={roleOptions}
          selectItem={selectedVirtualRosterRole}
          handleSelect={(value) => onFilterChange(value, 'role')}
          label="Role"
        />
      </Row>
      <Table>
        {virtualRosters.length > 0 &&
          virtualRosters.map((roster) => (
            <ViewRosterRow
              key={roster.id}
              roster={roster}
              onClickDeleteRoster={openDeleteModal}
              onClickEditRoster={openEditModal}
              isVirtualRoster={true}
            />
          ))}
      </Table>
    </>
  )
}

export default RostersTable
