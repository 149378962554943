import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ChatMessage,
  ChatMessageDeletedStatus,
  ChatMessageDeliveryStatus,
} from '@traba/types'
import { useState } from 'react'
import { Avatar, Col, Icon, Row } from 'src/components/base'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'
import MessageItemStatus from './MessageItemStatus'

interface Props {
  message: ChatMessage
  handleClickChatImage: (imageUrls: string[]) => void
  isCompanyChats?: boolean
  companyLogoUrl?: string
  workerUrl?: string
  onDeleteMessage: (messageId: string) => void
}

const getFailedReason = (deliveryStatus: ChatMessageDeliveryStatus) => {
  switch (deliveryStatus) {
    case ChatMessageDeliveryStatus.MODERATED_EXPLICIT:
      return 'This message failed to send due to explicit content'
    case ChatMessageDeliveryStatus.MODERATED_PAY_QUESTIONS:
      return 'This message failed to send due to pay questions'

    default:
      return ''
  }
}

const MessageItem = ({
  message,
  handleClickChatImage,
  isCompanyChats,
  companyLogoUrl,
  onDeleteMessage,
  workerUrl,
}: Props) => {
  const {
    attachmentUrl,
    workerSenderName,
    companyUserSenderId,
    companyUserSenderName,
    workerSenderId,
    messageContent,
    sentAt,
    deletedStatus,
    messageId,
    deliveryStatus,
  } = message
  const [showDeleteButton, setShowDeleteButton] = useState(false)

  if (!message && !attachmentUrl) {
    return null
  }
  let isFromBiz = false
  if (isCompanyChats) {
    isFromBiz = !!companyUserSenderId
  } else {
    isFromBiz = !!workerSenderId
  }

  const senderName = companyUserSenderName ?? workerSenderName
  const isDeleted = deletedStatus === ChatMessageDeletedStatus.DELETED
  const isFailed =
    deliveryStatus === ChatMessageDeliveryStatus.MODERATED_EXPLICIT ||
    deliveryStatus === ChatMessageDeliveryStatus.MODERATED_PAY_QUESTIONS

  return (
    <div
      style={{
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        justifyContent: isFromBiz ? 'flex-start' : 'flex-end',
        marginTop: theme.space.xs,
      }}
    >
      <Row
        style={{
          marginBottom: theme.space.xs,
          justifyContent: 'flex-end',
          alignSelf: isFromBiz ? 'flex-end' : 'flex-start',
          maxWidth: '65%',
        }}
      >
        {isFromBiz && (
          <Avatar
            src={isCompanyChats ? companyLogoUrl : workerUrl}
            name={senderName ?? ''}
            size={50}
          />
        )}
        <Col mx={theme.space.xxs}>
          <Tooltip title={getFailedReason(deliveryStatus)}>
            <div
              onMouseEnter={() => {
                setShowDeleteButton(true)
              }}
              onMouseLeave={() => {
                setShowDeleteButton(false)
              }}
            >
              <Card
                style={{
                  backgroundColor: isFromBiz
                    ? theme.colors.Grey10
                    : theme.colors.Violet10,
                  paddingTop: theme.space.xxs,
                  paddingBottom: theme.space.xxs,
                  paddingLeft: theme.space.xs,
                  borderWidth: 0,
                }}
              >
                {isDeleted && (
                  <Text variant={'body2'} style={{ wordBreak: 'break-word' }}>
                    This message has been deleted:
                  </Text>
                )}
                <Text variant={isDeleted ? 'body2' : 'body1'}>
                  {messageContent}
                </Text>
                {attachmentUrl && (
                  <img
                    onClick={() => handleClickChatImage([attachmentUrl])}
                    style={{
                      borderRadius: theme.shape.borderRadius,
                      cursor: 'pointer',
                    }}
                    src={attachmentUrl}
                    alt=""
                    height={100}
                    width={100}
                  />
                )}
              </Card>
              <Row justifyEnd={isFromBiz} alignCenter>
                <Text variant="body2" mr={theme.space.xxxs}>
                  {formatTimeDistanceToNow(new Date(sentAt))}
                  {senderName ? ` \u2022 ${senderName}` : ''}
                </Text>
                <MessageItemStatus deliveryStatus={deliveryStatus} />
                {showDeleteButton && !isDeleted && !isFailed ? (
                  <Icon
                    name="trash"
                    onClick={() => onDeleteMessage(messageId)}
                  />
                ) : (
                  <div style={{ width: 16 }} />
                )}
              </Row>
            </div>
          </Tooltip>
        </Col>

        {!isFromBiz && (
          <Avatar
            src={isCompanyChats ? workerUrl : companyLogoUrl}
            name={senderName ?? ''}
            size={50}
          />
        )}
      </Row>
    </div>
  )
}

export default MessageItem
