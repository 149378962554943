import { useAlert } from '@traba/context'
import {
  Text,
  Col,
  LoadingSpinner,
  Button,
  Table,
  Tr,
  Td,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  useDemoAccounts,
  useDemoAccountsMutations,
} from 'src/hooks/useDemoAccounts'

export default function DemoAccountsTable() {
  const { showInfo } = useAlert()
  const { deleteDemoAccount, isDeletingDemo } = useDemoAccountsMutations()
  const {
    data: demoAccounts,
    isLoading: demoAccountsLoading,
    isError: error,
  } = useDemoAccounts()

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    showInfo('Copied to clipboard', 'Copied')
  }

  return (
    <Col style={{ width: '50%' }} mt={theme.space.xs}>
      <Text center variant="h4">
        Demo Accounts
      </Text>
      {demoAccountsLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <Text variant="error">There was an error loading demo accounts</Text>
      ) : (
        <Table headers={['Email', 'Password', 'Actions']}>
          {demoAccounts && demoAccounts.length > 0 ? (
            demoAccounts.map((account) => (
              <Tr key={account.id}>
                <Td>
                  <Text
                    onClick={() => handleCopyToClipboard(account.email)}
                    style={{
                      cursor: 'pointer',
                      color: theme.colors.MidnightBlue,
                    }}
                  >
                    {account.email}
                  </Text>
                </Td>
                <Td>
                  <Text
                    onClick={() => handleCopyToClipboard(account.password)}
                    style={{
                      cursor: 'pointer',
                      color: theme.colors.MidnightBlue,
                    }}
                  >
                    {account.password}
                  </Text>
                </Td>
                <Td>
                  <Button
                    slim
                    variant={ButtonVariant.CANCEL}
                    onClick={() => deleteDemoAccount(account.id)}
                    loading={isDeletingDemo}
                    style={{ marginLeft: theme.space.xs }}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3}>
                <Text>No demo accounts available</Text>
              </Td>
            </Tr>
          )}
        </Table>
      )}
    </Col>
  )
}
