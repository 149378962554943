import { LinkText, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShift } from '@traba/types'
import { useNavigate } from 'react-router-dom'

interface Props {
  fulfilled: WorkerShift[]
  rejected: string[]
}

export const AddWorkerToFiniteScheduleResults = (props: Props) => {
  const { fulfilled, rejected } = props
  const isFullyAdded = rejected.length === 0
  const isNotAdded = fulfilled.length === 0
  const navigate = useNavigate()

  return (
    <>
      {isFullyAdded && (
        <Text color={theme.colors.Green80}>
          Worker is added to all shifts under this schedule
        </Text>
      )}
      {isNotAdded && (
        <Text variant="error">
          Worker is NOT added to any shifts under this schedule
        </Text>
      )}
      {!isFullyAdded && !isNotAdded && (
        <>
          {fulfilled.length > 0 && (
            <>
              <Text color={theme.colors.Green80}>
                Worker is added to following shifts:
              </Text>
              {fulfilled.map((r) => (
                <LinkText
                  key={`fulfilled-${r.shiftId}`}
                  onClick={() => {
                    navigate(`/field-monitor/${r.shiftId}`)
                  }}
                >
                  {r.shiftId}
                </LinkText>
              ))}
            </>
          )}
          {rejected.length > 0 && (
            <>
              <Text variant="error">
                Worker is NOT added to following shifts:
              </Text>
              {rejected.map((r) => (
                <LinkText
                  key={`rejected-${r}`}
                  onClick={() => {
                    navigate(`/field-monitor/${r.split(':')[0]}`)
                  }}
                >
                  {r}
                </LinkText>
              ))}
            </>
          )}
        </>
      )}
    </>
  )
}
