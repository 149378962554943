import { TrainingVideo } from '@traba/types'
import { Button, Tr, Td, CopyTextIcon, Icon } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { truncateString } from 'src/utils/stringUtils'

interface Props {
  video: TrainingVideo
  onDelete: (videoId: string) => void
  isLoading: boolean
}

export const ShiftTrainingVideosTableRow = ({
  video,
  isLoading,
  onDelete,
}: Props) => {
  return (
    <Tr>
      <Td>{video.name}</Td>
      <Td>
        {truncateString(video.id, 10)}
        <CopyTextIcon textToCopy={video.id} />
      </Td>
      <Td>
        <video width="100" controls>
          <source src={video.videoLink} />
        </video>
      </Td>
      <Td>
        <Button
          variant={ButtonVariant.TEXT}
          disabled={isLoading}
          onClick={() => {
            onDelete(video.id)
          }}
        >
          <Icon name="trash" />
        </Button>
      </Td>
    </Tr>
  )
}
