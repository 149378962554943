import { VIRTUOSO_DYNAMIC_HEIGHT_CHANGE_HEIGHT } from '@traba/consts'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PendingClockOutsResponse, ShiftTag } from '@traba/types'
import { useCallback, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { PendingClockOutItem } from 'src/components/PendingClockOutItem/PendingClockOutItem'
import { usePendingClockOuts } from 'src/hooks/usePendingClockOuts'
import { useWorkerShiftMutations } from 'src/hooks/useWorkerShifts'
import { PendingClockOutAdjustAndConfirmModal } from 'src/modals/PendingClockOutAdjustAndConfirmModal'
import {
  FilterProps,
  FiltersSection,
  HiddenFilterTypes,
} from '../FiltersSection'
import { ResponseDisplayDialog } from '../ResponseDisplayDialog'
import { EmptySearchResultsMessageContainer } from '../styles'

interface PendingClockOutsProps {
  filterProps: FilterProps
}

const getMaxBulkCompleteShiftsTooltipMessage = (maxLimit: number) => {
  return `You can select up to ${maxLimit} worker shifts to complete at once. Complete the selected worker shifts first then select more.`
}
const COMPLETE_WORKER_SHIFTS_CONFIRM_MESSAGE =
  'Do you want to confirm the shifts for these worker shifts?'
const COMPLETE_WORKER_SHIFT_CONFIRM_MESSAGE =
  'Do you want to confirm the shift for this worker shift?'
const CLOCK_OUT_TIME_REQUIRED_ERROR_MESSAGE =
  'A clock out time is needed to end the shift. Try again or use "Update Shift" instead'

export function PendingClockOuts(props: PendingClockOutsProps) {
  const { hotSettings } = useHotSettings()
  const MAX_BULK_COMPLETE_SHIFTS_LIMIT = hotSettings?.bulkEndShiftsLimit || 20

  const { showError } = useAlert()
  const { filterProps } = props
  const {
    shiftId,
    workerId,
    dateRange,
    companyIds,
    regionIds,
    excludeCompanies,
    selectedTags,
  } = filterProps

  /* Get Pending Clock Outs */
  const {
    pendingClockOuts = [],
    refetch,
    isLoading,
    isFetched: pendingClockOutsFetched,
    isRefetching,
  } = usePendingClockOuts({
    shiftId,
    workerId,
    startAfter: dateRange[0]?.toISOString() ?? '',
    endBefore: dateRange[1]?.toISOString() ?? '',
    regionIds: regionIds,
    companyIds: excludeCompanies
      ? undefined
      : companyIds.map((c) => c.value as string),
    excludeCompanyIds: excludeCompanies
      ? companyIds.map((c) => c.value as string)
      : undefined,
    tags: selectedTags.map((tag) => tag.value as ShiftTag),
  })

  const [selectedWorkerShifts, setSelectedWorkerShifts] = useState<
    PendingClockOutsResponse[]
  >([])

  const [
    selectedWorkerShiftForAdjustment,
    setSelectedWorkerShiftForAdjustment,
  ] = useState<PendingClockOutsResponse | undefined>(undefined)

  const [responseOpen, setResponseOpen] = useState<boolean>(false)
  const handleOpenResponseModal = useCallback(() => {
    setResponseOpen(true)
  }, [])
  const handleCloseResponseModal = useCallback(() => {
    setResponseOpen(false)
  }, [])

  const { bulkEndShifts, isBulkEndShiftsLoading, bulkEndShiftResponseData } =
    useWorkerShiftMutations()

  const isLimitReachedForSelectableWorkerShifts =
    selectedWorkerShifts.length === MAX_BULK_COMPLETE_SHIFTS_LIMIT
  const allSelected =
    pendingClockOuts.length > 0 &&
    (selectedWorkerShifts.length === pendingClockOuts.length ||
      isLimitReachedForSelectableWorkerShifts)

  const handleBulkCompleteShifts = useCallback(async () => {
    const isConfirmed = window.confirm(COMPLETE_WORKER_SHIFTS_CONFIRM_MESSAGE)
    if (!isConfirmed) {
      return
    }

    const workerShiftsToEnd = selectedWorkerShifts.map((ws) => ({
      shiftId: ws.workerShift.shiftId,
      workerId: ws.workerShift.workerId,
      // this field is always present or else this worker shift would not show up in pending clock outs
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clockOutTime: ws.workerShift.clockOutTimeBeforeWorkerEdit!,
    }))
    window.analytics.track(
      `Ops Clicked Bulk Complete Shift for pending clock-out items`,
      {
        shiftIds: workerShiftsToEnd.map((ws) => ws.shiftId),
        workerIds: workerShiftsToEnd.map((ws) => ws.workerId),
      },
    )

    await bulkEndShifts({ workerShiftsToEnd })
    setSelectedWorkerShifts([])
  }, [bulkEndShifts, selectedWorkerShifts])

  const handleCompleteShift = useCallback(
    (pendingClockOut: PendingClockOutsResponse) => async () => {
      const { workerShift } = pendingClockOut
      const isConfirmed = window.confirm(COMPLETE_WORKER_SHIFT_CONFIRM_MESSAGE)
      if (!isConfirmed) {
        return
      }

      if (!workerShift.clockOutTimeBeforeWorkerEdit) {
        showError(CLOCK_OUT_TIME_REQUIRED_ERROR_MESSAGE)
        return
      }

      window.analytics.track(
        `Ops Clicked Complete Shift for pending clock-out item`,
        {
          shiftId: workerShift.shiftId,
          workerId: workerShift.workerId,
        },
      )

      await bulkEndShifts({
        workerShiftsToEnd: [
          {
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
            clockOutTime: workerShift.clockOutTimeBeforeWorkerEdit,
          },
        ],
      })
    },
    [bulkEndShifts, showError],
  )

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedWorkerShifts([])
    } else {
      setSelectedWorkerShifts(
        pendingClockOuts.slice(0, MAX_BULK_COMPLETE_SHIFTS_LIMIT),
      )
    }
  }

  const handleSelectWorkerShift =
    (pendingClockOut: PendingClockOutsResponse, isCurrentlySelected: boolean) =>
    () => {
      if (isCurrentlySelected) {
        setSelectedWorkerShifts((curr) =>
          curr.filter(
            (selectedShift) =>
              selectedShift.workerShift.id !== pendingClockOut.workerShift.id,
          ),
        )
      } else if (!isLimitReachedForSelectableWorkerShifts) {
        setSelectedWorkerShifts((curr) => [...curr, pendingClockOut])
      }
    }

  const handleAdjustWorkerShiftClick = useCallback(
    (pendingClockOutSummary: PendingClockOutsResponse) => () => {
      setSelectedWorkerShiftForAdjustment(pendingClockOutSummary)
    },
    [],
  )

  const handleCloseAdjustWorkerShiftModal = useCallback(() => {
    setSelectedWorkerShiftForAdjustment(undefined)
  }, [])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
        minWidth: '1100px',
      }}
    >
      <Text variant="h4">Pending Clock-outs</Text>
      <FiltersSection
        isLoading={!pendingClockOutsFetched || isLoading}
        onClickSearch={refetch}
        filterProps={filterProps}
        hiddenFilters={[
          HiddenFilterTypes.PAYMENT_STATUS_OPTIONS,
          HiddenFilterTypes.WARDEN_FLAG_ONLY,
          HiddenFilterTypes.WORKER_EDITED_ONLY,
          HiddenFilterTypes.INCENTIVE_OPTIONS,
        ]}
      />
      <Row mt={theme.space.med} justifyBetween alignCenter>
        <Row alignCenter>
          <Text variant="h5">Search Results ({pendingClockOuts.length})</Text>
          <Button
            variant={ButtonVariant.OUTLINED}
            ml={theme.space.xs}
            onClick={handleOpenResponseModal}
            disabled={!bulkEndShiftResponseData}
          >
            Responses
          </Button>
          <ResponseDisplayDialog
            isOpen={responseOpen}
            onClose={handleCloseResponseModal}
            response={bulkEndShiftResponseData}
          />
        </Row>
        <Row alignCenter gap={theme.space.xs}>
          <Text variant="caption" style={{ fontSize: 12 }}>
            {selectedWorkerShifts.length} Selected
          </Text>

          <Button variant={ButtonVariant.OUTLINED} onClick={handleSelectAll}>
            {allSelected
              ? 'Unselect All'
              : `Select All (max ${MAX_BULK_COMPLETE_SHIFTS_LIMIT})`}
          </Button>
          <Button
            variant={ButtonVariant.PURPLEGRADIENT}
            onClick={handleBulkCompleteShifts}
            loading={isBulkEndShiftsLoading || isRefetching}
            disabled={selectedWorkerShifts.length === 0}
          >
            Bulk complete shift
          </Button>
        </Row>
      </Row>
      {isLoading ? (
        <CircularProgress size={'medium'} />
      ) : pendingClockOuts.length > 0 ? (
        <Virtuoso
          data={pendingClockOuts}
          // the following sets a dynamic height to take up the viewport
          // aside from the height of components above. 435px will need to change if the height of other components changes
          style={{
            height: `calc(100vh - ${VIRTUOSO_DYNAMIC_HEIGHT_CHANGE_HEIGHT}px)`,
            marginTop: theme.space.xs,
          }}
          totalCount={pendingClockOuts.length}
          itemContent={(_, pendingClockOut) => (
            <Row mt={theme.space.xs}>
              <PendingClockOutItem
                key={pendingClockOut.workerShift.id}
                pendingClockOutSummary={pendingClockOut}
                isLoading={isBulkEndShiftsLoading}
                isSelected={selectedWorkerShifts.includes(pendingClockOut)}
                onSelect={handleSelectWorkerShift(
                  pendingClockOut,
                  selectedWorkerShifts.includes(pendingClockOut),
                )}
                onAdjustShiftClick={handleAdjustWorkerShiftClick(
                  pendingClockOut,
                )}
                onCompleteShiftClick={handleCompleteShift(pendingClockOut)}
                disableSelectMore={isLimitReachedForSelectableWorkerShifts}
                disableSelectMoreText={
                  isLimitReachedForSelectableWorkerShifts
                    ? getMaxBulkCompleteShiftsTooltipMessage(
                        MAX_BULK_COMPLETE_SHIFTS_LIMIT,
                      )
                    : undefined
                }
              />
            </Row>
          )}
        />
      ) : (
        <EmptySearchResultsMessageContainer>
          Worker shifts with pending clock-outs will show up here
        </EmptySearchResultsMessageContainer>
      )}
      {selectedWorkerShiftForAdjustment &&
        selectedWorkerShiftForAdjustment.workerShift.workerId &&
        selectedWorkerShiftForAdjustment.workerShift.shiftId && (
          <PendingClockOutAdjustAndConfirmModal
            isOpen={true}
            handleClose={handleCloseAdjustWorkerShiftModal}
            workerId={selectedWorkerShiftForAdjustment.workerShift.workerId}
            shiftId={selectedWorkerShiftForAdjustment.workerShift.shiftId}
          />
        )}
    </Col>
  )
}
