import { InfoTooltip } from '@traba/react-components'
import { AccountStatus } from '@traba/types'
import { format, startOfDay } from 'date-fns'
import Badge from '.'

type StripeAccountPayoutsBadgeProps = {
  accountStatus: AccountStatus
}

const getBadgeProps = (
  accountStatus: AccountStatus,
): { title: string; variant: string; tooltip?: string } => {
  if (
    !accountStatus.payment ||
    accountStatus.payment.setupStatus === 'NO_ACCOUNT'
  ) {
    return {
      title: 'No account',
      variant: 'danger',
    }
  }

  const { payoutsEnabled, payoutsDisabledDeadline, payoutsDisabledReason } =
    accountStatus.payment
  if (!payoutsEnabled) {
    return {
      title: 'Payouts Disabled',
      variant: 'danger',
      tooltip: payoutsDisabledReason && `Reason: ${payoutsDisabledReason}`,
    }
  }

  const deadlineTodayOrLater =
    payoutsDisabledDeadline &&
    startOfDay(new Date(payoutsDisabledDeadline)) >= startOfDay(new Date())
  if (deadlineTodayOrLater) {
    return {
      title: 'Payouts Disabled Soon',
      variant: 'warning',
      tooltip: `Will be disabled on ${format(
        new Date(payoutsDisabledDeadline),
        'MMM d, yyyy',
      )}`,
    }
  }

  // Stripe account is not missing any requirements.
  return {
    title: 'Enabled',
    variant: 'success',
  }
}

export default function StripeAccountPayoutsBadge(
  props: StripeAccountPayoutsBadgeProps,
) {
  const badgeProps = getBadgeProps(props.accountStatus)

  return (
    <>
      <Badge title={badgeProps.title} variant={badgeProps.variant} />
      {badgeProps.tooltip && <InfoTooltip title={badgeProps.tooltip} />}
    </>
  )
}
