import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus } from '@traba/types'
import { NoteType, WorkerNote } from '@traba/types'
import { Worker } from '@traba/types'
import { Col, Row } from 'src/components/base'
import { useApi } from 'src/hooks/useApi'
import useNotes from 'src/hooks/useNotes'
import { sortByProp } from 'src/utils/helperUtils'
import NoteDrawer from './NoteDrawer/NoteDrawer'
import { NotePlaceholder } from './NotePlaceholder'
import { PostedNote } from './PostedNote'

interface WorkerNoteDrawerProps {
  workerId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideFAB?: boolean
}

export const WorkerNoteDrawer = (props: WorkerNoteDrawerProps) => {
  const { workerId, isOpen, setIsOpen, hideFAB } = props
  const { useWorkerNotes } = useNotes()
  const { workerNotes = [] } = useWorkerNotes(workerId)
  const { data: worker, isLoading: isLoadingWorker } = useApi<
    Worker & AccountStatus
  >(`/workers/${workerId}/worker-and-profile`)

  if (isLoadingWorker) {
    return <div>Loading...</div>
  }

  const workerName = `${worker?.firstName} ${worker?.lastName}'s`

  workerNotes.sort(sortByProp('createdAt', 'DESC'))
  const createNoteParams = { id: workerId, noteType: NoteType.Worker }
  return (
    <NoteDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      createNoteParameters={createNoteParams}
      title={`${workerName ?? 'Worker'} Notes`}
      hideFAB={hideFAB}
      noteCount={workerNotes.length}
    >
      {workerNotes.length > 0 ? (
        <Col>
          <Row alignCenter mb={theme.space.xs}>
            <Text variant="h4">Past Notes ({workerNotes.length})</Text>
          </Row>
          {workerNotes.map((workerNote: WorkerNote) => {
            return <PostedNote note={workerNote} noteType={NoteType.Worker} />
          })}
        </Col>
      ) : (
        <NotePlaceholder subtitle="Worker Notes Will Show Up Here" />
      )}
    </NoteDrawer>
  )
}
