export enum TransitStatus {
  NOT_LEFT = 'NOT_LEFT',
  IN_TRANSIT = 'IN_TRANSIT',
  SLOWED = 'SLOWED',
  STOPPED = 'STOPPED',
  MOVING_AWAY = 'MOVING_AWAY',
  LOST_CONNECTION = 'LOST_CONNECTION',
}

export enum TransitResolution {
  NOT_LEFT = 'NOT_LEFT',
  TOO_EARLY = 'TOO_EARLY',
  ON_TIME = 'ON_TIME',
  AT_RISK = 'AT_RISK',
  LATE = 'LATE',
}
export enum TransitMetricChange {
  NOT_STARTED = 'NOT_STARTED',
  INCREASING = 'INCREASING',
  DECREASING = 'DECREASING',
  STAGNANT = 'STAGNANT',
}

export type TransitHistoryEntry = {
  eventTimestamp: Date
  remainingDistanceMeters: number
  transitStatus: TransitStatus
}
export type WorkerShiftTransit = {
  id: string
  initialRemainingDurationSeconds: number
  initialRemainingDistanceMeters: number
  latestRemainingDurationSeconds: number
  latestRemainingDistanceMeters: number
  latestRemainingMetricsTimestamp: Date
  latestTransitStatus: TransitStatus
  expectedTransitResolution: TransitResolution
  remainingDistanceChange: TransitMetricChange
  etaChange: TransitMetricChange
  transitLogId?: string
  arrivedAt?: Date
  workerId: string
  shiftId: string
  workerShiftId?: string
  workerShiftTransitHistory?: TransitHistoryEntry[]
}

export type TransitHistoryEntryResponse = {
  eventTimestamp: string
  remainingDistanceMeters: number
  transitStatus: TransitStatus
}

export type WorkerShiftTransitResponse = {
  id: string
  initialRemainingDurationSeconds: number
  initialRemainingDistanceMeters: number
  latestRemainingDurationSeconds: number
  latestRemainingDistanceMeters: number
  latestRemainingMetricsTimestamp: string
  latestTransitStatus: TransitStatus
  expectedTransitResolution: TransitResolution
  remainingDistanceChange: TransitMetricChange
  etaChange: TransitMetricChange
  transitLogId?: string
  arrivedAt?: string
  workerId: string
  shiftId: string
  workerShiftId?: string
  workerShiftTransitHistory?: TransitHistoryEntryResponse[]
}

export type WorkerShiftTransitResponseByShift = {
  workerShiftTransits: WorkerShiftTransitResponse[]
}
