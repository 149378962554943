import { trabaApi } from '@traba/api-utils'
import { Announcement, CreateAnnouncementRequest } from '@traba/types'
import { useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from 'react-query'

const getAnnouncementsForShift = async (shiftId: string) => {
  try {
    const response = await trabaApi.get(`/announcements/shift/${shiftId}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const postAnnouncement = async (request: CreateAnnouncementRequest) => {
  const response = await trabaApi.post(`/announcements`, request)
  return response.data
}

export const useShiftAnnouncements = (shiftId: string) => {
  const [isSendingAnnouncement, setIsSendingAnnouncement] = useState(false)

  const {
    isLoading,
    isError,
    data: announcements,
    error,
    refetch: refetchAnnouncements,
    isFetched,
  } = useQuery<Announcement[], Error>(`shift-announcement-${shiftId}`, () =>
    getAnnouncementsForShift(shiftId),
  )

  async function sendAnnouncement({
    shiftId,
    content,
    imageUrl,
    opsOnly,
    scheduledFor,
    onSubmitOrCancel,
    showSuccess,
    handleError,
    refetch,
  }: {
    shiftId: string
    content: string
    imageUrl?: string
    opsOnly: boolean
    scheduledFor?: Date
    onSubmitOrCancel: () => void
    showSuccess: (successMessage: string, title?: string) => void
    handleError: (
      error: unknown,
      location: string,
      message: string,
      title: string,
      durationInMs?: number | undefined,
    ) => void
    refetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<Announcement[], Error>>
  }) {
    try {
      setIsSendingAnnouncement(true)
      const announcement: CreateAnnouncementRequest = {
        shiftId,
        content,
        imageUrls: imageUrl ? [imageUrl] : undefined,
        sentByOps: true,
        opsOnly,
        scheduledFor: scheduledFor ?? new Date(),
      }
      await postAnnouncement(announcement)
      onSubmitOrCancel()
      window.analytics?.track('Ops User Posted An Announcement', announcement)
      showSuccess('Announcement sent!', 'Success')
      await refetch()
    } catch (error) {
      setIsSendingAnnouncement(false)
      handleError(
        error,
        'useShiftAnnouncements -> postAnnouncement()',
        'Failed to send announcement to workers, please try again',
        'Something went wrong',
      )
    }
  }

  return {
    isLoading,
    isError,
    error,
    isFetched,
    announcements,
    refetchAnnouncements,
    sendAnnouncement,
    isSendingAnnouncement,
  }
}
