import { Text } from '@traba/react-components'
import { Shift } from '@traba/types'
import { startOfDay, startOfMinute, subWeeks } from 'date-fns'
import { useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import {
  TimeFormat,
  useDownloadCompanyReport,
} from 'src/hooks/useDownloadCompanyReport'
import useTimezonedDates from 'src/hooks/useTimezonedDates'

type DownloadReportModalProps = {
  handleClose: () => void
  isOpen: boolean
  shift: Shift
}

export default function DownloadReportModal({
  isOpen,
  handleClose,
  shift,
}: DownloadReportModalProps) {
  const today = startOfDay(new Date())
  const [from, setFrom] = useState(subWeeks(today, 2))
  const [to, setTo] = useState(today)
  const [timeFormat, setTimeFormat] = useState(TimeFormat.LocaleDateString)
  const tz = useTimezonedDates(shift.timezone)
  const { isLoading, getWorkersReport } = useDownloadCompanyReport(
    shift.companyId,
  )

  const timeOptions = [
    {
      value: TimeFormat.LocaleDateString,
      label: 'Date string (mm/dd/yyyy, h:mm PM EST)',
    },
    { value: TimeFormat.UnixTimestamp, label: 'Unix timestamp' },
  ]

  const handleSubmit = async () => {
    getWorkersReport({
      from: startOfMinute(from),
      to: startOfMinute(to),
      timeFormat,
    })
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Download report for ${shift.employerName}`}
      size={MODAL_SIZE.LARGE}
    >
      <Col>
        <Text variant="body2" style={{ marginBottom: '24px' }}>
          Download a report of all worker shifts for this company.
        </Text>
        <Row fullWidth center alignCenter>
          <div style={{ display: 'flex', width: '50%' }}>
            <DatePicker
              date={from}
              setDate={(from) => {
                if (from) {
                  setFrom(from)
                }
              }}
              label="From"
              showTimeFieldInPopover
              granularity="minute"
              isClearable={false}
            />
          </div>
          <Col ml={24} style={{ display: 'flex' }}>
            <Text variant="caption">SHIFT LOCAL TIME</Text>
            <Text style={{ marginTop: '8px' }} variant="body1">
              {`${tz.getDate(from)}, ${tz.getTime(from)}`}
            </Text>
          </Col>
        </Row>
        <Row fullWidth center alignCenter mt={16}>
          <div style={{ display: 'flex', width: '50%' }}>
            <DatePicker
              date={to}
              setDate={(to) => {
                if (to) {
                  setTo(to)
                }
              }}
              label="To"
              showTimeFieldInPopover
              granularity="minute"
              isClearable={false}
            />
          </div>
          <Col ml={24} style={{ display: 'flex' }}>
            <Text variant="caption">SHIFT LOCAL TIME</Text>
            <Text style={{ marginTop: '8px' }} variant="body1">
              {`${tz.getDate(to)}, ${tz.getTime(to)}`}
            </Text>
          </Col>
        </Row>
        <Select
          label="Time Format"
          menuItems={timeOptions}
          value={timeFormat}
          handleSelect={(v) => setTimeFormat(v as TimeFormat)}
          containerStyle={{ marginTop: '24px', width: '50%' }}
        />
        <Row
          fullWidth
          style={{ justifyContent: 'space-between', marginTop: 24 }}
        >
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ width: '200px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '200px' }}
            onClick={handleSubmit}
            loading={isLoading}
          >
            Download
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
