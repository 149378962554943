import { GridColDef } from '@mui/x-data-grid'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Anchor, Row, Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import {
  RobocallCommunication,
  SentinelNotification,
  SentinelTriggerBasedEventType,
  WorkerShiftForOps,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { useMemo } from 'react'
import { WorkerNameWithIcons } from 'src/components/WorkerNameWithIcons/WorkerNameWithIcons'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { sortByProp } from '../../../utils/helperUtils'
import { getWorkerShiftStringId } from '../../../utils/workerShiftUtils'
import { CopyTextIcon, Link } from '../../base'
import WorkerBusinessApprovalScore from '../../WorkerTable/components/WorkerBusinessApprovalScore'
import WorkerReliabilityScore from '../../WorkerTable/components/WorkerReliabilityScore'
import WorkerTableConfirmationIcon from '../../WorkerTable/components/WorkerTableConfirmationIcon'
import WorkerTableJobStatusCell from '../../WorkerTable/components/WorkerTableJobStatusCell'
import { SentinelTableDataGridStyle } from './styles'

function makeBackfillCallNoSignUpColumn() {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      minWidth: 150,
      width: 150,
      renderCell: (params) => (
        <Row alignCenter>
          <Link
            to={`/workers/${params.row.id}`}
            target="_blank"
            style={{ alignSelf: 'center', width: 100 }}
          >
            <Text
              variant="body1"
              style={{
                textAlign: 'left',
                width: 100,
              }}
            >
              <WorkerNameWithIcons
                name={params.row.workerName}
                maxLength={10} // truncate to 10 characters
                isFirstShiftWithCompany={
                  params.row.isFirstTimeWorkerShiftWithCompany
                }
              />
            </Text>
          </Link>
          {params.row.workerName && (
            <CopyTextIcon textToCopy={params.row.workerName} />
          )}
        </Row>
      ),
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      minWidth: 80,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(params.value)}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      renderCell: (params) => (
        <>
          {params.value}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 2,
    },
  ]

  return columns
}

const makeWorkerDetailsColumns = (notification: SentinelNotification) => {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      width: 150,
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to={`/workers/${params.row.id}`}
            target="_blank"
            style={{ alignSelf: 'center', width: 100 }}
          >
            <Text
              variant="body1"
              style={{
                textAlign: 'left',
                width: 100,
              }}
            >
              <WorkerNameWithIcons
                name={params.row.workerName}
                maxLength={10} // truncate to 10 characters
                isFirstShiftWithCompany={
                  params.row.isFirstTimeWorkerShiftWithCompany
                }
              />
            </Text>
          </Link>
          {params.row.workerName && (
            <CopyTextIcon textToCopy={params.row.workerName} />
          )}
        </div>
      ),
      flex: 1,
    },
    {
      field: 'jobStatus',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        return (
          <WorkerTableJobStatusCell
            workerShift={params.value}
            timezone={notification.data.shift.timezone}
          />
        )
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      width: 130,
      minWidth: 130,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(truncateString(params.value, 8))}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'reliability',
      headerName: 'Rel',
      width: 80,
      renderCell: (params) => (
        <WorkerReliabilityScore workerId={params.row.id} />
      ),
    },
    {
      field: 'confirmation',
      headerName: 'Conf',
      width: 80,
      renderCell: (params) => (
        <WorkerTableConfirmationIcon
          workerShift={params.value}
          latestRobocall={params.value.latestRobocall}
        />
      ),
    },
    {
      field: 'businessApproval',
      headerName: 'Biz Aprv',
      width: 80,
      renderCell: (params) => (
        <WorkerBusinessApprovalScore workerId={params.row.id} />
      ),
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      width: 50,
      renderCell: (params) => (
        <>
          {truncateString(params.value, 4)}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
  ]

  return columns
}

function makeBackfillCallNoSignUpRow(notification: SentinelNotification) {
  return [
    {
      id: notification.data.worker?.workerId,
      workerId: notification.data.worker?.workerId,
      workerName: `${notification.data.worker?.firstName} ${notification.data.worker?.lastName}`,
      phoneNumber: notification.data.worker?.phoneNumber,
    },
  ]
}

const makeWorkerDetailsRow = (
  workerShift: WorkerShiftWithWorkerDetails & {
    latestRobocall?: RobocallCommunication
  },
) => {
  return {
    id: workerShift.workerId,
    workerId: workerShift.workerId,
    workerName: `${workerShift.worker?.firstName} ${workerShift.worker?.lastName}`,
    phoneNumber: workerShift.worker?.phoneNumber,
    isFirstTimeWorkerShiftWithCompany:
      workerShift.isFirstTimeWorkerShiftWithCompany,
    confirmation: workerShift,
    jobStatus: workerShift,
  }
}

const groupRobocallsByWorkerShift = (
  robocalls: RobocallCommunication[] = [],
): Map<string, RobocallCommunication[]> => {
  const robocallsByWorkerShift = new Map<string, RobocallCommunication[]>()
  for (const robocall of robocalls) {
    const key = getWorkerShiftStringId(
      robocall.workerId,
      robocall.relatedShiftId || '',
    )
    let workerShiftRobocalls = robocallsByWorkerShift.get(key)
    if (!workerShiftRobocalls) {
      workerShiftRobocalls = []
      robocallsByWorkerShift.set(key, workerShiftRobocalls)
    }
    workerShiftRobocalls.push(robocall)
  }
  return robocallsByWorkerShift
}

export const SentinelWorkerDetailsTable = ({
  workerShifts,
  robocalls,
  handleSelectWorkers,
  notification,
  slim,
}: {
  workerShifts: WorkerShiftForOps[]
  handleSelectWorkers: (workersSelected: GridRowSelectionModel) => void
  robocalls?: RobocallCommunication[]
  notification: SentinelNotification
  slim?: boolean
}) => {
  const isBackfillCallNoSignUpNotif =
    notification.eventType ===
    SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP

  const columns = useMemo(() => {
    return isBackfillCallNoSignUpNotif
      ? makeBackfillCallNoSignUpColumn()
      : makeWorkerDetailsColumns(notification)
  }, [isBackfillCallNoSignUpNotif, notification])
  const rows = useMemo(() => {
    if (isBackfillCallNoSignUpNotif) {
      return makeBackfillCallNoSignUpRow(notification)
    }
    const robocallsByWorkerShift = groupRobocallsByWorkerShift(robocalls)
    return workerShifts.map((workerShift) => {
      const key = getWorkerShiftStringId(
        workerShift.workerId,
        workerShift.shiftId,
      )
      const latestRobocall = robocallsByWorkerShift
        .get(key)
        ?.sort(sortByProp('createdAt', 'DESC'))[0]
      return makeWorkerDetailsRow({ ...workerShift, latestRobocall })
    })
  }, [isBackfillCallNoSignUpNotif, notification, workerShifts, robocalls])

  return (
    <Row
      style={{
        height: slim ? 'auto' : 300,
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DataGrid
        {...SentinelTableDataGridStyle}
        rows={rows}
        columns={columns}
        localeText={{
          footerRowSelected: (count: number) =>
            `${count} worker${makePlural(count)} selected`,
        }}
        onRowSelectionModelChange={handleSelectWorkers}
      />
    </Row>
  )
}
