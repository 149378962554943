import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { AccountStatusBadge, Row } from '../base'

export interface DraggableWorkerListProps {
  selectedWorkerList: PopulatedWorker[]
  setSelectedWorkerList: React.Dispatch<React.SetStateAction<PopulatedWorker[]>>
}

export const DraggableWorkerList = (props: DraggableWorkerListProps) => {
  const { selectedWorkerList, setSelectedWorkerList } = props
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return
    } // Drop outside the list

    const reorderedWorkers = [...selectedWorkerList]
    const [movedWorker] = reorderedWorkers.splice(result.source.index, 1)
    reorderedWorkers.splice(result.destination.index, 0, movedWorker)

    setSelectedWorkerList(reorderedWorkers)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="worker-list">
        {(provided: any) => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {selectedWorkerList.map((worker, index) => (
              <Draggable
                key={worker.id}
                draggableId={worker.id!.toString()}
                index={index}
              >
                {(provided: any, snapshot: any) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card
                      style={{
                        borderColor: theme.colors.Blue10,
                        padding: theme.space.xxs,
                        paddingLeft: theme.space.xs,
                        paddingRight: theme.space.xs,
                        marginBottom: theme.space.xxxs,
                        backgroundColor: snapshot.isDragging
                          ? theme.colors.Orange10
                          : theme.colors.Blue10,
                      }}
                    >
                      <Row alignCenter justifyBetween>
                        <Row alignCenter>
                          <Text
                            variant="h6"
                            style={{ marginRight: theme.space.xs }}
                          >
                            {worker.firstName} {worker.lastName}
                          </Text>
                          <AccountStatusBadge
                            accountStatus={worker.accountStatus.accountStatus}
                            approvalStatusReason={
                              worker.accountStatus.approvalStatusReason
                            }
                            accountSuspensionExpiration={
                              worker.accountStatus.suspensionExpiration
                            }
                          />
                        </Row>
                        <Text variant="body1">{worker.id}</Text>
                      </Row>
                    </Card>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}
