export default function SideNav({
  color = '#66788A',
  size = 24,
}: {
  size?: number
  color?: string
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97266 14.25C4.55844 14.25 4.22266 14.5858 4.22266 15C4.22266 15.4142 4.55844 15.75 4.97266 15.75L4.97266 14.25ZM17 15.75C17.4142 15.75 17.75 15.4142 17.75 15C17.75 14.5858 17.4142 14.25 17 14.25L17 15.75ZM1 8L0.471393 7.46795C0.329687 7.60874 0.25 7.80024 0.25 8C0.25 8.19975 0.329687 8.39126 0.471393 8.53204L1 8ZM17.0186 8.75C17.4328 8.75 17.7686 8.41421 17.7686 8C17.7686 7.58578 17.4328 7.25 17.0186 7.25L17.0186 8.75ZM17.0186 1.75C17.4328 1.75 17.7686 1.41421 17.7686 0.999998C17.7686 0.585785 17.4328 0.249998 17.0186 0.249998L17.0186 1.75ZM9.99047 0.249999C9.57626 0.249999 9.24047 0.585786 9.24047 0.999999C9.24047 1.41421 9.57626 1.75 9.99047 1.75L9.99047 0.249999ZM4.54814 5.53205C4.84198 5.24011 4.84352 4.76523 4.55158 4.47139C4.25964 4.17755 3.78477 4.17601 3.49092 4.46795L4.54814 5.53205ZM3.49092 11.532C3.78477 11.824 4.25964 11.8224 4.55158 11.5286C4.84352 11.2348 4.84198 10.7599 4.54814 10.468L3.49092 11.532ZM4.97266 15.75L17 15.75L17 14.25L4.97266 14.25L4.97266 15.75ZM1 8.75L17.0186 8.75L17.0186 7.25L1 7.25L1 8.75ZM17.0186 0.249998L9.99047 0.249999L9.99047 1.75L17.0186 1.75L17.0186 0.249998ZM1.52861 8.53205L4.54814 5.53205L3.49092 4.46795L0.471393 7.46795L1.52861 8.53205ZM0.471393 8.53204L3.49092 11.532L4.54814 10.468L1.52861 7.46795L0.471393 8.53204Z"
        fill={color}
      />
    </svg>
  )
}
