import { useEligibilityConnections, useLocations } from '@traba/hooks'
import { IneligibleSelect, LoadingSpinner } from '@traba/react-components'

import { EligibilityConnectionMutationPayload } from '@traba/types'
import { Row } from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import { useRoles } from 'src/hooks/useRoles'

type ConnectionProps = {
  workerId: string
  companyId: string
  setPayload: React.Dispatch<
    React.SetStateAction<EligibilityConnectionMutationPayload | undefined>
  >
}

export const EligibilityConnectionContent = (props: ConnectionProps) => {
  const { workerId, companyId, setPayload } = props
  const { state } = useUserContext()
  const email = state.userProfile?.email

  const { eligibilityConnections, globalEligibilityConnection } =
    useEligibilityConnections(companyId, workerId)

  const { roles } = useRoles({ companyId })
  const { locations } = useLocations(companyId)

  const modalReady =
    email &&
    eligibilityConnections &&
    globalEligibilityConnection !== undefined &&
    roles?.length &&
    locations?.length
  return (
    <Row style={{ justifyContent: 'center' }} flexCol>
      {modalReady ? (
        <IneligibleSelect
          eligibilityConnections={eligibilityConnections}
          globalEligibilityConnection={globalEligibilityConnection}
          roles={roles}
          locations={locations}
          workerId={workerId}
          companyId={companyId}
          setPayload={setPayload}
          initiatedBy={email}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Row>
  )
}
