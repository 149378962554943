import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { Company } from '@traba/types'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import { ShiftBadgeContent } from 'src/modals/ShiftBadgeModal/ShiftBadgeContent'

import { getErrorMessage } from 'src/utils/errorUtils'

interface Props {
  company: Company
}

export const CompanyShiftBadges = ({ company }: Props) => {
  const { shiftBadges: companyBadges, refetch: refetchCompanyBadges } =
    useCustomBadges({
      companyId: company.companyId,
    })
  const { showSuccess, showError } = useAlert()

  return (
    <ShiftBadgeContent
      title="Company shift badges"
      existingBadgesTitle="Company existing badges"
      addBadgeTitle="Add badge to company"
      existingBadges={companyBadges}
      description="IMPORTANT: badges added here will be applied to all future shifts of
    this company. If you wish to only add badges to a role or a shift,
    please use role/shift edit flow"
      onAddBadge={async (selectedBadgeToAdd) => {
        try {
          if (selectedBadgeToAdd?.id) {
            await trabaApi.patch(`shift-badge/${selectedBadgeToAdd?.id}/add`, {
              companyId: company.companyId,
            })
          }
          refetchCompanyBadges()
          showSuccess('Successfully added badge to company', 'Success')
        } catch (error) {
          showError(getErrorMessage(error), 'Error adding badge to company')
        }
      }}
      onRemoveBadge={async (selectedExistingBadge) => {
        try {
          await trabaApi.patch(
            `shift-badge/${selectedExistingBadge?.id}/remove`,
            { companyId: company.companyId },
          )
          refetchCompanyBadges()
          showSuccess('Successfully removed badge from company', 'Success')
        } catch (error) {
          showError('Error removing badge from company')
        }
      }}
    />
  )
}
