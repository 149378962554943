import { ListItem, ListItemAvatar, ListItemButton } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ChatSummary } from '@traba/types'
import { memo } from 'react'
import { Avatar, Col } from 'src/components/base'
import { formatTimeDistanceToNow } from 'src/utils/dateUtils'

interface Props {
  chat: ChatSummary
  selected: boolean
  onClick: () => void
  lastChatRef?: React.MutableRefObject<HTMLDivElement | null>
}

const MessageListItem = memo(
  ({ chat, selected, onClick, lastChatRef }: Props) => {
    const {
      profilePictureUrl,
      mostRecentMessageAttachmentUrl,
      mostRecentMessageContent,
      mostRecentMessageSentAt,
      name,
    } = chat

    return (
      <div ref={lastChatRef}>
        <ListItem divider>
          <ListItemButton
            onClick={onClick}
            sx={{ justifyContent: 'space-between' }}
            selected={selected}
          >
            <ListItemAvatar>
              <Avatar src={profilePictureUrl} name={name} size={50} />
            </ListItemAvatar>
            <Col>
              <Text variant="h5">{name}</Text>
              <Text variant="body1" color={theme.colors.MidnightBlue}>
                {`${mostRecentMessageContent} ${
                  mostRecentMessageAttachmentUrl ? '1 Attachment' : ''
                }`}
              </Text>
            </Col>
            {mostRecentMessageSentAt && (
              <Text variant="body1" color={theme.colors.MidnightBlue}>
                {formatTimeDistanceToNow(new Date(mostRecentMessageSentAt))}
              </Text>
            )}
          </ListItemButton>
        </ListItem>
      </div>
    )
  },
)

export default MessageListItem
