import { BusinessComplaint } from '@traba/types'
import { Table } from 'src/components/base'
import IncidentsTableRow from './IncidentsTableRow'

const headers = [
  'Category',
  'Reported At',
  'Needs Manual Invoice Override',
  'Notes',
]

type IncidentsTableProps = {
  businessComplaints: BusinessComplaint[]
}

export default function IncidentsTable(props: IncidentsTableProps) {
  const sortedBusinessComplaints = props.businessComplaints
  sortedBusinessComplaints.sort(
    (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
  )

  return (
    <Table headers={headers}>
      {sortedBusinessComplaints.map((complaint) => {
        return <IncidentsTableRow businessComplaint={complaint} />
      })}
    </Table>
  )
}
