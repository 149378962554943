import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { Card, CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ParentInvoiceGroup, ParentInvoiceGroupStatus } from '@traba/types'
import { useState } from 'react'
import { Dollar } from 'src/assets/svg/icons/Dollar'
import { Modal, Row, Button, Col } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { getErrorMessage } from 'src/utils/errorUtils'

type ArchiveInvoiceGroupModalProps = {
  companyId: string
  group: ParentInvoiceGroup
  isOpen: boolean
  handleClose: () => void
}

export const ArchiveInvoiceGroupModal = (
  props: ArchiveInvoiceGroupModalProps,
) => {
  const { group } = props

  const { showError } = useAlert()
  const { editInvoiceGroup } = useInvoiceGroups(props.companyId)

  const [archiving, setArchiving] = useState(false)

  const handleArchiveClick = async () => {
    setArchiving(true)
    await editInvoiceGroup(
      {
        groupId: props.group.id,
        updateGroupData: { status: ParentInvoiceGroupStatus.ARCHIVED },
      },
      {
        onSettled: () => {
          setArchiving(false)
          props.handleClose()
        },
        onError: (err: any) => {
          showError(getErrorMessage(err), 'Unable to archive invoice group')
        },
      },
    )
  }

  return (
    <Modal
      title="Archive invoice group"
      isOpen={props.isOpen}
      size={MODAL_SIZE.MEDIUM}
      handleClose={props.handleClose}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Col>
          <Text variant="body2">
            Archiving an invoice group will remove it from the dropdown list
            when creating a shift. However, any outstanding shifts remaining in
            the group will still be invoiced as such.
          </Text>

          <Text
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
            variant="h5"
          >
            Invoice group to archive
          </Text>

          <Card>
            <Row alignCenter justifyBetween>
              <Row justifyCenter>
                <CardTile size="56px">
                  <Dollar />
                </CardTile>
                <span style={{ marginLeft: theme.space.sm }}>
                  <Text variant="h5">{group.name}</Text>
                </span>
              </Row>
            </Row>

            {group.description && (
              <div style={{ marginTop: theme.space.xs }}>
                <Text variant="caption">DESCRIPTION</Text>
                <Text>{group.description}</Text>
              </div>
            )}

            <div style={{ marginTop: theme.space.xs }}>
              <Text variant="caption">TYPE</Text>
              <Text>
                {group.recurring
                  ? 'Reusable invoice group'
                  : 'One-time purchase order'}
              </Text>
            </div>
          </Card>
        </Col>

        <Row justifyBetween pt={theme.space.med}>
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.CANCEL}
            onClick={handleArchiveClick}
            loading={archiving}
          >
            Archive
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
