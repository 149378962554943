import { Row } from 'src/components/base'
import styled from 'styled-components'

export const ShiftDetailsWrapper = styled.div`
  margin: 8px;
  width: 100%;
  text-align: left;
  display: flex;
`

export const HeaderWrapper = styled(Row)`
  margin-bottom: 0px;
  margin-right: 32px;
  align-items: center;
`

export const SubWrapper = styled(Row)`
  margin-bottom: 12px;
  margin-right: 32px;
`
