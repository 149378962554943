import { ListItem } from '@mui/material'
import { Col, Link, Row, Text, Toggle } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift } from '@traba/types'
import { format } from 'date-fns-tz'
import { useShowCompletedShifts } from 'src/hooks/useShowCompletedShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import * as S from 'src/screens/FieldMonitorScreen/styles'
import styled from 'styled-components'

/*
  Lot of similarities between this component and the ShiftList in ShiftRequestCard.tsx. Chose not to refactor that at this time
  as it has some different functionality for multi-select checkboxes vs. simple links needed for this component.
*/

type ShiftListProps = {
  shifts: Shift[]
  currentShiftId: string
}

const StyledListItem = styled(ListItem)`
  flex-wrap: wrap;
  &:hover {
    background-color: ${theme.colors.Grey10};
  }
`

export const ShiftList = ({ shifts, currentShiftId }: ShiftListProps) => {
  const { showCompleteShifts, setShowCompleteShifts, validShifts } =
    useShowCompletedShifts(shifts)
  const tz = useTimezonedDates((shifts[0] && shifts[0].timezone) || '')
  const { shortLocation, employerName, shiftRole } = shifts[0]

  return (
    <Col pl={theme.space.xs}>
      <S.CardWrapper>
        <Row
          justifyBetween
          alignCenter
          fullWidth
          px={theme.space.xs}
          py={theme.space.xxxs}
          style={{ backgroundColor: theme.colors.Violet10 }}
          wrap
        >
          <Text variant="h6">
            {employerName} | {shiftRole} | {shortLocation}
          </Text>
          <Text variant="body2">Shift request: {shifts[0].shiftRequestId}</Text>
          <Toggle
            buttonState={showCompleteShifts}
            label="Show past shifts"
            runOnChange={() => setShowCompleteShifts(!showCompleteShifts)}
            color="transparent"
          />
        </Row>
        {validShifts.map((shift) => {
          const shiftFilledString = `- ${shift.slotsFilled}/${shift.slotsRequested}/${shift.overbookSlotsRequested ? `OB${shift.overbookSlotsRequested}` : ``} filled -`
          return (
            <Row
              key={`${shift.shiftId}-${shift.shiftRequestId}`}
              justifyStart
              alignCenter
              pr={theme.space.xs}
            >
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/field-monitor/${shift.shiftId}`}
              >
                <StyledListItem
                  key={`${shift.shiftId}-${shift.shiftRequestId}_shift_list`}
                  sx={{
                    backgroundColor: 'transparent',
                    wordBreak: 'break-word',
                  }}
                >
                  <Text variant="body1" style={{ fontWeight: 500 }}>
                    {`${format(new Date(shift.startTime), 'E')} - ${tz.getDate(shift.startTime, false)} ${tz.getShiftTime(
                      shift.startTime,
                      shift.endTime,
                    )}`}
                  </Text>
                  <Text variant="body1" ml={theme.space.xxs}>
                    {shiftFilledString}
                  </Text>
                  <Text ml={theme.space.xxs}>( {shift.shiftId}) </Text>
                  {shift.shiftId === currentShiftId && (
                    <Text ml={theme.space.xxs} variant="money">
                      CURRENT SHIFT
                    </Text>
                  )}
                </StyledListItem>
              </Link>
            </Row>
          )
        })}
      </S.CardWrapper>
    </Col>
  )
}
