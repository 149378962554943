import { Close, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, IconButton, ListItemButton } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useSearchInputItems, Field } from 'src/hooks/useSearchInputItems'
import { useFieldsConfig } from '../../../../hooks/useFieldsConfig'

import * as S from './styles'

interface SearchConfigProps {
  isOpen: boolean
  onClose: () => void
  onDrawerClose: (fields: string[]) => void
  showBasicInfo?: boolean
  showShiftInfo?: boolean
  listName: string
}

const isSelected = (selectedFields: string[], infoField: Field) =>
  !!infoField.fixed || selectedFields.includes(infoField.field)

function SettingsList({
  action,
  fieldsList,
  selectedFields,
  title,
}: {
  action: (field: string) => void
  fieldsList: Field[]
  selectedFields: string[]
  title: string
}) {
  const [isOpen, setIsOpen] = useState(true)
  const selectedFieldsCount = fieldsList.filter((field) =>
    selectedFields.includes(field.field),
  ).length

  return (
    <>
      <ListItemButton
        onClick={() => setIsOpen(!isOpen)}
        style={{
          borderTopLeftRadius: theme.space.xxs,
          borderTopRightRadius: theme.space.xxs,
        }}
      >
        <ListItemText secondary={`${title} (${selectedFieldsCount})`} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={S.ListStyle}>
          {fieldsList.map((infoField: Field) => (
            <ListItemButton
              key={infoField.field}
              onClick={() => action(infoField.field)}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              disabled={!!infoField.fixed}
            >
              <Checkbox
                checked={isSelected(selectedFields, infoField)}
                disableRipple
              />
              <ListItemText secondary={infoField.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

const SearchConfigDrawer = ({
  isOpen,
  onClose,
  onDrawerClose,
  showBasicInfo,
  showShiftInfo,
  listName,
}: SearchConfigProps) => {
  const [selectedFields, setSelectedFields] = useFieldsConfig(listName)
  const {
    basicInfoFields,
    advancedInfoFields,
    metricsInfoFields,
    shiftBasedFields,
  } = useSearchInputItems()
  const handleToggle = (field: string) => {
    const currentIndex = selectedFields.indexOf(field)
    const newSelectedFields = [...selectedFields]
    if (currentIndex === -1) {
      newSelectedFields.push(field)
    } else {
      newSelectedFields.splice(currentIndex, 1)
    }
    setSelectedFields(newSelectedFields)
  }

  const handleClose = () => {
    onClose()
    onDrawerClose(selectedFields)
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <S.DrawerContainer>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          sx={{
            position: 'absolute',
            top: theme.space.xxs,
            right: theme.space.xxs,
          }}
        >
          <Close />
        </IconButton>
        <List
          subheader={
            <S.SubHeaderWrapper>
              <Text variant="h5">Worker search fields</Text>
              <Text variant="label" my={theme.space.xs}>
                Select the filters you want to use on the search, you can always
                come back and change them at any time.
              </Text>
            </S.SubHeaderWrapper>
          }
        >
          {showBasicInfo && (
            <SettingsList
              action={handleToggle}
              fieldsList={basicInfoFields}
              selectedFields={selectedFields}
              title="Basic info"
            />
          )}
          {showShiftInfo && (
            <SettingsList
              action={handleToggle}
              fieldsList={shiftBasedFields}
              selectedFields={selectedFields}
              title="Shift info"
            />
          )}
          <SettingsList
            action={handleToggle}
            fieldsList={advancedInfoFields}
            selectedFields={selectedFields}
            title="Advanced info"
          />
          <SettingsList
            action={handleToggle}
            fieldsList={metricsInfoFields}
            selectedFields={selectedFields}
            title="Worker metrics"
          />
        </List>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default SearchConfigDrawer
