import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useCompanies } from 'src/hooks/useCompanies'
import { useRegions } from 'src/hooks/useRegions'
import { useShiftTags } from 'src/hooks/useShiftTags'
import FieldMonitor from './FieldMonitor'

export const FieldMonitorScreen = () => {
  const { companies = [], isLoading: companiesLoading } = useCompanies({
    isApproved: true,
    identifiersOnly: true,
  })
  const { regions = [], isLoading: regionsLoading } = useRegions()
  const { shiftTagMenuItems } = useShiftTags()

  if (companiesLoading || regionsLoading) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={theme.space.xxxl} />
      </Row>
    )
  }

  return (
    <MainLayout title="Field Monitor">
      <FieldMonitor
        companies={companies}
        regions={regions}
        shiftTagMenuItems={shiftTagMenuItems}
      />
    </MainLayout>
  )
}

export default FieldMonitorScreen
