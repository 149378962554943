import { theme } from '@traba/theme'
import { CostCenterType } from '@traba/types'
import React, { useCallback } from 'react'
import {
  ModalPaddingContainer,
  Card,
  Button,
  ButtonVariant,
  Row,
  Text,
} from '../base-components'
import { CostCenter } from './CostCenter'

export type ArchiveCostCenterModalProps = {
  costCenter: CostCenterType
  deleteCostCenter: (id: string) => void
  handleClose: () => void
}

export function ArchiveCostCenter(
  props: ArchiveCostCenterModalProps,
): React.JSX.Element {
  const { deleteCostCenter, handleClose } = props

  const handleArchiveClick = useCallback(() => {
    deleteCostCenter(props.costCenter.id)
    handleClose()
  }, [deleteCostCenter, handleClose, props.costCenter.id])

  return (
    <ModalPaddingContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.space.xs,
        }}
      >
        <Text variant="h2">Archive Cost Center</Text>
      </div>
      <Text variant="body2">
        Archiving a cost center will remove it from the dropdown list when
        creating a shift. However, the cost center information will continue to
        show up for any previous shift it was tied to.
      </Text>

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Cost Center to Archive
      </Text>
      <Card
        style={{
          marginTop: theme.space.xxs,
          width: '100%',
          display: 'flex',
        }}
      >
        <CostCenter costCenter={props.costCenter} />
      </Card>
      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={props.handleClose}
        >
          Back
        </Button>
        <Button variant={ButtonVariant.CANCEL} onClick={handleArchiveClick}>
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
