import { theme } from '@traba/theme'
import { isEmpty } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import { Button, Col, Icon, Input, Row } from '../base'
import { ButtonVariant } from '../base/Button/types'
import * as S from './LocationMedia.styles'

type LocationMediaProps = {
  mediaFiles: string[]
  setMediaFiles: Dispatch<SetStateAction<string[]>>
}

const basicUrlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // optional protocol
    '((www|i)\\.)?' + // optional subdomain (www or i)
    '([a-z\\d]([a-z\\d-]*[a-z\\d])*)' + // domain name
    '(\\.[a-z]{2,})' + // dot followed by extension
    '(\\/.*)?$', // optional path
  'i',
)

export const LocationMedia = (props: LocationMediaProps) => {
  const { mediaFiles, setMediaFiles } = props

  const [newMediaUrl, setNewMediaUrl] = useState('')
  const [urlError, setUrlError] = useState(false)

  const onAddFile = () => {
    if (!basicUrlPattern.test(newMediaUrl)) {
      setUrlError(true)
      return
    }
    setMediaFiles((currentFiles) => [...currentFiles, newMediaUrl])
    setNewMediaUrl('')
  }

  const onRemoveFile = (i: number) => {
    setMediaFiles((currentFiles) =>
      currentFiles.filter((_, index) => i !== index),
    )
  }

  return (
    <Col>
      <Row style={{ marginBottom: theme.space.sm }}>
        <Col>
          <Input
            full
            label="Media URL"
            containerStyle={{ marginTop: theme.space.xs }}
            value={newMediaUrl}
            onChange={(event: any) => {
              setUrlError(false)
              setNewMediaUrl(event.target.value)
            }}
            inputStatus={urlError ? 3 : 1}
            errorMessage="Make sure that this is a valid URL"
          />
        </Col>
        <Button
          variant={ButtonVariant.FILLED}
          onClick={(e) => {
            e.preventDefault()
            onAddFile()
          }}
          style={{ marginLeft: theme.space.xxs, marginTop: theme.space.xs }}
          disabled={urlError}
        >
          Add
        </Button>
      </Row>
      <Row style={{ overflowX: 'auto' }}>
        {!isEmpty(mediaFiles) &&
          mediaFiles.map((file, i) => (
            <S.ImagePreviewWrapper key={i}>
              <S.ImagePreview src={file} />
              <S.DeleteButton
                slim
                variant={ButtonVariant.OUTLINED}
                onClick={(e) => {
                  e.preventDefault()
                  onRemoveFile(i)
                }}
              >
                <Icon name={'trash'} width={12} height={12} />
              </S.DeleteButton>
            </S.ImagePreviewWrapper>
          ))}
      </Row>
    </Col>
  )
}
