import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InternalUser,
  NotificationSettings,
  SentinelNotification,
} from '@traba/types'
import { useState } from 'react'
import { Col, Icon, Link, Row } from 'src/components/base'
import { getShiftTimeString } from 'src/utils/dateUtils'
import { getLocalTimezone } from 'src/utils/dateUtils'
import styled from 'styled-components'
import { SentinelNotificationsList } from './SentinelNotificationsList'

export const ShiftWithSentinelNotifications = ({
  shiftId,
  notifications,
  selectedNotificationId,
  setSelectedNotificationId,
  shiftNotificationSettings,
  userNotificationSettings,
  internalUser,
}: {
  shiftId: string
  notifications: SentinelNotification[]
  selectedNotificationId: string
  setSelectedNotificationId: React.Dispatch<React.SetStateAction<string>>
  shiftNotificationSettings: NotificationSettings
  userNotificationSettings: NotificationSettings
  internalUser: InternalUser
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const shift = notifications?.[0]?.data?.shift
  const shiftTimeString =
    shift.startTime && shift.endTime
      ? getShiftTimeString(
          shift.startTime,
          shift.endTime,
          shift.timezone ?? getLocalTimezone(),
        )
      : undefined
  const slotsFilledString = `${shift?.slotsFilled}/${shift?.slotsRequested} slots filled`

  const shiftNameString = `${shift?.roleName} • ${shift?.employerName} • ${slotsFilledString}`

  return (
    <Col key={shiftId}>
      <Row
        style={{
          background: `linear-gradient(to right, rgb(230,204,255,0.5), rgb(135,147,255,0.5))`, // Violet20 & TrabaBlue @ 50% opacity
          border: `1px solid ${theme.colors.TrabaBlue}`,
        }}
        py={theme.space.xsmed}
        pl={theme.space.sm}
        pr={theme.space.xl}
        justifyBetween
        alignCenter
      >
        <Row gap={theme.space.xxs}>
          {(shiftNotificationSettings.shiftSilenced ||
            userNotificationSettings.shiftSilenced) && (
            <Tooltip title="Shift notifications are muted">
              <div>
                <Row
                  justifyCenter
                  alignCenter
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    backgroundColor: theme.colors.Violet20,
                    color: theme.colors.Violet,
                  }}
                >
                  <Icon
                    type="svg"
                    name="volumeSlash"
                    color={theme.colors.Grey50}
                  />
                </Row>
              </div>
            </Tooltip>
          )}
          <Col>
            <Link to={`/field-monitor/${shiftId}`}>
              <StyledText variant="h6">{shiftNameString}</StyledText>
            </Link>
            {shiftTimeString && (
              <Text
                variant="body1"
                color={theme.colors.MidnightBlue}
                style={{
                  fontSize: '13px',
                  marginTop: -theme.space.xxxs,
                  marginBottom: -theme.space.xxxs,
                }}
              >
                {shiftTimeString}
              </Text>
            )}
          </Col>
        </Row>
        <Icon
          name={isExpanded ? 'chevronUp' : 'chevronDown'}
          type="svg"
          style={{
            height: 16,
            width: 16,
            cursor: 'pointer',
          }}
          color={theme.colors.MidnightBlue}
          onClick={() => {
            setIsExpanded((isExpanded) => !isExpanded)
          }}
        />
      </Row>
      {isExpanded && (
        <SentinelNotificationsList
          notifications={notifications}
          selectedNotificationId={selectedNotificationId}
          setSelectedNotificationId={setSelectedNotificationId}
          inlineActions={true}
          shiftNotificationSettings={shiftNotificationSettings}
          userNotificationSettings={userNotificationSettings}
          shiftId={shiftId}
          internalUser={internalUser}
        />
      )}
    </Col>
  )
}

const StyledText = styled(Text)`
  color: ${theme.colors.MidnightBlue};
  &:hover {
    color: ${theme.colors.brand};
  }
`
