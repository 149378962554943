import { useAlert } from '@traba/context'
import { Text, Dialog } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CountryCode } from '@traba/types'
import { BackgroundCheckFlow, Region, RegionStatus } from '@traba/types'
import { TierLevel } from '@traba/types'
import React, { useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Button, Col, Input, Modal, Row, Select } from '../../components/base'
import Checkbox from '../../components/base/Checkbox'
import Divider from '../../components/base/Divider'
import { NumberInput } from '../../components/base/Input/NumberInput'
import { MODAL_SIZE } from '../../components/base/Modal/types'
import {
  bgcFlowTypeItems,
  useRegions,
  workerTierItems,
} from '../../hooks/useRegions'
import { getInvalidPostalCodes } from '../../utils/helperUtils'
const INITIAL_REGION_FORM: Omit<Region, 'regionCode'> = {
  regionId: '',
  postalCodes: [],
  acceptingSignups: false,
  backgroundCheckFlow: BackgroundCheckFlow.early,
  countryCode: CountryCode.US,
  displayName: '',
  status: RegionStatus.inactive,
  minimumAcceptedTier: TierLevel.UNPROVEN,
  unprovenWorkerThreshold: 1,
}

function CreateRegionModal({
  show,
  handleClose,
  handleSetRegion,
}: {
  show: boolean
  handleClose: () => void
  handleSetRegion: (regionId: string) => void
}) {
  const [regionForm, setRegionForm] =
    useState<Omit<Region, 'regionCode'>>(INITIAL_REGION_FORM)
  const [hasInvalidPostalCodes, setHasInvalidPostalCodes] = useState('')
  const [duplicatedPostalCodes, setDuplicatedPostalCodes] = useState<
    { regionId: string; postalCode: string }[]
  >([])
  const [createReferralIncentive, setCreateReferralIncentive] = useState(false)
  const [isFetchingRegions, setIsFetchingRegions] = useState(false)
  const { createRegion, isCreating, findPostalCodesInOtherRegions } =
    useRegions()
  const { showError, showSuccess } = useAlert()

  const postalCodeValidationText = isFetchingRegions
    ? 'Validating Postal Codes'
    : duplicatedPostalCodes.length
      ? 'Some postal codes are already present in other regions'
      : ''
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleCreateRegion = async () => {
    if (hasInvalidPostalCodes) {
      return
    }
    setIsFetchingRegions(true)
    const duplicatedCodes = await findPostalCodesInOtherRegions(
      regionForm.postalCodes,
    )
    setIsFetchingRegions(false)
    if (duplicatedCodes.length) {
      setDuplicatedPostalCodes(duplicatedCodes)
      showError('Some postal codes are present in other regions.')
      return
    }
    showSuccess('Postal codes were validated successfully!')
    await createRegion(regionForm, createReferralIncentive)
    // Set the main screen to the new region
    handleSetRegion(regionForm.regionId)
    setRegionForm(INITIAL_REGION_FORM)
    handleClose()
  }

  // build the select items from the type CountryCode type
  const countryCodeItems = Object.keys(CountryCode).map((key) => {
    const value = CountryCode[key as keyof typeof CountryCode]
    return {
      value: value.toString(),
      label: key,
    }
  })

  const handleFormUpdate = (key: keyof Region, value: string | number) => {
    let valueToSet: string | string[] | number = value
    if (key === 'postalCodes' && typeof value === 'string') {
      const postalCodesInput = value.split(',').map((p: string) => p.trim())
      const invalidPostalCodes = getInvalidPostalCodes(postalCodesInput)
      setHasInvalidPostalCodes(invalidPostalCodes.join(', '))
      valueToSet = postalCodesInput
    }
    if (key === 'unprovenWorkerThreshold' && typeof value === 'number') {
      valueToSet = value < 0 ? 0 : value > 1 ? 1 : value
    }
    setRegionForm((prev) => ({ ...prev, [key]: valueToSet }))
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleDialogConfirm = async () => {
    await handleCreateRegion()
    setIsDialogOpen(false)
  }

  return (
    <Modal
      isOpen={show}
      handleClose={handleClose}
      size={MODAL_SIZE.LARGE}
      title="Create new region"
    >
      <Col style={{ flex: 1 }}>
        <Col my={theme.space.xxs}>
          <Text variant="h6">Region ID</Text>
          <Input
            placeholder="Region id (e.g. miami-fl)"
            value={regionForm.regionId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormUpdate('regionId', e.target.value)
            }
            full
          />
          {!regionForm.regionId && (
            <Text variant="error">Region ID is required</Text>
          )}
        </Col>
        <Col my={theme.space.xxs}>
          <Text variant="h6">Display name</Text>
          <Input
            placeholder="Display name"
            value={regionForm.displayName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormUpdate('displayName', e.target.value)
            }
            full
          />
          {!regionForm.displayName && (
            <Text variant="error">Display Name is required</Text>
          )}
        </Col>
        <Col my={theme.space.xxs}>
          <Text variant="h6">Country code </Text>
          <Select
            fullWidth
            value={regionForm.countryCode.toString()}
            handleSelect={(value) => handleFormUpdate('countryCode', value)}
            menuItems={countryCodeItems}
          />
        </Col>
        <Col my={theme.space.xxs}>
          <Text variant="h6">Postal codes</Text>
          <Input
            rows={3}
            type="textarea"
            className="xs-12"
            value={
              regionForm.postalCodes.length
                ? regionForm.postalCodes.join(',')
                : ''
            }
            maxLength={5000}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleFormUpdate('postalCodes', e.target.value)
            }
            full
          />
          {hasInvalidPostalCodes && (
            <Text variant="error" mt={theme.space.xs}>
              Invalid postal codes: {hasInvalidPostalCodes}
            </Text>
          )}
          {duplicatedPostalCodes.length > 0 && (
            <Col style={{ flex: 1 }}>
              <Text variant="body1" mt={theme.space.xs}>
                The following postal codes are already in use in other regions,
                please remove from the update or the original region and try
                again:
              </Text>
              <Col
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: theme.space.xxs,
                }}
                px={theme.space.xs}
                py={theme.space.xs}
              >
                {duplicatedPostalCodes.map((p) => (
                  <Row key={p.postalCode}>
                    <Text variant="error" mt={theme.space.xs}>
                      {p.postalCode} - {p.regionId}
                    </Text>
                  </Row>
                ))}
              </Col>
            </Col>
          )}
          {!regionForm.postalCodes.length && (
            <Text variant="error">Postal codes are required</Text>
          )}
        </Col>
        <Divider />
        <Text my={theme.space.xs} variant="h5">
          Launch region settings
        </Text>
        <Col mb={theme.space.xxs}>
          <Text my={theme.space.xxs} variant="h7">
            Background check type
          </Text>
          <Select
            fullWidth
            value={regionForm.backgroundCheckFlow || ''}
            handleSelect={(value) =>
              handleFormUpdate('backgroundCheckFlow', value)
            }
            menuItems={bgcFlowTypeItems}
          />
        </Col>
        <Col my={theme.space.xxs}>
          <Text my={theme.space.xxs} variant="h7">
            Minimum worker tier
          </Text>
          <Select
            fullWidth
            value={regionForm.minimumAcceptedTier || ''}
            handleSelect={(value) =>
              handleFormUpdate('minimumAcceptedTier', value)
            }
            menuItems={workerTierItems}
          />
        </Col>
        <Row my={theme.space.xs} fullWidth justifyBetween>
          <Text my={theme.space.xxs} variant="h7">
            Unproven threshold
          </Text>
          <NumberInput
            value={regionForm.unprovenWorkerThreshold}
            setValue={(value?: number) =>
              handleFormUpdate('unprovenWorkerThreshold', value || 0)
            }
            decimals={2}
            containerStyle={{ width: 70 }}
            width="70px"
            min={0}
            max={1}
            step={0.01}
          />
        </Row>
        <Divider />
        <Row
          mb={theme.space.xs}
          fullWidth
          justifyBetween
          alignCenter
          style={{ cursor: 'pointer' }}
        >
          <Col>
            <Text my={theme.space.xxs} variant="h7">
              Create region referral incentive
            </Text>
            <Text my={theme.space.xxs} variant="label">
              The default incentive is "Complete 1 shift get $20.00"
            </Text>
          </Col>
          <Checkbox
            checked={createReferralIncentive}
            style={{ marginRight: theme.space.med }}
            onChange={() =>
              setCreateReferralIncentive(!createReferralIncentive)
            }
          />
        </Row>
        <Divider />
        <Col py={theme.space.xs}>
          <Text my={theme.space.xs} variant="body1">
            Creating a region will also create the related Slack channels and
            automatically assign the system messages
          </Text>
          <Text my={theme.space.xs} variant="body1">
            The region will be created with Accepting Signups disable and status
            Inactive, you need to launch it manually
          </Text>
        </Col>
        <Divider />
      </Col>
      <Row fullWidth justifyEnd py={theme.space.xs} px={theme.space.xs}>
        <Col>
          <Text variant="h6" mt={theme.space.xs}>
            {postalCodeValidationText}
          </Text>
        </Col>

        <Button
          onClick={() => setIsDialogOpen(true)}
          variant={ButtonVariant.PURPLEGRADIENT}
          disabled={
            !!hasInvalidPostalCodes.length ||
            isCreating ||
            !regionForm.displayName ||
            !regionForm.regionId ||
            isFetchingRegions
          }
          loading={isCreating}
        >
          Create region
        </Button>
        <Dialog
          dialogTitle={'Zip Code Validation'}
          open={isDialogOpen}
          onClose={() => handleDialogClose()}
          onConfirm={async () => await handleDialogConfirm()}
        >
          Please confirm that you have verified that the zip codes in the new
          region are areas where Traba can operate.
        </Dialog>
      </Row>
    </Modal>
  )
}

export default CreateRegionModal
