import { Menu, MenuItem } from '@mui/material'
import { ExtendedShift } from '@traba/types'
import React from 'react'
import { Button, Icon } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { UserRolePermission } from 'src/context/user/types'
import { useDownloadShiftReport } from 'src/hooks/useDownloadShiftReport'
import { usePermissions } from 'src/hooks/usePermissions'
import DownloadReportModal from 'src/modals/DownloadReport/DownloadReport'

export default function DownloadMenu({ shift }: { shift: ExtendedShift }) {
  const [anchorEl, setAnchorEl] = React.useState<
    null | SVGElement | HTMLButtonElement
  >(null)
  const downloadReportModal = useModal()

  const open = !!anchorEl

  const downloadWorkerShiftsAccess = usePermissions([
    UserRolePermission.DownloadWorkerShift,
  ])

  function handleClick(
    event: React.MouseEvent<SVGElement | HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const { getCsvReport, getPdfReport, getTimesheetReport, isLoading } =
    useDownloadShiftReport(shift.shiftId)

  return (
    <>
      <Button
        slim
        variant={ButtonVariant.OUTLINED}
        iconPadding={`0 10px 0 0`}
        leftIcon={<Icon name="download" />}
        onClick={handleClick}
        disabled={!downloadWorkerShiftsAccess}
        loading={isLoading}
      >
        Download
      </Button>

      {/* MENU ITEMS */}
      <Menu
        id={`shift-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `shift-menu`,
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            getCsvReport()
          }}
        >
          Download Shift CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            getPdfReport()
          }}
        >
          Download Shift PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            downloadReportModal.open()
          }}
        >
          Download Company Report
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            getTimesheetReport()
          }}
        >
          Download Traba Time Sheet
        </MenuItem>
      </Menu>

      <DownloadReportModal {...downloadReportModal} shift={shift} />
    </>
  )
}
