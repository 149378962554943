import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus } from '@traba/types'
import {
  ChatEndedStatus,
  ChatMessageDeletedStatus,
  ChatSummary,
} from '@traba/types'
import { Company } from '@traba/types'
import { Worker } from '@traba/types'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Avatar, Icon, Modal, Row } from 'src/components/base'
import { Dropdown } from 'src/components/base/Dropdown'

import { updateChatMessage, useMessages } from 'src/hooks/useChatMessages'
import { updateChat } from 'src/hooks/useChats'
import { ConnectionContent } from 'src/modals/ManageWorkerModal/ManageWorkerModalContent'
import { openInNewTab } from 'src/utils/helperUtils'
import MessageItem from './MessageItem'

interface Props {
  handleClickChatImage: (imageUrls: string[]) => void
  setSelectedChat: React.Dispatch<React.SetStateAction<ChatSummary | undefined>>
  company?: Company
  worker?: Worker & AccountStatus
  selectedChat: ChatSummary
  refetchChats: () => void
}

export const MessagesPanel = ({
  handleClickChatImage,
  refetchChats,
  selectedChat,
  setSelectedChat,
  company,
  worker,
}: Props) => {
  const {
    summaryId,
    name,
    profilePictureUrl,
    companyEndedChatStatus,
    recipient,
  } = selectedChat
  const { messages, refetch, isLoading } = useMessages(summaryId)
  const isCompanyChats = !!company
  const { handleError, showSuccess } = useAlert()
  const [isChatEnded, setIsChatEnded] = useState(false)
  const [showConnectionModal, setShowConnectionModal] = useState(false)
  const companyId = isCompanyChats
    ? company.companyId
    : _.get(recipient, 'companyId')
  const workerId = isCompanyChats
    ? _.get(recipient, 'workerId')
    : worker?.workerId

  useEffect(() => {
    setIsChatEnded(companyEndedChatStatus === ChatEndedStatus.ENDED)
  }, [companyEndedChatStatus, selectedChat])

  const onEndOrOpenChat = async (companyEndedChatStatus: ChatEndedStatus) => {
    try {
      await updateChat({ companyEndedChatStatus }, [summaryId])
      refetchChats()
      showSuccess('This chat has been updated', 'Success')
      setIsChatEnded(companyEndedChatStatus === ChatEndedStatus.ENDED)
    } catch (error) {
      handleError(
        error,
        'End or open chat for business',
        'Failed to end chat, please try again',
        'Something went wrong',
      )
    }
  }
  const actions = [
    {
      label: 'End chat for business',
      handleClick: () => onEndOrOpenChat(ChatEndedStatus.ENDED),
    },
    {
      label: 'Manage worker and company connection',
      handleClick: () => setShowConnectionModal(true),
    },
  ]

  const onDeleteMessage = async (messageId: string) => {
    try {
      await updateChatMessage(
        { deletedStatus: ChatMessageDeletedStatus.DELETED },
        messageId,
      )
      showSuccess('Message has been deleted', 'Success')
      refetch()
    } catch (error) {
      handleError(
        error,
        'onDeleteMessage',
        'Failed to delete message, please try again',
        'Something went wrong',
      )
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        borderLeftColor: theme.colors.Grey30,
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        width: '60%',
        marginLeft: theme.space.xs,
        paddingLeft: theme.space.xs,
      }}
    >
      <Row
        justifyBetween
        alignCenter
        py={theme.space.xxxs}
        style={{
          backgroundColor: theme.colors.White,
          zIndex: 1,
          borderBottomColor: theme.colors.Grey20,
          borderBottomWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <CardTile
          size="40px"
          onClick={() => {
            setSelectedChat(undefined)
          }}
        >
          <Icon name="chevronRight" />
        </CardTile>
        <Row
          alignCenter
          onClick={() => {
            if (isCompanyChats) {
              openInNewTab(`/workers/${workerId}`)
            } else {
              openInNewTab(`/companies/${companyId}`)
            }
          }}
        >
          <Avatar src={profilePictureUrl} name={name} size={50} />
          <Text variant={'h5'} ml={theme.space.xs}>
            {name}
          </Text>
        </Row>
        <Dropdown
          dropdownOptions={actions}
          orientation={'left'}
          containerStyling={{
            marginRight: theme.space.med,
          }}
        />
      </Row>
      {isLoading && (
        <Row justifyCenter mt={theme.space.med}>
          <CircularProgress size={40} style={{ alignSelf: 'center' }} />
        </Row>
      )}
      <div
        style={{
          overflow: 'auto',
          height: 750,
          flexDirection: 'column-reverse',
          display: 'flex',
        }}
      >
        {messages?.map((message, index) => (
          <MessageItem
            onDeleteMessage={onDeleteMessage}
            message={message}
            key={index}
            handleClickChatImage={handleClickChatImage}
            isCompanyChats={isCompanyChats}
            companyLogoUrl={
              isCompanyChats ? company?.companyLogo : profilePictureUrl
            }
            workerUrl={isCompanyChats ? profilePictureUrl : worker?.photoUrl}
          />
        ))}

        <Text
          style={{ textAlign: 'center' }}
          variant="body2"
          mb={theme.space.xxs}
        >
          - This is the beginning of all messages. -
        </Text>
      </div>
      {isChatEnded && (
        <Text style={{ textAlign: 'center' }} variant="body2">
          This chat has ended and the worker won't be able to send messages
          anymore.
          <Text
            variant="link"
            onClick={() => {
              onEndOrOpenChat(ChatEndedStatus.NONE)
            }}
            ml={theme.space.xxs}
          >
            Reopen chat
          </Text>
        </Text>
      )}
      {workerId && companyId && (
        <Modal
          handleClose={() => setShowConnectionModal(false)}
          isOpen={showConnectionModal}
        >
          <ConnectionContent
            handleClose={() => setShowConnectionModal(false)}
            workerId={workerId}
            companyId={companyId}
          />
        </Modal>
      )}
    </div>
  )
}
