export type RoboCallStatus =
  | 'queued'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'no-answer'
  | 'canceled'

export enum RobocallSubject {
  PreShiftConfirmationCall = 'PRE_SHIFT_CONFIRMATION_CALL',
}

export enum CallAnsweredBy {
  Human = 'human',
  Machine = 'machine',
  MachineStart = 'machine_start',
  Fax = 'fax',
  Unknown = 'unknown',
  MachineEndBeep = 'machine_end_beep',
  MachineEndSilence = 'machine_end_silence',
  MachineEndOther = 'machine_end_other',
}

export type RobocallDto = {
  twilioSid: string
  fromNumber: string
  toNumber: string
  status: RoboCallStatus
  answeredBy?: CallAnsweredBy
}
