import { theme } from '@traba/theme'
import React, { useState } from 'react'
import { Modal } from 'src/components/base'
import { Button, Input, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'

interface AddCustomVettingQuestionModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (question: string) => void
}

export const AddCustomVettingQuestionModal: React.FC<
  AddCustomVettingQuestionModalProps
> = ({ isOpen, onClose, onSubmit }) => {
  const [question, setQuestion] = useState('')

  const handleSubmit = () => {
    onSubmit(question)
    setQuestion('')
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      size={MODAL_SIZE.EXTRA_SMALL}
      title="Add Custom Vetting Question"
      style={{ minHeight: 'auto' }}
    >
      <Row flexCol fullWidth>
        <Input
          type="textarea"
          label="Question"
          value={question}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setQuestion(e.target.value.slice(0, 200))
          }
          placeholder="Enter your custom vetting question... (max 200 characters)"
          rows={1}
          full
          maxLength={200}
        />
        <Row justifyEnd mt={theme.space.sm}>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={onClose}
            style={{ marginRight: theme.space.xs }}
          >
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleSubmit}
            disabled={!question.trim()}
          >
            Create
          </Button>
        </Row>
      </Row>
    </Modal>
  )
}
