import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Badge, LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, Role } from '@traba/types'
import { formatTimeSince, getLocalTimezone, truncateString } from '@traba/utils'
import React from 'react'
import { Button, CopyTextIcon, Icon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { VettingCampaign } from 'src/hooks/useVettingCampaigns'
import { VettingCampaignActions } from './VettingCampaignActions'

const LastSyncCell = ({ value }: { value: string }) => {
  if (!value) {
    return <div>-</div>
  }

  const localTime = useTimezonedDates(getLocalTimezone()).getDateTime(
    new Date(value),
  )
  return (
    <div title={localTime}>{localTime ? formatTimeSince(localTime) : '-'}</div>
  )
}

export const VettingCampaignSearchResults = ({
  isLoading,
  error = '',
  campaigns = [],
}: any) => {
  const numberOfResults = campaigns.length
  const GOOGLE_SHEETS_URL_PREFIX = 'https://docs.google.com/spreadsheets/d/'

  const columns: GridColDef[] = [
    { field: 'date', headerName: 'Creation Date', flex: 1 },
    { field: 'title', headerName: 'Campaign Name', flex: 1 },
    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      flex: 1,
      renderCell: (params) => (
        <Text>
          {truncateString(params.value)}...
          <CopyTextIcon textToCopy={params.value} />
        </Text>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/companies/${params.row.companyId}`}>
          <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
            {params.value}
          </Button>
        </Link>
      ),
    },
    {
      field: 'role',
      headerName: 'Role(s)',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'regions',
      headerName: 'Region ID(s)',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Row alignCenter wrap style={{ gap: theme.space.xxs }}>
          {params.value.map((regionId: string) => (
            <Badge
              key={regionId}
              variant="info"
              title={regionId}
              style={{ whiteSpace: 'nowrap' }}
            />
          ))}
        </Row>
      ),
    },
    {
      field: 'campaignLink',
      headerName: 'Link',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Row
          alignCenter
          mt={theme.space.xxxs}
          mb={theme.space.xxxs}
          onClick={() => {
            window.open(params.value, '_blank', 'noopener,noreferrer')
          }}
          style={{ cursor: 'pointer' }}
        >
          <Text color={theme.colors.Violet} mr={theme.space.xxs}>
            GSheets
          </Text>
          <Icon name="link" />
        </Row>
      ),
    },
    {
      field: 'lastSync',
      headerName: 'Last Sync',
      flex: 1,
      renderCell: (params) => <LastSyncCell value={params.value} />,
    },
    {
      field: 'actions',
      flex: 1,
      sortable: false,
      headerName: ' ',
      renderCell: (params) => {
        const creationDate = new Date(params.row.date)
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
        const isOlderThanSevenDays = creationDate < sevenDaysAgo
        return (
          <VettingCampaignActions
            campaignId={params.row.campaignId}
            disabled={isOlderThanSevenDays}
          />
        )
      },
    },
  ]

  const rows = campaigns.map((campaign: VettingCampaign) => ({
    id: campaign.id,
    campaignId: campaign.id,
    title: campaign.title,
    date: new Date(campaign.createdAt).toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }),
    companyId: campaign.companies?.length
      ? campaign.companies[0].companyId
      : '',
    company: campaign.companies
      .map((c: Pick<Company, 'employerName' | 'companyId'>) => c.employerName)
      .join(', '),
    role: campaign.roles
      .map((r: Pick<Role, 'roleName'>) => r.roleName)
      .join(', '),
    regions: campaign.regions,
    campaignLink: GOOGLE_SHEETS_URL_PREFIX + campaign.googleSheetsId,
    lastSync: campaign.lastGSheetSyncAt,
  }))

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <Text variant="error" role="alert">
        {error}
      </Text>
    )
  }

  return (
    <Row flexCol mt={theme.space.sm}>
      <Text variant="h6" mb={theme.space.xs}>
        {numberOfResults} {numberOfResults === 1 ? 'result' : 'results'} found
      </Text>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-cell': {
            py: 1,
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          width: '100%',
          overflow: 'auto',
          minWidth: '1000px',
        }}
      />
    </Row>
  )
}
