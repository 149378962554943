import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { ConfirmationDialog, MediaUploader } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftTime, WorkerShiftAsTimesheetRow } from '@traba/types'
import { addDays, isBefore } from 'date-fns'
import Papa, { ParseResult } from 'papaparse'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import {
  unstable_BlockerFunction as BlockerFunction,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom'
import { useModal } from 'src/components/base/Modal/Modal'
import { useTimesheetReport } from 'src/hooks/useTimesheet'
import { useWorkerShiftsForTimesheet } from 'src/hooks/useWorkerShiftsForTimesheet'
import { parseDateTimeString } from 'src/utils/dateUtils'
import { TimesheetAdjustment } from '../timesheet.types'
import { zip } from '../utils'
import TimesheetAdjustModal from './TimesheetAdjustModal'
import TimesheetTable from './TimesheetTable'

function TimesheetTableWrapper({
  shiftIds,
  parsedData,
  setParsedData,
  onAdjust,
}: {
  shiftIds: string[]
  parsedData: WorkerShiftAsTimesheetRow[]
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
  onAdjust: () => void
}) {
  const timesheetAdjustModal = useModal()
  const [workerShiftAdjustments, setWorkerShiftAdjustments] = useState<
    TimesheetAdjustment[]
  >([])
  // States to store the original hours worked and pay before adjustments
  const [prevMinutesWorked, setPrevMinutesWorked] = useState<number>(0)
  const [prevTotalPay, setPrevTotalPay] = useState<number>(0)
  // States to store the new hours worked and pay after adjustments
  const [newMinutesWorked, setNewMinutesWorked] = useState<number>(0)
  const [newTotalPay, setNewTotalPay] = useState<number>(0)
  const {
    workerShifts = [],
    refetch: refetchWorkerShifts,
    isLoading,
  } = useWorkerShiftsForTimesheet(shiftIds)
  const [useBreaks, setUseBreaks] = useState<boolean>(false)

  if (isLoading) {
    return <CircularProgress size={theme.space.xxxl} />
  }
  return (
    <>
      <TimesheetTable
        parsedData={parsedData}
        openModal={timesheetAdjustModal.open}
        setWorkerShiftAdjustments={setWorkerShiftAdjustments}
        setPrevMinutesWorked={setPrevMinutesWorked}
        setPrevTotalPay={setPrevTotalPay}
        setNewMinutesWorked={setNewMinutesWorked}
        setNewTotalPay={setNewTotalPay}
        workerShifts={workerShifts}
        refetchWorkerShifts={refetchWorkerShifts}
        setParsedData={setParsedData}
        useBreaks={useBreaks}
        setUseBreaks={setUseBreaks}
      />
      <TimesheetAdjustModal
        {...timesheetAdjustModal}
        parsedData={parsedData}
        workerShiftAdjustments={workerShiftAdjustments}
        prevMinutesWorked={prevMinutesWorked}
        prevTotalPay={prevTotalPay}
        newMinutesWorked={newMinutesWorked}
        newTotalPay={newTotalPay}
        onAdjust={onAdjust}
        useBreaks={useBreaks}
      />
    </>
  )
}

export default function TrabaTimesheetUpload({
  companyId,
  shiftIds,
}: {
  companyId?: string
  shiftIds?: string[]
}) {
  const { showError } = useAlert()

  const [file, setFile] = useState<File | undefined>()
  const [parsedData, setParsedData] = useState<WorkerShiftAsTimesheetRow[]>([])
  const [parsedShiftIds, setParsedShiftIds] = useState<string[]>([])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { downloadUrl, isLoading } = useTimesheetReport(companyId, shiftIds)

  // confirm client side navigation when there are unsaved changes
  const blocker = useBlocker(
    useCallback<BlockerFunction>(
      ({ currentLocation, nextLocation }) =>
        isDirty && currentLocation.pathname !== nextLocation.pathname,
      [isDirty],
    ),
  )

  // confirm browser navigation when there are unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  const parseTimesheet = useCallback((fileOrUrl?: File | string) => {
    if (!fileOrUrl) {
      return
    }

    if (fileOrUrl instanceof File) {
      setFile(fileOrUrl)
    }

    setIsDirty(true)

    const config = {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: {
        payRate: true,
        unitsWorked: true,
        breakTime: true,
        minimumPaidTime: true,
      },
      complete: (results: ParseResult<WorkerShiftAsTimesheetRow>) => {
        const { data } = results

        const dataWithBreaks = data.map((row) => {
          const breakInKeys = Object.keys(row)
            .filter((key): key is `breakIn_${number}` =>
              /^breakIn_\d+$/.test(key),
            )
            .sort()

          const breakOutKeys = Object.keys(row)
            .filter((key): key is `breakOut_${number}` =>
              /^breakOut_\d+$/.test(key),
            )
            .sort()

          const breaks = zip(breakInKeys, breakOutKeys)
            .filter(
              ([breakInKey, breakOutKey]) =>
                row[breakInKey] && row[breakOutKey],
            )
            .map<ShiftTime>(([breakInKey, breakOutKey]) => {
              const breakIn = row[breakInKey]
              const breakOut = row[breakOutKey]

              let startTime = null
              let endTime = null

              if (row[breakInKey] && row[breakOutKey] && row.clockInDate) {
                startTime = parseDateTimeString(row.clockInDate, breakIn)
                endTime = parseDateTimeString(row.clockInDate, breakOut)
                if (startTime && endTime && isBefore(endTime, startTime)) {
                  addDays(endTime, 1)
                }
              }

              return { startTime, endTime }
            })

          return {
            ...row,
            breaks,
          }
        })

        const shiftIdsSet = new Set(dataWithBreaks.map((d) => d.shiftId))
        setParsedShiftIds(Array.from(shiftIdsSet))
        setParsedData(dataWithBreaks)
      },
    }

    if (fileOrUrl instanceof File) {
      Papa.parse(fileOrUrl, config)
    } else {
      Papa.parse(fileOrUrl, {
        ...config,
        download: true,
      })
    }
  }, [])

  useEffect(() => {
    if (downloadUrl) {
      parseTimesheet(downloadUrl)
    }
  }, [downloadUrl, parseTimesheet])

  const onChangeFile = useCallback(
    (f?: File) => {
      parseTimesheet(f)
    },
    [parseTimesheet],
  )

  const onDeleteFile = useCallback(() => {
    setFile(undefined)
    setParsedData([])
    setParsedShiftIds([])
    setIsDirty(false)
  }, [])

  if (isLoading) {
    return <CircularProgress size={theme.space.xxxl} />
  }

  return (
    <>
      <MediaUploader
        label="Upload Traba Timesheet"
        file={file}
        onChange={onChangeFile}
        onDelete={onDeleteFile}
        onError={(error) => {
          showError(error, 'Something went wrong')
        }}
        fileType="csv"
      />
      {parsedShiftIds.length > 0 && parsedData.length > 0 ? (
        <TimesheetTableWrapper
          shiftIds={parsedShiftIds}
          parsedData={parsedData}
          setParsedData={setParsedData}
          onAdjust={() => setIsDirty(false)}
        />
      ) : (
        <Card
          style={{
            marginTop: theme.space.lg,
            width: '50%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Upload a CSV to get started!
          <br />
          <br />
          You can Download a CSV from a Company Page or a Shift's Details page.
          Once you make your changes to Clock Ins, Clock Outs, etc. Come back to
          compare and submit your changes!
        </Card>
      )}
      <ConfirmationDialog
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset?.()}
        onConfirm={() => blocker.proceed?.()}
        confirmationText="You have unsaved changes. Are you sure you want to leave?"
        onConfirmCTA="Leave"
      />
    </>
  )
}
