import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExtendedShift } from '@traba/types'
import { useState } from 'react'
import {
  Button,
  CopyTextIcon,
  Icon,
  IconButton,
  Link,
  Row,
  ShiftStatusBadge,
} from 'src/components/base'
import { EmploymentTypeBadge } from 'src/components/base/Badge/EmploymentTypeBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from 'src/components/base/Table/DataTable'
import { ShiftNoteDrawer } from 'src/components/ShiftNoteDrawer'
import { shouldShowOverbookWarning } from 'src/utils/shiftUtils'
import { getPayRateString } from 'src/utils/stringUtils'
import { ShiftDateCell, ShiftTimesCell } from './CompanyShiftsTableCells'

// This logic is consistent with the logic in the CollapsibleShiftRow/index.tsx formatStringOrCheck(). Please update both if changing this.
const populateSlotsFilled = (shift: ExtendedShift) => {
  const shiftInfo = `${shift.slotsFilled}/${shift.slotsRequested}`
  const textSuffix = shift.overbookSlotsRequested
    ? `/OB${shift.overbookSlotsRequested}`
    : ''
  const newLine =
    shift.paidBackupSlotsRequested || shift.paidBackupSlotsFilled
      ? `${shift.paidBackupSlotsFilled ?? 0}/PB${shift.paidBackupSlotsRequested ?? 0}`
      : ''

  const warnSlotsFilled =
    shift.slotsFilled < (shift.overbookSlotsRequested ?? shift.slotsRequested)
  const shouldShowWarning = shouldShowOverbookWarning(shift)
  const warningBackgroundColor =
    shift.slotsFilled < shift.slotsRequested
      ? theme.colors.Red40
      : theme.colors.Red10
  const shouldShowRedText =
    shouldShowWarning ?? shift.slotsFilled > shift.slotsRequested

  return (
    <div
      style={{
        backgroundColor: warnSlotsFilled ? warningBackgroundColor : '',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: `${theme.space.xxxs}px ${theme.space.xms}px`,
        minWidth: theme.space.xxxl,
      }}
    >
      <Row>
        {shift.slotsFilled > 0 &&
          shift.slotsFilled === shift.slotsRequested && (
            <Icon name="greenCheck" style={{ marginRight: theme.space.xxxs }} />
          )}
        <Text
          style={{
            color: shouldShowRedText ? theme.colors.Red80 : '',
          }}
        >
          {shiftInfo + textSuffix}
        </Text>
      </Row>
      {newLine && <Row>{newLine}</Row>}
    </div>
  )
}

export default function CompanyShiftsTable({
  shifts,
  selectedRows,
  onSelectRows,
}: {
  shifts: ExtendedShift[]
  selectedRows: TableRow[]
  onSelectRows: (selectedRows: TableRow[]) => void
}) {
  const [shiftToOpen, setShiftToOpen] = useState<string>('')
  const [shiftNotesOpen, setShiftNotesOpen] = useState<boolean>(false)

  const headers: (DataTableHeader | string)[] = [
    { key: 'shiftName', label: 'Shift Name', sortable: true },
    { key: 'date', label: 'Date', sortable: true },
    { key: 'startAndEndTime', label: 'Start - End Time' },
    { key: 'region', label: 'Region', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'payRate', label: 'Pay Rate', sortable: true },
    { key: 'minAcceptedTier', label: 'Min Tier' },
    {
      key: 'slotsFilledOverSlotsRequested',
      label: 'Slots Filled',
    },
    { key: 'signupStatus', label: 'Signup Status' },
    { key: 'BGCRequirement', label: 'BGC Requirement' },
    { key: 'postAgain', label: 'Post Again' },
  ]

  const rows: TableRow[] = shifts?.map((shift) => {
    const locationNameSubstring = shift.location?.name
      ? `${shift.location?.name},`
      : ''
    const location = `${locationNameSubstring} ${shift.location?.shortLocation}`
    const shiftName = `${shift.shiftRole || shift.role?.roleName} ${
      location.length ? ` @ ${location}` : ''
    }`

    const cells: TableCell[] = [
      {
        sortKey: shiftName,
        renderFn: () => (
          <Row justifyBetween key={shift.id}>
            <Link to={`/field-monitor/${shift.id}`}>
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {shiftName}
              </Button>
            </Link>
            <CopyTextIcon textToCopy={shift.id} />
            {shift.shiftEmploymentType && (
              <EmploymentTypeBadge
                employmentTypes={[shift.shiftEmploymentType]}
              />
            )}
            <Row alignCenter>
              <Button
                variant={ButtonVariant.OUTLINED}
                style={{
                  padding: theme.space.xxs,
                  height: theme.space.sm,
                  marginLeft: theme.space.xxs,
                }}
                onClick={() => {
                  setShiftToOpen(shift.id)
                  setShiftNotesOpen(!shiftNotesOpen)
                }}
              >
                <Row center>
                  <Icon name="info" />
                  <Text variant="caption">Notes</Text>
                </Row>
              </Button>
            </Row>
          </Row>
        ),
      },
      {
        sortKey: new Date(shift.startTime).getTime(),
        renderFn: () => <ShiftDateCell shift={shift} />,
      },
      {
        renderFn: () => <ShiftTimesCell shift={shift} />,
      },
      {
        sortKey: shift.regionId,
        renderFn: () => shift.regionId,
      },
      {
        sortKey: shift.status,
        renderFn: () => <ShiftStatusBadge shiftStatus={shift.status} />,
      },
      {
        sortKey: shift.payRate,
        renderFn: () => getPayRateString(shift.payType, shift.payRate),
      },
      {
        renderFn: () => `${shift.minimumAcceptedTier || '-'}`,
      },
      {
        renderFn: () => {
          return populateSlotsFilled(shift)
        },
      },
      {
        renderFn: () => `${shift.signupStatus || '-'}`,
      },
      {
        renderFn: () => `${shift.extraBGCRequirement || '-'}`,
      },
      {
        renderFn: () => (
          <Link to={`/post/${shift.companyId}?duplicateShiftId=${shift.id}`}>
            <IconButton iconName="plus_active" />
          </Link>
        ),
      },
    ]
    return {
      key: shift.id,
      cells,
    }
  })

  return (
    <>
      <DataTable
        allowSelect={true}
        headers={headers}
        rows={rows}
        initialSortByColumnIndex={1} // sort by date column
        initialSortByAscOrder={true} // sort by recency
        onSelectRows={onSelectRows}
        selectedRows={selectedRows}
      />
      <ShiftNoteDrawer
        shiftId={shiftToOpen}
        isOpen={shiftNotesOpen}
        setIsOpen={setShiftNotesOpen}
        hideFAB
      />
    </>
  )
}
