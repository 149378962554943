import _ from 'lodash'
import { useState } from 'react'
import { IMenuItem } from 'src/components/base/Select/Select'

export enum FilterFieldType {
  Select = 'SELECT',
  Text = 'TEXT',
}

export type FilterField = {
  key: string
  label: string
  type: FilterFieldType
  options?: IMenuItem[]
  shouldPass?: (docValue: any, filterValue: any) => boolean
}

/**
 * Returns filtered collection data based on given filter parameters
 * @param rawData Raw collection to be filtered
 * @param filterFields Definition of what the filters are
 * @param filterValues User input values for the fields
 * @returns Filtered collection
 */
function getFilteredData<T>(
  rawData: T[] | undefined,
  filterFields: FilterField[],
  filterValues: Record<string, any>,
) {
  return (
    rawData &&
    rawData.filter((doc) => {
      for (const filterKey in filterValues) {
        const docValue = _.get(doc, filterKey)
        const filterValue = _.get(filterValues, filterKey)
        const filterField = filterFields.find((f) => f.key === filterKey)

        if (typeof filterValue === 'undefined' || filterValue === '') {
          continue
        }

        // FilterFieldType = TEXT
        if (
          filterField?.type === FilterFieldType.Text && filterField.shouldPass
            ? filterField.shouldPass(doc, filterValue)
            : String(docValue).toLowerCase().includes(filterValue.toLowerCase())
        ) {
          continue
        }

        // FilterFieldType = SELECT
        if (
          filterField?.type === FilterFieldType.Select && filterField.shouldPass
            ? filterField.shouldPass(docValue, filterValue)
            : docValue === filterValue
        ) {
          continue
        }

        return false
      }

      return true
    })
  )
}

export default function useFilteredData<T>(
  rawData: T[] | undefined,
  filterFields: FilterField[],
) {
  /**
   * Filter setter and getter
   */
  const [filterValues, setFilters] = useState<Record<string, any>>({})
  function setFilterValue(key: string, value: any) {
    setFilters({
      ...filterValues,
      [key]: value,
    })
  }

  /**
   * Data Filtering
   */
  const filteredData = getFilteredData(rawData, filterFields, filterValues)

  return {
    filterValues,
    setFilterValue,
    filteredData,
    filterFields,
  }
}
