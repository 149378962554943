import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import { Row } from 'src/components/base'
import Input from './Input'

interface NumberInputProps {
  value?: number
  setValue: (x: number | undefined) => void | ((x: number) => void)
  placeholder?: string
  decimals?: number
  label?: string
  max?: number
  min?: number
  required?: boolean
  step?: number
  containerStyle?: React.CSSProperties
  className?: string
  disabled?: boolean
  width?: string
  onError?: () => void
  isMoney?: boolean
  error?: boolean
  customErrorMessage?: string
  warningTip?: string
}

export const NumberInput = (props: NumberInputProps) => {
  const {
    value,
    setValue,
    label,
    placeholder,
    min,
    max,
    required,
    step,
    className,
    disabled,
    width,
    onError,
    isMoney,
    error,
    customErrorMessage,
    warningTip,
  } = props
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  //To handle the case where first value is an error
  useEffect(() => {
    if (error && customErrorMessage) {
      setErrorMessage(customErrorMessage)
      onError?.()
    } else if (min !== undefined && value !== undefined && value < min) {
      setErrorMessage(`The value cannot be less than ${min}`)
      onError?.()
    } else if (max !== undefined && value !== undefined && value > max) {
      setErrorMessage(`The value cannot be greater than ${max}`)
      onError?.()
    }
  }, [value])

  const isValid = value !== undefined && value.toString() !== 'NaN'

  return (
    <Row alignCenter>
      {isMoney && (
        <Text variant="h5" mr={4}>
          $
        </Text>
      )}
      <Row gap={theme.space.us}>
        <Input
          width={width}
          placeholder={placeholder}
          value={value ?? ''}
          className={`${isValid ? 'valid' : ''} ${className ?? ''}`}
          label={label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(parseFloat(e.target.value))
          }}
          inputStatus={errorMessage ? InputStatus.error : undefined}
          onBlur={() => {
            if (error && customErrorMessage) {
              setErrorMessage(customErrorMessage)
              onError?.()
            } else if (required && (value === undefined || isNaN(value))) {
              setErrorMessage('This field is required.')
              onError?.()
            } else if (
              min !== undefined &&
              value !== undefined &&
              value < min
            ) {
              setErrorMessage(`The value cannot be less than ${min}`)
              onError?.()
            } else if (
              max !== undefined &&
              value !== undefined &&
              value > max
            ) {
              setErrorMessage(`The value cannot be greater than ${max}`)
              onError?.()
            } else {
              setErrorMessage(undefined)
              const maskedValue = value?.toFixed(
                isMoney ? 2 : (props.decimals ?? 0),
              )
              setValue(
                !maskedValue || maskedValue.toString() === 'NaN'
                  ? undefined
                  : parseFloat(maskedValue),
              )
            }
          }}
          rows={1}
          type="number"
          containerStyle={{ width, ...props.containerStyle }}
          errorMessage={errorMessage}
          step={step || 1}
          errorStyle={{ alignSelf: 'center' }}
          disabled={disabled}
        />
        {warningTip && <InfoTooltip title={warningTip} />}
      </Row>
    </Row>
  )
}
