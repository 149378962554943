import { PaymentLog } from '@traba/types'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'
import { useApi } from './useApi'

interface PaymentLogParams {
  workerShiftId?: string
  workerIncentiveId?: string
  before?: string
  after?: string
  cutOffDate?: Date
}

const getPaymentLogParams = (params?: PaymentLogParams) => {
  const activeQueryParams: string[] = []
  if (params) {
    let key: keyof PaymentLogParams
    for (key in params) {
      const value = params[key]
      if (value) {
        if (Array.isArray(value)) {
          continue
        } else {
          activeQueryParams.push(`${key}=${value.toString()}`)
        }
      }
    }
  }

  return activeQueryParams
}

export const usePaymentLogs = (workerId: string, params?: PaymentLogParams) => {
  const paymentLogParams = getPaymentLogParams(params)
  const {
    isLoading,
    data: paymentLogs,
    refetch: refetchPaymentLogs,
    isFetched,
  } = useApi<PaymentLog[] | undefined>(
    `payment-log/${workerId}${
      paymentLogParams.length ? `?${paymentLogParams.join('&')}` : ''
    }`,
    undefined,
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isFetched,
    refetchPaymentLogs,
    paymentLogs,
  }
}
