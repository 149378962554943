import { Menu, MenuItem } from '@mui/material'
import React from 'react'

interface ShiftActionsMenuProps {
  anchorEl: null | HTMLElement
  open: boolean
  handleClose: () => void
  openAddWorkersToShiftsModal: () => void
  openShiftAssignmentModal: () => void
  openBulkCancelShiftsModal: () => void
}

export const ShiftActionsMenu: React.FC<ShiftActionsMenuProps> = ({
  anchorEl,
  open,
  handleClose,
  openAddWorkersToShiftsModal,
  openShiftAssignmentModal,
  openBulkCancelShiftsModal,
}) => {
  return (
    <Menu
      id="actions-menu"
      aria-labelledby="actions-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          openAddWorkersToShiftsModal()
          handleClose()
        }}
      >
        Add Workers
      </MenuItem>
      <MenuItem
        onClick={() => {
          openShiftAssignmentModal()
          handleClose()
        }}
      >
        Assign Shifts
      </MenuItem>
      <MenuItem
        onClick={() => {
          openBulkCancelShiftsModal()
          handleClose()
        }}
      >
        Cancel Shifts
      </MenuItem>
    </Menu>
  )
}
