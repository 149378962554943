import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  WorkerJobPlacementResponseByCompanyId,
  WorkerJobPlacementResponseByWorkerId,
  WorkerJobPlacementStatus,
} from '@traba/types'
import { useState, useMemo } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable } from 'src/components/base/Table/DataTable'
import { useCompanyJobPlacements } from 'src/hooks/useWorkerJobPlacements'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { truncateString } from 'src/utils/stringUtils'
import { CompanyJobPlacementCreateModal } from './CompanyJobPlacementCreateModal'
import { CompanyJobPlacementTerminateModal } from './CompanyJobPlacementTerminateModal'

interface CompanyJobPlacementsProps {
  companyId: string
  status: WorkerJobPlacementStatus
}

function isWorkerJobPlacementByCompanyId(
  jobPlacement:
    | WorkerJobPlacementResponseByCompanyId
    | WorkerJobPlacementResponseByWorkerId,
): jobPlacement is WorkerJobPlacementResponseByCompanyId {
  return (
    (jobPlacement as WorkerJobPlacementResponseByCompanyId)
      .employeeFirstName !== undefined &&
    (jobPlacement as WorkerJobPlacementResponseByCompanyId).employeeLastName !==
      undefined &&
    (jobPlacement as WorkerJobPlacementResponseByCompanyId)
      .workerJobPlacement !== undefined
  )
}

export default function CompanyJobPlacements({
  companyId,
  status,
}: CompanyJobPlacementsProps) {
  const { isLoading, jobPlacements, refetch } = useCompanyJobPlacements(
    companyId,
    status,
  )

  const createWorkerJobPlacementModal = useModal()
  const terminateWorkerJobPlacementModal = useModal()
  const [selectedWorkerId, setSelectedWorkerId] = useState<string | undefined>(
    undefined,
  )
  const [selectedWorkerName, setSelectedWorkerName] = useState('')

  const tableRows = useMemo(() => {
    return (
      jobPlacements?.map((jobPlacement) => {
        const { id, workerId, startDate, endDate, initiatedBy } =
          jobPlacement.workerJobPlacement

        const placementStartDate = new Date(startDate)
        const placementEndDate = endDate ? new Date(endDate) : undefined

        const displayName = isWorkerJobPlacementByCompanyId(jobPlacement)
          ? `${jobPlacement.employeeFirstName} ${jobPlacement.employeeLastName}`
          : jobPlacement.employerName

        return {
          key: id,
          cells: [
            {
              renderFn: () => (
                <Row justifyBetween>
                  <Link to={`/workers/${workerId}`} target="_blank">
                    <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                      {displayName}
                    </Button>
                  </Link>
                </Row>
              ),
              sortKey: displayName,
            },
            {
              renderFn: () => (
                <>
                  {truncateString(workerId, 6)}
                  <CopyTextIcon textToCopy={workerId} />
                </>
              ),
              sortKey: workerId,
            },
            {
              renderFn: () =>
                status === WorkerJobPlacementStatus.ACTIVE_WORKER
                  ? formatDateTimeWithTimezone(placementStartDate)
                  : endDate
                    ? formatDateTimeWithTimezone(placementEndDate)
                    : 'No end date provided',
              sortKey: Date.parse(startDate.toString()),
            },
            {
              renderFn: () => initiatedBy,
              sortKey: initiatedBy,
            },
            {
              renderFn: () => (
                <Row justifyBetween>
                  <Button
                    style={{
                      padding: 0,
                      color: 'currentColor',
                      textDecoration: 'underline',
                    }}
                    variant={ButtonVariant.TEXT}
                    onClick={() => {
                      setSelectedWorkerId(workerId)
                      setSelectedWorkerName(displayName)
                      terminateWorkerJobPlacementModal.open()
                      refetch()
                    }}
                  >
                    {status === WorkerJobPlacementStatus.ACTIVE_WORKER
                      ? 'Terminate'
                      : undefined}
                  </Button>
                </Row>
              ),
            },
          ],
        }
      }) ?? []
    )
  }, [jobPlacements, status, refetch, terminateWorkerJobPlacementModal])

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  return (
    <>
      <Row pb={theme.space.xs} fullWidth alignCenter justifyBetween>
        <Text variant="h5">
          {status === WorkerJobPlacementStatus.ACTIVE_WORKER
            ? 'Hired'
            : 'Terminated'}
        </Text>
        {status === WorkerJobPlacementStatus.ACTIVE_WORKER && (
          <Button
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={createWorkerJobPlacementModal.open}
          >
            Add Worker
          </Button>
        )}
      </Row>
      <DataTable
        initialSortByColumnIndex={0}
        rows={tableRows}
        headers={[
          {
            key: 'employeeName',
            label: 'Worker Name',
            sortable: true,
          },
          {
            key: 'workerId',
            label: 'Worker ID',
            sortable: true,
          },
          {
            key: 'workerPlacementStartDate',
            label: 'Date & Time added',
            sortable: true,
          },
          {
            key: 'initiatedBy',
            label: 'Initiated By',
            sortable: true,
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
          },
        ]}
      />
      <CompanyJobPlacementCreateModal
        companyId={companyId}
        isOpen={createWorkerJobPlacementModal.isOpen}
        handleClose={createWorkerJobPlacementModal.handleClose}
      />
      {selectedWorkerId && (
        <CompanyJobPlacementTerminateModal
          workerId={selectedWorkerId}
          workerName={selectedWorkerName}
          companyId={companyId}
          isOpen={terminateWorkerJobPlacementModal.isOpen}
          handleClose={terminateWorkerJobPlacementModal.handleClose}
        />
      )}
    </>
  )
}
