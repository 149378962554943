export enum WorkerJobPlacementAction {
  CREATE_WORKER = 'CREATE_WORKER',
  TERMINATE_WORKER = 'TERMINATE_WORKER',
}

export enum WorkerJobPlacementStatus {
  ACTIVE_WORKER = 'ACTIVE_WORKER',
  TERMINATED_WORKER = 'TERMINATED_WORKER',
}

export type WorkerJobPlacement = {
  id: string
  createdAt: Date
  updatedAt: Date
  workerId: string
  companyId: string
  initiatedBy?: string
  startDate: Date
  endDate?: Date
  reason?: string
  isEnded: boolean
}

export type WorkerJobPlacementResponseByWorkerId = {
  workerJobPlacement: WorkerJobPlacement
  employerName: string
}

export type WorkerJobPlacementResponseByCompanyId = {
  workerJobPlacement: WorkerJobPlacement
  employeeFirstName: string
  employeeLastName: string
}
