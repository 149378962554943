import { RobocallCommunication } from '@traba/types'
import { PaidBackup } from '@traba/types'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Icon } from 'src/components/base'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'

export type PaidBackupConfirmationIconProps = {
  paidBackup: PaidBackup
  timezone: string
  latestRobocall?: RobocallCommunication
}

export default function PaidBackupConfirmationIcon({
  paidBackup,
  timezone,
  latestRobocall,
}: PaidBackupConfirmationIconProps) {
  const { isConfirmed, confirmedAt } = paidBackup

  /**
   * Gets paid backup confirmation icon based on
   * shift confirmation and (eventually) robocall status
   * TODO(gavin): add robocalls for paid backups
   */
  function getConfirmationIcon() {
    if (isConfirmed) {
      return <Icon name="greenCheck" />
    }

    if (latestRobocall?.data?.answeredBy === 'human') {
      return <Icon name="phone" type="svg" size={14} color="green" />
    }

    if (typeof latestRobocall?.createdAt !== 'undefined') {
      return <Icon name="phone" type="svg" size={14} color="gray" />
    }

    return '-'
  }

  const confirmationIcon = getConfirmationIcon()

  // If there's no confirmation nor robocall, we just return the confirmation icon without a tooltip
  if (!isConfirmed && !latestRobocall) {
    return confirmationIcon
  }

  return (
    <span data-tooltip-id={`confirmation_${latestRobocall?.workerId}`}>
      {confirmationIcon}
      <ReactTooltip
        place="top"
        id={`confirmation_${latestRobocall?.workerId}`}
        style={{ zIndex: 2 }}
      >
        <p>
          {formatDateTimeWithTimezone(
            isConfirmed && confirmedAt
              ? confirmedAt
              : latestRobocall?.createdAt,
            timezone,
          )}

          {!isConfirmed && latestRobocall && (
            <>
              <br />
              <br />
              Status: {latestRobocall.data.status}
              <br />
              Answered By: {latestRobocall.data.answeredBy || '?'}
            </>
          )}
        </p>
      </ReactTooltip>
    </span>
  )
}
