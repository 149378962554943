import { theme } from '@traba/theme'
import { CreateSchedule } from '@traba/types'
import { SetStateAction, useCallback, useMemo } from 'react'
import { Row } from 'src/components/base'
import { LocationSingleSearchSelector } from 'src/components/LocationSingleSearchSelector'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'

import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'

interface Props {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  timezone: string
}

export const LocationSection = ({
  createShiftRequests,
  setCreateShiftRequests,
  timezone,
}: Props) => {
  const firstShiftRequest = useMemo(
    () => createShiftRequests[0],
    [createShiftRequests],
  )

  const companyId = useMemo(
    () => firstShiftRequest.companyId,
    [firstShiftRequest.companyId],
  )

  const locationId = useMemo(
    () => firstShiftRequest.locationId,
    [firstShiftRequest.locationId],
  )

  const parkingLocationId = useMemo(
    () => firstShiftRequest.parkingLocationId,
    [firstShiftRequest.parkingLocationId],
  )

  const onChangeLocation = useCallback(
    (locationId?: string) => {
      setCreateShiftRequests((prev) => {
        return prev.map((shiftReq) => {
          return {
            ...shiftReq,
            locationId: locationId || '',
            schedules: shiftReq.schedules.map(
              (schedule): CreateSchedule => ({
                ...schedule,
                timeZone: timezone,
              }),
            ),
          }
        })
      })
    },
    [setCreateShiftRequests, timezone],
  )
  const onChangeParkingLocation = useCallback(
    (parkingLocationId?: string) => {
      setCreateShiftRequests((prev) => {
        return prev.map((shiftReq) => {
          return { ...shiftReq, parkingLocationId: parkingLocationId || '' }
        })
      })
    },
    [setCreateShiftRequests],
  )

  return (
    <ShiftPostingInputContainer title="Location details">
      <Row
        fullWidth
        style={{ justifyContent: 'space-around' }}
        mb={theme.space.sm}
        wrap
      >
        <ShiftPostingInputContainerSection
          label="Location"
          input={
            <LocationSingleSearchSelector
              companyId={companyId}
              placeholder=""
              selectedLocationId={locationId}
              onChange={onChangeLocation}
              label=""
              style={{ width: '40%' }}
              hideCompanyWideOption
            />
          }
        />
        <ShiftPostingInputContainerSection
          label="Parking Location (optional)"
          input={
            <LocationSingleSearchSelector
              companyId={companyId}
              placeholder=""
              selectedLocationId={parkingLocationId}
              onChange={onChangeParkingLocation}
              label=""
              style={{ width: '40%' }}
              hideCompanyWideOption
              showClearButton
            />
          }
        />
      </Row>
    </ShiftPostingInputContainer>
  )
}
