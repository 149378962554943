import { Anchor, Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ShiftInvitation,
  ShiftInvitationStatus,
  ShiftInvitationWithWorkerInfo,
} from '@traba/types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  CopyTextIcon,
  Td,
  Tr,
  Row,
  AccountStatusBadge,
  Badge,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import WorkerCoordinate from 'src/components/WorkerTable/components/WorkerCoordinate'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatPhoneNumber } from 'src/utils/stringUtils'

type ShiftInvitationsTableRowProps = {
  shiftInvitation: ShiftInvitationWithWorkerInfo
  shift: OpsExtendedShift
  setSelectedRows: (newSelectedRows: string[]) => void
  selectedRows: string[]
  openAddWorkersModal: () => void
}

const invitationBadgeVariant = (
  invitation: ShiftInvitationWithWorkerInfo,
): string => {
  switch (invitation.status) {
    case ShiftInvitationStatus.Declined:
      return 'danger'
    case ShiftInvitationStatus.Rescinded:
      return 'disabled'
    case ShiftInvitationStatus.Accepted:
      return 'brand'
    case ShiftInvitationStatus.Sent:
      return 'success'
    case ShiftInvitationStatus.Pending:
      return invitation.notificationError ? 'opaqueRed' : 'info'
    default:
      return 'info'
  }
}

const invitationBadgeTitle = (
  invitation: ShiftInvitationWithWorkerInfo,
): string => {
  if (
    invitation.status !== ShiftInvitationStatus.Pending ||
    !invitation.notificationError
  ) {
    return invitation.status
  }

  return invitation.notificationError.replaceAll('_', ' ')
}

const resendDisabledForStatus = (status: ShiftInvitationStatus): boolean => {
  return (
    status === ShiftInvitationStatus.Accepted ||
    status === ShiftInvitationStatus.Declined ||
    status === ShiftInvitationStatus.Rescinded
  )
}
const rescindDisabledForStatus = (status: ShiftInvitationStatus): boolean => {
  return (
    status === ShiftInvitationStatus.Accepted ||
    status === ShiftInvitationStatus.Rescinded
  )
}
const addWorkerDisabledForStatus = (status: ShiftInvitationStatus): boolean => {
  return (
    status === ShiftInvitationStatus.Accepted ||
    status === ShiftInvitationStatus.Declined ||
    status === ShiftInvitationStatus.Rescinded
  )
}

export default function ShiftInvitationsTableRow(
  props: ShiftInvitationsTableRowProps,
) {
  const { shiftInvitation, shift } = props
  const { shiftId } = shift
  const { worker, failedEligibilityChecks = [] } = shiftInvitation
  const [isLoadingResendButton, setIsLoadingResendButton] = useState(false)
  const [isLoadingRescindButton, setIsLoadingRescindButton] = useState(false)
  const { rescindInvitation, resendInvitation } = useShiftInvitations(shiftId)

  const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)
  const tz = useTimezonedDates(shift.timezone)

  const handleRescindInvitation = async (shiftInvitation: ShiftInvitation) => {
    if (!window.confirm('Are you sure you want to rescind this invitation?')) {
      return
    }
    setIsLoadingRescindButton(true)
    rescindInvitation(shiftInvitation, {
      onSettled: () => setIsLoadingRescindButton(false),
    })
  }

  const handleResendInvitation = async (workerId: string) => {
    setIsLoadingResendButton(true)
    resendInvitation(
      { shiftId, workerId },
      {
        onSettled: () => setIsLoadingResendButton(false),
      },
    )
  }

  const handleAddSingleWorkerToShiftClick = () => {
    props.setSelectedRows([worker.id])
    props.openAddWorkersModal()
  }

  function onToggleRowSelection(ev: React.ChangeEvent<HTMLInputElement>) {
    const updatedSelectedRows = ev.target.checked
      ? [...props.selectedRows, worker.id]
      : props.selectedRows.filter((wid) => wid !== worker.id)

    props.setSelectedRows(updatedSelectedRows)
  }

  return (
    <Tr key={shiftInvitation.id}>
      <Td>
        <Checkbox
          onChange={(ev) => onToggleRowSelection(ev)}
          checked={!!props.selectedRows.find((wid) => wid === worker.id)}
          labelStyle={{ top: -12 }}
          disabled={addWorkerDisabledForStatus(shiftInvitation.status)}
        />
      </Td>
      <Td>
        <Link to={`/workers/${shiftInvitation.workerId}`} target="_blank">
          <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
            {`${shiftInvitation.worker.firstName} ${shiftInvitation.worker.lastName}`}
          </Button>
        </Link>
      </Td>
      <Td>
        {shiftInvitation.worker.accountApprovalStatus && (
          <AccountStatusBadge
            accountStatus={shiftInvitation.worker.accountApprovalStatus}
          />
        )}
      </Td>
      <Td>
        <Text variant="body1">
          {shiftInvitation.workerId}
          <CopyTextIcon textToCopy={shiftInvitation.workerId} />
        </Text>
      </Td>
      <Td>
        <Anchor openInNewTab={false} href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Anchor>
        <CopyTextIcon textToCopy={phoneNumber} />
      </Td>
      <Td>
        <>
          <Badge
            title={invitationBadgeTitle(shiftInvitation)}
            variant={invitationBadgeVariant(shiftInvitation)}
          />
          {failedEligibilityChecks.length > 0 && (
            <InfoTooltip title={failedEligibilityChecks.join(', ')} />
          )}
        </>
      </Td>
      <Td>
        <Text variant="body1">
          {shiftInvitation.invitedAt
            ? `${tz.getDateTime(new Date(shiftInvitation.invitedAt))}`
            : '-'}
        </Text>
      </Td>
      <Td>{worker.tierLevel ? `${worker.tierLevel}` : '-'}</Td>
      <Td>
        {worker.zipCode ? (
          <WorkerCoordinate
            workerZipCode={worker.zipCode}
            shiftCoords={shift.location.coords}
          />
        ) : (
          '-'
        )}
      </Td>
      <Td>{worker?.completedShifts || '0'}</Td>
      <Td style={{ alignContent: 'right' }}>
        <Row justifyEnd>
          <Button
            slim
            loading={isLoadingRescindButton}
            mt={theme.space.ms}
            mb={theme.space.ms}
            disabled={rescindDisabledForStatus(shiftInvitation.status)}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              handleRescindInvitation(shiftInvitation)
            }}
          >
            Cancel
          </Button>
          <Button
            slim
            loading={isLoadingResendButton}
            mt={theme.space.ms}
            mb={theme.space.ms}
            ml={theme.space.ms}
            disabled={resendDisabledForStatus(shiftInvitation.status)}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              handleResendInvitation(shiftInvitation.workerId)
            }}
          >
            {shiftInvitation.status === ShiftInvitationStatus.Pending
              ? 'Send'
              : 'Resend'}
          </Button>
          <Button
            slim
            variant={ButtonVariant.FILLED}
            mt={theme.space.ms}
            mb={theme.space.ms}
            ml={theme.space.ms}
            disabled={addWorkerDisabledForStatus(shiftInvitation.status)}
            onClick={handleAddSingleWorkerToShiftClick}
          >
            {'Add to shift'}
          </Button>
        </Row>
      </Td>
    </Tr>
  )
}
