import { Checkbox, ListItem } from '@mui/material'
import { Badge, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { RequiredMultiShiftType, ShiftStatus } from '@traba/types'
import { Shift } from '@traba/types'
import { differenceInHours } from 'date-fns'
import { format, toDate } from 'date-fns-tz'
import { useCallback } from 'react'
import { Col, Row } from 'src/components/base'
import Toggle from 'src/components/base/Toggle'
import { useShowCompletedShifts } from 'src/hooks/useShowCompletedShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import * as S from '../../styles'
import { ShiftRequestWithShifts } from './types'

type SetSelectedShifts = React.Dispatch<
  React.SetStateAction<Record<string, string[]>>
>

export type SelectAllControlProps = {
  isSelected: boolean
  onSelectAll: () => void
  disabled: boolean
  text: string
}

type ShiftRequestCardProps = {
  shiftRequest: ShiftRequestWithShifts
  selectedShifts: Record<string, string[]>
  setSelectedShifts: SetSelectedShifts
  conflictingShiftIds: string[]
  isCancellation?: boolean
}

const SelectAllControl = ({
  isSelected,
  onSelectAll,
  disabled,
  text,
}: SelectAllControlProps) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      onClick={() => (disabled ? undefined : onSelectAll())}
    >
      <Checkbox checked={isSelected} disabled={disabled} />
      <Text variant="body1" style={{ fontWeight: 500 }}>
        {text}
      </Text>
    </ListItem>
  )
}

type ShiftListProps = {
  shifts: Shift[]
  conflictingShiftIds: string[]
  selectedShifts: string[]
  isCancellation?: boolean
  lateCancellationShifts?: string[]
  onShiftToggle: (shiftId: string, shiftRequestId: string) => void
}

const ShiftList = ({
  shifts,
  selectedShifts,
  conflictingShiftIds,
  isCancellation,
  lateCancellationShifts,
  onShiftToggle,
}: ShiftListProps) => {
  const tz = useTimezonedDates(shifts[0].timezone)

  return (
    <Col pl={theme.space.xs}>
      {shifts.map((shift) => {
        const isSelected = selectedShifts?.includes(shift.shiftId) || false
        const hasConflicts = conflictingShiftIds.includes(shift.shiftId)
        const isLateCancellation =
          isCancellation && lateCancellationShifts?.includes(shift.shiftId)
        const disabled = hasConflicts || isLateCancellation

        const shiftFilledString = `- ${shift.slotsFilled}/${shift.slotsRequested}${shift.overbookSlotsRequested ? `/OB${shift.overbookSlotsRequested}` : ``} filled -`

        const handleClick = () =>
          disabled
            ? undefined
            : onShiftToggle(shift.shiftId, shift.shiftRequestId)

        return (
          <Row
            key={`${shift.shiftId}-${shift.shiftRequestId}`}
            justifyStart
            alignCenter
            pr={theme.space.xs}
          >
            <S.StyledListItem
              key={`${shift.shiftId}-${shift.shiftRequestId}_shift_list`}
              disableGutters
              disablePadding
              onClick={handleClick}
              sx={{
                backgroundColor: isSelected
                  ? theme.colors.Grey20
                  : 'transparent',
                textDecoration: disabled ? 'line-through' : 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
                wordBreak: 'break-word',
              }}
            >
              <Checkbox checked={isSelected} disabled={disabled} />
              <Text variant="body1" style={{ fontWeight: 500 }}>
                {`${format(new Date(shift.startTime), 'E')} - ${tz.getDate(shift.startTime, false)} ${tz.getShiftTime(
                  shift.startTime,
                  shift.endTime,
                )}`}
              </Text>
              <Text variant="body1" ml={theme.space.xxs}>
                {shiftFilledString}
              </Text>
              <Text ml={theme.space.xxs}>( {shift.shiftId} )</Text>
              {shift.status === ShiftStatus.CANCELED && (
                <Badge sm variant="danger" title="cancelled" />
              )}
            </S.StyledListItem>

            {hasConflicts && <Text variant="error">Conflicts</Text>}
            {isLateCancellation && (
              <Text variant="error">
                Late cancels must be cancelled individually
              </Text>
            )}
          </Row>
        )
      })}
    </Col>
  )
}

function toggleShiftInSet(
  shiftId: string,
  shiftRequestId: string,
  prevSelectedShifts: Record<string, string[]>,
): Record<string, string[]> {
  const selectedShiftsForRequest = new Set(prevSelectedShifts[shiftRequestId])
  if (selectedShiftsForRequest.has(shiftId)) {
    selectedShiftsForRequest.delete(shiftId)
  } else {
    selectedShiftsForRequest.add(shiftId)
  }

  return {
    ...prevSelectedShifts,
    [shiftRequestId]: Array.from(selectedShiftsForRequest),
  }
}

const ShiftRequestCard = ({
  shiftRequest,
  selectedShifts,
  setSelectedShifts,
  conflictingShiftIds,
  isCancellation,
}: ShiftRequestCardProps) => {
  const { shortLocation, employerName, shiftRequestId, shiftRole, shifts } =
    shiftRequest

  const {
    validShifts,
    showCompleteShifts,
    setShowCompleteShifts,
    showCancelledShifts,
    setShowCancelledShifts,
  } = useShowCompletedShifts(shifts)

  const lateCancellationShifts = validShifts
    .filter(
      (shift) =>
        differenceInHours(
          toDate(shift.startTime, { timeZone: shift.timezone }),
          toDate(new Date(), { timeZone: shift.timezone }),
        ) <= 18,
    )
    .map((shift) => shift.shiftId)

  const selectableShiftsIds = validShifts
    .filter((shift) =>
      isCancellation
        ? !lateCancellationShifts.includes(shift.shiftId)
        : !conflictingShiftIds.includes(shift.shiftId),
    )
    .map((shift) => shift.shiftId)

  const handleShiftCheckboxToggle = useCallback(
    (shiftId: string, shiftRequestId: string): void => {
      setSelectedShifts((prevSelectedShifts) =>
        toggleShiftInSet(shiftId, shiftRequestId, prevSelectedShifts),
      )
    },
    [setSelectedShifts],
  )

  const handleSelectAllToggle = () => {
    const updatedSelectedShifts = { ...selectedShifts }
    if (
      updatedSelectedShifts[shiftRequestId]?.length ===
      selectableShiftsIds.length
    ) {
      updatedSelectedShifts[shiftRequestId] = []
    } else {
      updatedSelectedShifts[shiftRequestId] = selectableShiftsIds
    }
    setSelectedShifts(updatedSelectedShifts)
  }

  // If we are in cancellation flow, ignore rmsa
  const isRequiredMultiShift =
    !isCancellation &&
    shiftRequest?.requiredMultiShiftType ===
      RequiredMultiShiftType.ALL_IN_REQUEST

  // Check if there is no shift to select
  const disabledSelectAll = selectableShiftsIds.length === 0

  const rowStyle = {
    backgroundColor: !validShifts?.length
      ? theme.colors.Grey10
      : isRequiredMultiShift
        ? theme.colors.Violet10
        : theme.colors.Green10,
  }

  return (
    <Row my={theme.space.xxs} fullWidth>
      <S.CardWrapper>
        <Row
          justifyBetween
          alignCenter
          fullWidth
          px={theme.space.xs}
          py={theme.space.xxxs}
          style={rowStyle}
          wrap
        >
          <Text variant="h6">
            {employerName} | {shiftRole} | {shortLocation}{' '}
            {isRequiredMultiShift && (
              <Text variant="brand"> Required multi shift</Text>
            )}
          </Text>
          <Text variant="body2">Shift request: {shiftRequestId}</Text>
          <Toggle
            buttonState={showCancelledShifts}
            label="Show cancelled shifts"
            runOnChange={() => setShowCancelledShifts((s) => !s)}
            color="transparent"
          />
          <Toggle
            buttonState={showCompleteShifts}
            label="Show past shifts"
            runOnChange={() => setShowCompleteShifts((s) => !s)}
            color="transparent"
          />
        </Row>

        {!!validShifts?.length && (
          <Row pl={theme.space.xs} py={theme.space.xxs} flexCol>
            <SelectAllControl
              isSelected={
                !disabledSelectAll &&
                selectedShifts[shiftRequestId]?.length ===
                  selectableShiftsIds?.length
              }
              onSelectAll={handleSelectAllToggle}
              disabled={disabledSelectAll}
              text="Select all future shifts in this shift request"
            />
            <ShiftList
              shifts={validShifts}
              selectedShifts={selectedShifts[shiftRequestId]}
              onShiftToggle={handleShiftCheckboxToggle}
              conflictingShiftIds={conflictingShiftIds}
              isCancellation={isCancellation}
              lateCancellationShifts={lateCancellationShifts}
            />
          </Row>
        )}

        {!validShifts?.length && (
          <Text variant="body1" px={theme.space.xs} py={theme.space.xs}>
            This shift request has no upcoming shifts
          </Text>
        )}
      </S.CardWrapper>
    </Row>
  )
}

export default ShiftRequestCard
