import { Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon, Row } from './base'

interface NotePlaceholderProps {
  subtitle: string
}

export const NotePlaceholder = (props: NotePlaceholderProps) => {
  const { subtitle } = props
  return (
    <Row
      alignCenter
      justifyCenter
      fullWidth
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        backgroundColor: theme.colors.Grey10,
        padding: theme.space.sm,
      }}
    >
      <Row flexCol alignCenter justifyCenter>
        <CardTile style={{ marginBottom: theme.space.xs }}>
          <Icon name="folder" />
        </CardTile>
        <Text variant="h4" style={{ marginBottom: theme.space.xs }}>
          No Notes Yet
        </Text>
        <Text variant="h5">{subtitle}</Text>
      </Row>
    </Row>
  )
}
