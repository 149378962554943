import { spacingStyles } from '@traba/theme'
import styled, { css } from 'styled-components'
import { ButtonProps } from './types'

export const ButtonContainer = styled.button.attrs((props) => ({
  style: props.style,
}))<ButtonProps>`
  ${(props) => spacingStyles(props)}
  cursor: pointer;
  border-radius: 8px;
  line-height: 24px;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.full ? 'width: 100%;' : '')}
  padding: ${(prop) => (prop.slim ? css`8px 16px` : css`12px 24px`)};

  ${({ theme, variant, reverse, disabled, textColor, textHoverColor }) => {
    switch (variant) {
      case 'text':
        return css`
          color: ${textColor ?? theme?.TextButton};
          border-radius: 8px;
          border: 0px;
          background: none;
          ${disabled
            ? css`
                cursor: default;
              `
            : css`
                :hover {
                  color: ${textHoverColor ?? theme?.HoverTextButton};
                }
              `}
        `
      case 'outlined':
        return css`
          background: #fff;
          color: #08105e;
          border: 1px solid ${theme?.BorderColor};
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.HoverBorderColor};
                }
              `}
        `
      case 'redoutlined':
        return css`
          background: #fff;
          color: ${theme?.colors.red};
          border: 1px solid ${theme?.colors.red};
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
          border-radius: 8px;
          ${disabled
            ? css`
                cursor: default;
                color: #b6bfc9;
                box-shadow: none;
                border: 1px solid #e2e6e9;
                background: #ffffff;
              `
            : css`
                :hover {
                  border: 1px solid ${theme?.colors.Red70};
                  color: ${theme?.colors.Red70};
                }
              `}
        `
      case 'cancel':
        return css`
          border: none;
          background: ${theme?.colors.red};
          color: #fff;
          ${disabled
            ? css`
                cursor: default;
                background-color: #e2e6e9;
                color: #b6bfc9;
                box-shadow: none;
                border: 0px;
              `
            : css`
                :hover {
                  background: ${theme?.colors.Red70};
                }
              `}
        `
      case 'purplegradient':
        return css`
          border: none;
          background: #f5ebff;
          color: ${theme?.colors.Violet};
          ${disabled
            ? css`
                cursor: default;
                background-color: #e2e6e9;
                color: #b6bfc9;
                box-shadow: none;
                border: 0px;
              `
            : css`
                :hover {
                  background: ${theme?.colors.Violet};
                  color: #fff;
                }
              `}
        `
      case 'transparent':
        return css`
          border: none;
          color: ${theme?.colors.Violet};
          background-color: white;
        `
      case 'error':
        return css`
          border: none;
          background: none;
          padding: 0;
          color: ${theme.colors.Red60};
          ${disabled
            ? css`
                color: ${theme.colors.Grey40};
              `
            : css`
                :hover {
                  color: ${theme.colors.Red80};
                }
              `}
        `
      default:
        return css`
          border: none;
          background: ${reverse
            ? theme?.ReverseBackColor
            : theme?.ActiveBackColor};
          color: ${reverse ? theme?.ActiveBackColor : '#fff'};
          ${disabled
            ? css`
                cursor: default;
                background-color: ${theme.colors.Grey30};
                opacity: 0.7;
                box-shadow: none;
                border: 0px;
              `
            : css`
                :hover {
                  background: ${reverse
                    ? theme?.ReverseHoverColor
                    : theme?.ActiveHoverColor};
                }
              `}
        `
    }
  }}
`

export type ButtonIconProps = {
  reverse: boolean
  padding?: string
}

export const ButtonIcon = styled.div<ButtonIconProps>`
  display: flex;
  align-self: center;
  box-sizing: content-box;
  padding: ${({ padding }) => (padding ? padding : '0 10px')};
  color: ${({ reverse, theme }) => (reverse ? theme?.ActiveBackColor : '#fff')};
`

export const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: 400;
`
