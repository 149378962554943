import {
  AccountApprovalStatus,
  BackgroundCheckStatus,
  EmploymentType,
  InstantPayEligibilityStatus,
  RequiredAttributeLevelWithLevelType,
  Transportation,
} from '@traba/types'
import { WorkerProfileStatus } from '@traba/types'
import { TierLevel } from '@traba/types'
import { WorkerGender } from '@traba/types'
import { useMemo } from 'react'
import { enumToSelectItems } from 'src/helpers'
import { useCertifications } from './useCertifications'

const accountStatusItems = enumToSelectItems(AccountApprovalStatus)
const instantPayStatusItems = enumToSelectItems(InstantPayEligibilityStatus)
const backgroundCheckStatusItems = enumToSelectItems(BackgroundCheckStatus)
const tierLevelItems = enumToSelectItems(TierLevel)
const workerTransportationItems = enumToSelectItems(Transportation)

const workerProfileStatuses = enumToSelectItems(WorkerProfileStatus)
const workerGenderItems = enumToSelectItems(WorkerGender)

const employmentTypeItems = enumToSelectItems(EmploymentType)

export const SEARCH_INPUT_ON_SHIFT_WAITLIST_FIELD_KEY = 'isOnWaitlistForShiftId'
export const SEARCH_INPUT_SAME_DAY_WAITLIST_FIELD_KEY =
  'isOnWaitlistOnSameDayOfShiftId'
export const SEARCH_INPUT_AVAILABLE_FOR_SHIFT_FIELD_KEY =
  'isAvailableForShiftId'

export enum FieldType {
  input = 'input',
  int = 'int',
  float = 'float',
  date = 'date',
  checkbox = 'checkbox',
  select = 'select',
  multiSelect = 'multiSelect',
  attributeLevelModal = 'attributeLevelModal',
}

export interface Field {
  field: string
  label: string
  type: FieldType
  items?: Array<{ value: any; label: string }>
  itemLabelExtractor?: (item: any) => string
  placeholder?: string
  order?: number
  tooltip?: string
  fixed?: boolean
}

const basicInfoFields: Field[] = [
  {
    field: 'firstName',
    label: 'First Name',
    type: FieldType.input,
    order: 1,
    fixed: true,
  },
  {
    field: 'lastName',
    label: 'Last Name',
    type: FieldType.input,
    order: 2,
    fixed: true,
  },
  {
    field: 'workerId',
    label: 'Worker Id',
    type: FieldType.input,
    order: 3,
    fixed: true,
  },
  {
    field: 'regionId',
    label: 'Region',
    type: FieldType.input,
    order: 4,
    fixed: true,
  },
  {
    field: 'phoneNumber',
    label: 'Phone',
    type: FieldType.input,
    order: 5,
    fixed: true,
  },
  {
    field: 'email',
    label: 'Email',
    type: FieldType.input,
    order: 7,
    fixed: true,
  },
]

const advancedInfoFieldsRaw: Field[] = [
  {
    field: 'transportation',
    label: 'Transportation',
    type: FieldType.select,
    items: workerTransportationItems,
  },
  {
    field: 'tierLevels',
    label: 'Tiers',
    type: FieldType.multiSelect,
    items: tierLevelItems,
  },
  {
    field: 'accountStatuses',
    label: 'Account Status',
    type: FieldType.multiSelect,
    items: accountStatusItems,
    order: 8,
    fixed: true,
  },
  {
    field: 'lastActiveAtFrom',
    label: 'Last active after',
    type: FieldType.date,
    order: 9,
    fixed: true,
  },
  {
    field: 'accountCreatedAfter',
    label: 'Account created after',
    type: FieldType.date,
    order: 12,
    fixed: false,
  },
  {
    field: 'workerProfileFields',
    label: 'Attributes',
    type: FieldType.multiSelect,
  },
  {
    field: 'workerProfileStatuses',
    label: 'Attribute status',
    type: FieldType.multiSelect,
    items: workerProfileStatuses,
  },
  {
    field: 'attributeLevels',
    label: 'Attribute Levels',
    type: FieldType.attributeLevelModal,
    itemLabelExtractor: (item: RequiredAttributeLevelWithLevelType) =>
      item.attribute.type,
    fixed: true,
    order: 13,
  },
  {
    field: 'industries',
    label: 'Industries',
    type: FieldType.multiSelect,
  },
  {
    field: 'availableToday',
    label: 'Available Today',
    type: FieldType.checkbox,
    order: 9,
    fixed: true,
  },
  {
    field: 'availableTomorrow',
    label: 'Available Tomorrow',
    type: FieldType.checkbox,
  },
  {
    field: 'activeEmploymentType',
    label: 'W2/1099',
    type: FieldType.select,
    items: employmentTypeItems,
    fixed: true,
  },
  {
    field: 'instantPayEligibilityStatus',
    label: 'Instant Pay Eligibility Status',
    type: FieldType.select,
    items: instantPayStatusItems,
  },
  {
    field: 'instantPayEnabled',
    label: 'Instant Pay Enabled',
    type: FieldType.checkbox,
  },
  {
    field: 'zipCode',
    label: 'Postal Code',
    type: FieldType.input,
  },
  {
    field: 'hasResumeUploaded',
    label: 'Has resume uploaded',
    type: FieldType.checkbox,
  },
  {
    field: 'hasCompletedShiftId',
    label: 'Completed shift - shiftId',
    type: FieldType.input,
  },
  { field: 'approvedAt', label: 'Approved after', type: FieldType.date },

  {
    field: 'backgroundCheckStatus',
    label: 'Background Check Status',
    type: FieldType.select,
    items: backgroundCheckStatusItems,
  },
  {
    field: 'hasCleanBGC',
    label: 'Has Clean BGC',
    type: FieldType.checkbox,
    tooltip:
      'Worker is BGC eligible for the shift (if shift is CLEAN BGC Req, worker has BGC assessment != REVIEW or ESCALATED)',
  },
  { field: 'deviceId', label: 'Device ID', type: FieldType.input },
  {
    field: 'dob',
    label: 'Date of Birth',
    type: FieldType.input,
    placeholder: '1983-07-11',
  },
  { field: 'last4SSN', label: 'Last 4 SSN', type: FieldType.input },
  { field: 'referralCode', label: 'Referral Code', type: FieldType.input },
  { field: 'referredBy', label: 'Referred By', type: FieldType.input },
  {
    field: 'hasNotReportedGender',
    label: 'Has Not Reported Gender',
    type: FieldType.checkbox,
  },
  {
    field: 'workerGender',
    label: 'Worker Gender',
    type: FieldType.select,
    items: workerGenderItems,
  },
  {
    field: 'hasStripePayoutsDisabled',
    label: 'Stripe Payouts Disabled',
    type: FieldType.checkbox,
  },
  {
    field: 'hasStripePayoutsDisabledDeadline',
    label: 'Stripe Payouts to be Disabled Soon',
    type: FieldType.checkbox,
  },
]

const metricsInfoFields: Field[] = [
  {
    field: 'minReliabilityScore',
    label: 'Min Reliability Score',
    type: FieldType.float,
  },
  { field: 'minOnTimeRate', label: 'Min On Time Rate', type: FieldType.float },
  {
    field: 'minBusinessApprovalScore',
    label: 'Min Business Approval Score',
    type: FieldType.float,
  },
  {
    field: 'minCompletedShifts',
    label: 'Min Completed Shifts',
    type: FieldType.int,
  },
  {
    field: 'minFavoriteCount',
    label: 'Min Favorite Count',
    type: FieldType.int,
  },
  { field: 'maxBlockedCount', label: 'Max Blocked Count', type: FieldType.int },
]

const shiftBasedFields: Field[] = [
  {
    field: 'minDistanceFromShiftId',
    label: 'Min distance',
    type: FieldType.int,
    tooltip: 'Minimum distance in miles from the shift geohash',
    order: 1,
    fixed: true,
  },
  {
    field: 'maxDistanceFromShiftId',
    label: 'Max distance',
    type: FieldType.int,
    tooltip: 'Maximum distance in miles from the shift geohash',
    order: 2,
    fixed: true,
  },
  {
    field: 'workerId',
    label: 'Worker Id',
    type: FieldType.input,
    tooltip: 'Worker Id of desired worker',
    order: 3,
    fixed: true,
  },
  {
    field: 'hasWorkedWithCompanyId',
    label: 'Worked with the company',
    type: FieldType.checkbox,
  },
  {
    field: 'isOnShiftId',
    label: 'Is on shift',
    type: FieldType.checkbox,
  },
  {
    field: SEARCH_INPUT_SAME_DAY_WAITLIST_FIELD_KEY,
    label: 'On ANY waitlist today',
    type: FieldType.checkbox,
    tooltip:
      'Is on waitlist on a shift on the same day in the selected regions',
  },
  {
    field: SEARCH_INPUT_ON_SHIFT_WAITLIST_FIELD_KEY,
    label: 'Is on the waitlist for this shift',
    type: FieldType.checkbox,
  },
  {
    field: SEARCH_INPUT_AVAILABLE_FOR_SHIFT_FIELD_KEY,
    label: 'Is available, no conflicts',
    type: FieldType.checkbox,
    tooltip:
      'Worker is available for the shift, considering a 30min commute buffer',
    order: 10,
    fixed: true,
  },
  {
    field: 'isInvitedToShiftId',
    label: 'Is invited to shift',
    type: FieldType.checkbox,
    tooltip: 'Worker has sent or pending invitation for the shift',
    order: 11,
    fixed: true,
  },
]

export const useSearchInputItems = () => {
  const { certifications } = useCertifications()

  return useMemo(() => {
    const certificationTypes = certifications?.map((cert) => ({
      value: cert.type,
      label: cert.name,
    }))

    const advancedInfoFields = [
      ...advancedInfoFieldsRaw,
      {
        field: 'hasCertificationType',
        label: 'Has Certification',
        type: FieldType.select,
        items: certificationTypes,
      },
    ]

    const bugleInputFields = [
      ...shiftBasedFields,
      ...advancedInfoFields,
      ...metricsInfoFields,
    ]

    const allInputFields = [
      ...basicInfoFields,
      ...shiftBasedFields,
      ...advancedInfoFields,
      ...metricsInfoFields,
    ]

    return {
      advancedInfoFields,
      basicInfoFields,
      metricsInfoFields,
      shiftBasedFields,
      bugleInputFields,
      allInputFields,
    }
  }, [certifications])
}
