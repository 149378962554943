import Stripe from 'stripe'

export enum PaymentSource {
  STRIPE = 'STRIPE',
}

export enum TransferStatus {
  SUCCEEDED = 'succeeded',
  PAID = 'paid',
  PENDING = 'pending',
  FAILED = 'failed',
  REVERSED = 'reversed',
}

export enum StripeEventType {
  PAYOUT_CREATED = 'payout.created',
  PAYOUT_UPDATED = 'payout.updated',
  PAYOUT_PAID = 'payout.paid',
  PAYOUT_FAILED = 'payout.failed',
  PAYOUT_CANCELED = 'payout.canceled',
  TRANSFER_CREATED = 'transfer.created',
  TRANSFER_REVERSED = 'transfer.reversed',
  TRANSFER_PAID = 'transfer.paid',
  TRANSFER_FAILED = 'transfer.failed',
  INVOICE_CREATED = 'invoice.created',
  INVOICE_DELETED = 'invoice.deleted',
  INVOICE_FINALIZED = 'invoice.finalized',
  INVOICE_MARKED_UNCOLLECTIBLE = 'invoice.marked_uncollectible',
  INVOICE_VOIDED = 'invoice.voided',
  INVOICE_PAID = 'invoice.paid',
  INVOICE_PAYMENT_SUCCEEDED = 'invoice.payment_succeeded',
  INVOICE_PAYMENT_FAILED = 'invoice.payment_failed',
  INVOICE_PAYMENT_ACTION_REQUIRED = 'invoice.payment_action_required',
}

export enum PayoutStatus {
  PAID = 'paid',
  PENDING = 'pending',
  FAILED = 'failed',
  CANCELED = 'canceled',
  IN_TRANSIT = 'in_transit',
}

export enum PayoutMethod {
  INSTANT_PAYOUT = 'instant',
  STANDARD = 'standard',
}

export enum TransactionType {
  TRANSFER = 'TRANSFER',
  PAYOUT = 'PAYOUT',
}

/**
 * HOURLY_SHIFT_PAY = 'hourly_shift_pay', // All shift transfers including adjustments
 * WORKER_SIGNUP_INCENTIVE = 'worker_signup_incentive', TODO_MRN Ops only for now
 * WORKER_ENGAGEMENT = 'worker_engagement', // All generic incentives
 * WORKER_APPEASEMENT = 'worker_appeasement', // Set at the `/appease` flow
 * REFERRAL_INCENTIVE = 'referral_incentive', // Any incentive related to a referrer or referee
 * WORKER_CHARGE = 'worker_charge',  TODO_MRN Ops only for now
 * BUSINESS_BUSINESS_REFERRAL = 'business_business_referral', TODO_MRN Ops only for now
 * WORKER_BUSINESS_REFERRAL = 'worker_business_referral', TODO_MRN Ops only for now
 */
export enum TransferType {
  HOURLY_SHIFT_PAY = 'hourly_shift_pay',
  WORKER_SIGNUP_INCENTIVE = 'worker_signup_incentive',
  WORKER_ENGAGEMENT = 'worker_engagement',
  WORKER_APPEASEMENT = 'worker_appeasement',
  REFERRAL_INCENTIVE = 'referral_incentive',
  WORKER_CHARGE = 'worker_charge',
  BUSINESS_BUSINESS_REFERRAL = 'business_business_referral',
  WORKER_BUSINESS_REFERRAL = 'worker_business_referral',
}

export interface Transaction {
  id: string // event Id (py_ or tr_)
  stripeId: string // stripe connect account Id
  type: TransactionType
  source: PaymentSource
  destination:
    | string
    | Stripe.Account
    | Stripe.BankAccount
    | Stripe.DeletedBankAccount
    | Stripe.Card
    | Stripe.DeletedCard
    | null // saved as `destination`, `created`, `amount` to mimick stripe's response
  created: Date
  amount: number
  currency: string
  status: TransferStatus | PayoutStatus
  method?: PayoutMethod
  reversed?: boolean
  amount_reversed?: number
  reversals?: { data: TransferReversal[]; total_count?: number }
  relatedTransferIds: Array<string>
}

// A paired down version of Stripe.TransferReversal
export interface TransferReversal {
  id: string // reversal id `tr`
  destination_payment_refund: string | Stripe.Refund | null // destination Id, account or debit card
  created: Date
  amount: number
  currency: string
}

export interface StripeMetadata {
  workerId?: string
  shiftId?: string
  workerIncentiveId?: string
  documentType?: string
}

export enum StripePaymentStatus {
  NO_ACCOUNT = 'NO_ACCOUNT',
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
}

export enum PaymentValidationError {
  MISSING_STRIPE_ID = 'missing-stripe-id',
  MISSING_PAYMENT_METHOD = 'missing-payment-method',
  NO_WORKED_MINUTES = 'no-worked-minutes',
  PAY_BELOW_MIN = 'pay-below-min',
  INVALID_SHIFT_OVERTIME = 'invalid-shift-overtime',
}

export enum TransactionDocumentType {
  WORKER_SHIFT = 'worker_shift',
  WORKER_INCENTIVE = 'worker_incentive',
  WORKER_DEBIT = 'worker_debit',
}
