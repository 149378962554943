import { InputAdornment, TextField } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useState } from 'react'

import { InlineBanner } from './InlineBanner/InlineBanner'

interface DefaultPayRateComponentProps {
  defaultPayRate: number
  setDefaultPayRate: (num: number) => void
  previousRoleId?: string
  minHourlyPayRate: number
}

export const DefaultPayRateComponent = (
  props: DefaultPayRateComponentProps,
) => {
  const { defaultPayRate, setDefaultPayRate, previousRoleId } = props

  const [maskedDefaultPayRate, setMaskedDefaultPayRate] = useState(
    defaultPayRate.toFixed(2),
  )
  const [touchedDefaultPayRate, setTouchedDefaultPayRate] = useState(false)

  const onDefaultPayRateChange = (masked: string) => {
    const payRate = +Math.abs(+masked).toFixed(2)
    setMaskedDefaultPayRate(masked)
    setDefaultPayRate(payRate)
  }

  const onDefaultPayRateBlur = () => {
    setMaskedDefaultPayRate(defaultPayRate.toFixed(2))
    setTouchedDefaultPayRate(true)
  }

  useEffect(() => {
    setMaskedDefaultPayRate(defaultPayRate.toFixed(2))
  }, [previousRoleId])

  const defaultPayRateValidationError = defaultPayRate < props.minHourlyPayRate

  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        What should be the default hourly pay for this role?
      </Text>
      <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
        The default hourly pay rate will fill the initial pay rate when creating
        shifts with this role. The user creating the shift will still be able to
        update the pay rate.
      </Text>
      <TextField
        id="payrate"
        label="Default Pay Rate"
        type="number"
        style={{
          width: 200,
          marginTop: theme.space.sm,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Text variant="body1">$</Text>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Text variant="body1">/ hr</Text>
            </InputAdornment>
          ),
        }}
        value={maskedDefaultPayRate}
        onChange={(e) => onDefaultPayRateChange(e.currentTarget.value)}
        onBlur={onDefaultPayRateBlur}
      />
      {touchedDefaultPayRate && defaultPayRateValidationError && (
        <InlineBanner
          severity="error"
          text={`The hourly rate must be $${props.minHourlyPayRate} or greater.`}
          style={{ marginTop: theme.space.xs }}
        />
      )}
    </>
  )
}
