import { CircularProgress } from '@mui/material'
import { CoordinatesDto } from '@traba/types'
import { useMemo } from 'react'
import { useApi } from 'src/hooks/useApi'
import { ONE_HOUR_IN_MS } from 'src/libs/constants'
import { getDistanceBetweenCoords } from 'src/utils/helperUtils'

export default function WorkerCoordinate({
  workerZipCode,
  shiftCoords,
}: {
  workerZipCode: string
  shiftCoords: CoordinatesDto
}) {
  const { data: coordinates, isLoading: isLoadingCoordinates } =
    useApi<CoordinatesDto>(
      `/google/geocode/${workerZipCode}`,
      {},
      {
        staleTime: ONE_HOUR_IN_MS,
        cacheTime: ONE_HOUR_IN_MS,
      },
    )
  const distanceFromShift = useMemo(
    () =>
      getDistanceBetweenCoords(
        coordinates || shiftCoords,
        shiftCoords,
        true,
      ).toFixed(1),
    [coordinates, shiftCoords],
  )
  return isLoadingCoordinates ? (
    <CircularProgress size={16} />
  ) : coordinates ? (
    `${distanceFromShift}`
  ) : (
    '-'
  )
}
