import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { RulesFlag } from '@traba/types'
import { ChangeEvent, useState } from 'react'
import { Modal, Col, Row, Select, Input } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getErrorMessage } from 'src/utils/errorUtils'

type FraudulentModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift: WorkerShift
}

export const FraudulentModal = (props: FraudulentModalProps) => {
  const { handleClose, isOpen, workerShift } = props
  const { showError, showSuccess } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()
  const { state } = useUserContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [rulesFlag, setRulesFlag] = useState<string>(
    workerShift.rulesFlag ?? RulesFlag.Undetermined,
  )

  const rulesFlagItems = [
    { value: RulesFlag.Undetermined, label: 'Undetermined' },
    { value: RulesFlag.Fraudulent, label: 'Fraudulent' },
    { value: RulesFlag.Clear, label: 'Clear' },
  ]

  //Fraud Types
  const fraudTypes = [
    { value: 'worker_time_fraud', label: 'Worker Time Fraud' },
    {
      value: 'worker_didnt_attend_shift',
      label: 'Worker Did Not Attend Shift',
    },
    { value: 'Other', label: 'Other' },
  ]
  const [fraudType, setFraudType] = useState<string>(fraudTypes[0].value)
  const [freeFormFraudType, setFreeFormFraudType] = useState<string>('')

  //Fraud Discovery Method
  const discoveredByMethods = [
    { value: 'location_data', label: 'Location Data' },
    {
      value: 'clock_times',
      label: 'Clock In / Clock Out Time',
    },
    {
      value: 'in_person',
      label: 'In Person',
    },
    { value: 'Other', label: 'Other' },
  ]
  const [discoveredBy, setDiscoveredBy] = useState<string>(
    discoveredByMethods[0].value,
  )
  const [freeFormDiscoveredBy, setFreeFormDiscoveredBy] = useState<string>('')
  const [notes, setNotes] = useState<string>('')

  const handleSetRulesFlag = async () => {
    setLoading(true)
    try {
      await trabaApi.patch(
        `/workers/${workerShift.workerId}/worker-shifts/${workerShift.shiftId}`,
        {
          rulesFlag,
        },
      )
      await refetchActiveQueries()

      //Tracking call
      window.analytics.track(`Set Worker Shift RulesFlag to ${rulesFlag}`, {
        workerId: workerShift.workerId,
        shiftId: workerShift.shiftId,
        rulesFlag,
        ...(rulesFlag === RulesFlag.Fraudulent
          ? {
              fraudType: fraudType === 'Other' ? freeFormFraudType : fraudType,
              discoveredBy:
                discoveredBy === 'Other' ? freeFormDiscoveredBy : discoveredBy,
            }
          : {}),
        notes,
        flaggedBy: state.userProfile?.email,
      })

      showSuccess(
        'Successfully updated worker shift',
        `Successfully set worker shift ${workerShift.workerId}_${workerShift.shiftId} to ${rulesFlag}`,
      )
      setNotes('')
    } catch (e) {
      showError(getErrorMessage(e), 'Error Patching Worker Shift')
    }
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={`Flag Worker Shift`}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Row style={{ flex: 1, justifyContent: 'center' }} fullWidth>
          <Col>
            <Text variant="h5" style={{ marginBottom: theme.space.med }}>
              Set {workerShift.worker?.firstName} {workerShift.worker?.lastName}
              's shift flag
            </Text>
            <Row style={{ marginBottom: '24px' }} fullWidth alignCenter>
              <Select
                label="Shift Flag"
                menuItems={rulesFlagItems}
                value={rulesFlag}
                handleSelect={setRulesFlag}
                containerStyle={{ width: '50%', marginRight: '24px' }}
              />
            </Row>
            {rulesFlag === RulesFlag.Fraudulent && (
              <>
                <Col mb={theme.space.sm}>
                  <Select
                    fullWidth
                    label="Fraud Type"
                    menuItems={fraudTypes}
                    value={fraudType}
                    handleSelect={setFraudType}
                  />
                  {fraudType === 'Other' && (
                    <Row mt={theme.space.xs}>
                      <Input
                        full
                        label="Fraud Type in Your Words"
                        value={freeFormFraudType}
                        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                          setFreeFormFraudType(ev.target.value)
                        }
                      />
                    </Row>
                  )}
                </Col>
                <Col>
                  <Select
                    fullWidth
                    label="How Did you Discover the Fraud?"
                    menuItems={discoveredByMethods}
                    value={discoveredBy}
                    handleSelect={setDiscoveredBy}
                  />
                  {discoveredBy === 'Other' && (
                    <Row mt={theme.space.xs}>
                      <Input
                        full
                        label="Method of Discovery In Your Words"
                        value={freeFormDiscoveredBy}
                        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                          setFreeFormDiscoveredBy(ev.target.value)
                        }
                      />
                    </Row>
                  )}
                </Col>
              </>
            )}
            <Row mt={theme.space.xs}>
              <Input
                full
                label="Notes (Optional)"
                value={notes}
                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  setNotes(ev.target.value)
                }
              />
            </Row>
          </Col>
        </Row>
        <ModalButtons
          handleClose={handleClose}
          handleConfirm={handleSetRulesFlag}
          loading={loading}
        />
      </Col>
    </Modal>
  )
}
