import { CoordinatesDto } from './locations'

export enum GeofenceType {
  CIRCLE = 'CIRCLE',
}

export enum GeofenceUseCase {
  SHIFT_AREA_FENCE = 'SHIFT_AREA_FENCE',
}

export type UpdateShiftAreaFenceDto = {
  type: GeofenceType
  circleRadiusMeters?: number
  circleCoordinates?: CoordinatesDto
}

export type GetGeoFenceDto = {
  locationId: string
  type: GeofenceType
  createdAt: Date
  updatedAt: Date
  useCase: GeofenceUseCase
  circleRadiusMeters?: number
  circleCoordinates?: CoordinatesDto
}
