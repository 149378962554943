import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { FixedLengthArray } from '@traba/types'
import { getErrorMessage } from '@traba/utils'
import { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

export function trabaApiRequest<T>(
  apiMethod: 'get' | 'post' | 'patch' | 'put' | 'delete',
  apiPath: string,
  apiConfig?: AxiosRequestConfig,
): () => Promise<T> {
  return async () => {
    const res = await trabaApi[apiMethod](
      apiPath,
      apiConfig?.data ?? undefined,
      apiConfig,
    )
    return res.data
  }
}

export const useApi = <T>(
  apiPath: string,
  apiConfig?: AxiosRequestConfig,
  overrideConfig: UseQueryOptions<T, Error, T, QueryKey> = {},
) => {
  const { data, isLoading, isFetching, isFetched, refetch } = useQuery<
    T,
    Error
  >([apiPath], trabaApiRequest<T>('get', apiPath, apiConfig), overrideConfig)

  return {
    data,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  }
}

export function getQueryParams(
  params: FixedLengthArray<[string, string | null | undefined | boolean]>[],
) {
  const paramsToAdd: string[] = []

  for (const queryParam of params) {
    if (typeof queryParam[1] === 'boolean') {
      paramsToAdd.push(`${queryParam[0]}=${queryParam[1].toString()}`)
      continue
    }
    if (queryParam[1]) {
      paramsToAdd.push(`${queryParam[0]}=${queryParam[1]}`)
    }
  }

  if (!paramsToAdd.length) {
    return ''
  }
  return `?${paramsToAdd.join('&')}`
}

/**
 * Helper hook that handles API call + feedback aspects, which include:
 * loading flag state, success and error alerts.
 */
export const useApiWithFeedback = <T>(
  method: 'get' | 'post' | 'patch' | 'put' | 'delete',
  url: string,
  successMessage?: string,
  config: AxiosRequestConfig = {},
) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useAlert()

  async function apiCall(data?: Record<string, unknown>) {
    try {
      setIsLoading(true)
      if (data) {
        config.data = data
      }
      const resData = await trabaApiRequest<T>(method, url, config)()
      if (successMessage) {
        showSuccess(successMessage, 'Success')
      }
      return resData
    } catch (err: unknown) {
      showError(getErrorMessage(err), 'Error')
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    apiCall,
  }
}
