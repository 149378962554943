import { Text } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CustomizedBadgeVariant } from '@traba/types'
import { Back } from 'src/assets/svg/icons/Back'
import { Avatar, Badge, Col, Icon, Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'

export const getBadgeDetails = (variant: CustomizedBadgeVariant) => {
  switch (variant) {
    case CustomizedBadgeVariant.GREEN:
      return {
        variant,
        badgeStyleVariant: 'outlineGreen',
        badgeText: 'Positive',
      }
    case CustomizedBadgeVariant.RED:
      return {
        variant,
        badgeStyleVariant: 'outlineRed',
        badgeText: 'Negative',
      }
    case CustomizedBadgeVariant.YELLOW:
      return {
        variant,
        badgeStyleVariant: 'outlineYellow',
        badgeText: 'Warning',
      }
    case CustomizedBadgeVariant.VIOLET:
      return {
        variant,
        badgeStyleVariant: 'outlineViolet',
        badgeText: 'Info',
      }
  }
}

interface Props {
  selectedVariant: CustomizedBadgeVariant | null
  badgeName: string
  shortDescription: string
  longDescription: string
}

export const BadgePreview = ({
  selectedVariant,
  badgeName,
  shortDescription,
  longDescription,
}: Props) => {
  return (
    <Row
      style={{
        border: `2px solid ${theme.colors.Grey30}`,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
        flex: 1,
      }}
    >
      <Col>
        <Text variant="h5">Preview</Text>
        <Text variant="h6" mt={theme.space.xs}>
          Shift card in Find shifts
        </Text>
        <Card>
          <Row alignCenter justifyBetween>
            <Text variant="h6">Warehouse Associate</Text>
            <Text variant="h6" style={{ color: theme.colors.Violet }}>
              $128.00
            </Text>
          </Row>
          <Row alignCenter justifyBetween>
            <Text variant="body2">07:00 AM - 03:00 PM</Text>
            <Text variant="body2" color={theme.colors.Violet70}>
              $16/hr
            </Text>
          </Row>

          <Row alignCenter justifyBetween>
            <Text variant="body2">Miami, United States</Text>
            <Text variant="body2" color={theme.colors.Violet70}>
              8h
            </Text>
          </Row>
          <Text variant="body2">5.2 mi</Text>
          <Row mt={theme.space.xxs}>
            {selectedVariant && (
              <Badge
                disableUppercase
                variant={getBadgeDetails(selectedVariant).badgeStyleVariant}
                title={badgeName}
              />
            )}
          </Row>
        </Card>
        <Text variant="h6" mt={theme.space.xs}>
          Shift details section
        </Text>
        <Card>
          <Back size={18} color={theme.colors.MidnightBlue} />
          <Row alignCenter justifyBetween>
            <Text variant="h4">Warehouse Associate</Text>
            <Avatar name={'C'} size={50} />
          </Row>
          <Row alignCenter>
            <Icon name="company" />
            <Text ml={theme.space.xxs} variant="body2">
              Food Production Company
            </Text>
          </Row>
          <Row alignCenter pb={theme.space.xs}>
            <Icon name="dollarRound" />
            <Text ml={theme.space.xxs} variant="body2">
              $128.00
            </Text>
          </Row>
          <Divider />
          <Text variant="body2" pt={theme.space.xs}>
            Nov 3, 2024, 07:00 AM - 03:00 PM
          </Text>
          <Text variant="body2">Miami, United States • 5.2 mi</Text>
          <Row mt={theme.space.xxs}>
            {selectedVariant && (
              <Badge
                disableUppercase
                variant={getBadgeDetails(selectedVariant).badgeStyleVariant}
                title={`${badgeName}: ${shortDescription}`}
              />
            )}
          </Row>
          {longDescription && (
            <>
              <Text variant="h7" mt={theme.space.xl}>
                Shown in bottomsheet:
              </Text>
              <Text variant="body2">{longDescription}</Text>
            </>
          )}
        </Card>
      </Col>
    </Row>
  )
}
