import { LinkText, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShift } from '@traba/types'
import { useNavigate } from 'react-router-dom'

interface Props {
  addedToSchedule: boolean
  fulfilled: WorkerShift[]
  rejected: string[]
}

export const AddWorkerToNoEndScheduleResults = (props: Props) => {
  const { addedToSchedule, fulfilled, rejected } = props
  const navigate = useNavigate()
  return (
    <>
      {addedToSchedule ? (
        <Text color={theme.colors.Green80}>
          Worker has been added to recurring schedule
        </Text>
      ) : (
        <Text variant="error">Worker is not added to recurring schedule </Text>
      )}

      {fulfilled.length > 0 && !addedToSchedule && (
        <>
          <Text color={theme.colors.Green80}>
            However, worker is added to following shifts:
          </Text>
          {fulfilled.map((r) => (
            <LinkText
              key={`fulfilled-${r.shiftId}`}
              onClick={() => {
                navigate(`/field-monitor/${r.shiftId}`)
              }}
            >
              {r.shiftId}
            </LinkText>
          ))}
        </>
      )}
      {rejected.length > 0 && addedToSchedule && (
        <>
          <Text variant="error">
            However, worker is NOT added to following shifts:
          </Text>
          {rejected.map((r) => (
            <LinkText
              key={`rejected-${r}`}
              onClick={() => {
                navigate(`/field-monitor/${r.split(':')[0]}`)
              }}
            >
              {r}
            </LinkText>
          ))}
        </>
      )}
    </>
  )
}
