import copyToClipboard from 'copy-to-clipboard'
import { useState } from 'react'
import { Button } from '../Button'
import Icon from '../Icon'

export type CopyTextIconProps = {
  textToCopy?: string
  fetchCopyText?: () => Promise<string | undefined>
  disabled?: boolean
  iconColor?: string
}

export default function CopyTextIcon(props: CopyTextIconProps) {
  const [showSuccessIcon, setShowSuccessIcon] = useState(false)
  const [showFailIcon, setShowFailIcon] = useState(false)

  async function onPressCopy() {
    const fetchedText = await props.fetchCopyText?.()
    if (!fetchedText && !props.textToCopy) {
      setShowFailIcon(true)
      setTimeout(() => {
        setShowFailIcon(false)
      }, 1000)
      return
    }
    copyToClipboard(props.textToCopy || fetchedText || '')
    setShowSuccessIcon(true)
    setTimeout(() => {
      setShowSuccessIcon(false)
    }, 1000)
  }
  return (
    <Button
      style={{
        padding: 0,
        display: 'inline',
        marginLeft: '5px',
        backgroundColor: 'transparent',
      }}
      onClick={async (event) => {
        event.stopPropagation()
        onPressCopy()
      }}
      disabled={props.disabled}
    >
      <Icon
        name={showSuccessIcon ? 'checkmark' : showFailIcon ? 'error' : 'copy'}
        type={showSuccessIcon ? 'img' : showFailIcon ? 'img' : 'svg'}
        size={14}
        color={props.iconColor}
      />
    </Button>
  )
}
