import { GroupedLocationSingleSearchSelector } from '@traba/react-components'
import { useRegionLocationMap } from 'src/hooks/useCompanyLocations'
import { useValidRegionsMap } from 'src/hooks/useRegions'

interface Props {
  companyId: string
  selectedLocationId?: string
  disabled?: boolean
  onChange?: (locationId: string | undefined) => void
  errorMessage?: string
  onBlur?: () => void
  placeholder?: string
  label?: string
  hideCompanyWideOption?: boolean
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  showClearButton?: boolean
}

export function LocationSingleSearchSelector({
  companyId,
  selectedLocationId,
  disabled,
  onChange,
  errorMessage,
  onBlur,
  placeholder,
  label,
  hideCompanyWideOption,
  style,
  selectStyle,
  showClearButton,
}: Props) {
  const { isLoading: isLocationsLoading, activeRegionsWithLocationsMap } =
    useRegionLocationMap(companyId)
  const { isLoading: isRegionsLoading, regionMap } = useValidRegionsMap()

  return (
    <GroupedLocationSingleSearchSelector
      selectedLocationId={selectedLocationId}
      disabled={disabled}
      onChange={onChange}
      errorMessage={errorMessage}
      onBlur={onBlur}
      regionMap={regionMap}
      regionToLocationsMap={activeRegionsWithLocationsMap}
      isLoading={isLocationsLoading || isRegionsLoading}
      companyWideOptionSecondaryLabel="Default Setting. The role can be used in any location."
      placeholder={placeholder}
      label={label}
      hideCompanyWideOption={hideCompanyWideOption}
      style={style}
      selectStyle={selectStyle}
      showClearButton={showClearButton}
    />
  )
}
