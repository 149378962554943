import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { WorkerScores } from '@traba/types'

export default function WorkerCompletions({
  workerScores,
  companyId,
  roleId,
  short = true,
}: {
  workerScores: WorkerScores | undefined
  companyId?: string
  roleId?: string
  short?: boolean
}) {
  const totalShiftsCompleted = workerScores?.numberOfCompletions ?? 0

  const shiftsCompletedForCompany = companyId
    ? (workerScores?.companyCounts?.find(
        (companyCount) => companyCount.companyId === companyId,
      )?.count ?? 0)
    : undefined
  const shiftsCompletedForRole = roleId
    ? (workerScores?.roleCounts?.find(
        (roleCount) => roleCount.roleId === roleId,
      )?.count ?? 0)
    : undefined

  return (
    <Tooltip
      title={`Shifts completed (${shiftsCompletedForRole ? 'role/' : ''}${shiftsCompletedForCompany ? 'company/' : ''}total)`}
    >
      <Text>
        {shiftsCompletedForRole !== undefined
          ? `${shiftsCompletedForRole}/`
          : ''}
        {shiftsCompletedForCompany !== undefined
          ? `${shiftsCompletedForCompany}/`
          : ''}
        {totalShiftsCompleted}
        {!short ? ` shift${makePlural(totalShiftsCompleted)}` : ''}
      </Text>
    </Tooltip>
  )
}
