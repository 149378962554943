import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'src/components/base'
import { useShift } from 'src/hooks/useShifts'
import { JobStatus } from 'src/types'
import { CompanyLogo } from './CompanyLogo/CompanyLogo'
import JobStatusBadge from './JobStatusBadge'
type ShiftTileProps = {
  shiftId: string
  showCompanyName?: boolean
  showShortAddress?: boolean
  navigateToShiftDetailsOnClick?: boolean
  showJobStatus?: boolean
  jobStatus?: JobStatus
}

export const ShiftTile = (props: ShiftTileProps) => {
  const {
    shiftId,
    showCompanyName = true,
    showShortAddress = true,
    showJobStatus = false,
    jobStatus,
  } = props
  const { shift } = useShift(shiftId)
  const navigate = useNavigate()
  const navigateToShiftDetails = useCallback(() => {
    navigate(`/field-monitor/${shiftId}`)
  }, [shiftId, navigate])

  if (!shift) {
    return (
      <CircularProgress
        size={36}
        sx={{
          color: 'ButtonHighlight',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-18px',
          marginLeft: '-18px',
        }}
      />
    )
  }

  return (
    <CardTile
      style={{
        width: '100%',
        minWidth: '300px',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '16px',
        backgroundColor: theme.colors.White,
        height: '100%',
        cursor: props.navigateToShiftDetailsOnClick ? 'pointer' : 'default',
      }}
      onClick={
        props.navigateToShiftDetailsOnClick ? navigateToShiftDetails : undefined
      }
    >
      <Row alignCenter>
        <CompanyLogo
          style={{
            width: '40px',
            height: '40px',
            marginRight: '10px',
            fontSize: '32px',
            alignSelf: 'start',
          }}
          companyName={shift.employerName}
          companyLogoUrl={shift?.companyLogo}
        />
        <Col>
          <Text variant="h5">{shift.shiftRole}</Text>
          {showCompanyName && <Text variant="body2">{shift.employerName}</Text>}
          <Text variant="caption">
            {getShiftDateString(shift.startTime, shift.endTime, shift.timezone)}
            ,{' '}
            {getShiftTimeString(shift.startTime, shift.endTime, shift.timezone)}
          </Text>

          {showShortAddress && (
            <Text variant="caption">{shift.location.shortLocation}</Text>
          )}

          {showJobStatus && jobStatus && (
            <JobStatusBadge jobStatus={jobStatus} />
          )}
        </Col>
      </Row>
    </CardTile>
  )
}
