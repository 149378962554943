import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Button, Col, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  wardenExemptionsInfoMap,
  wardenHardRulesInfoMap,
} from 'src/types/warden'
import styled from 'styled-components'

type WardenExemptionInfoModalProps = {
  isOpen: boolean
  handleClose: () => void
}

const SuperWardenInfoCard = styled(Col)`
  border: 1px solid ${theme.border.color};
  border-radius: ${theme.border.radius};
  height: 100%;
`

const SuperWardenInfoComponent = (props: {
  title: string
  description: string
}) => {
  const { title, description } = props
  return (
    <Col
      style={{
        'min-width': '50%',
        'max-width': '50%',
        padding: `${theme.space.xxs}px`,
      }}
    >
      <SuperWardenInfoCard>
        <Row
          p={theme.space.xs}
          style={{ 'border-bottom': `1px solid ${theme.border.color}` }}
        >
          <Text variant="h5">{title}</Text>
        </Row>

        <Text p={theme.space.xs} variant="body1">
          {description}
        </Text>
      </SuperWardenInfoCard>
    </Col>
  )
}

export const WardenExemptionInfoModal = (
  props: WardenExemptionInfoModalProps,
) => {
  const { isOpen, handleClose } = props
  return (
    <Modal
      title="Warden Exemption Info"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Text variant="h4" mb={theme.space.xxs}>
        Hard Rules
      </Text>
      <Text variant="body1">
        Hard rules will automatically halt a payment from processing if they are
        broken without the appropriate bypass.
      </Text>
      <Row wrap pb={theme.space.xs} fullWidth justifyBetween>
        {Object.values(wardenHardRulesInfoMap).map((hardRule) => (
          <SuperWardenInfoComponent
            key={hardRule.title}
            title={
              hardRule.bypassable
                ? `${hardRule.title} (Bypassable)`
                : hardRule.title
            }
            description={hardRule.description}
          />
        ))}
      </Row>
      <Text variant="h4" mb={theme.space.xxs}>
        Warden Exemptions
      </Text>
      <Text variant="body1">
        Warden exemptions can be used to bypass worker shifts from hard rules
        engine or model inference. Hard rule exemptions will stop superwarden
        from blocking a payment due to a violation of the respective hard rule.
        Model exemptions will process the paynent assuming no hard rules are
        violated, effectively bypassing the ML models output.
      </Text>

      <Row wrap pb={theme.space.sm}>
        {Object.values(wardenExemptionsInfoMap).map((exemption) => (
          <SuperWardenInfoComponent
            key={exemption.title}
            title={exemption.title}
            description={exemption.description}
          />
        ))}
      </Row>

      <Button
        type="button"
        variant={ButtonVariant.OUTLINED}
        onClick={handleClose}
      >
        Close
      </Button>
    </Modal>
  )
}
