import { Z_INDEXES } from '@traba/theme'
import styled, { css } from 'styled-components'

interface ListContainerProps {
  orientation?: 'right' | 'left'
  verticalPosition?: 'top' | 'bottom'
}

type ListItemProps = {
  disabled?: boolean
}

export const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
`

export const ListContainer = styled.div<ListContainerProps>`
  position: absolute;
  ${({ verticalPosition = 'bottom' }) =>
    verticalPosition === 'top' ? 'bottom: 100%' : 'top: 100%'};
  margin-bottom: 8px;
  ${({ orientation }) => (orientation === 'left' ? 'right: 50%' : 'left: 70%')};
  width: 222px;
  z-index: ${Z_INDEXES.DROPDOWN_LIST_CONTAINER};
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.Grey20};
  box-shadow:
    0px 24px 32px rgba(8, 16, 94, 0.08),
    0px 1px 1px rgba(196, 204, 212, 0.24);
  border-radius: 8px;
`

export const ListStyling = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const ListItem = styled.li<ListItemProps>`
  padding: 8px 12px;
  :hover {
    background-color: ${({ theme }) => theme.colors.Violet10};
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey10};
      color: ${({ theme }) => theme.colors.Grey60};
      cursor: default;

      :hover {
        background-color: ${({ theme }) => theme.colors.Grey10};
        color: ${({ theme }) => theme.colors.Grey60};
        cursor: default;
      }
    `}
`
