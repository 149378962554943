import { Table, Td, Text, Tr } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  InstantPayEligibilityStatus,
  JobStatus,
  WorkerShiftAsTimesheetRow,
} from '@traba/types'
import { differenceInMinutes } from 'date-fns'
import { useMemo, useState } from 'react'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { formatDuration } from 'src/utils/dateUtils'
import { TimesheetAdjustment } from '../timesheet.types'
import { calculateBreakTime } from '../utils'

const WORKER_ELIGIBLE_FOR_INSTANT_PAY = new Set([
  InstantPayEligibilityStatus.ELIGIBLE,
  InstantPayEligibilityStatus.ELIGIBLE_WITH_WARNING,
])

function workerCanGetInstantPay(
  workerShiftAdjustments: TimesheetAdjustment[],
): boolean {
  return workerShiftAdjustments.some(
    (ws) =>
      ws.accountStatus?.payment?.instantPayEnabled &&
      ws.accountStatus?.payment?.instantPayEligibility.status &&
      WORKER_ELIGIBLE_FOR_INSTANT_PAY.has(
        ws.accountStatus.payment.instantPayEligibility.status,
      ),
  )
}

type AdjustmentFormProps = {
  handleClose: () => void
  parsedData: WorkerShiftAsTimesheetRow[]
  workerShiftAdjustments: TimesheetAdjustment[]
  unitsPerWorker?: number
  prevTotalPay: number
  prevMinutesWorked: number
  newTotalPay: number
  newMinutesWorked: number
  handleAdjustments: (params: {
    shouldAdjustPayment: boolean
    shouldInstantPay: boolean
    workerShiftAdjustments: TimesheetAdjustment[]
  }) => Promise<void>
  useBreaks: boolean
}

export function TimesheetAdjustmentForm({
  handleClose,
  parsedData,
  workerShiftAdjustments,
  handleAdjustments,
  prevMinutesWorked,
  prevTotalPay,
  newMinutesWorked,
  newTotalPay,
  useBreaks,
}: AdjustmentFormProps) {
  /*
  States
  */
  const [shouldAdjustPayment, setShouldAdjustPayment] = useState<boolean>(false)
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onConfirm = async () => {
    setLoading(true)
    await handleAdjustments({
      shouldAdjustPayment,
      shouldInstantPay,
      workerShiftAdjustments,
    })
    setLoading(false)
  }

  const timeWorkedPerWorker = useMemo(() => {
    const timeWorkedInMinutes = parsedData
      .filter(
        (
          data,
        ): data is WorkerShiftAsTimesheetRow & {
          clockInDate: string
          clockOutDate: string
          clockInTime: string
          clockOutTime: string
        } => {
          const {
            clockInDate,
            clockInTime,
            clockOutDate,
            clockOutTime,
            jobStatus,
          } = data
          return (
            clockInDate !== undefined &&
            clockInTime !== undefined &&
            clockOutDate !== undefined &&
            clockOutTime !== undefined &&
            jobStatus === JobStatus.Complete
          )
        },
      )
      .map((ws) => {
        const breakTime = useBreaks
          ? calculateBreakTime(ws.breaks)
          : ws.breakTime
        return {
          workerId: ws.workerId,
          workerName: ws.workerName,
          timeWorked:
            differenceInMinutes(
              new Date(`${ws.clockOutDate} ${ws.clockOutTime}`),
              new Date(`${ws.clockInDate} ${ws.clockInTime}`),
            ) - breakTime,
        }
      })
      .reduce<Record<string, { workerName: string; timeWorked: number }>>(
        (acc, { workerId, workerName, timeWorked }) => {
          acc[workerId] = {
            workerName,
            timeWorked: (acc[workerId]?.timeWorked || 0) + timeWorked,
          }
          return acc
        },
        {},
      )

    return Object.values(timeWorkedInMinutes)
      .map(({ workerName, timeWorked }) => [
        workerName,
        (timeWorked / 60).toFixed(2),
      ])
      .sort(([a], [b]) => a.localeCompare(b))
  }, [parsedData])

  return (
    <Row flexCol fullWidth fullHeight justifyBetween>
      <>
        <Row alignCenter justifyBetween>
          <Col mb={theme.space.med}>
            <Text variant="h5">Total Hours:</Text>
            <Text variant="body2">
              Summed across all shifts in uploaded timesheet
            </Text>
          </Col>

          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5" mb={theme.space.med}>
              {formatDuration(newMinutesWorked)}
            </Text>
            <Text
              variant="h5"
              mb={theme.space.med}
              style={{ textDecoration: 'line-through' }}
            >
              {formatDuration(prevMinutesWorked)}
            </Text>
          </Row>
        </Row>
        <Row alignCenter justifyBetween>
          <Col mb={theme.space.med}>
            <Text variant="h5">Total Pay:</Text>
            <Text variant="body2">
              Summed across all shifts in uploaded timesheet
            </Text>
          </Col>
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h5" mb={theme.space.med}>
              ${newTotalPay.toFixed(2)}
            </Text>
            <Text
              variant="h5"
              mb={theme.space.med}
              style={{ textDecoration: 'line-through' }}
            >
              ${prevTotalPay.toFixed(2)}
            </Text>
          </Row>
        </Row>
        <Row mt={theme.space.med}>
          <Col>
            <Table headers={['Worker Name', 'Total Hours Worked']}>
              {timeWorkedPerWorker.map(([workerName, timeWorked], i) => (
                <Tr key={i}>
                  <Td>{workerName}</Td>
                  <Td>{timeWorked}</Td>
                </Tr>
              ))}
            </Table>
          </Col>
        </Row>
      </>
      <Divider />
      <Row
        justifyBetween
        style={{
          padding: '36px',
        }}
      >
        <Checkbox
          checked={shouldAdjustPayment}
          label={'Should Adjust Payment'}
          onChange={(e) => {
            setShouldAdjustPayment(e.target.checked)
          }}
        />
        {shouldAdjustPayment &&
          workerCanGetInstantPay(workerShiftAdjustments) && (
            <Checkbox
              checked={shouldInstantPay}
              label={'Should Instant Pay'}
              onChange={(e) => {
                setShouldInstantPay(e.target.checked)
              }}
            />
          )}
      </Row>
      <Divider />
      <Row fullWidth mt={theme.space.med} justifyBetween>
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '200px' }}
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          style={{ width: '200px' }}
          onClick={onConfirm}
          loading={loading}
        >
          Submit Adjustment
        </Button>
      </Row>
    </Row>
  )
}
