import InfoIcon from '@mui/icons-material/InfoOutlined'
import { CircularProgress, IconButton } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, Col, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import { WardenExemptionsTable } from 'src/components/WardenExemptionsTable'
import { useCompanyWardenExemptions } from 'src/hooks/useWardenExemptions'
import { ArchiveWardenExemptionModal } from 'src/modals/ArchiveWardenExemptionModal/ArchiveWardenExemptionModal'
import { CreateWardenExemptionModal } from 'src/modals/CreateWardenExemptionModal/CreateWardenExemptionModal'
import { WardenExemptionInfoModal } from 'src/modals/WardenExemptionInfoModal/WardenExemptionInfoModal'
import { ExtendedWardenExemption } from 'src/types/warden'

type CompanyWardenExemptionsTabProps = {
  companyId: string
}

export const CompanyWardenExemptionsTab = ({
  companyId,
}: CompanyWardenExemptionsTabProps) => {
  const { handleError } = useAlert()

  const {
    companyWardenExemptions = [],
    error,
    isLoading,
    refetch,
  } = useCompanyWardenExemptions(companyId)

  const [
    isCreateWardenExemptionModalOpen,
    setIsCreateWardenExemptionModalOpen,
  ] = useState(false)

  const [exemptionToArchive, setExemptionToArchive] = useState<
    ExtendedWardenExemption | undefined
  >()

  const infoModal = useModal()

  if (error) {
    handleError(
      error,
      'WardenExemptionsTab',
      'Please try again',
      'Error fetching warden exemptions',
    )
  }

  return (
    <>
      <Col>
        <Row alignCenter justifyBetween mb={theme.space.xs}>
          <Row>
            <Text variant="h4">Warden Exemptions</Text>
            <IconButton
              style={{ padding: theme.space.xxxs }}
              onClick={() => infoModal.open()}
            >
              <InfoIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Row>

          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => setIsCreateWardenExemptionModalOpen(true)}
          >
            New Exemption
          </Button>
        </Row>
        {isLoading ? (
          <Row style={{ height: 500 }} alignCenter center>
            <Row style={{ top: 100 }}>
              <CircularProgress size={36} color={'primary'} />
            </Row>
          </Row>
        ) : (
          <WardenExemptionsTable
            wardenExemptions={companyWardenExemptions}
            onArchiveClick={setExemptionToArchive}
            hideCompany
          />
        )}
      </Col>
      <CreateWardenExemptionModal
        title="Create new exemption for company"
        isOpen={isCreateWardenExemptionModalOpen}
        setIsOpen={setIsCreateWardenExemptionModalOpen}
        companyId={companyId}
        refetch={refetch}
      />
      <ArchiveWardenExemptionModal
        exemptionToArchive={exemptionToArchive}
        setExemptionToArchive={setExemptionToArchive}
        refetch={refetch}
      />
      <WardenExemptionInfoModal {...infoModal} />
    </>
  )
}
