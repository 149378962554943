import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ScheduledBreak } from '@traba/types'
import { BreakType } from '@traba/types'
import { Shift } from '@traba/types'
import { Button, Col, Icon, Row, Select } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { SelectDropdown } from 'src/components/base/Select/Select'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { EditShiftField } from './EditShiftForm'

interface EditShiftsBreaksProps {
  shift: Shift
  scheduledBreaks: ScheduledBreak[]
  setScheduledBreaks: React.Dispatch<React.SetStateAction<ScheduledBreak[]>>
  breakType: BreakType
  setBreakType: React.Dispatch<React.SetStateAction<BreakType>>
}

function getBreakOptions() {
  const opt = []
  let len = 5
  while (len <= 60) {
    opt.push({ value: `${len}`, label: `${len} min` })
    len += 5
  }
  return opt
}

const BREAK_OPTIONS = getBreakOptions()

export function EditShiftsBreaks(props: EditShiftsBreaksProps) {
  const {
    shift,
    scheduledBreaks,
    setScheduledBreaks,
    breakType,
    setBreakType,
  } = props
  const { hotSettings } = useHotSettings()

  const breakOptions = [
    { value: BreakType.AUTO_UNPAID, label: 'Auto Unpaid' },
    ...(hotSettings?.enableReturnOfManualBreak
      ? [{ value: BreakType.MANUAL_UNPAID, label: 'Manual Unpaid' }]
      : []),
    { value: BreakType.PAID, label: 'Paid' },
  ]
  function findIndexByLength(length: number) {
    return scheduledBreaks.findIndex((b) => b.breakLength === length)
  }

  function increaseBreakCount(
    currBreak: ScheduledBreak | undefined,
    breaks: ScheduledBreak[],
    len: number,
  ): ScheduledBreak[] {
    if (!currBreak) {
      return [...breaks, { breakLength: len, count: 1 }]
    } else {
      return breaks.map((b) =>
        b.breakLength !== currBreak.breakLength
          ? b
          : { breakLength: len, count: b.count + 1 },
      )
    }
  }

  function decreaseBreakCount(
    currBreak: ScheduledBreak,
    breaks: ScheduledBreak[],
  ): ScheduledBreak[] {
    if (currBreak.count === 1) {
      return breaks.filter((b) => b.breakLength !== currBreak.breakLength)
    } else {
      return breaks.map((b) =>
        b.breakLength !== currBreak.breakLength
          ? b
          : { breakLength: currBreak.breakLength, count: b.count - 1 },
      )
    }
  }

  function addBreak(len: number) {
    const index = findIndexByLength(len)
    const existingBreak = index > -1 ? scheduledBreaks[index] : undefined
    setScheduledBreaks(increaseBreakCount(existingBreak, scheduledBreaks, len))
  }

  function editBreak(oldLen: number, newLen: number) {
    const oldIndex = findIndexByLength(oldLen)
    const newIndex = findIndexByLength(newLen)
    const oldBreak = scheduledBreaks[oldIndex]
    const newBreak = newIndex > -1 ? scheduledBreaks[newIndex] : undefined
    const withRemoval = decreaseBreakCount(oldBreak, scheduledBreaks)
    const withRemovalAndAddition = increaseBreakCount(
      newBreak,
      withRemoval,
      newLen,
    )
    setScheduledBreaks(withRemovalAndAddition)
  }

  function removeBreak(len: number) {
    const index = findIndexByLength(len)
    const existingBreak = scheduledBreaks[index]
    setScheduledBreaks(decreaseBreakCount(existingBreak, scheduledBreaks))
  }

  return (
    <Col>
      <Row fullWidth mb={theme.space.xs}>
        <EditShiftField
          label="Break Type"
          currentValue={breakType}
          originalValue={shift.breakType}
          setter={setBreakType}
          input={
            <Row style={{ width: 300 }}>
              <Select
                menuItems={breakOptions}
                fullWidth
                dropdownStyle={{ height: '48px' }}
                handleSelect={(bt) => setBreakType(bt as BreakType)}
                value={breakType}
              />
            </Row>
          }
        />
      </Row>
      <>
        <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
          Add or remove unpaid breaks
        </Text>
        <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
          Only unpaid breaks need to be added to ensure paid time is correctly
          calculated for workers at your shift.
        </Text>
        {scheduledBreaks
          .reduce<number[]>(
            (acc, curr) =>
              acc.concat(
                Array.from(Array(curr.count)).map(() => curr.breakLength),
              ),
            [],
          )
          .sort((a, b) => a - b)
          .map((len, i) => (
            <Row
              key={`${len}-${i}`}
              className="xs-12 sm-6"
              style={{
                columnGap: theme.space.xs,
                marginBottom: theme.space.xs,
              }}
            >
              <SelectDropdown
                key={`${len}-${i}-dropdown`}
                style={{ flex: 1 }}
                value={`${len}`}
                menuItems={BREAK_OPTIONS}
                placeholder="Duration"
                handleSelect={(val) => editBreak(len, +val)}
              />
              <Button
                variant={ButtonVariant.OUTLINED}
                onClick={() => removeBreak(len)}
                style={{
                  alignSelf: 'stretch',
                  padding: `0 ${theme.space.xs}px`,
                }}
              >
                <Icon name="trash" color={theme.colors.Grey50} />
              </Button>
            </Row>
          ))}
        <Button
          variant={ButtonVariant.OUTLINED}
          className="xs-12 sm-6"
          onClick={() => addBreak(30)}
        >
          Add break
        </Button>
      </>
    </Col>
  )
}
