import { createCalendar } from '@internationalized/date'
import { AriaDateFieldProps } from '@react-aria/datepicker'
import {
  DateSegment as AriaDateSegment,
  DateFieldState,
} from '@react-stately/datepicker'
import { DateValue } from '@react-types/datepicker'
import { theme } from '@traba/theme'
import { useEffect, useRef } from 'react'
import { useDateField, useDateSegment, useLocale } from 'react-aria'
import { useDateFieldState } from 'react-stately'
import styled from 'styled-components'

const StyledDateFieldDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${theme.space.xxs}px;
  padding-right: ${theme.space.xxxs}px;
  position: relative;
  height: 40px;
`

const StyledSegmentDiv = styled.div<{
  segment: AriaDateSegment
}>`
  font-size: ${theme.default.fontSize};
  padding-right: 1px;
  padding-left: 1px;
  margin-top: ${theme.space.xxxs}px;
  margin-bottom: ${theme.space.xxxs}px;
  margin-left: ${(props) =>
    props.segment.type == 'timeZoneName' ? theme.space.xxxs + 'px' : '0'};
  margin-right: ${(props) =>
    props.segment.type === 'literal' && props.segment.text === ', '
      ? theme.space.xxxs + 'px'
      : '0'};
  border-radius: 2.5px;
  text-align: ${(props) =>
    props.segment?.type == 'hour' ? 'right' : 'center'};
  outline: none;
  background-color: transparent;
  color: ${(props) =>
    props.segment.isEditable ? theme.colors.Grey90 : theme.colors.Grey50};
  &:focus {
    background-color: ${theme.colors.Violet50};
    color: ${theme.colors.White};
  }
`

const StyledPlaceholderSpan = styled.span<{ segment: AriaDateSegment }>`
  display: block;
  text-align: center;
  outline: none;
  color: ${theme.colors.Grey50};
  .group:focus & {
    color: ${theme.colors.White};
  }
  visibility: ${(props) =>
    props.segment.isPlaceholder ? 'visible' : 'hidden'};
  height: ${(props) => (props.segment.isPlaceholder ? 'auto' : '0')};
  pointer-events: none;
`

interface DateSegmentProps {
  segment: AriaDateSegment
  state: DateFieldState
  handleStartTyping?: () => void
}

export function DateSegment(props: DateSegmentProps) {
  const ref = useRef(null)
  const { segment, state } = props
  const { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <StyledSegmentDiv
      {...segmentProps}
      ref={ref}
      segment={segment}
      className="group"
      onKeyDown={() => {
        props.handleStartTyping && props.handleStartTyping()
      }}
    >
      <StyledPlaceholderSpan segment={segment}>
        {segment.placeholder?.toUpperCase()}
      </StyledPlaceholderSpan>
      {segment.isPlaceholder ? '' : segment.text}
    </StyledSegmentDiv>
  )
}

interface DateFieldProps extends AriaDateFieldProps<DateValue> {
  handleStartTyping?: () => void
}

export default function DateField(props: DateFieldProps) {
  const { locale } = useLocale()

  const timezoneSegmentRef = useRef<any>(null)

  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  })

  /* Timezones weren't standardized before 1883, and this datepicker
   will render the exact timezone when manually typing in dates.
  Ex: for America/New_York before 1883, the timezone is defined as GMT-4:56:02.
  https://yoursunny.com/t/2020/GMT-0456/
  
  Though technically correct, to avoid displaying GMT-4:56:02 to the user while 
  typing in a date, we capture the first render of timezone (based on the given 
  placeholder DateTime) and display that in subsequent renders. */

  const MAX_TIMEZONE_LENGTH = 6 // Allows "EST" or "GMT-12" but not "GMT-4:56:02"

  // Capture the first render of the timezone segment
  useEffect(() => {
    const timezoneSegment = state.segments.find(
      (segment) => segment.type === 'timeZoneName',
    )
    if (
      timezoneSegment &&
      !timezoneSegmentRef.current &&
      timezoneSegment.text.length <= MAX_TIMEZONE_LENGTH
    ) {
      timezoneSegmentRef.current = timezoneSegment
    }
  }, [state.segments])

  const ref = useRef(null)
  const { labelProps, fieldProps } = useDateField(props, state, ref)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
      className="group"
    >
      <span {...labelProps}>{props.label}</span>
      <StyledDateFieldDiv {...fieldProps} ref={ref}>
        {state.segments.map((segment, i) => {
          // Use the captured timezone segment if available
          if (
            segment.type === 'timeZoneName' &&
            timezoneSegmentRef.current &&
            segment.text.length > MAX_TIMEZONE_LENGTH
          ) {
            segment = timezoneSegmentRef.current
          }
          return (
            <DateSegment
              key={i}
              segment={segment}
              state={state}
              handleStartTyping={props.handleStartTyping}
            />
          )
        })}
      </StyledDateFieldDiv>
    </div>
  )
}
