import { LoadingSpinner, Text } from '@traba/react-components'
import { useParams } from 'react-router-dom'
import { Card } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'

export default function AddOneOffToScheduleScreen() {
  const { shiftRequestParentId } = useParams<{ shiftRequestParentId: string }>()
  const { shiftRequestParent, isLoading } = useShiftRequestParent(
    shiftRequestParentId ?? '',
  )

  if (isLoading || !shiftRequestParent) {
    return <LoadingSpinner />
  }

  return (
    <MainLayout title={`Add one off shift ${shiftRequestParent.title}`}>
      <Text variant="h3">Add a one-off shift to schedule </Text>
      <Card>
        <Text variant="h4">Add a day/one-off shift</Text>

        <Divider />
        <Text variant="h5">Date of new shift</Text>
      </Card>
    </MainLayout>
  )
}
