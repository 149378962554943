export enum DrugPanelType {
  FIVE_PANEL = 'FIVE_PANEL',
  TEN_PANEL = 'TEN_PANEL',
  THIRTEEN_PANEL = 'THIRTEEN_PANEL',
}

export enum DrugScreeningVerificationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum DrugScreeningOrderStatus {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
}

export enum DrugScreeningResultStatus {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  TEMPORARILY_EXEMPTED = 'TEMPORARILY_EXEMPTED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface DrugScreeningOrder {
  id: string
  workerId: string
  status: DrugScreeningOrderStatus
  drugScreeningVendor: string
  vendorOrderId: string
  drugPanelType: DrugPanelType
  requestingCompanyId?: string
  expiresAt: Date
  labPassportId: string
  lastReminderSentAt?: Date
  applicantPortalUrl: string
  labPassportEmailedAt?: Date
  waiverSignedAt: Date
  scheduledBy: string
  createdAt: Date
  updatedAt: Date
  requestingCompanyName?: string
}

export interface DrugScreeningResult {
  id: string
  createdAt: Date
  updatedAt: Date
  workerId: string
  drugScreeningResultStatus: DrugScreeningResultStatus
  drugScreeningVendor: string
  vendorOrderId: string
  drugPanelType: DrugPanelType
  requestingCompanyId?: string
  expiresAt: Date
  labPassportId: string
  lastReminderSentAt?: Date
  applicantPortalUrl: string
  labPassportEmailedAt?: Date
  waiverSignedAt: Date
  scheduledBy: string
  verificationStatus: DrugScreeningVerificationStatus
  requestingCompanyName?: string
  resultFileUrl?: string
}
