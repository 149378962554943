import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { GenderPreference } from '@traba/types'
import { Col, Row } from '../base'
import { RadioButton } from '../RadioButton/RadioButton'

interface GenderPreferenceSelectorProps {
  selectedGenderPreference?: GenderPreference
  setSelectedGenderPreference: (
    genderPreference: GenderPreference | undefined,
  ) => void
}

export const GenderPreferenceSelector = (
  props: GenderPreferenceSelectorProps,
) => {
  const { selectedGenderPreference, setSelectedGenderPreference } = props
  return (
    <>
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        Is there a preferred gender for this role?
      </Text>
      <Row
        alignCenter
        style={{ columnGap: theme.space.xl, marginBottom: theme.space.xs }}
      >
        <Col style={{ display: 'flex', flex: '0 1 auto' }}>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => setSelectedGenderPreference(undefined)}
          >
            <RadioButton selected={selectedGenderPreference === undefined} />
            <Text variant="body1">No Preference</Text>
          </Row>
        </Col>
        <Col style={{ display: 'flex', flex: '0 1 auto' }}>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => setSelectedGenderPreference(GenderPreference.Male)}
          >
            <RadioButton
              selected={selectedGenderPreference === GenderPreference.Male}
            />
            <Text variant="body1">Male</Text>
          </Row>
        </Col>
        <Col style={{ display: 'flex', flex: '0 1 auto' }}>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => setSelectedGenderPreference(GenderPreference.Female)}
          >
            <RadioButton
              selected={selectedGenderPreference === GenderPreference.Female}
            />
            <Text variant="body1">Female</Text>
          </Row>
        </Col>
      </Row>
    </>
  )
}
