import { WorkerShiftAsTimesheetRow } from '@traba/types'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useEffect, useMemo, Dispatch } from 'react'
import { Col } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import {
  LeanWorkerShiftAsTimesheetRow,
  TableRow,
  TimesheetAdjustment,
} from '../timesheet.types'
import {
  buildBaseTableRow,
  calculateTotalMinutesWorked,
  calculateTotalPay,
  hasDiff,
  tableRowToTimesheetAdjustment,
  checkAndSetDiff,
  FIELDS_TO_CHECK,
} from '../utils'
import NewWorkersTable from './NewWorkersTable'
import TimesheetAdjustmentsTable from './TimesheetAdjustmentsTable'

function compareDataAndBuildTableRows(
  parsedData: WorkerShiftAsTimesheetRow[],
  workerShifts: WorkerShiftAsTimesheetRow[],
  useBreaks: boolean,
): {
  allTableRows: TableRow[]
  diffedTableRows: TableRow[]
  workerShiftAdjustments: TimesheetAdjustment[]
  newWorkerShifts: TableRow[]
} {
  const allTableRows: TableRow[] = []
  const diffedTableRows: TableRow[] = []
  const workerShiftAdjustments: TimesheetAdjustment[] = []
  const newWorkerShifts: TableRow[] = []
  parsedData.forEach((parsedWorkerShift) => {
    const workerShift = workerShifts.find(
      (item) =>
        item.workerId === parsedWorkerShift.workerId &&
        item.shiftId === parsedWorkerShift.shiftId,
    )

    if (workerShift) {
      const tableRow: TableRow = buildBaseTableRow(
        parsedWorkerShift,
        workerShift,
        useBreaks,
      )
      // TODO(gavin): may one day need to check workerName as well to see if business worker name is different from ops console worker name
      FIELDS_TO_CHECK.forEach((field) => {
        const key = field as keyof LeanWorkerShiftAsTimesheetRow
        checkAndSetDiff(tableRow, key) // this function mutates tableRow
      })

      allTableRows.push(tableRow)
      if (hasDiff(tableRow, useBreaks)) {
        diffedTableRows.push(tableRow)
        workerShiftAdjustments.push(
          tableRowToTimesheetAdjustment(tableRow, workerShift, useBreaks),
        )
      }
    } else {
      const tableRow: TableRow = buildBaseTableRow(
        parsedWorkerShift,
        {} as WorkerShiftAsTimesheetRow,
        useBreaks,
      )
      newWorkerShifts.push(tableRow)
    }
  })

  return {
    allTableRows,
    diffedTableRows,
    workerShiftAdjustments,
    newWorkerShifts,
  }
}

export default function TimesheetTable({
  parsedData,
  openModal,
  setWorkerShiftAdjustments,
  setPrevMinutesWorked,
  setPrevTotalPay,
  setNewMinutesWorked,
  setNewTotalPay,
  workerShifts,
  refetchWorkerShifts,
  setParsedData,
  useBreaks,
  setUseBreaks,
}: {
  parsedData: WorkerShiftAsTimesheetRow[]
  openModal: () => void
  setWorkerShiftAdjustments: Dispatch<
    React.SetStateAction<TimesheetAdjustment[]>
  >
  setPrevMinutesWorked: Dispatch<React.SetStateAction<number>>
  setPrevTotalPay: Dispatch<React.SetStateAction<number>>
  setNewMinutesWorked: Dispatch<React.SetStateAction<number>>
  setNewTotalPay: Dispatch<React.SetStateAction<number>>
  workerShifts: WorkerShiftAsTimesheetRow[]
  refetchWorkerShifts: () => void
  setParsedData: Dispatch<React.SetStateAction<WorkerShiftAsTimesheetRow[]>>
  useBreaks: boolean
  setUseBreaks: Dispatch<React.SetStateAction<boolean>>
}) {
  const {
    allTableRows,
    diffedTableRows,
    workerShiftAdjustments,
    newWorkerShifts,
  } = useMemo(
    () => compareDataAndBuildTableRows(parsedData, workerShifts, useBreaks),
    [parsedData, workerShifts, useBreaks],
  )

  const [{ newPay, prevPay }, { newMinutesWorked, prevMinutesWorked }] =
    useMemo(
      () => [
        calculateTotalPay(allTableRows),
        calculateTotalMinutesWorked(allTableRows),
      ],
      [allTableRows],
    )

  useEffect(() => {
    setWorkerShiftAdjustments(workerShiftAdjustments)
    setNewTotalPay(newPay)
    setNewMinutesWorked(newMinutesWorked)
    setPrevTotalPay(prevPay)
    setPrevMinutesWorked(prevMinutesWorked)
  }, [
    newMinutesWorked,
    newPay,
    prevMinutesWorked,
    prevPay,
    setNewMinutesWorked,
    setNewTotalPay,
    setPrevMinutesWorked,
    setPrevTotalPay,
    setWorkerShiftAdjustments,
    workerShiftAdjustments,
  ])

  const shiftMenuItems = useMemo(() => {
    const uniqueShifts = Array.from(
      new Map(workerShifts.map((shift) => [shift.shiftId, shift])),
    ).sort(([, a], [, b]) => a.startTime.getTime() - b.startTime.getTime())

    return uniqueShifts.map<IMenuItem>(
      ([, { shiftId, shiftName, startTime, timezone }]) => {
        return {
          value: shiftId,
          label: shiftName,
          secondaryLabel: shiftId,
          trailingTags: [
            {
              title: format(
                utcToZonedTime(startTime, timezone),
                'MM/dd/yyyy h:mm a',
              ),
              variant: 'info',
            },
          ],
        }
      },
    )
  }, [workerShifts])

  return (
    <Col style={{ overflow: 'scroll' }}>
      {allTableRows.length > 0 && (
        <TimesheetAdjustmentsTable
          allTableRows={allTableRows}
          diffedTableRows={diffedTableRows}
          openModal={openModal}
          setParsedData={setParsedData}
          useBreaks={useBreaks}
          setUseBreaks={setUseBreaks}
        />
      )}
      {newWorkerShifts.length > 0 && (
        <NewWorkersTable
          tableRows={newWorkerShifts}
          shiftMenuItems={shiftMenuItems}
          refetchWorkerShifts={refetchWorkerShifts}
          setParsedData={setParsedData}
        />
      )}
    </Col>
  )
}
