import { SpacingProps, spacingStyles } from '@traba/theme'
import React from 'react'
import styled, { css } from 'styled-components'

export type RowProps = {
  center?: boolean
  justifyBetween?: boolean
  justifyEnd?: boolean
  justifyStart?: boolean
  justifyCenter?: boolean
  flexCol?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  children: React.ReactNode
  style?: { [key: string]: string | number }
  className?: string
  card?: boolean
  onClick?: any
  alignCenter?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  wrap?: boolean
  gap?: number
  ref?: React.MutableRefObject<HTMLDivElement | null>
} & SpacingProps

const StyledRow = styled.div<RowProps>`
  display: flex;
  ${(props) => spacingStyles(props)}

  /* Flex-related */
  ${(props) => props.center && `justify-content: center;`}
  ${(props) => props.justifyBetween && `justify-content: space-between;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}
  ${(props) => props.flexCol && `flex-direction: column;`}
  ${({ card, theme }) =>
    card &&
    css`
      background-color: #fff;
      border: 1px solid ${theme.border.color};
      border-radius: ${theme.border.radius};
      padding: 8px 16px;
    `}
  ${(props) => props.fullHeight && `height: 100%;`}
  ${(props) => props.fullWidth && `width: 100%;`}
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.wrap && `flex-wrap: wrap`}
  ${(props) => props.gap && `gap: ${props.gap}px`}
`

export default function Row({ className = '', ...props }: RowProps) {
  return <StyledRow {...props} className={`Row ${className}`} />
}
