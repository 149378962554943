import * as Sentry from '@sentry/react'
import {
  CostCenterType,
  CostCenterFormData,
  CreateCostCenter,
  UpdateCostCenter,
} from '@traba/types'
import React, { useState } from 'react'
import { Dialog, MODAL_SIZE } from '../base-components'
import { CostCenterForm } from './CostCenterForm'

export type CreateOrEditCostCenterModalProps = {
  companyId?: string
  // if editing a cost center, pass in the cost center to edit
  costCenter?: CostCenterType
  createCostCenter: (data: CreateCostCenter) => void
  updateCostCenter: (data: UpdateCostCenter) => void
  open: () => void
  isOpen: boolean
  handleClose: () => void
}

export function CreateOrEditCostCenterModal(
  props: CreateOrEditCostCenterModalProps,
) {
  const { companyId, createCostCenter, updateCostCenter, isOpen, handleClose } =
    props

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<CostCenterFormData | undefined>()
  const [isValid, setIsValid] = useState(false)

  async function handleCreate(formData: CostCenterFormData): Promise<void> {
    try {
      setLoading(true)
      if (formData.name) {
        createCostCenter({
          name: formData.name,
          locationIds: formData.locationIds,
        })
      } else {
        throw new Error('Cost Center name is required')
      }
      handleClose()
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleEdit(formData: CostCenterFormData): Promise<void> {
    try {
      setLoading(true)
      if (props.costCenter?.id) {
        updateCostCenter({
          id: props.costCenter.id,
          ...formData,
        })
      }
      handleClose()
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      size={MODAL_SIZE.LARGE}
      dialogTitle={props.costCenter ? 'Edit Cost Center' : 'Create Cost Center'}
      dialogTitleIcon={'dollar'}
      onConfirm={() => {
        if (formData) {
          if (props.costCenter) {
            handleEdit(formData)
          } else {
            handleCreate(formData)
          }
        }
      }}
      onClose={handleClose}
      confirmDisabled={!formData || !isValid}
      onConfirmCTA={props.costCenter ? 'Submit' : 'Create'}
      open={isOpen}
      confirming={loading}
    >
      <CostCenterForm
        companyId={companyId}
        onChange={(formData, error) => {
          setFormData(formData)
          setIsValid(!error)
        }}
        onCancel={handleClose}
        costCenter={props.costCenter}
      />
    </Dialog>
  )
}
