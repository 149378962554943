import { useAlert } from '@traba/context'
import { SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import { useCompanyEligibilityConnections } from 'src/hooks/useConnections'
import { truncateString } from 'src/utils/stringUtils'
import { CompanyEligibilityConnectionModal } from './CompanyEligibilityConnectionModal'

interface CompanyEligibilityConnectionsProps {
  companyId: string
}

export default function CompanyEligibilityConnections({
  companyId,
}: CompanyEligibilityConnectionsProps) {
  const { showError } = useAlert()
  const { eligibilityConnections, isLoading, isError } =
    useCompanyEligibilityConnections(companyId)
  const createCompanyEligibilityConnectionModal = useModal()
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('')
  const [showSelect, setShowSelect] = useState<boolean>(false)

  useEffect(() => {
    if (isError) {
      showError(
        'There was an error fetching the eligibility connections',
        'Error fetching eligibility connections',
      )
    }
  }, [isError, showError])

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  const tableRows: TableRow[] =
    eligibilityConnections?.map((worker) => {
      const { firstName, lastName, regionId } = worker

      const workerId = worker.workerId || worker.uid
      const name = `${firstName} ${lastName}`

      return {
        key: `eligibility_connections_${worker.id}`,
        cells: [
          {
            renderFn: () => (
              <Row justifyBetween>
                <Link to={`/workers/${workerId}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {name}
                  </Button>
                </Link>
              </Row>
            ),
            sortKey: name,
          },
          {
            renderFn: () => (
              <>
                {truncateString(workerId, 6)}
                <CopyTextIcon textToCopy={workerId} />
              </>
            ),
            sortKey: workerId,
          },
          {
            renderFn: () => regionId,
          },
          {
            renderFn: () => (
              <SvgIcon
                name={'edit'}
                onClick={() => {
                  setSelectedWorkerId(workerId)
                  setShowSelect(false)
                  createCompanyEligibilityConnectionModal.open()
                }}
                style={{ cursor: 'pointer' }}
              />
            ),
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Row pb={theme.space.xs} fullWidth alignCenter>
        <Row ml="auto">
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setShowSelect(true)
              createCompanyEligibilityConnectionModal.open()
            }}
          >
            Create Eligibility Connection
          </Button>
        </Row>
      </Row>
      <DataTable
        initialSortByColumnIndex={0} // sort by name column
        rows={tableRows}
        headers={[
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'workerId',
            label: 'Worker ID',
            sortable: true,
          },
          {
            key: 'regionId',
            label: 'Region Id',
          },
          {
            key: 'modifyEligibilityConnection',
            label: 'Modify Eligibility Connection',
          },
        ]}
      />
      <CompanyEligibilityConnectionModal
        companyId={companyId}
        isOpen={createCompanyEligibilityConnectionModal.isOpen}
        handleClose={createCompanyEligibilityConnectionModal.handleClose}
        selectedWorkerId={selectedWorkerId}
        setSelectedWorkerId={setSelectedWorkerId}
        showSelect={showSelect}
      />
    </>
  )
}
