import { Col } from '@traba/react-components'
import { Shift } from '@traba/types'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { ShiftList } from 'src/components/ShiftList'

interface ViewRelatedShiftsModalProps {
  handleClose: () => void
  isOpen: boolean
  relatedShifts: Shift[]
  currentShiftId: string
}
export function ViewRelatedShiftsModal(props: ViewRelatedShiftsModalProps) {
  const { handleClose, isOpen, relatedShifts, currentShiftId } = props
  if (relatedShifts.length === 0) {
    return null
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title="Go to Related Shift"
    >
      <Col>
        <ShiftList shifts={relatedShifts} currentShiftId={currentShiftId} />
      </Col>
    </Modal>
  )
}
