import CircularProgress from '@mui/material/CircularProgress'
import { useAlert } from '@traba/context'
import { theme, Z_INDEXES } from '@traba/theme'
import { AccountStatus, EmploymentType } from '@traba/types'
import { BreakType } from '@traba/types'
import { useState } from 'react'
import { AdjustmentSummary } from 'src/components/AdjustmentForm/components/AdjustmentSummary'
import { Button, Col, Modal, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import Divider from 'src/components/base/Divider'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerShiftHistory } from 'src/components/WorkerShiftHistory'
import { useUserContext } from 'src/context/user/UserContext'
import { getQueryParams, useApi } from 'src/hooks/useApi'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { isWorkerEligibleForInstantPay } from 'src/screens/WorkerDetailsScreen/WorkerDetailsScreen'
import {
  EditPendingAdjustmentParams,
  GetPendingRequestResponseDTO,
} from '../../../../hooks/usePendingAdjustments'

type PendingAdjustmentReviewModalProps = {
  isOpen: boolean
  onClose: () => void
  pendingAdjustment: GetPendingRequestResponseDTO
  editPendingAdjustment: (params: EditPendingAdjustmentParams) => Promise<void>
}

enum ActionState {
  APPROVING,
  DECLINING,
  NONE,
}

export default function PendingAdjustmentReviewModal({
  isOpen,
  pendingAdjustment,
  onClose,
  editPendingAdjustment,
}: PendingAdjustmentReviewModalProps) {
  /*
  Hooks
  */
  const { showError, showSuccess, showWarning } = useAlert()
  const workerId = pendingAdjustment.workerShift.worker.id
  const shiftId = pendingAdjustment.shiftId

  const { state: userContext } = useUserContext()
  // Account Status
  const { data: worker, isLoading: isLoadingWorker } = useApi<
    Worker & AccountStatus
  >(`/workers/${workerId}/worker-and-profile`)
  const workerCanGetInstantPay =
    isWorkerEligibleForInstantPay(worker) && worker?.payment?.instantPayEnabled

  // Worker Shift
  const { workerShifts = [], isLoading: isLoadingWorkerShift } =
    useWorkerShifts(shiftId)
  const workerShift = workerShifts.find((ws) => ws.workerId === workerId)

  // States
  const [shouldAdjustPayment, setShouldAdjustPayment] = useState<boolean>(false)
  const [shouldInstantPay, setShouldInstantPay] = useState<boolean>(false)
  const [actionInProgress, setActionInProgress] = useState<ActionState>(
    ActionState.NONE,
  )

  const handleApproveOrDecline = async (action: ActionState) => {
    setActionInProgress(action)
    const queryString = getQueryParams([
      ['shouldAdjustPayment', shouldAdjustPayment.toString()],
      ['shouldInstantPay', shouldInstantPay.toString()],
    ])

    try {
      await editPendingAdjustment({
        workerId,
        shiftId,
        action: action === ActionState.APPROVING ? 'approve' : 'decline',
        shouldAdjustPayment,
        queryString,
      })
      if (action === ActionState.APPROVING) {
        showSuccess('Approve successful', 'Approved')
      } else {
        showWarning('Decline successful', 'Declined')
      }

      window.analytics.track(
        `Pending Adjustment ${
          action === ActionState.APPROVING ? 'Approved' : 'Declined'
        }`,
        {
          workerId,
          shiftId,
          pendingAdjustment,
          shouldAdjustPayment,
          shouldInstantPay,
          by: userContext.userProfile?.email,
        },
      )
    } catch (err) {
      showError((err as Error).message, 'Action failed')
    }
    setActionInProgress(ActionState.NONE)
    onClose()
  }

  const summary = workerShift ? (
    <AdjustmentSummary
      workerShift={workerShift}
      adjustedStartTime={
        pendingAdjustment.clockInTime
          ? new Date(pendingAdjustment.clockInTime)
          : workerShift.clockInTime
            ? new Date(workerShift.clockInTime)
            : (() => {
                throw new Error('Clock-in time does not exist')
              })()
      }
      adjustedEndTime={
        pendingAdjustment.clockOutTime
          ? new Date(pendingAdjustment.clockOutTime)
          : workerShift.clockOutTime
            ? new Date(workerShift.clockOutTime)
            : (() => {
                throw new Error('Clock-out time does not exist')
              })()
      }
      adjustedBreakType={BreakType.AUTO_UNPAID}
      adjustedBreaks={
        pendingAdjustment.breaks?.length ? pendingAdjustment.breaks : undefined
      }
      adjustmentReason={pendingAdjustment.adjustmentReason}
      adjustedUnitsWorked={pendingAdjustment.unitsWorked ?? undefined}
    />
  ) : null
  const workerName =
    pendingAdjustment.workerShift.worker.firstName +
    ' ' +
    pendingAdjustment.workerShift.worker.lastName

  return (
    <Modal
      handleClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={`Review Pending Adjustment for ${workerName}`}
      style={{
        zIndex: Z_INDEXES.MODAL,
      }}
    >
      <Col
        style={{
          overflowY: 'scroll',
        }}
      >
        {!isLoadingWorker || !isLoadingWorkerShift ? (
          <>
            <Row my={theme.space.xs} px={theme.space.xs}>
              {workerShift && <WorkerShiftHistory workerShift={workerShift} />}
            </Row>
            {summary}
          </>
        ) : (
          <Row center>
            <CircularProgress size={48} />
          </Row>
        )}
      </Col>
      {workerShift?.shiftInfo.shiftEmploymentType !== EmploymentType.W2 && (
        <Row
          style={{
            justifyContent: 'space-between',
          }}
          my={theme.space.xs}
        >
          <Checkbox
            checked={shouldAdjustPayment}
            label={'Should Adjust Payment'}
            onChange={(e) => {
              setShouldAdjustPayment(e.target.checked)
            }}
          />
          {shouldAdjustPayment && workerCanGetInstantPay && (
            <Checkbox
              checked={shouldInstantPay}
              label={'Should Instant Pay'}
              onChange={(e) => {
                setShouldInstantPay(e.target.checked)
              }}
            />
          )}
        </Row>
      )}
      <Divider />

      <Row
        fullWidth
        style={{
          marginTop: theme.space.med,
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '200px' }}
          onClick={() => handleApproveOrDecline(ActionState.DECLINING)}
          loading={actionInProgress === ActionState.DECLINING}
          disabled={actionInProgress !== ActionState.NONE}
        >
          Decline
        </Button>
        <Button
          style={{ width: '200px' }}
          onClick={() => handleApproveOrDecline(ActionState.APPROVING)}
          loading={actionInProgress === ActionState.APPROVING}
          disabled={actionInProgress !== ActionState.NONE}
        >
          Approve
        </Button>
      </Row>
    </Modal>
  )
}
