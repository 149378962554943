import styled from 'styled-components'
import { Row } from '../base'

export const EarningsSummaryItemContainer = styled(Row)`
  border-radius: 8px;
  width: 100%;
  min-width: 1000px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  padding: 16px;
  flex-direction: column;
`
