export type WorkerVettingCampaign = {
  id: string
  createdAt: Date
  updatedAt: Date
  deadline?: Date
  phoneNumbers: string[]
  regionIds: string[]
  questions: string[]
  initialMessage: string
  title: string
  promptDetails?: string
  targetConversationModel?: string
  targetEvaluationModel?: string
  shiftId?: string
  roleId?: string
  initiatedBy: string
  type: WorkerVettingMethod
  status: WorkerVettingCampaignStatus
  customQualifiedExitMessage?: string
  customUnqualifiedExitMessage?: string
}

export type WorkerVettingSession = {
  id: string
  createdAt: Date
  updatedAt: Date
  phoneNumber: string
  properties: any
  lastActiveAt: Date
  lastEvaluatedAt?: Date
  score?: number
  opsScore?: number
  summary?: string
  threadId?: string
  status: WorkerVettingSessionStatus
  workerVettingCampaignId: string
  workerId?: string
}

export type WorkerVettingSessionMessage = {
  id: string
  createdAt: Date
  updatedAt: Date
  source: WorkerVettingMessageSource
  message: string
  reviewCode?: number
  reviewReason?: string
  workerVettingSessionId: string
}

export enum WorkerVettingMessageSource {
  ops = 'OPS',
  system = 'SYSTEM',
  worker = 'WORKER',
}

export enum WorkerVettingSessionStatus {
  active = 'ACTIVE',
  complete = 'COMPLETE',
  exited = 'EXITED',
  canceled = 'CANCELED',
  stale = 'STALE',
}

export enum WorkerVettingMethod {
  call = 'CALL',
  sms = 'SMS',
  SMS_AND_CALL = 'SMS_AND_CALL',
}

export enum WorkerVettingCampaignStatus {
  active = 'ACTIVE',
  completed = 'COMPLETED',
  canceled = 'CANCELED',
}

export enum SupportedLanguages {
  english = 'en_US',
  spanish = 'es_US',
}
