import { Drawer, LinearProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { identity, pickBy } from 'lodash'
import { useState } from 'react'
import { Button } from '../../../../components/base'
import { workerSearch } from '../../../../hooks/searchWorkersApi'
import { extractArrayValues } from '../../../../hooks/useWorkers'
import { downloadBlob } from '../../../../utils/helperUtils'
import { PopulatedWorker } from '../../worker-search.types'
import * as S from './styles'
import { convertToCSV, getExportMessage, getLinearProgressValue } from './utils'

type ExportDataDrawerProps = {
  params: any
  isOpen: boolean
  onClose: () => void
}

const ExportDataDrawer = ({
  params,
  isOpen,
  onClose,
}: ExportDataDrawerProps) => {
  const { handleError } = useAlert()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalWorkers, setTotalWorkers] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const limit = 100
  let offset = 0
  let totalCount = 0
  let workers: PopulatedWorker[] = []

  const handleExport = async () => {
    try {
      setIsLoading(true)

      while (true) {
        const { workers: result, count } = await workerSearch({
          parameters: extractArrayValues(pickBy(params, identity)),
          paginationParams: {
            limit,
            offset,
          },
          includes: {
            accountStatus: true,
            workerMetric: true,
            payment: true,
            backgroundCheck: true,
            certifications: true,
            connections: true,
            schedulePreferences: true,
            workerProfile: true,
            workerShifts: true,
            workAvailability: true,
            workPreferences: true,
          },
        })

        workers = [...workers, ...result]
        totalCount = count

        // Update the UI to show the current page and number of workers found
        setCurrentPage(Math.ceil(offset / limit) + 1)
        setTotalWorkers(count)

        if (workers.length >= totalCount) {
          break
        }

        offset += limit
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }

      const csv = convertToCSV(workers)
      downloadBlob(csv, 'workers.csv')

      setCurrentPage(1)
      setTotalWorkers(0)
      setIsLoading(false)
      window.analytics.track('Workers Search List Exported')
    } catch (error) {
      handleError(
        error,
        'ExportDataDrawer -> handleExport()',
        'Unable to export workers',
        'Worker search error',
      )
      setIsLoading(false)
      console.log('Error exporting workers:', error)
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <S.DrawerContainer>
        <Text variant="h4" my={theme.space.xs}>
          Export workers to CSV
        </Text>
        <Text variant="body1" mb={theme.space.med}>
          This will generate a file with the total number of workers found with
          the set params in the search
        </Text>

        <S.DrawerBody>
          <Text variant="label" mb={theme.space.xxs}>
            {getExportMessage({
              currentPage,
              totalRecords: totalWorkers,
              isExporting: isLoading,
              limit,
              entity: 'workers',
            })}
          </Text>
          <div style={{ backgroundColor: 'green' }}>
            {isLoading && (
              <LinearProgress
                variant="determinate"
                value={getLinearProgressValue(currentPage, totalWorkers, limit)}
                style={{ height: 10 }}
              />
            )}
          </div>
          <Button
            onClick={handleExport}
            loading={isLoading}
            style={{ width: 180 }}
            mt={theme.space.xs}
          >
            {isLoading ? 'Exporting...' : 'Export'}
          </Button>
        </S.DrawerBody>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default ExportDataDrawer
