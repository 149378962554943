import { Tooltip } from '@mui/material'
import { Text } from '@traba/react-components'
import { MEDIA, theme } from '@traba/theme'
import {
  InternalUser,
  NotificationSettings,
  NotificationSettingsLevel,
  SentinelNotification,
  ShiftNotificationsStatus,
} from '@traba/types'
import { useCallback, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useSentinelMutations } from 'src/hooks/useSentinelNotifications'
import { getReadableTimeInTimezone } from 'src/shared/utils/dateUtils'
import { getLocalTimezone } from 'src/utils/dateUtils'
import { internalUserHasSupervisorPrivileges } from 'src/utils/internalUserUtils'
import { mapSentinelEventToField } from 'src/utils/sentinelNotificationUtils'
import { Button, Icon, Row } from '../../base'
import MuteNotificationDropdown from './MuteNotificationDropdown'
interface SentinelNotificationProps {
  sentinelNotification: SentinelNotification
  selected?: boolean
  inlineActions?: boolean
  onClickRow?: () => void
  shiftNotificationSettings?: NotificationSettings
  userNotificationSettings: NotificationSettings
  internalUser: InternalUser
  shiftId: string
}

export const SentinelNotificationRow = (props: SentinelNotificationProps) => {
  const {
    sentinelNotification,
    selected,
    inlineActions,
    onClickRow,
    shiftNotificationSettings,
    userNotificationSettings,
    shiftId,
    internalUser,
  } = props

  const timezone =
    sentinelNotification.data?.shift?.timezone ?? getLocalTimezone()

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${MEDIA.LARGE}px)`,
  })

  const { updateNotificationSettings, dismissSentinelNotification } =
    useSentinelMutations()

  const isMutedForEveryone = useMemo(
    () =>
      shiftNotificationSettings?.[
        mapSentinelEventToField(sentinelNotification.eventType)
      ] === ShiftNotificationsStatus.SILENCED,
    [shiftNotificationSettings, sentinelNotification.eventType],
  )

  const isMutedForMe = useMemo(
    () =>
      userNotificationSettings?.[
        mapSentinelEventToField(sentinelNotification.eventType)
      ] === ShiftNotificationsStatus.SILENCED,
    [userNotificationSettings, sentinelNotification.eventType],
  )

  const canMuteForEveryone = useMemo(
    () => internalUserHasSupervisorPrivileges(internalUser),
    [internalUser],
  )

  const onUpdateSettings = useCallback(
    async (
      settingsLevel: NotificationSettingsLevel,
      eventType: SentinelNotification['eventType'],
      notificationStatus: ShiftNotificationsStatus,
    ) => {
      const notificationSettings = {
        [mapSentinelEventToField(eventType)]: notificationStatus,
      }
      await updateNotificationSettings({
        internalUserId: internalUser.id,
        shiftId,
        settingsLevel,
        notificationSettings,
      })
      window.analytics.track('Sentinel Notification Settings Updated', {
        notificationSettings,
        email: internalUser.email,
        shiftId,
      })
    },
    [internalUser, updateNotificationSettings, shiftId],
  )

  const onDismissSentinelNotification = useCallback(
    async (sentinelNotification: SentinelNotification, shiftId: string) => {
      const sentinelNotificationToUserId =
        sentinelNotification.sentinelNotificationToUser.id
      await dismissSentinelNotification({
        notificationId: sentinelNotificationToUserId,
        shiftId,
      })
      window.analytics.track('Sentinel Notification Dismissed', {
        sentinelNotificationToUserId,
        shiftId,
      })
    },
    [dismissSentinelNotification],
  )

  const wrapActions = (selected && !inlineActions) || !isLargeScreen

  return (
    <Row
      fullWidth
      justifyBetween
      alignCenter
      py={theme.space.xxs}
      px={theme.space.xs}
      onClick={() => onClickRow && onClickRow()}
      style={{
        cursor: 'pointer',
        border: `1px solid ${
          selected ? theme.colors.Violet70 : theme.colors.Grey30
        }`,
        ...(selected && {
          borderLeft: `4px solid ${theme.colors.Violet70}`,
          boxShadow: `0px 1px 0px 0px ${theme.colors.Violet20}`,
          ...(inlineActions && { borderBottom: 'none' }),
        }),
        ...(wrapActions && {
          flexWrap: 'wrap',
        }),
      }}
    >
      <Tooltip
        title={selected ? '' : sentinelNotification.message}
        enterDelay={400}
        enterNextDelay={100}
      >
        <div
          style={
            !selected
              ? {
                  overflow: 'hidden',
                }
              : {}
          }
        >
          <Text
            variant={selected ? 'h6' : 'body1'}
            style={
              !selected
                ? {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }
                : {}
            }
            pr={theme.space.xxs}
          >
            {sentinelNotification.message}
          </Text>
        </div>
      </Tooltip>

      <Row alignCenter gap={theme.space.xs}>
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Text variant="body1">
            {getReadableTimeInTimezone(
              new Date(sentinelNotification.createdAt),
              timezone,
            )}
          </Text>
        </div>
        <Button
          slim
          variant={ButtonVariant.TEXT}
          rightIcon={
            <Icon
              name="checkmark"
              type="svg"
              style={{ cursor: 'pointer' }}
              color={theme.colors.MidnightBlue}
            />
          }
          onClick={(event) => {
            event.stopPropagation()
            onDismissSentinelNotification(sentinelNotification, shiftId)
          }}
        >
          Dismiss
        </Button>

        <MuteNotificationDropdown
          onMuteForMe={() => {
            onUpdateSettings(
              NotificationSettingsLevel.INTERNAL_USER,
              sentinelNotification.eventType,
              isMutedForMe
                ? ShiftNotificationsStatus.ACTIVE
                : ShiftNotificationsStatus.SILENCED,
            )
            window.analytics.track('Sentinel Notification Muted For User', {
              eventType: sentinelNotification.eventType,
              isMutedForMe: !isMutedForMe,
              sentinelNotification: sentinelNotification.id,
            })
          }}
          onMuteForEveryone={
            canMuteForEveryone
              ? () => {
                  onUpdateSettings(
                    NotificationSettingsLevel.SHIFT,
                    sentinelNotification.eventType,
                    isMutedForEveryone
                      ? ShiftNotificationsStatus.ACTIVE
                      : ShiftNotificationsStatus.SILENCED,
                  )
                  window.analytics.track(
                    'Sentinel Notification Muted For All',
                    {
                      eventType: sentinelNotification.eventType,
                      isMutedForMe: !isMutedForMe,
                      sentinelNotification: sentinelNotification.id,
                    },
                  )
                }
              : undefined
          }
          isMutedForEveryone={!!isMutedForEveryone}
          isMutedForMe={!!isMutedForMe}
        />
      </Row>
    </Row>
  )
}
