import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { formatDuration } from '@traba/utils'
import { Col } from 'src/components/base'
import { SummaryLine } from './PostShiftForm.styles'

interface SummarySectionProps {
  totalShiftTime: number
  totalBreakTime: number
  totalPaidTime: number
  totalBilledTime: number | null
  estimatedPay: string
  isHourly: boolean
}

export const PaymentSummary = ({
  totalBreakTime,
  totalBilledTime,
  totalPaidTime,
  totalShiftTime,
  estimatedPay,
  isHourly,
}: SummarySectionProps) => {
  return (
    <Col
      style={{
        backgroundColor: theme.colors.Grey20,
        borderRadius: theme.space.xs,
        padding: theme.space.sm,
      }}
    >
      <SummaryLine>
        <Text variant="h5">Summary</Text>
      </SummaryLine>
      <SummaryLine>
        <Text variant="h6">Shift Length:</Text>
        <Text variant="h7">{formatDuration(totalShiftTime)}</Text>
      </SummaryLine>
      <SummaryLine>
        <Text variant="h6">Break(s):</Text>
        <Text variant="h7">{formatDuration(totalBreakTime)}</Text>
      </SummaryLine>
      <SummaryLine>
        <Text variant="h6">Total paid time:</Text>
        <Text variant="h7">{formatDuration(totalPaidTime)}</Text>
      </SummaryLine>
      {totalBilledTime !== null && (
        <SummaryLine>
          <Text variant="h6">Total billed time:</Text>
          <Text variant="h7">{formatDuration(totalBilledTime)}</Text>
        </SummaryLine>
      )}
      <SummaryLine>
        <Text variant="h6">
          Total estimated pay per {isHourly ? 'worker shift' : 'shift'}:
        </Text>
        <Text variant="h7">{estimatedPay}</Text>
      </SummaryLine>
    </Col>
  )
}
