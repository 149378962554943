import { Text } from '@traba/react-components'
import { Row, Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerIncentive } from '@traba/types'
import { Link } from 'react-router-dom'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { truncateString } from 'src/utils/stringUtils'
import CopyTextIcon from '../base/CopyTextIcon/CopyTextIcon'
import { DotText } from '../base/DotText'
import { TitleLink } from './styles'

interface PendingIncentiveItemHeaderProps {
  pendingIncentive: WorkerIncentive
}

export function PendingIncentiveItemHeader({
  pendingIncentive,
}: PendingIncentiveItemHeaderProps) {
  const { workerId, shiftId, worker } = pendingIncentive

  const tz = useTimezonedDates()

  const workerName = worker
    ? `${worker.firstName} ${worker.lastName}`
    : workerId

  return (
    <Col>
      <Row alignCenter>
        <Link
          to={`/workers/${workerId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TitleLink>{workerName}</TitleLink>
        </Link>
        <CopyTextIcon textToCopy={workerId} />
        {shiftId && (
          <>
            <DotText />
            <Link
              to={`/field-monitor/${shiftId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TitleLink>{shiftId}</TitleLink>
            </Link>
            <CopyTextIcon textToCopy={shiftId} />
          </>
        )}
      </Row>
      <Text>{pendingIncentive.reviewReason}</Text>
      <Row alignCenter>
        <Link
          to={`/incentives/${pendingIncentive.incentiveId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text>
            {truncateString(
              `${pendingIncentive.incentiveInfo.title} - ${pendingIncentive.incentiveInfo.description}`,
              75,
            )}
          </Text>
        </Link>
        <DotText />
        <Text>{tz.getDateTime(pendingIncentive.earnedAt)}</Text>
      </Row>
      {pendingIncentive.reviewReason && (
        <Text color={theme.colors.red}>{pendingIncentive.reviewReason}</Text>
      )}
    </Col>
  )
}
