export interface ImageProps {
  src: string
  alt: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const Image = (props: ImageProps) => {
  const { src, alt, style, ...rest } = props
  return <img src={src} alt={alt} style={style} {...rest} />
}

export default Image
