import { useContext, useEffect, useRef } from 'react'
import { WebSocketContext } from 'src/context/webSocketContext/WebSocketContext'

export const WEBSOCKET_KEY = 'node'
export const OPS_WEBSOCKET_KEY = 'ops-node'
export const PORTER_WEBSOCKET_KEY = 'porter-node'

export function useWebSocketListener<T>(
  event: string,
  callback: (data: T) => void,
) {
  const { sockets } = useContext(WebSocketContext)

  useEffect(() => {
    Object.values(sockets).forEach((socket) => {
      if (!socket) {
        return
      }
      socket.on(event, callback)
      socket.on('error', (error: string) => {
        console.error(`Error: ${error}`)
      })
    })

    return () => {
      Object.values(sockets).forEach((socket) => {
        if (!socket) {
          return
        }
        socket.off(event)
        socket.off('error')
      })
    }
  }, [sockets, event, callback])
}

type Callback<T> = (data: T) => void

/**
 * @param {string} room - The name of the room to join.
 * @param {Object<string, function(T): void>} eventCallbacks - An object mapping event names to callback functions.
 * Each key in the object is the name of an event to listen for, and the corresponding value is a function that will be called
 * whenever that event occurs. The function will be passed the data sent with the event.
 */
export function useWebSocketRoom({
  room,
  eventCallbacks,
}: {
  room?: string
  eventCallbacks: { [eventName: string]: Callback<any> }
}) {
  const { sockets } = useContext(WebSocketContext)
  const eventCallbacksRef = useRef(eventCallbacks)

  useEffect(() => {
    const currentEventCallbacks = eventCallbacksRef.current

    Object.values(sockets).forEach((socket) => {
      if (!socket || !room) {
        return
      }

      socket.emit('join-room', room)

      // Register event listeners
      for (const [event, callback] of Object.entries(currentEventCallbacks)) {
        socket.on(event, callback)
      }
      socket.on('error', (error: string) => {
        console.error(`Error: ${error}`)
      })
    })

    return () => {
      Object.values(sockets).forEach((socket) => {
        if (!socket || !room) {
          return
        }
        socket.emit('leave-room', room)

        // Unregister event listeners
        for (const event of Object.keys(currentEventCallbacks)) {
          socket.off(event)
        }
        socket.off('error')
      })
    }
  }, [sockets, room, eventCallbacksRef])
}
