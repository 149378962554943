import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  Col,
  CompanyStatusBadge,
  CopyTextIcon,
  Icon,
  Row,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { PageHeader } from 'src/components/base/PageHeader'
import { useCompany } from 'src/hooks/useCompany'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import AuditLogsScreen from './AuditLogsScreen'

export default function CompanyLogsScreen() {
  const { companyId = '' } = useParams<{ companyId: string }>()
  const { company, isLoading } = useCompany(companyId)

  if (isLoading) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="large" />
      </Row>
    )
  }
  if (!company) {
    return <div>Company not found</div>
  }

  const CompanyLogsHeader = () => {
    return (
      <PageHeader
        avatarString={company.employerName}
        avatarUrl={company.companyLogo}
        children={
          <Col ml={theme.space.xs}>
            <Row alignCenter>
              <Link to={`/companies/${companyId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  <Text variant="h4">{company.employerName}</Text>
                </Button>
              </Link>
              <CompanyStatusBadge
                isApproved={company.isApproved}
                style={{ marginLeft: theme.space.xs }}
              />
            </Row>
            <Row alignCenter>
              <Text variant="h7">Company Id: {companyId}</Text>
              <CopyTextIcon textToCopy={companyId} />
            </Row>
            <Row alignCenter>
              <Text
                variant="body1"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  name="calendar_active"
                  style={{ height: '17px', marginLeft: 2, marginRight: 6 }}
                />
                Joined on: {formatDateTimeWithTimezone(company.createdAt)}
              </Text>
            </Row>
          </Col>
        }
      />
    )
  }

  return (
    <AuditLogsScreen
      headerContent={<CompanyLogsHeader />}
      logType="COMPANY"
      searchId={companyId}
    />
  )
}
