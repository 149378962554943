import { theme } from '@traba/theme'
import styled from 'styled-components'

export const WorkerCardContainer = styled.div`
  width: 300px;
  padding: ${theme.space.xs}px;
  margin-top: ${({ theme }) => theme.space.xs}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  flex: 1;
`

export const AvatarWrapper = styled.div`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`

export const InfoWrapper = styled.div`
  margin-top: ${theme.space.xs}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  padding-left: ${theme.space.xs}px;
`
