import { CircularProgress } from '@mui/material'
import { theme } from '@traba/theme'
import { ShiftWithSlimCharges } from '@traba/types'
import { useMemo } from 'react'
import { Col, Row } from 'src/components/base'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import InvoiceGroupSection from './InvoiceGroupSection'

type InvoiceGroupSelectorProps = {
  selectedInvoiceGroupIds: string[]
  handleSelectedInvoiceGroupIds: (groupId: string) => void
  shifts: ShiftWithSlimCharges[]
  isFetchingShifts: boolean
  selectedShifts: ShiftWithSlimCharges[]
  onSelectShiftIds: (shiftIds: string[]) => void
}

type GroupData = { name: string; shifts: ShiftWithSlimCharges[] }

export default function InvoiceGroupSelector(props: InvoiceGroupSelectorProps) {
  const {
    selectedInvoiceGroupIds,
    handleSelectedInvoiceGroupIds,
    shifts,
    isFetchingShifts,
    selectedShifts,
    onSelectShiftIds,
  } = props

  const groupData = useMemo(() => {
    return shifts.reduce((acc: { [key: string]: GroupData }, shift) => {
      const { parentInvoiceGroupId, invoiceGroupName } = shift
      if (parentInvoiceGroupId) {
        if (!acc[parentInvoiceGroupId]) {
          acc[parentInvoiceGroupId] = {
            name: invoiceGroupName ?? '',
            shifts: [],
          }
        }
        acc[parentInvoiceGroupId].shifts.push(shift)
      }
      return acc
    }, {})
  }, [shifts])

  const InvoiceGroupSelectorContainer = (props: {
    children: React.ReactNode
  }) => {
    return (
      <Col style={{ paddingBottom: theme.space.xs }}>
        <Row justifyCenter>{props.children}</Row>
      </Col>
    )
  }

  if (isFetchingShifts) {
    return (
      <InvoiceGroupSelectorContainer>
        <CircularProgress size={20} />
      </InvoiceGroupSelectorContainer>
    )
  }

  if (!shifts.length) {
    return (
      <InvoiceGroupSelectorContainer>
        <InlineBanner
          text="No uninvoiced grouped shifts with a charge found within the search filters"
          severity="info"
        />
      </InvoiceGroupSelectorContainer>
    )
  }

  return (
    <InvoiceGroupSelectorContainer>
      <Col style={{ maxHeight: '40em', overflow: 'no-scroll' }}>
        {Object.entries(groupData).map(([groupId, groupData]) => (
          <Col mb={theme.space.xs}>
            <Row mb={theme.space.xxs}>
              <InvoiceGroupSection
                groupName={groupData.name}
                groupId={groupId}
                shifts={groupData.shifts}
                selected={selectedInvoiceGroupIds.includes(groupId)}
                onSelect={() => handleSelectedInvoiceGroupIds(groupId)}
                selectedShifts={selectedShifts}
                onSelectShiftIds={onSelectShiftIds}
              />
            </Row>
          </Col>
        ))}
      </Col>
    </InvoiceGroupSelectorContainer>
  )
}
