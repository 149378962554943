import { createCalendar } from '@internationalized/date'
import { AriaRangeCalendarProps } from '@react-aria/calendar'
import { DateValue } from '@react-types/datepicker'
import { Text } from '@traba/react-components'
import { useRef } from 'react'
import { useLocale, useRangeCalendar } from 'react-aria'

import { useRangeCalendarState } from 'react-stately'
import { Row } from 'src/components/base'
import Icon from 'src/components/base/Icon'
import { CalendarButton } from './Button'
import CalendarGrid from './CalendarGrid'

interface RangeCalendarProps extends AriaRangeCalendarProps<DateValue> {
  timezone: string
}

export default function RangeCalendar(props: RangeCalendarProps) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, state, ref)

  return (
    <div {...calendarProps} ref={ref}>
      <Row center alignCenter pb={8} justifyBetween>
        <Text variant="h5">{title}</Text>
        <Row gap={8}>
          <CalendarButton {...prevButtonProps}>
            <Icon name="chevronLeft" width={15} height={15} />
          </CalendarButton>
          <CalendarButton {...nextButtonProps}>
            <Icon name="chevronRight" width={15} height={15} />
          </CalendarButton>
        </Row>
      </Row>
      <CalendarGrid state={state} timezone={props.timezone} />
    </div>
  )
}
