import { theme } from '@traba/theme'

export function Checkmark({
  color = '#1AB373',
  size,
  ...props
}: {
  color: string
  size?: number
}) {
  return (
    <svg
      width={size ?? '14'}
      height={size ?? theme.space.xsmed}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1L4.42857 11L1 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
