import { useState } from 'react'
import { useApi } from './useApi'

// Pagination parameters
export const DEFAULT_PAGE_SIZE = 50

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type PaginationParams = {
  limit?: number
  offset?: number
  sortBy?: string
  sortOrder?: SortOrder
}

export const basePaginationParams: PaginationParams = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
  sortBy: 'createdAt',
  sortOrder: SortOrder.asc,
}

export function useServerPagination<T>(endpoint: string, orderBy?: string) {
  const [currentPage, setCurrentPage] = useState(0)
  const goToNextPage = () => setCurrentPage(currentPage + 1)
  const goToPreviousPage = () =>
    currentPage > 0 && setCurrentPage(currentPage - 1)

  const { data, isLoading } = useApi<T[]>(
    `${endpoint}?startAt=${currentPage * DEFAULT_PAGE_SIZE}&limit=${
      DEFAULT_PAGE_SIZE + 1
    }${orderBy ? `&orderBy=${String(orderBy)}` : ''}`,
  )

  return {
    data,
    isLoading,
    goToNextPage,
    goToPreviousPage,
    setCurrentPage,
    currentPage,
  }
}

export function useBasicPagination(pageSize?: number) {
  const [currentPage, setCurrentPage] = useState(0)

  const goToNextPage = () => setCurrentPage(currentPage + 1)
  const goToPreviousPage = () =>
    currentPage > 0 && setCurrentPage(currentPage - 1)

  const getNextPaginationOffsetAndUpdatePage = (
    direction: 'next' | 'previous' | 'initial',
  ) => {
    let offset = currentPage * (pageSize ?? DEFAULT_PAGE_SIZE)
    if (direction === 'next') {
      setCurrentPage((prev) => prev + 1)
      offset = (currentPage + 1) * (pageSize ?? DEFAULT_PAGE_SIZE)
    } else if (direction === 'previous') {
      setCurrentPage((prev) => (prev > 0 ? prev - 1 : prev))
      offset =
        currentPage > 0
          ? (currentPage - 1) * (pageSize ?? DEFAULT_PAGE_SIZE)
          : currentPage * (pageSize ?? DEFAULT_PAGE_SIZE)
    } else {
      setCurrentPage(0)
      offset = 0
    }
    return offset
  }

  const currentLocalOffset = currentPage * (pageSize ?? DEFAULT_PAGE_SIZE)

  return {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    setCurrentPage,
    getNextPaginationOffsetAndUpdatePage,
    currentLocalOffset,
  }
}
