import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Icon, Row } from 'src/components/base'
import { getMoneyString } from 'src/utils/stringUtils'

export type EarningsFormattedPaymentProps = {
  transferredPay: number
  owedPay: number
}

export default function EarningsFormattedPayment({
  transferredPay,
  owedPay,
}: EarningsFormattedPaymentProps) {
  if (transferredPay === owedPay) {
    return (
      <Row ml={theme.space.xxs}>
        <Text
          style={{
            color: theme.colors.Green80,
            marginRight: theme.space.xxxs,
            fontWeight: 600,
          }}
          variant="money"
        >
          {getMoneyString(transferredPay / 100)} Paid
        </Text>
        <Icon name="greenCheck" />
      </Row>
    )
  } else {
    return (
      <Text
        style={{ color: theme.colors.Red60, fontWeight: 600 }}
        variant="money"
        ml={theme.space.xxs}
      >
        {getMoneyString(transferredPay / 100)} of{' '}
        {getMoneyString(owedPay / 100)} Paid
      </Text>
    )
  }
}
