import { AccountStatus } from '@traba/types'
import { Worker } from '@traba/types'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Dropdown, DropdownElement } from 'src/components/base/Dropdown'
import { UserRolePermission } from 'src/context/user/types'
import { useApi } from 'src/hooks/useApi'
import { useDownloadResume } from 'src/hooks/useDownloadResume'
import { usePermissions } from 'src/hooks/usePermissions'
import { openInNewTab } from 'src/utils/helperUtils'
import {
  getCheckrReportUrl,
  getRetoolUrl,
  getStripeUrl,
} from 'src/utils/workerUtils'

type WorkerSearchDropdownProps = {
  worker: Worker
  buttonVariant?: ButtonVariant
  showProfileLink?: boolean
}

export default function WorkerSearchDropdown({
  worker,
  buttonVariant,
  showProfileLink = true,
}: WorkerSearchDropdownProps) {
  const workerId = worker.workerId ?? worker.uid
  const navigate = useNavigate()
  const { downloadResume, isLoading: resumeIsLoading } =
    useDownloadResume(workerId)
  const {
    data: workerProfile,
    isLoading,
    refetch: fetchWorkerProfile,
  } = useApi<Worker & AccountStatus>(
    `workers/${workerId}/worker-and-profile`,
    {},
    { enabled: false },
  )

  async function onOpenDropdown() {
    await fetchWorkerProfile()
  }

  const backgroundCheck =
    workerProfile?.backgroundCheck || workerProfile?.stash?.backgroundCheck
  const isBgcAvailable =
    !!backgroundCheck?.candidateId && !!backgroundCheck?.reportId

  const checkrReportUrl = backgroundCheck
    ? getCheckrReportUrl(backgroundCheck)
    : ''
  const stripeUrl = getStripeUrl(worker)
  const retoolUrl = getRetoolUrl(worker)

  // Eventually might be based on stripe, bgc, retool etc no differentiator atm
  const dropdownActionAccess = usePermissions([UserRolePermission.RetoolAccess])

  const workerActions: DropdownElement[] = isLoading
    ? [
        {
          label: 'Loading...',
          handleClick: () => null,
        },
      ]
    : [
        ...(showProfileLink
          ? [
              {
                label: 'Profile',
                handleClick: () => navigate(`/workers/${workerId}`),
              },
            ]
          : []),
        {
          disabled: !isBgcAvailable || !dropdownActionAccess,
          label: 'BGC',
          handleClick: () => isBgcAvailable && openInNewTab(checkrReportUrl),
        },
        {
          disabled: !dropdownActionAccess,
          label: 'Stripe',
          handleClick: () => openInNewTab(stripeUrl),
        },
        {
          disabled: !dropdownActionAccess,
          label: 'Retool',
          handleClick: () => openInNewTab(retoolUrl),
        },
        {
          disabled: false,
          label: resumeIsLoading ? 'Loading...' : 'Download Resume',
          handleClick: () => {
            if (resumeIsLoading) {
              return
            }
            downloadResume()
          },
        },
      ]

  return (
    <Dropdown
      dropdownOptions={workerActions}
      orientation={'left'}
      onOpenDropdown={onOpenDropdown}
      buttonVariant={buttonVariant}
    />
  )
}
