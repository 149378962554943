import { BusinessComplaint } from '@traba/types'
import { Icon, Td, Tr } from 'src/components/base'
import { complaintDisplayName } from 'src/utils/complaintUtils'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'

type IncidentsTableRowProps = {
  businessComplaint: BusinessComplaint
}

export default function IncidentsTableRow(props: IncidentsTableRowProps) {
  const { businessComplaint } = props

  return (
    <Tr>
      <Td>{complaintDisplayName(businessComplaint.complaintId)}</Td>
      <Td>{formatDateTimeWithTimezone(businessComplaint.createdAt)}</Td>
      <Td centerContent>
        {businessComplaint.requiresManualInvoiceAdjustment ? (
          <Icon name="greenCheck" />
        ) : (
          '-'
        )}
      </Td>
      <Td>{businessComplaint.complaintReason}</Td>
    </Tr>
  )
}
