import { Text } from '@traba/react-components'
import { useNavigate } from 'react-router-dom'
import { Nav } from 'src/screens/WorkerDetailsScreen/WorkerDetailsScreen.styles'
import Avatar from './Avatar'
import { Icon, Row } from '.'

interface PageHeaderProps {
  avatarString: string
  avatarUrl?: string
  children?: React.ReactNode
}

export const PageHeader = (props: PageHeaderProps) => {
  const { avatarString, avatarUrl, children } = props
  const navigate = useNavigate()

  return (
    <>
      <Nav>
        <Text variant="body2" onClick={() => navigate(-1)}>
          <Icon name="leftArrow" />
          Go back
        </Text>
      </Nav>
      <div
        style={{
          height: '40px',
          margin: '0 -40px',
        }}
      />
      <Row mt={-12} alignCenter>
        <Avatar src={avatarUrl} name={avatarString} />
        {children}
      </Row>
    </>
  )
}
