import { trabaApi } from '@traba/api-utils'
import { WorkerShiftEligibility } from '@traba/types'
import { uniq } from 'lodash'
import { useQuery } from 'react-query'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface UseWorkerShiftEligibilitiesProps {
  workersSearchResult: PopulatedWorker[]
  shiftId: string
  hasProcessedEligibility: boolean
  setHasProcessedEligibility: React.Dispatch<React.SetStateAction<boolean>>
}

interface UseEligibilitiesProps {
  workerIds: string[]
  shiftId: string
  isEnabled: boolean | undefined
}

export interface WorkerWithEligibility {
  worker: PopulatedWorker
  eligibility: WorkerShiftEligibility | undefined
}

export async function fetchEligibilities(
  workerIds: string[],
  shiftId: string,
): Promise<WorkerShiftEligibility[]> {
  const res = await trabaApi.post<WorkerShiftEligibility[]>(
    `worker-shifts/${shiftId}/check-eligibilities`,
    { workerIds: uniq(workerIds) },
  )
  return res.data
}

async function getWorkersWithEligibilities(
  workersSearchResult: PopulatedWorker[],
  shiftId: string,
): Promise<WorkerWithEligibility[]> {
  const workerIds: string[] = workersSearchResult.flatMap((worker) =>
    worker.id ? [worker.id] : [],
  )
  const eligibilities = await fetchEligibilities(workerIds, shiftId)

  return workersSearchResult.map((worker) => ({
    worker,
    eligibility: eligibilities.find(
      (el: WorkerShiftEligibility) => el.workerId === worker.id,
    ),
  }))
}

const useWorkerShiftsEligibilities = ({
  workersSearchResult = [],
  shiftId = '',
  hasProcessedEligibility = false,
  setHasProcessedEligibility,
}: UseWorkerShiftEligibilitiesProps) => {
  const sortedWorkerIds = workersSearchResult.map((worker) => worker.id).sort()
  const {
    data: workersWithEligibility,
    isLoading: isFetchingEligibility,
    isError,
    error,
  } = useQuery<WorkerWithEligibility[], Error>(
    ['workerShiftsEligibilities', shiftId, sortedWorkerIds],
    () => getWorkersWithEligibilities(workersSearchResult, shiftId),
    {
      enabled:
        shiftId.length > 0 &&
        !!workersSearchResult.length &&
        !hasProcessedEligibility,
      onSuccess: () => setHasProcessedEligibility(true),
    },
  )
  return {
    workersWithEligibility,
    isFetchingEligibility,
    isError,
    error,
  }
}

export const useEligibilities = ({
  workerIds = [],
  shiftId = '',
  isEnabled = true,
}: UseEligibilitiesProps) => {
  const {
    data: eligibilityData,
    isLoading: isLoadingEligibilityData,
    refetch: fetchEligibilityData,
  } = useQuery<WorkerShiftEligibility[]>(
    ['workerEligibilities', shiftId, workerIds.sort()],
    () => fetchEligibilities(workerIds, shiftId),
    {
      enabled: shiftId.length > 0 && !!workerIds.length && isEnabled,
    },
  )
  return {
    eligibilityData,
    isLoadingEligibilityData,
    fetchEligibilityData,
  }
}

export default useWorkerShiftsEligibilities
