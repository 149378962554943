import { CircularProgress } from '@mui/material'
import { Anchor } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PaidBackup, WorkerScores } from '@traba/types'
import { ExtendedShift } from '@traba/types'
import { WorkerShiftTimeToDestination } from '@traba/types'
import { addSeconds, differenceInMinutes } from 'date-fns'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  Button,
  CopyTextIcon,
  Td,
  Tr,
  AccountStatusBadge,
  Icon,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { Dropdown } from 'src/components/base/Dropdown'
import { WorkerNameWithIcons } from 'src/components/WorkerNameWithIcons/WorkerNameWithIcons'
import WorkerCompletions from 'src/components/WorkerTable/components/WorkerCompletions'
import WorkerCoordinate from 'src/components/WorkerTable/components/WorkerCoordinate'
import {
  PaidBackupAction,
  usePaidBackupsMutations,
} from 'src/hooks/usePaidBackups'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import PaidBackupConfirmationIcon from './PaidBackupConfirmationIcon'
import PaidBackupStatusBadge from './PaidBackupStatusBadge'

type PaidBackupTableRowProps = {
  paidBackup: PaidBackup
  shift: ExtendedShift
  favoriteWorkerIds: Set<string>
  timeToDestination?: WorkerShiftTimeToDestination
  workerScoresByWorkerId: { [workedId: string]: WorkerScores }
  refetchDependencies: () => void
}

export default function PaidBackupTableRow(props: PaidBackupTableRowProps) {
  const {
    paidBackup,
    shift,
    refetchDependencies,
    timeToDestination,
    favoriteWorkerIds,
    workerScoresByWorkerId,
  } = props
  const { workerId, shiftId, worker } = paidBackup
  const workerZipCode = worker.zipCode
  const shiftCoords = shift.location.coords
  const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)
  const workerName = `${worker.firstName} ${worker.lastName}`
  const tz = useTimezonedDates(shift.timezone)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { updatePaidBackupsMutation } = usePaidBackupsMutations()

  const differenceBetweenArrivalAndStart = paidBackup.workerSiteArrivalTime
    ? differenceInMinutes(
        new Date(paidBackup.workerSiteArrivalTime),
        new Date(shift.startTime),
      )
    : 0
  const arrivalTimeString = `~${Math.abs(
    differenceBetweenArrivalAndStart,
  )} mins ${differenceBetweenArrivalAndStart > 0 ? 'after' : 'before'} start`

  const handleClick = useCallback(
    async (action: PaidBackupAction) => {
      setIsLoading(true)
      await updatePaidBackupsMutation(
        {
          workerId,
          shiftId,
          action,
        },
        () => {
          refetchDependencies()
          setIsLoading(false)
        },
      )
    },
    [workerId, shiftId, updatePaidBackupsMutation, refetchDependencies],
  )

  const paidBackupActions = [
    {
      label: 'Add to Shift',
      handleClick: () => handleClick(PaidBackupAction.ACCEPT_SHIFT),
      icon: () => <Icon name="checkmark" />,
    },
    {
      label: 'Dismiss and Pay',
      handleClick: () => handleClick(PaidBackupAction.DISMISS),
      icon: () => <Icon name="cancel" />,
    },
    {
      label: 'Cancel',
      handleClick: () => handleClick(PaidBackupAction.CANCEL),
      icon: () => <Icon name="close" />,
    },
  ]

  const workerScores = workerScoresByWorkerId?.[workerId]

  return (
    <Tr key={`paid_backup_${workerId}`} colorWhenHover={true}>
      <Td>
        <Link to={`/workers/${workerId}`} target="_blank">
          <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
            {isLoading && <CircularProgress size={theme.space.xs} />}
            <WorkerNameWithIcons
              name={workerName}
              isFavoriteWorker={favoriteWorkerIds.has(workerId)}
              isFirstShiftWithCompany={
                workerScores &&
                !workerScores.companyCounts.some(
                  (cc) => cc.companyId === shift.companyId,
                )
              }
              isFirstShiftWithTraba={workerScores?.numberOfCompletions === 0}
            />
          </Button>
        </Link>
      </Td>
      <Td>
        {worker.accountApprovalStatus && (
          <AccountStatusBadge accountStatus={worker.accountApprovalStatus} />
        )}
      </Td>
      <Td>
        {truncateString(workerId)} <CopyTextIcon textToCopy={workerId} />
      </Td>
      <Td>
        <Anchor openInNewTab={false} href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Anchor>
        <CopyTextIcon textToCopy={phoneNumber} />
      </Td>
      <Td>{worker.tierLevel ? `${worker.tierLevel}` : '-'}</Td>
      <Td>
        <PaidBackupStatusBadge
          source={paidBackup.source}
          status={paidBackup.status}
        />
      </Td>
      <Td>
        {workerZipCode ? (
          <WorkerCoordinate
            workerZipCode={workerZipCode}
            shiftCoords={shiftCoords}
          />
        ) : (
          '-'
        )}
      </Td>
      <Td>
        <PaidBackupConfirmationIcon
          paidBackup={paidBackup}
          timezone={shift.timezone}
        />
      </Td>
      <Td>{timeToDestination?.onTimeStatus || '-'}</Td>
      <Td>
        {timeToDestination?.transitStatus || '-'}
        <ReactTooltip
          place="top"
          id={`worker-arrival${workerId}`}
          style={{ zIndex: 2 }}
        >
          <p style={{ lineHeight: '24px' }}>
            Arrived At:{' '}
            {timeToDestination?.arrivedAt
              ? tz.getTime(timeToDestination.arrivedAt)
              : '-'}
          </p>
          <p style={{ lineHeight: '24px' }}>{arrivalTimeString}</p>
        </ReactTooltip>
      </Td>
      <Td>
        {timeToDestination
          ? tz.getTime(
              timeToDestination.arrivedAt
                ? timeToDestination.arrivedAt
                : addSeconds(
                    timeToDestination.recordedAt,
                    timeToDestination.timeToDestinationSeconds,
                  ),
            )
          : '-'}
      </Td>
      <Td>
        <WorkerCompletions
          workerScores={workerScores}
          companyId={shift.companyId}
          roleId={shift.roleId}
        />
      </Td>
      <Td>
        <Dropdown orientation="left" dropdownOptions={paidBackupActions} />
      </Td>
    </Tr>
  )
}
