import { CompanyCategory } from '@traba/types'

import { CertificationEntry } from '@traba/types'
import { Company, Role } from '@traba/types'
import { ShiftAttribute } from '@traba/types'
import { RoleAttribute, RoleAttributeCategory } from '@traba/types'
import _ from 'lodash'

export const parseFreeFormAttributesToRequiredAttributes = (
  requiredAttributes: ShiftAttribute[] | undefined,
  freeformAttributes:
    | Partial<Record<RoleAttributeCategory, string>>
    | undefined,
) => {
  const fullRequiredAttributes = requiredAttributes ? requiredAttributes : []

  const hasLiftingCategory = requiredAttributes?.some(
    (attribute) => attribute.category === RoleAttributeCategory.Lifting,
  )

  if (!hasLiftingCategory) {
    requiredAttributes?.push({
      category: RoleAttributeCategory.Lifting,
      type: `other_${RoleAttributeCategory.Lifting}`,
    })
  }

  if (freeformAttributes) {
    if (freeformAttributes[RoleAttributeCategory.Responsibilities]) {
      fullRequiredAttributes.push({
        type: `other_${RoleAttributeCategory.Responsibilities}`,
        category: RoleAttributeCategory.Responsibilities,
      })
    }
    if (freeformAttributes[RoleAttributeCategory.Equipment]) {
      fullRequiredAttributes.push({
        type: `other_${RoleAttributeCategory.Equipment}`,
        category: RoleAttributeCategory.Equipment,
      })
    }
  }
  return fullRequiredAttributes
}

export function getRoleCardEntries(
  role?: Partial<Role>,
  attributes?: RoleAttribute[],
  certifications?: CertificationEntry[],
  company?: Company,
  fromPrevious?: boolean,
) {
  function getAttributesByCategory(category: RoleAttributeCategory) {
    const filteredAttributes = (
      role?.requiredAttributes?.filter((ra) => ra.category === category) ?? []
    )
      .map((r) =>
        _.upperFirst(attributes?.find((a) => a.type === r.type)?.displayName),
      )
      .filter((a) => !!a)
    if (role?.freeformAttributes && category in role.freeformAttributes) {
      filteredAttributes.push(role.freeformAttributes[category]!)
    }
    return filteredAttributes
  }
  const certificationNames = (role?.requiredCertifications || []).map(
    (r) => certifications?.find((c) => c.type === r)?.name,
  )

  function withFallback(content?: string) {
    return !role ? '…' : content || 'None'
  }

  const gearString = getAttributesByCategory(RoleAttributeCategory.Gear).join(
    ', ',
  )

  const isEventCompany = company?.category === CompanyCategory.EVENTS

  const nameSection = { label: 'Name', value: withFallback(role?.roleName) }
  const responsibilitiesSection = {
    label: 'Responsibilities & equipment',
    value: withFallback(
      [
        ...getAttributesByCategory(RoleAttributeCategory.Responsibilities),
        ...getAttributesByCategory(RoleAttributeCategory.Lifting),
        ...getAttributesByCategory(RoleAttributeCategory.Equipment),
        ...getAttributesByCategory(
          RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
        ),
        ...getAttributesByCategory(
          RoleAttributeCategory.FOOD_PREPARATION_AND_SERVICE,
        ),
      ].join(', '),
    ),
  }
  const requirementsSection = {
    label: 'Requirements & certifications',
    value: withFallback(
      [
        ...certificationNames,
        ...getAttributesByCategory(RoleAttributeCategory.Languages),
        ...(isEventCompany
          ? getAttributesByCategory(RoleAttributeCategory.Lifting)
          : []),
      ].join(', '),
    ),
  }
  const attireSection = {
    label: 'Attire & gear',
    value: withFallback(
      gearString
        ? `${gearString}; ${role?.requiredAttire}`
        : role?.requiredAttire,
    ),
  }
  const detailsSection = {
    label: 'Overview',
    value: withFallback(role?.roleDescription),
  }

  return (
    !isEventCompany
      ? [
          nameSection,
          responsibilitiesSection,
          requirementsSection,
          attireSection,
          detailsSection,
        ]
      : [nameSection, detailsSection, requirementsSection, attireSection]
  ).filter((s) => !fromPrevious || !['Name', 'Overview'].includes(s.label))
}
