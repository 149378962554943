import { Transportation } from '@traba/types'
import Badge from '.'

export type TransportationBadgeProps = {
  transportationMethod?: Transportation
}

export function TransportationBadge({
  transportationMethod,
}: TransportationBadgeProps) {
  return (
    <Badge
      title={transportationMethod || 'Not Filled'}
      variant={transportationMethod ? 'success' : 'disabled'}
    />
  )
}
