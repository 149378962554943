import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { identity, isEmpty, pickBy } from 'lodash'
import { useEffect, useState } from 'react'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { DEFAULT_INITIAL_FORM, useSearchWorkers } from 'src/hooks/useWorkers'
import { Row } from '../../components/base'
import Pagination from '../../components/base/Pagination/Pagination'
import { DEFAULT_PAGE_SIZE, SortOrder } from '../../hooks/usePagination'
import WorkerSearchBadges from './components/WorkerSearchBadges'
import WorkerSearchForm from './components/WorkerSearchForm'
import WorkerSearchResults from './components/WorkerSearchResults'

export type FormState<T = Record<string, any>> = T

const defaultSort = { sortBy: 'firstName', sortOrder: SortOrder.asc }

export default function WorkerSearchScreen() {
  const [form, setForm] = useState<FormState>(DEFAULT_INITIAL_FORM)
  const [sort, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>(
    defaultSort,
  )

  const {
    isLoading,
    error,
    searchWorkers,
    workersSearchResult,
    activeSearchParamsObject,
    totalFound,
    currentPage,
  } = useSearchWorkers()

  //Removes undefined values from form object before passing to search
  const searchData = pickBy(form, identity)

  function handleClearParams(key?: string) {
    if (key) {
      setSort(defaultSort)
      setForm({ ...form, [key]: undefined })
      searchWorkers({
        searchData: { ...searchData, [key]: undefined },
        direction: 'initial',
      })
      return
    }
    setSort(defaultSort)
    setForm({})
    searchWorkers({
      searchData: {},
      direction: 'initial',
    })
    return
  }

  function handleSortSearch(sortBy = 'firstName', sortOrder = SortOrder.asc) {
    setSort({ sortBy, sortOrder })
    searchWorkers({
      searchData,
      direction: 'initial',
      sort: {
        sortBy,
        sortOrder,
      },
    })
  }

  const handleSortChange = (field: string, order: SortOrder) => {
    setSort({ sortBy: field, sortOrder: order })
  }

  // make a query on page load
  useEffect(() => {
    searchWorkers({ searchData, direction: 'initial', sort: defaultSort })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleKeyboardPagination = (event: KeyboardEvent) => {
      const lastPage = Math.ceil(totalFound / DEFAULT_PAGE_SIZE) - 1

      if (event.shiftKey && event.ctrlKey) {
        if (
          currentPage > 0 &&
          (event.key === 'ArrowLeft' || event.key === 'A')
        ) {
          searchWorkers({ searchData, direction: 'previous', sort })
        } else if (
          currentPage < lastPage &&
          (event.key === 'ArrowRight' || event.key === 'D')
        ) {
          searchWorkers({ searchData, direction: 'next', sort })
        }
      }
    }
    window.addEventListener('keydown', handleKeyboardPagination)
    return () => {
      window.removeEventListener('keydown', handleKeyboardPagination)
    }
  }, [searchData, searchWorkers, currentPage, totalFound, sort])

  return (
    <MainLayout title="Search Workers">
      <WorkerSearchForm
        form={form}
        setForm={setForm}
        onPressSearch={() =>
          searchWorkers({ searchData, direction: 'initial', sort: defaultSort })
        }
        isLoading={isLoading}
        onClearParams={handleClearParams}
      />
      <Row mt={46} mb={8}>
        <Text variant="h4">
          {isLoading ? 'Searching...' : `Search Results (${totalFound})`}
        </Text>
      </Row>
      {error && (
        <Row mb={30}>
          <Text variant="error">{`Error searching for worker : ${error}`}</Text>
        </Row>
      )}
      {!isEmpty(activeSearchParamsObject) && (
        <Row my={16}>
          <WorkerSearchBadges
            searchParams={activeSearchParamsObject}
            onClearBadge={handleClearParams}
          />
        </Row>
      )}

      <Row justifyCenter mb={theme.space.xs}>
        <Pagination
          dataSize={workersSearchResult.length}
          onPageLeft={() =>
            searchWorkers({ searchData, direction: 'previous', sort })
          }
          onPageRight={() =>
            searchWorkers({ searchData, direction: 'next', sort })
          }
          page={currentPage}
          pageSize={DEFAULT_PAGE_SIZE}
          totalFound={totalFound}
        />
      </Row>

      <WorkerSearchResults
        isLoading={isLoading}
        workers={workersSearchResult}
        runSearch={handleSortSearch}
        onSortChange={handleSortChange}
        sort={sort}
      />
      <Row justifyCenter mt={16}>
        <Pagination
          dataSize={workersSearchResult.length}
          onPageLeft={() =>
            searchWorkers({ searchData, direction: 'previous', sort })
          }
          onPageRight={() =>
            searchWorkers({ searchData, direction: 'next', sort })
          }
          page={currentPage}
          pageSize={DEFAULT_PAGE_SIZE}
          totalFound={totalFound}
        />
      </Row>
    </MainLayout>
  )
}
