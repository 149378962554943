import { useHotSettings } from '@traba/hooks'
import { LoadingSpinner, Row, Text, Toggle } from '@traba/react-components'
import { theme } from '@traba/theme'
import { isShiftRequestParentInactive } from '@traba/utils'
import { useState } from 'react'

import { ScheduleInfoCard } from 'src/components/Schedule/ScheduleInfoCard'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'

interface Props {
  companyId: string
}

export const CompanySchedulesTab = ({ companyId }: Props) => {
  const { hotSettings } = useHotSettings()
  const [showInactiveSchedules, setShowInactiveSchedules] = useState(false)
  const { isLoading, shiftRequestParents } = useShiftRequestParents({
    companyId,
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (
    !hotSettings?.recurringSchedulesEnabled &&
    !hotSettings?.recurringSchedulesEnabledCompanies?.includes(companyId)
  ) {
    return (
      <Text variant="h4" mb={theme.space.xs}>
        Recurring schedule is not enabled for this company
      </Text>
    )
  }

  return (
    <>
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h4" mb={theme.space.xs}>
          Schedules
        </Text>
        <Toggle
          label="Show inactive schedules"
          buttonState={showInactiveSchedules}
          runOnChange={() => setShowInactiveSchedules((s) => !s)}
        ></Toggle>
      </Row>
      {shiftRequestParents?.map((srp, index) =>
        showInactiveSchedules || !isShiftRequestParentInactive(srp) ? (
          <ScheduleInfoCard
            shiftRequestParent={srp}
            key={`${srp.shiftRequestParentId}_${index}`}
          />
        ) : null,
      )}
    </>
  )
}
