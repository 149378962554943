import { InternalUser, InternalUserRank } from '@traba/types'

export function internalUserHasSupervisorPrivileges(
  internalUser?: InternalUser,
) {
  if (!internalUser) {
    return false
  }

  return (
    internalUser.rank === InternalUserRank.ADMIN ||
    internalUser.rank === InternalUserRank.SUPERVISOR
  )
}

export function internalUserHasOpsAdminPrivileges(internalUser?: InternalUser) {
  if (!internalUser) {
    return false
  }

  return internalUser.rank === InternalUserRank.OPS_ADMIN
}

export function internalUserHasOpsAdminOrAdminPrivileges(
  internalUser?: InternalUser,
) {
  if (!internalUser) {
    return false
  }

  return (
    internalUser.rank === InternalUserRank.OPS_ADMIN ||
    internalUser.rank === InternalUserRank.ADMIN
  )
}

export function internalUserHasOpsLeadPrivileges(internalUser?: InternalUser) {
  if (!internalUser) {
    return false
  }

  return (
    internalUser.rank === InternalUserRank.OPS_ADMIN ||
    internalUser.rank === InternalUserRank.OPS_LEAD ||
    internalUser.rank === InternalUserRank.ADMIN
  )
}
