import { SelectChangeEvent } from '@mui/material'
import { MenuItem, Select } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftBadge } from '@traba/types'
import { useState } from 'react'
import { Badge, Button, Col, Row } from 'src/components/base'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'

import { RadioButton } from 'src/components/RadioButton/RadioButton'
import { useCustomBadges } from 'src/hooks/useCustomBadges'
import {
  BadgePreview,
  getBadgeDetails,
} from 'src/screens/ToolsScreen/components/CustomizedBadge/BadgePreview'

interface Props {
  existingBadges?: ShiftBadge[]
  onAddBadge: (badge: ShiftBadge) => void
  onRemoveBadge: (badge: ShiftBadge) => void
  description: string
  title?: string
  existingBadgesTitle: string
  addBadgeTitle: string
}

export const ShiftBadgeContent = ({
  existingBadges,
  onAddBadge,
  onRemoveBadge,
  description,
  existingBadgesTitle,
  addBadgeTitle,
  title,
}: Props) => {
  const { activeBadges } = useCustomBadges()
  const [selectedBadgeToAdd, setSelectedBadgeToAdd] =
    useState<ShiftBadge | null>()
  const [selectedExistingBadge, setSelectedExistingBadge] =
    useState<ShiftBadge | null>()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedExistingBadge(null)
    setSelectedBadgeToAdd(
      activeBadges?.find((badge) => badge.id === event.target.value),
    )
  }
  const hasMoreThanFourExistingBadges =
    !!existingBadges && existingBadges?.length >= 5
  return (
    <>
      {title && (
        <Text variant="h4" my={theme.space.xs}>
          {title}
        </Text>
      )}
      <InlineBanner severity="warning" text={description} />
      <Col>
        <Text variant="h6" mt={theme.space.xs}>
          {existingBadgesTitle}
        </Text>
        <Row alignCenter gap={theme.space.xs} justifyBetween>
          {existingBadges?.map((badge) => {
            const { variant, badgeName, id } = badge
            const { badgeStyleVariant } = getBadgeDetails(variant)
            return (
              <Row
                key={id}
                gap={theme.space.xxs}
                onClick={() => {
                  setSelectedBadgeToAdd(null)
                  setSelectedExistingBadge(badge)
                }}
              >
                <RadioButton selected={selectedExistingBadge?.id === id} />
                <Badge variant={badgeStyleVariant} title={badgeName} />
              </Row>
            )
          })}
          {existingBadges && existingBadges.length > 0 ? (
            <Button
              slim
              ml={theme.space.xs}
              disabled={!selectedExistingBadge?.id}
              onClick={() => {
                if (selectedExistingBadge) {
                  onRemoveBadge(selectedExistingBadge)
                }
                setSelectedExistingBadge(null)
              }}
            >
              Remove badge
            </Button>
          ) : (
            <Text variant="body2">None</Text>
          )}
        </Row>
        <Text variant="h6" mt={theme.space.xs}>
          {addBadgeTitle}
        </Text>
        {hasMoreThanFourExistingBadges && (
          <InlineBanner
            severity="warning"
            text="There are 5 existing badges already. If you are sure you need to add more badges, please reach out to eng-help"
          />
        )}
        <Row mb={theme.space.sm} mt={theme.space.xxs}>
          <Select
            style={{ flex: 1 }}
            value={selectedBadgeToAdd?.badgeName}
            onChange={handleChange}
            renderValue={() => selectedBadgeToAdd?.badgeName}
          >
            {activeBadges?.map(
              ({
                variant,
                id,
                badgeName,
                shortDescription,
                longDescription,
              }) => {
                const { badgeStyleVariant } = getBadgeDetails(variant)
                return (
                  <MenuItem key={id} value={id}>
                    <Col>
                      <Badge
                        disableUppercase
                        variant={badgeStyleVariant}
                        title={badgeName}
                      />
                      <Text
                        variant="body2"
                        style={{ color: theme.colors.MidnightBlue }}
                      >
                        {shortDescription}
                      </Text>
                      <Text variant="body3">{longDescription}</Text>
                    </Col>
                  </MenuItem>
                )
              },
            )}
          </Select>
          <Button
            slim
            ml={theme.space.xs}
            disabled={!selectedBadgeToAdd?.id || hasMoreThanFourExistingBadges}
            onClick={() => {
              if (selectedBadgeToAdd) {
                onAddBadge(selectedBadgeToAdd)
              }
              setSelectedBadgeToAdd(null)
            }}
          >
            Add badge
          </Button>
        </Row>
        <Col style={{ maxWidth: 420 }}>
          {selectedBadgeToAdd && (
            <BadgePreview
              selectedVariant={selectedBadgeToAdd.variant}
              longDescription={selectedBadgeToAdd.longDescription ?? ''}
              shortDescription={selectedBadgeToAdd.shortDescription}
              badgeName={selectedBadgeToAdd.badgeName}
            />
          )}
          {selectedExistingBadge && (
            <BadgePreview
              selectedVariant={selectedExistingBadge.variant}
              longDescription={selectedExistingBadge.longDescription ?? ''}
              shortDescription={selectedExistingBadge.shortDescription}
              badgeName={selectedExistingBadge.badgeName}
            />
          )}
        </Col>
      </Col>
    </>
  )
}
