import { Text } from '@traba/react-components'
import { AcceptShiftBypassSetting } from '@traba/types'
import { GenericAcceptShiftBypasses } from '@traba/types'
import { EligibilityCriteria } from '@traba/types'
import Checkbox from 'src/components/base/Checkbox'
import { getMapFromBypassSettings } from 'src/utils/workerEligibilityUtils'

interface Props {
  setBypasses: React.Dispatch<React.SetStateAction<GenericAcceptShiftBypasses>>
  criteria: keyof EligibilityCriteria
  acceptShiftBypassSettings: AcceptShiftBypassSetting[]
  bypasses: GenericAcceptShiftBypasses
}

export const BypassItem = (props: Props) => {
  const { criteria, bypasses, acceptShiftBypassSettings, setBypasses } = props
  const criteriaMap = getMapFromBypassSettings(acceptShiftBypassSettings)
  const bypass = criteriaMap.get(criteria)
  if (!bypass) {
    return (
      <Text variant="error" style={{ fontWeight: '600' }}>
        One or more workers can not accept shift as {criteria} is FALSE
      </Text>
    )
  }

  if (bypass.errorText) {
    return (
      <Text variant="error" style={{ fontWeight: '600' }}>
        {bypass.errorText}
      </Text>
    )
  }
  if (!bypass.label || !bypass.bypassKey || !bypass.eligibilityCriteria) {
    return (
      <Text variant="error" style={{ fontWeight: '600' }}>
        One or more workers can not accept shift as {criteria} is FALSE
      </Text>
    )
  }
  return (
    <Checkbox
      checked={bypasses[bypass.bypassKey]}
      label={bypass.label}
      onChange={(e) => {
        setBypasses({
          ...bypasses,
          [String(bypass.bypassKey)]: e.target.checked,
        })
      }}
    />
  )
}
