import { CircularProgress, Switch } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  ButtonVariant,
  ModalButtons,
  RoleSearchSelect,
  SupervisorForShiftSearchSelect,
  Text,
} from '@traba/react-components'
import { InfoTooltip, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  EmploymentType,
  ForwardFillMax,
  InvitedWorkers,
  PaymentType,
  RequiredAttributeLevel,
  ShiftSignupStatus,
  ShiftTag,
} from '@traba/types'
import { BGCRequirement } from '@traba/types'
import { GenderPreference, RequiredMultiShiftType } from '@traba/types'
import { Role } from '@traba/types'
import { Shift, ShiftFeaturedStatus, ShiftPayType } from '@traba/types'
import { User } from '@traba/types'
import { WorkerCertificationType } from '@traba/types'
import { RoleAttribute } from '@traba/types'
import { TierLevel } from '@traba/types'
import {
  doesUserHaveAccessToLocation,
  getRoleSearchSelectErrorMessage,
  getSelectSupervisorForBookingShiftErrorMessage,
  isRoleLocationNotMatchingShiftLocation,
} from '@traba/utils'
import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { Autocomplete } from 'src/components/Autocomplete/Autocomplete'
import { Button, Col, Icon, Input, Row, Select } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'
import { DeprecatedNumberInput } from 'src/components/base/Input/DeprecatedNumberInput'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useModal } from 'src/components/base/Modal/Modal'
import { IMenuItem } from 'src/components/base/Select/Select'
import { BGCRequirementSelector } from 'src/components/BGCRequirementSelector/BGCRequirementSelector'
import { LocationSingleSearchSelector } from 'src/components/LocationSingleSearchSelector'
import { WeeklyPayWarning } from 'src/components/WeeklyPayWarning'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { getQueryParams } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useShiftTags } from 'src/hooks/useShiftTags'
import { sortByProp, valueEdited } from 'src/utils/helperUtils'
import { getEnumOptions } from 'src/utils/inputUtils'
import {
  convertCentsToDollars,
  convertPayRateToCents,
} from 'src/utils/moneyUtils'
import {
  MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB,
  genderPreferenceOptions,
  minimumTierOptions,
  paymentTypeOptions,
  validateClockCodeLength,
  validateDynamicOverbookAllowed,
  validateOverbook,
  validateSlots,
} from 'src/utils/shiftFormUtils'
import { validateEmail } from 'src/utils/stringUtils'

import Divider from '../../components/base/Divider'
import { RequiredAttributeLevelsModal } from '../RequiredAttributeLevelsModal/RequiredAttributeLevelsModal'
import { EditShiftsBreaks } from './EditShiftBreaks'
import { EditShiftTimes } from './EditShiftTimes'
import IncentivesSection from './IncentivesSection'
import InvoicingSection from './InvoicingSection'
import { ShiftSettingsSection } from './ShiftSettingsSection'
import { FormWrapper } from './style'
import {
  validateMinimumPaidTime,
  validatePaidBackups,
  validatePayRate,
  validateUnprovenThreshold,
} from './utils'

interface UpdateShiftData extends Partial<Shift> {
  genderPreference?: GenderPreference | null
  minimumAgeRequirement?: number | null
}
interface EditShiftFormProps {
  shift: Shift
  shiftList: string[]
  roles?: Role[]
  attributes?: RoleAttribute[]
  companyUsers: User[]
  onCancel: () => void
  handleClose: () => void
  minHourlyPayRate: number
  allowSelectionFromShiftRequest?: boolean
  sentinelNotificationToUserId?: string
}

export const EditShiftForm = (props: EditShiftFormProps) => {
  const {
    shift,
    shiftList,
    roles,
    attributes,
    onCancel,
    handleClose,
    minHourlyPayRate,
    companyUsers,
    allowSelectionFromShiftRequest,
    sentinelNotificationToUserId,
  } = props

  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { showError, showSuccess } = useAlert()
  const { refetchActiveQueries } = useActiveQueries()
  const { trackAnalytics } = useAnalytics()

  /*
   * Options
   */
  const { shiftTagMenuItems } = useShiftTags()

  const roleMap: Map<string, Role> = useMemo(
    () => new Map(roles?.map((role) => [role.roleId, role]) || []),
    [roles],
  )

  const attributeOptions: IMenuItem[] =
    attributes
      ?.filter(
        (attr) =>
          !hotSettings?.allowStoringRequiredAttributeLevel ||
          !attr.allowOpsLeveling ||
          shift.requiredAttributes?.some(
            (requiredAttr) =>
              (typeof requiredAttr === 'string'
                ? requiredAttr === attr.type
                : requiredAttr.type === attr.type) &&
              !shift.requiredAttributeLevels?.some((level) =>
                typeof requiredAttr === 'string'
                  ? level.attribute.type === requiredAttr
                  : level.attribute.type === requiredAttr.type,
              ),
          ),
      )
      ?.map((attribute) => {
        return {
          label: attribute.displayName,
          value: attribute.type,
        }
      })
      .sort(sortByProp('label', 'ASC')) || []

  const signupStatusOptions: IMenuItem[] = [
    { label: 'Allowed', value: ShiftSignupStatus.ALLOWED },
    { label: 'Disallowed', value: ShiftSignupStatus.DISALLOWED },
  ]

  const ffMaxOptions: IMenuItem[] = [
    { value: ForwardFillMax.NONE, label: 'None' },
    { value: ForwardFillMax.INVITED_FIRST, label: 'Invited First' },
    { value: ForwardFillMax.INVITED_ONLY, label: 'Invited Only' },
    { value: ForwardFillMax.FAVORITES, label: 'Favorites Only' },
    {
      value: ForwardFillMax.EXPERIENCED,
      label: 'Previously Worked Workers',
      secondaryLabel: 'Deprecated',
      disabled: true,
    },
    {
      value: ForwardFillMax.EXPERIENCED_ONLY,
      label: 'Experienced Only',
    },
    {
      value: ForwardFillMax.EXPERIENCED_FIRST,
      label: 'Experienced First',
    },
    {
      value: ForwardFillMax.INEXPERIENCED_ONLY,
      label: 'Inexperienced Only',
    },
  ]

  const invitedWorkersOptions: IMenuItem[] = [
    { value: InvitedWorkers.ALL, label: 'All' },
    { value: InvitedWorkers.FAVORITES_FIRST, label: 'Favorites First' },
    { value: InvitedWorkers.FAVORITES_ONLY, label: 'Favorites Only' },
  ]

  const featuredShiftStatusOptions = [
    { value: ShiftFeaturedStatus.NOT_FEATURED, label: 'Not Featured' },
    { value: ShiftFeaturedStatus.FEATURED, label: 'Featured' },
  ]

  const certificationOptions = getEnumOptions(WorkerCertificationType)
  /**
   * States
   */

  const {
    isOpen: isRoleAttrModalOpen,
    open: openRoleAttrModal,
    handleClose: handleCloseRoleAttrModal,
  } = useModal()

  const [roleId, setRoleId] = useState<string>(shift.roleId)
  const [locationId, setLocationId] = useState<string>(shift.locationId)
  const [supervisorId, setSupervisorId] = useState<string>(shift.supervisorId)
  const [shiftRequestId, setShiftRequestId] = useState<string>(
    shift.shiftRequestId,
  )
  const [parkingLocationId, setParkingLocationId] = useState<
    string | undefined
  >(shift.parkingLocationId)
  const [payRate, setPayRate] = useState<number>(shift.payRate)
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>(
    shift.paymentType,
  )
  const [startTime, setStartTime] = useState<Date>(new Date(shift.startTime))
  const [businessStartTime, setBusinessStartTime] = useState<
    Date | undefined | null
  >(shift.businessStartTime)
  const [isInvalidBuffer, setIsInvalidBuffer] = useState<boolean>(false)
  const [endTime, setEndTime] = useState<Date>(new Date(shift.endTime))
  const [signupStatus, setSignupStatus] = useState<
    ShiftSignupStatus | undefined
  >(shift.signupStatus)
  const [slotsRequested, setSlotsRequested] = useState<number>(
    shift.slotsRequested,
  )
  const [enableDynamicOverbooking, setEnableDynamicOverbooking] =
    useState<boolean>(!!shift.enableDynamicOverbooking)
  const [overbookSlotsRequested, setOverbookSlotsRequested] = useState<
    number | undefined
  >(enableDynamicOverbooking ? undefined : shift.overbookSlotsRequested)
  const [minimumAcceptedTier, setMinimumAcceptedTier] = useState<
    TierLevel | undefined
  >(shift.minimumAcceptedTier)
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | null | undefined
  >(shift.genderPreference)
  const [unprovenWorkerThreshold, setUnprovenWorkerThreshold] = useState<
    number | undefined
  >(shift.unprovenWorkerThreshold)
  const [forwardFillMax, setForwardFillMax] = useState<
    ForwardFillMax | undefined
  >(shift.forwardFillMax)
  const [invitedWorkers, setInvitedWorkers] = useState<
    InvitedWorkers | undefined
  >(shift.invitedWorkers)
  const [isMultiShiftRequired, setIsMultiShiftRequired] = useState<boolean>(
    shift.isInRequiredMultiShift || false,
  )
  const [showAddressToNonWorkersForShift, setShowAddressToNonWorkersForShift] =
    useState<boolean>(shift.showAddressToNonWorkersForShift || false)
  const [showNameToNonWorkersForShift, setShowNameToNonWorkersForShift] =
    useState<boolean>(shift.showNameToNonWorkersForShift || false)
  const [paidBackupSlotsRequested, setPaidBackupSlotsRequested] = useState<
    number | undefined
  >(shift.paidBackupSlotsRequested)
  const [paidBackupPayAmount, setPaidBackupPayAmount] = useState<
    number | undefined
  >(shift.paidBackupPayAmount)
  const [featuredStatus, setFeaturedStatus] = useState<
    ShiftFeaturedStatus | undefined
  >(shift.featuredStatus)
  const [extraBGCRequirement, setExtraBGCRequirement] =
    useState<BGCRequirement>(shift.extraBGCRequirement)
  const [confirmationCode, setConfirmationCode] = useState<number>(
    shift.confirmationCode,
  )
  const [minimumPaidTime, setMinimumPaidTime] = useState<number | undefined>(
    shift.minimumPaidTime,
  )
  const [clockOutCode, setClockOutCode] = useState<number>(shift.clockOutCode)
  const [
    shouldUpdateCompletedShiftsWithWorkers,
    setShouldUpdateCompletedShiftsWithWorkers,
  ] = useState<boolean>(false)
  const [
    shouldNotifyUpcomingOrInProgressShifts,
    setShouldNotifyUpcomingOrInProgressShifts,
  ] = useState<boolean>(false)
  const [shouldNotifyCompletedShifts, setShouldNotifyCompletedShifts] =
    useState<boolean>(false)
  const [shouldBypassValidateSchedule, setShouldBypassValidateSchedule] =
    useState<boolean>(false)
  const [scheduledBreaks, setScheduledBreaks] = useState(shift.scheduledBreaks)
  const [breakType, setBreakType] = useState(shift.breakType)
  const [calculatedMarkup, setCalculatedMarkup] = useState(
    shift.calculatedMarkup,
  )
  const [additionalEmails, setAdditionalEmails] = useState<
    string[] | undefined
  >(shift.additionalEmails)
  const [minimumAgeRequirement, setMinimumAgeRequirement] = useState(
    shift.minimumAgeRequirement,
  )
  const [resumeUploadRequired, setResumeUploadRequired] = useState(
    shift.resumeUploadRequired,
  )
  const [canBypassTrainingShift, setCanBypassTrainingShift] = useState<boolean>(
    shift.canBypassTrainingShift ?? false,
  )
  const [requireW9Authorization, setRequireW9Authorization] = useState<boolean>(
    shift.requireW9Authorization ?? false,
  )
  const [pluckFromRequiredMultiShift, setPluckFromRequiredMultiShift] =
    useState(shift.pluckFromRequiredMultiShift)
  const [allowsBackfill, setAllowsBackfill] = useState<boolean>(
    shift.backfillSettings.allowsBackfill,
  )
  const [lateTolerance, setLateTolerance] = useState<number>(
    shift.backfillSettings.lateTolerance,
  )
  const [numberOfUnits, setNumberOfUnits] = useState<number | undefined>(
    shift.numberOfUnits,
  )

  const roleLocationMatchesShiftLocation =
    roleId &&
    !isRoleLocationNotMatchingShiftLocation(locationId, roleMap.get(roleId))

  const supervisorLocationMatchesShiftLocation = useMemo(
    () =>
      supervisorId &&
      doesUserHaveAccessToLocation({
        locationId,
        user: companyUsers.find((s) => s.uid === supervisorId),
      }),
    [locationId, companyUsers, supervisorId],
  )

  const shiftTags =
    shift.tags?.map((t) => {
      return { value: t, label: t }
    }) || []

  const shiftRequiredAttributes =
    compact(
      shift.requiredAttributes
        ?.filter(
          (attribute) =>
            !hotSettings?.allowStoringRequiredAttributeLevel ||
            !shift.requiredAttributeLevels?.some((level) =>
              typeof attribute === 'string'
                ? level.attribute.type === attribute
                : level.attribute.type === attribute.type,
            ),
        )
        ?.map((attribute) => {
          const roleAttribute = attributes?.find((sa) =>
            typeof attribute === 'string'
              ? sa.type === attribute
              : sa.type === attribute.type,
          )
          if (roleAttribute) {
            return {
              label: roleAttribute.displayName,
              value: roleAttribute.type,
            }
          }
        }),
    ) || []

  const shiftRequiredCertifications =
    compact(
      shift.requiredCertifications?.map((certification) => {
        return {
          label: certification,
          value: certification,
        }
      }),
    ) || []

  const [requiredAttributes, setRequiredAttributes] = useState<IMenuItem[]>(
    shiftRequiredAttributes,
  )
  const [requiredAttributeLevels, setRequiredAttributeLevels] = useState<
    RequiredAttributeLevel[]
  >(shift.requiredAttributeLevels ?? [])

  const [requiredCertifications, setRequiredCertifications] = useState<
    IMenuItem[]
  >(shiftRequiredCertifications)
  const [tags, setTags] = useState<IMenuItem[]>(shiftTags)

  const setPaidBackupPayAmountHandler = (amount: number | undefined) => {
    setPaidBackupPayAmount(amount ? convertPayRateToCents(amount) : undefined)
  }

  const getPaidBackupPayAmountInDollars = () =>
    paidBackupPayAmount ? convertCentsToDollars(paidBackupPayAmount) : undefined

  /**
   * Submit Handler
   */
  const validateShiftEdit = () => {
    return (
      validatePayRate({
        payType: shift.payType,
        payRate,
        minHourlyPayRate,
        numberOfUnits: shift.numberOfUnits,
      }) &&
      validateSlots(slotsRequested, overbookSlotsRequested) &&
      validateUnprovenThreshold(unprovenWorkerThreshold) &&
      validateDynamicOverbookAllowed(
        slotsRequested,
        !!enableDynamicOverbooking,
      ) &&
      validateClockCodeLength(confirmationCode) &&
      validateClockCodeLength(clockOutCode) &&
      !isInvalidBuffer &&
      !!shiftRequestId &&
      validatePaidBackups({
        paidBackupSlotsRequested,
        paidBackupPayAmount,
        paidBackupPayAmountMax: hotSettings?.paidBackupPayAmountMax,
      }) &&
      validateMinimumPaidTime(minimumPaidTime) &&
      roleLocationMatchesShiftLocation &&
      supervisorLocationMatchesShiftLocation
    )
  }

  const [editLoading, setEditLoading] = useState(false)
  const handleSubmitEdits = async () => {
    setEditLoading(true)
    try {
      if (valueEdited(shift.isInRequiredMultiShift, isMultiShiftRequired)) {
        await trabaApi.patch(`shift-requests/${shift.shiftRequestId}`, {
          requiredMultiShiftType: isMultiShiftRequired
            ? RequiredMultiShiftType.ALL_IN_REQUEST
            : RequiredMultiShiftType.None,
        })
        trackAnalytics('Multi-Shift Edited From Console', {
          shiftRequestId: shift.shiftRequestId,
          isRequired: isMultiShiftRequired,
        })
      }

      const allEditStates: UpdateShiftData = {
        roleId,
        locationId,
        supervisorId,
        parkingLocationId,
        payRate,
        signupStatus,
        enableDynamicOverbooking,
        overbookSlotsRequested,
        minimumAcceptedTier,
        unprovenWorkerThreshold,
        forwardFillMax,
        featuredStatus,
        confirmationCode,
        invitedWorkers,
        clockOutCode,
        extraBGCRequirement,
        paymentType,
        scheduledBreaks,
        breakType,
        calculatedMarkup,
        additionalEmails,
        minimumAgeRequirement,
        resumeUploadRequired,
        shiftRequestId,
        canBypassTrainingShift,
        pluckFromRequiredMultiShift,
        minimumPaidTime,
        requireW9Authorization,
        numberOfUnits,
      }

      const shiftUpdates: UpdateShiftData = {}

      const editedStartTime = valueEdited(new Date(shift.startTime), startTime)
      const editedEndTime = valueEdited(new Date(shift.endTime), endTime)
      const editedBusinessStartTime =
        !!businessStartTime &&
        !!shift.businessStartTime &&
        valueEdited(
          new Date(shift.businessStartTime),
          new Date(businessStartTime),
        )

      if (
        editedBusinessStartTime ||
        editedStartTime ||
        (editedEndTime && shiftList.length > 1)
      ) {
        shiftUpdates['businessStartTime'] = businessStartTime
      }

      if (
        valueEdited(
          shift.showAddressToNonWorkersForShift,
          showAddressToNonWorkersForShift,
        )
      ) {
        shiftUpdates['showAddressToNonWorkersForShift'] =
          showAddressToNonWorkersForShift
      }

      if (
        valueEdited(
          shift.showNameToNonWorkersForShift,
          showNameToNonWorkersForShift,
        )
      ) {
        shiftUpdates['showNameToNonWorkersForShift'] =
          showNameToNonWorkersForShift
      }

      if (editedStartTime || (editedEndTime && shiftList.length > 1)) {
        shiftUpdates['startTime'] = startTime
      }
      if (
        editedEndTime ||
        ((editedStartTime || editedBusinessStartTime) && shiftList.length > 1)
      ) {
        shiftUpdates['endTime'] = endTime
      }

      if (
        valueEdited(requiredAttributes, shiftRequiredAttributes) ||
        valueEdited(requiredAttributeLevels, shift.requiredAttributeLevels)
      ) {
        shiftUpdates['requiredAttributes'] = compact(
          requiredAttributes.map((attr) => {
            const attribute = attributes?.find(
              (a) =>
                (a.type === attr.value &&
                  (!hotSettings?.allowStoringRequiredAttributeLevel ||
                    !a.allowOpsLeveling)) ||
                shift.requiredAttributes?.some(
                  (requiredAttr) =>
                    (typeof requiredAttr === 'string'
                      ? requiredAttr === a.type
                      : requiredAttr.type === a.type) &&
                    !shift.requiredAttributeLevels?.some((level) =>
                      typeof requiredAttr === 'string'
                        ? level.attribute.type === requiredAttr
                        : level.attribute.type === requiredAttr.type,
                    ),
                ),
            )
            if (attribute) {
              return {
                category: attribute.category,
                type: attr.value,
              }
            }
            return undefined
          }),
        )
        shiftUpdates['requiredAttributeLevels'] = requiredAttributeLevels
      }

      if (valueEdited(requiredCertifications, shiftRequiredCertifications)) {
        shiftUpdates['requiredCertifications'] = requiredCertifications.map(
          (cert) => cert.value as WorkerCertificationType,
        )
      }

      if (valueEdited(tags, shiftTags)) {
        shiftUpdates['tags'] = tags.map((tag) => tag.value as ShiftTag)
      }

      if (valueEdited(allowsBackfill, shift.backfillSettings.allowsBackfill)) {
        shiftUpdates['backfillSettings'] = {
          ...shift.backfillSettings,
          allowsBackfill,
        }
      }

      if (valueEdited(lateTolerance, shift.backfillSettings.lateTolerance)) {
        shiftUpdates['backfillSettings'] = {
          ...(shiftUpdates['backfillSettings'] || shift.backfillSettings), // In case its been overwritten already
          lateTolerance,
        }
      }

      if (valueEdited(shift.slotsRequested, slotsRequested)) {
        shiftUpdates.slotsRequested = slotsRequested
        shiftUpdates.minSlotsRequested = slotsRequested
      }

      for (const key in allEditStates) {
        const value = allEditStates[key as keyof typeof allEditStates]
        //@ts-ignore
        if (valueEdited(shift[key], value)) {
          //@ts-ignore
          shiftUpdates[key] = value
        }
      }

      // Only if dynamic overbooking was explicitly enabled or disabled do we
      // want to change the value of enableDynamicOverbooking for the shift
      if (
        valueEdited(enableDynamicOverbooking, shift.enableDynamicOverbooking)
      ) {
        shiftUpdates.enableDynamicOverbooking =
          allEditStates.enableDynamicOverbooking
      }

      if (valueEdited(shift.genderPreference, genderPreference)) {
        shiftUpdates.genderPreference = genderPreference
          ? genderPreference
          : null
      }

      if (valueEdited(shift.minimumAgeRequirement, minimumAgeRequirement)) {
        shiftUpdates.minimumAgeRequirement = minimumAgeRequirement
          ? minimumAgeRequirement
          : null
      }
      // We don't support dynamic overbooking on shifts with less than 5 slots
      // requested.
      if (slotsRequested < MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB) {
        shiftUpdates.enableDynamicOverbooking = false
      }

      if (
        valueEdited(shift.paidBackupSlotsRequested, paidBackupSlotsRequested)
      ) {
        shiftUpdates.paidBackupSlotsRequested = Math.max(
          0,
          paidBackupSlotsRequested ?? 0,
        )
      }

      if (valueEdited(shift.paidBackupPayAmount, paidBackupPayAmount)) {
        shiftUpdates.paidBackupPayAmount = Math.max(0, paidBackupPayAmount ?? 0)
      }

      // Remove keys with undefined values because the request payload
      // would not include undefined values but it will pass if statement below
      const sanitizedShiftUpdates = Object.entries(shiftUpdates).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key as keyof UpdateShiftData] = value
          }
          return acc
        },
        {} as UpdateShiftData,
      )

      if (Object.keys(sanitizedShiftUpdates).length > 0) {
        const shiftIds = shiftList.join(',')
        const queryParamsString = getQueryParams([
          ['shiftIds', shiftIds],
          [
            'shouldUpdateCompletedShiftsWithWorkers',
            shouldUpdateCompletedShiftsWithWorkers,
          ],
          [
            'shouldNotifyUpcomingOrInProgressShifts',
            shouldNotifyUpcomingOrInProgressShifts,
          ],
          ['shouldNotifyCompletedShifts', shouldNotifyCompletedShifts],
          ['shouldBypassValidateSchedule', shouldBypassValidateSchedule],
        ])

        await trabaApi.patch(`shifts${queryParamsString}`, {
          shiftUpdates: sanitizedShiftUpdates,
          sentinelNotificationToUserId,
        })
        if (sentinelNotificationToUserId) {
          trackAnalytics('Sentinel Action Taken Shifts Edited', {
            shiftIds,
            shiftUpdates: sanitizedShiftUpdates,
            sentinelNotificationToUserId,
          })
        }
        trackAnalytics('Shifts Edited From Console', {
          shiftIds,
          shiftUpdates: sanitizedShiftUpdates,
        })
      }

      await refetchActiveQueries()
      showSuccess('Successfully Edited Shifts', 'Shifts Edited')
      handleClose()
    } catch (error) {
      showError(JSON.stringify(error), 'Error Editing Shifts')
    } finally {
      setEditLoading(false)
    }
  }

  const handleRoleChange = useCallback(
    (roleId: string) => {
      // Auto populate pay rate for the new role
      const newRoleDefaultPayRate = roles?.find(
        (role) => role.roleId === roleId,
      )?.defaultPayRate
      const isRolePayRateValid = validatePayRate({
        payType: shift.payType,
        payRate: newRoleDefaultPayRate,
        minHourlyPayRate,
      })
      if (isRolePayRateValid && newRoleDefaultPayRate) {
        setPayRate(newRoleDefaultPayRate)
      }
      setRoleId(roleId)
    },
    [roles, shift.payType, minHourlyPayRate, setPayRate, setRoleId],
  )

  const supervisorErrorMessage = useMemo(
    () =>
      getSelectSupervisorForBookingShiftErrorMessage({
        locationId,
        supervisor: companyUsers.find((s) => s.uid === supervisorId),
        isRegionalAccessEnabled: hotSettings?.enableRegionalAccessPhase2,
      }),
    [
      locationId,
      supervisorId,
      companyUsers,
      hotSettings?.enableRegionalAccessPhase2,
    ],
  )

  const handleLocationChange = useCallback((locationId?: string) => {
    if (locationId) {
      setLocationId(locationId)
    }
  }, [])

  const handleParkingLocationChange = useCallback(
    (parkingLocationId?: string) => {
      setParkingLocationId(parkingLocationId)
    },
    [],
  )

  const oldOverbook = (
    <Row fullWidth mb={theme.space.xs}>
      <EditShiftField
        label="Overbook Slots Requested"
        currentValue={overbookSlotsRequested}
        originalValue={shift.overbookSlotsRequested}
        setter={setOverbookSlotsRequested}
        input={
          <Row style={{ width: 300 }}>
            <DeprecatedNumberInput
              value={overbookSlotsRequested}
              setter={(numReq) => setOverbookSlotsRequested(numReq)}
              placeholder={`e.g. ${slotsRequested + 1}`}
              error={!validateOverbook(slotsRequested, overbookSlotsRequested)}
              helperText="Must be ≥ slots requested"
            />
          </Row>
        }
      />
    </Row>
  )

  const updatedDynamicOverbooking = (
    <>
      <Row fullWidth mb={theme.space.xs} wrap>
        <EditShiftField
          label="Enable Dynamic Overbooking"
          currentValue={enableDynamicOverbooking}
          originalValue={shift.enableDynamicOverbooking}
          setter={setEnableDynamicOverbooking}
          input={
            <Row alignCenter style={{ width: 300 }}>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={enableDynamicOverbooking}
                onClick={() => {
                  if (
                    !enableDynamicOverbooking &&
                    slotsRequested < MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
                  ) {
                    alert(
                      'Dynamic overbooking can only be enabled on shifts with 5 or more slots requested.',
                    )
                    return
                  }
                  const newEnableDynamicOverbooking = !enableDynamicOverbooking
                  setEnableDynamicOverbooking(newEnableDynamicOverbooking)
                  // Whenever this switch is toggled, completely reset overbook
                  // slots. This dramatically simplifies state management.
                  setOverbookSlotsRequested(undefined)
                }}
              />
              <InfoTooltip
                title={`When enabled, this shift's overbook slots will be continuously adjusted (no
                         action needed on your part). If you want to override this and manually set the
                         overbook slots, disable this and add your desired overbook slots in the
                         "Overbook Slots Requested" field that appears.`}
                tooltipProps={{
                  leaveDelay: 500,
                  placement: 'top',
                  arrow: true,
                }}
              />
            </Row>
          }
        />
      </Row>
      {!enableDynamicOverbooking && oldOverbook}
    </>
  )

  if (isLoadingHotSettings) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxxl} />
      </Row>
    )
  }
  return (
    <FormWrapper>
      <Row wrap>
        <Col style={{ flex: 1 }}>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={shiftRequestId}
              originalValue={shift.shiftRequestId}
              label="Shift Request Id"
              setter={setShiftRequestId}
              input={
                <Row style={{ width: 300 }}>
                  <Input
                    full
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setShiftRequestId(e.target.value)
                    }
                    value={shiftRequestId}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={roleId}
              originalValue={shift.roleId}
              label="Role"
              setter={setRoleId}
              input={
                <RoleSearchSelect
                  roles={roles ?? []}
                  selectedRoleId={roleId}
                  selectedLocationId={locationId}
                  handleRoleChange={handleRoleChange}
                  style={{ width: 300 }}
                  errorMessage={getRoleSearchSelectErrorMessage(
                    locationId,
                    roleMap.get(roleId),
                  )}
                />
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={supervisorId}
              originalValue={shift.supervisorId}
              label="Supervisor"
              setter={setSupervisorId}
              input={
                <SupervisorForShiftSearchSelect
                  allSupervisors={companyUsers}
                  selectedSupervisorId={supervisorId}
                  selectedLocationId={locationId}
                  handleSupervisorChange={setSupervisorId}
                  showEmailForUser
                  style={{ width: 300 }}
                  errorMessage={supervisorErrorMessage}
                  disabled={!locationId}
                  disabledTooltipText="Please select a work site location before selecting a supervisor."
                />
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={locationId}
              originalValue={shift.locationId}
              label="Location"
              setter={handleLocationChange}
              input={
                <LocationSingleSearchSelector
                  companyId={shift.companyId}
                  placeholder=""
                  selectedLocationId={locationId}
                  onChange={handleLocationChange}
                  label=""
                  style={{ width: 300 }}
                  hideCompanyWideOption
                />
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={parkingLocationId}
              originalValue={shift.parkingLocationId}
              label="Parking Location"
              setter={handleParkingLocationChange}
              input={
                <LocationSingleSearchSelector
                  companyId={shift.companyId}
                  placeholder=""
                  selectedLocationId={parkingLocationId}
                  onChange={handleParkingLocationChange}
                  label=""
                  style={{ width: 300 }}
                  hideCompanyWideOption
                  showClearButton
                />
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={payRate}
              originalValue={shift.payRate}
              label="Pay Rate"
              setter={setPayRate}
              input={
                <Row style={{ width: 300 }}>
                  <DeprecatedNumberInput
                    value={payRate}
                    setter={(payRate) => setPayRate(payRate)}
                    step={0.01}
                    money
                    placeholder="Pay Rate"
                    error={
                      !validatePayRate({
                        payType: shift.payType,
                        payRate: payRate,
                        minHourlyPayRate,
                        numberOfUnits: shift.numberOfUnits,
                      })
                    }
                    helperText="To post a lower rate, adjust the min hourly rate company setting."
                  />
                </Row>
              }
            />
          </Row>
          {shift.payType === ShiftPayType.UNIT && (
            <Row fullWidth mb={theme.space.xs}>
              <EditShiftField
                label="Number of Units"
                currentValue={numberOfUnits}
                originalValue={shift.numberOfUnits}
                setter={setNumberOfUnits}
                input={
                  <Row style={{ width: 300 }}>
                    <NumberInput
                      value={numberOfUnits}
                      setValue={setNumberOfUnits}
                      step={1}
                      min={0}
                    />
                  </Row>
                }
              />
            </Row>
          )}
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={paymentType}
              originalValue={shift.paymentType}
              label="Payment Type"
              setter={setPaymentType}
              input={
                <Row style={{ width: 300, flexDirection: 'column' }}>
                  <Select
                    menuItems={paymentTypeOptions}
                    fullWidth
                    dropdownStyle={{ height: '48px' }}
                    handleSelect={(paymentType) =>
                      setPaymentType(paymentType as PaymentType | undefined)
                    }
                    value={paymentType || ''}
                  />
                  {paymentType === PaymentType.AUTO_WEEKLY && (
                    <WeeklyPayWarning mt={theme.space.xs} mb={theme.space.xs} />
                  )}
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={requiredAttributes}
              originalValue={shiftRequiredAttributes}
              label={
                hotSettings?.allowStoringRequiredAttributeLevel
                  ? 'Attributes (not enforced)'
                  : 'Required Attributes'
              }
              setter={setRequiredAttributes}
              input={
                <Row style={{ width: 300 }}>
                  <SearchSelect
                    multiple
                    options={attributeOptions}
                    handleSelectMultiple={setRequiredAttributes}
                    selectedItems={requiredAttributes}
                    width={300}
                  />
                </Row>
              }
            />
          </Row>
          {hotSettings?.allowStoringRequiredAttributeLevel && (
            <Row fullWidth mb={theme.space.xs}>
              <EditShiftField
                currentValue={requiredAttributeLevels}
                originalValue={shift.requiredAttributeLevels ?? []}
                label={'Required Attribute Levels'}
                setter={setRequiredAttributeLevels}
                input={
                  <Row style={{ width: 300 }}>
                    <Button
                      onClick={() => openRoleAttrModal()}
                      variant={ButtonVariant.OUTLINED}
                    >
                      View or Update Attribute Levels
                    </Button>
                  </Row>
                }
              />
            </Row>
          )}
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={requiredCertifications}
              originalValue={shiftRequiredCertifications}
              label="Required Certifications"
              setter={setRequiredCertifications}
              input={
                <Row style={{ width: 300 }}>
                  <SearchSelect
                    multiple
                    options={certificationOptions}
                    handleSelectMultiple={setRequiredCertifications}
                    selectedItems={requiredCertifications}
                    width={300}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={tags}
              originalValue={shiftTags}
              label="Shift Tags"
              setter={setTags}
              input={
                <Row style={{ width: 300 }}>
                  <SearchSelect
                    multiple
                    options={shiftTagMenuItems}
                    handleSelectMultiple={setTags}
                    selectedItems={tags}
                    width={300}
                    onlyShowLabel
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={signupStatus}
              originalValue={shift.signupStatus}
              label="Signup Status"
              setter={setSignupStatus}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={signupStatusOptions}
                    fullWidth
                    dropdownStyle={{ height: '48px' }}
                    handleSelect={(signupStatus) =>
                      setSignupStatus(
                        signupStatus as ShiftSignupStatus | undefined,
                      )
                    }
                    value={signupStatus || ''}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={minimumAcceptedTier}
              originalValue={shift.minimumAcceptedTier}
              label="Minimum Tier"
              setter={setMinimumAcceptedTier}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={minimumTierOptions}
                    fullWidth
                    handleSelect={(tier) =>
                      setMinimumAcceptedTier(tier as TierLevel)
                    }
                    value={minimumAcceptedTier || ''}
                    showEmptyOption
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={genderPreference}
              originalValue={shift.genderPreference}
              label="Gender Preference"
              setter={setGenderPreference}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={genderPreferenceOptions}
                    fullWidth
                    handleSelect={(genderPreference) =>
                      setGenderPreference(genderPreference as GenderPreference)
                    }
                    value={genderPreference || ''}
                    showEmptyOption
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={forwardFillMax}
              originalValue={shift.forwardFillMax}
              label="Forward Fill Max"
              setter={setForwardFillMax}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={ffMaxOptions}
                    fullWidth
                    handleSelect={(ffMax) =>
                      setForwardFillMax(ffMax as ForwardFillMax)
                    }
                    value={forwardFillMax || ''}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={invitedWorkers}
              originalValue={shift.invitedWorkers}
              label="InvitedWorkers"
              setter={setInvitedWorkers}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={invitedWorkersOptions}
                    fullWidth
                    handleSelect={(invitedWorkers) =>
                      setInvitedWorkers(invitedWorkers as InvitedWorkers)
                    }
                    value={invitedWorkers || ''}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={additionalEmails}
              originalValue={shift.additionalEmails}
              label="Additional Emails"
              setter={setAdditionalEmails}
              input={
                <Row style={{ width: 200 }}>
                  <Autocomplete
                    label="Email addresses"
                    value={additionalEmails || []}
                    options={[]}
                    onChangeValues={(_, value) => {
                      setAdditionalEmails(value)
                    }}
                    errorMessage="Email address is invalid"
                    validateInput={validateEmail}
                  />
                </Row>
              }
            />
          </Row>
          <EditShiftField
            currentValue={minimumAgeRequirement}
            originalValue={shift.minimumAgeRequirement}
            label="Min Age Requirement"
            setter={setMinimumAgeRequirement}
            input={
              <Row style={{ marginBottom: theme.space.xs, width: 200 }}>
                <NumberInput
                  setValue={setMinimumAgeRequirement}
                  value={minimumAgeRequirement || undefined}
                />
              </Row>
            }
          />
          <EditShiftField
            currentValue={resumeUploadRequired}
            originalValue={shift.resumeUploadRequired}
            label="Resume required"
            setter={setResumeUploadRequired}
            input={
              <Row style={{ width: 200 }}>
                <Switch
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={resumeUploadRequired}
                  onClick={() => setResumeUploadRequired(!resumeUploadRequired)}
                />
              </Row>
            }
          />
        </Col>
        <Col style={{ flex: 1 }}>
          <EditShiftTimes
            shift={shift}
            startTime={startTime}
            businessStartTime={businessStartTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setBusinessStartTime={setBusinessStartTime}
            setEndTime={setEndTime}
            isInvalidBuffer={isInvalidBuffer}
            setIsInvalidBuffer={setIsInvalidBuffer}
          />
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={minimumPaidTime}
              originalValue={shift.minimumPaidTime}
              label="Min Paid Time (minutes)"
              setter={setMinimumPaidTime}
              input={
                <Row style={{ width: 300 }}>
                  <NumberInput
                    value={minimumPaidTime}
                    setValue={(threshold) => setMinimumPaidTime(threshold)}
                    decimals={0}
                    placeholder="eg. 240"
                    min={0}
                    step={5}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              label="Slots Requested"
              currentValue={slotsRequested}
              originalValue={shift.slotsRequested}
              setter={setSlotsRequested}
              input={
                <Row style={{ width: 300 }}>
                  <DeprecatedNumberInput
                    value={slotsRequested}
                    setter={setSlotsRequested}
                    error={
                      !validateSlots(slotsRequested, overbookSlotsRequested) ||
                      !validateDynamicOverbookAllowed(
                        slotsRequested,
                        !!enableDynamicOverbooking,
                      )
                    }
                    helperText={`Must be ${
                      enableDynamicOverbooking
                        ? '≥ 5 because dynamic overbooking is enabled.'
                        : '> 0 and ≤ overbook slots'
                    }`}
                  />
                </Row>
              }
            />
          </Row>
          {hotSettings?.shouldUseNodeOverbooking
            ? updatedDynamicOverbooking
            : oldOverbook}
          <>
            <Row fullWidth mb={theme.space.xs}>
              <EditShiftField
                label="Paid Backup Slots Requested"
                currentValue={paidBackupSlotsRequested}
                originalValue={shift.paidBackupSlotsRequested}
                setter={setPaidBackupSlotsRequested}
                input={
                  <Row style={{ width: 300 }}>
                    <NumberInput
                      value={paidBackupSlotsRequested}
                      setValue={setPaidBackupSlotsRequested}
                      step={1}
                      min={0}
                    />
                  </Row>
                }
              />
            </Row>
            <Row fullWidth mb={theme.space.xs}>
              <EditShiftField
                label="Paid Backup Pay"
                currentValue={getPaidBackupPayAmountInDollars()}
                originalValue={
                  shift.paidBackupPayAmount
                    ? convertCentsToDollars(shift.paidBackupPayAmount)
                    : undefined
                }
                setter={setPaidBackupPayAmountHandler}
                input={
                  <Row style={{ width: 300 }}>
                    <NumberInput
                      value={getPaidBackupPayAmountInDollars()}
                      setValue={setPaidBackupPayAmountHandler}
                      isMoney={true}
                      step={0.01}
                      min={1}
                      max={100}
                    />
                  </Row>
                }
              />
            </Row>
          </>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={unprovenWorkerThreshold}
              originalValue={shift.unprovenWorkerThreshold}
              label="Unproven Threshold"
              setter={setUnprovenWorkerThreshold}
              input={
                <Row style={{ width: 300 }}>
                  <NumberInput
                    value={unprovenWorkerThreshold}
                    setValue={(threshold) =>
                      setUnprovenWorkerThreshold(threshold)
                    }
                    decimals={2}
                    placeholder="eg. 0.5"
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={featuredStatus}
              originalValue={shift.featuredStatus}
              label="Featured Shift Status"
              setter={setFeaturedStatus}
              input={
                <Row style={{ width: 300 }}>
                  <Select
                    menuItems={featuredShiftStatusOptions}
                    fullWidth
                    handleSelect={(featuredShiftStatus) =>
                      setFeaturedStatus(
                        featuredShiftStatus as ShiftFeaturedStatus,
                      )
                    }
                    value={featuredStatus || ''}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={lateTolerance}
              originalValue={shift.backfillSettings.lateTolerance}
              label="BF Late Tolerance"
              setter={setLateTolerance}
              input={
                <Row style={{ width: 300 }}>
                  <NumberInput
                    value={lateTolerance}
                    setValue={(tol) => setLateTolerance(tol || 0)}
                    decimals={0}
                    placeholder="eg. 15"
                    min={0}
                    max={240}
                    step={1}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={allowsBackfill}
              originalValue={shift.backfillSettings.allowsBackfill}
              label="Allows Backfill"
              setter={setAllowsBackfill}
              input={
                <Row style={{ width: 300 }}>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={allowsBackfill}
                    onClick={() => setAllowsBackfill(!allowsBackfill)}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={isMultiShiftRequired}
              originalValue={shift.isInRequiredMultiShift ?? false}
              label="Multi-Shift Required (for whole shift request)"
              setter={setIsMultiShiftRequired}
              input={
                <Row style={{ width: 100 }}>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={isMultiShiftRequired}
                    onClick={() =>
                      setIsMultiShiftRequired(!isMultiShiftRequired)
                    }
                  />
                </Row>
              }
            />
            <EditShiftField
              currentValue={pluckFromRequiredMultiShift}
              originalValue={shift.pluckFromRequiredMultiShift}
              label="Pluck from Required Multi-Shift"
              setter={setPluckFromRequiredMultiShift}
              input={
                <Row style={{ width: 100 }}>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={pluckFromRequiredMultiShift}
                    onClick={() =>
                      setPluckFromRequiredMultiShift(
                        !pluckFromRequiredMultiShift,
                      )
                    }
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={canBypassTrainingShift}
              originalValue={shift.canBypassTrainingShift ?? false}
              label="Bypass Training Shift"
              setter={setCanBypassTrainingShift}
              input={
                <Row style={{ width: 300 }}>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={canBypassTrainingShift}
                    onClick={() =>
                      setCanBypassTrainingShift(!canBypassTrainingShift)
                    }
                  />
                </Row>
              }
            />
            <EditShiftField
              currentValue={requireW9Authorization}
              originalValue={shift.requireW9Authorization ?? false}
              label="Require W9 Authorization"
              setter={setRequireW9Authorization}
              input={
                <Row style={{ width: 300 }}>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={requireW9Authorization}
                    onClick={() =>
                      setRequireW9Authorization(!requireW9Authorization)
                    }
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <EditShiftField
              currentValue={showNameToNonWorkersForShift}
              originalValue={shift.showNameToNonWorkersForShift ?? false}
              label="Show Company Name to Non-workers"
              setter={setShowNameToNonWorkersForShift}
              input={
                <Row>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={showNameToNonWorkersForShift}
                    onClick={() =>
                      setShowNameToNonWorkersForShift(
                        !showNameToNonWorkersForShift,
                      )
                    }
                  />
                  <InfoTooltip
                    title={
                      "When enabled, workers who are not on this shift or haven't worked with this company before will also be able to see the company name."
                    }
                    tooltipProps={{
                      leaveDelay: 500,
                      placement: 'top',
                      arrow: true,
                    }}
                  />
                </Row>
              }
            />
            <EditShiftField
              currentValue={showAddressToNonWorkersForShift}
              originalValue={shift.showAddressToNonWorkersForShift ?? false}
              label="Show Shift Address to Non-workers"
              setter={setShowAddressToNonWorkersForShift}
              input={
                <Row>
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={showAddressToNonWorkersForShift}
                    onClick={() =>
                      setShowAddressToNonWorkersForShift(
                        !showAddressToNonWorkersForShift,
                      )
                    }
                  />
                  <InfoTooltip
                    title={
                      "When enabled, workers who are not on this shift or haven't worked with this company before will also be able to see the shift full address."
                    }
                    tooltipProps={{
                      leaveDelay: 500,
                      placement: 'top',
                      arrow: true,
                    }}
                  />
                </Row>
              }
            />
          </Row>
          <Row fullWidth mb={theme.space.xs}>
            <Row mr={theme.space.xxs}>
              <Checkbox
                checked={shouldUpdateCompletedShiftsWithWorkers}
                label={'Update Completed Shifts'}
                onChange={(e) => {
                  setShouldUpdateCompletedShiftsWithWorkers(e.target.checked)
                }}
                textvariant="h6"
              />
            </Row>
            <Checkbox
              checked={shouldNotifyUpcomingOrInProgressShifts}
              label={'Notify Upcoming/In Progress Shifts'}
              onChange={(e) => {
                setShouldNotifyUpcomingOrInProgressShifts(e.target.checked)
              }}
              textvariant="h6"
            />
          </Row>
          <Row fullWidth>
            <Row mr={theme.space.xxs}>
              <Checkbox
                checked={shouldNotifyCompletedShifts}
                label={'Notify Completed Shifts'}
                onChange={(e) => {
                  setShouldNotifyCompletedShifts(e.target.checked)
                }}
                textvariant="h6"
              />
            </Row>
            <Checkbox
              checked={shouldBypassValidateSchedule}
              label={'Bypass Validate Schedule'}
              onChange={(e) => {
                setShouldBypassValidateSchedule(e.target.checked)
              }}
              textvariant="h6"
            />
          </Row>
        </Col>
      </Row>
      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />
      <RequiredAttributeLevelsModal
        attributes={attributes ?? []}
        isOpen={isRoleAttrModalOpen}
        handleClose={handleCloseRoleAttrModal}
        handleConfirm={handleCloseRoleAttrModal}
        requiredAttributeLevels={requiredAttributeLevels ?? []}
        setRequiredAttributeLevels={setRequiredAttributeLevels}
        title="Shift Required Attribute Levels"
      />

      <EditShiftsBreaks
        shift={shift}
        scheduledBreaks={scheduledBreaks}
        setScheduledBreaks={setScheduledBreaks}
        setBreakType={setBreakType}
        breakType={breakType}
      />

      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />

      {shift.shiftEmploymentType !== EmploymentType.W2 && (
        <IncentivesSection shiftList={shiftList} shift={shift} />
      )}

      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />

      <InvoicingSection shiftIds={shiftList} shift={shift} />

      <Row fullWidth mb={theme.space.xs}>
        <EditShiftField
          currentValue={calculatedMarkup}
          originalValue={shift.calculatedMarkup}
          label="Calculated Markup"
          setter={setCalculatedMarkup}
          input={
            <Row style={{ width: 300 }}>
              <NumberInput
                value={calculatedMarkup}
                setValue={(cm) => setCalculatedMarkup(cm || 0)}
                decimals={4}
                placeholder="eg. 0.5"
                min={0}
                max={1}
                step={0.01}
              />
            </Row>
          }
        />
      </Row>

      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />

      <ShiftSettingsSection>
        <BGCRequirementSelector
          title={`What's the shift's Background Check Requirement?`}
          selectedExtraBGCRequirement={extraBGCRequirement}
          handleSelectExtraBGCRequirement={setExtraBGCRequirement.bind(this)}
        />
        <Row fullWidth mt={theme.space.xs} mb={theme.space.xs}>
          <EditShiftField
            label="Clock In Code"
            currentValue={confirmationCode}
            originalValue={shift.confirmationCode}
            setter={setConfirmationCode}
            input={
              <Row style={{ width: 300 }}>
                <DeprecatedNumberInput
                  value={confirmationCode}
                  setter={(code) => setConfirmationCode(code)}
                  placeholder={`e.g. 1234`}
                  error={!validateClockCodeLength(confirmationCode)}
                  helperText="Don't be too smart and start with a leading 0"
                />
              </Row>
            }
          />
        </Row>
        <Row fullWidth mb={theme.space.xs}>
          <EditShiftField
            label="Clock Out Code"
            currentValue={clockOutCode}
            originalValue={shift.clockOutCode}
            setter={setClockOutCode}
            input={
              <Row style={{ width: 300 }}>
                <DeprecatedNumberInput
                  value={clockOutCode}
                  setter={(code) => setClockOutCode(code)}
                  placeholder={`e.g. 1234`}
                  error={!validateClockCodeLength(clockOutCode)}
                  helperText="Don't be too smart and start with a leading 0"
                />
              </Row>
            }
          />
        </Row>
      </ShiftSettingsSection>

      <ModalButtons
        handleClose={onCancel}
        handleConfirm={handleSubmitEdits}
        confirmText={`Confirm Changes`}
        closeText={allowSelectionFromShiftRequest ? 'Previous' : undefined}
        isDisabled={!validateShiftEdit()}
        loading={editLoading}
      />
    </FormWrapper>
  )
}

export type EditShiftFieldProps = {
  originalValue:
    | string
    | number
    | Date
    | boolean
    | undefined
    | null
    | IMenuItem[]
    | string[]
    | object[]
  currentValue:
    | string
    | number
    | Date
    | boolean
    | undefined
    | null
    | IMenuItem[]
    | string[]
    | object[]
  label: string
  input: React.ReactElement<{ disabled?: boolean }>
  setter: React.Dispatch<React.SetStateAction<any>>
  disabled?: boolean
}

export const EditShiftField = (props: EditShiftFieldProps) => {
  const { originalValue, currentValue, label, input, setter } = props

  const showEdited = valueEdited(originalValue, currentValue)

  return (
    <Row alignCenter justifyBetween fullWidth>
      <Row>
        <Text variant="h7">{label}</Text>
      </Row>
      <Row flexCol style={{ width: '75%' }}>
        {input}
        {showEdited && (
          <Row alignCenter>
            <Icon name="edit" />
            <Text variant="caption">Edited</Text>
            <Button
              style={{
                padding: 0,
                top: 2,
                display: 'inline',
                marginLeft: theme.space.xxs,
                backgroundColor: 'transparent',
              }}
              onClick={() => {
                setter(originalValue)
              }}
            >
              <Icon name={'trash'} size={14} />
            </Button>
          </Row>
        )}
      </Row>
    </Row>
  )
}
