export function DocumentFolded({
  color = '#7A8A99',
  size,
  ...props
}: {
  color: string
  size?: number
}) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '17'}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.23522 9.91593L2.96463 9.74139L2.23522 9.91593ZM2.23522 5.93009L2.96463 6.10463L2.23522 5.93009ZM13.7647 5.9301L13.0353 6.10464L13.7647 5.9301ZM13.7647 9.91592L13.0353 9.74138L13.7647 9.91592ZM10.033 13.574L9.86495 12.843L10.033 13.574ZM5.96693 13.574L5.79886 14.3049L5.96693 13.574ZM5.96693 2.27205L6.13499 3.00298L5.96693 2.27205ZM10.033 2.27205L9.86495 3.00298L10.033 2.27205ZM2.96463 9.74139C2.67842 8.54531 2.67842 7.30071 2.96463 6.10463L1.50581 5.75555C1.16469 7.18111 1.16469 8.66491 1.50581 10.0905L2.96463 9.74139ZM13.0353 6.10464C13.3215 7.30071 13.3215 8.5453 13.0353 9.74138L14.4941 10.0905C14.8353 8.6649 14.8353 7.18112 14.4941 5.75556L13.0353 6.10464ZM9.86495 12.843C8.63833 13.1251 7.36161 13.1251 6.13499 12.843L5.79886 14.3049C7.24667 14.6378 8.75326 14.6378 10.2011 14.3049L9.86495 12.843ZM6.13499 3.00298C7.36161 2.72093 8.63833 2.72093 9.86495 3.00298L10.2011 1.54112C8.75327 1.20822 7.24667 1.20822 5.79886 1.54112L6.13499 3.00298ZM6.13499 12.843C4.55458 12.4796 3.33117 11.2731 2.96463 9.74139L1.50581 10.0905C2.00794 12.1888 3.67604 13.8168 5.79886 14.3049L6.13499 12.843ZM10.2011 14.3049C12.3239 13.8168 13.992 12.1888 14.4941 10.0905L13.0353 9.74138C12.6688 11.2731 11.4454 12.4796 9.86495 12.843L10.2011 14.3049ZM9.86495 3.00298C11.4454 3.36637 12.6688 4.57288 13.0353 6.10464L14.4941 5.75556C13.992 3.65718 12.3239 2.02924 10.2011 1.54112L9.86495 3.00298ZM5.79886 1.54112C3.67603 2.02924 2.00794 3.65717 1.50581 5.75555L2.96463 6.10463C3.33117 4.57288 4.55458 3.36637 6.13499 3.00298L5.79886 1.54112ZM10.137 14.0414C10.137 13.0584 10.1387 12.3981 10.2062 11.9055C10.2706 11.436 10.3819 11.2322 10.5214 11.0954L9.47136 10.0242C9.00147 10.4849 8.80809 11.0604 8.72013 11.7017C8.63534 12.3198 8.637 13.1016 8.637 14.0414H10.137ZM13.5481 9.21249C12.5881 9.21249 11.7941 9.21096 11.1672 9.29359C10.5201 9.37886 9.93917 9.56567 9.47136 10.0242L10.5214 11.0954C10.663 10.9567 10.8771 10.8448 11.3632 10.7807C11.8693 10.714 12.5465 10.7125 13.5481 10.7125V9.21249Z"
        fill={color}
      />
      <path
        d="M5.99997 6.04144H7.99997M5.99997 8.04144H9.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
