import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { Button, Icon } from './base'
import { ButtonVariant } from './base/Button/types'

interface InlineClockOutProps {
  workerShift: WorkerShift
  openClockOutModal: (workerShift: WorkerShift) => void
  openAdjustmentModal: (workerShift: WorkerShift) => void
}

const PendingClockOutTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.Yellow10,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.Yellow10,
    color: theme.colors.Yellow80,
  },
}))

export default function InlineClockOut(props: InlineClockOutProps) {
  const { hotSettings } = useHotSettings()
  const { workerShift, openAdjustmentModal, openClockOutModal } = props
  const tz = useTimezonedDates(workerShift.shiftInfo.timezone)

  const displayedClockOutTime = hotSettings?.enableWorkerEditTime
    ? workerShift.clockOutTime || workerShift.clockOutTimeBeforeWorkerEdit
    : workerShift.clockOutTime

  const clockOutButtonText = displayedClockOutTime
    ? tz.getTime(displayedClockOutTime)
    : workerShift.clockInTime
      ? 'Clock Out'
      : '-'
  const isClockedOut = !!workerShift.clockOutTime
  const isPendingClockOut =
    !isClockedOut && !!workerShift.clockOutTimeBeforeWorkerEdit

  const clockOutButton = (
    <Button
      disabled={!workerShift.clockInTime}
      variant={
        !workerShift.clockInTime || isPendingClockOut || isClockedOut
          ? ButtonVariant.TEXT
          : ButtonVariant.OUTLINED
      }
      rightIcon={isClockedOut ? <Icon name="edit_active" /> : undefined}
      ml={theme.space.xxs}
      style={{
        color: isPendingClockOut ? theme.colors.Yellow80 : undefined,
        padding: isPendingClockOut ? theme.space.zero : theme.space.xxs,
        height: theme.space.sm,
      }}
      onClick={() =>
        workerShift.clockOutTime
          ? openAdjustmentModal(workerShift)
          : openClockOutModal(workerShift)
      }
    >
      {/* Warning Icon */}
      {isPendingClockOut && <span>{'⚠️\u00A0'}</span>}
      {clockOutButtonText}
    </Button>
  )

  return isPendingClockOut ? (
    <PendingClockOutTooltip
      title={
        <div>
          <Text variant="h6" style={{ color: theme.colors.Yellow80 }}>
            Pending clock out
          </Text>
          <Text variant="body2" style={{ color: theme.colors.Yellow80 }}>
            The worker has not confirmed their clock out times and is still
            clocked into the shift. Click the button to confirm the clock out
            time.
          </Text>
        </div>
      }
    >
      <div>{clockOutButton}</div>
    </PendingClockOutTooltip>
  ) : (
    clockOutButton
  )
}
