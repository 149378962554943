import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback } from 'react'
import { Modal } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useShift } from 'src/hooks/useShifts'
import {
  UpdateShiftParams,
  useWorkerShift,
  useWorkerShiftMutations,
} from 'src/hooks/useWorkerShifts'
import { getErrorMessage } from 'src/utils/errorUtils'
import { PendingClockOutAdjustForm } from './PendingClockOutAdjustForm'

type PendingClockOutAdjustAndConfirmModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerId: string
  shiftId: string
}

export function PendingClockOutAdjustAndConfirmModal({
  handleClose,
  isOpen,
  workerId,
  shiftId,
}: PendingClockOutAdjustAndConfirmModalProps) {
  const {
    workerShift,
    isLoading: isLoadingWorkerShift,
    error: workerShiftError,
  } = useWorkerShift({
    workerId,
    shiftId,
    includeWorker: true,
    includeAccountStatus: true,
  })
  const {
    shift,
    isLoading: isLoadingShift,
    error: shiftError,
  } = useShift(shiftId)

  const {
    updateShift,
    isUpdateShiftLoading,
    bulkEndShifts,
    isBulkEndShiftsLoading,
  } = useWorkerShiftMutations()

  const isLoading = isLoadingWorkerShift || isLoadingShift

  const { handleError, showError, showSuccess } = useAlert()

  if (workerShiftError || shiftError) {
    handleError(
      workerShiftError ?? shiftError,
      'PendingClockOutAdjustAndConfirmModal',
      'Please try again',
      'Error fetching worker shift updated data',
    )
    handleClose()
  }

  const { state } = useUserContext()

  const handleConfirmAndEndShift = useCallback(
    async ({
      // required for "ending shift"
      clockOutTime,
      // optional params if ops made updates
      breaks,
      clockInTime,
      shiftInfo,
    }: Pick<
      UpdateShiftParams,
      'clockInTime' | 'clockOutTime' | 'breaks' | 'shiftInfo'
    >) => {
      if (!workerShift) {
        return
      }

      if (!clockOutTime) {
        showError('A clock out time must be provided to end the shift')
        return
      }

      try {
        await updateShift({
          workerId: workerShift.workerId,
          shiftId: workerShift.shiftId,
          breaks,
          clockInTime,
          shiftInfo,
        })

        await bulkEndShifts({
          workerShiftsToEnd: [
            {
              workerId: workerShift.workerId,
              shiftId: workerShift.shiftId,
              clockOutTime,
            },
          ],
        })

        showSuccess('Worker Shift Updated and Shift Ended Successfully')

        window.analytics.track(
          'Worker Shift Updated and Shift Ended From Console',
          {
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
            updatedBy: state.userProfile?.email,
          },
        )
      } catch (e: unknown) {
        showError(
          getErrorMessage(e),
          'Error Updating and Confirming Worker Shift',
        )
      }

      handleClose()
    },
    [
      workerShift,
      handleClose,
      showError,
      updateShift,
      bulkEndShifts,
      showSuccess,
      state.userProfile?.email,
    ],
  )

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title="Update and complete shift"
    >
      {isLoading || !shift || !workerShift ? (
        <Row fullWidth fullHeight alignCenter justifyCenter>
          <CircularProgress size={theme.space.xxxl} />
        </Row>
      ) : (
        <PendingClockOutAdjustForm
          handleClose={handleClose}
          handleConfirmAndEndShift={handleConfirmAndEndShift}
          workerShift={workerShift}
          loading={isUpdateShiftLoading || isBulkEndShiftsLoading}
        />
      )}
    </Modal>
  )
}
