import { Tooltip } from '@mui/material'
import { Badge } from 'src/components/base'

export function EarlyArrivalBufferBadge(props: {
  buffer: number
  style?: React.CSSProperties
}) {
  const { buffer, style } = props
  const tooltipContent = `This shift has an early arrival buffer of ${buffer} minutes.`

  return (
    <Tooltip title={tooltipContent}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Badge title={'Early Arrival Buffer'} style={style} />
      </div>
    </Tooltip>
  )
}
