import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { PhoneNumberStatus } from '@traba/types'
import { useMemo } from 'react'
import { Dropdown, DropdownElement } from '../../../components/base/Dropdown'

export type PhoneNumberStatusBadgeProps = {
  phoneNumberStatus?: PhoneNumberStatus
  hasActiveAction?: boolean
  onStatusUpdate: (params: {
    status: PhoneNumberStatus
    shouldSetAction?: boolean
    shouldLiftAction?: boolean
  }) => void
  isLoading?: boolean
}

const phoneNumberStatusToBadgeVariant: Record<PhoneNumberStatus, string> = {
  [PhoneNumberStatus.VERIFIED]: 'success',
  [PhoneNumberStatus.UNREACHABLE]: 'danger',
  [PhoneNumberStatus.WRONG_NUMBER]: 'danger',
  [PhoneNumberStatus.TWILIO_BLOCKED]: 'warning',
}

const getDropdownOptions = (
  status: PhoneNumberStatus | undefined,
  hasActiveAction: boolean,
  onStatusUpdate: PhoneNumberStatusBadgeProps['onStatusUpdate'],
  isLoading?: boolean,
): DropdownElement[] => {
  const verifiedOptions: DropdownElement[] = [
    {
      label: 'Mark Invalid',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.UNREACHABLE,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="cancel" />,
    },
    {
      label: 'Push in App',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.UNREACHABLE,
          shouldSetAction: true,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="notification" />,
    },
  ]

  const unreachableOptions: DropdownElement[] = [
    {
      label: 'Mark Verified',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.VERIFIED,
          shouldLiftAction: true,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="circleCheck" />,
    },
    {
      label: 'Push in App',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.UNREACHABLE,
          shouldSetAction: true,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="notification" />,
    },
  ]

  const activeActionOptions: DropdownElement[] = [
    {
      label: 'Mark Verified',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.VERIFIED,
          shouldLiftAction: true,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="circleCheck" />,
    },
    {
      label: 'Unblock App',
      handleClick: () =>
        onStatusUpdate({
          status: PhoneNumberStatus.UNREACHABLE,
          shouldLiftAction: true,
        }),
      disabled: isLoading,
      icon: () => <SvgIcon name="unblock" />,
    },
  ]

  if (hasActiveAction) {
    return activeActionOptions
  }

  return status === PhoneNumberStatus.VERIFIED
    ? verifiedOptions
    : unreachableOptions
}

export default function PhoneNumberStatusBadge({
  phoneNumberStatus,
  hasActiveAction = false,
  onStatusUpdate,
  isLoading,
}: PhoneNumberStatusBadgeProps) {
  const badgeConfig = useMemo(() => {
    let badgeTitle = 'Unknown'
    if (hasActiveAction) {
      badgeTitle = 'Pushed'
    } else if (phoneNumberStatus === PhoneNumberStatus.VERIFIED) {
      badgeTitle = 'Verified'
    } else if (phoneNumberStatus === PhoneNumberStatus.TWILIO_BLOCKED) {
      badgeTitle = 'Twilio Blocked'
    } else if (
      phoneNumberStatus === PhoneNumberStatus.UNREACHABLE ||
      phoneNumberStatus === PhoneNumberStatus.WRONG_NUMBER
    ) {
      badgeTitle = 'Invalid'
    }

    const variant = phoneNumberStatus
      ? phoneNumberStatusToBadgeVariant[phoneNumberStatus]
      : 'warning'

    return {
      variant,
      title: badgeTitle,
    }
  }, [hasActiveAction, phoneNumberStatus])

  const dropdownOptions = getDropdownOptions(
    phoneNumberStatus,
    hasActiveAction,
    onStatusUpdate,
    isLoading,
  )

  if (isLoading) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }

  return (
    <Dropdown
      orientation="right"
      dropdownOptions={dropdownOptions}
      listContainerStyling={{ width: '175px' }}
      listItemStyling={{
        alignItems: 'center',
        color: theme.colors.Violet80,
        fontWeight: '500',
        fontSize: 14,
      }}
      buttonStyling={{
        whiteSpace: 'nowrap',
        minWidth: 'fit-content',
      }}
      useBadge
      badgeVariant={badgeConfig.variant}
      title={badgeConfig.title}
    />
  )
}
